import { forwardRef, useMemo } from "react";
import { useTheme } from "~/features/theme/theme.hooks";
import { iconMap } from "./icon.map";
import type { IconChildrenProps, IconProps } from "./icon.types";

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      disabled = false,
      inheritColor = false,
      name,
      noAccent = false,
      size = "size-10",
      variant = "dark",
      ...svgProps
    },
    ref,
  ) => {
    const { colors, sizes } = useTheme();
    const Children = useMemo(() => iconMap[name], [name]);

    const parentSize = useMemo(() => sizes[size], [size, sizes]);

    const childrenProps = useMemo<IconChildrenProps>(() => {
      const color = inheritColor
        ? "currentColor"
        : disabled
          ? colors["midgrey-20"]
          : variant === "dark"
            ? colors["dark-20"]
            : colors["beige-20"];

      const oppositeColor =
        disabled || variant === "dark" ? colors["white-00"] : colors["dark-20"];

      const infoColor = disabled ? colors["midgrey-70"] : colors["navy-30"];

      const infoBackgroundColor = disabled
        ? colors["midgrey-90"]
        : colors["navy-80"];

      const accentColor = noAccent ? color : colors["red-20"];

      const confirmColor = colors["score-01"];

      return {
        accentColor,
        color,
        confirmColor,
        infoBackgroundColor,
        infoColor,
        oppositeColor,
      };
    }, [colors, disabled, inheritColor, noAccent, variant]);

    return (
      <svg
        data-testid="icon"
        ref={ref}
        {...svgProps}
        fill="none"
        height={parentSize}
        viewBox="0 0 20 20"
        width={parentSize}
        xmlns="http://www.w3.org/2000/svg"
      >
        <Children {...childrenProps} />
      </svg>
    );
  },
);

Icon.displayName = "Icon";
