import type { IconChildrenProps } from "../../icon.types";

export const Crane = ({ color }: IconChildrenProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3435 1H1.65653C1.29544 1 1 1.29547 1 1.65659V4.90673C1 5.26785 1.29544 5.56332 1.65653 5.56332C2.01763 5.56332 2.31307 5.26785 2.31307 4.90673V4.25013H7.16049V6.21991C7.16049 6.58104 7.45593 6.8765 7.81702 6.8765H9.45836H10.5416H12.2486C12.6097 6.8765 12.9052 6.58104 12.9052 6.21991V4.25013H17.6869V4.90673C17.6869 5.26785 17.9824 5.56332 18.3435 5.56332C18.7046 5.56332 19 5.26785 19 4.90673V1.65659C19 1.29547 18.7046 1 18.3435 1ZM8.47356 5.56332H11.5921V4.25013H8.47356V5.56332ZM2.31307 2.31319V2.93695H17.6869V2.31319H2.31307Z"
      fill={color}
    />
    <path
      d="M9.4504 6.87646V14.4824H9.26438C8.958 14.4824 8.71727 14.7232 8.71727 15.0296C8.71727 15.336 8.958 15.5767 9.26438 15.5767H9.4504V15.8613C8.91423 16.0801 8.53125 16.6054 8.53125 17.2292C8.53125 18.039 9.18778 18.6955 9.99751 18.6955C10.4024 18.6955 10.7744 18.5423 11.048 18.2469C11.3215 17.9733 11.4638 17.6122 11.4638 17.2182C11.4638 16.9118 11.223 16.6711 10.9167 16.6711C10.6103 16.6711 10.3695 16.9118 10.3695 17.2182C10.3695 17.3167 10.3367 17.4043 10.2601 17.4809C10.0413 17.7107 9.61453 17.5246 9.61453 17.2182C9.61453 17.0103 9.77867 16.8461 9.98657 16.8461C10.293 16.8461 10.5337 16.6054 10.5337 16.299V15.5767H10.7416C11.048 15.5767 11.2887 15.336 11.2887 15.0296C11.2887 14.7232 11.048 14.4824 10.7416 14.4824H10.5337V6.87646H9.4504Z"
      fill={color}
    />
  </>
);
