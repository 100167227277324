import type { IconChildrenProps } from "../icon.types";

export const Like = ({ color }: IconChildrenProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14.017 2.2651C13.0602 1.72026 11.8466 2.03949 11.2876 2.99766L8.3322 8.32709L8.32835 8.3343C8.13331 8.7 8.01953 9.11024 8.01953 9.53959V15.0996C8.01953 16.2057 8.91339 17.0996 10.0195 17.0996H14.5195C16.4557 17.0996 18.0195 15.5357 18.0195 13.5996V9.09959C18.0195 7.99345 17.1257 7.09959 16.0195 7.09959H13.5368L14.7523 5.00012L14.754 4.99701C15.2989 4.04022 14.9796 2.82661 14.0215 2.26768L14.017 2.2651ZM9.20891 8.80827L12.1539 3.49729C12.4356 3.0195 13.0384 2.86023 13.5198 3.1328C13.9995 3.41418 14.1595 4.01857 13.8858 4.5008L12.2368 7.34907C12.1473 7.50376 12.1471 7.69449 12.2364 7.84933C12.3256 8.00418 12.4908 8.09959 12.6695 8.09959H16.0195C16.5734 8.09959 17.0195 8.54573 17.0195 9.09959V13.5996C17.0195 14.9834 15.9034 16.0996 14.5195 16.0996H10.0195C9.46567 16.0996 9.01953 15.6534 9.01953 15.0996V9.53959C9.01953 9.29015 9.08511 9.04158 9.20891 8.80827ZM3 9.09952C3 8.54723 3.44772 8.09952 4 8.09952H5C5.55228 8.09952 6 8.54723 6 9.09952V15.0995C6 15.6518 5.55228 16.0995 5 16.0995H4C3.44772 16.0995 3 15.6518 3 15.0995V9.09952ZM4 7.09952C2.89543 7.09952 2 7.99495 2 9.09952V15.0995C2 16.2041 2.89543 17.0995 4 17.0995H5C6.10457 17.0995 7 16.2041 7 15.0995V9.09952C7 7.99495 6.10457 7.09952 5 7.09952H4Z"
    fill={color}
  />
);
