import type { IconChildrenProps } from "../../icon.types";

export const ErgonomicsLowerArm = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M2.96892 10.6315C3.16089 10.4395 3.4613 10.422 3.67298 10.5791L3.73363 10.6315L5.17558 12.0734C5.36755 12.2654 5.385 12.5658 5.22793 12.7775L5.17558 12.8381L3.73363 14.2801C3.52246 14.4912 3.18009 14.4912 2.96892 14.2801C2.77695 14.0881 2.7595 13.7877 2.91657 13.576L2.96892 13.5154L4.02834 12.4557L2.96892 11.3962C2.77695 11.2042 2.7595 10.9038 2.91657 10.6921L2.96892 10.6315ZM16.2647 10.6315C16.4759 10.4203 16.8182 10.4203 17.0294 10.6315C17.2214 10.8234 17.2388 11.1238 17.0818 11.3355L17.0294 11.3962L15.9698 12.4557L17.0294 13.5154C17.2214 13.7073 17.2388 14.0077 17.0818 14.2194L17.0294 14.2801C16.8374 14.472 16.537 14.4895 16.3253 14.3324L16.2647 14.2801L14.8227 12.8381C14.6308 12.6461 14.6133 12.3457 14.7704 12.1341L14.8227 12.0734L16.2647 10.6315Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.94948 8.85161C9.67573 8.85161 9.44949 8.64818 9.41369 8.38425L9.40875 8.31088L9.40983 6.89744C9.40966 6.51583 9.51967 6.14334 9.72506 5.82428L9.80652 5.70722L11.1134 3.96479C11.2781 3.74501 11.2562 3.43754 11.0617 3.24306C10.9584 3.13961 10.8181 3.08146 10.6719 3.08146C10.5549 3.08146 10.4417 3.11867 10.348 3.18666L10.2817 3.24332L8.78664 4.73841C8.49574 5.02951 8.28712 5.39126 8.1805 5.7867L8.14534 5.93649L7.72264 8.04986C7.66592 8.3335 7.66232 8.62474 7.71152 8.909L7.74737 9.0786L8.22451 10.9888C8.27589 11.1926 8.30818 11.4006 8.32108 11.6101L8.32751 11.8199L8.32717 12.6578L8.1555 17.4785C8.14487 17.7769 7.89432 18.0103 7.59587 17.9996C7.32229 17.9899 7.10344 17.7785 7.07705 17.5135L7.07473 17.44L7.24605 12.6386V11.8198C7.24609 11.6764 7.23285 11.5335 7.20641 11.3925L7.17558 11.2521L6.69821 9.34094C6.59041 8.91025 6.5675 8.46332 6.63011 8.02502L6.66218 7.83778L7.08492 5.72421C7.20447 5.12753 7.48042 4.57463 7.88296 4.12121L8.02179 3.97384L9.51683 2.4788C9.82309 2.17224 10.2386 2 10.6719 2C11.1052 2 11.5208 2.17228 11.8267 2.47865C12.3711 3.02311 12.4579 3.86821 12.051 4.50874L11.9787 4.61351L11.2337 5.60631L11.2606 5.60636C11.9684 5.60616 12.5905 6.06119 12.8104 6.72416L12.8449 6.84327L13.2582 8.49661C13.336 8.80822 13.3753 9.12811 13.3753 9.4489C13.3756 10.16 13.1826 10.8565 12.8188 11.4644L12.7154 11.6279L11.9455 12.7816L12.3263 17.415C12.3487 17.6879 12.1644 17.93 11.9043 17.9872L11.8316 17.9982C11.5588 18.0205 11.3166 17.8363 11.2594 17.5762L11.2485 17.5034L10.8531 12.6827C10.8451 12.5856 10.8636 12.4885 10.9059 12.4017L10.9421 12.3386L11.8157 11.0278C12.1277 10.5604 12.2941 10.0111 12.2939 9.44908C12.2939 9.27457 12.2778 9.10047 12.246 8.92908L12.209 8.75867L11.7957 7.10539C11.7406 6.88451 11.5564 6.72292 11.3356 6.69287L11.2608 6.68782H11.0422C10.8957 6.68782 10.7553 6.74596 10.6519 6.84935C10.569 6.93222 10.5152 7.03865 10.4971 7.15289L10.4911 7.22201L10.4913 8.31095C10.4913 8.5847 10.2879 8.81094 10.0239 8.84674L9.94948 8.85161Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
