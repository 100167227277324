import type { IconChildrenProps } from "../icon.types";

export const Print = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M15.8346 6.66667H4.16797C2.78464 6.66667 1.66797 7.78333 1.66797 9.16667V12.5C1.66797 13.4167 2.41797 14.1667 3.33464 14.1667H5.0013V15.8333C5.0013 16.75 5.7513 17.5 6.66797 17.5H13.3346C14.2513 17.5 15.0013 16.75 15.0013 15.8333V14.1667H16.668C17.5846 14.1667 18.3346 13.4167 18.3346 12.5V9.16667C18.3346 7.78333 17.218 6.66667 15.8346 6.66667ZM12.5013 15.8333H7.5013C7.04297 15.8333 6.66797 15.4583 6.66797 15V11.6667H13.3346V15C13.3346 15.4583 12.9596 15.8333 12.5013 15.8333ZM15.8346 10C15.3763 10 15.0013 9.625 15.0013 9.16667C15.0013 8.70833 15.3763 8.33333 15.8346 8.33333C16.293 8.33333 16.668 8.70833 16.668 9.16667C16.668 9.625 16.293 10 15.8346 10ZM14.168 2.5H5.83464C5.3763 2.5 5.0013 2.875 5.0013 3.33333V5C5.0013 5.45833 5.3763 5.83333 5.83464 5.83333H14.168C14.6263 5.83333 15.0013 5.45833 15.0013 5V3.33333C15.0013 2.875 14.6263 2.5 14.168 2.5Z"
    fill={color}
    fillRule="evenodd"
  />
);
