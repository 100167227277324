import type { IconChildrenProps } from "../../icon.types";

export const SolutionsFoodAndBeverage = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M11.6667 13.2144V12.3811C11.6667 11.0003 10.5475 9.8811 9.16667 9.8811H5C3.61917 9.8811 2.5 11.0003 2.5 12.3811V13.2144"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      clipRule="evenodd"
      d="M10 18.2144H4.16667C3.24583 18.2144 2.5 17.4685 2.5 16.5477V15.7144H11.6667V16.5477C11.6667 17.4685 10.9208 18.2144 10 18.2144Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M1.66602 13.2144H12.4993"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M8.90292 9.88112L8.58542 7.49112C8.51958 6.99112 8.90792 6.54779 9.41208 6.54779H16.6662C17.1737 6.54779 17.5629 6.99696 17.4912 7.49862L16.0629 17.4986C16.0037 17.9095 15.6521 18.2145 15.2379 18.2145H13.4604"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M15.9598 3.21436H14.179C13.7657 3.21436 13.4157 3.51686 13.3548 3.92519L12.9648 6.54769"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
  </>
);
