import type { IconChildrenProps } from "../icon.types";

export const Delete = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M10 3.02087C11.2875 3.02083 12.3449 4.03843 12.3942 5.32504L12.3958 5.41671H15.4167C15.6933 5.4176 15.9209 5.63458 15.9351 5.91083C15.9493 6.18709 15.7451 6.42624 15.47 6.45546L15.4167 6.45837H14.9621L14.2525 15.8625C14.1828 16.7862 13.413 17.5001 12.4867 17.5H7.51333C6.58704 17.5001 5.81724 16.7862 5.7475 15.8625L5.0375 6.45837H4.58333C4.31624 6.4585 4.09235 6.25656 4.065 5.99087L4.0625 5.93754C4.0625 5.66796 4.2675 5.44587 4.53 5.41962L4.58333 5.41671H7.60417C7.60417 4.09353 8.67682 3.02087 10 3.02087ZM13.9175 6.45837H6.0825L6.78667 15.7842C6.81542 16.1643 7.13212 16.4582 7.51333 16.4584H12.4867C12.868 16.4584 13.185 16.1645 13.2137 15.7842L13.9179 6.45837H13.9175ZM11.3542 8.64587C11.6238 8.64587 11.8458 8.85087 11.8725 9.11337L11.875 9.16671V13.75C11.8763 14.0282 11.6587 14.2584 11.3809 14.2726C11.103 14.2869 10.863 14.0803 10.8358 13.8034L10.8333 13.75V9.16671C10.8333 8.87921 11.0667 8.64587 11.3542 8.64587ZM8.64583 8.64587C8.91542 8.64587 9.1375 8.85087 9.16417 9.11337L9.16667 9.16671V13.75C9.16796 14.0282 8.95038 14.2584 8.67253 14.2726C8.39469 14.2869 8.1547 14.0803 8.1275 13.8034L8.125 13.75V9.16671C8.125 8.87921 8.35833 8.64587 8.64583 8.64587ZM10 4.06254C9.28186 4.06249 8.68857 4.62306 8.64792 5.34004L8.64583 5.41671H11.3542C11.3542 4.66882 10.7479 4.06254 10 4.06254Z"
    fill={color}
    fillRule="evenodd"
  />
);
