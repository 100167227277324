import type { IconChildrenProps } from "../icon.types";

export const EHSSuite = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      d="M14.3961 2.5C15.4975 2.5 16.3955 3.3705 16.4397 4.46099L16.4414 4.54529V6.88302C16.4414 7.15188 16.2235 7.36984 15.9546 7.36984C15.7025 7.36984 15.4952 7.17828 15.4703 6.93279L15.4678 6.88302V4.54529C15.4678 3.97536 15.0229 3.50933 14.4614 3.4756L14.3961 3.47364H5.04529C4.47535 3.47364 4.00933 3.91855 3.9756 4.48001L3.97364 4.54529V15.4546C3.97364 16.0245 4.41855 16.4906 4.98001 16.5243L5.04529 16.5263H8.16224C8.43111 16.5263 8.64906 16.7442 8.64906 17.0131C8.64906 17.2651 8.4575 17.4725 8.21202 17.4974L8.16224 17.4999H5.04529C3.94394 17.4999 3.04592 16.6294 3.00171 15.5389L3 15.4546V4.54529C3 3.44395 3.87049 2.54592 4.96098 2.50171L5.04529 2.5H14.3961Z"
      fill={color}
    />
    <path
      d="M13.0479 7C13.5737 7 14 7.21796 14 7.48682C14 7.73888 13.6254 7.9462 13.1453 7.97113L13.0479 7.97364H6.95208C6.42626 7.97364 6 7.75568 6 7.48682C6 7.23476 6.37464 7.02744 6.85474 7.00251L6.95208 7H13.0479Z"
      fill={color}
    />
    <path
      d="M9.52396 10C9.78687 10 10 10.218 10 10.4868C10 10.7389 9.81268 10.9462 9.57263 10.9711L9.52396 10.9736H6.47604C6.21313 10.9736 6 10.7557 6 10.4868C6 10.2348 6.18732 10.0274 6.42737 10.0025L6.47604 10H9.52396Z"
      fill={color}
    />
    <path
      d="M7.76198 13C7.89344 13 8 13.218 8 13.4868C8 13.7389 7.90634 13.9462 7.78632 13.9711L7.76198 13.9736H6.23802C6.10657 13.9736 6 13.7557 6 13.4868C6 13.2348 6.09366 13.0274 6.21368 13.0025L6.23802 13H7.76198Z"
      fill={color}
    />
    <path
      d="M14.748 15.918H13.834L13.2832 12.5898V10.1699H15.252V12.5898L14.748 15.918ZM13.2363 19V16.8613H15.3691V19H13.2363Z"
      fill={accentColor}
    />
  </>
);
