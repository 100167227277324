export type AuthEnvironments = "local" | "production" | "staging";

export type AuthConfig = Partial<Record<AuthEnvironments, AuthConfigOptions>>;

export type AuthConfigOptions = {
  audience: string;
  redirectUri: string;
  tenants: Record<AuthTenant, AuthTenantConfig>;
  url: string;
};

export type AuthCookieConfig = {
  appName: string;
  appUrl: string;
};

export enum AuthSearchParam {
  appName = "name",
  appUrl = "url",
  redirect = "to",
}

export type AuthStorage = {
  appName: string;
  appUrl: string;
  emailPasswordLogin?: boolean;
  internalRedirect?: string;
  redirect?: string;
  ssoLogin?: boolean;
  tenant: AuthTenant;
};

export enum AuthTenant {
  eu = "eu",
  us = "us",
}

export type AuthTenantConfig = {
  clientId: string;
  domain: string;
};

export type RedirectToAppOptions = {
  appName: string;
  appUrl: string;
  redirect?: string;
  tenant: AuthTenant;
};

export type RedirectToAuthOptions = {
  redirect?: string;
};
