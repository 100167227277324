import { clsx } from "clsx";
import { type CSSProperties, forwardRef } from "react";
import { formatVarProp } from "~/utils/css";
import { CardContext, initialCardContextValue } from "./card.context";
import type { CardProps } from "./card.types";
import css from "./card.module.css";

export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      backgroundColor,
      children,
      className,
      classNames,
      dropdown,
      fullHeight = false,
      fullWidth = false,
      pipeColors,
      size = initialCardContextValue.size,
      style,
      ...divProps
    },
    ref,
  ) => (
    <CardContext.Provider value={{ size }}>
      <div
        data-testid="card"
        {...divProps}
        className={clsx(
          className,
          classNames?.root,
          css.root,
          css[size],
          dropdown && css.dropdown,
          fullHeight && css.fullHeight,
          fullWidth && css.fullWidth,
          pipeColors?.length && css.hasPipes,
        )}
        style={{
          ...style,
          backgroundColor: backgroundColor
            ? formatVarProp(backgroundColor)
            : undefined,
        }}
        ref={ref}
      >
        {pipeColors?.length ? (
          <div data-testid="card-pipes" className={css.pipes}>
            <div className={clsx(classNames?.pipe, css.pipe)}>
              {pipeColors.map((color, index) => (
                <div
                  data-testid="card-pipe"
                  className={css.pipeItem}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${color ?? "pipe"}-${index}`}
                  style={{ "--pipe-background-color": color } as CSSProperties}
                />
              ))}
            </div>
          </div>
        ) : null}
        <div className={clsx(classNames?.children, css.children)}>
          {children}
        </div>
      </div>
    </CardContext.Provider>
  ),
);

Card.displayName = "Card";
