import { AR_CATEGORY_PANDEMIC } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../icon.types";

export const CategoryPandemic = ({ color }: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();

  return (
    <>
      <path
        clipRule="evenodd"
        d="M10.9634 3.40425L11.1719 3.46465L14.7494 4.60424C15.6285 4.88319 16.2415 5.67001 16.3057 6.58124L16.3113 6.7342L16.312 7.15981C17.6945 7.36751 18.7544 8.5603 18.7544 10.0006C18.7544 11.441 17.6945 12.6337 16.312 12.8414L16.3113 13.2671C16.3113 14.1913 15.7441 15.0149 14.8937 15.3499L14.7494 15.4012L11.1711 16.537C10.4786 16.7578 9.73941 16.7779 9.03889 16.5973L8.82997 16.5372L5.25663 15.4017C4.37111 15.1231 3.75427 14.3297 3.69403 13.4128L3.68919 13.2671L3.6893 12.8415C2.30646 12.6342 1.24609 11.4413 1.24609 10.0006C1.24609 8.56001 2.30647 7.36705 3.6893 7.15969L3.68919 6.73364C3.69004 5.80538 4.26264 4.9795 5.11729 4.6484L5.25454 4.6003L8.83505 3.45981C9.44976 3.26564 10.1009 3.22947 10.7291 3.3512L10.9634 3.40425ZM9.37836 4.57523L9.20423 4.6237L5.62303 5.7644C5.23444 5.88664 4.95965 6.22683 4.91624 6.62445L4.91023 6.7342V13.2665C4.9106 13.6766 5.15644 14.0428 5.52901 14.202L5.62473 14.2374L9.20034 15.3737C9.66291 15.5212 10.156 15.5375 10.6259 15.4227L10.8009 15.3734L14.3801 14.2374C14.7677 14.1143 15.0414 13.7742 15.0844 13.3768L15.0903 13.2671V6.73582C15.0892 6.33003 14.8479 5.96753 14.4822 5.80665L14.3794 5.76789L10.7993 4.62744C10.3957 4.49809 9.96853 4.46865 9.55476 4.53898L9.37836 4.57523ZM12.2624 10.7477C12.5996 10.7477 12.8729 11.021 12.8729 11.3582C12.8729 11.6673 12.6433 11.9227 12.3453 11.9631L12.2624 11.9687H7.73783C7.40065 11.9687 7.12731 11.6954 7.12731 11.3582C7.12731 11.0491 7.35699 10.7937 7.65498 10.7532L7.73783 10.7477H12.2624ZM16.3119 8.40542L16.3118 11.5958C17.0155 11.4065 17.5334 10.7641 17.5334 10.0006C17.5334 9.23722 17.0155 8.59475 16.3119 8.40542ZM2.46713 10.0006C2.46713 10.7642 2.98522 11.4067 3.68907 11.5959L3.68874 8.4054C2.98507 8.59469 2.46713 9.23717 2.46713 10.0006ZM12.2624 8.03257C12.5996 8.03257 12.8729 8.30591 12.8729 8.64309C12.8729 8.95217 12.6433 9.20761 12.3453 9.24804L12.2624 9.25361H7.73783C7.40065 9.25361 7.12731 8.98027 7.12731 8.64309C7.12731 8.33401 7.35699 8.07857 7.65498 8.03815L7.73783 8.03257H12.2624Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.8726 8.64311C12.8726 8.30593 12.5993 8.03259 12.2621 8.03259H7.73747L7.65463 8.03817C7.35663 8.07859 7.12695 8.33403 7.12695 8.64311C7.12695 8.98029 7.40029 9.25363 7.73747 9.25363H12.2621L12.3449 9.24806C12.6429 9.20763 12.8726 8.95219 12.8726 8.64311ZM12.8726 11.3582C12.8726 11.021 12.5993 10.7477 12.2621 10.7477H7.73747L7.65463 10.7533C7.35663 10.7937 7.12695 11.0491 7.12695 11.3582C7.12695 11.6954 7.40029 11.9687 7.73747 11.9687H12.2621L12.3449 11.9632C12.6429 11.9227 12.8726 11.6673 12.8726 11.3582Z"
        fill={getAlertCategoryColor(AR_CATEGORY_PANDEMIC)}
        fillRule="evenodd"
      />
    </>
  );
};
