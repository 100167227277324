import type { IconChildrenProps } from "../../icon.types";

export const SolutionsIndustrialMachinery = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M9.35069 10.6684C8.89319 11.1768 8.23736 11.5018 7.49986 11.5018C5.97236 11.5018 4.76486 10.1318 5.03902 8.5526C5.21486 7.5426 6.04569 6.71676 7.05569 6.5401C7.97152 6.38093 8.80319 6.72676 9.35069 7.3351H16.6665C17.1265 7.3351 17.4999 7.70843 17.4999 8.16843V9.8351C17.4999 10.2951 17.1265 10.6684 16.6665 10.6684H9.35069Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M12.5 10.6683V16.5017C12.5 17.4225 11.7542 18.1683 10.8333 18.1683H4.16667C3.24583 18.1683 2.5 17.4225 2.5 16.5017V4.835C2.5 3.91417 3.24583 3.16833 4.16667 3.16833H10.8333C11.7542 3.16833 12.5 3.91417 12.5 4.835V7.335"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M4.9987 14.8267C4.9962 14.8267 4.99453 14.8284 4.99453 14.8309C4.99453 14.8334 4.9962 14.8351 4.9987 14.8351C5.0012 14.8351 5.00286 14.8334 5.00286 14.8309C5.00286 14.8284 5.0012 14.8267 4.9987 14.8267"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M7.50456 14.8267C7.50206 14.8267 7.50039 14.8284 7.50039 14.8309C7.50039 14.8334 7.50289 14.8351 7.50456 14.8351C7.50706 14.8351 7.50872 14.8334 7.50872 14.8309C7.50872 14.8284 7.50706 14.8267 7.50456 14.8267"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M9.9987 14.8267C9.9962 14.8267 9.99453 14.8284 9.99453 14.8309C9.99453 14.8334 9.9962 14.8351 9.9987 14.8351C10.0012 14.8351 10.0029 14.8334 10.0029 14.8309C10.0029 14.8284 10.0012 14.8267 9.9987 14.8267"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
  </>
);
