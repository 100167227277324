/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Ported from react-virtualized-auto-sizer
 *
 * https://github.com/bvaughn/react-virtualized-auto-sizer/blob/master/src/vendor/detectElementResize.js
 */

const TIMEOUT_DURATION = 20;

// Counter intuitively, environments that support animation frames can be trickier.
// Chrome's "Throttle non-visible cross-origin iframes" flag can prevent rAFs from being called.
// In this case, we should fallback to a setTimeout() implementation.
const cancelFrame = ([animationFrameID, timeoutID]: [
  number,
  number | undefined,
]) => {
  cancelAnimationFrame(animationFrameID);
  clearTimeout(timeoutID);
};

const requestFrame = (callback: () => void) => {
  const animationFrameID = requestAnimationFrame(() => {
    clearTimeout(timeoutID);
    callback();
  });

  const timeoutID = setTimeout(() => {
    cancelAnimationFrame(animationFrameID);
    callback();
  }, TIMEOUT_DURATION);

  return [animationFrameID, timeoutID];
};

export const createDetectElementResize = () => {
  const domPrefixes = "Webkit Moz O ms".split(" ");
  const startEvents =
    "webkitAnimationStart animationstart oAnimationStart MSAnimationStart".split(
      " ",
    );

  /* Detect CSS Animations support to detect element display/re-attach */
  let animation = false;
  let keyframeprefix = "";
  let animationStartEvent = "animationstart";

  let pfx = "";

  const elm = document.createElement("fakeelement");
  if (elm.style.animationName !== undefined) {
    animation = true;
  }

  if (!animation) {
    for (let i = 0; i < domPrefixes.length; i++) {
      if ((elm.style as any)[`${domPrefixes[i]}AnimationName`] !== undefined) {
        pfx = domPrefixes[i];
        keyframeprefix = `-${pfx.toLowerCase()}-`;
        animationStartEvent = startEvents[i];
        animation = true;
        break;
      }
    }
  }

  const animationName = "resizeanim";
  const animationKeyframes = `@${keyframeprefix}keyframes ${animationName} { from { opacity: 0; } to { opacity: 0; } } `;
  const animationStyle = `${keyframeprefix}animation: 1ms ${animationName};`;

  const resetTriggers = (element: HTMLElement) => {
    const triggers = (element as any).__resizeTriggers__;
    const expand = triggers.firstElementChild;
    const contract = triggers.lastElementChild;
    const expandChild = expand.firstElementChild;

    contract.scrollLeft = contract.scrollWidth;
    contract.scrollTop = contract.scrollHeight;
    expandChild.style.width = `${expand.offsetWidth + 1}px`;
    expandChild.style.height = `${expand.offsetHeight + 1}px`;
    expand.scrollLeft = expand.scrollWidth;
    expand.scrollTop = expand.scrollHeight;
  };

  const checkTriggers = (element: HTMLElement) =>
    element.offsetWidth !== (element as any).__resizeLast__.width ||
    element.offsetHeight !== (element as any).__resizeLast__.height;

  const scrollListener = function (this: any, e: any) {
    // Don't measure (which forces) reflow for scrolls that happen inside of children!
    if (
      e.target.className &&
      typeof e.target.className.indexOf === "function" &&
      e.target.className.indexOf("contract-trigger") < 0 &&
      e.target.className.indexOf("expand-trigger") < 0
    ) {
      return;
    }

    const element = this;

    resetTriggers(this);
    if (this.__resizeRAF__) {
      cancelFrame(this.__resizeRAF__);
    }
    this.__resizeRAF__ = requestFrame(() => {
      if (checkTriggers(element)) {
        element.__resizeLast__.width = element.offsetWidth;
        element.__resizeLast__.height = element.offsetHeight;
        element.__resizeListeners__.forEach((fn: any) => {
          fn.call(element, e);
        });
      }
    });
  };

  const createStyles = function () {
    if (!document.getElementById("detectElementResize")) {
      //opacity:0 works around a chrome bug https://code.google.com/p/chromium/issues/detail?id=286360
      const css = `${
        animationKeyframes ? animationKeyframes : ""
      }.resize-triggers { ${
        animationStyle ? animationStyle : ""
      }visibility: hidden; opacity: 0; } .resize-triggers, .resize-triggers > div, .contract-trigger:before { content: " "; display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden; z-index: -1; } .resize-triggers > div { background: #eee; overflow: auto; } .contract-trigger:before { width: 200%; height: 200%; }`;

      const head = document.head || document.getElementsByTagName("head")[0];
      const style = document.createElement("style");

      style.id = "detectElementResize";

      style.appendChild(document.createTextNode(css));
      head.appendChild(style);
    }
  };

  const addResizeListener = function (element: HTMLElement, fn: () => void) {
    if (!(element as any).__resizeTriggers__) {
      const elementStyle = window.getComputedStyle(element);
      if (elementStyle && elementStyle.position === "static") {
        element.style.position = "relative";
      }
      createStyles();
      (element as any).__resizeLast__ = {};
      (element as any).__resizeListeners__ = [];
      ((element as any).__resizeTriggers__ =
        document.createElement("div")).className = "resize-triggers";
      const expandTrigger = document.createElement("div");
      expandTrigger.className = "expand-trigger";
      expandTrigger.appendChild(document.createElement("div"));
      const contractTrigger = document.createElement("div");
      contractTrigger.className = "contract-trigger";
      (element as any).__resizeTriggers__.appendChild(expandTrigger);
      (element as any).__resizeTriggers__.appendChild(contractTrigger);
      element.appendChild((element as any).__resizeTriggers__);
      resetTriggers(element);
      element.addEventListener("scroll", scrollListener, true);

      /* Listen for a css animation to detect element display/re-attach */
      if (animationStartEvent) {
        (element as any).__resizeTriggers__.__animationListener__ = (
          e: any,
        ) => {
          if (e.animationName === animationName) {
            resetTriggers(element);
          }
        };
        (element as any).__resizeTriggers__.addEventListener(
          animationStartEvent,
          (element as any).__resizeTriggers__.__animationListener__,
        );
      }
    }
    (element as any).__resizeListeners__.push(fn);
  };

  const removeResizeListener = function (element: HTMLElement, fn: () => void) {
    (element as any).__resizeListeners__.splice(
      (element as any).__resizeListeners__.indexOf(fn),
      1,
    );
    if (!(element as any).__resizeListeners__.length) {
      element.removeEventListener("scroll", scrollListener, true);
      if ((element as any).__resizeTriggers__.__animationListener__) {
        (element as any).__resizeTriggers__.removeEventListener(
          animationStartEvent,
          (element as any).__resizeTriggers__.__animationListener__,
        );
        (element as any).__resizeTriggers__.__animationListener__ = null;
      }
      try {
        (element as any).__resizeTriggers__ = !element.removeChild(
          (element as any).__resizeTriggers__,
        );
      } catch (e) {
        // Preact compat; see developit/preact-compat/issues/228
      }
    }
  };

  return {
    addResizeListener,
    removeResizeListener,
  };
};
