import type { IconChildrenProps } from "../icon.types";

export const Incident = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      d="M12.3355 9.53054L9.91882 5.22648C9.65752 4.76469 9.18294 4.50339 8.70091 4.50339C8.20605 4.50339 7.72399 4.76469 7.46269 5.22648L5.05131 9.53054C4.93718 9.73854 4.87109 9.99232 4.87109 10.2334C4.87109 11.015 5.46728 11.6593 6.29701 11.6593H11.1175C11.9473 11.6593 12.536 11.0278 12.536 10.2334C12.5232 9.99232 12.4496 9.73854 12.3355 9.53054ZM11.0973 10.9556H6.28424C5.833 10.9556 5.54172 10.6238 5.54172 10.2131C5.54172 10.0915 5.58226 9.96986 5.64307 9.85571L8.05973 5.56443C8.20162 5.30841 8.44487 5.18678 8.70091 5.18678C8.94417 5.18678 9.18737 5.30841 9.32928 5.56443L11.7384 9.85571C11.7992 9.97731 11.8398 10.099 11.8398 10.2206C11.8323 10.6313 11.5485 10.9556 11.0973 10.9556ZM8.6934 10.102C8.44487 10.102 8.23688 9.90678 8.23688 9.64549C8.23688 9.40451 8.44487 9.19651 8.6934 9.19651C8.94196 9.19651 9.14989 9.40451 9.14989 9.64549C9.14239 9.90678 8.94196 10.102 8.6934 10.102ZM8.6934 8.80912C8.51318 8.80912 8.38628 8.68223 8.38628 8.50728L8.34573 6.86704C8.34573 6.65377 8.48541 6.5066 8.7062 6.5066C8.91943 6.5066 9.05912 6.65377 9.04632 6.86704L8.99303 8.50728C9.00054 8.69503 8.87365 8.80912 8.6934 8.80912Z"
      fill={accentColor}
    />
    <path
      d="M2.49219 8.50454C2.49219 11.8509 5.21987 14.5786 8.57902 14.5786C9.93185 14.5786 11.1706 14.1304 12.1819 13.376L16.1358 17.3426C16.2935 17.5003 16.5046 17.5739 16.7156 17.5739C17.1858 17.5739 17.514 17.2232 17.514 16.7628C17.514 16.5464 17.4351 16.3481 17.2902 16.1957L13.3363 12.2494C14.1643 11.2177 14.6403 9.91823 14.6403 8.49176C14.6403 5.1454 11.9126 2.40494 8.56624 2.40494C5.21987 2.41771 2.49219 5.1454 2.49219 8.50454ZM3.61521 8.50454C3.61521 5.77189 5.83359 3.55351 8.56624 3.55351C11.2989 3.55351 13.5173 5.77189 13.5173 8.50454C13.5173 11.2372 11.2989 13.4556 8.56624 13.4556C5.83359 13.4556 3.61521 11.2372 3.61521 8.50454Z"
      fill={color}
    />
  </>
);
