import type { IconChildrenProps } from "../icon.types";

export const Safe = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M5.20943 4.06981L9.84189 2.52566C9.94452 2.49145 10.0555 2.49145 10.1581 2.52566L14.7906 4.06981C15.8114 4.4101 16.5 5.36544 16.5 6.44152V11.44C16.5 15.0298 13.5899 17.94 9.9908 17.9399L9.75665 17.9355C6.27011 17.807 3.49999 14.9391 3.5 11.44V6.44152C3.5 5.36544 4.18858 4.4101 5.20943 4.06981ZM10 3.527L5.52566 5.01849C4.91315 5.22266 4.5 5.79587 4.5 6.44152V11.44C4.49999 14.4007 6.84405 16.8275 9.78426 16.9359L10 16.94C13.0376 16.94 15.5 14.4776 15.5 11.44V6.44152C15.5 5.79587 15.0869 5.22266 14.4743 5.01849L10 3.527ZM8.81236 11.1569L12.4202 7.56583C12.7131 7.27295 13.1879 7.27295 13.4808 7.56583C13.7737 7.85872 13.7737 8.33361 13.4804 8.62688L9.69431 12.3963L9.12862 12.962C8.95289 13.1378 8.66799 13.1378 8.49224 12.962L7.92482 12.3946L6.51923 10.9618C6.22635 10.6689 6.22635 10.194 6.51923 9.90112C6.81211 9.60824 7.287 9.60824 7.58156 9.90282L8.81236 11.1569Z"
    fill={color}
    fillRule="evenodd"
  />
);
