import type { IconChildrenProps } from "../icon.types";

export const ChartComplianceTask = ({
  accentColor,
  color,
}: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M6.44437 2C6.71699 2 6.938 2.22101 6.938 2.49363C6.938 2.74922 6.74376 2.95944 6.49484 2.98472L6.44437 2.98727H4.86407C3.85634 2.98727 3.0341 3.78151 2.9892 4.77814L2.98727 4.86404V8.02464C2.98727 8.29727 2.76626 8.51828 2.49363 8.51828C2.23805 8.51828 2.02783 8.32403 2.00255 8.07511L2 8.02464V4.86404C2 3.31393 3.23152 2.05141 4.76953 2.00153L4.86407 2H6.44437ZM17.4444 12.2719C17.7 12.2719 17.9102 12.4662 17.9354 12.7151L17.938 12.7655V15.136C17.938 16.6861 16.7065 17.9486 15.1685 17.9985L15.0739 18H13.4936C13.221 18 13 17.779 13 17.5064C13 17.2508 13.1942 17.0406 13.4432 17.0153L13.4936 17.0128H15.0739C16.0817 17.0128 16.9039 16.2185 16.9488 15.2219L16.9507 15.136V12.7655C16.9507 12.4929 17.1717 12.2719 17.4444 12.2719ZM4.86668 18.0001L4.85989 18H2.49363C2.22101 18 2 17.779 2 17.5064V14.5434C2 14.2707 2.22101 14.0497 2.49363 14.0497H4.37305V11.1853C4.37305 10.9127 4.59405 10.6917 4.86668 10.6917H7.23711C7.50974 10.6917 7.73074 10.9127 7.73074 11.1853V12.4694H9.60603C9.87866 12.4694 10.0997 12.6904 10.0997 12.9631V17.5064C10.0997 17.779 9.87866 18 9.60603 18H7.2447C7.24218 18.0001 7.23965 18.0001 7.23711 18.0001H4.86668ZM6.74273 12.9353C6.74223 12.9445 6.74197 12.9538 6.74197 12.9631V17.0124H5.35938V11.6788H6.74273V12.9353ZM7.73074 17.0124H9.11166V13.4567H7.73074V17.0124ZM4.36929 15.0367V17.0124H2.98633V15.0367H4.36929Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M15.8522 2.13682C15.579 1.93411 15.1913 1.95665 14.9436 2.20442L14.876 2.28269C14.6733 2.55591 14.6958 2.94362 14.9436 3.19137L15.6144 3.86158L11.7135 3.86177L11.6188 3.86814C11.2782 3.91435 11.0156 4.20634 11.0156 4.55965C11.0156 4.94508 11.3281 5.25754 11.7135 5.25754H17.2989L17.3944 5.25144C17.9514 5.17927 18.2089 4.48266 17.7924 4.06616L15.9305 2.20439L15.8522 2.13682ZM17.9982 8.28333C17.9982 7.8979 17.6857 7.58545 17.3003 7.58545H11.7149L11.6194 7.59154C11.0625 7.66371 10.8049 8.36031 11.2214 8.77681L13.0832 10.6386L13.1615 10.7062C13.4347 10.9089 13.8224 10.8863 14.0702 10.6386L14.1377 10.5603C14.3404 10.2871 14.3179 9.89939 14.0702 9.65162L13.3984 8.98038L17.3003 8.98122L17.395 8.97485C17.7356 8.92864 17.9982 8.63664 17.9982 8.28333Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
