import type { IconChildrenProps } from "../icon.types";

export const SmartPlug = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M8.76453 2.07129C12.6738 1.46448 16.4418 3.79804 17.6404 7.56817C18.839 11.3383 17.1103 15.4193 13.5683 17.1814C13.309 17.3104 12.9943 17.2048 12.8654 16.9455C12.7364 16.6863 12.842 16.3716 13.1013 16.2426C16.1803 14.7109 17.6831 11.1632 16.6411 7.88585C15.5992 4.60848 12.3237 2.57992 8.92536 3.10741C5.52705 3.63491 3.02059 6.56096 3.02119 9.99996C3.01955 11.9323 3.78734 13.7862 5.15492 15.1513C6.14759 16.1401 7.49151 16.6954 8.89253 16.6955C9.21455 16.6952 9.47554 16.4342 9.47578 16.1122V12.7095C9.47578 12.42 9.71051 12.1853 10 12.1853C10.2896 12.1853 10.5243 12.42 10.5243 12.7095V16.1126C10.5236 17.0131 9.79381 17.7433 8.89332 17.744C7.21452 17.744 5.6038 17.0786 4.41453 15.8938C2.84968 14.3318 1.97079 12.2106 1.97266 9.99961C1.97266 9.99943 1.97266 9.99979 1.97266 9.99961C1.97223 6.04378 4.85546 2.67806 8.76453 2.07129Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M7.91654 9.48213C7.74565 9.48213 7.60713 9.62066 7.60713 9.79155V10.8337C7.60713 11.5801 8.21222 12.1852 8.95864 12.1852H11.0428C11.7893 12.1852 12.3944 11.5801 12.3944 10.8337V9.79155C12.3944 9.62066 12.2558 9.48213 12.0849 9.48213H7.91654ZM6.55859 9.79155C6.55859 9.04158 7.16657 8.4336 7.91654 8.4336H12.0849C12.8349 8.4336 13.4429 9.04158 13.4429 9.79155V10.8337C13.4429 12.1592 12.3684 13.2337 11.0428 13.2337H8.95864C7.63313 13.2337 6.55859 12.1592 6.55859 10.8337V9.79155Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.3631 6.5578C8.65264 6.5578 8.88737 6.79252 8.88737 7.08207V8.95785C8.88737 9.24739 8.65264 9.48211 8.3631 9.48211C8.07356 9.48211 7.83883 9.24739 7.83883 8.95785V7.08207C7.83883 6.79252 8.07356 6.5578 8.3631 6.5578Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11.6381 6.5578C11.9277 6.5578 12.1624 6.79252 12.1624 7.08207V8.95785C12.1624 9.24739 11.9277 9.48211 11.6381 9.48211C11.3486 9.48211 11.1139 9.24739 11.1139 8.95785V7.08207C11.1139 6.79252 11.3486 6.5578 11.6381 6.5578Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
