import { clsx } from "clsx";
import {
  forwardRef,
  useCallback,
  useMemo,
  type MouseEventHandler,
} from "react";
import { Link } from "react-router-dom";
import { Button } from "../button";
import type { LinkButtonProps } from "./link-button.types";
import css from "../button-deprecated/button-deprecated.module.css";

const protocolRegex = /^https?:\/\//;

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  (props, ref) => {
    const { to, buttonStyleProps, ...buttonProps } = props;

    const classNames = clsx(
      css.root,
      buttonProps.className,
      buttonProps.fullWidth && css.fullWidth,
    );

    const actualTo = useMemo(() => {
      if (typeof to === "string") {
        return to;
      }

      if (to.pathname) {
        if (to.search) {
          if (to.hash) {
            return `${to.pathname}?${to.search}#${to.hash}`;
          }
          return `${to.pathname}?${to.search}`;
        }
        return to.pathname;
      }
    }, [to]);

    const StuntButton = useCallback(
      () => <Button {...buttonStyleProps} {...buttonProps} />,
      [buttonProps, buttonStyleProps],
    );

    const onClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
      (e) => {
        if (buttonProps.disabled) {
          e.preventDefault();
        }
      },
      [buttonProps.disabled],
    );

    if (!actualTo) {
      return null;
    }

    if (protocolRegex.test(actualTo) || actualTo.startsWith("mailto:")) {
      return (
        <a
          ref={ref}
          className={classNames}
          href={actualTo}
          rel="noopener"
          onClick={onClick}
        >
          <StuntButton />
        </a>
      );
    }

    return (
      <Button {...buttonStyleProps} {...buttonProps}>
        <Link ref={ref} className={classNames} to={to} onClick={onClick}>
          {buttonProps.children}
        </Link>
      </Button>
    );
  },
);

LinkButton.displayName = "LinkButton";
