import { clsx } from "clsx";
import { Link } from "react-router-dom";
import { AuthButton } from "~/components/auth-button";
import { Input } from "~/components/input";
import { Text } from "~/components/text";
import { useUi } from "~/features/ui/ui.hooks";
import { FormCard } from "../form-card/form-card";
import { useLoginForm } from "./login-form.hooks";
import type { LoginFormProps } from "./login-form.types";
import css from "./login-form.module.css";

export const LoginForm = (props: LoginFormProps) => {
  const {
    email,
    emailError,
    emailPasswordLogin,
    fromSignUp,
    loading,
    loginError,
    onEmailChange,
    onPasswordChange,
    onPressSubmit,
    onSubmit,
    password,
    passwordError,
  } = useLoginForm(props);

  const { isMobile } = useUi();

  return (
    <FormCard title="Login">
      {fromSignUp ? (
        <div className={css.success}>
          <Text
            align="center"
            color="success-20"
            fontWeight="text-semibold"
            fontSize={isMobile ? "text-md" : "text-lg"}
            fullWidth
          >
            {`Sign up was successful! You may now login.`}
          </Text>
        </div>
      ) : null}
      <form onSubmit={onSubmit}>
        <Input
          autoCapitalize="off"
          autoComplete="email"
          autoCorrect="off"
          className={css.input}
          data-testid="login-input-email"
          disabled={emailPasswordLogin || loading}
          error={emailError}
          fullWidth
          name="email"
          onChange={onEmailChange}
          placeholder="Email"
          shape="rounded"
          spellCheck={false}
          type="email"
          value={email}
        />
        {emailPasswordLogin ? (
          <Input
            autoCapitalize="off"
            autoComplete="current-password"
            autoCorrect="off"
            className={clsx(css.input, css.secondInput)}
            data-testid="login-input-password"
            disabled={loading}
            error={passwordError}
            fullWidth
            name="password"
            onChange={onPasswordChange}
            placeholder="Password"
            shape="rounded"
            spellCheck={false}
            type="password"
            value={password}
          />
        ) : null}
        {loginError ? (
          <div className={css.error}>
            <Text
              align="center"
              color="red-20"
              fontWeight="text-semibold"
              fontSize={isMobile ? "text-md" : "text-lg"}
              fullWidth
            >
              {loginError}
            </Text>
          </div>
        ) : null}
        <AuthButton
          className={css.button}
          data-testid={
            emailPasswordLogin ? "login-button-login" : "login-button-next"
          }
          disabled={
            !email ||
            emailError ||
            passwordError ||
            loading ||
            (emailPasswordLogin && !password)
          }
          fullWidth
          loading={loading}
          onPress={onPressSubmit}
        >
          {emailPasswordLogin ? "Login" : "Next"}
        </AuthButton>
      </form>
      {emailPasswordLogin ? (
        <div className={css.link}>
          <Link data-testid="login-link-forgot-password" to="/change-password">
            <Text
              align="center"
              fontSize={isMobile ? "text-sm" : "text-md"}
              fullWidth
            >
              Forgot Password
            </Text>
          </Link>
        </div>
      ) : null}
    </FormCard>
  );
};
