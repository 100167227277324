import type { IconChildrenProps } from "../icon.types";

export const UseCase = ({ color, oppositeColor }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M10.0078 2.5H6.4784C4.28551 2.5 2.50781 4.27769 2.50781 6.47059V13.5294C2.50781 15.7223 4.28551 17.5 6.4784 17.5H13.5372C15.7301 17.5 17.5078 15.7223 17.5078 13.5294V10V6.47059C17.5078 4.27769 15.7301 2.5 13.5372 2.5H13.2246H10.0078ZM10.0078 3.38235H6.4784C4.77282 3.38235 3.39017 4.765 3.39017 6.47059V13.5294C3.39017 15.235 4.77282 16.6176 6.4784 16.6176H13.5372C15.2428 16.6176 16.6255 15.235 16.6255 13.5294V10H12.0078C10.9032 10 10.0078 9.10457 10.0078 8V3.38235ZM8.50781 7C8.50781 7.82843 7.83624 8.5 7.00781 8.5C6.17939 8.5 5.50781 7.82843 5.50781 7C5.50781 6.17157 6.17939 5.5 7.00781 5.5C7.83624 5.5 8.50781 6.17157 8.50781 7ZM13.0078 14.5C13.8362 14.5 14.5078 13.8284 14.5078 13C14.5078 12.1716 13.8362 11.5 13.0078 11.5C12.1794 11.5 11.5078 12.1716 11.5078 13C11.5078 13.8284 12.1794 14.5 13.0078 14.5ZM8.50781 13C8.50781 13.8284 7.83624 14.5 7.00781 14.5C6.17939 14.5 5.50781 13.8284 5.50781 13C5.50781 12.1716 6.17939 11.5 7.00781 11.5C7.83624 11.5 8.50781 12.1716 8.50781 13Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M17.5078 10V6.47059C17.5078 4.27769 15.7301 2.5 13.5372 2.5H10.0078V3.38235H13.5372C15.2428 3.38235 16.6255 4.765 16.6255 6.47059V10H17.5078Z"
      fill={oppositeColor} // This should be oppositeColor
      fillRule="evenodd"
    />
    <circle
      cx="13.0078"
      cy="7"
      fill={oppositeColor} // This should be oppositeColor
      r="1.5"
    />
  </>
);
