import type { IconChildrenProps } from "../icon.types";

export const Abstract = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M8.5197 2.46002L6.46363 4.51609C5.64619 5.33466 5.64619 6.66157 6.46363 7.479L8.51944 9.53481C9.33801 10.3522 10.6649 10.3522 11.4824 9.53481L13.5382 7.479C14.3556 6.66043 14.3556 5.33352 13.5382 4.51609L11.4821 2.46002C10.6638 1.64289 9.33796 1.64289 8.5197 2.46002ZM10.7339 3.20924L12.7895 5.26479C13.1935 5.66881 13.1935 6.32571 12.7892 6.73056L10.7336 8.78611C10.3296 9.19014 9.67272 9.19014 9.26788 8.78585L7.21233 6.7303C6.8083 6.32628 6.8083 5.66937 7.21259 5.26453L9.26814 3.20898C9.67277 2.80491 10.329 2.80491 10.7339 3.20924ZM5.42648 10.9952L5.31504 10.997C3.38077 11.0609 1.84766 12.6425 1.84766 14.574L1.84943 14.6855C1.91338 16.6197 3.49499 18.1528 5.42648 18.1528L5.53792 18.1511C7.47219 18.0871 9.0053 16.5055 9.0053 14.574L9.00353 14.4626C8.93958 12.5283 7.35797 10.9952 5.42648 10.9952ZM5.3413 12.0554L5.43029 12.0538C6.78638 12.054 7.90021 13.1337 7.94507 14.4888L7.94655 14.5828C7.94648 15.9339 6.8668 17.0478 5.51166 17.0926L5.41773 17.0941C4.06658 17.094 2.95274 16.0143 2.90789 14.6592L2.90641 14.5653C2.90648 13.2141 3.98616 12.1003 5.3413 12.0554Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M16.0423 10.9943L13.105 10.9943C11.9396 10.9943 10.9941 11.9401 10.9941 13.1052V16.042C10.9941 17.2077 11.9393 18.1528 13.105 18.1528H16.0418C17.2073 18.1528 18.1526 17.2078 18.1526 16.0428V13.1061C18.1526 11.9406 17.2078 10.9955 16.0423 10.9943ZM13.105 12.0532L16.0413 12.0532C16.6226 12.0537 17.0938 12.5251 17.0938 13.1061V16.0428C17.0938 16.623 16.6226 17.094 16.0418 17.094H13.105C12.5241 17.094 12.053 16.6229 12.053 16.042V13.1052C12.053 12.5248 12.5244 12.0532 13.105 12.0532Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
