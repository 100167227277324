import type { IconChildrenProps } from "../icon.types";

export const Alert = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M9.99845 18.25C10.8235 18.25 11.491 17.5825 11.491 16.7575H8.50595C8.50595 17.5825 9.17345 18.25 9.99845 18.25ZM16.216 14.7175L15.2485 13.75V9.25C15.2485 6.7375 13.4785 4.6375 11.1235 4.1275V3.25C11.1235 2.6275 10.621 2.125 9.99845 2.125C9.37595 2.125 8.87345 2.6275 8.87345 3.25V4.1275C6.51845 4.6375 4.74845 6.7375 4.74845 9.25V13.75L3.78095 14.7175C3.30845 15.19 3.63845 16 4.30595 16H15.6835C16.3585 16 16.6885 15.19 16.216 14.7175ZM10.7485 13H9.24845V11.5H10.7485V13ZM10.7485 9.25C10.7485 9.6625 10.411 10 9.99845 10C9.58595 10 9.24845 9.6625 9.24845 9.25V7.75C9.24845 7.3375 9.58595 7 9.99845 7C10.411 7 10.7485 7.3375 10.7485 7.75V9.25Z"
    fillRule="evenodd"
    fill={color}
  />
);
