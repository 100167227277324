import { type CSSProperties, useContext, useMemo } from "react";
import { formatVarProp } from "~/utils/css";
import { ThemeContext } from "./theme.context";
import type { ThemeTypographyStyleProps } from "./theme.types";

export const useTheme = () => useContext(ThemeContext);

export const useThemeStyle = ({
  backgroundColor,
  color,
  fontSize,
  fontWeight,
}: ThemeTypographyStyleProps) =>
  useMemo(() => {
    const style: CSSProperties = {};

    if (backgroundColor) {
      style.backgroundColor = formatVarProp(backgroundColor);
    }

    if (color) {
      style.color = formatVarProp(color);
    }

    if (fontSize) {
      style.fontSize = formatVarProp(fontSize);
    }

    if (fontWeight) {
      style.fontWeight = formatVarProp(fontWeight);
    }

    return Object.keys(style).length ? style : undefined;
  }, [backgroundColor, color, fontSize, fontWeight]);
