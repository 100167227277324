import type { IconChildrenProps } from "../../icon.types";

export const HazardsWorkingAtHeights = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M2.49653 13.9585C2.86244 13.9585 3.15907 14.2551 3.15907 14.621V16.2884C3.15907 16.8433 3.60894 17.2932 4.16389 17.2932H8.33229C8.6982 17.2932 8.99484 17.5898 8.99484 17.9557C8.99484 18.3217 8.6982 18.6183 8.33229 18.6183H4.16389C2.87712 18.6183 1.83398 17.5752 1.83398 16.2884V14.621C1.83398 14.2551 2.13062 13.9585 2.49653 13.9585Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.33739 2.94949C9.33739 2.58358 9.63402 2.28695 9.99993 2.28695H15.8357C17.1225 2.28695 18.1656 3.33008 18.1656 4.61685V8.78525C18.1656 9.15117 17.869 9.4478 17.5031 9.4478C17.1371 9.4478 16.8405 9.15117 16.8405 8.78525V4.61685C16.8405 4.06191 16.3906 3.61203 15.8357 3.61203H9.99993C9.63402 3.61203 9.33739 3.3154 9.33739 2.94949Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M4.37526 7.99435H7.28863C8.66325 8.00055 9.77606 9.11337 9.78227 10.488L9.78228 10.491L9.78228 10.8491C9.78112 11.456 9.2894 11.9477 8.68247 11.9489H2.97842C2.37149 11.9477 1.87977 11.456 1.87862 10.8491L1.87861 10.488C1.88482 9.11337 2.99764 8.00056 4.37226 7.99435L4.37526 7.99435ZM4.37692 9.31943C3.7305 9.32307 3.20733 9.84623 3.2037 10.4927V10.6238H8.45719V10.4927C8.45356 9.84624 7.93039 9.32307 7.28397 9.31943H4.37692Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M3.41463 4.69867C3.41781 3.36369 4.50214 2.28382 5.83711 2.28614C7.17209 2.28846 8.25266 3.37211 8.25118 4.70708C8.2497 6.04206 7.16674 7.12332 5.83177 7.12269C5.1896 7.12238 4.57393 6.86668 4.1205 6.41195C3.66707 5.95723 3.41312 5.34082 3.41463 4.69867ZM5.83481 3.61122C5.23133 3.61017 4.74115 4.09834 4.73972 4.70182C4.73903 4.99211 4.85383 5.27075 5.05881 5.47631C5.26378 5.68187 5.5421 5.79746 5.83239 5.7976C6.43587 5.79788 6.92543 5.3091 6.9261 4.70562C6.92676 4.10214 6.43829 3.61227 5.83481 3.61122Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M15.0015 13.6152C13.9835 13.6152 13.163 14.4357 13.163 15.4537C13.163 16.4717 13.9835 17.2922 15.0015 17.2922C16.0195 17.2922 16.84 16.4717 16.84 15.4537C16.84 14.4357 16.0195 13.6152 15.0015 13.6152ZM11.8379 15.4537C11.8379 13.7039 13.2517 12.2902 15.0015 12.2902C16.7513 12.2902 18.1651 13.7039 18.1651 15.4537C18.1651 17.2036 16.7513 18.6173 15.0015 18.6173C13.2517 18.6173 11.8379 17.2036 11.8379 15.4537Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M17.2372 13.2176C17.496 13.4763 17.496 13.8958 17.2372 14.1546L13.7016 17.6902C13.4429 17.9489 13.0234 17.9489 12.7646 17.6902C12.5059 17.4315 12.5059 17.012 12.7646 16.7532L16.3003 13.2176C16.559 12.9588 16.9785 12.9588 17.2372 13.2176Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
