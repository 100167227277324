import type { IconChildrenProps } from "../icon.types";

export const Accuracy = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M14 2.5H6C5.17157 2.5 4.5 3.17157 4.5 4V4.46482C4.5 4.96635 4.75065 5.43469 5.16795 5.71289L7.27735 7.11916C7.41645 7.21189 7.5 7.36801 7.5 7.53518V8.58579C7.5 8.71839 7.44732 8.84557 7.35355 8.93934L4.93934 11.3536C4.65804 11.6349 4.5 12.0164 4.5 12.4142V12.5L4.50673 12.6166C4.56449 13.114 4.98716 13.5 5.5 13.5L9 13.499V16.5C9 17.0523 9.44772 17.5 10 17.5L10.1166 17.4933C10.614 17.4355 11 17.0128 11 16.5V13.499L14.5 13.5C15.0523 13.5 15.5 13.0523 15.5 12.5V12.4142C15.5 12.0164 15.342 11.6349 15.0607 11.3536L12.6464 8.93934C12.5527 8.84557 12.5 8.71839 12.5 8.58579V7.53518C12.5 7.36801 12.5836 7.21189 12.7226 7.11916L14.8321 5.71289C15.2493 5.43469 15.5 4.96635 15.5 4.46482V4C15.5 3.17157 14.8284 2.5 14 2.5ZM6 3.5H14C14.2761 3.5 14.5 3.72386 14.5 4V4.46482C14.5 4.63199 14.4164 4.78811 14.2774 4.88084L12.1679 6.28711C11.7507 6.56531 11.5 7.03365 11.5 7.53518V8.58579C11.5 8.98361 11.658 9.36514 11.9393 9.64645L14.3536 12.0607C14.4473 12.1544 14.5 12.2816 14.5 12.4142V12.5H5.5V12.4142C5.5 12.2816 5.55268 12.1544 5.64645 12.0607L8.06066 9.64645C8.34196 9.36514 8.5 8.98361 8.5 8.58579V7.53518C8.5 7.03365 8.24935 6.56531 7.83205 6.28711L5.72265 4.88084C5.58355 4.78811 5.5 4.63199 5.5 4.46482V4C5.5 3.72386 5.72386 3.5 6 3.5Z"
    fill={color}
    fillRule="evenodd"
  />
);
