import type { IconChildrenProps } from "../icon.types";

export const Assignee = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M15.8333 2.50001H12.35C12 1.53334 11.0833 0.833344 10 0.833344C8.91667 0.833344 8 1.53334 7.65 2.50001H4.16667C3.25 2.50001 2.5 3.25001 2.5 4.16668V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16668C17.5 3.25001 16.75 2.50001 15.8333 2.50001ZM10 2.50001C10.4583 2.50001 10.8333 2.87501 10.8333 3.33334C10.8333 3.79168 10.4583 4.16668 10 4.16668C9.54167 4.16668 9.16667 3.79168 9.16667 3.33334C9.16667 2.87501 9.54167 2.50001 10 2.50001ZM10 5.83334C11.3833 5.83334 12.5 6.95001 12.5 8.33334C12.5 9.71668 11.3833 10.8333 10 10.8333C8.61667 10.8333 7.5 9.71668 7.5 8.33334C7.5 6.95001 8.61667 5.83334 10 5.83334ZM15 15.8333H5V14.6667C5 13 8.33333 12.0833 10 12.0833C11.6667 12.0833 15 13 15 14.6667V15.8333Z"
    fill={color}
    fillRule="evenodd"
  />
);
