import type { IconChildrenProps } from "../icon.types";

export const Eye2 = ({ color }: IconChildrenProps) => (
  <g id="eye-2">
    <path
      id="Combined Shape"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.71727 2.37707C6.71727 2.08468 6.48024 1.84766 6.18786 1.84766H4.28197L4.18854 1.84941C2.88658 1.89849 1.84668 2.96896 1.84668 4.28295V6.18883L1.84941 6.24296C1.87652 6.50992 2.10198 6.71824 2.37609 6.71824C2.66848 6.71824 2.9055 6.48122 2.9055 6.18883V4.28295L2.90768 4.20481C2.94816 3.48069 3.54769 2.90648 4.28197 2.90648H6.18786L6.24199 2.90375C6.50894 2.87664 6.71727 2.65118 6.71727 2.37707ZM2.90277 13.7582C2.87566 13.4913 2.6502 13.283 2.37609 13.283C2.08371 13.283 1.84668 13.52 1.84668 13.8124V15.7182L1.84844 15.8117C1.89752 17.1136 2.96798 18.1535 4.28197 18.1535H6.18786L6.24199 18.1508C6.50894 18.1237 6.71727 17.8982 6.71727 17.6241C6.71727 17.3317 6.48024 17.0947 6.18786 17.0947H4.28197L4.20384 17.0925C3.47972 17.0521 2.9055 16.4525 2.9055 15.7182V13.8124L2.90277 13.7582ZM17.6231 13.283C17.8973 13.283 18.1227 13.4913 18.1498 13.7582L18.1526 13.8124V15.7182C18.1526 17.0322 17.1127 18.1027 15.8107 18.1518L15.7173 18.1535H13.8114C13.519 18.1535 13.282 17.9165 13.282 17.6241C13.282 17.35 13.4903 17.1246 13.7573 17.0974L13.8114 17.0947H15.7173C16.4516 17.0947 17.0511 16.5205 17.0916 15.7964L17.0937 15.7182V13.8124C17.0937 13.52 17.3308 13.283 17.6231 13.283ZM18.1508 4.18952C18.1017 2.88756 17.0313 1.84766 15.7173 1.84766H13.8114L13.7573 1.85039C13.4903 1.8775 13.282 2.10296 13.282 2.37707C13.282 2.66945 13.519 2.90648 13.8114 2.90648H15.7173L15.7954 2.90866C16.5195 2.94913 17.0937 3.54867 17.0937 4.28295V6.18883L17.0965 6.24296C17.1236 6.50992 17.349 6.71824 17.6231 6.71824C17.9155 6.71824 18.1526 6.48122 18.1526 6.18883V4.28295L18.1508 4.18952Z"
      fill={color}
    />
  </g>
);
