import { endpoints } from "~/backend/endpoints";
import { report } from "~/features/error-reporter";
import { getHttp } from "~/utils/http";
import type { SignUpRequest, SignUpReturnType } from "./sign-up-form.types";

export const signUp = async (req: SignUpRequest): Promise<SignUpReturnType> => {
  try {
    const response = await getHttp().post<{ data: unknown }>(
      endpoints.session.signUp,
      req,
    );
    return [undefined, response.data.data];
  } catch (e) {
    void report(e);
    if (e instanceof Response) {
      try {
        const data = (await e.json()) as { message?: string };
        const message = (data?.message ?? "").toLowerCase();

        switch (message) {
          case "consent not given":
            return ["Please give your consent to Terms of Service to sign up."];
          case "missing field":
            return [
              "Please make sure that you fill in all fields in the form to sign up.",
            ];
          case "invitation not found":
            return [
              "Apparently, your invitation is no longer active. Please send our customer support an email at support@intenseye.com",
            ];
          default:
            return ["Something went wrong."];
        }
      } catch {
        // Couldn't parse response, returns error below
      }
    }

    return ["Something went wrong."];
  }
};
