import { Header } from "../components/header/header";
import { AppProvider } from "./app.provider";
import { AppRoutes } from "./app.routes";
import css from "./app.module.css";

export const App = () => (
  <AppProvider>
    <div className={css.app}>
      <Header />
      <div className={css.content}>
        <AppRoutes />
      </div>
    </div>
  </AppProvider>
);
