import type { IconChildrenProps } from "../../icon.types";

export const Touch = ({ color }: IconChildrenProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.88121 2.59469C7.80262 2.30653 7.5009 2.13805 7.21625 2.22215L7.20415 2.22573C6.91599 2.30432 6.74747 2.60591 6.83157 2.89056L6.83339 2.89672L8.95376 10.3667L8.9541 10.3678C9.04452 10.6864 8.85978 11.018 8.54133 11.1087C8.22287 11.1994 7.89111 11.015 7.80004 10.6967L6.12159 4.82904C6.07516 4.66191 5.90228 4.5555 5.70983 4.60895C5.54333 4.6552 5.4371 4.82698 5.48916 5.01855L5.90475 6.46035C5.93162 6.52174 5.95473 6.5857 5.97369 6.65208L7.2901 11.2665L7.29056 11.268C7.38138 11.5864 7.19719 11.9181 6.87897 12.0094C6.56075 12.1006 6.22874 11.9169 6.13705 11.5988L6.1366 11.5972L4.78296 6.90118C4.71074 6.79064 4.5676 6.72934 4.425 6.77008L4.42076 6.77129C4.26235 6.81529 4.16184 6.97926 4.21253 7.16204L6.7318 15.5642L7.89848 19.142C8.00122 19.4571 7.8291 19.7957 7.51406 19.8985C7.19902 20.0012 6.86034 19.8291 6.75761 19.5141L5.5887 15.9294L5.58441 15.9157L3.05946 7.49462C2.83499 6.70895 3.26013 5.84948 4.09747 5.61564C4.19689 5.58739 4.29716 5.56973 4.39696 5.56203L4.3335 5.3419C4.10974 4.53635 4.5578 3.68353 5.38866 3.45273C5.50149 3.42139 5.61524 3.40323 5.72808 3.39745L5.67975 3.22718C5.4064 2.29514 5.95548 1.32589 6.88228 1.06956C7.7247 0.823921 8.59692 1.2497 8.94521 2.01862C9.0909 1.9193 9.25418 1.84136 9.43184 1.7906C10.3391 1.53139 11.2765 2.07076 11.532 2.96504L11.5323 2.9662L13.3891 9.51467C13.4795 9.83348 13.2943 10.1652 12.9755 10.2556C12.6567 10.346 12.325 10.1608 12.2346 9.84202L10.3781 3.29471C10.3781 3.29453 10.3782 3.29489 10.3781 3.29471C10.3008 3.025 10.0184 2.87103 9.7615 2.94443C9.49796 3.01973 9.33593 3.31138 9.41123 3.57493L11.1993 9.84012C11.2902 10.1587 11.1057 10.4909 10.7871 10.5819C10.4685 10.6729 10.1365 10.4884 10.0455 10.1698L7.88121 2.59469Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2532 8.13589C14.2532 8.13592 14.2532 8.13587 14.2532 8.13589L13.4216 10.0625C13.2903 10.3667 12.9372 10.5069 12.6329 10.3756C12.3287 10.2443 12.1885 9.89117 12.3199 9.58693L13.1515 7.66025C13.5512 6.73468 14.6473 6.33252 15.5534 6.79432C16.3521 7.1953 16.7366 8.1623 16.3775 9.01471L14.563 13.4914L15.6534 16.7956C15.7525 17.096 15.6014 17.4217 15.3081 17.5401L12.6332 18.619C12.3259 18.7429 11.9763 18.5943 11.8523 18.287C11.7284 17.9797 11.877 17.6301 12.1843 17.5061L14.3376 16.6376L13.3544 13.6582C13.3098 13.5231 13.3147 13.3766 13.3681 13.2448L15.2709 8.55042C15.3789 8.29607 15.2656 7.99208 15.0142 7.86639L15.0092 7.8639C14.7237 7.71783 14.3799 7.84259 14.2532 8.13589Z"
      fill={color}
    />
  </>
);
