import type { IconChildrenProps } from "../icon.types";

export const Warning = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M8.22056 3.70903C8.95761 2.23493 11.0612 2.23493 11.7983 3.70903L16.9965 14.1055C17.6614 15.4353 16.6944 16.9999 15.2077 16.9999H4.81117C3.32441 16.9999 2.35742 15.4353 3.02232 14.1055L8.22056 3.70903ZM8.60245 5.75424H11.4139L10.711 11.3771H9.30531L8.60245 5.75424ZM10.0082 15.5942C10.7845 15.5942 11.4139 14.9649 11.4139 14.1885C11.4139 13.4122 10.7845 12.7828 10.0082 12.7828C9.23181 12.7828 8.60245 13.4122 8.60245 14.1885C8.60245 14.9649 9.23181 15.5942 10.0082 15.5942Z"
    fill={color}
    fillRule="evenodd"
  />
);
