import type { IconChildrenProps } from "../icon.types";

export const DotOut = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4ZM10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6Z"
    fill={color}
    fillRule="evenodd"
  />
);
