import type { IconChildrenProps } from "../icon.types";

export const Inspection2 = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      d="M11.6493 3.33855C11.6782 3.33855 11.7062 3.33855 11.7332 3.33855C12.4501 3.33855 12.9797 3.82361 13.0476 4.52548H13.2647C14.6145 4.52548 15.2959 5.22464 15.2959 6.57899V14.3173C15.2959 15.6716 14.6145 16.3596 13.2647 16.3596H6.0273C4.68409 16.3596 4.00727 15.6716 3.99609 14.3173V6.57899C3.99609 5.22464 4.68409 4.52548 6.0273 4.52548H6.251C6.31888 3.82361 6.84842 3.33855 7.56539 3.33855C7.59706 3.33855 7.62894 3.33855 7.66082 3.33855C7.95052 2.54501 8.76694 2 9.65489 2C10.5527 2 11.3615 2.54501 11.6493 3.33855ZM13.0519 5.46358C13.0163 6.20562 12.4751 6.72507 11.7332 6.72507H7.56997C6.81728 6.72507 6.28608 6.20562 6.24703 5.46358H6.07857C5.32377 5.46358 4.93422 5.8643 4.93422 6.59673V14.2996C4.93422 15.032 5.32377 15.4327 6.07857 15.4327H13.2134C13.9682 15.4327 14.3578 15.032 14.3578 14.2996V6.59673C14.3578 5.8643 13.9682 5.46358 13.2134 5.46358H13.0519ZM10.1416 11.7496C10.1416 11.9567 9.97464 12.1236 9.76761 12.1236H6.59841C6.3979 12.1236 6.23094 11.9567 6.22438 11.7496C6.22438 11.5603 6.39134 11.3933 6.59841 11.3933H9.76761C9.97464 11.3933 10.1416 11.5425 10.1416 11.7496ZM13.0722 9.40939C13.0722 9.61641 12.9053 9.78342 12.6982 9.78342H6.59841C6.3979 9.78342 6.23094 9.61641 6.22438 9.40939C6.22438 9.20231 6.39134 9.03536 6.59841 9.03536H12.6982C12.9053 9.03536 13.0722 9.20231 13.0722 9.40939ZM8.25578 4.07199H7.56997C7.20325 4.07199 6.98112 4.29217 6.98112 4.68125V5.38236C6.98112 5.77144 7.19669 5.99162 7.56997 5.99162H11.7332C12.1065 5.99162 12.3333 5.76488 12.3333 5.38236V4.68125C12.3333 4.29217 12.1065 4.07199 11.7332 4.07199H11.054C11.0204 3.33273 10.4053 2.73344 9.65489 2.73344C8.91096 2.73344 8.28928 3.32617 8.25578 4.07199ZM10.2469 4.10553C10.2535 4.43142 9.98077 4.70408 9.64831 4.70408C9.31585 4.70408 9.04975 4.43142 9.04975 4.10553C9.04975 3.77309 9.32242 3.50043 9.64831 3.50043C9.98077 3.50043 10.2469 3.77309 10.2469 4.10553Z"
      fill={color}
    />
    <path
      d="M14.1357 14.4607C14.3297 14.4607 14.4789 14.3693 14.5992 14.1866L17.8224 9.14433C17.8848 9.04173 17.9585 8.89907 17.9585 8.77881C17.9585 8.5224 17.7356 8.34888 17.4838 8.34888C17.3346 8.34888 17.1853 8.42907 17.0828 8.6118L14.1068 13.336L12.6296 11.4789C12.4981 11.2961 12.3554 11.2449 12.2062 11.2449C11.9609 11.2449 11.7539 11.4565 11.7539 11.7083C11.7539 11.8286 11.8052 11.9666 11.8919 12.0758L13.6499 14.1977C13.7926 14.3805 13.9352 14.4607 14.1357 14.4607Z"
      fill={accentColor}
    />
  </>
);
