import type { IconChildrenProps } from "../icon.types";

export const Template = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      d="M14.3886 6.00134C14.7242 6.00134 14.9963 6.2193 14.9963 6.48816C14.9963 6.74022 14.7571 6.94754 14.4507 6.97247L14.3886 6.97498H6.60763C6.27205 6.97498 6 6.75703 6 6.48816C6 6.2361 6.2391 6.02879 6.54551 6.00386L6.60763 6.00134H14.3886Z"
      fill={accentColor}
    />
    <path
      d="M12.5245 9.00012C12.7855 9.00012 12.9971 9.21808 12.9971 9.48694C12.9971 9.739 12.8111 9.94632 12.5728 9.97125L12.5245 9.97376H6.4726C6.21159 9.97376 6 9.75581 6 9.48694C6 9.23488 6.18597 9.02757 6.42428 9.00264L6.4726 9.00012H12.5245Z"
      fill={color}
    />
    <path
      d="M10.6603 11.9989C10.8468 11.9989 10.9979 12.2169 10.9979 12.4857C10.9979 12.7378 10.8651 12.9451 10.6949 12.97L10.6603 12.9725H6.33757C6.15114 12.9725 6 12.7546 6 12.4857C6 12.2337 6.13284 12.0263 6.30306 12.0014L6.33757 11.9989H10.6603Z"
      fill={color}
    />
    <path
      clipRule="evenodd"
      d="M3.00195 4.19033C3.00195 2.98233 3.98123 2.00305 5.18924 2.00305H15.1892C16.3972 2.00305 17.3765 2.98233 17.3765 4.19033V16.2742C17.3765 17.2518 16.584 18.0443 15.6064 18.0443H5.18924C3.98123 18.0443 3.00195 17.065 3.00195 15.857V4.19033ZM5.18924 3.04428C4.55629 3.04428 4.04319 3.55739 4.04319 4.19033V15.857C4.04319 16.4899 4.55629 17.0031 5.18924 17.0031H15.6064C16.009 17.0031 16.3353 16.6767 16.3353 16.2742V4.19033C16.3353 3.55739 15.8222 3.04428 15.1892 3.04428H5.18924Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
