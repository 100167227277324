import { AR_CATEGORY_VEHICLE } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseDynamicDelimitation = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19327)">
        <path
          d="M9.99953 12.73C8.48953 12.73 7.26953 11.5 7.26953 10C7.26953 8.50002 8.49953 7.27002 9.99953 7.27002C11.4995 7.27002 12.7295 8.50002 12.7295 10C12.7295 11.5 11.4995 12.73 9.99953 12.73ZM9.99953 8.46002C9.14953 8.46002 8.46953 9.15002 8.46953 9.99002C8.46953 10.83 9.15953 11.52 9.99953 11.52C10.8395 11.52 11.5295 10.83 11.5295 9.99002C11.5295 9.15002 10.8395 8.46002 9.99953 8.46002Z"
          fill={color}
        />
        <path
          d="M5.34844 15.7599C5.08844 15.5499 4.70844 15.5899 4.50844 15.8499C4.29844 16.1099 4.33844 16.4899 4.59844 16.6899C4.73844 16.7999 4.87844 16.9099 5.02844 17.0099C5.13844 17.0799 5.25844 17.1199 5.37844 17.1199C5.56844 17.1199 5.74844 17.0299 5.86844 16.8699C6.05844 16.5999 5.99844 16.2299 5.72844 16.0299C5.59844 15.9399 5.47844 15.8499 5.35844 15.7499L5.34844 15.7599Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M7.78844 17.0599C7.63844 17.0099 7.49844 16.9599 7.34844 16.9099C7.03844 16.7899 6.68844 16.9499 6.56844 17.2499C6.44844 17.5599 6.60844 17.9099 6.90844 18.0299C7.07844 18.0899 7.24844 18.1499 7.41844 18.2099C7.47844 18.2299 7.53844 18.2399 7.59844 18.2399C7.85844 18.2399 8.08844 18.0799 8.16844 17.8199C8.26844 17.4999 8.08844 17.1699 7.77844 17.0699L7.78844 17.0599Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M7.64844 1.7199C7.47844 1.7699 7.30844 1.8199 7.13844 1.8799C6.82844 1.9899 6.65844 2.3299 6.76844 2.6499C6.85844 2.8999 7.08844 3.0499 7.33844 3.0499C7.40844 3.0499 7.46844 3.0399 7.53844 3.0199C7.68844 2.9699 7.82844 2.9199 7.97844 2.8799C8.29844 2.7899 8.47844 2.4599 8.38844 2.1399C8.29844 1.8199 7.96844 1.6399 7.64844 1.7299V1.7199Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M9.79844 2.5999H10.2684C10.2684 2.5999 10.2784 2.5999 10.2884 2.5999C10.6084 2.5999 10.8784 2.3499 10.8884 2.0199C10.8984 1.6899 10.6384 1.4099 10.3084 1.3999C10.2084 1.3999 10.0984 1.3999 10.0084 1.3999H9.77844C9.44844 1.4099 9.18844 1.6899 9.19844 2.0199C9.19844 2.3499 9.50844 2.6499 9.81844 2.5999H9.79844Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M12.0884 2.8999C12.2384 2.9399 12.3784 2.9899 12.5284 3.0399C12.5984 3.0599 12.6684 3.0799 12.7384 3.0799C12.9784 3.0799 13.2084 2.9299 13.2984 2.6899C13.4084 2.3799 13.2484 2.0299 12.9384 1.9199C12.7684 1.8599 12.5984 1.7999 12.4284 1.7499C12.1084 1.6599 11.7784 1.8399 11.6884 2.1599C11.5984 2.4799 11.7784 2.8099 12.0984 2.8999H12.0884Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M2.16844 8.2499C2.22844 8.2699 2.28844 8.2799 2.33844 8.2799C2.59844 8.2799 2.83844 8.1099 2.90844 7.8499C2.95844 7.6999 3.00844 7.5499 3.05844 7.4099C3.17844 7.0999 3.01844 6.7499 2.70844 6.6399C2.39844 6.5299 2.04844 6.6799 1.93844 6.9899C1.87844 7.1599 1.81844 7.3299 1.76844 7.4999C1.66844 7.8199 1.84844 8.1499 2.16844 8.2499Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M2.00844 10.7399C2.33844 10.7399 2.59844 10.4599 2.59844 10.1299V9.9999C2.59844 9.8899 2.59844 9.7799 2.59844 9.6699C2.60844 9.3399 2.35844 9.0599 2.02844 9.0399C1.70844 9.0399 1.41844 9.2799 1.39844 9.6099C1.39844 9.7399 1.39844 9.8699 1.39844 9.9999V10.1499C1.39844 10.4799 1.67844 10.7399 1.99844 10.7399H2.00844Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M3.42844 6.0399C3.61844 6.0399 3.80844 5.9499 3.91844 5.7799C4.00844 5.6499 4.09844 5.5299 4.19844 5.4099C4.40844 5.1499 4.35844 4.7699 4.09844 4.5699C3.83844 4.3599 3.45844 4.4099 3.25844 4.6699C3.14844 4.8099 3.03844 4.9599 2.93844 5.0999C2.74844 5.3699 2.81844 5.7499 3.08844 5.9299C3.18844 5.9999 3.30844 6.0399 3.42844 6.0399Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M5.14844 4.2399C5.27844 4.2399 5.39844 4.1999 5.50844 4.1199C5.62844 4.0299 5.75844 3.9399 5.88844 3.8499C6.16844 3.6699 6.23844 3.2899 6.05844 3.0199C5.86844 2.7399 5.49844 2.6699 5.22844 2.8499C5.07844 2.9499 4.92844 3.0499 4.78844 3.1599C4.52844 3.3599 4.47844 3.7399 4.67844 3.9999C4.79844 4.1499 4.97844 4.2399 5.15844 4.2399H5.14844Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M3.80844 14.0499C3.62844 13.7699 3.25844 13.6999 2.97844 13.8799C2.69844 14.0599 2.62844 14.4299 2.80844 14.7099C2.90844 14.8599 3.00844 15.0099 3.11844 15.1499C3.23844 15.3099 3.41844 15.3899 3.59844 15.3899C3.71844 15.3899 3.84844 15.3499 3.95844 15.2699C4.22844 15.0699 4.27844 14.6999 4.07844 14.4299C3.98844 14.3099 3.89844 14.1799 3.80844 14.0499Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M2.99844 12.3999C2.94844 12.2499 2.89844 12.1099 2.85844 11.9599C2.76844 11.6399 2.43844 11.4499 2.11844 11.5399C1.79844 11.6299 1.60844 11.9599 1.69844 12.2799C1.74844 12.4499 1.79844 12.6199 1.85844 12.7899C1.94844 13.0399 2.17844 13.1999 2.42844 13.1999C2.48844 13.1999 2.55844 13.1899 2.61844 13.1699C2.92844 13.0599 3.09844 12.7199 2.98844 12.4099L2.99844 12.3999Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M15.9984 14.3299C15.9084 14.4599 15.8084 14.5799 15.7184 14.6999C15.5084 14.9599 15.5484 15.3299 15.7984 15.5399C15.9084 15.6299 16.0484 15.6799 16.1784 15.6799C16.3484 15.6799 16.5284 15.5999 16.6384 15.4599C16.7484 15.3199 16.8584 15.1799 16.9684 15.0399C17.1584 14.7699 17.0984 14.3999 16.8284 14.1999C16.5584 14.0099 16.1884 14.0699 15.9884 14.3399L15.9984 14.3299Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M16.9584 7.4799C17.0084 7.6199 17.0584 7.7699 17.0984 7.9199C17.1784 8.1799 17.4184 8.3499 17.6784 8.3499C17.7384 8.3499 17.7884 8.3499 17.8484 8.3299C18.1684 8.2399 18.3484 7.8999 18.2584 7.5799C18.2084 7.4099 18.1484 7.2399 18.0884 7.0699C17.9784 6.7599 17.6284 6.5999 17.3184 6.7099C17.0084 6.8199 16.8484 7.1699 16.9584 7.4799Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M18.5884 9.6899C18.5784 9.3599 18.3084 9.0999 17.9684 9.1099C17.6384 9.1199 17.3784 9.3999 17.3884 9.7299V9.9899C17.3884 10.1499 17.3884 10.2999 17.3884 10.4499C17.3684 10.7799 17.6184 11.0699 17.9484 11.0899C17.9584 11.0899 17.9784 11.0899 17.9884 11.0899C18.2984 11.0899 18.5684 10.8399 18.5884 10.5299C18.5984 10.3499 18.6084 10.1699 18.6084 9.9899C18.6084 9.8899 18.6084 9.7799 18.6084 9.6799L18.5884 9.6899Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M14.3784 15.9699C14.2584 16.0599 14.1284 16.1499 13.9984 16.2299C13.7184 16.4099 13.6384 16.7799 13.8184 17.0599C13.9284 17.2399 14.1284 17.3399 14.3284 17.3399C14.4384 17.3399 14.5484 17.3099 14.6484 17.2399C14.7984 17.1399 14.9484 17.0399 15.0884 16.9299C15.3584 16.7299 15.4084 16.3599 15.2184 16.0899C15.0284 15.8199 14.6484 15.7699 14.3784 15.9599V15.9699Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M14.5384 4.1599C14.6484 4.2399 14.7784 4.2899 14.9084 4.2899C15.0884 4.2899 15.2684 4.2099 15.3784 4.0599C15.5784 3.7999 15.5384 3.4199 15.2684 3.2199C15.1284 3.1099 14.9784 2.9999 14.8384 2.8999C14.5684 2.7099 14.1884 2.7799 14.0084 3.0599C13.8184 3.3299 13.8884 3.7099 14.1684 3.8899C14.2984 3.9799 14.4184 4.0699 14.5484 4.1599H14.5384Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M17.7984 11.8899C17.4784 11.7899 17.1484 11.9599 17.0384 12.2799C16.9884 12.4299 16.9384 12.5699 16.8784 12.7099C16.7584 13.0199 16.9084 13.3699 17.2184 13.4899C17.2884 13.5199 17.3684 13.5299 17.4384 13.5299C17.6784 13.5299 17.8984 13.3899 17.9984 13.1499C18.0684 12.9799 18.1284 12.8099 18.1784 12.6399C18.2784 12.3199 18.1084 11.9899 17.7884 11.8799L17.7984 11.8899Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M12.3384 17.0199C12.1884 17.0699 12.0484 17.1099 11.8984 17.1499C11.5784 17.2399 11.3884 17.5599 11.4684 17.8799C11.5384 18.1499 11.7784 18.3299 12.0484 18.3299C12.0984 18.3299 12.1484 18.3299 12.1984 18.3099C12.3684 18.2599 12.5384 18.2099 12.7084 18.1599C13.0184 18.0599 13.1984 17.7199 13.0884 17.3999C12.9884 17.0899 12.6484 16.9099 12.3284 17.0199H12.3384Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M9.99844 17.3999C9.86844 17.3999 9.72844 17.3999 9.59844 17.3899C9.25844 17.3699 8.98844 17.6299 8.96844 17.9599C8.94844 18.2899 9.20844 18.5699 9.53844 18.5899C9.68844 18.5899 9.84844 18.5999 9.99844 18.5999H10.0684C10.3984 18.5999 10.6284 18.3299 10.6284 17.9999C10.6284 17.6699 10.3284 17.3999 9.98844 17.3999H9.99844Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M16.1184 5.8399C16.2384 6.0099 16.4184 6.0999 16.6184 6.0999C16.7384 6.0999 16.8484 6.0699 16.9584 5.9999C17.2284 5.8099 17.2984 5.4399 17.1184 5.1699C17.0184 5.0199 16.9084 4.8799 16.7984 4.7399C16.5984 4.4799 16.2184 4.4299 15.9584 4.6299C15.6984 4.8299 15.6484 5.2099 15.8484 5.4699C15.9384 5.5899 16.0384 5.7199 16.1184 5.8399Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M5.76844 9.9999C5.76844 9.7799 5.58844 9.5999 5.36844 9.5999H5.19844L5.32844 9.4699C5.52844 9.2799 5.53844 8.9599 5.34844 8.7599C5.15844 8.5599 4.83844 8.5499 4.63844 8.7399L3.60844 9.7099C3.40844 9.8999 3.39844 10.2199 3.58844 10.4199L4.55844 11.4499C4.65844 11.5499 4.78844 11.6099 4.91844 11.6099C5.03844 11.6099 5.16844 11.5599 5.25844 11.4699C5.45844 11.2799 5.46844 10.9599 5.27844 10.7599L4.93844 10.3999H5.35844C5.57844 10.3999 5.75844 10.2199 5.75844 9.9999H5.76844Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M7.26844 9.9999C7.26844 9.7799 7.08844 9.5999 6.86844 9.5999H6.36844C6.14844 9.5999 5.96844 9.7799 5.96844 9.9999C5.96844 10.2199 6.14844 10.3999 6.36844 10.3999H6.86844C7.08844 10.3999 7.26844 10.2199 7.26844 9.9999Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M14.0284 9.9999C14.0284 9.7799 13.8484 9.5999 13.6284 9.5999H13.1284C12.9084 9.5999 12.7284 9.7799 12.7284 9.9999C12.7284 10.2199 12.9084 10.3999 13.1284 10.3999H13.6284C13.8484 10.3999 14.0284 10.2199 14.0284 9.9999Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M9.99844 12.7299C9.77844 12.7299 9.59844 12.9099 9.59844 13.1299V13.6299C9.59844 13.8499 9.77844 14.0299 9.99844 14.0299C10.2184 14.0299 10.3984 13.8499 10.3984 13.6299V13.1299C10.3984 12.9099 10.2184 12.7299 9.99844 12.7299Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M10.3984 6.3699C10.3984 6.1499 10.2184 5.9699 9.99844 5.9699C9.77844 5.9699 9.59844 6.1499 9.59844 6.3699V6.8699C9.59844 7.0899 9.77844 7.2699 9.99844 7.2699C10.2184 7.2699 10.3984 7.0899 10.3984 6.8699V6.3699Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M16.4784 9.6799L15.5084 8.6499C15.3184 8.4499 14.9984 8.4399 14.7984 8.6299C14.5984 8.8199 14.5884 9.1399 14.7784 9.3399L15.0184 9.5999H14.6284C14.4084 9.5999 14.2284 9.7799 14.2284 9.9999C14.2284 10.2199 14.4084 10.3999 14.6284 10.3999H14.9884L14.7384 10.6399C14.5384 10.8299 14.5284 11.1499 14.7184 11.3499C14.8184 11.4499 14.9484 11.5099 15.0784 11.5099C15.1984 11.5099 15.3284 11.4599 15.4184 11.3699L16.4484 10.3999C16.6484 10.2099 16.6584 9.8899 16.4684 9.6899L16.4784 9.6799Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M9.32844 5.3099L9.59844 5.0499V5.3699C9.59844 5.5899 9.77844 5.7699 9.99844 5.7699C10.2184 5.7699 10.3984 5.5899 10.3984 5.3699V5.1099L10.6184 5.3399C10.7184 5.4399 10.8484 5.4999 10.9784 5.4999C11.0984 5.4999 11.2284 5.4499 11.3184 5.3599C11.5184 5.1699 11.5284 4.8499 11.3384 4.6499L10.3684 3.6199C10.1784 3.4199 9.85844 3.4099 9.65844 3.5999L8.62844 4.5699C8.42844 4.7599 8.41844 5.0799 8.60844 5.2799C8.79844 5.4799 9.10844 5.4899 9.31844 5.2999L9.32844 5.3099Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M11.4484 14.8199C11.2584 14.6199 10.9484 14.6099 10.7384 14.7999L10.3884 15.1299V14.6299C10.3884 14.4099 10.2084 14.2299 9.98844 14.2299C9.76844 14.2299 9.58844 14.4099 9.58844 14.6299V14.9099L9.44844 14.7599C9.25844 14.5599 8.93844 14.5499 8.73844 14.7399C8.53844 14.9299 8.52844 15.2499 8.71844 15.4499L9.68844 16.4799C9.78844 16.5799 9.91844 16.6399 10.0484 16.6399C10.1684 16.6399 10.2984 16.5899 10.3884 16.4999L11.4184 15.5299C11.6184 15.3399 11.6284 15.0199 11.4384 14.8199H11.4484Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19327">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
