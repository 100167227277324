import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseFireDetection = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  return (
    <>
      <g clipPath="url(#clip0_1030_19325)">
        <path
          d="M11.4998 15.32C11.3498 15.32 11.1898 15.25 11.0998 15.12C10.9598 14.93 10.9698 14.67 11.1198 14.49C11.2898 14.29 11.6998 13.66 11.5798 13.11C11.4898 12.73 11.1598 12.4 10.5698 12.14C9.39983 11.62 8.87983 10.68 8.64983 9.81002C8.16983 10.75 7.88983 12.38 9.60983 14.5C9.74983 14.67 9.75983 14.9 9.63983 15.08C9.52983 15.26 9.30983 15.35 9.09983 15.3C7.50983 14.92 4.63983 13.32 5.11983 10.02C5.38983 8.17002 6.47983 7.23002 7.43983 6.41002C8.53983 5.46002 9.33983 4.77002 8.73983 2.91002C8.67983 2.71002 8.73983 2.49002 8.91983 2.36002C9.08983 2.23002 9.31983 2.23002 9.49983 2.35002C9.55983 2.39002 10.9698 3.35002 11.6398 4.77002C12.1098 5.76002 12.2198 6.92002 12.2998 7.77002C12.3198 8.01002 12.3498 8.30002 12.3798 8.52002C12.5398 8.20002 12.5398 7.63002 12.4598 7.45002C12.3798 7.25002 12.4198 6.96002 12.5898 6.82002C12.7598 6.68002 13.0198 6.67002 13.1998 6.79002C13.5998 7.05002 15.3898 9.68002 14.7598 11.84C14.0798 14.19 11.7798 15.24 11.6798 15.28C11.6098 15.31 11.5398 15.32 11.4798 15.32H11.4998ZM9.95983 4.07002C9.95983 5.57002 9.01983 6.38002 8.10983 7.17002C7.19983 7.95002 6.34983 8.69002 6.12983 10.17C5.85983 12.03 6.92983 13.12 7.90983 13.72C6.18983 10.36 8.64983 8.17002 8.67983 8.15002C8.82983 8.02002 9.03983 7.99002 9.20983 8.07002C9.38983 8.15002 9.49983 8.33002 9.49983 8.52002C9.49983 9.45002 9.75983 10.68 10.9798 11.23C11.8698 11.63 12.3998 12.19 12.5598 12.9C12.5998 13.09 12.6098 13.28 12.5998 13.46C13.0898 13 13.5898 12.37 13.8198 11.56C14.0798 10.66 13.7498 9.58002 13.3598 8.78002C13.3098 8.92002 13.2398 9.05002 13.1598 9.17002C12.9298 9.51002 12.5798 9.67002 12.1998 9.68002C11.4798 9.68002 11.3998 8.84002 11.3098 7.87002C11.2298 7.05002 11.1398 6.03002 10.7498 5.21002C10.5498 4.78002 10.2498 4.40002 9.95983 4.08002V4.07002ZM12.2198 8.67002C12.2198 8.67002 12.2198 8.67002 12.2298 8.67002H12.2198Z"
          fill="#FF3D00"
        />
        <path
          d="M14.3003 17.6399H5.33031C5.10031 17.6799 4.92031 17.8799 4.92031 18.1199C4.92031 18.3899 5.14031 18.5999 5.40031 18.5999H14.3703C14.6003 18.5599 14.7803 18.3599 14.7803 18.1199C14.7803 17.8499 14.5603 17.6399 14.3003 17.6399Z"
          fill={color}
        />
        <path
          d="M5.84031 16.3699C6.07031 16.3399 6.25031 16.1399 6.25031 15.8899C6.25031 15.6199 6.03031 15.4099 5.77031 15.4099H3.48031C3.25031 15.4499 3.07031 15.6499 3.07031 15.8899C3.07031 16.1599 3.29031 16.3699 3.55031 16.3699H5.85031H5.84031Z"
          fill={color}
        />
        <path
          d="M16.5303 15.4199H9.04031C8.81031 15.4599 8.63031 15.6599 8.63031 15.8999C8.63031 16.1699 8.85031 16.3799 9.11031 16.3799H16.6003C16.8303 16.3399 17.0103 16.1399 17.0103 15.8999C17.0103 15.6299 16.7903 15.4199 16.5303 15.4199Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19325">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
