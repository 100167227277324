import type { IconChildrenProps } from "../icon.types";

export const UserCheck = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M13.3137 6.52649C11.5702 6.53318 10.1622 7.95188 10.1688 9.69533C10.1754 11.4388 11.5941 12.8469 13.3376 12.8403C14.1745 12.8383 14.9771 12.5029 15.5674 11.9082C16.1576 11.3135 16.4869 10.5084 16.4826 9.6705C16.4761 7.97265 15.129 6.59242 13.4472 6.52876L13.3137 6.52649ZM13.3192 7.97879C14.2606 7.97528 15.0267 8.73557 15.0303 9.67698C15.0326 10.1299 14.855 10.5643 14.5366 10.8851C14.2182 11.206 13.7851 11.3869 13.3331 11.388C12.3907 11.3915 11.6247 10.6312 11.6211 9.68984C11.6177 8.78332 12.3226 8.03938 13.2155 7.98229L13.3192 7.97879ZM15.7585 13.791L10.9133 13.791C9.17604 13.7961 7.77071 15.2015 7.76563 16.9366L7.76562 17.5441C7.76778 18.2795 8.36171 18.8735 9.09503 18.8756L17.5725 18.8756C18.308 18.8735 18.9019 18.2795 18.9041 17.5462L18.9041 16.9387C18.899 15.2015 17.4936 13.7961 15.7585 13.791ZM15.7542 15.2433L15.8575 15.2467C16.7458 15.3025 17.4491 16.0391 17.4517 16.9408L17.4513 17.423H9.2175L9.21793 16.9387C9.22067 16.0045 9.97906 15.2461 10.9154 15.2433H15.7542Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M13.6609 3.20447C11.7773 0.50841 8.35664 -0.645536 5.22479 0.358558C2.09291 1.36264 -0.0190829 4.29039 0.0160569 7.57912C0.0512646 10.8678 2.2254 13.7498 5.37801 14.6866C5.76244 14.8009 6.16669 14.5818 6.28093 14.1974C6.39517 13.813 6.17614 13.4087 5.79171 13.2945C3.24971 12.5391 1.49667 10.2153 1.46828 7.56358C1.43995 4.91183 3.14288 2.55114 5.66817 1.74153C8.19345 0.931907 10.9516 1.86235 12.4704 4.03623C12.7 4.36499 13.1527 4.4453 13.4815 4.21562C13.8103 3.98593 13.8906 3.53323 13.6609 3.20447ZM9.32674 6.33498C9.04299 6.05157 8.58321 6.05184 8.2998 6.33559L7.2002 7.43603L6.74566 6.98133L6.68233 6.9249C6.3973 6.6992 5.98205 6.71801 5.71873 6.98133C5.43515 7.26491 5.43515 7.72469 5.71873 8.00827L6.68734 8.97688L6.7507 9.03333C7.03585 9.25911 7.4513 9.24017 7.71458 8.97658L9.32734 7.36192L9.38373 7.29855C9.60927 7.01339 9.59022 6.59815 9.32674 6.33498Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
