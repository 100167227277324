import type { IconChildrenProps } from "../icon.types";

export const Filter = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M17.5489 3.06754C17.5682 3.2153 17.5543 3.36552 17.5082 3.50722L16.5558 6.43048C16.1954 7.53643 15.4704 8.4873 14.4992 9.12747L13.4489 9.81986C13.1682 10.0049 12.9992 10.3186 12.9992 10.6548V16C12.9992 16.5523 12.5515 17 11.9992 17H8.99923C8.44694 17 7.99923 16.5523 7.99923 16V10.6548C7.99923 10.3186 7.83029 10.0049 7.5496 9.81986L6.49923 9.12747C5.52808 8.4873 4.80301 7.53643 4.44269 6.43048L3.49282 3.515C3.32174 2.98989 3.60874 2.4255 4.13385 2.25442C4.27704 2.20777 4.42891 2.19406 4.57814 2.21431L16.4275 2.20591C16.9751 2.13417 17.4771 2.51993 17.5489 3.06754ZM16.5574 3.19744L4.44363 3.20523L5.3935 6.12071C5.65948 6.93709 6.17227 7.64854 6.85827 8.15853L7.0496 8.29255L8.09997 8.98494C8.61458 9.32417 8.94136 9.87962 8.99225 10.4877L8.99923 10.6548V16H11.9992V10.6548C11.9992 10.0384 12.2831 9.45988 12.7629 9.0827L12.8985 8.98494L13.9489 8.29255C14.6657 7.81999 15.2161 7.13719 15.5264 6.34068L15.605 6.12071L16.5574 3.19744Z"
    fill={color}
    fillRule="evenodd"
  />
);
