import { AR_CATEGORY_AREA_CONTROLS } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseTimeLimited = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19309)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.36922 9.92994C9.36922 9.92994 9.35922 10.0299 9.35922 10.0699C9.35922 10.4699 9.67922 10.7899 10.0792 10.7899C10.4792 10.7899 10.7992 10.4699 10.7992 10.0699C10.7992 9.66994 10.4992 9.36994 10.1192 9.34994H10.0192C9.98922 9.34994 9.95922 9.34994 9.92922 9.35994L8.04922 7.47994C7.88922 7.34994 7.65922 7.35994 7.50922 7.50994C7.34922 7.66994 7.34922 7.91994 7.50922 8.07994L9.35922 9.92994H9.36922Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6392 12.0699C12.4792 11.9099 12.2292 11.9099 12.0692 12.0699C11.5392 12.5999 10.8292 12.8999 10.0792 12.8999C8.51922 12.8999 7.25922 11.6399 7.25922 10.0799C7.25922 9.91994 7.27922 9.74994 7.30922 9.57994C7.34922 9.35994 7.20922 9.14994 6.98922 9.10994C6.76922 9.06994 6.55922 9.20994 6.51922 9.42994C6.46922 9.65994 6.44922 9.86994 6.44922 10.0899C6.44922 12.0899 8.06922 13.7099 10.0692 13.7099C11.0392 13.7099 11.9592 13.3199 12.6292 12.6499C12.7892 12.4899 12.7892 12.2399 12.6292 12.0799L12.6392 12.0699Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3706 2.58008C11.5906 2.58008 11.7706 2.76008 11.7706 2.98008C11.7706 3.19008 11.6106 3.36008 11.4106 3.38008H8.79062C8.57062 3.38008 8.39062 3.20008 8.39062 2.98008C8.39062 2.77008 8.55062 2.60008 8.75062 2.58008H11.3706Z"
          fill={color}
        />
        <path
          d="M14.2883 6.43991L15.2383 5.48991L15.6283 5.87991C15.7883 6.00991 16.0183 5.99991 16.1683 5.84991C16.3283 5.68991 16.3283 5.43991 16.1683 5.27991L14.8583 3.96991C14.6983 3.83991 14.4683 3.84991 14.3183 3.99991C14.1583 4.15991 14.1583 4.40991 14.3183 4.56991L14.6783 4.92991L13.7283 5.87991C11.5483 3.98991 8.23828 4.07991 6.15828 6.14991C4.07828 8.21991 3.98828 11.8399 6.15828 14.0099C8.32828 16.1799 11.8483 16.1799 14.0183 14.0099C16.1883 11.8399 16.1883 8.62991 14.2883 6.43991ZM6.71828 13.4399C4.85828 11.5799 4.85828 8.56991 6.71828 6.71991C8.57828 4.86991 11.4983 4.85991 13.3583 6.63991L13.5183 6.79991C15.2883 8.65991 15.2683 11.6099 13.4383 13.4399C11.6083 15.2699 8.56828 15.2999 6.71828 13.4399Z"
          fill={color}
        />
        <path
          d="M2.00828 10.6799C2.28828 10.6799 2.50828 10.4499 2.49828 10.1699V9.98991C2.49828 9.79991 2.49828 9.59991 2.51828 9.40991C2.53828 9.12991 2.33828 8.88991 2.05828 8.86991C1.80828 8.84991 1.53828 9.04991 1.51828 9.32991C1.49828 9.54991 1.48828 9.75991 1.48828 9.97991V10.1699C1.48828 10.4399 1.71828 10.6599 1.98828 10.6599L2.00828 10.6799Z"
          fill={color}
        />
        <path
          d="M2.14828 8.38991C2.14828 8.38991 2.23828 8.40991 2.27828 8.40991C2.49828 8.40991 2.69828 8.25991 2.75828 8.03991C2.82828 7.79991 2.89828 7.55991 2.98828 7.31991C3.08828 7.05991 2.95828 6.76991 2.69828 6.67991C2.43828 6.57991 2.14828 6.70991 2.05828 6.96991C1.95828 7.22991 1.86828 7.49991 1.79828 7.77991C1.72828 8.04991 1.87828 8.31991 2.14828 8.38991Z"
          fill={color}
        />
        <path
          d="M4.69828 15.2999C4.49828 15.0999 4.18828 15.0999 3.98828 15.2999C3.78828 15.4999 3.78828 15.8099 3.98828 16.0099C4.18828 16.2099 4.39828 16.3999 4.61828 16.5799C4.70828 16.6599 4.82828 16.6899 4.93828 16.6899C5.07828 16.6899 5.22828 16.6299 5.32828 16.5099C5.49828 16.2999 5.46828 15.9799 5.25828 15.8099C5.06828 15.6499 4.87828 15.4799 4.69828 15.3099V15.2999Z"
          fill={color}
        />
        <path
          d="M3.79828 14.2199C3.65828 14.0099 3.52828 13.7999 3.40828 13.5799C3.27828 13.3399 2.96828 13.2499 2.72828 13.3799C2.48828 13.5099 2.39828 13.8099 2.52828 14.0599C2.65828 14.3099 2.80828 14.5499 2.96828 14.7799C3.06828 14.9199 3.21828 14.9999 3.37828 14.9999C3.47828 14.9999 3.57828 14.9699 3.65828 14.9099C3.88828 14.7499 3.94828 14.4399 3.78828 14.2199H3.79828Z"
          fill={color}
        />
        <path
          d="M2.85828 12.2899C2.77828 12.0499 2.71828 11.8099 2.66828 11.5699C2.60828 11.2999 2.34828 11.1299 2.07828 11.1799C1.80828 11.2399 1.63828 11.4999 1.68828 11.7699C1.74828 12.0499 1.81828 12.3199 1.90828 12.5899C1.97828 12.7999 2.16828 12.9399 2.38828 12.9399C2.43828 12.9399 2.48828 12.9399 2.53828 12.9199C2.79828 12.8399 2.94828 12.5499 2.85828 12.2899Z"
          fill={color}
        />
        <path
          d="M17.2983 8.28991C17.3483 8.51991 17.5583 8.67991 17.7883 8.67991C17.8283 8.67991 17.8683 8.67991 17.8983 8.66991C18.1683 8.60991 18.3383 8.33991 18.2783 8.06991C18.2183 7.78991 18.1383 7.51991 18.0383 7.24991C17.9483 6.98991 17.6583 6.84991 17.4083 6.93991C17.1483 7.02991 17.0083 7.30991 17.0983 7.57991C17.1783 7.80991 17.2483 8.04991 17.3083 8.29991L17.2983 8.28991Z"
          fill={color}
        />
        <path
          d="M2.91828 6.22991C2.99828 6.27991 3.08828 6.29991 3.17828 6.29991C3.34828 6.29991 3.50828 6.21991 3.60828 6.05991C3.73828 5.84991 3.87828 5.63991 4.02828 5.43991C4.19828 5.21991 4.14828 4.90991 3.93828 4.73991C3.71828 4.56991 3.40828 4.60991 3.23828 4.82991C3.06828 5.04991 2.90828 5.28991 2.75828 5.52991C2.61828 5.75991 2.68828 6.06991 2.91828 6.21991V6.22991Z"
          fill={color}
        />
        <path
          d="M4.65828 4.54991C4.77828 4.54991 4.89828 4.50991 4.98828 4.41991C5.17828 4.24991 5.36828 4.09991 5.56828 3.94991C5.78828 3.78991 5.83828 3.46991 5.67828 3.24991C5.50828 3.02991 5.19828 2.97991 4.97828 3.13991C4.74828 3.30991 4.52828 3.48991 4.31828 3.66991C4.10828 3.84991 4.09828 4.16991 4.27828 4.37991C4.37828 4.48991 4.51828 4.54991 4.64828 4.54991H4.65828Z"
          fill={color}
        />
        <path
          d="M7.08828 16.9099C6.85828 16.8099 6.62828 16.6999 6.40828 16.5799C6.16828 16.4499 5.86828 16.5399 5.72828 16.7799C5.59828 17.0199 5.68828 17.3299 5.92828 17.4599C6.17828 17.5999 6.42828 17.7199 6.69828 17.8299C6.75828 17.8599 6.82828 17.8699 6.88828 17.8699C7.08828 17.8699 7.26828 17.7599 7.34828 17.5599C7.45828 17.3099 7.33828 17.0099 7.07828 16.9099H7.08828Z"
          fill={color}
        />
        <path
          d="M13.3583 16.7099C13.1383 16.8199 12.9083 16.9199 12.6683 17.0099C12.4083 17.1099 12.2783 17.3999 12.3783 17.6599C12.4583 17.8599 12.6483 17.9799 12.8483 17.9799C12.9083 17.9799 12.9683 17.9699 13.0283 17.9499C13.2983 17.8499 13.5583 17.7299 13.8083 17.6099C14.0583 17.4899 14.1583 17.1899 14.0283 16.9399C13.9083 16.6899 13.5983 16.5899 13.3583 16.7199V16.7099Z"
          fill={color}
        />
        <path
          d="M15.1183 15.4799C14.9383 15.6499 14.7483 15.8099 14.5483 15.9599C14.3283 16.1299 14.2883 16.4399 14.4583 16.6599C14.5583 16.7899 14.7083 16.8599 14.8583 16.8599C14.9683 16.8599 15.0683 16.8299 15.1583 16.7599C15.3883 16.5899 15.5983 16.4099 15.8083 16.2099C16.0083 16.0199 16.0183 15.6999 15.8283 15.4999C15.6383 15.2999 15.3283 15.2899 15.1183 15.4799Z"
          fill={color}
        />
        <path
          d="M11.3183 17.3799C11.0783 17.4199 10.8283 17.4499 10.5783 17.4699C10.2983 17.4899 10.0983 17.7299 10.1183 18.0099C10.1383 18.2699 10.3583 18.4699 10.6183 18.4699C10.6283 18.4699 10.6483 18.4699 10.6583 18.4699C10.9383 18.4499 11.2283 18.4099 11.4983 18.3599C11.7683 18.3099 11.9483 18.0499 11.9083 17.7799C11.8583 17.5099 11.5983 17.3299 11.3283 17.3699L11.3183 17.3799Z"
          fill={color}
        />
        <path
          d="M9.16828 17.4499C8.91828 17.4199 8.66828 17.3799 8.42828 17.3299C8.16828 17.2699 7.88828 17.4399 7.83828 17.7099C7.77828 17.9799 7.94828 18.2499 8.21828 18.2999C8.48828 18.3599 8.77828 18.3999 9.05828 18.4399C9.07828 18.4399 9.09828 18.4399 9.11828 18.4399C9.36828 18.4399 9.58828 18.2499 9.61828 17.9899C9.64828 17.7199 9.44828 17.4699 9.17828 17.4399L9.16828 17.4499Z"
          fill={color}
        />
        <path
          d="M17.1483 13.6299C16.9183 13.4899 16.6083 13.5699 16.4683 13.8099C16.3383 14.0299 16.2083 14.2299 16.0583 14.4399C15.8983 14.6599 15.9383 14.9799 16.1683 15.1399C16.2583 15.2099 16.3583 15.2399 16.4583 15.2399C16.6083 15.2399 16.7683 15.1699 16.8583 15.0399C17.0283 14.8099 17.1783 14.5799 17.3283 14.3299C17.4683 14.0899 17.3883 13.7899 17.1483 13.6399V13.6299Z"
          fill={color}
        />
        <path
          d="M17.8783 11.4499C17.6083 11.3899 17.3383 11.5499 17.2683 11.8199C17.2083 12.0599 17.1383 12.2999 17.0483 12.5399C16.9583 12.7999 17.0883 13.0899 17.3483 13.1799C17.4083 13.1999 17.4583 13.2099 17.5183 13.2099C17.7283 13.2099 17.9183 13.0799 17.9883 12.8799C18.0883 12.6099 18.1683 12.3399 18.2383 12.0699C18.3083 11.7999 18.1383 11.5299 17.8683 11.4599L17.8783 11.4499Z"
          fill={color}
        />
        <path
          d="M18.4883 9.63991C18.4783 9.35991 18.2383 9.12991 17.9683 9.15991C17.6883 9.16991 17.4783 9.40991 17.4883 9.67991C17.4883 9.78991 17.4883 9.89991 17.4883 9.99991C17.4883 10.1399 17.4883 10.2899 17.4783 10.4299C17.4583 10.7099 17.6683 10.9399 17.9483 10.9599C17.9483 10.9599 17.9683 10.9599 17.9783 10.9599C18.2383 10.9599 18.4583 10.7499 18.4783 10.4899C18.4783 10.3299 18.4883 10.1699 18.4883 9.99991C18.4883 9.87991 18.4883 9.75991 18.4883 9.63991Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19309">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
