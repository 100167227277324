import type { IconChildrenProps } from "../icon.types";

export const Without = ({ color }: IconChildrenProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.39645 3.39646C3.59171 3.2012 3.90829 3.2012 4.10355 3.39646L16.1036 15.3965C16.2988 15.5917 16.2988 15.9083 16.1036 16.1036C15.9083 16.2988 15.5917 16.2988 15.3964 16.1036L3.39645 4.10357C3.20118 3.90831 3.20118 3.59172 3.39645 3.39646Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.09428 4.68009C7.12224 3.93757 8.38502 3.5 9.75 3.5C13.2018 3.5 16 6.29822 16 9.75C16 11.115 15.5624 12.3778 14.8199 13.4057L15.8916 14.4774C16.9002 13.1692 17.5 11.5296 17.5 9.75C17.5 5.46979 14.0302 2 9.75 2C7.97037 2 6.33083 2.59984 5.02255 3.60837L6.09428 4.68009ZM4.68007 6.09431L3.60834 5.02258C2.59983 6.33086 2 7.97038 2 9.75C2 14.0302 5.46979 17.5 9.75 17.5C11.5296 17.5 13.1691 16.9002 14.4774 15.8917L13.4057 14.8199C12.3777 15.5624 11.115 16 9.75 16C6.29822 16 3.5 13.2018 3.5 9.75C3.5 8.38503 3.93756 7.12227 4.68007 6.09431Z"
      fill={color}
    />
  </>
);
