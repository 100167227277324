import type { IconChildrenProps } from "../icon.types";

export const Timer = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M11.2792 1.42352C11.5716 1.42352 11.8087 1.66055 11.8087 1.95293C11.8087 2.22705 11.6003 2.4525 11.3334 2.47961L11.2792 2.48235H7.89101C7.59862 2.48235 7.3616 2.24532 7.3616 1.95293C7.3616 1.67882 7.56992 1.45337 7.83688 1.42626L7.89101 1.42352H11.2792ZM16.3616 5.24281L15.1122 6.49223C17.5988 9.3624 17.4785 13.7094 14.7512 16.4366C11.8981 19.2897 7.27226 19.2897 4.41913 16.4366C1.56602 13.5835 1.56602 8.95768 4.41913 6.10455C7.14637 3.37731 11.4933 3.25698 14.3635 5.74353L15.6129 4.49411L15.1402 4.0214C14.9334 3.81465 14.9334 3.47945 15.1402 3.2727C15.3332 3.07974 15.638 3.06687 15.8459 3.23411L15.8889 3.2727L16.7359 4.11976L17.583 4.96682C17.7898 5.17357 17.7898 5.50877 17.583 5.71552C17.39 5.90849 17.0852 5.92135 16.8773 5.75411L16.8343 5.71552L16.3616 5.24281ZM5.16784 6.85325C7.57106 4.45003 11.4451 4.41416 13.8923 6.74564L14.0025 6.85325L14.1101 6.96349C16.4416 9.41061 16.4057 13.2847 14.0025 15.6879C11.5629 18.1275 7.60747 18.1275 5.16784 15.6879C2.72821 13.2483 2.72821 9.29288 5.16784 6.85325ZM9.6488 10.3198L9.58343 10.3176L9.51805 10.3198C9.47755 10.3226 9.43774 10.3279 9.39878 10.3355L6.96089 7.89764L6.9179 7.85905C6.71002 7.69181 6.40515 7.70468 6.21219 7.89764C6.00544 8.10439 6.00544 8.4396 6.21219 8.64634L8.64989 11.084C8.63783 11.1456 8.63173 11.2092 8.6322 11.2744C8.63219 11.7968 9.05895 12.2235 9.58513 12.2235C10.1113 12.2235 10.5381 11.7968 10.5381 11.2706C10.5381 10.7658 10.1456 10.3533 9.6488 10.3198ZM4.91131 10.3995C4.96903 10.1129 5.24818 9.92728 5.53481 9.985C5.82144 10.0427 6.00702 10.3219 5.9493 10.6085C5.90293 10.8388 5.87924 11.0568 5.87924 11.2706C5.87924 13.3169 7.53878 14.9765 9.58513 14.9765C10.5817 14.9765 11.5148 14.5819 12.2062 13.8912C12.413 13.6846 12.7483 13.6848 12.9549 13.8916C13.1615 14.0985 13.1613 14.4337 12.9545 14.6403C12.0666 15.5273 10.8653 16.0353 9.58513 16.0353C6.95401 16.0353 4.82042 13.9017 4.82042 11.2706C4.82042 10.9832 4.85165 10.6958 4.91131 10.3995Z"
    fill={color}
    fillRule="evenodd"
  />
);
