import type { IconChildrenProps } from "../icon.types";

export const VisualAnalysisSelected = ({
  accentColor,
  color,
}: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M9.52822 12.8684L9.53223 13.0124C9.53223 13.7926 10.7025 13.7926 10.7025 13.0124C10.7025 11.6113 11.8383 10.4754 13.2394 10.4754C14.0196 10.4754 14.0196 9.30516 13.2394 9.30516L13.0955 9.30115C11.809 9.22918 10.7785 8.1987 10.7065 6.91226L10.7025 6.76831C10.7025 6.02063 9.62772 5.98947 9.53816 6.67485L9.53223 6.76831C9.53223 8.12105 8.4734 9.22652 7.13926 9.30115L6.9953 9.30516C6.24762 9.30516 6.21646 10.38 6.90184 10.4695L6.9953 10.4754C8.34811 10.4754 9.45358 11.5343 9.52822 12.8684ZM10.1172 8.76761L10.1216 8.77476C10.3727 9.16403 10.6946 9.50325 11.0692 9.77417L11.2399 9.8903L11.2329 9.89457C10.8437 10.1456 10.5044 10.4676 10.2335 10.8422L10.1172 11.0122L10.1131 11.0059C9.86207 10.6166 9.54012 10.2774 9.16552 10.0064L8.99453 9.8903L9.0018 9.88605C9.39107 9.63501 9.7303 9.31307 10.0012 8.93847L10.1172 8.76761Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.1176 2.28052C10.4408 2.28052 10.7027 2.54249 10.7027 2.86566C10.7027 3.18882 10.4408 3.4508 10.1176 3.4508C6.56118 3.4508 3.67809 6.33386 3.67809 9.89031C3.67809 13.4467 6.56119 16.3297 10.1176 16.3297C13.674 16.3297 16.557 13.4467 16.557 9.89031C16.557 9.56714 16.819 9.30517 17.1422 9.30517C17.4653 9.30517 17.7273 9.56714 17.7273 9.89031C17.7273 14.093 14.3203 17.5 10.1176 17.5C5.91487 17.5 2.50781 14.093 2.50781 9.89031C2.50781 5.68753 5.91485 2.28052 10.1176 2.28052Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.9918 6.64798L14.9959 6.76824C14.9959 7.54842 16.1662 7.54842 16.1662 6.76824C16.1662 5.79824 16.9525 5.01188 17.9225 5.01188C18.6702 5.01188 18.7013 3.93709 18.016 3.84752L17.8023 3.83755C16.9281 3.77844 16.2293 3.07966 16.1702 2.20546L16.1662 2.0852C16.1662 1.33752 15.0914 1.30637 15.0018 1.99174L14.9959 2.0852C14.9959 3.01482 14.2737 3.77575 13.3597 3.83755L13.2394 3.8416C12.4918 3.8416 12.4606 4.91639 13.146 5.00595L13.2394 5.01188C14.1691 5.01188 14.93 5.73407 14.9918 6.64798Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
