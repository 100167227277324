import { AR_CATEGORY_PPE } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseApron = ({ color, oppositeColor }: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19332)">
        <path
          d="M10.2297 17.96H9.42969C9.14969 17.96 8.92969 18.18 8.92969 18.46C8.92969 18.74 9.14969 18.96 9.42969 18.96H10.2297C10.5097 18.96 10.7297 18.74 10.7297 18.46C10.7297 18.18 10.5097 17.96 10.2297 17.96Z"
          fill={color}
        />
        <path
          d="M7.93062 17.96H7.14062C6.86062 17.96 6.64062 18.18 6.64062 18.46C6.64062 18.74 6.86062 18.96 7.14062 18.96H7.93062C8.21062 18.96 8.43063 18.74 8.43063 18.46C8.43063 18.18 8.21062 17.96 7.93062 17.96Z"
          fill={color}
        />
        <path
          d="M12.5305 17.96H11.7305C11.4505 17.96 11.2305 18.18 11.2305 18.46C11.2305 18.74 11.4505 18.96 11.7305 18.96H12.5305C12.8105 18.96 13.0305 18.74 13.0305 18.46C13.0305 18.18 12.8105 17.96 12.5305 17.96Z"
          fill={color}
        />
        <path
          d="M14.8516 12.81C13.7316 10.18 13.1316 7.14004 13.1316 4.02004V1.39004C13.1316 1.06004 12.8616 0.790039 12.5316 0.790039C12.2016 0.790039 11.9316 1.06004 11.9316 1.39004V3.42004H8.07156V1.39004C8.07156 1.06004 7.80156 0.790039 7.47156 0.790039C7.14156 0.790039 6.87156 1.06004 6.87156 1.39004V4.02004C6.87156 7.14004 6.28156 10.17 5.15156 12.81C5.12156 12.88 5.10156 12.96 5.10156 13.05V18.46C5.10156 18.79 5.37156 19.06 5.70156 19.06C6.03156 19.06 6.30156 18.79 6.30156 18.46V13.17C7.38156 10.59 7.98156 7.64004 8.05156 4.62004H11.9416C12.0116 7.64004 12.6116 10.58 13.6916 13.17V18.46C13.6916 18.79 13.9616 19.06 14.2916 19.06C14.6216 19.06 14.8916 18.79 14.8916 18.46V13.05C14.8916 12.97 14.8716 12.89 14.8416 12.81H14.8516Z"
          fill={color}
        />
        <path
          d="M11.0409 11.19H8.96094C8.68094 11.19 8.46094 10.97 8.46094 10.69V6.77002C8.46094 6.49002 8.68094 6.27002 8.96094 6.27002H11.0409C11.3209 6.27002 11.5409 6.49002 11.5409 6.77002V10.69C11.5409 10.97 11.3209 11.19 11.0409 11.19ZM9.46094 10.19H10.5409V7.27002H9.46094V10.19Z"
          fill={getAlertCategoryColor(AR_CATEGORY_PPE)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19332">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
