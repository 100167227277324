import type { IconChildrenProps } from "../../icon.types";

export const ErgonomicsUpperArm = ({
  accentColor,
  color,
}: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M15.7703 4.33078C15.9823 4.11874 16.3261 4.11874 16.5381 4.33078C16.7309 4.52353 16.7484 4.82517 16.5907 5.03772L16.5381 5.09862L14.7505 6.88648L16.5381 8.67433C16.7309 8.86709 16.7484 9.16872 16.5907 9.38128L16.5381 9.44217C16.3453 9.63493 16.0437 9.65245 15.8312 9.49474L15.7703 9.44217L13.5985 7.2704C13.4057 7.07764 13.3882 6.776 13.5459 6.56345L13.5985 6.50256L15.7703 4.33078Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.14533 4.87541C8.08613 3.91265 7.32858 3.15168 6.39284 3.08994L6.26982 3.08589C5.27825 3.08589 4.45608 3.87079 4.3943 4.87541L4.3913 4.98258L5.13774 13.8703C5.16988 14.2532 5.43938 14.5703 5.80395 14.6683L5.89699 14.6882L12.6275 15.7614C12.9236 15.8086 13.1254 16.0869 13.0782 16.383C13.0349 16.6544 12.7974 16.8467 12.53 16.8404L12.4565 16.8337L5.726 15.7605C4.86474 15.6232 4.19849 14.9431 4.07077 14.0904L4.05569 13.9612L3.30657 5.04244L3.30469 4.99698C3.30469 3.39848 4.54672 2.082 6.12126 2.00369L6.26982 2L6.41838 2.00369C7.94371 2.07956 9.15699 3.31741 9.23133 4.84798L9.23494 4.99698L9.23437 11.2243L13.4126 12.8978C13.6911 13.0093 13.8263 13.3253 13.7148 13.6037C13.6126 13.8589 13.3386 13.9938 13.0792 13.9286L13.0089 13.9058L8.4901 12.096C8.30702 12.0227 8.17995 11.8569 8.15397 11.665L8.14906 11.592V4.99698L8.14533 4.87541Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12.3253 16.4446L13.0367 13.2654C13.1675 12.6819 13.7435 12.3112 14.3263 12.4414L15.3727 12.6755C15.9554 12.8061 16.3186 13.3869 16.1882 13.9707L15.4768 17.1498C15.3461 17.7334 14.77 18.1041 14.1869 17.9739L13.1408 17.7398C12.558 17.6092 12.1949 17.0284 12.3253 16.4446ZM14.0964 13.5024L13.3848 16.6812L14.4171 16.9121L15.1285 13.7338L14.0964 13.5024Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
