import { AR_CATEGORY_AREA_CONTROLS } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseCraneArea = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19307)">
        <path
          d="M9.99875 15.9101C10.2188 15.9101 10.3987 15.7301 10.3987 15.5101V15.0101C10.3987 14.7901 10.2188 14.6101 9.99875 14.6101C9.77875 14.6101 9.59875 14.7901 9.59875 15.0101V15.5101C9.59875 15.7301 9.77875 15.9101 9.99875 15.9101Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M9.99875 17.4101C10.2188 17.4101 10.3987 17.2301 10.3987 17.0101V16.5101C10.3987 16.2901 10.2188 16.1101 9.99875 16.1101C9.77875 16.1101 9.59875 16.2901 9.59875 16.5101V17.0101C9.59875 17.2301 9.77875 17.4101 9.99875 17.4101Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M9.99875 14.4101C10.2188 14.4101 10.3987 14.2301 10.3987 14.0101V13.5101C10.3987 13.2901 10.2188 13.1101 9.99875 13.1101C9.77875 13.1101 9.59875 13.2901 9.59875 13.5101V14.0101C9.59875 14.2301 9.77875 14.4101 9.99875 14.4101Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M10.1887 17.6701C10.1287 17.6401 10.0687 17.6101 9.99875 17.6101C9.92875 17.6101 9.86875 17.6301 9.80875 17.6701C7.85875 17.6701 2.46875 17.7601 2.46875 18.5501C2.46875 19.3401 8.22875 19.4301 9.99875 19.4301C11.7687 19.4301 17.5288 19.3701 17.5288 18.5501C17.5288 17.7301 12.1387 17.6701 10.1887 17.6701ZM9.59875 18.2001V18.5101C9.59875 18.7201 9.75875 18.8901 9.96875 18.9001C7.72875 18.9001 6.20875 18.7101 5.73875 18.5501C6.18875 18.3901 7.57875 18.2201 9.60875 18.2001H9.59875ZM10.0287 18.9101C10.2287 18.8901 10.3987 18.7301 10.3987 18.5201V18.2101C12.4287 18.2301 13.8188 18.4001 14.2688 18.5601C13.7888 18.7301 12.2687 18.9101 10.0387 18.9101H10.0287ZM5.63875 18.2001C5.37875 18.2901 5.21875 18.4101 5.21875 18.5501C5.21875 18.6901 5.37875 18.8101 5.63875 18.9001C4.25875 18.8001 3.32875 18.6701 2.97875 18.5501C3.32875 18.4301 4.25875 18.2901 5.63875 18.2001ZM14.3587 18.9101C14.6187 18.8201 14.7787 18.7001 14.7787 18.5601C14.7787 18.4201 14.6187 18.3001 14.3587 18.2101C15.7387 18.3101 16.6588 18.4401 17.0188 18.5601C16.6687 18.6801 15.7387 18.8201 14.3587 18.9101Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M17.5805 1.59009H2.33047C2.00047 1.59009 1.73047 1.86009 1.73047 2.19009V5.16009C1.73047 5.49009 2.00047 5.76009 2.33047 5.76009C2.66047 5.76009 2.93047 5.49009 2.93047 5.16009V4.56009H7.36047V6.36009C7.36047 6.69009 7.63047 6.96009 7.96047 6.96009H9.46047V8.77009H9.29047C9.01047 8.77009 8.79047 8.99009 8.79047 9.27009C8.79047 9.55009 9.01047 9.77009 9.29047 9.77009H9.46047V10.0301C8.97047 10.2301 8.62047 10.7101 8.62047 11.2801C8.62047 12.0201 9.22047 12.6201 9.96047 12.6201C10.3305 12.6201 10.6705 12.4801 10.9205 12.2101C11.1605 11.9601 11.3005 11.6301 11.3005 11.2701C11.3005 10.9901 11.0805 10.7701 10.8005 10.7701C10.5205 10.7701 10.3005 10.9901 10.3005 11.2701C10.3005 11.3601 10.2705 11.4501 10.2005 11.5101C10.0005 11.7101 9.61047 11.5501 9.61047 11.2701C9.61047 11.0801 9.76047 10.9301 9.95047 10.9301C10.2305 10.9301 10.4505 10.7101 10.4505 10.4301V9.77009H10.6405C10.9205 9.77009 11.1405 9.55009 11.1405 9.27009C11.1405 8.99009 10.9205 8.77009 10.6405 8.77009H10.4505V6.96009H12.0105C12.3405 6.96009 12.6105 6.69009 12.6105 6.36009V4.56009H16.9805V5.16009C16.9805 5.49009 17.2505 5.76009 17.5805 5.76009C17.9105 5.76009 18.1805 5.49009 18.1805 5.16009V2.19009C18.1805 1.86009 17.9105 1.59009 17.5805 1.59009ZM11.4105 5.76009H8.56047V4.56009H11.4105V5.76009ZM2.93047 3.36009V2.79009H16.9805V3.36009H2.93047Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19307">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
