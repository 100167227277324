import type { IconChildrenProps } from "../icon.types";

export const Task2 = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      d="M12.0478 3.36891C12.0774 3.36891 12.106 3.36891 12.1336 3.36891C12.8668 3.36891 13.4084 3.86497 13.4778 4.58275H13.6999C15.0803 4.58275 15.7773 5.29777 15.7773 6.68283V14.5966C15.7773 15.9817 15.0803 16.6853 13.6999 16.6853H6.29806C4.92434 16.6853 4.23214 15.9817 4.2207 14.5966V6.68283C4.2207 5.29777 4.92434 4.58275 6.29806 4.58275H6.52684C6.59627 3.86497 7.13785 3.36891 7.87107 3.36891C7.9035 3.36891 7.93611 3.36891 7.96868 3.36891C8.26498 2.55737 9.09994 2 10.0081 2C10.9263 2 11.7534 2.55737 12.0478 3.36891ZM13.4823 5.54212C13.4459 6.301 12.8924 6.83223 12.1336 6.83223H7.8758C7.10599 6.83223 6.56272 6.301 6.52279 5.54212H6.3505C5.57855 5.54212 5.18015 5.95194 5.18015 6.70098V14.5785C5.18015 15.3275 5.57855 15.7373 6.3505 15.7373H13.6475C14.4194 15.7373 14.8178 15.3275 14.8178 14.5785V6.70098C14.8178 5.95194 14.4194 5.54212 13.6475 5.54212H13.4823ZM8.57717 4.11898H7.8758C7.50074 4.11898 7.27356 4.34415 7.27356 4.74206V5.45907C7.27356 5.85697 7.49402 6.08214 7.8758 6.08214H12.1336C12.5154 6.08214 12.7473 5.85026 12.7473 5.45907V4.74206C12.7473 4.34415 12.5154 4.11898 12.1336 4.11898H11.4389C11.4046 3.36295 10.7756 2.75008 10.0081 2.75008C9.24722 2.75008 8.61148 3.35624 8.57717 4.11898ZM10.6135 4.15328C10.6202 4.48656 10.3414 4.7654 10.0013 4.7654C9.66132 4.7654 9.38917 4.48656 9.38917 4.15328C9.38917 3.8133 9.66804 3.53445 10.0013 3.53445C10.3414 3.53445 10.6135 3.8133 10.6135 4.15328Z"
      fill={color}
    />
    <path
      d="M10.3368 14.2038C10.6696 14.2038 10.9386 14.0397 11.1503 13.7365L16.7621 4.94573C16.8717 4.75882 16.9948 4.51947 16.9948 4.3097C16.9948 3.87202 16.6028 3.57153 16.1698 3.57153C15.9122 3.57153 15.6595 3.72414 15.4726 4.01593L10.2958 12.2447L7.7242 9.01671C7.49627 8.71351 7.2569 8.61333 6.97455 8.61333C6.54827 8.61333 6.19531 8.97574 6.19531 9.41536C6.19531 9.63859 6.28877 9.86176 6.43666 10.0487L9.48221 13.7432C9.74644 14.0693 10.002 14.2038 10.3368 14.2038Z"
      fill={accentColor}
    />
  </>
);
