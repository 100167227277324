import type { IconChildrenProps } from "../../icon.types";

export const HazardsElectricalHazards = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M8.75065 10.3218V9.07178"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M11.2507 10.3218V9.07178"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M6.72461 7.04669C8.53378 5.23753 11.4663 5.23753 13.2746 7.04669"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M3.16262 7.651C2.13595 10.2835 2.68179 13.3877 4.80762 15.5135C7.67512 18.381 12.3243 18.381 15.1918 15.5135C17.3176 13.3877 17.8635 10.2835 16.8368 7.651"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M4.80859 5.13012C7.67609 2.26262 12.3253 2.26262 15.1928 5.13012"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      clipRule="evenodd"
      d="M10.0005 14.4884V14.4884C8.43966 14.4884 7.17383 13.2226 7.17383 11.6618V11.1551C7.17383 10.6951 7.54716 10.3218 8.00716 10.3218H11.9938C12.4538 10.3218 12.8272 10.6951 12.8272 11.1551V11.6618C12.8272 13.2226 11.5613 14.4884 10.0005 14.4884Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M9.9987 14.4884V17.6634"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
  </>
);
