import type { IconChildrenProps } from "../icon.types";

export const DateAndTime = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6528 3C13.8983 3 14.1024 3.17688 14.1447 3.41012L14.1528 3.5V3.77989H14.2857C16.0609 3.77989 17.5 5.27228 17.5 7.11323V9H16.4286V8.00212H3.57143V13.6688C3.57143 14.8403 4.44558 15.8001 5.55436 15.8849L5.71429 15.891H10V17.0021H5.71429C3.93908 17.0021 2.5 15.5097 2.5 13.6688V7.11323C2.5 5.27228 3.93908 3.77989 5.71429 3.77989H5.85V3.5C5.85 3.22386 6.07386 3 6.35 3C6.59546 3 6.79961 3.17688 6.84194 3.41012L6.85 3.5V3.77989H13.1528V3.5C13.1528 3.22386 13.3767 3 13.6528 3ZM5.85 4.891V5.00212L5.85806 5.09199C5.90039 5.32524 6.10454 5.50212 6.35 5.50212C6.62614 5.50212 6.85 5.27826 6.85 5.00212V4.891H13.1528V5.00212L13.1609 5.09199C13.2032 5.32524 13.4073 5.50212 13.6528 5.50212C13.9289 5.50212 14.1528 5.27826 14.1528 5.00212V4.891H14.2857L14.4456 4.8971C15.5192 4.97923 16.3727 5.88155 16.4259 7.00212H3.57537L3.57731 6.94738C3.65911 5.79754 4.58461 4.891 5.71429 4.891H5.85Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 10C12.567 10 11 11.567 11 13.5C11 15.433 12.567 17 14.5 17C16.433 17 18 15.433 18 13.5C18 11.567 16.433 10 14.5 10ZM14.5122 10.9578C15.9229 10.9578 17.0666 12.1014 17.0666 13.5122C17.0666 14.9229 15.9229 16.0666 14.5122 16.0666C13.1014 16.0666 11.9578 14.9229 11.9578 13.5122C11.9578 12.1014 13.1014 10.9578 14.5122 10.9578Z"
      fill={color}
    />
    <path
      d="M14.2199 11.9171C14.3049 11.9171 14.3756 11.9784 14.3902 12.0591L14.393 12.0903V13.5764L15.3112 14.495C15.3713 14.5551 15.378 14.6483 15.3312 14.7158L15.3112 14.7398C15.2511 14.7999 15.1579 14.8066 15.0904 14.7598L15.0665 14.7398L14.0469 13.7198V12.0903C14.0469 11.9946 14.1244 11.9171 14.2199 11.9171Z"
      fill={accentColor}
    />
    <ellipse
      cx="14.2192"
      cy="13.6477"
      rx="0.346138"
      ry="0.346273"
      fill={accentColor}
    />
  </>
);
