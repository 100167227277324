import type { IconChildrenProps } from "../../icon.types";

export const HazardsErgonomicsManualLifting = ({
  color,
  oppositeColor,
}: IconChildrenProps) => (
  <>
    <path
      d="M13.7395 2.73756C14.309 3.30708 14.309 4.23044 13.7395 4.79995C13.17 5.36947 12.2466 5.36947 11.6771 4.79995C11.1076 4.23044 11.1076 3.30707 11.6771 2.73756C12.2467 2.16805 13.17 2.16805 13.7395 2.73756"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32506"
    />
    <mask id="path-2-inside-1_48_10425" fill={oppositeColor}>
      <path
        clipRule="evenodd"
        d="M4.8438 9.97689H8.4088C8.69019 9.97689 8.91797 9.74883 8.91797 9.46772V5.90272C8.91797 5.62133 8.68991 5.39355 8.4088 5.39355H4.84408C4.56269 5.39355 4.33491 5.62161 4.33491 5.90272V9.46744C4.33464 9.74883 4.56269 9.97689 4.8438 9.97689Z"
        fillRule="evenodd"
      />
    </mask>
    <path
      d="M4.33491 9.46744L5.65998 9.46744L5.65998 9.46875L4.33491 9.46744ZM4.8438 8.65182H8.4088V11.302H4.8438V8.65182ZM8.4088 8.65182C7.95791 8.65182 7.59291 9.01749 7.59291 9.46772H10.243C10.243 10.4802 9.42247 11.302 8.4088 11.302V8.65182ZM7.59291 9.46772V5.90272H10.243V9.46772H7.59291ZM7.59291 5.90272C7.59291 6.35362 7.95857 6.71862 8.4088 6.71862V4.06849C9.42125 4.06849 10.243 4.88905 10.243 5.90272H7.59291ZM8.4088 6.71862H4.84408V4.06849H8.4088V6.71862ZM4.84408 6.71862C5.29497 6.71862 5.65998 6.35295 5.65998 5.90272H3.00985C3.00985 4.89027 3.83041 4.06849 4.84408 4.06849V6.71862ZM5.65998 5.90272V9.46744H3.00985V5.90272H5.65998ZM5.65998 9.46875C5.66042 9.01753 5.29472 8.65182 4.8438 8.65182V11.302C3.83066 11.302 3.00885 10.4801 3.00985 9.46614L5.65998 9.46875Z"
      fill={color}
      mask="url(#path-2-inside-1_48_10425)"
    />
    <path
      d="M2.83594 12.5601L2.83594 17.5601"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32506"
    />
    <path
      d="M0.835937 14.5598L2.83594 12.5598L4.83594 14.5598"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32506"
    />
    <path
      d="M9.76758 10.9421L15.0679 7.12744L16.8347 12.8695L13.3011 15.7405L15.0679 18.6115H12.7122"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32506"
    />
  </>
);
