import { AR_CATEGORY_VEHICLE } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseSpeedLimit = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19330)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9819 15.7099C17.0319 15.6199 17.0719 15.5199 17.0719 15.4099V10.3699C17.0719 10.3699 17.0719 10.3399 17.0619 10.3199C17.0619 10.1999 17.0619 10.0899 16.9919 9.97988L14.3119 5.18988C14.2019 4.99988 14.0019 4.87988 13.7919 4.87988H10.6019C10.2719 4.87988 10.0019 5.14988 10.0019 5.47988V6.47988H7.14187C6.81187 6.47988 6.54187 6.74988 6.54187 7.07988V9.76988H5.43187C5.10187 9.76988 4.83187 10.0399 4.83187 10.3699V15.4099C4.83187 15.4999 4.85188 15.5799 4.88188 15.6499C4.98187 16.6299 5.71187 17.4399 6.66187 17.6399H3.33187C3.10187 17.6799 2.92188 17.8799 2.92188 18.1199C2.92188 18.3899 3.14188 18.5999 3.40188 18.5999H16.3719C16.6019 18.5599 16.7819 18.3599 16.7819 18.1199C16.7819 17.8499 16.5619 17.6399 16.3019 17.6399H15.2119C16.1419 17.4399 16.8519 16.6599 16.9819 15.6999V15.7099ZM11.1919 6.07988H13.4319L15.4419 9.66988H11.1819V6.07988H11.1919ZM7.73187 7.67988H9.72188V9.66988H7.73187V7.67988ZM15.8719 10.9699V13.4399C15.5319 13.2399 15.1419 13.1199 14.7219 13.1199C13.6719 13.1199 12.7919 13.8399 12.5219 14.8099H9.32187C9.05187 13.8399 8.18187 13.1199 7.12187 13.1199C6.71187 13.1199 6.34188 13.2399 6.01188 13.4199V10.9599H15.8719V10.9699ZM6.05188 15.4099C6.05188 14.8099 6.54187 14.3199 7.14187 14.3199C7.74187 14.3199 8.23187 14.8099 8.23187 15.4099C8.23187 16.0099 7.74187 16.4999 7.14187 16.4999C6.54187 16.4999 6.05188 16.0099 6.05188 15.4099ZM7.62187 17.6399C8.45187 17.4599 9.11188 16.8199 9.33187 16.0099H12.5319C12.7519 16.8299 13.4119 17.4599 14.2419 17.6399H7.62187ZM14.7319 16.4899C14.1319 16.4899 13.6419 15.9999 13.6419 15.3999C13.6419 14.7999 14.1319 14.3099 14.7319 14.3099C15.3319 14.3099 15.8219 14.7999 15.8219 15.3999C15.8219 15.9999 15.3319 16.4899 14.7319 16.4899Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.73953 12.0501C3.50953 12.0901 3.32953 12.2901 3.32953 12.5301C3.32953 12.8001 3.54953 13.0101 3.80953 13.0101H6.10953C6.33953 12.9701 6.51953 12.7701 6.51953 12.5301C6.51953 12.2601 6.29953 12.0501 6.03953 12.0501H3.73953Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.03953 10.9901C5.26953 10.9601 5.44953 10.7601 5.44953 10.5101C5.44953 10.2401 5.22953 10.0301 4.96953 10.0301H2.67953C2.44953 10.0701 2.26953 10.2701 2.26953 10.5101C2.26953 10.7801 2.48953 10.9901 2.74953 10.9901H5.04953H5.03953Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.80953 9.05009H6.10953C6.33953 9.01009 6.51953 8.81009 6.51953 8.57009C6.51953 8.30009 6.29953 8.09009 6.03953 8.09009H3.73953C3.50953 8.13009 3.32953 8.33009 3.32953 8.57009C3.32953 8.84009 3.54953 9.05009 3.80953 9.05009Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19330">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
