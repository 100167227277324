import type { IconChildrenProps } from "../icon.types";

export const User = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M12.8175 3.34504C11.1728 1.88436 8.7163 1.913 7.10346 3.43096C5.47191 4.96655 5.50199 7.58421 7.08078 9.26167C8.61637 10.8932 11.234 10.8631 12.9115 9.28435L13.0004 9.19808C14.5711 7.62648 14.5417 5.06082 12.923 3.44213L12.9115 3.43096L12.8175 3.34504ZM7.82914 4.202C9.0372 3.06501 10.8643 3.03917 12.1005 4.12448L12.1823 4.19851L12.2546 4.27396C13.405 5.50813 13.3785 7.39076 12.1858 8.51332C10.9204 9.70426 8.97243 9.72665 7.85182 8.53599C6.66088 7.27062 6.63849 5.32261 7.82914 4.202Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      d="M9.2447 12.0118C9.53709 12.0118 9.77411 12.2488 9.77411 12.5412C9.77411 12.8153 9.56579 13.0408 9.29883 13.0679L9.2447 13.0706H7.04235C5.21709 13.0706 3.76 14.5277 3.76 16.353C3.76 16.6453 3.52297 16.8824 3.23058 16.8824C2.9382 16.8824 2.70117 16.6453 2.70117 16.353C2.70117 13.9831 4.56848 12.0763 6.92225 12.0134L7.04235 12.0118H9.2447Z"
      fill={color}
    />
    <path
      d="M8.77352 12.0117C8.48114 12.0117 8.24411 12.2488 8.24411 12.5411C8.24411 12.8153 8.45244 13.0407 8.7194 13.0678L8.77352 13.0706H12.9759C14.8011 13.0706 16.2582 14.5276 16.2582 16.3529C16.2582 16.6453 16.4953 16.8823 16.7876 16.8823C17.08 16.8823 17.3171 16.6453 17.3171 16.3529C17.3171 13.983 15.4497 12.0762 13.096 12.0133L12.9759 12.0117H8.77352Z"
      fill={color}
    />
  </>
);
