import { Route, Routes } from "react-router-dom";
import { CallbackRoute } from "../routes/callback-route/callback-route";
import { ChangePasswordRoute } from "../routes/change-password-route/change-password-route";
import { GuardRoute } from "../routes/guard-route/guard-route";
import { LoginRoute } from "../routes/login-route/login-route";
import { NotFoundRoute } from "../routes/not-found-route/not-found-route";
import { SignUpRoute } from "../routes/sign-up-route/sign-up-route";

export const AppRoutes = () => (
  <Routes>
    <Route path="/callback" element={<CallbackRoute />} />
    <Route element={<GuardRoute />}>
      <Route path="/change-password" element={<ChangePasswordRoute />} />
      <Route path="/login" element={<LoginRoute />} />
      <Route path="/signup/:id" element={<SignUpRoute />} />
    </Route>
    <Route path="*" element={<NotFoundRoute />} />
  </Routes>
);
