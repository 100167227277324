// Anonymization
export const ANONYMIZATION_DATE = "DATE_ANONYMIZATION";
export type ANONYMIZATION_DATE = typeof ANONYMIZATION_DATE;

// Alert Rule Categories
export const AR_CATEGORY_ANOMALY = "ANOMALY";
export const AR_CATEGORY_AREA_CONTROLS = "AREA_CONTROLS";
export const AR_CATEGORY_BEHAVIOR = "BEHAVIOR";
export const AR_CATEGORY_CUSTOM = "CUSTOM";
export const AR_CATEGORY_ERGONOMICS = "ERGONOMICS";
export const AR_CATEGORY_PANDEMIC = "PANDEMIC";
export const AR_CATEGORY_PPE = "PPE";
export const AR_CATEGORY_PUBLIC_SAFETY = "PUBLIC_SAFETY";
export const AR_CATEGORY_VEHICLE = "VEHICLE";
export const AR_CATEGORY_TEST_RULE = "TEST_RULE";

export type AR_CATEGORY = (typeof AR_CATEGORY)[number];

export const AR_CATEGORY = [
  AR_CATEGORY_ANOMALY,
  AR_CATEGORY_AREA_CONTROLS,
  AR_CATEGORY_BEHAVIOR,
  AR_CATEGORY_CUSTOM,
  AR_CATEGORY_ERGONOMICS,
  AR_CATEGORY_PANDEMIC,
  AR_CATEGORY_PPE,
  AR_CATEGORY_TEST_RULE,
  AR_CATEGORY_PUBLIC_SAFETY,
  AR_CATEGORY_VEHICLE,
] as const;

// Anomaly Detection
export const AR_ANOMALY_CLEAN_PEDESTRIAN_WAYS = "CLEAN_PEDESTRIAN_WAYS";
export const AR_ANOMALY_CLEAN_VEHICLE_ROADS = "CLEAN_VEHICLE_ROADS";
export const AR_ANOMALY_FIRE_DETECTION = "FIRE_DETECTION";
export const AR_ANOMALY_LEAKAGE_AND_SPILL = "LEAKAGE_AND_SPILL";
export const AR_ANOMALY_OPEN_CLOSED_DOORS = "OPEN_CLOSED_DOORS";
export const AR_ANOMALY_SMOKE_DETECTION = "SMOKE_DETECTION";
export const AR_ANOMALY_UNATTENDED_OBJECT_DETECTION =
  "UNATTENDED_OBJECT_DETECTION";
export type AR_ANOMALY = (typeof AR_ANOMALY)[number];

export const AR_ANOMALY = [
  AR_ANOMALY_CLEAN_PEDESTRIAN_WAYS,
  AR_ANOMALY_CLEAN_VEHICLE_ROADS,
  AR_ANOMALY_FIRE_DETECTION,
  AR_ANOMALY_LEAKAGE_AND_SPILL,
  AR_ANOMALY_OPEN_CLOSED_DOORS,
  AR_ANOMALY_SMOKE_DETECTION,
  AR_ANOMALY_UNATTENDED_OBJECT_DETECTION,
] as const;

// Behavior and Action Base Rules
export const AR_BEHAVIOR_BANNISTER_USAGE = "BANNISTER_USAGE";
export const AR_BEHAVIOR_CLIMBING = "CLIMBING";
export const AR_BEHAVIOR_CONTACT_WITH_ELECTRICITY = "CONTACT_WITH_ELECTRICITY";
export const AR_BEHAVIOR_GROUPING = "GROUPING";
export const AR_BEHAVIOR_MANDOWN = "MANDOWN";
export const AR_BEHAVIOR_PEDESTRIAN_WAY_VIOLATION = "PEDESTRIAN_WAY_VIOLATION";
export const AR_BEHAVIOR_RESTRICTED_HEIGHT = "RESTRICTED_HEIGHT";
export const AR_BEHAVIOR_RUNNING = "RUNNING";

export type AR_BEHAVIOR = (typeof AR_BEHAVIOR)[number];

export const AR_BEHAVIOR = [
  AR_BEHAVIOR_BANNISTER_USAGE,
  AR_BEHAVIOR_CLIMBING,
  AR_BEHAVIOR_CONTACT_WITH_ELECTRICITY,
  AR_BEHAVIOR_GROUPING,
  AR_BEHAVIOR_PEDESTRIAN_WAY_VIOLATION,
  AR_BEHAVIOR_MANDOWN,
  AR_BEHAVIOR_RESTRICTED_HEIGHT,
  AR_BEHAVIOR_RUNNING,
] as const;

// Confined Space
export const AR_CONFINED_SPACE_AMBIENT_LIGHT = "OCCUPANCY_LIGHTING";
export const AR_CONFINED_SPACE_CRANE_AREA = "CRANE_AREA";
export const AR_CONFINED_SPACE_HOT_OBJECT_DETECTION = "HOT_OBJECT_DETECTION";
export const AR_CONFINED_SPACE_LIGHT_CONTROLS = "LIGHT_CONTROLS";
export const AR_CONFINED_SPACE_LINE_OF_FIRE = "LINE_OF_FIRE";
export const AR_CONFINED_SPACE_MACHINE_AREA_CONTROL = "MACHINE_AREA_CONTROL";
export const AR_CONFINED_SPACE_MACHINE_MAN_RESTRICTED_AREA =
  "MACHINE_MAN_RESTRICTED_AREA";
export const AR_CONFINED_SPACE_MAX_WORKER = "MAX_WORKER_IN_CONFINED_SPACE";
export const AR_CONFINED_SPACE_MIN_WORKER = "MIN_WORKER_IN_CONFINED_SPACE";
export const AR_CONFINED_SPACE_PERSON_MANDATORY_AREA = "PERSON_MANDATORY_AREA";
export const AR_CONFINED_SPACE_PROHIBITED_OBJECT_DETECTION =
  "PROHIBITED_OBJECT_DETECTION";
export const AR_CONFINED_SPACE_SAFE_LIFTING = "LIFTING_WITH_MIN_WORKER";
export const AR_CONFINED_SPACE_STATIC_DELIMITATION_AREA =
  "STATIC_DELIMITATION_AREA";
export const AR_CONFINED_SPACE_TIME_LIMITED_AREA = "TIME_LIMITED_AREA";

export type AR_CONFINED_SPACE = (typeof AR_CONFINED_SPACE)[number];

export const AR_CONFINED_SPACE = [
  AR_CONFINED_SPACE_AMBIENT_LIGHT,
  AR_CONFINED_SPACE_CRANE_AREA,
  AR_CONFINED_SPACE_HOT_OBJECT_DETECTION,
  AR_CONFINED_SPACE_LIGHT_CONTROLS,
  AR_CONFINED_SPACE_LINE_OF_FIRE,
  AR_CONFINED_SPACE_MACHINE_AREA_CONTROL,
  AR_CONFINED_SPACE_MACHINE_MAN_RESTRICTED_AREA,
  AR_CONFINED_SPACE_MAX_WORKER,
  AR_CONFINED_SPACE_MIN_WORKER,
  AR_CONFINED_SPACE_PERSON_MANDATORY_AREA,
  AR_CONFINED_SPACE_PROHIBITED_OBJECT_DETECTION,
  AR_CONFINED_SPACE_SAFE_LIFTING,
  AR_CONFINED_SPACE_STATIC_DELIMITATION_AREA,
  AR_CONFINED_SPACE_TIME_LIMITED_AREA,
] as const;

// Ergonomics
export const AR_ERGONOMICS_REBA = "REBA";
export const AR_ERGONOMICS_RULA = "RULA";

export type AR_ERGONOMICS = (typeof AR_ERGONOMICS)[number];

export const AR_ERGONOMICS = [AR_ERGONOMICS_REBA, AR_ERGONOMICS_RULA] as const;

export const AR_ERGONOMICS_FORCE_LOAD_SCORE_0 = 0;
export const AR_ERGONOMICS_FORCE_LOAD_SCORE_1 = 1;
export const AR_ERGONOMICS_FORCE_LOAD_SCORE_2 = 2;
export const AR_ERGONOMICS_FORCE_LOAD_SCORES = [
  AR_ERGONOMICS_FORCE_LOAD_SCORE_0,
  AR_ERGONOMICS_FORCE_LOAD_SCORE_1,
  AR_ERGONOMICS_FORCE_LOAD_SCORE_2,
] as const;

export type AR_ERGONOMICS_FORCE_LOAD_SCORE =
  (typeof AR_ERGONOMICS_FORCE_LOAD_SCORES)[number];

export const AR_ERGONOMICS_COUPLING_GOOD = "GOOD";
export const AR_ERGONOMICS_COUPLING_FAIR = "FAIR";
export const AR_ERGONOMICS_COUPLING_POOR = "POOR";
export const AR_ERGONOMICS_COUPLING_UNMANAGEABLE = "UNMANAGEABLE";

export type AR_ERGONOMICS_COUPLING =
  | typeof AR_ERGONOMICS_COUPLING_FAIR
  | typeof AR_ERGONOMICS_COUPLING_GOOD
  | typeof AR_ERGONOMICS_COUPLING_POOR
  | typeof AR_ERGONOMICS_COUPLING_UNMANAGEABLE;

// Pandemic Control Measures
export const AR_PANDEMIC_FACE_MASK = "FACE_MASK";
export const AR_PANDEMIC_SOCIAL_DISTANCE = "SOCIAL_DISTANCE";
// export const AR_PANDEMIC_BODY_HEAT_MEASUREMENT = "BODY_HEAT_MEASUREMENT";

export type AR_PANDEMIC = (typeof AR_PANDEMIC)[number];

export const AR_PANDEMIC = [
  AR_PANDEMIC_FACE_MASK,
  AR_PANDEMIC_SOCIAL_DISTANCE,
  // AR_PANDEMIC_BODY_HEAT_MEASUREMENT,
] as const;

// PPE
export const AR_PPE_APRON = "APRON";
export const AR_PPE_CAL_SUIT = "CAL_SUIT";
export const AR_PPE_FACE_SHIELD = "FACE_SHIELD";
export const AR_PPE_GLASSES = "GLASSES";
export const AR_PPE_GLOVES = "GLOVE";
export const AR_PPE_HEARING_MUFF = "MUFF";
export const AR_PPE_HELMET = "HELMET";
export const AR_PPE_MASK = "MASK";
export const AR_PPE_PROTECTIVE_CLOTHING = "PROTECTIVE_CLOTHING";
export const AR_PPE_REFLECTIVE_VEST = "REFLECTIVE_VEST";
export const AR_PPE_SLEEVE = "SLEEVE";

export type AR_PPE = (typeof AR_PPE)[number];

export const AR_PPE = [
  AR_PPE_APRON,
  // AR_PPE_CAL_SUIT,
  AR_PPE_FACE_SHIELD,
  AR_PPE_GLASSES,
  AR_PPE_GLOVES,
  AR_PPE_HEARING_MUFF,
  AR_PPE_HELMET,
  AR_PPE_MASK,
  AR_PPE_PROTECTIVE_CLOTHING,
  AR_PPE_REFLECTIVE_VEST,
  AR_PPE_SLEEVE,
] as const;

// Public Safety
export const AR_PUBLIC_SAFETY_CONTACT_WITH_TRAIN = "CONTACT_WITH_TRAIN";
export const AR_PUBLIC_SAFETY_CROSSING_YELLOW_LINE = "CROSSING_THE_YELLOW_LINE";

export type AR_PUBLIC_SAFETY = (typeof AR_PUBLIC_SAFETY)[number];

export const AR_PUBLIC_SAFETY = [
  AR_PUBLIC_SAFETY_CONTACT_WITH_TRAIN,
  AR_PUBLIC_SAFETY_CROSSING_YELLOW_LINE,
] as const;

// Vehicle Controls
export const AR_VEHICLE_CONTROLS_DYNAMIC_DELIMITATION_AREA =
  "DYNAMIC_DELIMITATION_AREA";
export const AR_VEHICLE_CONTROLS_NEAR_MISS = "VEHICLE_NEAR_MISS";
export const AR_VEHICLE_CONTROLS_ONE_WAY_TRAFFIC = "ONE_WAY_TRAFFIC";
export const AR_VEHICLE_CONTROLS_OPERATION_ZONE = "VEHICLE_OPERATION_ZONE";
export const AR_VEHICLE_CONTROLS_RESTRICTED_AREA = "VEHICLE_RESTRICTED_AREA";
export const AR_VEHICLE_CONTROLS_SPEED_LIMIT = "SPEED_LIMIT";
export const AR_VEHICLE_CONTROLS_VEHICLE_PEDESTRIAN_INTERACTION =
  "VEHICLE_PEDESTRIAN_INTERACTION";
export const AR_VEHICLE_CONTROLS_VEHICLE_PPE_COMPLIANCE =
  "VEHICLE_PPE_COMPLIANCE";
export const AR_VEHICLE_CONTROLS_VEHICLE_VEHICLE_INTERACTION =
  "VEHICLE_VEHICLE_INTERACTION";

export type AR_VEHICLE_CONTROLS = (typeof AR_VEHICLE_CONTROLS)[number];

export const AR_VEHICLE_CONTROLS = [
  AR_VEHICLE_CONTROLS_DYNAMIC_DELIMITATION_AREA,
  AR_VEHICLE_CONTROLS_NEAR_MISS,
  AR_VEHICLE_CONTROLS_ONE_WAY_TRAFFIC,
  AR_VEHICLE_CONTROLS_OPERATION_ZONE,
  AR_VEHICLE_CONTROLS_RESTRICTED_AREA,
  AR_VEHICLE_CONTROLS_SPEED_LIMIT,
  AR_VEHICLE_CONTROLS_VEHICLE_PEDESTRIAN_INTERACTION,
  AR_VEHICLE_CONTROLS_VEHICLE_PPE_COMPLIANCE,
  AR_VEHICLE_CONTROLS_VEHICLE_VEHICLE_INTERACTION,
] as const;

// Custom
export const AR_CUSTOM = "CUSTOM";
export type AR_CUSTOM = typeof AR_CUSTOM;

// Exclusion zone
export const AR_EXCLUSION_ZONE = "EXCLUSION_ZONE";
export type AR_EXCLUSION_ZONE = typeof AR_EXCLUSION_ZONE;

// Custom rule use this type while creation but then will change
export const AR_UNKNOWN = "UNKNOWN";
export type AR_UNKNOWN = typeof AR_UNKNOWN;

export type AR_PRESET_ID = (typeof AR_PRESET_ID)[number];

export const AR_PRESET_ID = [
  ...AR_ANOMALY,
  ...AR_BEHAVIOR,
  ...AR_CONFINED_SPACE,
  ...AR_ERGONOMICS,
  ...AR_PANDEMIC,
  ...AR_PPE,
  ...AR_PUBLIC_SAFETY,
  ...AR_VEHICLE_CONTROLS,
  AR_CUSTOM,
  AR_EXCLUSION_ZONE,
  AR_UNKNOWN,
] as const;

// Presets that camera calibration is required
export type AR_PRESET_ID_REQUIRE_CALIBRATION =
  | typeof AR_BEHAVIOR_GROUPING
  | typeof AR_BEHAVIOR_MANDOWN
  | typeof AR_BEHAVIOR_RUNNING
  | typeof AR_CONFINED_SPACE_CRANE_AREA
  | typeof AR_CONFINED_SPACE_LINE_OF_FIRE
  | typeof AR_CONFINED_SPACE_SAFE_LIFTING
  | typeof AR_VEHICLE_CONTROLS_NEAR_MISS
  | typeof AR_VEHICLE_CONTROLS_ONE_WAY_TRAFFIC
  | typeof AR_VEHICLE_CONTROLS_SPEED_LIMIT
  | typeof AR_VEHICLE_CONTROLS_VEHICLE_PEDESTRIAN_INTERACTION
  | typeof AR_VEHICLE_CONTROLS_VEHICLE_PPE_COMPLIANCE
  | typeof AR_VEHICLE_CONTROLS_VEHICLE_VEHICLE_INTERACTION;

export const AR_PRESET_ID_REQUIRE_CALIBRATION: AR_PRESET_ID_REQUIRE_CALIBRATION[] =
  [
    AR_BEHAVIOR_GROUPING,
    AR_BEHAVIOR_MANDOWN,
    AR_BEHAVIOR_RUNNING,
    AR_CONFINED_SPACE_CRANE_AREA,
    AR_CONFINED_SPACE_LINE_OF_FIRE,
    AR_CONFINED_SPACE_SAFE_LIFTING,
    AR_VEHICLE_CONTROLS_NEAR_MISS,
    AR_VEHICLE_CONTROLS_ONE_WAY_TRAFFIC,
    AR_VEHICLE_CONTROLS_SPEED_LIMIT,
    AR_VEHICLE_CONTROLS_VEHICLE_PEDESTRIAN_INTERACTION,
    AR_VEHICLE_CONTROLS_VEHICLE_PPE_COMPLIANCE,
    AR_VEHICLE_CONTROLS_VEHICLE_VEHICLE_INTERACTION,
  ];

// Preset that camera calibration is recommended
export type AR_PRESET_ID_RECOMMEND_CALIBRATION =
  typeof AR_PANDEMIC_SOCIAL_DISTANCE;

export const AR_PRESET_ID_RECOMMEND_CALIBRATION: AR_PRESET_ID_RECOMMEND_CALIBRATION[] =
  [AR_PANDEMIC_SOCIAL_DISTANCE];

// Group operator
export const AR_GROUP_OPERATOR_AND = "AND";
export const AR_GROUP_OPERATOR_NAND = "NAND";
export const AR_GROUP_OPERATOR_NOR = "NOR";
export const AR_GROUP_OPERATOR_OR = "OR";
export const AR_GROUP_OPERATOR_XOR = "XOR";
export const AR_GROUP_OPERATOR_XNOR = "XNOR";

export type AR_GROUP_OPERATOR =
  | typeof AR_GROUP_OPERATOR_AND
  | typeof AR_GROUP_OPERATOR_NAND
  | typeof AR_GROUP_OPERATOR_NOR
  | typeof AR_GROUP_OPERATOR_OR
  | typeof AR_GROUP_OPERATOR_XNOR
  | typeof AR_GROUP_OPERATOR_XOR;

export const AR_GROUP_OPERATOR: AR_GROUP_OPERATOR[] = [
  AR_GROUP_OPERATOR_AND,
  AR_GROUP_OPERATOR_NAND,
  AR_GROUP_OPERATOR_NOR,
  AR_GROUP_OPERATOR_OR,
  AR_GROUP_OPERATOR_XOR,
  AR_GROUP_OPERATOR_XNOR,
];

// Accuracy
export const AR_ACCURACY_AT_LEAST = "AT_LEAST";
export const AR_ACCURACY_EXACT = "EXACT";

export type AR_ACCURACY =
  | typeof AR_ACCURACY_AT_LEAST
  | typeof AR_ACCURACY_EXACT;

export const AR_ACCURACY: AR_ACCURACY[] = [
  AR_ACCURACY_AT_LEAST,
  AR_ACCURACY_EXACT,
];

export const AR_DIRECTION_ANY = "ANY";
export const AR_DIRECTION_EAST = "EAST";
export const AR_DIRECTION_NORTH = "NORTH";
export const AR_DIRECTION_NORTH_EAST = "NORTH_EAST";
export const AR_DIRECTION_NORTH_WEST = "NORTH_WEST";
export const AR_DIRECTION_SOUTH = "SOUTH";
export const AR_DIRECTION_SOUTH_EAST = "SOUTH_EAST";
export const AR_DIRECTION_SOUTH_WEST = "SOUTH_WEST";
export const AR_DIRECTION_WEST = "WEST";

export type AR_DIRECTION =
  | typeof AR_DIRECTION_ANY
  | typeof AR_DIRECTION_EAST
  | typeof AR_DIRECTION_NORTH
  | typeof AR_DIRECTION_NORTH_EAST
  | typeof AR_DIRECTION_NORTH_WEST
  | typeof AR_DIRECTION_SOUTH
  | typeof AR_DIRECTION_SOUTH_EAST
  | typeof AR_DIRECTION_SOUTH_WEST
  | typeof AR_DIRECTION_WEST;

export const AR_DIRECTION: AR_DIRECTION[] = [
  AR_DIRECTION_ANY,
  AR_DIRECTION_EAST,
  AR_DIRECTION_NORTH,
  AR_DIRECTION_NORTH_EAST,
  AR_DIRECTION_NORTH_WEST,
  AR_DIRECTION_SOUTH,
  AR_DIRECTION_SOUTH_EAST,
  AR_DIRECTION_SOUTH_WEST,
  AR_DIRECTION_WEST,
];

//Control Points
export const AR_CONTROL_POINT_BOTTOM = "BOTTOM";
export const AR_CONTROL_POINT_CENTER = "CENTER";

export type AR_CONTROL_POINT =
  | typeof AR_CONTROL_POINT_BOTTOM
  | typeof AR_CONTROL_POINT_CENTER;

export const AR_CONTROL_POINT: AR_CONTROL_POINT[] = [
  AR_CONTROL_POINT_BOTTOM,
  AR_CONTROL_POINT_CENTER,
];

export const AR_ACTION_DRILL = "DRILL";
export const AR_ACTION_OBSERVATION = "OBSERVATION";
export type AR_ACTION = typeof AR_ACTION_DRILL | typeof AR_ACTION_OBSERVATION;
export const AR_ACTION: AR_ACTION[] = [AR_ACTION_DRILL, AR_ACTION_OBSERVATION];

// Severity
export const AR_SEVERITY_LOW = "LOW";
export const AR_SEVERITY_MEDIUM = "MEDIUM";
export const AR_SEVERITY_HIGH = "HIGH";

export type AR_SEVERITY =
  | typeof AR_SEVERITY_HIGH
  | typeof AR_SEVERITY_LOW
  | typeof AR_SEVERITY_MEDIUM;

export const AR_SEVERITY: AR_SEVERITY[] = [
  AR_SEVERITY_MEDIUM,
  AR_SEVERITY_LOW,
  AR_SEVERITY_HIGH,
];

/* eslint-disable sort-keys */
export const AR_SEVERITY_ORDER = {
  HIGH: 1,
  MEDIUM: 2,
  LOW: 3,
  NOT_ASSIGNED: 4,
};
/* eslint-enable sort-keys */

// Vehicle types
export const AR_VEHICLE_AMR_WITH_SHELVES = "AMR_WITH_SHELVES";
export const AR_VEHICLE_AUTOMATED_GUIDED_VEHICLE = "AUTOMATED_GUIDED_VEHICLE";
export const AR_VEHICLE_AUTOMATIC_BALE_OPENER = "AUTOMATIC_BALE_OPENER";
export const AR_VEHICLE_AUTO_RICKSHAW = "AUTO_RICKSHAW";
export const AR_VEHICLE_AUTONOMOUS_CART = "AUTONOMOUS_CART";
export const AR_VEHICLE_BICYCLE = "BICYCLE";
export const AR_VEHICLE_BOBBIN = "BOBBIN";
export const AR_VEHICLE_BOBBIN_CART = "BOBBIN_CART";
export const AR_VEHICLE_BUS = "BUS";
export const AR_VEHICLE_CAR = "CAR";
export const AR_VEHICLE_CARRY_CART = "CARRY_CART";
export const AR_VEHICLE_C_SHAPED_CRANE_HEAD = "C_SHAPED_CRANE_HEAD";
export const AR_VEHICLE_CRANE_CABINET_FRONT = "CRANE_CABINET_FRONT";
export const AR_VEHICLE_CRANE_CABINET_SIDE = "CRANE_CABINET_SIDE";
export const AR_VEHICLE_CRANE_HEAD_V2 = "CRANE_HEAD_V2";
export const AR_VEHICLE_CRANE_HEAD_V3 = "CRANE_HEAD_V3";
export const AR_VEHICLE_CRANE_HEAD_V4 = "CRANE_HEAD_V4";
export const AR_VEHICLE_EXCAVATOR = "EXCAVATOR";
export const AR_VEHICLE_FORKLIFT = "FORKLIFT";
export const AR_VEHICLE_FRAME_TRANSPORTER = "FRAME_TRANSPORTER";
export const AR_VEHICLE_GRAY_CYLINDER = "GRAY_CYLINDER";
export const AR_VEHICLE_GRAY_CYLINDER_CART = "GRAY_CYLINDER_CART";
export const AR_VEHICLE_GOLF_CART = "GOLF_CART";
export const AR_VEHICLE_INVERSE_T_CRANE_HEAD = "INVERSE_T_CRANE_HEAD";
export const AR_VEHICLE_LOADER = "LOADER";
export const AR_VEHICLE_LOWBED_TRAILER = "LOWBED_TRAILER";
export const AR_VEHICLE_MAFI = "MAFI";
export const AR_VEHICLE_MAGNETIC_LIFTING_BEAM = "MAGNETIC_LIFTING_BEAM";
export const AR_VEHICLE_MANLIFT_CLOSED = "MANLIFT_CLOSED";
export const AR_VEHICLE_MANUAL_FORKLIFT = "MANUAL_FORKLIFT";
export const AR_VEHICLE_MINICRANE_BLOCK = "MINICRANE_BLOCK";
export const AR_VEHICLE_MINICRANE_CYLINDER = "MINICRANE_CYLINDER";
export const AR_VEHICLE_MINICRANE_CYLINDER_V2 = "MINICRANE_CYLINDER_V2";
export const AR_VEHICLE_MINICRANE_CYLINDER_V3 = "MINICRANE_CYLINDER_V3";
export const AR_VEHICLE_MINICRANE_V4 = "MINICRANE_V4";
export const AR_VEHICLE_MINICRANE_RECTANGLE = "MINICRANE_RECTANGLE";
export const AR_VEHICLE_MINICRANE_TRIANGLE = "MINICRANE_TRIANGLE";
export const AR_VEHICLE_MILKRUN = "MILKRUN";
export const AR_VEHICLE_MOTORCYCLE = "MOTORCYCLE";
export const AR_VEHICLE_MULTI_SERVICE_VEHICLE = "MULTI_SERVICE_VEHICLE";
export const AR_VEHICLE_NARROW_AISLE_FORKLIFT = "NARROW_AISLE_FORKLIFT";
export const AR_VEHICLE_PAINT_CART = "PAINT_CART";
export const AR_VEHICLE_PENTAGONAL_CRANE_HEAD = "PENTAGONAL_CRANE_HEAD";
export const AR_VEHICLE_SCORPION_MINICRANE = "SCORPION_MINICRANE";
export const AR_VEHICLE_SIDE_LOADER = "SIDE_LOADER";
export const AR_VEHICLE_SLAG_POT_CARRIER = "SLAG_POT_CARRIER";
export const AR_VEHICLE_STANDUP_CART = "STANDUP_CART";
export const AR_VEHICLE_TRACTOR = "TRACTOR";
export const AR_VEHICLE_TRICYCLE_TRUCK = "TRICYCLE_TRUCK";
export const AR_VEHICLE_TRIPLE_HOOK_CRANE = "TRIPLE_HOOK_CRANE";
export const AR_VEHICLE_TRUCK = "TRUCK";
export const AR_VEHICLE_TRANSFER_VEHICLE = "TRANSFER_VEHICLE";
export const AR_VEHICLE_TRANSFER_VEHICLE_V2 = "TRANSFER_VEHICLE_V2";
export const AR_VEHICLE_WAREHOUSE_TRAILER = "WAREHOUSE_TRAILER";
export const AR_VEHICLE_MAINTENANCE_CART_V1 = "MAINTENANCE_CART_V1";
export const AR_VEHICLE_PROBLEM_SOLVE_CART = "PROBLEM_SOLVE_CART";
export const AR_VEHICLE_U_BOAT = "U_BOAT";
export const AR_VEHICLE_IBOT_CART = "IBOT_CART";
export const AR_VEHICLE_U_BOAT_LOADED = "U_BOAT_LOADED";
export const AR_VEHICLE_AUTOMATED_GUIDED_VEHICLE_V2 =
  "AUTOMATED_GUIDED_VEHICLE_V2";
export const AR_VEHICLE_CLEANING_CART = "CLEANING_CART";

export type AR_VEHICLE =
  | typeof AR_VEHICLE_AMR_WITH_SHELVES
  | typeof AR_VEHICLE_AUTO_RICKSHAW
  | typeof AR_VEHICLE_AUTOMATED_GUIDED_VEHICLE
  | typeof AR_VEHICLE_AUTOMATED_GUIDED_VEHICLE_V2
  | typeof AR_VEHICLE_AUTONOMOUS_CART
  | typeof AR_VEHICLE_BICYCLE
  | typeof AR_VEHICLE_BOBBIN
  | typeof AR_VEHICLE_BOBBIN_CART
  | typeof AR_VEHICLE_BUS
  | typeof AR_VEHICLE_CAR
  | typeof AR_VEHICLE_CARRY_CART
  | typeof AR_VEHICLE_CLEANING_CART
  | typeof AR_VEHICLE_CRANE_CABINET_FRONT
  | typeof AR_VEHICLE_CRANE_CABINET_SIDE
  | typeof AR_VEHICLE_EXCAVATOR
  | typeof AR_VEHICLE_FORKLIFT
  | typeof AR_VEHICLE_FRAME_TRANSPORTER
  | typeof AR_VEHICLE_GOLF_CART
  | typeof AR_VEHICLE_GRAY_CYLINDER
  | typeof AR_VEHICLE_GRAY_CYLINDER_CART
  | typeof AR_VEHICLE_IBOT_CART
  | typeof AR_VEHICLE_LOADER
  | typeof AR_VEHICLE_LOWBED_TRAILER
  | typeof AR_VEHICLE_MAFI
  | typeof AR_VEHICLE_MAINTENANCE_CART_V1
  | typeof AR_VEHICLE_MANLIFT_CLOSED
  | typeof AR_VEHICLE_MANUAL_FORKLIFT
  | typeof AR_VEHICLE_MILKRUN
  | typeof AR_VEHICLE_MINICRANE_BLOCK
  | typeof AR_VEHICLE_MINICRANE_CYLINDER
  | typeof AR_VEHICLE_MINICRANE_CYLINDER_V2
  | typeof AR_VEHICLE_MINICRANE_CYLINDER_V3
  | typeof AR_VEHICLE_MINICRANE_RECTANGLE
  | typeof AR_VEHICLE_MINICRANE_TRIANGLE
  | typeof AR_VEHICLE_MINICRANE_V4
  | typeof AR_VEHICLE_MOTORCYCLE
  | typeof AR_VEHICLE_MULTI_SERVICE_VEHICLE
  | typeof AR_VEHICLE_NARROW_AISLE_FORKLIFT
  | typeof AR_VEHICLE_PAINT_CART
  | typeof AR_VEHICLE_PROBLEM_SOLVE_CART
  | typeof AR_VEHICLE_SIDE_LOADER
  | typeof AR_VEHICLE_SLAG_POT_CARRIER
  | typeof AR_VEHICLE_STANDUP_CART
  | typeof AR_VEHICLE_TRACTOR
  | typeof AR_VEHICLE_TRANSFER_VEHICLE
  | typeof AR_VEHICLE_TRANSFER_VEHICLE_V2
  | typeof AR_VEHICLE_TRICYCLE_TRUCK
  | typeof AR_VEHICLE_TRUCK
  | typeof AR_VEHICLE_U_BOAT
  | typeof AR_VEHICLE_WAREHOUSE_TRAILER;

export const AR_VEHICLE: AR_VEHICLE[] = [
  AR_VEHICLE_AMR_WITH_SHELVES,
  AR_VEHICLE_AUTOMATED_GUIDED_VEHICLE,
  AR_VEHICLE_AUTONOMOUS_CART,
  AR_VEHICLE_AUTO_RICKSHAW,
  AR_VEHICLE_BICYCLE,
  AR_VEHICLE_BOBBIN,
  AR_VEHICLE_BOBBIN_CART,
  AR_VEHICLE_BUS,
  AR_VEHICLE_CAR,
  AR_VEHICLE_CARRY_CART,
  AR_VEHICLE_CRANE_CABINET_FRONT,
  AR_VEHICLE_CRANE_CABINET_SIDE,
  AR_VEHICLE_EXCAVATOR,
  AR_VEHICLE_FORKLIFT,
  AR_VEHICLE_FRAME_TRANSPORTER,
  AR_VEHICLE_GRAY_CYLINDER,
  AR_VEHICLE_GRAY_CYLINDER_CART,
  AR_VEHICLE_GOLF_CART,
  AR_VEHICLE_LOADER,
  AR_VEHICLE_LOWBED_TRAILER,
  AR_VEHICLE_MAFI,
  AR_VEHICLE_MANLIFT_CLOSED,
  AR_VEHICLE_MANUAL_FORKLIFT,
  AR_VEHICLE_MILKRUN,
  AR_VEHICLE_MINICRANE_BLOCK,
  AR_VEHICLE_MINICRANE_CYLINDER,
  AR_VEHICLE_MINICRANE_CYLINDER_V2,
  AR_VEHICLE_MINICRANE_CYLINDER_V3,
  AR_VEHICLE_MINICRANE_V4,
  AR_VEHICLE_MINICRANE_RECTANGLE,
  AR_VEHICLE_MINICRANE_TRIANGLE,
  AR_VEHICLE_NARROW_AISLE_FORKLIFT,
  AR_VEHICLE_MOTORCYCLE,
  AR_VEHICLE_MULTI_SERVICE_VEHICLE,
  AR_VEHICLE_PAINT_CART,
  AR_VEHICLE_SIDE_LOADER,
  AR_VEHICLE_SLAG_POT_CARRIER,
  AR_VEHICLE_STANDUP_CART,
  AR_VEHICLE_TRACTOR,
  AR_VEHICLE_TRANSFER_VEHICLE,
  AR_VEHICLE_TRANSFER_VEHICLE_V2,
  AR_VEHICLE_TRICYCLE_TRUCK,
  AR_VEHICLE_TRUCK,
  AR_VEHICLE_WAREHOUSE_TRAILER,
  AR_VEHICLE_AUTOMATED_GUIDED_VEHICLE_V2,
  AR_VEHICLE_CLEANING_CART,
];

export const ADDITIONAL_VEHICLE_TYPES: AR_OBJECT[] = [
  AR_VEHICLE_MAINTENANCE_CART_V1,
  AR_VEHICLE_PROBLEM_SOLVE_CART,
  AR_VEHICLE_U_BOAT,
  AR_VEHICLE_IBOT_CART,
];

export const NEW_VEHICLE_TYPES: AR_OBJECT[] = [
  AR_VEHICLE_AUTOMATED_GUIDED_VEHICLE_V2,
  AR_VEHICLE_CLEANING_CART,
];

// Object types
export const AR_OBJECT_BOBBIN_VERTICAL = "BOBBIN_VERTICAL";
export const AR_OBJECT_BRIDGE_CRANE = "BRIDGE_CRANE";
export const AR_OBJECT_CAR = AR_VEHICLE_CAR;
export const AR_OBJECT_CRANE_SPREAD_BAR = "CRANE_SPREADER_BAR";
export const AR_OBJECT_FORKLIFT = AR_VEHICLE_FORKLIFT;
export const AR_OBJECT_GOLF_CART = AR_VEHICLE_GOLF_CART;
export const AR_OBJECT_INVERSE_T_CRANE_HEAD_V2 = "INVERSE_T_CRANE_HEAD_V2";
export const AR_OBJECT_LADDER = "LADDER";
export const AR_OBJECT_LOWBED_TRAILER = AR_VEHICLE_LOWBED_TRAILER;
export const AR_OBJECT_MILKRUN = AR_VEHICLE_MILKRUN;
export const AR_OBJECT_MOTORCYCLE = AR_VEHICLE_MOTORCYCLE;
export const AR_OBJECT_PERSON = "PERSON";
export const AR_OBJECT_PIPE = "PIPE";
export const AR_OBJECT_TRACTOR = AR_VEHICLE_TRACTOR;
export const AR_OBJECT_TRANSFER_VEHICLE_V4 = "TRANSFER_VEHICLE_V4";
export const AR_OBJECT_TRUCK = AR_VEHICLE_TRUCK;
export const AR_OBJECT_WAREHOUSE_TRAILER = AR_VEHICLE_WAREHOUSE_TRAILER;
export const AR_OBJECT_WELDING_MACHINE = "WELDING_MACHINE";

export type AR_OBJECT = (typeof AR_OBJECT)[number];

export const AR_OBJECT = [
  AR_OBJECT_BOBBIN_VERTICAL,
  AR_OBJECT_BRIDGE_CRANE,
  AR_OBJECT_CAR,
  AR_OBJECT_CRANE_SPREAD_BAR,
  AR_OBJECT_FORKLIFT,
  AR_OBJECT_GOLF_CART,
  AR_OBJECT_INVERSE_T_CRANE_HEAD_V2,
  AR_OBJECT_LADDER,
  AR_OBJECT_LOWBED_TRAILER,
  AR_OBJECT_MILKRUN,
  AR_OBJECT_PERSON,
  AR_OBJECT_PIPE,
  AR_OBJECT_TRACTOR,
  AR_OBJECT_TRANSFER_VEHICLE_V4,
  AR_OBJECT_TRUCK,
  AR_OBJECT_WAREHOUSE_TRAILER,
  AR_OBJECT_WELDING_MACHINE,
  AR_VEHICLE_AMR_WITH_SHELVES,
  AR_VEHICLE_AUTOMATED_GUIDED_VEHICLE,
  AR_VEHICLE_AUTOMATIC_BALE_OPENER,
  AR_VEHICLE_AUTONOMOUS_CART,
  AR_VEHICLE_AUTO_RICKSHAW,
  AR_VEHICLE_BICYCLE,
  AR_VEHICLE_BOBBIN,
  AR_VEHICLE_BOBBIN_CART,
  AR_VEHICLE_BUS,
  AR_VEHICLE_CARRY_CART,
  AR_VEHICLE_CRANE_CABINET_FRONT,
  AR_VEHICLE_CRANE_CABINET_SIDE,
  AR_VEHICLE_CRANE_HEAD_V2,
  AR_VEHICLE_CRANE_HEAD_V3,
  AR_VEHICLE_CRANE_HEAD_V4,
  AR_VEHICLE_C_SHAPED_CRANE_HEAD,
  AR_VEHICLE_EXCAVATOR,
  AR_VEHICLE_FRAME_TRANSPORTER,
  AR_VEHICLE_GRAY_CYLINDER,
  AR_VEHICLE_GRAY_CYLINDER_CART,
  AR_VEHICLE_INVERSE_T_CRANE_HEAD,
  AR_VEHICLE_LOADER,
  AR_VEHICLE_MAFI,
  AR_VEHICLE_MAGNETIC_LIFTING_BEAM,
  AR_VEHICLE_MANLIFT_CLOSED,
  AR_VEHICLE_MANUAL_FORKLIFT,
  AR_VEHICLE_MINICRANE_BLOCK,
  AR_VEHICLE_MINICRANE_CYLINDER,
  AR_VEHICLE_MINICRANE_CYLINDER_V2,
  AR_VEHICLE_MINICRANE_CYLINDER_V3,
  AR_VEHICLE_MINICRANE_RECTANGLE,
  AR_VEHICLE_MINICRANE_TRIANGLE,
  AR_VEHICLE_MINICRANE_V4,
  AR_VEHICLE_MOTORCYCLE,
  AR_VEHICLE_MULTI_SERVICE_VEHICLE,
  AR_VEHICLE_NARROW_AISLE_FORKLIFT,
  AR_VEHICLE_PAINT_CART,
  AR_VEHICLE_PENTAGONAL_CRANE_HEAD,
  AR_VEHICLE_SIDE_LOADER,
  AR_VEHICLE_SCORPION_MINICRANE,
  AR_VEHICLE_SLAG_POT_CARRIER,
  AR_VEHICLE_STANDUP_CART,
  AR_VEHICLE_TRANSFER_VEHICLE,
  AR_VEHICLE_TRANSFER_VEHICLE_V2,
  AR_VEHICLE_TRICYCLE_TRUCK,
  AR_VEHICLE_TRIPLE_HOOK_CRANE,
  AR_VEHICLE_MAINTENANCE_CART_V1,
  AR_VEHICLE_PROBLEM_SOLVE_CART,
  AR_VEHICLE_U_BOAT,
  AR_VEHICLE_IBOT_CART,
  AR_VEHICLE_U_BOAT_LOADED,
  AR_VEHICLE_AUTOMATED_GUIDED_VEHICLE_V2,
  AR_VEHICLE_CLEANING_CART,
] as const;

// Detect types
export const AR_DETECT_EXCLUSION = "EXCLUSION";
export const AR_DETECT_HANDS = "HANDS";
export const AR_DETECT_HOUSEKEEPING = "HOUSEKEEPING";
export const AR_DETECT_LIGHT = "LIGHT";
export const AR_DETECT_OBJECT = "OBJECT";
export const AR_DETECT_PERSON = "PERSON";
export const AR_DETECT_RESTRICTED_AREA = "RESTRICTED_AREA";
export const AR_DETECT_STATIC = "STATIC";
export const AR_DETECT_VEHICLE_AREA = "VEHICLE_AREA";

export type AR_PPE_AREA_TYPE =
  | typeof AR_DETECT_EXCLUSION
  | typeof AR_DETECT_HANDS
  | typeof AR_DETECT_HOUSEKEEPING
  | typeof AR_DETECT_LIGHT
  | typeof AR_DETECT_OBJECT
  | typeof AR_DETECT_PERSON
  | typeof AR_DETECT_RESTRICTED_AREA
  | typeof AR_DETECT_STATIC
  | typeof AR_DETECT_VEHICLE_AREA;

export const AR_PPE_AREA_TYPE: AR_PPE_AREA_TYPE[] = [
  AR_DETECT_EXCLUSION,
  AR_DETECT_HANDS,
  AR_DETECT_HOUSEKEEPING,
  AR_DETECT_LIGHT,
  AR_DETECT_OBJECT,
  AR_DETECT_PERSON,
  AR_DETECT_RESTRICTED_AREA,
  AR_DETECT_STATIC,
  AR_DETECT_VEHICLE_AREA,
];

// Light colors
export const AR_LIGHT_COLOR_ORANGE_BROWN = "ORANGE_BROWN";
export const AR_LIGHT_COLOR_BLUE = "BLUE";
export const AR_LIGHT_COLOR_GREEN = "GREEN";
export const AR_LIGHT_COLOR_RED = "RED";
export const AR_LIGHT_COLOR_YELLOW = "YELLOW";
export const AR_LIGHT_COLOR_MAGENTA = "MAGENTA";
export const AR_LIGHT_COLOR_BLACK = "BLACK";
export const AR_LIGHT_COLOR_GREY = "GREY";
export const AR_LIGHT_COLOR_WHITE = "WHITE";

export type AR_LIGHT_COLOR =
  | typeof AR_LIGHT_COLOR_BLACK
  | typeof AR_LIGHT_COLOR_BLUE
  | typeof AR_LIGHT_COLOR_GREEN
  | typeof AR_LIGHT_COLOR_GREY
  | typeof AR_LIGHT_COLOR_MAGENTA
  | typeof AR_LIGHT_COLOR_ORANGE_BROWN
  | typeof AR_LIGHT_COLOR_RED
  | typeof AR_LIGHT_COLOR_WHITE
  | typeof AR_LIGHT_COLOR_YELLOW;

export const AR_LIGHT_COLOR: AR_LIGHT_COLOR[] = [
  AR_LIGHT_COLOR_BLUE,
  AR_LIGHT_COLOR_GREEN,
  AR_LIGHT_COLOR_GREY,
  AR_LIGHT_COLOR_MAGENTA,
  AR_LIGHT_COLOR_ORANGE_BROWN,
  AR_LIGHT_COLOR_RED,
  AR_LIGHT_COLOR_WHITE,
  AR_LIGHT_COLOR_YELLOW,
  AR_LIGHT_COLOR_BLACK,
];

// Light status
// ! CHA-1259 Remove STATIC when cleaning up the code, need to remove translations for static too
export const AR_LIGHT_STATUS_BLINKING = "BLINKING";
export const AR_LIGHT_STATUS_OFF = "OFF";
export const AR_LIGHT_STATUS_ON = "ON";
export const AR_LIGHT_STATUS_STATIC = "STATIC";

export type AR_LIGHT_STATUS =
  | typeof AR_LIGHT_STATUS_BLINKING
  | typeof AR_LIGHT_STATUS_OFF
  | typeof AR_LIGHT_STATUS_ON
  | typeof AR_LIGHT_STATUS_STATIC;

export const AR_LIGHT_STATUS: AR_LIGHT_STATUS[] = [
  AR_LIGHT_STATUS_BLINKING,
  AR_LIGHT_STATUS_OFF,
  AR_LIGHT_STATUS_ON,
  AR_LIGHT_STATUS_STATIC,
];

// Apron types
export const AR_APRON_TYPE_FRONTAL = "FRONTAL";
export const AR_APRON_TYPE_FULL_BODY = "FULL_BODY";

export type AR_APRON_TYPE =
  | typeof AR_APRON_TYPE_FRONTAL
  | typeof AR_APRON_TYPE_FULL_BODY;

export const AR_APRON_TYPE: AR_APRON_TYPE[] = [
  AR_APRON_TYPE_FRONTAL,
  AR_APRON_TYPE_FULL_BODY,
];

// Reflective vest types
export const AR_REFLECTIVE_VEST_TYPE_VIVID_COLORS = "VIVID_COLORS";
export const AR_REFLECTIVE_VEST_TYPE_HARNESSES = "HARNESSES";
export const AR_REFLECTIVE_VEST_TYPE_METALLIC = "METALLIC";
export const AR_REFLECTIVE_VEST_TYPE_PATTERNS =
  "REFLECTIVE_PATTERNS_ON_DARK_COLORS";

export type AR_REFLECTIVE_VEST_TYPE =
  | typeof AR_REFLECTIVE_VEST_TYPE_HARNESSES
  | typeof AR_REFLECTIVE_VEST_TYPE_METALLIC
  | typeof AR_REFLECTIVE_VEST_TYPE_PATTERNS
  | typeof AR_REFLECTIVE_VEST_TYPE_VIVID_COLORS;

export const AR_REFLECTIVE_VEST_TYPE: AR_REFLECTIVE_VEST_TYPE[] = [
  AR_REFLECTIVE_VEST_TYPE_VIVID_COLORS,
  AR_REFLECTIVE_VEST_TYPE_HARNESSES,
  AR_REFLECTIVE_VEST_TYPE_METALLIC,
  AR_REFLECTIVE_VEST_TYPE_PATTERNS,
];

// Top level types
export const AR_AREA = "AREA";
export const AR_GROUP = "GROUP";

// Web service types
export const AR_SOAP = "SOAP";
export const AR_REST = "REST";

export type AR_WEB_SERVICE_TYPE = typeof AR_REST | typeof AR_SOAP;

export const AR_WEB_SERVICE_TYPE: AR_WEB_SERVICE_TYPE[] = [AR_SOAP, AR_REST];

// Alert rule training status
export const AR_TRAINING_STATUS_AVAILABLE = "AVAILABLE";
export const AR_TRAINING_STATUS_UNAVAILABLE = "UNAVAILABLE";
export const AR_TRAINING_STATUS_NOT_NEEDED = "NOT_NEEDED";

export type AR_TRAINING_STATUS =
  | typeof AR_TRAINING_STATUS_AVAILABLE
  | typeof AR_TRAINING_STATUS_NOT_NEEDED
  | typeof AR_TRAINING_STATUS_UNAVAILABLE;

// Alert Rule status
export const AR_STATUS_ACTIVE = "ACTIVE";
export const AR_STATUS_INACTIVE = "INACTIVE";

export type AR_STATUS = typeof AR_STATUS_ACTIVE | typeof AR_STATUS_INACTIVE;

// Alerts that training is required
export type AR_TRAINING_REQUIRED_RULES =
  | typeof AR_ANOMALY_CLEAN_PEDESTRIAN_WAYS
  | typeof AR_ANOMALY_CLEAN_VEHICLE_ROADS
  | typeof AR_ANOMALY_LEAKAGE_AND_SPILL
  | typeof AR_ANOMALY_OPEN_CLOSED_DOORS
  | typeof AR_ANOMALY_UNATTENDED_OBJECT_DETECTION
  | typeof AR_CONFINED_SPACE_CRANE_AREA
  | typeof AR_CONFINED_SPACE_SAFE_LIFTING;

export const AR_TRAINING_REQUIRED_RULES: AR_TRAINING_REQUIRED_RULES[] = [
  AR_ANOMALY_CLEAN_PEDESTRIAN_WAYS,
  AR_ANOMALY_CLEAN_VEHICLE_ROADS,
  AR_ANOMALY_UNATTENDED_OBJECT_DETECTION,
  AR_ANOMALY_LEAKAGE_AND_SPILL,
  AR_ANOMALY_OPEN_CLOSED_DOORS,
  AR_CONFINED_SPACE_CRANE_AREA,
  AR_CONFINED_SPACE_SAFE_LIFTING,
];

//Nested settings, we can add any other nested settings for
//every alert rules
export const NS_ERGONOMICS_SENSITIVITY_SETTINGS =
  "ERGONOMICS_SENSITIVITY_SETTINGS";

//custom
export const CUSTOM_RULE_MODE = "CUSTOM";

export const PREBUILT_RULE_MODE = "PREBUILT";

export const STANDARD_WORKING_TYPE = "STANDARD";

export const ROTATION_BASED_WORKING_TYPE = "ROTATION_BASED";

export const SHIFT_BASED_WORKING_TYPE = "SHIFT_BASED";

export const FACILITY_WORKING_TYPE = [
  STANDARD_WORKING_TYPE,
  ROTATION_BASED_WORKING_TYPE,
  SHIFT_BASED_WORKING_TYPE,
];

export type FACILITY_WORKING_TYPE =
  | typeof ROTATION_BASED_WORKING_TYPE
  | typeof SHIFT_BASED_WORKING_TYPE
  | typeof STANDARD_WORKING_TYPE;

export const OVERALL_SPEED_LIMIT = "OVERALL_SPEED_LIMIT";

export const OBJECT_BASED_SPEED_LIMIT = "OBJECT_BASED_SPEED_LIMIT";

export const DURATION_TYPE_MIN = "MIN";

export const DURATION_TYPE_MAX = "MAX";

export const DURATION_TYPE = [DURATION_TYPE_MIN, DURATION_TYPE_MAX];

export type DURATION_TYPE = typeof DURATION_TYPE_MAX | typeof DURATION_TYPE_MIN;

export type VEHICLE_SPEEDS = { trackables: AR_VEHICLE[]; speed: number };

export enum ARObjectBoxSize {
  Large = "LARGE",
  Medium = "MEDIUM",
  Small = "SMALL",
}

export enum ARObjectBoxSizeNotification {
  All = "ALL",
  Large = "LARGE",
  Medium = "MEDIUM",
  Small = "SMALL",
}

export const PROTECTIVE_CLOTHING_TYPE_UPPER_BODY = "UPPER_BODY";
export const PROTECTIVE_CLOTHING_TYPE_FULL_BODY = "FULL_BODY";

export const PROTECTIVE_CLOTHING_TYPE_OPTIONS = [
  PROTECTIVE_CLOTHING_TYPE_UPPER_BODY,
  PROTECTIVE_CLOTHING_TYPE_FULL_BODY,
];

export type PROTECTIVE_CLOTHING_TYPE_OPTIONS =
  | typeof PROTECTIVE_CLOTHING_TYPE_FULL_BODY
  | typeof PROTECTIVE_CLOTHING_TYPE_UPPER_BODY;
