import type { IconChildrenProps } from "../../icon.types";

export const HazardsChemicalHandling = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M8.75 9.54895H7.5C6.80964 9.54895 6.25 8.98931 6.25 8.29895V8.29895C6.25 7.60859 6.80964 7.04895 7.5 7.04895V7.04895C8.19036 7.04895 8.75 7.60859 8.75 8.29895V9.54895Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32453"
    />
    <path
      clipRule="evenodd"
      d="M8.75 9.54895H11.25V12.0489H8.75V9.54895Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32453"
    />
    <path
      clipRule="evenodd"
      d="M7.5 14.5488V14.5488C6.80964 14.5488 6.25 13.9892 6.25 13.2988V13.2988C6.25 12.6085 6.80964 12.0488 7.5 12.0488H8.75V13.2988C8.75 13.9892 8.19036 14.5488 7.5 14.5488Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32453"
    />
    <path
      clipRule="evenodd"
      d="M11.25 12.0488H12.5C13.1904 12.0488 13.75 12.6085 13.75 13.2988V13.2988C13.75 13.9892 13.1904 14.5488 12.5 14.5488V14.5488C11.8096 14.5488 11.25 13.9892 11.25 13.2988V12.0488Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32453"
    />
    <path
      clipRule="evenodd"
      d="M10 3.29895V3.29895C14.1421 3.29895 17.5 6.65681 17.5 10.7989V10.7989C17.5 14.9411 14.1421 18.2989 10 18.2989V18.2989C5.85786 18.2989 2.5 14.9411 2.5 10.7989V10.7989C2.5 6.65681 5.85786 3.29895 10 3.29895V3.29895Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32453"
    />
    <path
      clipRule="evenodd"
      d="M12.5 7.04895V7.04895C13.1904 7.04895 13.75 7.60859 13.75 8.29895V8.29895C13.75 8.98931 13.1904 9.54895 12.5 9.54895H11.25V8.29895C11.25 7.60859 11.8096 7.04895 12.5 7.04895Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32453"
    />
  </>
);
