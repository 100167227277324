import type { IconChildrenProps } from "../icon.types";

export const Generate = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M8.89262 17.5C8.60095 17.5 8.37595 17.2417 8.41762 16.95L9.16762 11.6667H6.25095C5.51762 11.6667 5.97595 11.0417 5.99262 11.0167C7.04262 9.15834 8.61762 6.40834 10.701 2.74167C10.8166 2.54326 11.0564 2.45433 11.2735 2.52939C11.4906 2.60445 11.6242 2.82253 11.5926 3.05001L10.8426 8.33334H13.7676C14.101 8.33334 14.2843 8.49167 14.101 8.88334C11.3593 13.6667 9.76762 16.4583 9.30929 17.2583C9.22595 17.4083 9.06762 17.5 8.89262 17.5Z"
    fill={color}
    fillRule="evenodd"
  />
);
