import type { IconChildrenProps } from "../../icon.types";

export const SolutionsTiresAndRubber = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M9.9987 8.87083V4.70416"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M9.9987 16.3708V12.2042"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M11.1738 9.36254L14.1238 6.41254"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M5.875 14.6625L8.825 11.7125"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M11.666 10.5376H15.8327"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M4.16602 10.5376H8.33268"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M11.1738 11.7125L14.1238 14.6625"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M5.875 6.41254L8.825 9.36254"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M15.8919 4.645C19.1462 7.89937 19.1462 13.1757 15.8919 16.4301C12.6375 19.6844 7.36112 19.6844 4.10679 16.4301C0.852424 13.1757 0.852424 7.89933 4.10679 4.645C7.36116 1.39063 12.6375 1.39063 15.8919 4.645"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M14.1241 6.41271C16.4022 8.69076 16.4022 12.3842 14.1241 14.6623C11.8461 16.9403 8.15262 16.9403 5.87456 14.6623C3.5965 12.3842 3.5965 8.69076 5.87456 6.41271C8.15262 4.13465 11.8461 4.13465 14.1241 6.41271"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M11.1772 9.35901C11.8281 10.0099 11.8281 11.0652 11.1772 11.716C10.5263 12.3669 9.47106 12.3669 8.82019 11.716C8.16931 11.0652 8.16931 10.0099 8.82019 9.35901C9.47106 8.70813 10.5263 8.70813 11.1772 9.35901"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
  </>
);
