export const endpoints = {
  actionInspection: {
    actions: (inspectionMasterId: string) =>
      `/internal/inspections/${inspectionMasterId}/actions`,
    base: (inspectionId: string) => `/internal/inspections/${inspectionId}`,
    bookmark: (inspectionId: string) =>
      `/internal/inspections/${inspectionId}/bookmark`,
    bookmarkBulk: "/internal/inspections/bookmark",
    createTask: "/internal/inspections/actions/task",
    deleteActions: "/internal/inspections/actions",
    history: (masterId: string) => `/internal/inspections/${masterId}/history`,
    inspectionTask: (taskId: string) =>
      `/internal/inspections/actions/${taskId}`,
    latest: (inspectionMasterId: string) =>
      `/internal/inspections/${inspectionMasterId}/latest`,
    list: "/internal/inspections",
    report: (inspectionId: string) =>
      `/internal/inspections/${inspectionId}/report`,
    status: (inspectionId: string) =>
      `/internal/inspections/${inspectionId}/status`,
    uploadMedia: "/internal/inspections/attachment",
  },
  actionTemplate: {
    allFacilities: (templateId: string) =>
      `/internal/inspections/templates/${templateId}/facility`,
    base: (templateId: string) =>
      `/internal/inspections/templates/${templateId}`,
    bookmarkBulk: "/internal/inspections/templates/bookmark",
    bookmarkRow: (id: string) =>
      `/internal/inspections/templates/${id}/bookmark`,
    draft: (templateMasterId: string) =>
      `/internal/inspections/templates/${templateMasterId}/draft`,
    facility: (templateId: string, facilityId: string) =>
      `/internal/inspections/templates/${templateId}/facility/${facilityId}`,
    history: (masterId: string) =>
      `/internal/inspections/templates/${masterId}/history`,
    list: "/internal/inspections/templates",
    save: "/internal/inspections/templates",
    status: (templateId: string) =>
      `/internal/inspections/templates/${templateId}/status`,
  },
  alert: {
    areas: "/internal/alerts/areas",
    availableTypes: "/internal/alerts/available_types",
    base: "/alerts",
    baseId: (alertId: string) => `/alerts/${alertId}`,
    baseIdInternal: (alertId: string) => `/internal/alerts/${alertId}`,
    baseInternal: "/internal/alerts",
    bulkBookmark: "/internal/alerts/bookmark",
    checkModelTraining: (cameraId: string) =>
      `/internal/cameras/${cameraId}/check_model`,
    export: "/internal/alerts/export",
    ioSettingsDynamicFlow: "/io-api/dynamic-flows",
    ioSettingsDynamicFlowList: (facilityId?: string, cameraId?: string) =>
      `/io-api/dynamic-flows?filter=facilityId==${facilityId ?? ""}&cameraId==${
        cameraId ?? ""
      }`,
    ioSettingsDynamicFlowSlot: (id: string) => `/io-api/dynamic-flows/${id}`,
    newAvailableTypes: "/internal/alert_rules/available_types",
    report: (alertId: string, status?: boolean) =>
      `/internal/alerts/${alertId}/report?${
        status !== undefined ? `status=${String(status)}` : ""
      }`,
  },
  cameras: {
    advancedSettings: (cameraId: string) =>
      `/internal/cameras/${cameraId}/settings`,
    alertRules: (cameraId: string) =>
      `/internal/cameras/${cameraId}/alert_rules`,
    baseId: (cameraId: string) => `/cameras/${cameraId}`,
    branchOverride: (cameraId: string) =>
      `/internal/cameras/${cameraId}/tracker_branch_override`,
    calibration: (cameraId: string) =>
      `/internal/cameras/${cameraId}/calibration`,
    customDataCollection: (cameraId: string) =>
      `/internal/cameras/${cameraId}/custom_data_collection_configs`,
    pathway: (cameraId: string) => `/internal/cameras/${cameraId}/pathways`,
    pathwayCamera: "/internal/cameras/with_pathway",
    refreshThumbnail: (cameraId: string) =>
      `/internal/cameras/${cameraId}/refreshThumbnail`,
    saveDateAnonymization: (cameraId: string) =>
      `/internal/cameras/${cameraId}/timestamp_area`,
    statusDetail: (cameraId: string) =>
      `/internal/cameras/${cameraId}/status_detail`,
    statusDetailBulk: "/internal/cameras/status_detail",
    validateCalibration: (cameraId: string) =>
      `/internal/cameras/${cameraId}/validate_calibration`,
  },
  client: {
    connection: {
      base: "/connections",
      baseId: (connectionId: string) => `/connections/${connectionId}`,
      restart: "/connections/restart",
      status: (connectionId: string) => `/connections/${connectionId}/connect`,
      validate: "/connections/validate",
    },
    diagnostic: {
      base: "/diagnostic/servers/status",
    },
    facility: {
      base: "/proxy/workspaces/facilities",
    },
    session: {
      login: "/auth/login",
      logout: "/auth/logout",
      refreshToken: "/auth/refresh_token",
      register: "/auth/register",
      serverCameraConfiguration: "/app/configuration/cameras",
      serverCameraConfigurationValidate: "/app/configuration/cameras/validate",
      serverConfiguration: "/app/configuration",
      serverSmartDevicesConfiguration: "/app/configuration/smart-devices",
      serverSmartDevicesConfigurationValidate:
        "/app/configuration/smart-devices/validate",
      user: "/users",
    },
  },
  controlRoom: {
    accuracy: {
      ratePerPeriod: "/internal/maat/analytics/accuracy/date",
      ratePerPeriodPerAlertCategory:
        "/internal/maat/analytics/accuracy/category",
      ratePerPeriodPerAlertType:
        "/internal/maat/analytics/accuracy/summary/alert_type",
      ratePerPeriodPerCamera: "/internal/maat/analytics/accuracy/camera",
      ratePerPeriodPerFacility: "/internal/maat/analytics/accuracy/facility",
      ratePerPeriodSummary: "/internal/maat/analytics/accuracy/summary",
      rateSummary: "/internal/maat/analytics/accuracy/summary/workspace",
      rateSummaryPerFacility:
        "/internal/maat/analytics/accuracy/summary/facility",
    },
    alerts: {
      countPerPeriod: "/internal/analytics/alert/count",
      countPerPeriodPerAlertCategory:
        "/internal/analytics/alert/supercategory/count",
      countPerPeriodPerAlertSeverity:
        "/internal/analytics/alert/severity/count",
      countPerPeriodPerAlertSeverityPerFacility:
        "/internal/analytics/alert/severity/count_by_facility",
      countPerPeriodPerAlertType: "/internal/analytics/alert/type/count",
      countPerPeriodPerCameraPerAlertType:
        "/internal/analytics/camera/alert/count",
      countPerPeriodPerFacility: "/internal/analytics/facility/alert/count",
      countPerPeriodPerRotation: "/internal/analytics/alert/rotation/count",
      countPerPeriodPerRotationalShift: "/internal/analytics/alert/shift/count",
      list: "/internal/alerts",
      observationRatio: "/internal/analytics/overview/alert/count",
      observationRatioPerFacility:
        "/internal/analytics/overview/alert/count_by_facility",
      useCasePerCamera: "/internal/analytics/overview/use_case_per_camera",
      useCasePerCameraPerFacility:
        "/internal/analytics/overview/use_case_per_camera_by_facility",
    },
    compliance: {
      bestWorst: "/internal/analytics/compliance/best_worst",
      scorePerPeriodPerAlertType: "/internal/analytics/compliance/type",
      scorePerPeriodPerCameraPerAlertType:
        "/internal/analytics/compliance/camera/type",
      scorePerPeriodPerFacility: "/internal/analytics/compliance/facility",
      scorePerPeriodPerRotation:
        "/internal/analytics/compliance/rotation/count",
      scorePerPeriodPerRotationalShift:
        "/internal/analytics/compliance/shift/count",
    },
    connectivity: {
      countPerPeriod: "/internal/workspaces/connectivity",
      countPerPeriodPerCamera: (facilityId: string) =>
        `/internal/facilities/connectivity/${facilityId}`,
      countPerPeriodPerFacility: "/internal/facilities/connectivity",
    },
  },
  controlRoomOverview: {
    actions: "/internal/control-room/actions",
    impacts: "/internal/control-room/impacts",
    indicators: "/internal/control-room/indicators",
    targets: "/internal/control-room/targets",
  },
  emailReports: {
    base: "/internal/users/email_reports",
    baseId: (id: string) => `/internal/users/email_reports/${id}`,
  },
  ergonomics: {
    alerts: {
      base: "/internal/ergonomics/alerts",
      baseId: (alertId: string) => `/internal/ergonomics/alerts/${alertId}`,
      bookmark: "/internal/ergonomics/alerts/bookmark",
      search: "/internal/ergonomics/alerts/search",
    },
    overview: {
      alertScoreDistribution:
        "/internal/ergonomics/analytics/alert_score_distribution",
      alertSessionRatio: "/internal/ergonomics/analytics/alert_session_summary",
      detectionCountPerUseCaseScores:
        "/internal/ergonomics/analytics/detection_distributionV2",
      scoreDistribution: "/internal/ergonomics/analytics/score_distribution",
      treemapCamera: "/internal/ergonomics/analytics/treemap/camera",
      treemapFacility: "/internal/ergonomics/analytics/treemap/facility",
      treemapUseCase: "/internal/ergonomics/analytics/treemap/usecase",
    },
  },
  facility: {
    base: "/facilities",
    baseId: (facilityId: string) => `/facilities/${facilityId}`,
    camera: (facilityId: string, cameraId: string) =>
      `/internal/facilities/${facilityId}/cameras/${cameraId}`,
    cameraAnalysis: (cameraId: string, facilityId: string) =>
      `/internal/facilities/${facilityId}/cameras/${cameraId}/analysis`,
    cameraRestartAll: (facilityId: string) =>
      `/internal/facilities/${facilityId}/cameras/restart_analysis`,
    cameraRules: (cameraId: string) =>
      `/internal/cameras/${cameraId}/alert_rules`,
    cameras: (facilityId: string) => `/facilities/${facilityId}/cameras`,
    camerasAll: "/internal/cameras",
    camerasStatus: "/internal/workspaces/cameras/status",
    camerasWithAlertRules: (facilityId: string) =>
      `/internal/facilities/${facilityId}/cameras`,
    clusters: "/internal/clusters",
    heatmaps: (facilityId: string) =>
      `/internal/facilities/${facilityId}/heatmaps`,
    kpis: (facilityId: string) => `/internal/facilities/${facilityId}/kpi`,
    modbusActiveStatus: (id: string) => `/io-api/modbus/devices/${id}`,
    modbusAddBulkSlots: "/io-api/modbus/slots/bulk",
    modbusAddDevice: "/io-api/modbus/devices",
    modbusDeleteSlot: (id: string) => `/io-api/modbus/slots/${id}`,
    modbusEditDevice: (deviceId: string) =>
      `/io-api/modbus/devices/${deviceId}`,
    modbusList: (facilityId: string) =>
      `/io-api/modbus/devices?filter=facilityId==${facilityId}&sort=name`,
    modbusReachDevice: "/io-api/modbus/device-connection-test",
    modbusSlotTagList: (deviceId: string) =>
      `/io-api/modbus/slots?filter=modbusDeviceId==${deviceId}&sort=createdAt&sort=name`,
    modbusSlots: "/io-api/modbus/slot-types",
    modbusTestSlot: "/io-api/modbus/slot-test",
    newCameraThumbnail: (facilityId: string) =>
      `/internal/cameras/temp_thumbnail?facilityId=${facilityId}`,
    nodes: (facilityId: string) => `/internal/facilities/${facilityId}/nodes`,
    rotations: (facilityId: string) =>
      `/internal/facilities/${facilityId}/rotation`,
    shiftsV2: (facilityId: string) =>
      `/internal/facilities/${facilityId}/shiftV2`,
    smartDevice: (facilityId: string, smartDeviceId: string) =>
      `/internal/facilities/${facilityId}/smart_devices/${smartDeviceId}`,
    smartDeviceSimulateAlert: (
      facilityId: string,
      smartDeviceId: string,
      alertType: string,
    ) =>
      `/internal/facilities/${facilityId}/smart_devices/${smartDeviceId}/simulate_alert?alertType=${alertType}`,
    smartDevices: (facilityId: string) =>
      `/internal/facilities/${facilityId}/smart_devices`,
    validateCamera: (facilityId: string) =>
      `/internal/cameras/validate?facilityId=${facilityId}`,
  },
  featureFlags: "/internal/feature-flags",
  locale: {
    messages: (locale: string) => `/languagepack/${locale}.json`,
  },
  maat: {
    autoReport: {
      bookmarkSamples: (reportRuleId: string) =>
        `/internal/maat/reports/${reportRuleId}/samples/bookmark`,
      deleteSamples: (reportRuleId: string) =>
        `/internal/maat/reports/${reportRuleId}/samples/alerts`,
      excessiveSamples: (reportRuleId: string) =>
        `/internal/maat/reports/${reportRuleId}/samples/excessive`,
      flagSamples: (reportRuleId: string) =>
        `/internal/maat/reports/${reportRuleId}/samples/flag`,
      report: (reportId: string) => `/internal/maat/reports/${reportId}`,
      reportRule: (reportRuleId: string, page: number, isExcessive?: boolean) =>
        `/internal/maat/reports/${reportRuleId}/samples?page=${page}&count=50${
          isExcessive ? "&isExcessive=true" : ""
        }`,
      reportStats: (reportRuleId: string) =>
        `/internal/maat/reports/${reportRuleId}/stats`,
      sample: (reportRuleId: string) =>
        `/internal/maat/reports/${reportRuleId}/samples`,
      search: "/internal/maat/reports/auto/search",
      status: "/internal/maat/reports/status",
    },
    automationRule: {
      rule: (ruleId: string) => `/internal/maat/report/rules/${ruleId}`,
      search: "/internal/maat/report/rules/search",
    },
    cameras: {
      base: "/internal/maat/cameras",
    },
    facility: {
      base: "/internal/maat/facilities",
    },
    workplaces: {
      base: "/internal/maat/workspaces",
    },
  },
  maia: {
    cameras: {
      base: "/internal/maia/cameras",
    },
    categories: {
      base: "/internal/maia/categories",
    },
    facility: {
      base: "/internal/maia/facilities",
    },
    jobs: {
      assignee: (jobId: string) => `/internal/maia/jobs/${jobId}/assignee`,
      base: "/internal/maia/jobs",
      baseId: (jobId: string) => `/internal/maia/jobs/${jobId}`,
      board: "/internal/maia/board",
      dataCollectionAutoAnnotate: (jobId: string, dataCollectionId: string) =>
        `/internal/maia/jobs/${jobId}/data_collections/${dataCollectionId}/annotate`,
      dataCollectionBase: (jobId: string) =>
        `/internal/maia/jobs/${jobId}/data_collections`,
      dataCollectionBaseId: (jobId: string, dataCollectionId: string) =>
        `/internal/maia/jobs/${jobId}/data_collections/${dataCollectionId}`,
      status: (jobId: string) => `/internal/maia/jobs/${jobId}/status`,
    },
    tags: {
      base: "/internal/maia/objecttags",
    },
    videos: {
      base: "/internal/maia/videos",
    },
    workplaces: {
      base: "/internal/maia/workspaces",
    },
  },
  management: {
    addUserRole: "/internal/management/add_role",
    group: (groupId: string) => `/internal/management/groups/${groupId}`,
    groups: "/internal/management/groups",
    updateUserGroup: "/internal/management/update_users_group",
  },
  session: {
    authorize: "/internal/authorize",
    changePassword: "/internal/users/change_password",
    invitation: (id: string) => `/internal/users/invitations/${id}/sso`,
    signUp: "/internal/v2/users/signup",
    user: "/user",
    userPhoneNumber: "/user/phone_number",
    userPicture: "/user/picture",
    users: "/internal/users",
  },
  taskManagement: {
    activeAlertTypes: {
      category: (facilityId: string) =>
        `/internal/facilities/${facilityId}/alert_rules/supercategory`,
      severity: (facilityId: string) =>
        `/internal/facilities/${facilityId}/alert_rules/severity`,
    },
    archivedTasks: "/internal/tasks/archived",
    attachment: "/internal/attachment",
    comments: (id: string) => `/internal/comments/${id}`,
    subtask: (id: string) => `/internal/subtasks/${id}`,
    subtaskAccept: (id: string) => `/internal/subtasks/${id}/accept`,
    subtaskActions: "/internal/tasks/actions",
    subtaskAssignee: (id: string) => `/internal/subtasks/${id}/assignee`,
    subtaskComplete: (id: string) => `/internal/subtasks/${id}/complete`,
    subtaskReopen: (id: string) => `/internal/subtasks/${id}/reopen`,
    task: (id: string) => `/internal/tasks/${id}`,
    taskArchive: (id: string) => `/internal/tasks/${id}/archive`,
    taskComments: (taskId: string) => `/internal/tasks/${taskId}/comments`,
    taskStatus: (id: string) => `/internal/tasks/${id}/status`,
    taskSubtask: (taskId: string) => `/internal/tasks/${taskId}/subtask`,
    tasks: "/internal/tasks",
  },
  users: {
    base: (id: string) => `/internal/users/${id}`,
    invitations: "/internal/users/invitations",
    resendInvitation: "/internal/users/invitations/resend",
  },
  videos: {
    addVideo: "/internal/cameras/videos/upload",
    analyzeVideo: (videoId: string) => `/internal/videos/${videoId}/draw`,
    deleteVideo: (videoId: string) => `/internal/videos/${videoId}`,
    getByCamera: (cameraId: string) => `/internal/cameras/${cameraId}/videos`,
    videoJob: (jobId: string) => `/internal/videos/jobs/${jobId}`,
  },
  workspace: {
    base: "/internal/workspaces",
    cloudServiceIntegrations: "/internal/workspaces/cloud_service_integration",
    facilities: "/internal/workspaces/facilities",
    image: "/internal/workspaces/image",
    invite: "/internal/v2/users/invite",
    objectStorages: "/internal/workspaces/object_storage",
    populatedemo: "/internal/workspaces/demo/seed",
    users: "/internal/workspaces/users",
  },
} as const;
