import { AR_CATEGORY_BEHAVIOR } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseGrouping = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19318)">
        <path
          d="M9.99922 13.1701C10.5592 13.1701 11.0892 12.9501 11.4892 12.5501C12.3092 11.7301 12.3092 10.4001 11.4892 9.5801C10.6992 8.7901 9.30922 8.7901 8.51922 9.5801C8.11922 9.9801 7.89922 10.5001 7.89922 11.0701C7.89922 11.6401 8.11922 12.1601 8.51922 12.5601C8.91922 12.9601 9.43922 13.1801 10.0092 13.1801L9.99922 13.1701ZM9.35922 10.4301C9.52922 10.2601 9.75922 10.1701 9.99922 10.1701C10.2392 10.1701 10.4692 10.2601 10.6392 10.4301C10.9892 10.7801 10.9892 11.3501 10.6392 11.7101C10.2992 12.0501 9.70922 12.0501 9.35922 11.7101C9.18922 11.5401 9.09922 11.3101 9.09922 11.0701C9.09922 10.8301 9.18922 10.6001 9.35922 10.4301Z"
          fill={color}
        />
        <path
          d="M10.8892 13.3801H9.11922C7.85922 13.3801 6.82922 14.4101 6.82922 15.6701V16.4401C6.82922 16.7701 7.09922 17.0401 7.42922 17.0401C7.75922 17.0401 8.02922 16.7701 8.02922 16.4401V15.6701C8.02922 15.0701 8.51922 14.5801 9.11922 14.5801H10.8892C11.4892 14.5801 11.9792 15.0701 11.9792 15.6701V16.4401C11.9792 16.7701 12.2492 17.0401 12.5792 17.0401C12.9092 17.0401 13.1792 16.7701 13.1792 16.4401V15.6701C13.1792 14.4101 12.1492 13.3801 10.8892 13.3801Z"
          fill={color}
        />
        <path
          d="M14.6592 9.6901C15.0292 9.6901 15.3892 9.5401 15.6492 9.2801C15.9092 9.0201 16.0592 8.6601 16.0592 8.2901C16.0592 7.9201 15.9092 7.5601 15.6492 7.3001C15.1192 6.7701 14.1992 6.7701 13.6692 7.3001C13.4092 7.5601 13.2592 7.9201 13.2592 8.2901C13.2592 8.6601 13.4092 9.0201 13.6692 9.2801C13.9292 9.5401 14.2892 9.6901 14.6592 9.6901ZM14.3792 8.0001C14.4592 7.9201 14.5592 7.8801 14.6592 7.8801C14.7592 7.8801 14.8692 7.9201 14.9392 8.0001C15.0092 8.0801 15.0592 8.1801 15.0592 8.2801C15.0592 8.3801 15.0192 8.4901 14.9392 8.5601C14.7792 8.7201 14.5292 8.7201 14.3692 8.5601C14.2892 8.4801 14.2492 8.3801 14.2492 8.2801C14.2492 8.1801 14.2892 8.0701 14.3692 8.0001H14.3792Z"
          fill={color}
        />
        <path
          d="M15.1992 9.7501H14.1392C13.3092 9.7501 12.6292 10.4301 12.6292 11.2601V11.7201C12.6292 12.0001 12.8492 12.2201 13.1292 12.2201C13.4092 12.2201 13.6292 12.0001 13.6292 11.7201V11.2601C13.6292 10.9801 13.8592 10.7501 14.1392 10.7501H15.1992C15.4792 10.7501 15.7092 10.9801 15.7092 11.2601V11.7201C15.7092 12.0001 15.9292 12.2201 16.2092 12.2201C16.4892 12.2201 16.7092 12.0001 16.7092 11.7201V11.2601C16.7092 10.4301 16.0292 9.7501 15.1992 9.7501Z"
          fill={color}
        />
        <path
          d="M4.98922 9.6901C5.35922 9.6901 5.71922 9.5401 5.97922 9.2801C6.23922 9.0201 6.38922 8.6601 6.38922 8.2901C6.38922 7.9201 6.23922 7.5601 5.97922 7.3001C5.44922 6.7701 4.52922 6.7701 3.99922 7.3001C3.73922 7.5601 3.58922 7.9201 3.58922 8.2901C3.58922 8.6601 3.73922 9.0201 3.99922 9.2801C4.25922 9.5401 4.61922 9.6901 4.98922 9.6901ZM4.70922 8.0001C4.78922 7.9201 4.88922 7.8801 4.98922 7.8801C5.08922 7.8801 5.19922 7.9201 5.26922 8.0001C5.33922 8.0801 5.38922 8.1801 5.38922 8.2801C5.38922 8.3801 5.34922 8.4901 5.26922 8.5601C5.10922 8.7201 4.85922 8.7201 4.69922 8.5601C4.61922 8.4801 4.57922 8.3801 4.57922 8.2801C4.57922 8.1801 4.61922 8.0701 4.69922 8.0001H4.70922Z"
          fill={color}
        />
        <path
          d="M7.02922 11.7301V11.2701C7.02922 10.4401 6.34922 9.7601 5.51922 9.7601H4.45922C3.62922 9.7601 2.94922 10.4401 2.94922 11.2701V11.7301C2.94922 12.0101 3.16922 12.2301 3.44922 12.2301C3.72922 12.2301 3.94922 12.0101 3.94922 11.7301V11.2701C3.94922 10.9901 4.17922 10.7601 4.45922 10.7601H5.51922C5.79922 10.7601 6.02922 10.9901 6.02922 11.2701V11.7301C6.02922 12.0101 6.24922 12.2301 6.52922 12.2301C6.80922 12.2301 7.02922 12.0101 7.02922 11.7301Z"
          fill={color}
        />
        <path
          d="M9.99922 5.9501C10.3692 5.9501 10.7292 5.8001 10.9892 5.5401C11.5392 4.9901 11.5392 4.1001 10.9892 3.5601C10.4592 3.0301 9.53922 3.0301 9.00922 3.5601C8.74922 3.8201 8.59922 4.1801 8.59922 4.5501C8.59922 4.9201 8.74922 5.2801 9.00922 5.5401C9.26922 5.8001 9.62922 5.9501 9.99922 5.9501ZM9.71922 4.2601C9.79922 4.1801 9.89922 4.1401 9.99922 4.1401C10.0992 4.1401 10.2092 4.1801 10.2792 4.2601C10.4392 4.4201 10.4392 4.6701 10.2792 4.8301C10.1192 4.9901 9.86922 4.9901 9.70922 4.8301C9.54922 4.6701 9.54922 4.4201 9.70922 4.2601H9.71922Z"
          fill={color}
        />
        <path
          d="M8.45922 8.4801C8.73922 8.4801 8.95922 8.2601 8.95922 7.9801V7.5201C8.95922 7.2401 9.18922 7.0101 9.46922 7.0101H10.5292C10.8092 7.0101 11.0392 7.2401 11.0392 7.5201V7.9801C11.0392 8.2601 11.2592 8.4801 11.5392 8.4801C11.8192 8.4801 12.0392 8.2601 12.0392 7.9801V7.5201C12.0392 6.6901 11.3592 6.0101 10.5292 6.0101H9.46922C8.63922 6.0101 7.95922 6.6901 7.95922 7.5201V7.9801C7.95922 8.2601 8.17922 8.4801 8.45922 8.4801Z"
          fill={color}
        />
        <path
          d="M16.01 3.99C14.4 2.38 12.27 1.5 10 1.5C7.73 1.5 5.6 2.38 3.99 3.99C2.38 5.6 1.5 7.73 1.5 10C1.5 12.27 2.38 14.4 3.99 16.01C5.6 17.62 7.82 18.5 10 18.5C12.18 18.5 14.35 17.67 16.01 16.01C17.67 14.35 18.5 12.27 18.5 10C18.5 7.73 17.62 5.6 16.01 3.99ZM4.7 4.7C6.12 3.28 8 2.5 10 2.5C11.83 2.5 13.55 3.15 14.91 4.34L4.34 14.91C3.15 13.55 2.5 11.82 2.5 10C2.5 8 3.28 6.11 4.7 4.7ZM15.3 15.3C12.49 18.11 7.99 18.21 5.05 15.62L15.62 5.05C16.83 6.42 17.5 8.16 17.5 10C17.5 12 16.72 13.89 15.3 15.3Z"
          fill={getAlertCategoryColor(AR_CATEGORY_BEHAVIOR)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19318">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
