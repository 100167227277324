import type { IconChildrenProps } from "../../icon.types";

export const LightDetection = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.47215 2.52935C7.47215 2.23696 7.23513 1.99994 6.94274 1.99994H5.03686L4.94342 2.0017C3.64147 2.05078 2.60156 3.12124 2.60156 4.43523V6.34112L2.6043 6.39524C2.63141 6.6622 2.85686 6.87053 3.13097 6.87053C3.42336 6.87053 3.66039 6.6335 3.66039 6.34112V4.43523L3.66256 4.35709C3.70304 3.63298 4.30257 3.05876 5.03686 3.05876H6.94274L6.99687 3.05603C7.26383 3.02892 7.47215 2.80346 7.47215 2.52935ZM3.65765 13.9105C3.63054 13.6436 3.40509 13.4352 3.13097 13.4352C2.83859 13.4352 2.60156 13.6723 2.60156 13.9647V15.8705L2.60332 15.964C2.6524 17.2659 3.72286 18.3058 5.03686 18.3058H6.94274L6.99687 18.3031C7.26383 18.276 7.47215 18.0505 7.47215 17.7764C7.47215 17.484 7.23513 17.247 6.94274 17.247H5.03686L4.95872 17.2448C4.2346 17.2044 3.66039 16.6048 3.66039 15.8705V13.9647L3.65765 13.9105ZM18.3783 13.4352C18.6524 13.4352 18.8779 13.6436 18.905 13.9105L18.9077 13.9646V15.8705C18.9077 17.1845 17.8678 18.255 16.5658 18.3041L16.4724 18.3058H14.5665C14.2741 18.3058 14.0371 18.0688 14.0371 17.7764C14.0371 17.5023 14.2454 17.2768 14.5124 17.2497L14.5665 17.247H16.4724C17.2067 17.247 17.8062 16.6728 17.8467 15.9487L17.8489 15.8705V13.9646C17.8489 13.6723 18.0859 13.4352 18.3783 13.4352ZM18.9059 4.3418C18.8569 3.03984 17.7864 1.99994 16.4724 1.99994H14.5665L14.5124 2.00267C14.2454 2.02978 14.0371 2.25524 14.0371 2.52935C14.0371 2.82174 14.2741 3.05876 14.5665 3.05876H16.4724L16.5505 3.06094C17.2747 3.10141 17.8489 3.70095 17.8489 4.43523V6.34112L17.8516 6.39524C17.8787 6.6622 18.1042 6.87053 18.3783 6.87053C18.6707 6.87053 18.9077 6.6335 18.9077 6.34112V4.43523L18.9059 4.3418Z"
      fill={accentColor}
    />
    <path
      d="M14.0846 10.0708C14.0846 11.2895 13.4575 12.3586 12.5148 12.9599C11.5722 13.5613 12.5148 15.8334 10.7513 15.8334C8.98777 15.8334 9.93045 13.5613 8.98777 12.9599C8.0451 12.3586 7.41797 11.2895 7.41797 10.0708C7.41797 8.19074 8.91035 6.66669 10.7513 6.66669C12.5923 6.66669 14.0846 8.19074 14.0846 10.0708Z"
      stroke={color}
      strokeWidth="1.25"
    />
    <path
      d="M12.4154 10C12.4154 10.7084 12.0795 11.3231 11.582 11.6667"
      stroke={color}
      strokeWidth="0.416667"
      strokeLinecap="round"
    />
    <path
      d="M10.5 3.53705C10.5254 3.41823 10.6303 3.33331 10.7518 3.33331C10.8733 3.33331 10.9783 3.41823 11.0037 3.53705L11.2462 4.67211C11.3135 4.98675 11.0736 5.28331 10.7518 5.28331C10.4301 5.28331 10.1902 4.98675 10.2574 4.67211L10.5 3.53705Z"
      fill={color}
    />
    <path
      d="M17.2111 9.42057C17.3299 9.44596 17.4148 9.55094 17.4148 9.67244C17.4148 9.79394 17.3299 9.89892 17.2111 9.92431L16.076 10.1668C15.7614 10.2341 15.4648 9.99418 15.4648 9.67244C15.4648 9.3507 15.7614 9.11081 16.076 9.17804L17.2111 9.42057Z"
      fill={color}
    />
    <path
      d="M4.28577 9.92431C4.16695 9.89892 4.08203 9.79394 4.08203 9.67244C4.08203 9.55094 4.16695 9.44596 4.28577 9.42057L5.42083 9.17804C5.73547 9.11081 6.03203 9.3507 6.03203 9.67244C6.03203 9.99418 5.73547 10.2341 5.42083 10.1668L4.28577 9.92431Z"
      fill={color}
    />
    <path
      d="M15.4914 5.2386C15.5939 5.17209 15.7288 5.1857 15.816 5.27136C15.9026 5.3565 15.9189 5.49019 15.8553 5.59365L15.3004 6.49629C15.1322 6.76983 14.7534 6.81603 14.5244 6.59093C14.2965 6.36689 14.3346 5.98946 14.6027 5.81547L15.4914 5.2386Z"
      fill={color}
    />
    <path
      d="M6.17664 5.1162C6.07555 5.0485 5.9408 5.06112 5.85404 5.14642C5.76767 5.23133 5.75245 5.36508 5.81755 5.46723L6.461 6.47691C6.63447 6.74913 7.01465 6.79238 7.24484 6.56608C7.47419 6.34061 7.43868 5.96138 7.17144 5.78242L6.17664 5.1162Z"
      fill={color}
    />
  </>
);
