import type { IconChildrenProps } from "../icon.types";

export const Calibrate = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M2.64645 2.64645C2.82001 2.47288 3.08944 2.4536 3.28431 2.58859L3.35355 2.64645L5.41637 4.70926C6.64443 3.64441 8.24696 3 10 3C11.753 3 13.3556 3.64441 14.5836 4.70926L16.6464 2.64645L16.7157 2.58859C16.9106 2.4536 17.18 2.47288 17.3536 2.64645C17.5488 2.84171 17.5488 3.15829 17.3536 3.35355L15.2907 5.41637C16.3556 6.64443 17 8.24696 17 10C17 11.753 16.3556 13.3556 15.2907 14.5836L17.3536 16.6464C17.5488 16.8417 17.5488 17.1583 17.3536 17.3536C17.18 17.5271 16.9106 17.5464 16.7157 17.4114L16.6464 17.3536L14.5836 15.2907C13.3556 16.3556 11.753 17 10 17C8.24696 17 6.64443 16.3556 5.41637 15.2907L3.35355 17.3536L3.28431 17.4114C3.08944 17.5464 2.82001 17.5271 2.64645 17.3536C2.45118 17.1583 2.45118 16.8417 2.64645 16.6464L4.70926 14.5836C3.64441 13.3556 3 11.753 3 10C3 8.24696 3.64441 6.64443 4.70926 5.41637L2.64645 3.35355C2.45118 3.15829 2.45118 2.84171 2.64645 2.64645ZM14.5815 13.8744L13.3536 12.6464L13.2843 12.5886C13.0894 12.4536 12.82 12.4729 12.6464 12.6464C12.4512 12.8417 12.4512 13.1583 12.6464 13.3536L13.8744 14.5815C12.8291 15.4664 11.4769 16 10 16C8.52315 16 7.17092 15.4664 6.12558 14.5815L7.35355 13.3536C7.54882 13.1583 7.54882 12.8417 7.35355 12.6464C7.17999 12.4729 6.91056 12.4536 6.71569 12.5886L6.64645 12.6464L5.41847 13.8744C4.53358 12.8291 4 11.4769 4 10C4 8.52315 4.53358 7.17092 5.41847 6.12558L6.64645 7.35355L6.71569 7.41141C6.91056 7.5464 7.17999 7.52712 7.35355 7.35355C7.54882 7.15829 7.54882 6.84171 7.35355 6.64645L6.12558 5.41847C7.17092 4.53358 8.52315 4 10 4C11.4769 4 12.8291 4.53358 13.8744 5.41847L12.6464 6.64645C12.4512 6.84171 12.4512 7.15829 12.6464 7.35355C12.82 7.52712 13.0894 7.5464 13.2843 7.41141L13.3536 7.35355L14.5815 6.12558C15.4664 7.17092 16 8.52315 16 10C16 11.4769 15.4664 12.8291 14.5815 13.8744ZM10 8C11.1046 8 12 8.89543 12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11C10.5523 11 11 10.5523 11 10C11 9.44772 10.5523 9 10 9Z"
    fill={color}
    fillRule="evenodd"
  />
);
