import type { IconChildrenProps } from "../../icon.types";

export const SolutionsFreightAndLogistics = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M15.0013 2.4657L16.668 4.13236L15.0013 5.79903"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      d="M11.668 4.1323H16.668"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      d="M16.7158 7.46273C18.1375 10.3244 17.5895 13.7727 15.3506 16.0527C13.1117 18.3326 9.67395 18.9432 6.78688 17.5737C3.8998 16.2042 2.19782 13.1556 2.54711 9.97931C2.89641 6.80304 5.22024 4.1971 8.33592 3.48773"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      clipRule="evenodd"
      d="M13.2688 11.9606L11.1617 14.0678C10.5201 14.7093 9.4799 14.7093 8.83832 14.0678L6.73117 11.9606C6.08961 11.319 6.08961 10.2788 6.73117 9.63727L8.83832 7.53012C9.4799 6.88856 10.5201 6.88856 11.1617 7.53012L13.2688 9.63727C13.9104 10.2788 13.9104 11.319 13.2688 11.9606V11.9606Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
  </>
);
