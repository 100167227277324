import type { IconChildrenProps } from "../icon.types";

export const ListSearch = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M17.9063 4.47643C17.8583 3.29054 16.8816 2.34387 15.684 2.34387H4.66759L4.57591 2.34573C3.39001 2.39381 2.44336 3.37041 2.44336 4.56811V16.4319L2.44521 16.5236C2.4933 17.7094 3.4699 18.6561 4.66759 18.6561H8.90464L8.95877 18.6534C9.22573 18.6263 9.43406 18.4008 9.43406 18.1267C9.43406 17.8343 9.19703 17.5973 8.90464 17.5973H4.66759L4.5966 17.5951C3.98602 17.5585 3.50218 17.0517 3.50218 16.4319V4.56811L3.50431 4.49711C3.541 3.88653 4.04779 3.4027 4.66759 3.4027H15.684L15.7549 3.40482C16.3655 3.44151 16.8494 3.94832 16.8494 4.56811V7.95774L16.8521 8.01187C16.8792 8.27883 17.1047 8.48715 17.3788 8.48715C17.6712 8.48715 17.9082 8.25013 17.9082 7.95774V4.56811L17.9063 4.47643ZM14.5171 6.26295C14.5171 5.97056 14.2801 5.73353 13.9877 5.73353H6.36101L6.30688 5.73627C6.03992 5.76338 5.8316 5.98883 5.8316 6.26295C5.8316 6.55533 6.06862 6.79236 6.36101 6.79236H13.9877L14.0418 6.78962C14.3088 6.76251 14.5171 6.53706 14.5171 6.26295ZM10.5981 9.12321C10.8905 9.12321 11.1275 9.36024 11.1275 9.65262C11.1275 9.92673 10.9192 10.1522 10.6522 10.1793L10.5981 10.182H6.36101C6.06862 10.182 5.8316 9.94501 5.8316 9.65262C5.8316 9.37851 6.03992 9.15305 6.30688 9.12594L6.36101 9.12321H10.5981ZM9.43266 13.0422C9.43266 12.7498 9.19563 12.5128 8.90325 12.5128H6.36101L6.30688 12.5155C6.03992 12.5426 5.8316 12.7681 5.8316 13.0422C5.8316 13.3346 6.06862 13.5716 6.36101 13.5716H8.90325L8.95738 13.5689C9.22433 13.5418 9.43266 13.3163 9.43266 13.0422Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.6031 10.818L14.7138 10.8196C16.6978 10.8781 18.2883 12.5049 18.2883 14.5032C18.2883 15.3299 18.0161 16.0931 17.5563 16.7079L18.6007 17.7524C18.8075 17.9591 18.8074 18.2943 18.6007 18.5011C18.4077 18.694 18.1029 18.7069 17.895 18.5397L17.852 18.5011L16.8076 17.4566C16.1928 17.9162 15.4298 18.1883 14.6031 18.1883C12.5679 18.1883 10.918 16.5384 10.918 14.5031C10.9181 12.505 12.5085 10.8783 14.4925 10.8196L14.6031 10.818ZM14.6032 11.8767L14.5069 11.8786C13.101 11.9293 11.9769 13.085 11.9768 14.5032C11.9768 15.9537 13.1526 17.1295 14.6031 17.1295C16.0536 17.1295 17.2295 15.9537 17.2295 14.5032C17.2295 13.0849 16.1053 11.9292 14.6994 11.8785L14.6032 11.8767Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
