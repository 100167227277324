import type { IconChildrenProps } from "../icon.types";

export const ZoomIn = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M3.06836 9.25482C3.06836 5.83925 5.83723 3.07037 9.25281 3.07037C12.6684 3.07037 15.4373 5.83925 15.4373 9.25482C15.4373 10.748 14.9081 12.1176 14.027 13.1863L16.7567 15.916C16.9886 16.1479 16.9886 16.5238 16.7567 16.7557C16.5506 16.9618 16.2307 16.9847 15.9993 16.8244L15.917 16.7557L13.1876 14.0263C12.1185 14.909 10.7476 15.4393 9.25281 15.4393C5.83723 15.4393 3.06836 12.6704 3.06836 9.25482ZM14.2498 9.25482C14.2498 6.49508 12.0125 4.25787 9.25281 4.25787C6.49307 4.25787 4.25586 6.49508 4.25586 9.25482C4.25586 12.0146 6.49307 14.2518 9.25281 14.2518C12.0125 14.2518 14.2498 12.0146 14.2498 9.25482ZM11.6992 9.61667H9.61589V11.7C9.61589 11.9292 9.42839 12.1167 9.19922 12.1167C8.97005 12.1167 8.78255 11.9292 8.78255 11.7V9.61667H6.69922C6.47005 9.61667 6.28255 9.42917 6.28255 9.2C6.28255 8.97083 6.47005 8.78333 6.69922 8.78333H8.78255V6.7C8.78255 6.47083 8.97005 6.28333 9.19922 6.28333C9.42839 6.28333 9.61589 6.47083 9.61589 6.7V8.78333H11.6992C11.9284 8.78333 12.1159 8.97083 12.1159 9.2C12.1159 9.42917 11.9284 9.61667 11.6992 9.61667Z"
    fill={color}
    fillRule="evenodd"
  />
);
