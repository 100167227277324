import type { IconChildrenProps } from "../icon.types";

export const Money = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M16.7765 6.08234H3.22353C1.99514 6.08234 1 7.07748 1 8.30587V16.7765C1 18.0048 1.99514 19 3.22353 19H16.7765C18.0049 19 19 18.0048 19 16.7765V8.30587C19 7.07748 18.0049 6.08234 16.7765 6.08234ZM3.22353 7.14116H16.7765C17.4201 7.14116 17.9412 7.66225 17.9412 8.30587V16.7765C17.9412 17.4201 17.4201 17.9412 16.7765 17.9412H3.22353C2.57992 17.9412 2.05882 17.4201 2.05882 16.7765V8.30587C2.05882 7.66225 2.57992 7.14116 3.22353 7.14116ZM8.12826 10.6694C9.16199 9.63565 10.838 9.63565 11.8718 10.6694C12.9055 11.7031 12.9055 13.3791 11.8718 14.4129C10.838 15.4466 9.16199 15.4466 8.12825 14.4129C7.09453 13.3791 7.09453 11.7031 8.12826 10.6694ZM11.0576 11.3561C10.4339 10.7986 9.47581 10.8192 8.87696 11.4181C8.25673 12.0383 8.25673 13.0439 8.87696 13.6642C9.4972 14.2844 10.5028 14.2844 11.1231 13.6642C11.7219 13.0653 11.7426 12.1072 11.185 11.4836L11.1231 11.4181L11.0576 11.3561ZM15.0824 9.8941C15.3565 9.8941 15.5819 10.1024 15.609 10.3694L15.6118 10.4235V14.6588C15.6118 14.9512 15.3747 15.1882 15.0824 15.1882C14.8082 15.1882 14.5828 14.9799 14.5557 14.7129L14.5529 14.6588V10.4235C14.5529 10.1311 14.79 9.8941 15.0824 9.8941ZM5.44433 10.3694C5.41721 10.1024 5.19176 9.8941 4.91765 9.8941C4.62526 9.8941 4.38824 10.1311 4.38824 10.4235V14.6588L4.39097 14.7129C4.41808 14.9799 4.64354 15.1882 4.91765 15.1882C5.21003 15.1882 5.44706 14.9512 5.44706 14.6588V10.4235L5.44433 10.3694Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M16.4581 1.52941C16.4581 1.23703 16.221 1 15.9287 1H4.06983L4.0157 1.00273C3.74874 1.02984 3.54042 1.2553 3.54042 1.52941C3.54042 1.8218 3.77744 2.05882 4.06983 2.05882H15.9287L15.9828 2.05609C16.2497 2.02898 16.4581 1.80352 16.4581 1.52941ZM17.3051 4.07059C17.3051 3.7782 17.0681 3.54118 16.7757 3.54118H3.22277L3.16864 3.54391C2.90168 3.57102 2.69336 3.79648 2.69336 4.07059C2.69336 4.36297 2.93039 4.6 3.22277 4.6H16.7757L16.8298 4.59727C17.0968 4.57016 17.3051 4.3447 17.3051 4.07059Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
