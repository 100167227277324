import type { IconChildrenProps } from "../icon.types";

export const TaskManagement = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M5.16269 6.83734C4.03254 6.83734 3.11637 7.75351 3.11637 8.88366C3.11637 10.0138 4.03254 10.93 5.16269 10.93C6.29284 10.93 7.209 10.0138 7.209 8.88366C7.209 7.75351 6.29284 6.83734 5.16269 6.83734ZM5.16269 7.95318C5.67658 7.95318 6.09317 8.36977 6.09317 8.88366C6.09317 9.39755 5.67658 9.81414 5.16269 9.81414C4.6488 9.81414 4.23221 9.39755 4.23221 8.88366C4.23221 8.36977 4.6488 7.95318 5.16269 7.95318ZM6.21056 11.6746L4.11626 11.6746C2.94814 11.6779 2.00333 12.6228 2 13.7893L2 14.093C2 14.6067 2.4164 15.0231 2.93005 15.0231H7.39525C7.90884 15.0231 8.32526 14.6066 8.32526 14.093V13.7909C8.32193 12.6228 7.37709 11.6779 6.21056 11.6746ZM6.20738 12.7904L6.30376 12.7953C6.81126 12.845 7.20794 13.2722 7.20943 13.7925L7.20883 13.9068H3.1152L3.11584 13.7909C3.11741 13.2397 3.56511 12.792 4.11785 12.7904H6.20738ZM13.791 14.6514L15.8853 14.6514C17.0518 14.6547 17.9967 15.5996 18 16.7677V17.0699C18 17.5835 17.5836 17.9999 17.07 17.9999H12.6048C12.0911 17.9999 11.6748 17.5835 11.6748 17.0699L11.6748 16.7661C11.6781 15.5996 12.6229 14.6547 13.791 14.6514ZM15.9785 15.7721L15.8821 15.7672H13.7926C13.2399 15.7688 12.7922 16.2165 12.7906 16.7677L12.7902 16.8839H16.8839L16.8842 16.7693C16.8827 16.2491 16.486 15.8219 15.9785 15.7721ZM14.8373 9.81409C13.7071 9.81409 12.791 10.7303 12.791 11.8604C12.791 12.9906 13.7071 13.9067 14.8373 13.9067C15.9674 13.9067 16.8836 12.9906 16.8836 11.8604C16.8836 10.7303 15.9674 9.81409 14.8373 9.81409ZM14.8373 10.9299C15.3512 10.9299 15.7678 11.3465 15.7678 11.8604C15.7678 12.3743 15.3512 12.7909 14.8373 12.7909C14.3234 12.7909 13.9068 12.3743 13.9068 11.8604C13.9068 11.3465 14.3234 10.9299 14.8373 10.9299Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.738 2.00006H9.99942C8.86929 2.00006 7.95312 2.91626 7.95312 4.04639V6.27895L7.95686 6.40361C8.02128 7.47575 8.91113 8.32532 9.99942 8.32532L10.0864 8.32473L10.087 8.88302C10.087 9.242 10.2936 9.56904 10.618 9.72303L10.7168 9.76325C11.0177 9.86598 11.3538 9.80812 11.6043 9.60413L13.175 8.32473L14.738 8.32532C15.8682 8.32532 16.7844 7.40917 16.7844 6.27903V4.04639C16.7844 2.91624 15.8682 2.00006 14.738 2.00006ZM9.99942 3.1159H14.738C15.2519 3.1159 15.6685 3.5325 15.6685 4.04639V6.27903C15.6685 6.79291 15.2519 7.20949 14.738 7.20949H12.9762L12.8999 7.21474C12.7991 7.22866 12.7036 7.26995 12.624 7.33478L11.2022 8.4921L11.2028 7.7674C11.2028 7.45928 10.953 7.20949 10.6449 7.20949H9.99942C9.48554 7.20949 9.06896 6.79289 9.06896 6.27895V4.04639C9.06896 3.53251 9.48556 3.1159 9.99942 3.1159Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
