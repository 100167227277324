import type { IconChildrenProps } from "../icon.types";

export const Send = ({ color }: IconChildrenProps) => (
  <path
    d="M2.15666 2.15238C2.31499 1.99447 2.55508 1.95208 2.75794 2.04623L17.6913 9.24655C17.877 9.33276 17.997 9.51767 17.9999 9.72237C18.0029 9.92706 17.8884 10.1154 17.7052 10.2069L2.77187 17.9404C2.57168 18.0404 2.33031 18.0049 2.16747 17.8514C2.00463 17.6979 1.95498 17.4591 2.04314 17.2535L5.14966 9.73812L2.04911 2.75317C1.95538 2.55018 1.99832 2.31029 2.15666 2.15238ZM6.08503 10.2632L3.5971 16.3358L15.2074 10.2632H6.08503ZM15.0515 9.19698H6.07459L3.62635 3.62479L15.0515 9.19698Z"
    fillRule="evenodd"
    clipRule="evenodd"
    fill={color}
  />
);
