import type { IconChildrenProps } from "../icon.types";

export const Add = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M14.9993 10.8333H10.8327V15C10.8327 15.4583 10.4577 15.8333 9.99935 15.8333C9.54102 15.8333 9.16602 15.4583 9.16602 15V10.8333H4.99935C4.54102 10.8333 4.16602 10.4583 4.16602 9.99999C4.16602 9.54166 4.54102 9.16666 4.99935 9.16666H9.16602V4.99999C9.16602 4.54166 9.54102 4.16666 9.99935 4.16666C10.4577 4.16666 10.8327 4.54166 10.8327 4.99999V9.16666H14.9993C15.4577 9.16666 15.8327 9.54166 15.8327 9.99999C15.8327 10.4583 15.4577 10.8333 14.9993 10.8333Z"
    fill={color}
    fillRule="evenodd"
  />
);
