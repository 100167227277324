import {
  useCallback,
  useEffect,
  useState,
  type ChangeEventHandler,
  type FormEventHandler,
} from "react";
import { getAuth0 } from "~/features/auth/auth.auth0";
import { setAuthStorage } from "~/features/auth/auth.storage";
import { type AuthTenant } from "~/features/auth/auth.types";
import { validateEmail, validatePassword } from "~/utils/form-validator";
import type { LoginFormProps, UserInfo } from "./login-form.types";
import {
  checkRecaptcha,
  emailPasswordConnection,
  getUserInfo,
} from "./login-form.utils";

export const useLoginForm = ({
  appName,
  appUrl,
  emailPasswordLoginError,
  fromSignUp,
  redirect,
  ssoLoginError,
}: LoginFormProps) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [userInfoLoading, setUserInfoLoading] = useState(false);

  const [emailPasswordLogin, setEmailPasswordLogin] = useState(false);
  const [emailPasswordLoginLoading, setEmailPasswordLoginLoading] =
    useState(false);

  const [ssoLoginLoading, setSsoLoginLoading] = useState(false);

  const [loginError, setLoginError] = useState<string>();

  const doSsoLogin = useCallback(
    (tenant: AuthTenant, connection: string) => {
      setSsoLoginLoading(true);

      const id = setAuthStorage({
        appName,
        appUrl,
        redirect,
        ssoLogin: true,
        tenant,
      });

      getAuth0(tenant, appName).authorize({
        connection,
        prompt: "login",
        state: id,
      });
    },
    [appName, appUrl, redirect],
  );

  const doEmailPasswordLogin = useCallback(
    (tenant: AuthTenant, email: string, password: string) => {
      setEmailPasswordLoginLoading(true);
      setLoginError(undefined);

      const id = setAuthStorage({
        appName,
        appUrl,
        emailPasswordLogin: true,
        redirect,
        tenant,
      });

      getAuth0(tenant, appName).login(
        {
          email,
          onRedirecting: (done) => {
            done();
          },
          password,
          realm: emailPasswordConnection,
          state: id,
        },
        (error) => {
          if (error) {
            setLoginError(
              "Invalid email or password. Please check your information and try again.",
            );
          }
          setEmailPasswordLoginLoading(false);
        },
      );
    },
    [appName, appUrl, redirect],
  );

  const fetchUserInfo = useCallback(
    async (email: string) => {
      // Start loading user info
      //   reset data and error
      setUserInfoLoading(true);
      setUserInfo(undefined);
      setLoginError(undefined);

      // Fetch recaptcha token
      const [recaptchaError, recaptchaToken] = await checkRecaptcha();
      if (recaptchaError) {
        // Error fetching recaptcha token
        setLoginError(recaptchaError);
      } else if (recaptchaToken) {
        // Got recaptcha token
        //   fetch user info
        const [error, response] = await getUserInfo(email, recaptchaToken);

        if (error) {
          // Error fetching user info
          setLoginError(error);
        } else if (response) {
          // Got user info
          setUserInfo(response);

          if (response.connection !== emailPasswordConnection) {
            // Attempt SSO login
            doSsoLogin(response.tenant, response.connection);
          } else {
            // Show password field
            setEmailPasswordLogin(true);
          }
        }
      }

      // Stop loading user info
      setUserInfoLoading(false);
    },
    [doSsoLogin],
  );

  const onEmailChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => setEmail(event.target.value),
    [],
  );

  const onPasswordChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => setPassword(event.target.value),
    [],
  );

  const onPressSubmit = useCallback(() => {
    if (email && !emailError && !userInfoLoading) {
      if (!userInfo) {
        void fetchUserInfo(email);
      } else if (emailPasswordLogin && password && !passwordError) {
        doEmailPasswordLogin(userInfo.tenant, email, password);
      }
    }
  }, [
    doEmailPasswordLogin,
    email,
    emailError,
    emailPasswordLogin,
    fetchUserInfo,
    password,
    passwordError,
    userInfo,
    userInfoLoading,
  ]);

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();
      onPressSubmit();
    },
    [onPressSubmit],
  );

  useEffect(() => {
    setEmailError(Boolean(email && !validateEmail(email)));
  }, [email]);

  useEffect(() => {
    setPasswordError(Boolean(password && !validatePassword(password)));
  }, [password]);

  useEffect(() => {
    if (emailPasswordLoginError) {
      setLoginError(
        "Invalid email or password. Please check your information and try again.",
      );
    }
  }, [emailPasswordLoginError]);

  useEffect(() => {
    if (ssoLoginError) {
      setLoginError("Invalid login. Please try again.");
    }
  }, [ssoLoginError]);

  return {
    email,
    emailError,
    emailPasswordLogin,
    fromSignUp,
    loading: emailPasswordLoginLoading || ssoLoginLoading || userInfoLoading,
    loginError,
    onEmailChange,
    onPasswordChange,
    onPressSubmit,
    onSubmit,
    password,
    passwordError,
  } as const;
};
