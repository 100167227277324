import type { IconChildrenProps } from "../icon.types";

export const Roles = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M3.22434 0.562866H6.12815C7.59861 0.562866 8.78993 1.75421 8.78993 3.22471V6.12851C8.78993 7.59896 7.59859 8.79029 6.12815 8.79029H3.22434C1.75384 8.79029 0.5625 7.59897 0.5625 6.12851V3.22471C0.5625 1.7542 1.75383 0.562866 3.22434 0.562866ZM6.12815 2.01477H3.22434C2.55569 2.01477 2.0144 2.55606 2.0144 3.22471V6.12851C2.0144 6.7971 2.5557 7.33839 3.22434 7.33839H6.12815C6.79673 7.33839 7.33802 6.7971 7.33802 6.12851V3.22471C7.33802 2.55607 6.79674 2.01477 6.12815 2.01477ZM13.8712 0.562866H16.775C18.2455 0.562866 19.4368 1.75419 19.4368 3.22471V6.12851C19.4368 7.59898 18.2455 8.79029 16.775 8.79029H13.8712C12.4007 8.79029 11.2094 7.59897 11.2094 6.12851V3.22471C11.2094 1.7542 12.4007 0.562866 13.8712 0.562866ZM16.775 2.01477H13.8712C13.2026 2.01477 12.6613 2.55606 12.6613 3.22471V6.12851C12.6613 6.7971 13.2026 7.33839 13.8712 7.33839H16.775C17.4436 7.33839 17.9849 6.79712 17.9849 6.12851V3.22471C17.9849 2.55604 17.4436 2.01477 16.775 2.01477ZM6.12815 11.2097H3.22434C1.75383 11.2097 0.5625 12.4011 0.5625 13.8716V16.7754C0.5625 18.2459 1.75383 19.4372 3.22434 19.4372H6.12815C7.59861 19.4372 8.78993 18.2459 8.78993 16.7754V13.8716C8.78993 12.4011 7.59861 11.2097 6.12815 11.2097ZM3.22434 12.6616H6.12815C6.79674 12.6616 7.33802 13.2029 7.33802 13.8716V16.7754C7.33802 17.444 6.79676 17.9853 6.12815 17.9853H3.22434C2.55568 17.9853 2.0144 17.444 2.0144 16.7754V13.8716C2.0144 13.2029 2.55569 12.6616 3.22434 12.6616Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M18.4693 13.4369C18.4693 13.036 18.1443 12.711 17.7434 12.711H12.9037L12.8295 12.7147C12.4634 12.7519 12.1777 13.0611 12.1777 13.4369C12.1777 13.8379 12.5028 14.1629 12.9037 14.1629H17.7434L17.8176 14.1592C18.1837 14.122 18.4693 13.8128 18.4693 13.4369ZM18.4693 17.2112C18.4693 16.8103 18.1443 16.4853 17.7434 16.4853H12.9037L12.8295 16.489C12.4634 16.5262 12.1777 16.8353 12.1777 17.2112C12.1777 17.6122 12.5028 17.9372 12.9037 17.9372H17.7434L17.8176 17.9334C18.1837 17.8962 18.4693 17.5871 18.4693 17.2112Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
