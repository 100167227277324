import type { IconChildrenProps } from "../icon.types";

export const Period1 = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5C14.1421 17.5 17.5 14.1421 17.5 10ZM3.5 10C3.5 6.41015 6.41015 3.5 10 3.5C13.5899 3.5 16.5 6.41015 16.5 10C16.5 13.5899 13.5899 16.5 10 16.5C6.41015 16.5 3.5 13.5899 3.5 10Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M13.4173 14.3099L5.69071 6.58205C5.47902 6.84858 5.29154 7.13521 5.13159 7.4386L12.5611 14.8687C12.8624 14.7099 13.149 14.523 13.4173 14.3099Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
