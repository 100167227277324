import { clsx } from "clsx";
import { forwardRef, useContext } from "react";
import { CardContext } from "../card";
import type { CardContentProps } from "./card-content.types";
import css from "./card-content.module.css";

export const CardContent = forwardRef<HTMLDivElement, CardContentProps>(
  (
    {
      className,
      classNames,
      noPadding = false,
      noPaddingX = false,
      noPaddingY = false,
      ...other
    },
    ref,
  ) => {
    const { size } = useContext(CardContext);

    return (
      <div
        ref={ref}
        className={clsx(
          css.root,
          className,
          classNames?.root,
          css[size],
          noPadding && css.noPadding,
          !noPadding && noPaddingX && css.noPaddingX,
          !noPadding && noPaddingY && css.noPaddingY,
        )}
        {...other}
      />
    );
  },
);

CardContent.displayName = "CardContent";
