import type { IconChildrenProps } from "../icon.types";

export const SmartDevices = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M9.7122 6.07072H5.26098C3.45999 6.07072 2 7.53071 2 9.3317V9.92682C2 11.7278 3.45999 13.1878 5.26098 13.1878H9.7122V6.07072ZM5.26098 4.57072C2.63156 4.57072 0.5 6.70228 0.5 9.3317V9.92682C0.5 12.5562 2.63156 14.6878 5.26098 14.6878H11.2122V4.57072H5.26098Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M4.4394 17.8709C3.65423 16.6611 3.06371 15.0802 2.75542 14.0012L4.19771 13.5891C4.48454 14.593 5.02475 16.0175 5.69763 17.0543C6.03763 17.5782 6.3701 17.9377 6.66508 18.1193C6.92243 18.2778 7.10666 18.2831 7.30701 18.1829C7.61826 18.0273 7.77329 17.8276 7.85635 17.609C7.94708 17.3703 7.97004 17.0564 7.91248 16.6741C7.79574 15.8986 7.37978 15.0509 7.01838 14.5088L8.26646 13.6767C8.69855 14.3248 9.23479 15.3815 9.39577 16.4508C9.47708 16.991 9.47027 17.5847 9.25849 18.1419C9.03903 18.7194 8.61878 19.2041 7.97783 19.5245C7.22598 19.9005 6.48778 19.7718 5.87848 19.3965C5.30681 19.0444 4.82843 18.4703 4.4394 17.8709Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M18.0438 15.623V3.04042C18.0438 2.74195 17.8019 2.5 17.5034 2.5C15.5146 2.5 13.9023 4.11224 13.9023 6.10105V12.5624C13.9023 14.5512 15.5146 16.1634 17.5034 16.1634C17.8019 16.1634 18.0438 15.9215 18.0438 15.623ZM17.5034 1C14.6862 1 12.4023 3.28382 12.4023 6.10105V12.5624C12.4023 15.3796 14.6862 17.6634 17.5034 17.6634C18.6303 17.6634 19.5438 16.7499 19.5438 15.623V3.04042C19.5438 1.91353 18.6303 1 17.5034 1Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
