import { createContext } from "react";
import { colors } from "./theme.colors";
import { shadows } from "./theme.shadows";
import { radius, sizes, spacings } from "./theme.sizes";
import { type ThemeContextValue, ThemeMode } from "./theme.types";
import { fontSizes, fontWeights } from "./theme.typography";

import { themeVariantLight } from "./variant/light/theme-variant.light";

// TODO: Use while implementing dark theme
// const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

// const mode: ThemeMode = prefersDarkScheme.matches
//   ? ThemeMode.dark
//   : ThemeMode.light;

// const palette: ThemePaletteVariables =
//   mode === ThemeMode.dark
//     ? themeVariantDark.palette
//     : themeVariantLight.palette;

export const initialThemeContextValue: ThemeContextValue = {
  borderRadius: radius,
  colors,
  fontSizes,
  fontWeights,
  mode: ThemeMode.light,
  palette: themeVariantLight.palette,
  setMode: () => console.warn("Missing ThemeProvider"),
  shadows,
  sizes,
  spacings,
};

export const ThemeContext = createContext(initialThemeContextValue);
