import { createRoot } from "react-dom/client";
import { start as startErrorReporter } from "~/features/error-reporter";
import { App } from "./app/app";

startErrorReporter({
  apiKey: import.meta.env.VITE_GOOGLE_CLOUD_ERROR_REPORTING_API_KEY,
  baseApiUrl: import.meta.env.VITE_GOOGLE_CLOUD_ERROR_REPORTING_URL,
  projectId: import.meta.env.VITE_GOOGLE_PROJECT_ID,
  serviceName: import.meta.env.VITE_GOOGLE_CLOUD_ERROR_REPORTING_SERVICE_NAME,
});

const container = document.getElementById("root") as HTMLElement;

const root = createRoot(container);

root.render(<App />);
