import type { IconChildrenProps } from "../icon.types";

export const Book = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M15.1343 2.24706C16.332 2.24706 17.308 3.19306 17.356 4.3789L17.3579 4.47058V9.55294C17.3579 9.84532 17.1208 10.0823 16.8284 10.0823C16.5543 10.0823 16.3289 9.87403 16.3018 9.60707L16.299 9.55294V4.47058C16.299 3.85081 15.8158 3.34465 15.2053 3.308L15.1343 3.30588H7.19315V13.2588H10.052C10.3444 13.2588 10.5814 13.4958 10.5814 13.7882C10.5814 14.0623 10.3731 14.2878 10.1061 14.3149L10.052 14.3176H4.96962C4.32601 14.3176 3.80492 14.8387 3.80492 15.4823C3.80492 16.1021 4.28812 16.6083 4.89863 16.6449L4.96962 16.6471H10.052C10.3444 16.6471 10.5814 16.8841 10.5814 17.1765C10.5814 17.4506 10.3731 17.676 10.1061 17.7031L10.052 17.7059H4.96962C3.74124 17.7059 2.74609 16.7107 2.74609 15.4823V4.47058C2.74609 3.27291 3.6921 2.29696 4.87794 2.24891L4.96962 2.24706H15.1343ZM3.80492 13.5877C4.11925 13.3941 4.48546 13.2766 4.87794 13.2607L4.96962 13.2588H6.13433V3.30588H4.96962C4.34985 3.30588 3.84368 3.78909 3.80704 4.3996L3.80492 4.47058V13.5877Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12.9627 12.4643C14.1623 11.2648 16.107 11.2648 17.3057 12.4645C18.3903 13.5491 18.482 15.2114 17.6476 16.3997L18.897 17.6492C19.1037 17.8559 19.1037 18.1911 18.897 18.3979C18.704 18.5908 18.3992 18.6037 18.1913 18.4365L18.1483 18.3979L16.9003 17.1499C16.894 17.1544 16.8876 17.1589 16.8812 17.1634C15.8491 17.8878 14.4191 17.8878 13.3872 17.1635C11.7993 16.0499 11.6155 13.8115 12.9627 12.4643ZM16.5812 16.033C16.5725 16.0405 16.5641 16.0485 16.5558 16.0567C16.547 16.0655 16.5386 16.0746 16.5305 16.0838C16.452 16.16 16.3661 16.2313 16.2728 16.2968C15.6059 16.765 14.6624 16.765 13.9953 16.2967C12.9481 15.5623 12.8277 14.0967 13.7114 13.213C14.4736 12.4509 15.6947 12.4278 16.4839 13.1436L16.5568 13.213L16.6228 13.2821C17.343 14.072 17.3124 15.2725 16.5812 16.033Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
