import { AR_CATEGORY_VEHICLE } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../icon.types";

export const CategoryVehicleControl = ({ color }: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();

  return (
    <>
      <path
        clipRule="evenodd"
        d="M15.9238 3.02893C16.2227 3.02893 16.4698 3.25105 16.5089 3.53923L16.5142 3.61935L16.515 10.9116L16.5137 10.9224L16.5142 12.1434L18.6584 12.1441C18.9573 12.1441 19.2044 12.3663 19.2434 12.6544L19.2488 12.7346C19.2488 13.0335 19.0267 13.2805 18.7385 13.3196L18.6584 13.325H15.9238C15.6249 13.325 15.3779 13.1029 15.3388 12.8147L15.3334 12.7346L15.3329 11.5018L13.19 11.502C12.8639 11.502 12.5996 11.2377 12.5996 10.9116C12.5996 10.6127 12.8217 10.3656 13.1099 10.3266L13.19 10.3212L15.3329 10.321L15.3334 3.61935C15.3334 3.29327 15.5977 3.02893 15.9238 3.02893Z"
        fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.4556 3.02893C10.6512 3.02893 10.8322 3.12567 10.9414 3.28385L10.9837 3.35531L13.7353 8.86119L13.7597 8.93199L13.7771 9.02316L13.7807 9.08845L13.7813 14.5575C13.7813 15.8429 12.7766 16.8935 11.5096 16.9669L11.3678 16.971C10.2387 16.971 9.29062 16.1955 9.02718 15.1481L5.50419 15.1481C5.24074 16.1955 4.29265 16.971 3.16349 16.971C1.87816 16.971 0.827504 15.9663 0.754097 14.6994L0.75 14.5575V9.08845C0.75 8.78954 0.972119 8.54251 1.2603 8.50342L1.34042 8.49803L2.57322 8.49779L2.57355 5.44233C2.57355 5.14342 2.79567 4.8964 3.08385 4.8573L3.16397 4.85191L6.21886 4.85136L6.21903 3.61935C6.21903 3.32044 6.44115 3.07342 6.72934 3.03432L6.80945 3.02893H10.4556ZM3.16349 13.325C2.48271 13.325 1.93084 13.8768 1.93084 14.5575C1.93084 15.2383 2.48271 15.7902 3.16349 15.7902C3.80641 15.7902 4.33441 15.2979 4.39109 14.6697L4.3955 14.5577C4.39224 13.873 3.84182 13.325 3.16349 13.325ZM11.3678 13.325C10.6871 13.325 10.1352 13.8768 10.1352 14.5575C10.1352 15.2383 10.6871 15.7902 11.3678 15.7902C12.0108 15.7902 12.5387 15.2979 12.5954 14.6697L12.6003 14.5384C12.5901 13.8665 12.0422 13.325 11.3678 13.325ZM12.5996 9.67863H1.9303L1.9306 12.4824C2.29144 12.2675 2.71306 12.1441 3.16349 12.1441C4.29256 12.1441 5.24061 12.9195 5.50414 13.9668L9.02724 13.9668C9.29075 12.9195 10.2387 12.1441 11.3678 12.1441C11.8179 12.1441 12.2392 12.2674 12.5999 12.4819L12.5996 9.67863ZM3.75431 6.0322L3.75405 8.49779H6.21886V6.0322H3.75431ZM10.0907 4.20977H7.39916L7.4005 5.44233L7.39891 5.46146L7.3997 8.49779H12.2341L10.0907 4.20977Z"
        fill={color}
        fillRule="evenodd"
      />
    </>
  );
};
