import type { IconChildrenProps } from "../icon.types";

export const PageLink = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M16.6669 1.44444C17.9232 1.44444 18.9476 2.43746 18.9981 3.68143L19 3.7776V6.44441V10.8889C19 11.1956 18.7514 11.4442 18.4447 11.4442C18.1571 11.4442 17.9206 11.2257 17.8922 10.9457L17.8893 10.8889V6.99975H2.11068V16.2224C2.11068 16.8725 2.61821 17.4042 3.25869 17.4426L3.33316 17.4449H5.55543C5.86213 17.4449 6.11076 17.6935 6.11076 18.0002C6.11076 18.2877 5.89224 18.5242 5.61221 18.5527L5.55543 18.5555H3.33316C2.0768 18.5555 1.05238 17.5625 1.00195 16.3185L1 16.2224V6.44441V3.7776C1 2.52125 1.99301 1.49682 3.23699 1.44639L3.33316 1.44444H16.6669ZM17.8893 3.7776V5.88907H2.11068V3.7776L2.11291 3.70313C2.1514 3.06265 2.68301 2.55512 3.33316 2.55512H16.6669L16.7413 2.55735C17.3818 2.59584 17.8893 3.12745 17.8893 3.7776ZM9.33265 4.10825C9.27942 3.83154 9.03604 3.6224 8.74369 3.6224C8.58455 3.6224 8.432 3.68567 8.3196 3.79807C8.20704 3.91062 8.14393 4.06318 8.14393 4.22221C8.14393 4.55337 8.41235 4.82197 8.74369 4.82197C9.07492 4.82197 9.34346 4.55344 9.34346 4.22221L9.34059 4.16543L9.33265 4.10825ZM6.48764 3.6224C6.64659 3.6224 6.79856 3.68549 6.91173 3.79807C7.00821 3.89454 7.06836 4.02041 7.08358 4.15455L7.08741 4.22221C7.08741 4.55344 6.81887 4.82197 6.48764 4.82197C6.15646 4.82197 5.88783 4.55353 5.88783 4.22221C5.88783 3.89093 6.15637 3.6224 6.48764 3.6224ZM4.82616 4.22221C4.82616 3.89093 4.55762 3.6224 4.22635 3.6224C3.89502 3.6224 3.62658 3.89103 3.62658 4.22221C3.62658 4.55344 3.89512 4.82197 4.22635 4.82197C4.55753 4.82197 4.82616 4.55353 4.82616 4.22221Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11.777 12.1113C12.0837 12.1113 12.3323 12.3599 12.3323 12.6666C12.3323 12.9542 12.1138 13.1907 11.8338 13.2191L11.777 13.222H10.8881C9.72199 13.222 8.77669 14.1673 8.77669 15.3334C8.77669 16.4671 9.67022 17.3921 10.7914 17.4426L10.8881 17.4448H11.777C12.0837 17.4448 12.3323 17.6934 12.3323 18.0001C12.3323 18.2876 12.1138 18.5241 11.8338 18.5526L11.777 18.5554H10.8881C9.1086 18.5554 7.66602 17.1129 7.66602 15.3334C7.66602 13.5895 9.05146 12.1691 10.7817 12.113L10.8881 12.1113H11.777ZM15.3335 12.1113C17.113 12.1113 18.5556 13.5539 18.5556 15.3334C18.5556 17.0773 17.1701 18.4976 15.4399 18.5537L15.3335 18.5554H14.4446C14.1379 18.5554 13.8893 18.3068 13.8893 18.0001C13.8893 17.7126 14.1078 17.4761 14.3878 17.4476L14.4446 17.4448H15.3335C16.4996 17.4448 17.4449 16.4994 17.4449 15.3334C17.4449 14.1997 16.5514 13.2747 15.4302 13.2241L15.3335 13.222H14.4446C14.1379 13.222 13.8893 12.9733 13.8893 12.6666C13.8893 12.3791 14.1078 12.1426 14.3878 12.1142L14.4446 12.1113H15.3335ZM15.8886 15.3333C15.8886 15.0266 15.64 14.7779 15.3333 14.7779H10.8887L10.8319 14.7808C10.5519 14.8093 10.3334 15.0457 10.3334 15.3333C10.3334 15.64 10.582 15.8886 10.8887 15.8886H15.3333L15.3901 15.8858C15.6701 15.8573 15.8886 15.6208 15.8886 15.3333Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
