import type { IconChildrenProps } from "../icon.types";

export const ArrowDownward = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M10.8339 4.51461V13.8229L14.9005 9.75627C15.2255 9.43127 15.7589 9.43127 16.0839 9.75627C16.4089 10.0813 16.4089 10.6063 16.0839 10.9313L10.5922 16.4229C10.4365 16.579 10.2251 16.6666 10.0047 16.6666C9.78427 16.6666 9.5729 16.579 9.4172 16.4229L3.9172 10.9396C3.7073 10.7297 3.62533 10.4238 3.70216 10.1371C3.77898 9.85035 4.00294 9.62639 4.28966 9.54957C4.57638 9.47274 4.8823 9.55471 5.0922 9.76461L9.1672 13.8229V4.51461C9.1672 4.05627 9.5422 3.68127 10.0005 3.68127C10.4589 3.68127 10.8339 4.05627 10.8339 4.51461Z"
    fill={color}
    fillRule="evenodd"
  />
);
