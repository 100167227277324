import type { IconChildrenProps } from "../icon.types";

export const DashboardChart = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M15.8656 1.84698H4.13472C2.8716 1.84698 1.84766 2.87093 1.84766 4.13404V12.4776C1.84766 13.7407 2.8716 14.7646 4.13472 14.7646H6.78136L6.09876 17.4951L6.08828 17.5482C6.04984 17.8138 6.21803 18.0706 6.48396 18.1371C6.76762 18.208 7.05505 18.0355 7.12597 17.7519L7.87277 14.7646H12.1284L12.8752 17.7519L12.891 17.8037C12.9821 18.0561 13.2513 18.2035 13.5172 18.1371C13.8009 18.0661 13.9734 17.7787 13.9024 17.4951L13.2198 14.7646H15.8665C17.1298 14.7646 18.1535 13.7405 18.1535 12.4767V4.13404C18.1535 2.87076 17.1294 1.84698 15.8656 1.84698ZM12.5537 13.7058H15.8665C16.5449 13.7058 17.0947 13.1558 17.0947 12.4767V4.13404C17.0947 3.45561 16.5447 2.90581 15.8656 2.90581H4.13472C3.45637 2.90581 2.90648 3.4557 2.90648 4.13404V12.4776C2.90648 13.1559 3.45637 13.7058 4.13472 13.7058H7.44751C7.45516 13.7056 7.46284 13.7056 7.47053 13.7058H12.5307C12.5384 13.7056 12.546 13.7056 12.5537 13.7058Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.25742 5.71051C9.23031 5.44355 9.00486 5.23523 8.73074 5.23523C8.43836 5.23523 8.20133 5.47226 8.20133 5.76464V10.847L8.20407 10.9011C8.23118 11.1681 8.45663 11.3764 8.73074 11.3764C9.02313 11.3764 9.26016 11.1394 9.26016 10.847V5.76464L9.25742 5.71051ZM6.71625 8.48001C6.68914 8.21305 6.46368 8.00473 6.18957 8.00473C5.89718 8.00473 5.66016 8.24176 5.66016 8.53414V10.8466L5.66289 10.9007C5.69 11.1677 5.91546 11.376 6.18957 11.376C6.48195 11.376 6.71898 11.139 6.71898 10.8466V8.53414L6.71625 8.48001ZM11.2719 7.50509C11.546 7.50509 11.7715 7.71342 11.7986 7.98038L11.8013 8.0345V10.8467C11.8013 11.1391 11.5643 11.3762 11.2719 11.3762C10.9978 11.3762 10.7724 11.1678 10.7452 10.9009L10.7425 10.8467V8.0345C10.7425 7.74212 10.9795 7.50509 11.2719 7.50509ZM14.3398 6.17622C14.3127 5.90927 14.0872 5.70094 13.8131 5.70094C13.5207 5.70094 13.2837 5.93797 13.2837 6.23035V10.8468L13.2864 10.901C13.3135 11.1679 13.539 11.3762 13.8131 11.3762C14.1055 11.3762 14.3425 11.1392 14.3425 10.8468V6.23035L14.3398 6.17622Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
