import { clsx } from "clsx";
import { SubmitButton } from "../submit-button";
import type { AuthButtonProps } from "./auth-button.types";
import css from "./auth-button.module.css";

export const AuthButton = ({
  className,
  disabled,
  ...props
}: AuthButtonProps) => {
  return (
    <SubmitButton
      className={clsx(css.authButton, className, disabled && css.disabled)}
      isAuthButton
      {...props}
    />
  );
};
