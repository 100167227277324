import { AR_CATEGORY_AREA_CONTROLS } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseHotObjectDetection = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19555)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.70937 11.63C5.68765 11.63 4.85938 12.4583 4.85938 13.48C4.85938 14.5017 5.68765 15.33 6.70937 15.33C7.7311 15.33 8.55937 14.5017 8.55937 13.48C8.55937 12.4583 7.7311 11.63 6.70937 11.63ZM5.65937 13.48C5.65937 12.9001 6.12948 12.43 6.70937 12.43C7.28927 12.43 7.75937 12.9001 7.75937 13.48C7.75937 14.0599 7.28927 14.53 6.70937 14.53C6.12948 14.53 5.65937 14.0599 5.65937 13.48Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1166 9.78893C13.2345 9.80208 13.3568 9.81 13.4811 9.81C15.1972 9.81 16.5911 8.41614 16.5911 6.7C16.5911 4.98385 15.1972 3.59 13.4811 3.59C11.765 3.59 10.3711 4.98385 10.3711 6.7C10.3711 6.82117 10.3786 6.94042 10.3912 7.0555L7.06335 10.3897C6.94788 10.3767 6.8305 10.37 6.71156 10.37C4.99396 10.37 3.60156 11.7624 3.60156 13.48C3.60156 15.1976 4.99396 16.59 6.71156 16.59C8.42917 16.59 9.82156 15.1976 9.82156 13.48C9.82156 13.3454 9.81301 13.2128 9.79642 13.0827L13.1166 9.78893ZM11.5177 5.92686L12.1166 5.32679C12.1465 5.29678 12.1799 5.27098 12.2158 5.24975L12.2804 5.19589C12.2915 5.18661 12.3031 5.17782 12.3149 5.16955C12.6296 4.95064 13.0095 4.83 13.4105 4.83C14.4866 4.83 15.3605 5.70386 15.3605 6.78C15.3605 7.04006 15.3298 7.38037 15.1287 7.68411C15.0607 7.78872 14.9303 7.94391 14.8417 8.04755C14.7916 8.10608 14.7447 8.1598 14.7103 8.19879L14.6691 8.24545L14.6575 8.25846L14.6531 8.2634C14.6465 8.27079 14.6397 8.27798 14.6326 8.28496L14.2491 8.66546C15.0341 8.35785 15.5911 7.59301 15.5911 6.7C15.5911 5.53614 14.645 4.59 13.4811 4.59C12.59 4.59 11.8266 5.14458 11.5177 5.92686ZM7.05311 11.3975C7.07862 11.4072 7.10513 11.4148 7.13242 11.4203C7.93601 11.581 8.58436 12.2193 8.76216 13.0274C8.76884 13.0578 8.77826 13.0872 8.79014 13.1152C8.8108 13.2337 8.82156 13.3556 8.82156 13.48C8.82156 14.6453 7.87688 15.59 6.71156 15.59C5.54624 15.59 4.60156 14.6453 4.60156 13.48C4.60156 12.3147 5.54624 11.37 6.71156 11.37C6.82785 11.37 6.94194 11.3794 7.05311 11.3975ZM9.45462 12.0132L13.9174 7.58589L13.9214 7.58135L13.9597 7.53809C13.9919 7.50146 14.0356 7.45142 14.0818 7.39745C14.1846 7.27733 14.2671 7.17501 14.2899 7.13962L14.2944 7.13261C14.3314 7.07717 14.3605 6.97902 14.3605 6.78C14.3605 6.25614 13.9343 5.83 13.4105 5.83C13.2187 5.83 13.0444 5.88513 12.902 5.97962L12.8006 6.06411C12.7907 6.07234 12.7805 6.08015 12.7701 6.08755L8.14645 10.7201C8.70189 11.0094 9.15902 11.4615 9.45462 12.0132Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4784 3.14999C11.5165 3.14999 9.91844 4.74806 9.91844 6.70999V6.78942C9.91844 6.85573 9.8921 6.91989 9.84521 6.96677L6.96521 9.84677C6.91833 9.89365 6.85474 9.91999 6.78844 9.91999H6.70861C4.74668 9.91999 3.14844 11.5181 3.14844 13.48C3.14844 15.4419 4.74651 17.04 6.70844 17.04C8.67037 17.04 10.2684 15.4419 10.2684 13.48V13.3601C10.2684 13.2935 10.295 13.2295 10.3423 13.1826L13.2023 10.3426C13.2491 10.2961 13.3124 10.27 13.3784 10.27H13.4781C15.44 10.27 17.0384 8.67192 17.0384 6.70999C17.0384 4.74806 15.4404 3.14999 13.4784 3.14999ZM9.41851 6.68637C9.43127 4.45912 11.2482 2.64999 13.4784 2.64999C15.7165 2.64999 17.5384 4.47192 17.5384 6.70999C17.5384 8.94705 15.7182 10.7683 13.4815 10.77L10.7684 13.4641V13.4795C10.7684 15.7176 8.94651 17.54 6.70844 17.54C4.47037 17.54 2.64844 15.7181 2.64844 13.48C2.64844 11.2498 4.45756 9.43283 6.68482 9.42006L9.41851 6.68637Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.48 2.2C11.0735 2.2 9.1068 4.08821 8.96983 6.45578C8.96842 6.48019 8.95811 6.50324 8.94086 6.52056L6.52086 8.95056C6.5034 8.96809 6.48007 8.97853 6.45536 8.97986C4.08708 9.10708 2.2 10.9945 2.2 13.48C2.2 15.9648 4.22523 17.99 6.71 17.99C9.12606 17.99 11.1025 16.0725 11.2101 13.6855C11.2112 13.6604 11.2218 13.6367 11.2396 13.619L13.6396 11.239C13.6571 11.2216 13.6804 11.2113 13.7051 11.2101C16.0932 11.0927 17.99 9.19531 17.99 6.71C17.99 4.22635 15.8859 2.2 13.48 2.2ZM8.77252 6.40617C8.93431 3.95159 10.9795 2 13.48 2C15.9941 2 18.19 4.11365 18.19 6.71C18.19 9.29133 16.2272 11.2669 13.7534 11.4078L11.408 13.7337C11.2759 16.2084 9.2207 18.19 6.71 18.19C4.11477 18.19 2 16.0752 2 13.48C2 10.899 3.95244 8.93346 6.4061 8.78237L8.77252 6.40617Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19555">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
