import { type RefObject, useLayoutEffect } from "react";
import { useLatest } from "../use-latest";
import type { UseResizeObserverCallback } from "./use-resize-observer.types";
import { getResizeObserver } from "./use-resize-observer.utils";

export const useResizeObserver = <T extends HTMLElement>(
  target: RefObject<T> | T | null,
  callback: UseResizeObserverCallback,
) => {
  const resizeObserver = getResizeObserver();
  const storedCallback = useLatest(callback);

  useLayoutEffect(() => {
    let didUnsubscribe = false;
    const targetEl = target && "current" in target ? target.current : target;

    if (!targetEl)
      return () => {
        // do nothing
      };

    const cb = (entry: ResizeObserverEntry, observer: ResizeObserver) => {
      if (didUnsubscribe) {
        return;
      }
      storedCallback.current(entry, observer);
    };

    resizeObserver.subscribe(targetEl as HTMLElement, cb);

    return () => {
      didUnsubscribe = true;
      resizeObserver.unsubscribe(targetEl as HTMLElement, cb);
    };
  }, [target, resizeObserver, storedCallback]);

  return resizeObserver.observer;
};
