import type { IconChildrenProps } from "../icon.types";

export const ArrowLeft = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M15.8337 9.16668H6.52534L10.592 5.10001C10.917 4.77501 10.917 4.24168 10.592 3.91668C10.4363 3.76065 10.2249 3.67297 10.0045 3.67297C9.78409 3.67297 9.57271 3.76065 9.41701 3.91668L3.92534 9.40834C3.76932 9.56405 3.68164 9.77542 3.68164 9.99584C3.68164 10.2163 3.76932 10.4276 3.92534 10.5833L9.41701 16.075C9.6269 16.2849 9.93283 16.3669 10.2196 16.2901C10.5063 16.2132 10.7302 15.9893 10.8071 15.7025C10.8839 15.4158 10.8019 15.1099 10.592 14.9L6.52534 10.8333H15.8337C16.292 10.8333 16.667 10.4583 16.667 10C16.667 9.54168 16.292 9.16668 15.8337 9.16668Z"
    fill={color}
    fillRule="evenodd"
  />
);
