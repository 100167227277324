import type { IconChildrenProps } from "../icon.types";

export const Bookmarked = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M10.6322 2.70001V3.96601L5.83257 3.96668C5.72211 3.96668 5.63257 4.05622 5.63257 4.16668V14.975L5.64108 15.0334C5.67419 15.1431 5.79815 15.2073 5.91126 15.1589L9.99923 13.409L14.0872 15.1589L14.1442 15.174C14.2581 15.1867 14.3659 15.098 14.3659 14.975L14.3652 9.36601H15.6322V17.196L10.078 14.8162H9.92045L4.36523 17.196L4.3659 4.16668C4.3659 3.36047 5.02636 2.70001 5.83257 2.70001L10.6322 2.70001Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.8583 7.50001L12.5 5.14168L13.675 3.96668L14.85 5.14168L17.8 2.19168L18.975 3.36668L14.8583 7.50001Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
