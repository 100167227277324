import type { IconChildrenProps } from "../icon.types";

export const Time = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M17.5078 10C17.5078 5.85786 14.1499 2.5 10.0078 2.5C5.86568 2.5 2.50781 5.85786 2.50781 10C2.50781 14.1421 5.86568 17.5 10.0078 17.5C14.1499 17.5 17.5078 14.1421 17.5078 10ZM3.50781 10C3.50781 6.41015 6.41796 3.5 10.0078 3.5C13.5977 3.5 16.5078 6.41015 16.5078 10C16.5078 13.5899 13.5977 16.5 10.0078 16.5C6.41796 16.5 3.50781 13.5899 3.50781 10ZM10.0078 5.00212C10.2533 5.00212 10.4574 5.17899 10.4998 5.41224L10.5078 5.50212V9.13378C10.8067 9.30669 11.0078 9.62986 11.0078 10C11.0078 10.0899 10.996 10.177 10.9737 10.2599L13.1605 12.4466C13.3341 12.6202 13.3533 12.8896 13.2184 13.0845L13.1605 13.1537C12.9869 13.3273 12.7175 13.3466 12.5226 13.2116L12.4534 13.1537L10.266 10.9664C10.1836 10.9883 10.0971 11 10.0078 11C9.45553 11 9.00781 10.5523 9.00781 10C9.00781 9.62986 9.20891 9.30669 9.50781 9.13378V5.50212C9.50781 5.22597 9.73167 5.00212 10.0078 5.00212Z"
    fill={color}
    fillRule="evenodd"
  />
);
