import type { IconChildrenProps } from "../icon.types";

export const Inspection = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M16.4397 4.46099C16.3955 3.3705 15.4975 2.5 14.3961 2.5H5.04529L4.96098 2.50171C3.87049 2.54592 3 3.44395 3 4.54529V15.4546L3.00171 15.5389C3.04592 16.6294 3.94394 17.4999 5.04529 17.4999H8.16224L8.21202 17.4974C8.4575 17.4725 8.64906 17.2651 8.64906 17.0131C8.64906 16.7442 8.43111 16.5263 8.16224 16.5263H5.04529L4.98001 16.5243C4.41855 16.4906 3.97364 16.0245 3.97364 15.4546V4.54529L3.9756 4.48001C4.00933 3.91855 4.47535 3.47364 5.04529 3.47364H14.3961L14.4614 3.4756C15.0229 3.50933 15.4678 3.97536 15.4678 4.54529V6.88302L15.4703 6.93279C15.4952 7.17828 15.7025 7.36984 15.9546 7.36984C16.2235 7.36984 16.4414 7.15188 16.4414 6.88302V4.54529L16.4397 4.46099ZM7.38185 11.0716L7.29911 11.0738C6.4118 11.125 5.7262 11.849 5.7262 12.7273L5.72838 12.81C5.77959 13.6973 6.50362 14.3829 7.38185 14.3829L7.46459 14.3807C8.35193 14.3295 9.03755 13.6055 9.03755 12.7273L9.03536 12.6445C8.98415 11.7572 8.26011 11.0716 7.38185 11.0716ZM7.34134 12.0464L7.38177 12.0452C7.74354 12.0452 8.04194 12.3278 8.06274 12.6868L8.06411 12.7413C8.0639 13.0889 7.78135 13.3873 7.42237 13.4081L7.36779 13.4095C7.02019 13.4093 6.7218 13.1267 6.701 12.7678L6.69963 12.7132C6.69984 12.3656 6.9824 12.0672 7.34134 12.0464Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M16.7202 10.8196L13.1124 14.4107L11.8816 13.1566C11.587 12.862 11.1122 12.862 10.8193 13.1549C10.5264 13.4478 10.5264 13.9227 10.8193 14.2156L12.2249 15.6484L12.7923 16.2158C12.968 16.3916 13.2529 16.3916 13.4287 16.2158L13.9944 15.6502L17.7805 11.8807C18.0737 11.5874 18.0737 11.1125 17.7809 10.8196C17.488 10.5268 17.0131 10.5268 16.7202 10.8196Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      d="M13.0479 7C13.5737 7 14 7.21796 14 7.48682C14 7.73888 13.6254 7.9462 13.1453 7.97113L13.0479 7.97364H6.95208C6.42626 7.97364 6 7.75568 6 7.48682C6 7.23476 6.37464 7.02744 6.85474 7.00251L6.95208 7H13.0479Z"
      fill={color}
    />
  </>
);
