import type { IconChildrenProps } from "../icon.types";

export const Reports = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M16.4397 4.46099C16.3955 3.3705 15.4975 2.5 14.3961 2.5H5.04529L4.96098 2.50171C3.87049 2.54592 3 3.44395 3 4.54529V15.4546L3.00171 15.5389C3.04592 16.6294 3.94394 17.4999 5.04529 17.4999H8.16224L8.21202 17.4974C8.4575 17.4725 8.64906 17.2651 8.64906 17.0131C8.64906 16.7442 8.43111 16.5263 8.16224 16.5263H5.04529L4.98001 16.5243C4.41855 16.4906 3.97364 16.0245 3.97364 15.4546V4.54529L3.9756 4.48001C4.00933 3.91855 4.47535 3.47364 5.04529 3.47364H14.3961L14.4614 3.4756C15.0229 3.50933 15.4678 3.97536 15.4678 4.54529V6.88302L15.4703 6.93279C15.4952 7.17828 15.7025 7.36984 15.9546 7.36984C16.2235 7.36984 16.4414 7.15188 16.4414 6.88302V4.54529L16.4397 4.46099ZM7.38185 11.0716L7.29911 11.0738C6.4118 11.125 5.7262 11.849 5.7262 12.7273L5.72838 12.81C5.77959 13.6973 6.50362 14.3829 7.38185 14.3829L7.46459 14.3807C8.35193 14.3295 9.03755 13.6055 9.03755 12.7273L9.03536 12.6445C8.98415 11.7572 8.26011 11.0716 7.38185 11.0716ZM7.34134 12.0464L7.38177 12.0452C7.74354 12.0452 8.04194 12.3278 8.06274 12.6868L8.06411 12.7413C8.0639 13.0889 7.78135 13.3873 7.42237 13.4081L7.36779 13.4095C7.02019 13.4093 6.7218 13.1267 6.701 12.7678L6.69963 12.7132C6.69984 12.3656 6.9824 12.0672 7.34134 12.0464ZM12.4469 5.61694C12.7158 5.61694 12.9337 5.83489 12.9337 6.10376C12.9337 6.35582 12.7422 6.56313 12.4967 6.58806L12.4469 6.59058H6.21302C5.94415 6.59058 5.7262 6.37262 5.7262 6.10376C5.7262 5.8517 5.91776 5.64438 6.16324 5.61945L6.21302 5.61694H12.4469ZM9.81677 8.83108C9.81677 8.56221 9.59881 8.34426 9.32995 8.34426H6.21302L6.16324 8.34677C5.91776 8.3717 5.7262 8.57902 5.7262 8.83108C5.7262 9.09994 5.94415 9.3179 6.21302 9.3179H9.32995L9.37972 9.31538C9.62521 9.29045 9.81677 9.08314 9.81677 8.83108Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M15.5535 9.28391L15.5166 9.325L10.6434 14.1987C10.4692 14.3718 10.3557 14.5987 10.3216 14.8435L10.0188 16.9436C9.97298 17.2613 10.2417 17.5356 10.5603 17.4962L12.605 17.2437C12.8564 17.2127 13.0903 17.0986 13.2696 16.9196L18.2242 11.965L18.2893 11.8969C18.9622 11.1576 18.9432 10.0114 18.2298 9.29426L18.225 9.2902C17.4889 8.551 16.293 8.54817 15.5535 9.28391ZM17.5436 9.98534L17.5395 9.98094C17.8961 10.3394 17.8948 10.919 17.5365 11.2758L12.5814 16.2309L12.5607 16.2483C12.5387 16.264 12.5129 16.2741 12.4857 16.2774L11.0732 16.4519L11.2856 14.9802C11.2906 14.944 11.3064 14.9125 11.3307 14.8883L16.2411 9.97794L16.2627 9.9534L16.2402 9.97414C16.5823 9.63375 17.126 9.61953 17.485 9.93056L17.5436 9.98534Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
