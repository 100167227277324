import type { IconChildrenProps } from "../../icon.types";

export const VehicleDetection = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.30418 2.52935C7.30418 2.23696 7.06716 1.99994 6.77477 1.99994H4.86889L4.77546 2.0017C3.4735 2.05078 2.43359 3.12124 2.43359 4.43523V6.34112L2.43633 6.39524C2.46344 6.6622 2.68889 6.87053 2.96301 6.87053C3.25539 6.87053 3.49242 6.6335 3.49242 6.34112V4.43523L3.4946 4.35709C3.53507 3.63298 4.1346 3.05876 4.86889 3.05876H6.77477L6.8289 3.05603C7.09586 3.02892 7.30418 2.80346 7.30418 2.52935ZM3.48968 13.9105C3.46257 13.6436 3.23712 13.4352 2.96301 13.4352C2.67062 13.4352 2.43359 13.6723 2.43359 13.9647V15.8705L2.43535 15.964C2.48443 17.2659 3.55489 18.3058 4.86889 18.3058H6.77477L6.8289 18.3031C7.09586 18.276 7.30418 18.0505 7.30418 17.7764C7.30418 17.484 7.06716 17.247 6.77477 17.247H4.86889L4.79075 17.2448C4.06663 17.2044 3.49242 16.6048 3.49242 15.8705V13.9647L3.48968 13.9105ZM18.2103 13.4352C18.4844 13.4352 18.7099 13.6436 18.737 13.9105L18.7397 13.9646V15.8705C18.7397 17.1845 17.6998 18.255 16.3979 18.3041L16.3044 18.3058H14.3986C14.1062 18.3058 13.8691 18.0688 13.8691 17.7764C13.8691 17.5023 14.0775 17.2768 14.3444 17.2497L14.3986 17.247H16.3044C17.0387 17.247 17.6383 16.6728 17.6787 15.9487L17.6809 15.8705V13.9646C17.6809 13.6723 17.9179 13.4352 18.2103 13.4352ZM18.738 4.3418C18.6889 3.03984 17.6184 1.99994 16.3044 1.99994H14.3986L14.3444 2.00267C14.0775 2.02978 13.8691 2.25524 13.8691 2.52935C13.8691 2.82174 14.1062 3.05876 14.3986 3.05876H16.3044L16.3826 3.06094C17.1067 3.10141 17.6809 3.70095 17.6809 4.43523V6.34112L17.6836 6.39524C17.7108 6.6622 17.9362 6.87053 18.2103 6.87053C18.5027 6.87053 18.7397 6.6335 18.7397 6.34112V4.43523L18.738 4.3418Z"
      fill={accentColor}
    />
    <path
      d="M5.58203 14.1667V9.16669H15.582V14.1667"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5846 6.66669H6.41797V9.16669H10.5846V6.66669Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.582 9.16667H10.582V5H13.3006L15.582 9.16667Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.2487 15.8333C8.16917 15.8333 8.91536 15.0871 8.91536 14.1667C8.91536 13.2462 8.16917 12.5 7.2487 12.5C6.32822 12.5 5.58203 13.2462 5.58203 14.1667C5.58203 15.0871 6.32822 15.8333 7.2487 15.8333Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9167 15.8333C14.8371 15.8333 15.5833 15.0871 15.5833 14.1667C15.5833 13.2462 14.8371 12.5 13.9167 12.5C12.9962 12.5 12.25 13.2462 12.25 14.1667C12.25 15.0871 12.9962 15.8333 13.9167 15.8333Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.91797 14.1667H12.2513"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);
