import { AR_CATEGORY_ERGONOMICS } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseReba = ({ color, oppositeColor }: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19335)">
        <path
          d="M11.8608 6.97H8.15078C7.27078 6.97 6.55078 7.69 6.55078 8.57V10.85H7.75078V8.57C7.75078 8.35 7.93078 8.17 8.15078 8.17H11.8608C12.0808 8.17 12.2608 8.35 12.2608 8.57V10.85H13.4608V8.57C13.4608 7.69 12.7408 6.97 11.8608 6.97Z"
          fill={color}
        />
        <path
          d="M9.98078 6.68C11.2708 6.68 12.3208 5.63 12.3208 4.34C12.3208 3.05 11.2708 2 9.98078 2C8.69078 2 7.64078 3.05 7.64078 4.34C7.64078 5.63 8.69078 6.68 9.98078 6.68ZM9.98078 3.17C10.6308 3.17 11.1508 3.69 11.1508 4.34C11.1508 4.99 10.6308 5.51 9.98078 5.51C9.33078 5.51 8.81078 4.99 8.81078 4.34C8.81078 3.69 9.33078 3.17 9.98078 3.17Z"
          fill={color}
        />
        <path
          d="M12.1308 11.81C12.1308 12.15 11.9708 12.47 11.6908 12.66L11.2808 12.95C11.0308 13.13 10.8808 13.42 10.8808 13.73V16.56C10.8808 16.62 10.8308 16.67 10.7708 16.67H9.11078C9.05078 16.67 9.00078 16.62 9.00078 16.56V13.73C9.00078 13.42 8.85078 13.13 8.60078 12.95L8.19078 12.66C7.91078 12.46 7.75078 12.14 7.75078 11.81V11.08H6.55078V11.81C6.55078 12.54 6.90078 13.22 7.49078 13.64L7.79078 13.86V16.56C7.79078 17.28 8.38078 17.87 9.10078 17.87H10.7608C11.4808 17.87 12.0708 17.28 12.0708 16.56V13.86L12.3708 13.64C12.9608 13.22 13.3108 12.53 13.3108 11.81V11.08H12.1108V11.81H12.1308Z"
          fill={color}
        />
        <path
          d="M15.7698 12.4001C15.5998 12.0701 15.3298 11.7201 14.9998 11.3701C14.7798 11.1401 14.4098 11.1301 14.1698 11.3501C13.9398 11.5701 13.9298 11.9401 14.1498 12.1801C14.4498 12.4901 14.6298 12.7401 14.7198 12.9201C14.7198 12.9301 14.7298 12.9401 14.7398 12.9501C14.7098 12.9501 14.6698 12.9501 14.6298 12.9501C14.3698 12.9501 13.9798 12.8901 13.4698 12.7401C13.1598 12.6501 12.8398 12.8301 12.7498 13.1401C12.6598 13.4501 12.8398 13.7701 13.1498 13.8601C13.6998 14.0201 14.2098 14.1201 14.6398 14.1101C15.0198 14.1101 15.5598 14.0301 15.8298 13.5601C16.0698 13.1401 15.9298 12.6801 15.7798 12.3801L15.7698 12.4001Z"
          fill={getAlertCategoryColor(AR_CATEGORY_ERGONOMICS)}
        />
        <path
          d="M6.53042 12.7601C6.03042 12.9001 5.64042 12.9701 5.37042 12.9701C5.33042 12.9701 5.29042 12.9701 5.26042 12.9701C5.27042 12.9401 5.29042 12.9101 5.31042 12.8701C5.44042 12.6401 5.70042 12.3301 6.08042 11.9601C6.31042 11.7401 6.32042 11.3701 6.10042 11.1301C5.88042 10.8901 5.51042 10.8901 5.27042 11.1101C4.85042 11.5101 4.51042 11.9101 4.29042 12.2801C4.10042 12.6101 3.90042 13.1201 4.17042 13.5901C4.44042 14.0601 4.98042 14.1401 5.36042 14.1401C5.78042 14.1401 6.30042 14.0501 6.85042 13.8901C7.16042 13.8001 7.34042 13.4801 7.25042 13.1701C7.16042 12.8601 6.84042 12.6801 6.53042 12.7701V12.7601Z"
          fill={getAlertCategoryColor(AR_CATEGORY_ERGONOMICS)}
        />
        <path
          d="M8.07038 9.14999C7.99038 8.83999 7.68038 8.63999 7.36038 8.71999C7.15038 8.76999 6.99038 8.93999 6.94038 9.13999C6.59038 8.83999 6.29038 8.54999 6.07038 8.29999C5.86038 8.05999 5.73038 7.85999 5.67038 7.72999C5.76038 7.72999 5.88038 7.72999 6.04038 7.72999C6.24038 7.74999 6.48038 7.79999 6.75038 7.86999C6.93038 7.40999 7.35038 7.06999 7.86038 6.99999C7.23038 6.77999 6.64038 6.61999 6.16038 6.55999C5.87038 6.52999 5.58038 6.52999 5.32038 6.58999C5.06038 6.64999 4.76038 6.78999 4.59038 7.08999C4.37038 7.45999 4.46038 7.86999 4.59038 8.14999C4.72038 8.44999 4.94038 8.75999 5.20038 9.04999C5.52038 9.40999 5.93038 9.79999 6.42038 10.2C6.20038 10.31 6.07038 10.56 6.11038 10.82C6.16038 11.14 6.46038 11.35 6.78038 11.3L7.92038 11.11C8.08038 11.08 8.22038 10.99 8.31038 10.85C8.40038 10.71 8.43038 10.55 8.39038 10.39L8.08038 9.13999L8.07038 9.14999Z"
          fill={getAlertCategoryColor(AR_CATEGORY_ERGONOMICS)}
        />
        <path
          d="M15.4207 7.09999C15.2307 6.76999 14.8907 6.62999 14.6107 6.57999C14.3207 6.52999 14.0007 6.53999 13.6707 6.58999C13.2207 6.65999 12.7007 6.79999 12.1307 6.99999C12.6507 7.05999 13.0807 7.39999 13.2607 7.86999C13.4707 7.80999 13.6707 7.76999 13.8407 7.73999C14.0607 7.70999 14.2307 7.69999 14.3407 7.70999C14.2807 7.84999 14.1507 8.03999 13.9407 8.27999C13.7207 8.53999 13.4207 8.81999 13.0707 9.11999C13.0207 8.91999 12.8607 8.75999 12.6507 8.69999C12.3407 8.61999 12.0207 8.80999 11.9407 9.12999L11.6307 10.39C11.5907 10.55 11.6207 10.72 11.7107 10.85C11.8007 10.99 11.9407 11.08 12.1007 11.11L13.2407 11.3C13.5607 11.35 13.8607 11.14 13.9107 10.82C13.9507 10.56 13.8207 10.32 13.6007 10.2C14.0907 9.79999 14.5007 9.40999 14.8207 9.04999C15.0807 8.74999 15.3007 8.43999 15.4307 8.14999C15.5507 7.86999 15.6407 7.45999 15.4307 7.08999L15.4207 7.09999Z"
          fill={getAlertCategoryColor(AR_CATEGORY_ERGONOMICS)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19335">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
