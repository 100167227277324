import type { IconChildrenProps } from "../icon.types";

export const Solution = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M13.8121 1.42395C13.5197 1.42395 13.2827 1.66098 13.2827 1.95336C13.2827 2.24575 13.5197 2.48277 13.8121 2.48277C15.8588 2.48277 17.5179 4.14195 17.5179 6.18868C17.5179 6.48104 17.755 6.71809 18.0474 6.71809C18.3398 6.71809 18.5768 6.48104 18.5768 6.18868C18.5768 3.55718 16.4435 1.42395 13.8121 1.42395ZM13.8121 3.7575C13.5197 3.7575 13.2827 3.99452 13.2827 4.28691C13.2827 4.57931 13.5197 4.81631 13.8121 4.81631C14.5699 4.81631 15.1842 5.43064 15.1842 6.18847C15.1842 6.48087 15.4212 6.71788 15.7136 6.71788C16.006 6.71788 16.243 6.48083 16.243 6.18847C16.243 4.84588 15.1547 3.7575 13.8121 3.7575ZM10.1062 2.79959C10.1062 2.50721 9.86915 2.27018 9.57677 2.27018C5.07403 2.27018 1.42383 5.92039 1.42383 10.4231C1.42383 14.9259 5.07403 18.5761 9.57677 18.5761C14.0795 18.5761 17.7297 14.9259 17.7297 10.4231C17.7297 10.1307 17.4927 9.89371 17.2003 9.89371C16.9079 9.89371 16.6709 10.1307 16.6709 10.4231C16.6709 14.3411 13.4947 17.5172 9.57677 17.5172C5.65881 17.5172 2.48265 14.3411 2.48265 10.4231C2.48265 6.50516 5.65881 3.32901 9.57677 3.32901C9.86915 3.32901 10.1062 3.09198 10.1062 2.79959Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M7.03467 6.50546H12.117C12.8772 6.50546 13.4935 7.12172 13.4935 7.88193V10.8466C13.4935 11.6069 12.8772 12.2231 12.117 12.2231H10.1516V13.2819L11.1284 13.282C11.4208 13.282 11.6578 13.519 11.6578 13.8114C11.6578 14.0855 11.4495 14.311 11.1825 14.3381L11.1284 14.3408L8.11665 14.3407C7.82426 14.3407 7.58724 14.1037 7.58725 13.8113C7.58726 13.5372 7.79559 13.3117 8.06255 13.2846L8.11668 13.2819L9.09277 13.2819V12.2231H7.03467C6.27446 12.2231 5.6582 11.6069 5.6582 10.8466V7.88193C5.6582 7.12172 6.27446 6.50546 7.03467 6.50546ZM12.117 11.1643H9.62218H7.03467C6.85923 11.1643 6.71703 11.0221 6.71703 10.8466V7.88193C6.71703 7.70649 6.85923 7.56429 7.03467 7.56429H12.117C12.2925 7.56429 12.4347 7.70649 12.4347 7.88193V10.8466C12.4347 11.0221 12.2925 11.1643 12.117 11.1643Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
