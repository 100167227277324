import type { IconChildrenProps } from "../icon.types";

export const Edit = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M11.5835 4.75285L11.533 4.80909L4.86203 11.4807C4.62352 11.7176 4.4682 12.0282 4.42149 12.3634L4.00699 15.2382C3.9443 15.6731 4.31221 16.0485 4.74825 15.9947L7.54727 15.6491C7.89143 15.6066 8.21159 15.4504 8.45701 15.2054L15.2394 8.42296L15.3285 8.32975C16.2496 7.31773 16.2237 5.74871 15.247 4.76701L15.2404 4.76146C14.2328 3.74956 12.5958 3.74569 11.5835 4.75285ZM14.3077 5.71435L14.3021 5.70833C14.7903 6.19903 14.7884 6.99247 14.298 7.48084L7.51491 14.2639L7.48662 14.2878C7.45649 14.3092 7.42119 14.323 7.38394 14.3276L5.45036 14.5664L5.74108 12.5518C5.748 12.5022 5.76955 12.4591 5.80289 12.426L12.5247 5.70422L12.5543 5.67064L12.5235 5.69903C12.9918 5.23307 13.7361 5.2136 14.2275 5.63936L14.3077 5.71435Z"
    fill={color}
    fillRule="evenodd"
  />
);
