import type { IconChildrenProps } from "../icon.types";

export const DataPrivacy = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M7.12994 3.10656C5.53706 3.7062 4.22488 4.87909 3.44492 6.37371H5.89992C6.16588 5.24311 6.57589 4.14304 7.12994 3.10656ZM5.65467 7.70705H2.91831C2.75474 8.29542 2.66732 8.91548 2.66732 9.55593C2.66732 10.1964 2.75474 10.8164 2.91831 11.4048H5.65439C5.49062 10.1789 5.49071 8.93293 5.65467 7.70705ZM7.00082 11.4048C6.81955 10.1806 6.81967 8.9313 7.00118 7.70705H12.1118C12.2021 8.31629 12.2478 8.93447 12.2478 9.55593C12.2478 9.92412 12.5462 10.2226 12.9144 10.2226C13.2826 10.2226 13.5811 9.92412 13.5811 9.55593C13.5811 8.93535 13.5399 8.31753 13.4583 7.70705H16.1941C16.3577 8.29542 16.4451 8.91548 16.4451 9.55593C16.4451 9.92412 16.7436 10.2226 17.1118 10.2226C17.48 10.2226 17.7784 9.92412 17.7784 9.55593C17.7784 5.02821 14.1178 1.35466 9.59492 1.3338C9.56919 1.33339 9.54345 1.33339 9.51772 1.3338C5.97299 1.35005 2.95781 3.60997 1.81939 6.76633C1.8041 6.80018 1.79156 6.83555 1.78205 6.87213C1.49168 7.71325 1.33398 8.6162 1.33398 9.55593C1.33398 10.4957 1.49168 11.3986 1.78205 12.2397C1.79156 12.2763 1.8041 12.3117 1.81939 12.3455C2.96193 15.5133 5.99484 17.7782 9.55621 17.7782C9.9244 17.7782 10.2229 17.4797 10.2229 17.1115C10.2229 16.7433 9.9244 16.4448 9.55621 16.4448C9.16701 16.4448 8.80858 16.2454 8.58928 15.8797C7.99906 14.8942 7.56035 13.8342 7.27317 12.7382H9.55621L9.65472 12.7309C9.9762 12.6833 10.2229 12.4062 10.2229 12.0715C10.2229 11.7033 9.9244 11.4048 9.55621 11.4048H7.00082ZM5.89942 12.7382H3.44492C4.22462 14.2323 5.5362 15.4049 7.12838 16.0047C6.57477 14.9684 6.16512 13.8685 5.89942 12.7382ZM9.53447 2.66708C9.54172 2.66706 9.54896 2.66705 9.55621 2.66705C9.56362 2.66705 9.57103 2.66706 9.57844 2.66708C9.94125 2.67441 10.3014 2.863 10.523 3.23284C11.1086 4.21003 11.5498 5.26914 11.8394 6.37371H7.2738C7.56116 5.27785 8 4.21815 8.59032 3.23277C8.81166 2.86295 9.17166 2.67439 9.53447 2.66708ZM13.2132 6.37371C12.9455 5.23552 12.5336 4.1361 11.9836 3.10699C13.576 3.70676 14.8877 4.87944 15.6675 6.37371H13.2132Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M17.526 13.1563C17.4446 12.0397 16.4839 11.1115 15.3333 11.1115C14.1189 11.1115 13.1351 12.0953 13.1351 13.3097V13.8301C12.4814 14.0051 12 14.6016 12 15.3106V17.1346C12 17.981 12.686 18.667 13.5324 18.667H17.1342C17.9806 18.667 18.6667 17.981 18.6667 17.1346V15.3106C18.6667 14.6016 18.1853 14.0051 17.5316 13.8301V13.3097L17.526 13.1563ZM16.1982 13.7781V13.3097C16.1982 12.8568 15.794 12.4448 15.3333 12.4448C14.8921 12.4448 14.5285 12.7744 14.4752 13.2011L14.4684 13.3097V13.7781H16.1982ZM13.8018 15.1115H13.5324C13.4224 15.1115 13.3333 15.2005 13.3333 15.3106V17.1346C13.3333 17.2446 13.4224 17.3337 13.5324 17.3337H17.1342C17.2443 17.3337 17.3333 17.2446 17.3333 17.1346V15.3106C17.3333 15.2005 17.2443 15.1115 17.1342 15.1115H16.8649H13.8018Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
