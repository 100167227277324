import type { IconChildrenProps } from "../../icon.types";

export const ErgonomicsTrunkPosition = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M5.85577 2.30109C4.54755 2.30109 3.48703 3.36161 3.48703 4.66983C3.48703 5.97804 4.54755 7.03857 5.85577 7.03857C7.16399 7.03857 8.22451 5.97804 8.22451 4.66983C8.22451 3.36161 7.16399 2.30109 5.85577 2.30109ZM5.85577 3.48509C6.51008 3.48509 7.04051 4.01551 7.04051 4.66983C7.04051 5.32414 6.51008 5.85457 5.85577 5.85457C5.20145 5.85457 4.67103 5.32414 4.67103 4.66983C4.67103 4.01551 5.20145 3.48509 5.85577 3.48509ZM7.82997 7.82866H3.88165C3.1188 7.82866 2.5 8.4475 2.5 9.21038V11.9465L2.50567 12.0716C2.54327 12.4855 2.76594 12.8638 3.1154 13.0964L3.68431 13.4747L3.68447 16.3173C3.68447 17.0801 4.3033 17.6989 5.06612 17.6989H6.64549L6.75878 17.6944C7.46865 17.6367 8.02714 17.042 8.02714 16.3173L8.02643 13.4755L8.59646 13.0962C8.98063 12.8405 9.21161 12.4083 9.21161 11.9465V9.21038C9.21161 8.4475 8.59281 7.82866 7.82997 7.82866ZM3.88165 9.01265H7.82997C7.93889 9.01265 8.02762 9.10139 8.02762 9.21038V11.9465C8.02762 12.0126 7.99444 12.0746 7.94019 12.1107L7.10689 12.666C6.94212 12.7757 6.84314 12.9606 6.84314 13.1586V16.3173C6.84314 16.4262 6.75441 16.5149 6.64549 16.5149H5.06612C4.9572 16.5149 4.86847 16.4262 4.86847 16.3173V13.1586C4.86847 12.9606 4.76949 12.7757 4.60472 12.666L3.77166 12.1109C3.71717 12.0746 3.684 12.0126 3.684 11.9465V9.21038C3.684 9.10139 3.77273 9.01265 3.88165 9.01265Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      d="M14.5412 8.61865C14.8409 8.61865 15.0886 8.84137 15.1278 9.13032L15.1332 9.21065V13.3074L16.4914 11.9494C16.7016 11.7392 17.0305 11.7201 17.2622 11.8921L17.3286 11.9494C17.5388 12.1596 17.5579 12.4885 17.386 12.7202L17.3286 12.7866L14.9606 15.1546C14.8417 15.2735 14.6848 15.3313 14.5289 15.3279C14.3611 15.3244 14.2104 15.2512 14.1048 15.1359L11.7554 12.7866C11.5242 12.5554 11.5242 12.1806 11.7554 11.9494C11.9656 11.7392 12.2945 11.7201 12.5262 11.8921L12.5926 11.9494L13.9492 13.3058V9.21065C13.9492 8.8837 14.2143 8.61865 14.5412 8.61865Z"
      fill={color}
    />
  </>
);
