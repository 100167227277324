import { EmptyCell } from "~/components/icon/icons/empty-cell";
import { LeadingIndicators } from "~/components/icon/icons/leading-indicators";
import { ObservationRatio } from "~/components/icon/icons/observation-ratio";
import { Tracker } from "~/components/icon/icons/tracker";
import { Update } from "~/components/icon/icons/update";
import { UseCaseHotObjectDetection } from "~/components/icon/icons/use-cases/area-controls/hot-object-detection";
import { UseCaseInactive } from "~/components/icon/icons/use-cases/inactive";
import { UseCaseProtectiveClothing } from "~/components/icon/icons/use-cases/ppe/protective-clothing";
import { UseCaseVehiclePedestrianInteraction } from "~/components/icon/icons/use-cases/vehicle/vehicle-pedestrian-interaction";
import { UseCaseVehicleVehicleInteraction } from "~/components/icon/icons/use-cases/vehicle/vehicle-vehicle-interaction";
import { Abstract } from "./icons/abstract";
import { Accuracy } from "./icons/accuracy";
import { Accuracy1 } from "./icons/accuracy-1";
import { Action } from "./icons/action";
import { Add } from "./icons/add";
import { AddCircle } from "./icons/add-circle";
import { Alert } from "./icons/alert";
import { AlertCircle } from "./icons/alert-circle";
import { Alerts } from "./icons/alerts";
import { All } from "./icons/all";
import { Archive } from "./icons/archive";
import { Area } from "./icons/area";
import { Crane } from "./icons/area-types/crane";
import { Door } from "./icons/area-types/door";
import { Elevated } from "./icons/area-types/elevated";
import { Light } from "./icons/area-types/light";
import { Machinery } from "./icons/area-types/machinery";
import { Standing } from "./icons/area-types/standing";
import { Touch } from "./icons/area-types/touch";
import { VehicleRoad } from "./icons/area-types/vehicle-road";
import { ArrowCross } from "./icons/arrow-cross";
import { ArrowDownward } from "./icons/arrow-downward";
import { ArrowLeft } from "./icons/arrow-left";
import { ArrowRight } from "./icons/arrow-right";
import { ArrowUpward } from "./icons/arrow-upward";
import { Assignee } from "./icons/assignee";
import { Attach } from "./icons/attach";
import { Audit } from "./icons/audit";
import { Book } from "./icons/book";
import { Bookmark } from "./icons/bookmark";
import { BookmarkList } from "./icons/bookmark-list";
import { Bookmarked } from "./icons/bookmarked";
import { BusinessChart } from "./icons/business-chart";
import { Calendar } from "./icons/calendar";
import { Calibrate } from "./icons/calibrate";
import { Camera } from "./icons/camera";
import { Camera3d } from "./icons/camera-3d";
import { CaretDown } from "./icons/caret-down";
import { CaretLeft } from "./icons/caret-left";
import { CaretLeftDouble } from "./icons/caret-left-double";
import { CaretRight } from "./icons/caret-right";
import { CaretRightDouble } from "./icons/caret-right-double";
import { CaretUp } from "./icons/caret-up";
import { CategoryAnomaly } from "./icons/category/anomaly";
import { CategoryAnomaly2 } from "./icons/category/anomaly-2";
import { CategoryAreaControls } from "./icons/category/area-controls";
import { CategoryBehavior } from "./icons/category/behavior";
import { CategoryCustom } from "./icons/category/custom";
import { CategoryEmergencyAlerts } from "./icons/category/emergency-alerts";
import { CategoryEmergencyAlerts2 } from "./icons/category/emergency-alerts-2";
import { CategoryErgonomics } from "./icons/category/ergonomics";
import { CategoryPandemic } from "./icons/category/pandemic";
import { CategoryPandemic2 } from "./icons/category/pandemic-2";
import { CategoryPpe } from "./icons/category/ppe";
import { CategoryPublicSafety } from "./icons/category/public-safety";
import { CategoryVehicleControl } from "./icons/category/vehicle-control";
import { CategoryVehicleControl2 } from "./icons/category/vehicle-control-2";
import { ChartAlertTask } from "./icons/chart-alert-task";
import { ChartComplianceTask } from "./icons/chart-compliance-task";
import { Check } from "./icons/check";
import { CheckCircle } from "./icons/check-circle";
import { CheckboxIndeterminate } from "./icons/checkbox-indeterminate";
import { CheckboxNonselected } from "./icons/checkbox-nonselected";
import { CheckboxSelected } from "./icons/checkbox-selected";
import { Cloud } from "./icons/cloud";
import { Code } from "./icons/code";
import { Coffee } from "./icons/coffee";
import { Cog } from "./icons/cog";
import { Compliance } from "./icons/compliance";
import { Computer } from "./icons/computer";
import { Connectivity } from "./icons/connectivity";
import { ControlRoom } from "./icons/control-room";
import { Cross } from "./icons/cross";
import { Crypto } from "./icons/crypto";
import { DashboardChart } from "./icons/dashboard-chart";
import { DataPrivacy } from "./icons/data-privacy";
import { Database } from "./icons/database";
import { DateAndTime } from "./icons/date-and-time";
import { Debug } from "./icons/debug";
import { Delete } from "./icons/delete";
import { Detection } from "./icons/detection";
import { LightDetection } from "./icons/detection-types/light-detection";
import { ObjectDetection } from "./icons/detection-types/object-detection";
import { VehicleDetection } from "./icons/detection-types/vehicle-detection";
import { Discard } from "./icons/discard";
import { Dislike } from "./icons/dislike";
import { Dot } from "./icons/dot";
import { DotOut } from "./icons/dot-out";
import { DragAndDrop } from "./icons/drag-and-drop";
import { Drawing } from "./icons/drawing";
import { Duplicate } from "./icons/duplicate";
import { Edit } from "./icons/edit";
import { EHSReport } from "./icons/ehs-report";
import { EHSSuite } from "./icons/ehs-suite";
import { Engineering } from "./icons/engineering";
import { Equal } from "./icons/equal";
import { Ergonomics } from "./icons/ergonomics";
import { ErgonomicsArmActivity } from "./icons/ergonomics/arm-activity";
import { ErgonomicsArmWorking } from "./icons/ergonomics/arm-working";
import { ErgonomicsBodyActivity } from "./icons/ergonomics/body-activity";
import { ErgonomicsLowerArm } from "./icons/ergonomics/lower-arm";
import { ErgonomicsNeckBend } from "./icons/ergonomics/neck-bend";
import { ErgonomicsNeckPosition } from "./icons/ergonomics/neck-position";
import { ErgonomicsNeckTwist } from "./icons/ergonomics/neck-twist";
import { ErgonomicsShoulder } from "./icons/ergonomics/shoulder";
import { ErgonomicsTrunkPosition } from "./icons/ergonomics/trunk-position";
import { ErgonomicsTrunkSideBend } from "./icons/ergonomics/trunk-side-bend";
import { ErgonomicsTrunkSideBend2 } from "./icons/ergonomics/trunk-side-bend-2";
import { ErgonomicsTrunkTwist } from "./icons/ergonomics/trunk-twist";
import { ErgonomicsUpperArm } from "./icons/ergonomics/upper-arm";
import { ErrorCircle } from "./icons/error-circle";
import { Eye } from "./icons/eye";
import { Eye2 } from "./icons/eye2";
import { Facilities } from "./icons/facilities";
import { Fact } from "./icons/fact";
import { File } from "./icons/file";
import { Filter } from "./icons/filter";
import { FilterAdd } from "./icons/filter-add";
import { Fullscreen } from "./icons/fullscreen";
import { Generate } from "./icons/generate";
import { Goal } from "./icons/goal";
import { Gpt } from "./icons/gpt";
import { HazardsChemicalHandling } from "./icons/hazards/chemical-handling";
import { HazardsElectricalHazards } from "./icons/hazards/electrical-hazards";
import { HazardsErgonomicsManualLifting } from "./icons/hazards/ergonomics-manual-lifting";
import { HazardsMachineInteraction } from "./icons/hazards/human-machine-interaction";
import { HazardsSlipsTripsAndFalls } from "./icons/hazards/slips-trips-and-falls";
import { HazardsSpillAndLeakage } from "./icons/hazards/spill-and-leakage";
import { HazardsWorkerMachineInteraction } from "./icons/hazards/worker-machine-interaction";
import { HazardsWorkingAtHeights } from "./icons/hazards/working-at-heights";
import { Heart } from "./icons/heart";
import { History } from "./icons/history";
import { Image } from "./icons/image";
import { Incident } from "./icons/incident";
import { Incident2 } from "./icons/incident-2";
import { Info } from "./icons/info";
import { InfoCircle } from "./icons/info-circle";
import { Inspection } from "./icons/inspection";
import { Inspection2 } from "./icons/inspection-2";
import { It } from "./icons/it";
import { Kpi } from "./icons/kpi";
import { Like } from "./icons/like";
import { LineChart } from "./icons/line-chart";
import { Link } from "./icons/link";
import { ListSearch } from "./icons/list-search";
import { Location } from "./icons/location";
import { Logic } from "./icons/logic";
import { Logout } from "./icons/logout";
import { Mail } from "./icons/mail";
import { Marker } from "./icons/marker";
import { Message } from "./icons/message";
import { Money } from "./icons/money";
import { MoreAction } from "./icons/more-action";
import { Notification } from "./icons/notification";
import { Observation } from "./icons/observation";
import { ObservationOff } from "./icons/observation-off";
import { PageLink } from "./icons/page-link";
import { PageWarning } from "./icons/page-warning";
import { Period } from "./icons/period";
import { Period1 } from "./icons/period-1";
import { Phone } from "./icons/phone";
import { PieChart } from "./icons/pie-chart";
import { PlayVideo } from "./icons/play-video";
import { Print } from "./icons/print";
import { Privacy } from "./icons/privacy";
import { RadioNonselected } from "./icons/radio-nonselected";
import { RadioSelected } from "./icons/radio-selected";
import { Refresh } from "./icons/refresh";
import { Remove } from "./icons/remove";
import { RemoveCircle } from "./icons/remove-circle";
import { Renew } from "./icons/renew";
import { Reopen } from "./icons/reopen";
import { Reports } from "./icons/reports";
import { Roles } from "./icons/roles";
import { Safe } from "./icons/safe";
import { Sample } from "./icons/sample";
import { Score } from "./icons/score";
import { Search } from "./icons/search";
import { Send } from "./icons/send";
import { Severity } from "./icons/severity";
import { Share } from "./icons/share";
import { SmartDevices } from "./icons/smart-devices";
import { SmartPlug } from "./icons/smart-plug";
import { Solution } from "./icons/solution";
import { SolutionsAutomativeParts } from "./icons/solutions/automative-parts";
import { SolutionsChemicalsAndRelatedProducts } from "./icons/solutions/chemicals-and-related-products";
import { SolutionsElectricityOilAndGas } from "./icons/solutions/electricity-oil-and-gas";
import { SolutionsFoodAndBeverage } from "./icons/solutions/food-and-beverage";
import { SolutionsFreightAndLogistics } from "./icons/solutions/freight-and-logistics";
import { SolutionsIndustrialMachinery } from "./icons/solutions/industrial-machinery";
import { SolutionsMining } from "./icons/solutions/mining";
import { SolutionsMotorVehicles } from "./icons/solutions/motor-vehicles";
import { SolutionsPlasticPackagingAndContainer } from "./icons/solutions/plastic-packaging-and-container";
import { SolutionsRetail } from "./icons/solutions/retail";
import { SolutionsTextileAndApparel } from "./icons/solutions/textile-and-apparel";
import { SolutionsTiresAndRubber } from "./icons/solutions/tires-and-rubber";
import { SolutionsWarehouses } from "./icons/solutions/warehouses";
import { SolutionsWiresAndCables } from "./icons/solutions/wires-and-cables";
import { SolveHow } from "./icons/solve-how";
import { Sort } from "./icons/sort";
import { Speaker } from "./icons/speaker";
import { Sphere } from "./icons/sphere";
import { Star } from "./icons/star";
import { Status } from "./icons/status";
import { Submit } from "./icons/submit";
import { Tag } from "./icons/tag";
import { Target } from "./icons/target";
import { Task } from "./icons/task";
import { Task2 } from "./icons/task-2";
import { TaskManagement } from "./icons/task-management";
import { TaskManagement2 } from "./icons/task-management-2";
import { TaskParent } from "./icons/task-parent";
import { TaskSub } from "./icons/task-sub";
import { Template } from "./icons/template";
import { Template2 } from "./icons/template-2";
import { Time } from "./icons/time";
import { Timer } from "./icons/timer";
import { Unlink } from "./icons/unlink";
import { Unsafe } from "./icons/unsafe";
import { UseCase } from "./icons/use-case";
import { UseCase2 } from "./icons/use-case-2";
import { UseCaseCleanPedestrianWays } from "./icons/use-cases/anomaly/clean-pedestrian-ways";
import { UseCaseCleanVehicleRoads } from "./icons/use-cases/anomaly/clean-vehicle-roads";
import { UseCaseLeakageAndSpill } from "./icons/use-cases/anomaly/leakage-and-spill";
import { UseCaseOpenClosedDoor } from "./icons/use-cases/anomaly/open-closed-door";
import { UseCaseUnattendedObject } from "./icons/use-cases/anomaly/unattended-object";
import { UseCaseCraneArea } from "./icons/use-cases/area-controls/crane-area";
import { UseCaseLightControls } from "./icons/use-cases/area-controls/light-controls";
import { UseCaseLineOfFire } from "./icons/use-cases/area-controls/line-of-fire";
import { UseCaseMachineArea } from "./icons/use-cases/area-controls/machine-area";
import { UseCaseMachineManRestricted } from "./icons/use-cases/area-controls/machine-man-restricted";
import { UseCaseMaxWorker } from "./icons/use-cases/area-controls/max-worker";
import { UseCaseMinWorker } from "./icons/use-cases/area-controls/min-worker";
import { UseCaseOccupancyLighting } from "./icons/use-cases/area-controls/occupancy-lighting";
import { UseCasePersonMandatoryArea } from "./icons/use-cases/area-controls/person-mandatory-area";
import { UseCaseProhibitedObject } from "./icons/use-cases/area-controls/prohibited-object";
import { UseCaseSafeLifting } from "./icons/use-cases/area-controls/safe-lifting";
import { UseCaseStaticDelimitation } from "./icons/use-cases/area-controls/static-delimitation";
import { UseCaseTimeLimited } from "./icons/use-cases/area-controls/time-limited";
import { UseCaseClimbing } from "./icons/use-cases/behavior/climbing";
import { UseCaseContactWithElectricity } from "./icons/use-cases/behavior/contact-with-electricity";
import { UseCaseGrouping } from "./icons/use-cases/behavior/grouping";
import { UseCasePedestrianWayViolation } from "./icons/use-cases/behavior/pedestrian-way-violation";
import { UseCaseRestrictedHeight } from "./icons/use-cases/behavior/restricted-height";
import { UseCaseRunning } from "./icons/use-cases/behavior/running";
import { UseCaseStairsBannisterUsage } from "./icons/use-cases/behavior/stairs-bannister-usage";
import { UseCaseFireDetection } from "./icons/use-cases/emergency/fire-detection";
import { UseCaseWorkerDown } from "./icons/use-cases/emergency/worker-down";
import { UseCaseReba } from "./icons/use-cases/ergonomics/reba";
import { UseCaseRula } from "./icons/use-cases/ergonomics/rula";
import { UseCaseExclusionZone } from "./icons/use-cases/exclusion-zone/exclusion-zone";
import { UseCaseFaceMask } from "./icons/use-cases/pandemic/face-mask";
import { UseCaseSocialDistancing } from "./icons/use-cases/pandemic/social-distancing";
import { UseCaseApron } from "./icons/use-cases/ppe/apron";
import { UseCaseFaceShield } from "./icons/use-cases/ppe/face-shield";
import { UseCaseGlasses } from "./icons/use-cases/ppe/glasses";
import { UseCaseGloves } from "./icons/use-cases/ppe/gloves";
import { UseCaseHardHat } from "./icons/use-cases/ppe/hard-hat";
import { UseCaseHearingMuff } from "./icons/use-cases/ppe/hearing-muff";
import { UseCaseReflectiveVest } from "./icons/use-cases/ppe/reflective-vest";
import { UseCaseRespiratoryProtectiveEquipment } from "./icons/use-cases/ppe/respiratory-protective-equipment";
import { UseCaseSleeve } from "./icons/use-cases/ppe/sleeve";
import { UseCaseCrossingYellowLine } from "./icons/use-cases/public-safety/crossing-yellow-line";
import { UseCaseTrainBody } from "./icons/use-cases/public-safety/train-body";
import { UseCaseDynamicDelimitation } from "./icons/use-cases/vehicle/dynamic-delimitation";
import { UseCaseOneWayTraffic } from "./icons/use-cases/vehicle/one-way-traffic";
import { UseCaseSpeedLimit } from "./icons/use-cases/vehicle/speed-limit";
import { UseCaseVehicleOperationZone } from "./icons/use-cases/vehicle/vehicle-operation-zone";
import { UseCaseVehiclePpeCompliance } from "./icons/use-cases/vehicle/vehicle-ppe-compliance";
import { UseCaseVehicleRestrictedArea } from "./icons/use-cases/vehicle/vehicle-restricted-area";
import { User } from "./icons/user";
import { UserAdd } from "./icons/user-add";
import { UserCheck } from "./icons/user-check";
import { Users } from "./icons/users";
import { ValueSelect } from "./icons/value-select";
import { Vision } from "./icons/vision";
import { VisualAnalysis } from "./icons/visual-analysis";
import { VisualAnalysisSelected } from "./icons/visual-analysis-selected";
import { Warning } from "./icons/warning";
import { Webcam } from "./icons/webcam";
import { With } from "./icons/with";
import { Without } from "./icons/without";
import { Workspace } from "./icons/workspace";
import { World } from "./icons/world";
import { ZoomIn } from "./icons/zoom-in";
import { ZoomOut } from "./icons/zoom-out";

export const iconMap = {
  abstract: Abstract,
  accuracy: Accuracy,
  "accuracy-1": Accuracy1,
  action: Action,
  add: Add,
  "add-circle": AddCircle,
  alert: Alert,
  "alert-circle": AlertCircle,
  alerts: Alerts,
  all: All,
  archive: Archive,
  area: Area,
  "area-types/crane": Crane,
  "area-types/door": Door,
  "area-types/elevated": Elevated,
  "area-types/light": Light,
  "area-types/machinery": Machinery,
  "area-types/standing": Standing,
  "area-types/touch": Touch,
  "area-types/vehicle-road": VehicleRoad,
  "arrow-cross": ArrowCross,
  "arrow-downward": ArrowDownward,
  "arrow-left": ArrowLeft,
  "arrow-right": ArrowRight,
  "arrow-upward": ArrowUpward,
  assignee: Assignee,
  attach: Attach,
  audit: Audit,
  book: Book,
  bookmark: Bookmark,
  "bookmark-list": BookmarkList,
  bookmarked: Bookmarked,
  "business-chart": BusinessChart,
  calendar: Calendar,
  calibrate: Calibrate,
  camera: Camera,
  "camera-3d": Camera3d,
  "caret-down": CaretDown,
  "caret-left": CaretLeft,
  "caret-left-double": CaretLeftDouble,
  "caret-right": CaretRight,
  "caret-right-double": CaretRightDouble,
  "caret-up": CaretUp,
  "category/anomaly": CategoryAnomaly,
  "category/anomaly-2": CategoryAnomaly2,
  "category/area-controls": CategoryAreaControls,
  "category/behavior": CategoryBehavior,
  "category/custom": CategoryCustom,
  "category/emergency-alerts": CategoryEmergencyAlerts,
  "category/emergency-alerts-2": CategoryEmergencyAlerts2,
  "category/ergonomics": CategoryErgonomics,
  "category/pandemic": CategoryPandemic,
  "category/pandemic-2": CategoryPandemic2,
  "category/ppe": CategoryPpe,
  "category/public-safety": CategoryPublicSafety,
  "category/vehicle-control": CategoryVehicleControl,
  "category/vehicle-control-2": CategoryVehicleControl2,
  "chart-alert-task": ChartAlertTask,
  "chart-compliance-task": ChartComplianceTask,
  check: Check,
  "check-circle": CheckCircle,
  "checkbox-indeterminate": CheckboxIndeterminate,
  "checkbox-nonselected": CheckboxNonselected,
  "checkbox-selected": CheckboxSelected,
  cloud: Cloud,
  code: Code,
  coffee: Coffee,
  cog: Cog,
  compliance: Compliance,
  computer: Computer,
  connectivity: Connectivity,
  "control-room": ControlRoom,
  cross: Cross,
  crypto: Crypto,
  "dashboard-chart": DashboardChart,
  "data-privacy": DataPrivacy,
  database: Database,
  "date-and-time": DateAndTime,
  debug: Debug,
  delete: Delete,
  detection: Detection,
  "detection-types/light": LightDetection,
  "detection-types/object": ObjectDetection,
  "detection-types/person": Detection,
  "detection-types/vehicle": VehicleDetection,
  discard: Discard,
  dislike: Dislike,
  dot: Dot,
  "dot-out": DotOut,
  "drag-and-drop": DragAndDrop,
  drawing: Drawing,
  duplicate: Duplicate,
  edit: Edit,
  "ehs-report": EHSReport,
  "ehs-suite": EHSSuite,
  "empty-cell": EmptyCell,
  engineering: Engineering,
  equal: Equal,
  ergonomics: Ergonomics,
  "ergonomics/arm-activity": ErgonomicsArmActivity,
  "ergonomics/arm-working": ErgonomicsArmWorking,
  "ergonomics/body-activity": ErgonomicsBodyActivity,
  "ergonomics/lower-arm": ErgonomicsLowerArm,
  "ergonomics/neck-bend": ErgonomicsNeckBend,
  "ergonomics/neck-position": ErgonomicsNeckPosition,
  "ergonomics/neck-twist": ErgonomicsNeckTwist,
  "ergonomics/shoulder": ErgonomicsShoulder,
  "ergonomics/trunk-position": ErgonomicsTrunkPosition,
  "ergonomics/trunk-side-bend": ErgonomicsTrunkSideBend,
  "ergonomics/trunk-side-bend-2": ErgonomicsTrunkSideBend2,
  "ergonomics/trunk-twist": ErgonomicsTrunkTwist,
  "ergonomics/upper-arm": ErgonomicsUpperArm,
  "error-circle": ErrorCircle,
  eye: Eye,
  "eye-2": Eye2,
  facilities: Facilities,
  fact: Fact,
  file: File,
  filter: Filter,
  "filter-add": FilterAdd,
  fullscreen: Fullscreen,
  generate: Generate,
  goal: Goal,
  gpt: Gpt,
  "hazards/chemical-handling": HazardsChemicalHandling,
  "hazards/electrical-hazards": HazardsElectricalHazards,
  "hazards/ergonomics-manual-lifting": HazardsErgonomicsManualLifting,
  "hazards/machine-interaction": HazardsMachineInteraction,
  "hazards/slips-trips-and-falls": HazardsSlipsTripsAndFalls,
  "hazards/spill-and-leakage": HazardsSpillAndLeakage,
  "hazards/worker-machine-interaction": HazardsWorkerMachineInteraction,
  "hazards/working-at-heights": HazardsWorkingAtHeights,
  heart: Heart,
  history: History,
  image: Image,
  incident: Incident,
  "incident-2": Incident2,
  info: Info,
  "info-circle": InfoCircle,
  inspection: Inspection,
  "inspection-2": Inspection2,
  it: It,
  kpi: Kpi,
  "leading-indicators": LeadingIndicators,
  like: Like,
  "line-chart": LineChart,
  link: Link,
  "list-search": ListSearch,
  location: Location,
  logic: Logic,
  logout: Logout,
  mail: Mail,
  marker: Marker,
  message: Message,
  money: Money,
  "more-action": MoreAction,
  notification: Notification,
  observation: Observation,
  "observation-off": ObservationOff,
  "observation-ratio": ObservationRatio,
  "page-link": PageLink,
  "page-warning": PageWarning,
  period: Period,
  "period-1": Period1,
  phone: Phone,
  "pie-chart": PieChart,
  "play-video": PlayVideo,
  print: Print,
  privacy: Privacy,
  "radio-nonselected": RadioNonselected,
  "radio-selected": RadioSelected,
  refresh: Refresh,
  remove: Remove,
  "remove-circle": RemoveCircle,
  renew: Renew,
  reopen: Reopen,
  reports: Reports,
  roles: Roles,
  safe: Safe,
  sample: Sample,
  score: Score,
  search: Search,
  send: Send,
  severity: Severity,
  share: Share,
  "smart-devices": SmartDevices,
  "smart-plug": SmartPlug,
  solution: Solution,
  "solutions/automative-parts": SolutionsAutomativeParts,
  "solutions/chemicals-and-related-products":
    SolutionsChemicalsAndRelatedProducts,
  "solutions/electricity-oil-and-gas": SolutionsElectricityOilAndGas,
  "solutions/food-and-beverage": SolutionsFoodAndBeverage,
  "solutions/freight-and-logistics": SolutionsFreightAndLogistics,
  "solutions/industrial-machinery": SolutionsIndustrialMachinery,
  "solutions/mining": SolutionsMining,
  "solutions/motor-vehicles": SolutionsMotorVehicles,
  "solutions/plastic-packaging-and-container":
    SolutionsPlasticPackagingAndContainer,
  "solutions/retail": SolutionsRetail,
  "solutions/textile-and-apparel": SolutionsTextileAndApparel,
  "solutions/tires-and-rubber": SolutionsTiresAndRubber,
  "solutions/warehouses": SolutionsWarehouses,
  "solutions/wires-and-cables": SolutionsWiresAndCables,
  "solve-how": SolveHow,
  sort: Sort,
  speaker: Speaker,
  sphere: Sphere,
  star: Star,
  status: Status,
  submit: Submit,
  tag: Tag,
  target: Target,
  task: Task,
  "task-2": Task2,
  "task-management": TaskManagement,
  "task-management-2": TaskManagement2,
  "task-parent": TaskParent,
  "task-sub": TaskSub,
  template: Template,
  "template-2": Template2,
  time: Time,
  timer: Timer,
  tracker: Tracker,
  unlink: Unlink,
  unsafe: Unsafe,
  update: Update,
  "use-case": UseCase,
  "use-case/apron": UseCaseApron,
  "use-case/clean-pedestrian-ways": UseCaseCleanPedestrianWays,
  "use-case/clean-vehicle-roads": UseCaseCleanVehicleRoads,
  "use-case/climbing": UseCaseClimbing,
  "use-case/contact-with-electricity": UseCaseContactWithElectricity,
  "use-case/crane-area": UseCaseCraneArea,
  "use-case/crossing-yellow-line": UseCaseCrossingYellowLine,
  "use-case/dynamic-delimitation": UseCaseDynamicDelimitation,
  "use-case/exclusion-zone": UseCaseExclusionZone,
  "use-case/face-mask": UseCaseFaceMask,
  "use-case/face-shield": UseCaseFaceShield,
  "use-case/fire-detection": UseCaseFireDetection,
  "use-case/glasses": UseCaseGlasses,
  "use-case/gloves": UseCaseGloves,
  "use-case/grouping": UseCaseGrouping,
  "use-case/hard-hat": UseCaseHardHat,
  "use-case/hearing-muff": UseCaseHearingMuff,
  "use-case/hot-object-detection": UseCaseHotObjectDetection,
  "use-case/inactive": UseCaseInactive,
  "use-case/leakage-and-spill": UseCaseLeakageAndSpill,
  "use-case/light-controls": UseCaseLightControls,
  "use-case/line-of-fire": UseCaseLineOfFire,
  "use-case/machine-area": UseCaseMachineArea,
  "use-case/machine-man-restricted": UseCaseMachineManRestricted,
  "use-case/max-worker": UseCaseMaxWorker,
  "use-case/min-worker": UseCaseMinWorker,
  "use-case/occupancy-lighting": UseCaseOccupancyLighting,
  "use-case/one-way-traffic": UseCaseOneWayTraffic,
  "use-case/open-closed-door": UseCaseOpenClosedDoor,
  "use-case/pedestrian-way-violation": UseCasePedestrianWayViolation,
  "use-case/person-mandatory-area": UseCasePersonMandatoryArea,
  "use-case/prohibited-object": UseCaseProhibitedObject,
  "use-case/protective-clothing": UseCaseProtectiveClothing,
  "use-case/reba": UseCaseReba,
  "use-case/reflective-vest": UseCaseReflectiveVest,
  "use-case/respiratory-protective-equipment":
    UseCaseRespiratoryProtectiveEquipment,
  "use-case/restricted-height": UseCaseRestrictedHeight,
  "use-case/rula": UseCaseRula,
  "use-case/running": UseCaseRunning,
  "use-case/safe-lifting": UseCaseSafeLifting,
  "use-case/sleeve": UseCaseSleeve,
  "use-case/social-distancing": UseCaseSocialDistancing,
  "use-case/speed-limit": UseCaseSpeedLimit,
  "use-case/stairs-bannister-usage": UseCaseStairsBannisterUsage,
  "use-case/static-delimitation": UseCaseStaticDelimitation,
  "use-case/time-limited": UseCaseTimeLimited,
  "use-case/train-body": UseCaseTrainBody,
  "use-case/unattended-object": UseCaseUnattendedObject,
  "use-case/vehicle-operation-zone": UseCaseVehicleOperationZone,
  "use-case/vehicle-pedestrian-interaction":
    UseCaseVehiclePedestrianInteraction,
  "use-case/vehicle-ppe-compliance": UseCaseVehiclePpeCompliance,
  "use-case/vehicle-restricted-area": UseCaseVehicleRestrictedArea,
  "use-case/vehicle-vehicle-interaction": UseCaseVehicleVehicleInteraction,
  "use-case/worker-down": UseCaseWorkerDown,
  "use-case-2": UseCase2,
  user: User,
  "user-add": UserAdd,
  "user-check": UserCheck,
  users: Users,
  "value-select": ValueSelect,
  vision: Vision,
  "visual-analysis": VisualAnalysis,
  "visual-analysis-selected": VisualAnalysisSelected,
  warning: Warning,
  webcam: Webcam,
  with: With,
  without: Without,
  workspace: Workspace,
  world: World,
  "zoom-in": ZoomIn,
  "zoom-out": ZoomOut,
} as const;
