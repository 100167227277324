import { LogoIcon } from "./logo-icon";
import { LogoText } from "./logo-text";
import type { LogoProps } from "./logo.types";

export const Logo = ({
  text = false,
  variant = "dark",
  ...svgProps
}: LogoProps) =>
  text ? (
    <LogoText {...svgProps} variant={variant} />
  ) : (
    <LogoIcon {...svgProps} variant={variant} />
  );
