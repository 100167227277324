import Cookies from "js-cookie";
import { getAppModeFromUrl } from "~/utils/app-env";
import { type AuthCookieConfig, AuthTenant } from "./auth.types";

const cookieNamePrefix = "auth-tenant";

const getAuthCookieApi = ({ appUrl }: AuthCookieConfig) => {
  const env = getAppModeFromUrl(appUrl);
  return Cookies.withAttributes({
    domain: ".intenseye.com",
    expires: 7,
    path: "",
    sameSite: "lax",
    secure: env !== "local",
  });
};

const getAuthCookieName = ({ appUrl }: AuthCookieConfig) => {
  const env = getAppModeFromUrl(appUrl);

  if (env === "production") {
    return cookieNamePrefix;
  }

  return `${cookieNamePrefix}-${env}`;
};

export const clearAuthCookie = (config?: AuthCookieConfig) => {
  if (config) {
    // Clear specific app cookie
    getAuthCookieApi(config).remove(getAuthCookieName(config));
  } else {
    // Clear all auth cookies instead
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const cookieName = cookie.trim().split("=")[0];
      if (cookieName.startsWith(cookieNamePrefix)) {
        document.cookie = `${cookieName}=;expires=0`;
      }
    }
  }
};

export const getAuthCookie = (config: AuthCookieConfig) => {
  const value = getAuthCookieApi(config).get(getAuthCookieName(config));

  if (value && (Object.values(AuthTenant) as string[]).includes(value)) {
    return value as AuthTenant;
  }
};

export const setAuthCookie = (config: AuthCookieConfig, tenant: AuthTenant) => {
  getAuthCookieApi(config).set(getAuthCookieName(config), tenant);
};
