import type { IconChildrenProps } from "../icon.types";

export const CaretRight = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M7.74142 13.2333L10.9747 9.99997L7.74142 6.76664C7.53152 6.55674 7.44955 6.25082 7.52638 5.9641C7.6032 5.67738 7.82716 5.45342 8.11388 5.3766C8.4006 5.29977 8.70652 5.38174 8.91642 5.59164L12.7414 9.41664C13.0664 9.74164 13.0664 10.2666 12.7414 10.5916L8.91642 14.4166C8.76071 14.5727 8.54934 14.6603 8.32892 14.6603C8.10849 14.6603 7.89712 14.5727 7.74142 14.4166C7.42475 14.0916 7.41642 13.5583 7.74142 13.2333Z"
    fill={color}
    fillRule="evenodd"
  />
);
