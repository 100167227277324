import type { IconChildrenProps } from "../icon.types";

export const Reopen = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M5.8868 4.32318C5.67917 3.98258 5.78697 3.53815 6.12758 3.33052C8.47276 1.90096 11.4116 1.80847 13.849 3.15952C16.9189 4.86122 18.3591 8.36635 17.594 11.638L19.0082 12.0435C19.1678 12.0917 19.2833 12.2304 19.3019 12.3961C19.3204 12.5617 19.2384 12.7225 19.0934 12.8048L16.2176 14.3989C16.0114 14.5131 15.7631 14.4419 15.6488 14.2358L14.0547 11.36C13.8696 11.0382 14.1699 10.6561 14.5304 10.7595L15.9642 11.1706L16.203 11.2391C16.7882 8.6 15.6174 5.79139 13.1486 4.42295C11.1708 3.32664 8.78615 3.40169 6.87946 4.56397C6.53885 4.77159 6.09442 4.66379 5.8868 4.32318ZM3.86754 8.68L4.03939 8.72928L5.47327 9.14044C5.83374 9.2438 6.13401 8.86177 5.94888 8.53994L4.3548 5.66415C4.24053 5.458 3.99221 5.38679 3.78606 5.50106L0.910267 7.09514C0.763964 7.17815 0.681929 7.341 0.702295 7.50798C0.722662 7.67495 0.841434 7.81331 1.0034 7.85872L2.43728 8.26988L2.47504 8.28071C2.09706 10.0404 2.33469 11.9137 3.21746 13.574C5.23125 17.3614 9.91639 18.8107 13.683 16.808C14.0352 16.6207 14.1689 16.1834 13.9817 15.8312C13.7944 15.479 13.3571 15.3453 13.0048 15.5325C9.94453 17.1597 6.13316 15.9807 4.49291 12.8958C3.78935 11.5726 3.5885 10.0847 3.86754 8.68ZM10.1852 11C9.72683 11 9.35183 10.625 9.35183 10.1666V6.83328C9.35183 6.37495 9.72683 5.99995 10.1852 5.99995C10.6435 5.99995 11.0185 6.37495 11.0185 6.83328V10.1666C11.0185 10.625 10.6435 11 10.1852 11ZM10.1852 14.5833C9.58516 14.5833 9.10183 14.1 9.10183 13.5C9.10183 12.9 9.58516 12.4166 10.1852 12.4166C10.7852 12.4166 11.2685 12.9 11.2685 13.5C11.2685 14.1 10.7852 14.5833 10.1852 14.5833Z"
    fill={color}
    fillRule="evenodd"
  />
);
