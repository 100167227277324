export const EmptyCell = () => {
  return (
    <>
      <defs>
        <pattern
          id="backslash-pattern"
          patternUnits="userSpaceOnUse"
          width="15"
          height="15"
        >
          <path
            d="M 0 15 L 15 0"
            stroke="#C2C2CD"
            strokeLinecap="round"
            strokeDasharray="2 2"
          />
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill="url(#backslash-pattern)" />
    </>
  );
};
