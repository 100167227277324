import { clsx } from "clsx";
import { forwardRef } from "react";
import { Flexbox } from "~/components/flexbox";
import { useFocus } from "~/hooks/use-focus";
import { useMouseEvents } from "~/hooks/use-mouse-events";
import { Icon, type IconName } from "../icon";
import { Spinner } from "../spinner";
// eslint-disable-next-line import/no-deprecated
import type { ButtonDeprecatedProps } from "./button-deprecated.types";
import css from "./button-deprecated.module.css";

// !Only exported for specific components. Not to be used elsewhere!
/**
 * @deprecated Use Button instead.
 */
export const DEPRECATED_BUTTON = forwardRef<
  HTMLButtonElement,
  // eslint-disable-next-line import/no-deprecated, deprecation/deprecation
  ButtonDeprecatedProps
>(
  (
    {
      className,
      classNames,
      disabled = false,
      error = false,
      focused = false,
      fullWidth = false,
      leftIcon,
      leftIconProps,
      loading = false,
      noStyles = false,
      onBlur,
      onFocus,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseUp,
      rightIcon,
      rightIconProps,
      shape = "contained",
      style,
      type = "button",
      variant = "primary",
      ...other
    },
    ref,
  ) => {
    const { focus, onInternalFocus, onInternalBlur } =
      useFocus<HTMLButtonElement>(
        focused,
        onFocus,
        onBlur,
        disabled || loading,
        focused,
      );

    const {
      active,
      hover,
      onInternalMouseDown,
      onInternalMouseEnter,
      onInternalMouseLeave,
      onInternalMouseUp,
    } = useMouseEvents<HTMLButtonElement>(
      false,
      false,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseUp,
    );

    let rootSpinnerClassName = "rightSpinner";

    if (
      shape === "icon" ||
      shape === "icon-outlined" ||
      (!leftIcon && !rightIcon)
    ) {
      rootSpinnerClassName = "coverSpinner";
    } else if (leftIcon && !rightIcon) {
      rootSpinnerClassName = "leftSpinner";
    }

    return (
      <div
        className={clsx(
          css.root,
          className,
          classNames?.root,
          !noStyles && css[shape],
          !noStyles && css[variant],
          !noStyles && css[rootSpinnerClassName],
          disabled
            ? clsx(!noStyles && css.disabled, classNames?.disabled)
            : undefined,
          !noStyles && fullWidth ? css.fullWidth : undefined,
          loading
            ? clsx(!noStyles && css.loading, classNames?.loading)
            : undefined,
          !disabled && active
            ? clsx(!noStyles && css.active, classNames?.active)
            : undefined,
          (!disabled && focus) || focused
            ? clsx(!noStyles && css.focused, classNames?.focused)
            : undefined,
          !disabled && hover
            ? clsx(!noStyles && css.hovered, classNames?.hovered)
            : undefined,
          !noStyles && !disabled && error ? css.error : undefined,
          !noStyles && !Array.isArray(leftIcon) && leftIcon && css.hasLeftIcon,
          !noStyles && Array.isArray(leftIcon) && css.hasLeftIcons,
          !noStyles && rightIcon && css.hasRightIcon,
        )}
        style={style}
      >
        {leftIcon ? (
          <div
            className={clsx(!noStyles && css.leftIcon, classNames?.leftIcon)}
          >
            {Array.isArray(leftIcon) && leftIcon?.length > 0 ? (
              <Flexbox direction={"row"} alignItems="center" gap="4px">
                {leftIcon?.map((name: IconName, index) => (
                  <Icon
                    data-testid="button-left-icon"
                    key={name}
                    inheritColor
                    name={name}
                    noAccent={shape === "contained" || shape === "rounded"}
                    {...(Array.isArray(leftIconProps)
                      ? leftIconProps[index]
                      : leftIconProps)}
                  />
                ))}
              </Flexbox>
            ) : (
              <Icon
                data-testid="button-left-icon"
                inheritColor
                name={leftIcon as IconName}
                noAccent={shape === "contained" || shape === "rounded"}
                {...(!Array.isArray(leftIconProps) ? leftIconProps : undefined)}
              />
            )}
          </div>
        ) : null}
        <button
          data-testid="button"
          ref={ref}
          className={clsx(css.button, classNames?.button)}
          {...other}
          disabled={disabled || loading}
          onFocus={onInternalFocus}
          onBlur={onInternalBlur}
          onMouseDown={onInternalMouseDown}
          onMouseEnter={onInternalMouseEnter}
          onMouseLeave={onInternalMouseLeave}
          onMouseUp={onInternalMouseUp}
          type={type}
        />
        {rightIcon ? (
          <div
            className={clsx(!noStyles && css.rightIcon, classNames?.rightIcon)}
          >
            <Icon
              data-testid="button-right-icon"
              inheritColor
              name={rightIcon}
              noAccent={shape === "contained" || shape === "rounded"}
              {...rightIconProps}
            />
          </div>
        ) : null}
        {loading ? (
          <div className={clsx(!noStyles && css.spinner, classNames?.spinner)}>
            <Spinner />
          </div>
        ) : null}
      </div>
    );
  },
);

// eslint-disable-next-line deprecation/deprecation, import/no-deprecated
DEPRECATED_BUTTON.displayName = "DEPRECATED_BUTTON";
