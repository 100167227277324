import type { IconChildrenProps } from "../icon.types";

export const ErrorCircle = ({
  accentColor,
  oppositeColor,
}: IconChildrenProps) => (
  <>
    <rect x="2" y="2" width="16" height="16" rx="8" fill={accentColor} />
    <path
      d="M13.813 6.18667C14.137 6.51068 14.137 7.036 13.813 7.36L11.173 10L13.813 12.64C14.137 12.964 14.137 13.4893 13.813 13.8133C13.489 14.1373 12.9637 14.1373 12.6397 13.8133L9.9997 11.1733L7.3597 13.8133C7.03569 14.1373 6.51037 14.1373 6.18636 13.8133C5.86236 13.4893 5.86236 12.964 6.18636 12.64L8.82636 10L6.18636 7.36C5.86236 7.036 5.86236 6.51068 6.18636 6.18667C6.51037 5.86266 7.03569 5.86266 7.3597 6.18667L9.9997 8.82667L12.6397 6.18667C12.9637 5.86266 13.489 5.86266 13.813 6.18667Z"
      fill={oppositeColor}
    />
  </>
);
