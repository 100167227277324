import { type RefObject, useLayoutEffect, useState } from "react";
import { useResizeObserver } from "../use-resize-observer";

type Dimensions = {
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
  readonly top: number;
  readonly right: number;
  readonly bottom: number;
  readonly left: number;
};

export const useDimensions = <T extends HTMLElement>(
  target: RefObject<T> | T | null,
) => {
  const [dimensions, setDimensions] = useState<Dimensions | undefined>(
    undefined,
  );

  useLayoutEffect(() => {
    const targetEl = target && "current" in target ? target.current : target;
    if (targetEl) {
      const { toJSON, ...rect } = targetEl.getBoundingClientRect();
      setDimensions(rect);
    }
  }, [target]);

  useResizeObserver(target, (entry) => setDimensions(entry.contentRect));

  return dimensions;
};
