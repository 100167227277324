import type { IconChildrenProps } from "../icon.types";

export const ArrowRight = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M4.16732 10.8333H13.4757L9.40898 14.9C9.08398 15.225 9.08398 15.7583 9.40898 16.0833C9.73398 16.4083 10.259 16.4083 10.584 16.0833L16.0757 10.5916C16.2317 10.4359 16.3194 10.2246 16.3194 10.0041C16.3194 9.78372 16.2317 9.57235 16.0757 9.41665L10.5923 3.91665C10.3824 3.70675 10.0765 3.62478 9.78978 3.70161C9.50306 3.77843 9.2791 4.00239 9.20228 4.28911C9.12545 4.57583 9.20742 4.88175 9.41732 5.09165L13.4757 9.16665H4.16732C3.70898 9.16665 3.33398 9.54165 3.33398 9.99998C3.33398 10.4583 3.70898 10.8333 4.16732 10.8333Z"
    fill={color}
    fillRule="evenodd"
  />
);
