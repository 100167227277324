import * as yup from "yup";

type AtLeastOneArgs<T> = {
  predicate: (item: T) => boolean;
  message?: string;
};

declare module "yup" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface ArraySchema<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    TIn extends any[] | null | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    TContext,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    TDefault = undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    TFlags extends yup.Flags = "",
  > extends yup.Schema<TIn, TContext, TDefault, TFlags> {
    atLeastOne<ItemType>(param: AtLeastOneArgs<ItemType>): this;
  }
}

/*
 * If there is less than 1 element after filtering,
 * we know atLeastOne must be false.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
yup.addMethod(yup.array, "atLeastOne", function (args: AtLeastOneArgs<any>) {
  const { message, predicate } = args;
  return this.test(
    "atLeastOne",
    message ?? "",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (list: any[] | null | undefined) =>
      Boolean(list && list.filter(predicate).length > 0),
  );
});

export { yup as default };
