import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "~/components/spinner";
import { getAuth0, getAuth0CallbackState } from "~/features/auth/auth.auth0";
import { clearAuthCookie } from "~/features/auth/auth.cookie";
import { clearAuthStorage, getAuthStorage } from "~/features/auth/auth.storage";
import { redirectToApp } from "~/features/auth/auth.utils";
import type { CallbackRouteState } from "./callback-route.types";

export const CallbackRoute = () => {
  const navigate = useNavigate();

  const navigateWithState = useCallback(
    (to: string, state?: Omit<CallbackRouteState, "fromCallback">) => {
      navigate(to, {
        replace: true,
        state: {
          ...state,
          fromCallback: true,
        },
      });
    },
    [navigate],
  );

  useEffect(() => {
    // Retrieve storage id from url hash
    const id = getAuth0CallbackState();

    if (id) {
      // Retrieve storage data from localStorage
      const data = getAuthStorage(id);
      if (data) {
        // At this point we're sure we made this request to auth0
        //  and we got back the data we sent,
        //  now we should check if the user is authenticated with auth0
        getAuth0(data.tenant, data.appName).parseHash((_, result) => {
          if (result?.accessToken) {
            // User is authenticated, we can send them to the app
            clearAuthStorage();
            redirectToApp({
              appName: data.appName,
              appUrl: data.appUrl,
              redirect: data.redirect,
              tenant: data.tenant,
            });
          } else {
            // User is not authenticated
            clearAuthCookie({
              appName: data.appName,
              appUrl: data.appUrl,
            });

            if (data.ssoLogin) {
              // SSO login failed, redirect to login with error
              navigateWithState("/login", {
                appName: data.appName,
                appUrl: data.appUrl,
                redirect: data.redirect,
                ssoLoginError: true,
              });
            } else if (data.emailPasswordLogin) {
              // Email & password login failed, redirect to login with error
              navigateWithState("/login", {
                appName: data.appName,
                appUrl: data.appUrl,
                emailPasswordLoginError: true,
                redirect: data.redirect,
              });
            } else {
              // Redirect to internal route
              navigateWithState(data.internalRedirect || "/login", {
                appName: data.appName,
                appUrl: data.appUrl,
                redirect: data.redirect,
              });
            }
          }
        });
      } else {
        // We failed to parse data from the request
        //  redirect to /login
        clearAuthCookie();
        navigateWithState("/login");
      }
    } else {
      // Auth0 callback didn't come from us
      //  redirect to /login
      clearAuthCookie();
      navigateWithState("/login");
    }
  }, [navigateWithState]);

  return <Spinner color="secondary" cover size="large" />;
};
