import type { IconChildrenProps } from "../icon.types";

export const Severity = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M11.7131 3.76891C11.1805 2.88195 10.046 2.54113 9.10509 3.01158C8.71804 3.20511 8.40419 3.51895 8.21066 3.90601L3.01237 14.3025L2.94785 14.445C2.55786 15.4033 2.96588 16.5153 3.90679 16.9858C4.1845 17.1246 4.49073 17.1969 4.80122 17.1969H15.1977L15.347 17.1914C16.3818 17.1151 17.1977 16.2513 17.1977 15.1969C17.1977 14.8864 17.1254 14.5802 16.9866 14.3025L11.7884 3.90602L11.7131 3.76891ZM9.5523 3.90601C10.0463 3.65902 10.647 3.85925 10.8939 4.35323L16.0921 14.7497L16.1379 14.8563C16.1774 14.9653 16.1977 15.0805 16.1977 15.1969C16.1977 15.7492 15.75 16.1969 15.1977 16.1969H4.80122L4.68537 16.1902C4.57031 16.1768 4.45814 16.1434 4.354 16.0914C3.86002 15.8444 3.6598 15.2437 3.90679 14.7497L9.10509 4.35322L9.17018 4.24169C9.26609 4.09933 9.39748 3.98342 9.5523 3.90601ZM9.08735 6.95073C9.04011 6.41346 9.46351 5.95118 10.0029 5.95118C10.5397 5.95118 10.9623 6.4094 10.9189 6.94451L10.5752 11.1825C10.5516 11.4731 10.3089 11.6969 10.0173 11.6969C9.72725 11.6969 9.48525 11.4753 9.45983 11.1863L9.08735 6.95073ZM9.99953 15C10.5518 15 10.9995 14.5523 10.9995 14C10.9995 13.4477 10.5518 13 9.99953 13C9.44724 13 8.99953 13.4477 8.99953 14C8.99953 14.5523 9.44724 15 9.99953 15Z"
    fill={color}
    fillRule="evenodd"
  />
);
