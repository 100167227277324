import type { IconChildrenProps } from "../icon.types";

export const Incident2 = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      d="M11.6503 3.33855C11.6792 3.33855 11.7072 3.33855 11.7342 3.33855C12.4511 3.33855 12.9806 3.82361 13.0485 4.52548H13.2657C14.6155 4.52548 15.2969 5.22464 15.2969 6.57899V14.3173C15.2969 15.6716 14.6155 16.3596 13.2657 16.3596H6.02827C4.68507 16.3596 4.00825 15.6716 3.99707 14.3173V6.57899C3.99707 5.22464 4.68507 4.52548 6.02827 4.52548H6.25197C6.31986 3.82361 6.8494 3.33855 7.56636 3.33855C7.59803 3.33855 7.62992 3.33855 7.6618 3.33855C7.9515 2.54501 8.76792 2 9.65586 2C10.5537 2 11.3624 2.54501 11.6503 3.33855ZM13.0529 5.46358C13.0173 6.20562 12.4761 6.72507 11.7342 6.72507H7.57094C6.81826 6.72507 6.28706 6.20562 6.24801 5.46358H6.07955C5.32475 5.46358 4.93519 5.8643 4.93519 6.59673V14.2996C4.93519 15.032 5.32475 15.4327 6.07955 15.4327H13.2144C13.9692 15.4327 14.3588 15.032 14.3588 14.2996V6.59673C14.3588 5.8643 13.9692 5.46358 13.2144 5.46358H13.0529ZM10.1426 11.7496C10.1426 11.9567 9.97561 12.1236 9.76858 12.1236H6.59939C6.39888 12.1236 6.23192 11.9567 6.22536 11.7496C6.22536 11.5603 6.39232 11.3933 6.59939 11.3933H9.76858C9.97561 11.3933 10.1426 11.5425 10.1426 11.7496ZM13.0732 9.40939C13.0732 9.61641 12.9063 9.78342 12.6992 9.78342H6.59939C6.39888 9.78342 6.23192 9.61641 6.22536 9.40939C6.22536 9.20231 6.39232 9.03536 6.59939 9.03536H12.6992C12.9063 9.03536 13.0732 9.20231 13.0732 9.40939ZM8.25676 4.07199H7.57094C7.20423 4.07199 6.9821 4.29217 6.9821 4.68125V5.38236C6.9821 5.77144 7.19767 5.99162 7.57094 5.99162H11.7342C12.1074 5.99162 12.3342 5.76488 12.3342 5.38236V4.68125C12.3342 4.29217 12.1074 4.07199 11.7342 4.07199H11.055C11.0214 3.33273 10.4063 2.73344 9.65586 2.73344C8.91193 2.73344 8.29026 3.32617 8.25676 4.07199ZM10.2479 4.10553C10.2544 4.43142 9.98175 4.70408 9.64929 4.70408C9.31683 4.70408 9.05072 4.43142 9.05072 4.10553C9.05072 3.77309 9.3234 3.50043 9.64929 3.50043C9.98175 3.50043 10.2479 3.77309 10.2479 4.10553Z"
      fill={color}
    />
    <path
      d="M14.1348 14.4595C14.3287 14.4595 14.4779 14.3681 14.5983 14.1854L17.8214 9.14311C17.8839 9.04051 17.9575 8.89785 17.9575 8.77759C17.9575 8.52118 17.7346 8.34766 17.4829 8.34766C17.3336 8.34766 17.1844 8.42785 17.0818 8.61058L14.1058 13.3348L12.6286 11.4777C12.4971 11.2949 12.3544 11.2436 12.2052 11.2436C11.96 11.2436 11.7529 11.4553 11.7529 11.7071C11.7529 11.8274 11.8042 11.9654 11.891 12.0745L13.6489 14.1965C13.7916 14.3793 13.9343 14.4595 14.1348 14.4595Z"
      fill={accentColor}
    />
  </>
);
