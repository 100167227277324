import type { IconChildrenProps } from "../../icon.types";

export const Door = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M14.7932 6.90233C15.1385 6.90233 15.421 6.61977 15.421 6.27442V3.4907C15.421 2.11977 14.3014 1 12.9305 1H5.49048C4.11967 1 3 2.11977 3 3.4907V16.5093C3 17.8802 4.11967 19 5.49048 19H12.9305C14.3014 19 15.421 17.8802 15.421 16.5093V10C15.421 9.65465 15.1385 9.37209 14.7932 9.37209C14.4479 9.37209 14.1653 9.65465 14.1653 10V16.5093C14.1653 17.1895 13.6107 17.7442 12.9305 17.7442H5.49048C4.81031 17.7442 4.25571 17.1895 4.25571 16.5093V3.4907C4.25571 2.81047 4.81031 2.25581 5.49048 2.25581H12.9305C13.6107 2.25581 14.1653 2.81047 14.1653 3.4907V6.28488C14.1653 6.63023 14.4479 6.91279 14.7932 6.91279V6.90233Z"
      fill={color}
    />
    <path
      d="M5.78348 14.6465C5.78348 14.9919 6.06602 15.2744 6.41134 15.2744C6.75665 15.2744 7.03919 14.9919 7.03919 14.6465C7.03919 14.3012 6.75665 14.0186 6.41134 14.0186C6.06602 14.0186 5.78348 14.3012 5.78348 14.6465Z"
      fill={color}
    />
    <path
      d="M8.57743 14.6465C8.57743 14.9919 8.85996 15.2744 9.20528 15.2744C9.5506 15.2744 9.83313 14.9919 9.83313 14.6465C9.83313 14.3012 9.5506 14.0186 9.20528 14.0186C8.85996 14.0186 8.57743 14.3012 8.57743 14.6465Z"
      fill={color}
    />
    <path
      d="M11.9992 15.2744C12.346 15.2744 12.6271 14.9933 12.6271 14.6465C12.6271 14.2997 12.346 14.0186 11.9992 14.0186C11.6525 14.0186 11.3714 14.2997 11.3714 14.6465C11.3714 14.9933 11.6525 15.2744 11.9992 15.2744Z"
      fill={color}
    />
    <path
      d="M10.92 10.1659C10.3208 10.1659 9.75863 9.90645 9.37562 9.44928C8.99262 8.9921 8.83201 8.38666 8.93085 7.79357C9.07293 6.97807 9.74627 6.31084 10.5617 6.16875C11.198 6.05754 11.8281 6.24906 12.2914 6.6877H16.9554C17.462 6.6877 17.8759 7.10163 17.8759 7.60823V8.70792C17.8759 9.21451 17.462 9.62844 16.9554 9.62844H12.2914C11.9208 9.98059 11.4266 10.1783 10.9138 10.1783L10.92 10.1659ZM10.92 6.87304C10.8459 6.87304 10.7656 6.87922 10.6914 6.89158C10.1787 6.98425 9.75245 7.40435 9.66597 7.91713C9.59801 8.30017 9.69685 8.67703 9.94395 8.97357C10.4196 9.54195 11.3586 9.56666 11.8652 9.00446C11.9331 8.92415 12.0381 8.8809 12.1431 8.8809H16.9616C17.0604 8.8809 17.1407 8.80059 17.1407 8.70174V7.60205C17.1407 7.5032 17.0604 7.42289 16.9616 7.42289H12.1431C12.0381 7.42289 11.9393 7.37964 11.8652 7.29933C11.6181 7.02132 11.2783 6.87304 10.92 6.87304Z"
      fill={color}
    />
  </>
);
