import { clsx } from "clsx";
import type { SpinnerProps } from "./spinner.types";
import css from "./spinner.module.css";

const SpinnerSvg = ({
  color = "inherit",
  size = "small",
  ...other
}: SpinnerProps) => {
  const boxSize = 44;
  const thickness = size !== "large" ? 4.2 : 3.6;

  return (
    <div
      className={clsx(css.root, css[color], css[size])}
      data-testid="spinner"
      {...other}
    >
      <svg
        className={css.svg}
        viewBox={`${boxSize / 2} ${boxSize / 2} ${boxSize} ${boxSize}`}
      >
        <circle
          className={css.circle}
          cx={boxSize}
          cy={boxSize}
          r={(boxSize - thickness) / 2}
          fill="none"
          strokeWidth={thickness}
        ></circle>
      </svg>
    </div>
  );
};

export const Spinner = ({ cover, ...other }: SpinnerProps) => {
  if (cover) {
    return (
      <div className={css.cover}>
        <SpinnerSvg {...other} />
      </div>
    );
  }

  return <SpinnerSvg {...other} />;
};
