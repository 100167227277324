import type { IconChildrenProps } from "../icon.types";

export const Logout = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M12.4987 1.66666H4.9987C4.07822 1.66666 3.33203 2.41286 3.33203 3.33333V16.6667C3.33203 17.5871 4.07822 18.3333 4.9987 18.3333H12.4987C13.4192 18.3333 14.1654 17.5871 14.1654 16.6667V15.8333C14.1654 15.3731 13.7923 15 13.332 15C12.8718 15 12.4987 15.3731 12.4987 15.8333V16.6667H4.9987V3.33333H12.4987V4.16666C12.4987 4.6269 12.8718 5 13.332 5C13.7923 5 14.1654 4.6269 14.1654 4.16666V3.33333C14.1654 2.41286 13.4192 1.66666 12.4987 1.66666ZM15.1695 13.5792C14.8445 13.9042 14.3195 13.9042 13.9945 13.5792C13.6702 13.2545 13.6698 12.7285 13.9937 12.4033L15.557 10.8333H8.33203C7.87179 10.8333 7.4987 10.4602 7.4987 10C7.4987 9.53976 7.87179 9.16666 8.33203 9.16666H15.557L13.9937 7.59666C13.6964 7.26768 13.7091 6.76335 14.0226 6.44978C14.3362 6.13621 14.8405 6.12348 15.1695 6.42083L18.1595 9.41083C18.4848 9.73625 18.4848 10.2637 18.1595 10.5892L15.1695 13.5792Z"
    fill={color}
    fillRule="evenodd"
  />
);
