import { AR_CATEGORY_AREA_CONTROLS } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseMaxWorker = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19322)">
        <path
          d="M13.6606 6.96988C13.9706 6.96988 14.2806 6.84988 14.5106 6.61988C14.9806 6.14988 14.9806 5.38988 14.5106 4.91988C14.0406 4.44988 13.2806 4.44988 12.8106 4.91988C12.3406 5.38988 12.3406 6.14988 12.8106 6.61988C13.0406 6.84988 13.3506 6.96988 13.6606 6.96988ZM13.3806 5.47988C13.4606 5.39988 13.5606 5.35988 13.6706 5.35988C13.7806 5.35988 13.8806 5.39988 13.9506 5.47988C14.1106 5.63988 14.1106 5.88988 13.9506 6.04988C13.7906 6.20988 13.5406 6.20988 13.3806 6.04988C13.2206 5.88988 13.2206 5.63988 13.3806 5.47988Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M14.1406 7.43988H13.1906C12.4706 7.43988 11.8906 8.01988 11.8906 8.73988V9.14988C11.8906 9.36988 12.0706 9.54988 12.2906 9.54988C12.5106 9.54988 12.6906 9.36988 12.6906 9.14988V8.73988C12.6906 8.45988 12.9206 8.23988 13.1906 8.23988H14.1406C14.4206 8.23988 14.6406 8.46988 14.6406 8.73988V9.14988C14.6406 9.36988 14.8206 9.54988 15.0406 9.54988C15.2606 9.54988 15.4406 9.36988 15.4406 9.14988V8.73988C15.4406 8.01988 14.8606 7.43988 14.1406 7.43988Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M8.63047 8.88008C7.87047 9.64008 7.87047 10.8601 8.63047 11.6201C9.01047 12.0001 9.50047 12.1901 10.0005 12.1901C10.5005 12.1901 10.9905 12.0001 11.3705 11.6201C12.1305 10.8601 12.1305 9.64008 11.3705 8.88008C10.6105 8.13008 9.39047 8.13008 8.63047 8.88008ZM10.5205 10.7701C10.2305 11.0601 9.76047 11.0601 9.48047 10.7701C9.19047 10.4801 9.19047 10.0101 9.48047 9.73008C9.62047 9.59008 9.81047 9.51008 10.0005 9.51008C10.1905 9.51008 10.3805 9.58008 10.5205 9.73008C10.8105 10.0201 10.8105 10.4901 10.5205 10.7701Z"
          fill={color}
        />
        <path
          d="M12.8905 15.3001V14.6101C12.8905 13.4501 11.9505 12.5101 10.7905 12.5101H9.21047C8.05047 12.5101 7.11047 13.4501 7.11047 14.6101V15.3001C7.11047 15.6301 7.38047 15.9001 7.71047 15.9001C8.04047 15.9001 8.31047 15.6301 8.31047 15.3001V14.6101C8.31047 14.1101 8.71047 13.7101 9.21047 13.7101H10.7905C11.2905 13.7101 11.6905 14.1101 11.6905 14.6101V15.3001C11.6905 15.6301 11.9605 15.9001 12.2905 15.9001C12.6205 15.9001 12.8905 15.6301 12.8905 15.3001Z"
          fill={color}
        />
        <path
          d="M5.42047 6.69008C5.67047 6.94008 6.01047 7.07008 6.34047 7.07008C6.67047 7.07008 7.01047 6.94008 7.26047 6.69008C7.77047 6.18008 7.77047 5.36008 7.26047 4.85008C6.75047 4.34008 5.93047 4.34008 5.42047 4.85008C4.91047 5.36008 4.91047 6.18008 5.42047 6.69008ZM6.13047 5.56008C6.19047 5.50008 6.27047 5.47008 6.34047 5.47008C6.41047 5.47008 6.50047 5.50008 6.55047 5.56008C6.67047 5.68008 6.67047 5.87008 6.55047 5.99008C6.43047 6.11008 6.24047 6.11008 6.12047 5.99008C6.00047 5.87008 6.00047 5.68008 6.12047 5.56008H6.13047Z"
          fill={color}
        />
        <path
          d="M7.21047 9.16008C7.21047 9.44008 7.43047 9.66008 7.71047 9.66008C7.99047 9.66008 8.21047 9.44008 8.21047 9.16008V8.75008C8.21047 7.98008 7.58047 7.35008 6.81047 7.35008H5.86047C5.09047 7.35008 4.46047 7.98008 4.46047 8.75008V9.16008C4.46047 9.44008 4.68047 9.66008 4.96047 9.66008C5.24047 9.66008 5.46047 9.44008 5.46047 9.16008V8.75008C5.46047 8.53008 5.64047 8.35008 5.86047 8.35008H6.81047C7.03047 8.35008 7.21047 8.53008 7.21047 8.75008V9.16008Z"
          fill={color}
        />
        <path
          d="M6.87047 3.13008C6.94047 3.13008 7.00047 3.12008 7.07047 3.09008C7.30047 2.99008 7.53047 2.91008 7.77047 2.83008C8.03047 2.75008 8.18047 2.47008 8.10047 2.20008C8.02047 1.93008 7.74047 1.79008 7.47047 1.87008C7.20047 1.95008 6.93047 2.05008 6.67047 2.16008C6.42047 2.27008 6.30047 2.56008 6.41047 2.82008C6.49047 3.01008 6.68047 3.12008 6.87047 3.12008V3.13008Z"
          fill={color}
        />
        <path
          d="M9.09047 2.55008C9.09047 2.55008 9.13047 2.55008 9.15047 2.55008C9.40047 2.52008 9.65047 2.51008 9.90047 2.50008C10.1805 2.50008 10.4005 2.27008 10.3905 1.99008C10.3905 1.71008 10.1305 1.49008 9.88047 1.50008C9.59047 1.50008 9.31047 1.52008 9.03047 1.55008C8.76047 1.58008 8.56047 1.83008 8.59047 2.10008C8.62047 2.35008 8.84047 2.54008 9.09047 2.54008V2.55008Z"
          fill={color}
        />
        <path
          d="M13.0705 16.8501C12.8405 16.9501 12.6105 17.0401 12.3705 17.1201C12.1105 17.2101 11.9705 17.4901 12.0505 17.7501C12.1205 17.9601 12.3205 18.0901 12.5205 18.0901C12.5705 18.0901 12.6305 18.0901 12.6805 18.0601C12.9505 17.9701 13.2105 17.8701 13.4705 17.7501C13.7205 17.6401 13.8305 17.3401 13.7205 17.0901C13.6105 16.8401 13.3105 16.7301 13.0605 16.8401L13.0705 16.8501Z"
          fill={color}
        />
        <path
          d="M18.0005 9.50008C17.7205 9.50008 17.5005 9.72008 17.5005 10.0001C17.5005 10.2501 17.4905 10.5001 17.4605 10.7501C17.4305 11.0201 17.6305 11.2701 17.9105 11.3001C17.9305 11.3001 17.9405 11.3001 17.9605 11.3001C18.2105 11.3001 18.4305 11.1101 18.4605 10.8501C18.4905 10.5701 18.5005 10.2901 18.5005 10.0001C18.5005 9.71008 18.2805 9.50008 18.0005 9.50008Z"
          fill={color}
        />
        <path
          d="M17.8205 11.7901C17.5505 11.7101 17.2805 11.8601 17.2005 12.1301C17.1305 12.3701 17.0505 12.6101 16.9505 12.8401C16.8505 13.1001 16.9705 13.3901 17.2205 13.4901C17.2805 13.5201 17.3505 13.5301 17.4105 13.5301C17.6105 13.5301 17.7905 13.4101 17.8705 13.2201C17.9805 12.9601 18.0705 12.6901 18.1505 12.4201C18.2305 12.1501 18.0805 11.8801 17.8105 11.8001L17.8205 11.7901Z"
          fill={color}
        />
        <path
          d="M16.4505 6.17008C16.5805 6.38008 16.6905 6.61008 16.8005 6.83008C16.8805 7.01008 17.0705 7.12008 17.2505 7.12008C17.3205 7.12008 17.3905 7.10008 17.4605 7.07008C17.7105 6.95008 17.8205 6.66008 17.7005 6.41008C17.5805 6.15008 17.4505 5.90008 17.3005 5.66008C17.1605 5.42008 16.8505 5.34008 16.6105 5.49008C16.3705 5.63008 16.2905 5.94008 16.4405 6.18008L16.4505 6.17008Z"
          fill={color}
        />
        <path
          d="M16.9905 13.9301C16.7605 13.7801 16.4505 13.8501 16.3005 14.0801C16.1605 14.2901 16.0205 14.4901 15.8605 14.6901C15.6905 14.9101 15.7205 15.2201 15.9405 15.3901C16.0305 15.4601 16.1405 15.5001 16.2505 15.5001C16.4005 15.5001 16.5405 15.4401 16.6405 15.3101C16.8205 15.0901 16.9805 14.8601 17.1405 14.6201C17.2905 14.3901 17.2205 14.0801 16.9905 13.9301Z"
          fill={color}
        />
        <path
          d="M4.91047 4.32008C5.02047 4.32008 5.13047 4.28008 5.23047 4.21008C5.42047 4.05008 5.62047 3.90008 5.83047 3.76008C6.06047 3.61008 6.12047 3.30008 5.97047 3.07008C5.82047 2.84008 5.51047 2.78008 5.28047 2.93008C5.04047 3.09008 4.82047 3.26008 4.60047 3.44008C4.39047 3.62008 4.36047 3.93008 4.53047 4.14008C4.63047 4.26008 4.77047 4.32008 4.92047 4.32008H4.91047Z"
          fill={color}
        />
        <path
          d="M17.2705 8.16008C17.3305 8.40008 17.3805 8.65008 17.4205 8.90008C17.4605 9.15008 17.6705 9.33008 17.9105 9.33008C17.9305 9.33008 17.9605 9.33008 17.9805 9.33008C18.2505 9.29008 18.4405 9.04008 18.4005 8.76008C18.3605 8.48008 18.3005 8.20008 18.2305 7.93008C18.1605 7.66008 17.8905 7.50008 17.6205 7.57008C17.3505 7.64008 17.1905 7.91008 17.2605 8.18008L17.2705 8.16008Z"
          fill={color}
        />
        <path
          d="M15.6205 5.04008C15.7205 5.15008 15.8605 5.21008 16.0005 5.21008C16.1205 5.21008 16.2405 5.17008 16.3305 5.09008C16.5405 4.91008 16.5605 4.59008 16.3705 4.38008C16.1805 4.17008 15.9805 3.96008 15.7805 3.77008C15.5805 3.58008 15.2605 3.59008 15.0705 3.80008C14.8805 4.00008 14.8905 4.32008 15.1005 4.51008C15.2805 4.68008 15.4605 4.86008 15.6205 5.05008V5.04008Z"
          fill={color}
        />
        <path
          d="M11.2905 2.61008C11.5405 2.65008 11.7805 2.71008 12.0205 2.78008C12.0605 2.79008 12.1105 2.80008 12.1505 2.80008C12.3705 2.80008 12.5705 2.66008 12.6305 2.43008C12.7005 2.16008 12.5505 1.89008 12.2805 1.81008C12.0105 1.73008 11.7305 1.67008 11.4505 1.62008C11.1805 1.57008 10.9205 1.76008 10.8705 2.03008C10.8205 2.30008 11.0105 2.56008 11.2805 2.61008H11.2905Z"
          fill={color}
        />
        <path
          d="M14.8805 15.7001C14.6905 15.8601 14.4905 16.0201 14.2905 16.1601C14.0605 16.3201 14.0105 16.6301 14.1705 16.8601C14.2705 17.0001 14.4205 17.0701 14.5805 17.0701C14.6805 17.0701 14.7805 17.0401 14.8705 16.9801C15.1005 16.8201 15.3305 16.6401 15.5405 16.4601C15.7505 16.2801 15.7705 15.9601 15.5905 15.7501C15.4105 15.5401 15.1005 15.5201 14.8805 15.7001Z"
          fill={color}
        />
        <path
          d="M4.47047 15.0701C4.28047 14.8701 3.97047 14.8501 3.76047 15.0401C3.56047 15.2301 3.54047 15.5401 3.73047 15.7501C3.92047 15.9601 4.12047 16.1601 4.33047 16.3501C4.43047 16.4401 4.54047 16.4801 4.66047 16.4801C4.80047 16.4801 4.93047 16.4201 5.03047 16.3101C5.21047 16.1001 5.20047 15.7901 4.99047 15.6001C4.80047 15.4301 4.62047 15.2601 4.46047 15.0701H4.47047Z"
          fill={color}
        />
        <path
          d="M6.80047 16.7801C6.57047 16.6701 6.35047 16.5501 6.14047 16.4301C5.90047 16.2901 5.60047 16.3601 5.45047 16.6001C5.31047 16.8401 5.38047 17.1401 5.62047 17.2901C5.86047 17.4401 6.11047 17.5701 6.37047 17.6901C6.44047 17.7201 6.51047 17.7401 6.58047 17.7401C6.77047 17.7401 6.95047 17.6301 7.03047 17.4501C7.15047 17.2001 7.04047 16.9001 6.79047 16.7801H6.80047Z"
          fill={color}
        />
        <path
          d="M14.6805 3.49008C14.8305 3.26008 14.7605 2.95008 14.5205 2.80008C14.2805 2.65008 14.0305 2.51008 13.7805 2.38008C13.5305 2.26008 13.2305 2.36008 13.1105 2.61008C12.9905 2.86008 13.0905 3.16008 13.3405 3.28008C13.5605 3.39008 13.7805 3.51008 13.9905 3.65008C14.0705 3.70008 14.1605 3.73008 14.2605 3.73008C14.4305 3.73008 14.5905 3.65008 14.6805 3.50008V3.49008Z"
          fill={color}
        />
        <path
          d="M11.0005 17.4301C10.7505 17.4601 10.5105 17.4801 10.2505 17.4901C9.97047 17.4901 9.76047 17.7301 9.77047 18.0101C9.77047 18.2801 10.0005 18.4901 10.2705 18.4901C10.2705 18.4901 10.2805 18.4901 10.2905 18.4901C10.5805 18.4901 10.8605 18.4601 11.1405 18.4201C11.4105 18.3801 11.6105 18.1301 11.5705 17.8601C11.5305 17.5901 11.2805 17.4001 11.0105 17.4301H11.0005Z"
          fill={color}
        />
        <path
          d="M8.85047 17.4101C8.60047 17.3701 8.36047 17.3201 8.12047 17.2601C7.85047 17.1901 7.58047 17.3501 7.51047 17.6201C7.44047 17.8901 7.60047 18.1601 7.87047 18.2301C8.14047 18.3001 8.42047 18.3601 8.70047 18.4001C8.73047 18.4001 8.75047 18.4001 8.78047 18.4001C9.02047 18.4001 9.24047 18.2201 9.27047 17.9801C9.31047 17.7101 9.12047 17.4501 8.85047 17.4101Z"
          fill={color}
        />
        <path
          d="M3.37047 6.02008C3.53047 6.02008 3.69047 5.94008 3.78047 5.80008C3.92047 5.59008 4.07047 5.39008 4.23047 5.20008C4.41047 4.99008 4.38047 4.67008 4.17047 4.50008C3.96047 4.32008 3.64047 4.35008 3.47047 4.56008C3.29047 4.78008 3.12047 5.00008 2.96047 5.24008C2.81047 5.47008 2.86047 5.78008 3.09047 5.93008C3.18047 5.99008 3.27047 6.02008 3.37047 6.02008Z"
          fill={color}
        />
        <path
          d="M2.22047 8.06008C2.27047 8.08008 2.32047 8.08008 2.37047 8.08008C2.58047 8.08008 2.78047 7.94008 2.85047 7.73008C2.93047 7.49008 3.01047 7.26008 3.11047 7.03008C3.22047 6.78008 3.11047 6.48008 2.85047 6.37008C2.60047 6.26008 2.30047 6.37008 2.19047 6.63008C2.08047 6.89008 1.98047 7.15008 1.89047 7.43008C1.81047 7.69008 1.95047 7.97008 2.22047 8.06008Z"
          fill={color}
        />
        <path
          d="M1.99047 10.3401C2.26047 10.3401 2.48047 10.1201 2.49047 9.85008C2.49047 9.60008 2.51047 9.35008 2.54047 9.10008C2.57047 8.83008 2.38047 8.58008 2.10047 8.54008C1.82047 8.51008 1.58047 8.70008 1.54047 8.98008C1.51047 9.26008 1.49047 9.54008 1.48047 9.83008C1.48047 10.1101 1.69047 10.3301 1.97047 10.3401H1.99047Z"
          fill={color}
        />
        <path
          d="M3.62047 13.9501C3.49047 13.7401 3.37047 13.5201 3.26047 13.2901C3.14047 13.0401 2.84047 12.9401 2.59047 13.0601C2.34047 13.1801 2.24047 13.4801 2.36047 13.7301C2.48047 13.9801 2.62047 14.2301 2.77047 14.4701C2.86047 14.6201 3.03047 14.7101 3.20047 14.7101C3.29047 14.7101 3.38047 14.6901 3.46047 14.6301C3.69047 14.4801 3.77047 14.1801 3.62047 13.9401V13.9501Z"
          fill={color}
        />
        <path
          d="M2.77047 11.9801C2.70047 11.7401 2.65047 11.5001 2.61047 11.2501C2.56047 10.9801 2.31047 10.7901 2.03047 10.8401C1.76047 10.8901 1.57047 11.1401 1.62047 11.4201C1.67047 11.7001 1.73047 11.9801 1.80047 12.2501C1.86047 12.4701 2.06047 12.6201 2.28047 12.6201C2.32047 12.6201 2.37047 12.6201 2.41047 12.6001C2.68047 12.5301 2.83047 12.2501 2.76047 11.9901L2.77047 11.9801Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19322">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
