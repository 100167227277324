import { clsx } from "clsx";
import { Card } from "~/components/card";
import { CardContent } from "~/components/card-content";
import { useUi } from "~/features/ui/ui.hooks";
import type { FormCardProps } from "./form-card.types";
import css from "./form-card.module.css";

export const FormCard = ({
  children,
  className,
  title,
  ...cardProps
}: FormCardProps) => {
  const { isMobile } = useUi();

  return (
    <Card
      {...cardProps}
      className={clsx(css.card, className)}
      size={isMobile ? "large" : "huge"}
    >
      <CardContent>
        <h1 className={css.title}>{title}</h1>
        {children}
      </CardContent>
    </Card>
  );
};
