import { AR_CATEGORY_PPE } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseHearingMuff = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19303)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4983 15.71C17.4283 14.4 16.4783 13.29 15.1783 13.03L12.9883 12.59L12.8683 12.57C12.7783 12.57 12.6883 12.57 12.6083 12.57L12.1783 10.62C13.0783 9.94998 13.6583 8.88998 13.6583 7.67998V4.46998C13.5883 3.35998 12.6683 2.47998 11.5383 2.47998H8.32828C7.21828 2.54998 6.33828 3.46998 6.33828 4.59998V7.87998C6.40828 8.99998 6.96828 9.98998 7.81828 10.62L7.38828 12.57C7.25828 12.56 7.12828 12.57 6.99828 12.59L4.80828 13.03L4.65828 13.06C3.38828 13.38 2.48828 14.53 2.48828 15.85V16.99C2.52828 17.27 2.76828 17.49 3.06828 17.49C3.38828 17.49 3.64828 17.23 3.64828 16.91V15.72C3.70828 14.95 4.27828 14.3 5.03828 14.15L7.22828 13.71H7.25828C7.25828 13.71 7.29828 13.71 7.31828 13.73C8.93828 14.81 11.0483 14.81 12.6683 13.73C12.6983 13.71 12.7283 13.71 12.7583 13.71L14.9483 14.15L15.0783 14.18C15.8183 14.39 16.3483 15.07 16.3483 15.85V16.99C16.3883 17.27 16.6283 17.49 16.9283 17.49C17.2483 17.49 17.5083 17.23 17.5083 16.91V15.69L17.4983 15.71ZM8.46828 13.07L8.88828 11.18C9.23828 11.29 9.61828 11.35 9.99828 11.35C10.3783 11.35 10.7583 11.29 11.1083 11.18L11.5283 13.07C10.5583 13.52 9.43828 13.52 8.46828 13.07ZM9.99828 10.2C8.61828 10.2 7.49828 9.07998 7.49828 7.69998V4.51998C7.54828 4.03998 7.95828 3.65998 8.45828 3.65998H11.5383C12.0683 3.65998 12.4983 4.08998 12.4983 4.61998V7.83998C12.4183 9.14998 11.3283 10.2 9.99828 10.2Z"
          fill={color}
        />
        <path
          d="M15.3392 4.74988L15.1592 4.09988C14.3792 2.01988 12.3092 0.629883 9.99922 0.629883C7.68922 0.629883 5.61922 2.01988 4.82922 4.12988L4.65922 4.74988C4.23922 4.93988 3.94922 5.35988 3.94922 5.83988V7.33988C3.94922 8.00988 4.48922 8.54988 5.15922 8.54988H5.32922C5.46922 8.84988 5.76922 9.04988 6.11922 9.04988C6.60922 9.04988 6.99922 8.65988 6.99922 8.16988V5.24988C6.99922 4.76988 6.60922 4.36988 6.11922 4.36988C5.88922 4.36988 5.67922 4.45988 5.51922 4.60988L5.58922 4.36988C6.24922 2.59988 8.02922 1.41988 9.99922 1.41988C11.9692 1.41988 13.7492 2.60988 14.3992 4.33988L14.4792 4.60988C14.3192 4.45988 14.1092 4.36988 13.8792 4.36988C13.3892 4.36988 12.9992 4.75988 12.9992 5.24988V8.16988C12.9992 8.64988 13.3892 9.04988 13.8792 9.04988C14.2292 9.04988 14.5292 8.83988 14.6692 8.54988H14.8392C15.5092 8.54988 16.0492 8.00988 16.0492 7.33988V5.83988C16.0492 5.34988 15.7592 4.93988 15.3392 4.74988ZM5.23922 7.74988H5.15922C4.92922 7.74988 4.74922 7.56988 4.74922 7.33988V5.83988C4.74922 5.60988 4.92922 5.42988 5.15922 5.42988H5.23922V7.74988ZM6.04922 5.24988C6.04922 5.24988 6.08922 5.16988 6.12922 5.16988C6.16922 5.16988 6.20922 5.20988 6.20922 5.24988V8.16988C6.20922 8.25988 6.04922 8.25988 6.04922 8.16988V5.24988ZM13.9592 8.16988C13.9592 8.25988 13.7992 8.25988 13.7992 8.16988V5.24988C13.7992 5.24988 13.8392 5.16988 13.8792 5.16988C13.9192 5.16988 13.9592 5.20988 13.9592 5.24988V8.16988ZM15.2492 7.32988C15.2492 7.55988 15.0692 7.73988 14.8392 7.73988H14.7592V5.41988H14.8392C15.0692 5.41988 15.2492 5.59988 15.2492 5.82988V7.32988Z"
          fill={getAlertCategoryColor(AR_CATEGORY_PPE)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19303">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
