import type { IconChildrenProps } from "../icon.types";

export const LineChart = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M2.37707 1.84705C2.65118 1.84705 2.87664 2.05537 2.90375 2.32233L2.90648 2.37646V15.9294C2.90648 16.5492 3.38969 17.0553 4.0002 17.092L4.07119 17.0941H17.6241C17.9165 17.0941 18.1535 17.3311 18.1535 17.6235C18.1535 17.8976 17.9452 18.1231 17.6783 18.1502L17.6241 18.1529H4.07119C2.87351 18.1529 1.89756 17.2069 1.84951 16.0211L1.84766 15.9294V2.37646C1.84766 2.08407 2.08468 1.84705 2.37707 1.84705Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M17.2179 4.57873C17.405 4.35411 17.7389 4.32375 17.9635 4.51092C18.1731 4.68562 18.2136 4.98806 18.0658 5.21024L18.0313 5.25655L14.1364 9.93077C13.8168 10.314 13.3176 10.4925 12.8307 10.4033L12.7449 10.3848L10.3885 9.79529C10.2851 9.76943 10.1765 9.79741 10.0986 9.86753L10.0672 9.90012L6.17247 14.5742C5.9853 14.7988 5.65147 14.8292 5.42685 14.642C5.2172 14.4673 5.17677 14.1649 5.32449 13.9427L5.35904 13.8964L9.25394 9.22216C9.57351 8.83894 10.0736 8.66031 10.5598 8.74956L10.6454 8.7681L13.0015 9.35755C13.1056 9.38351 13.2138 9.35568 13.2917 9.28546L13.3231 9.2528L17.2179 4.57873Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
