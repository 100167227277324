import type { IconChildrenProps } from "../../icon.types";

export const SolutionsPlasticPackagingAndContainer = ({
  color,
}: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M15.8333 17.5H11.6667C10.7458 17.5 10 16.7542 10 15.8333V11.6667C10 10.7458 10.7458 10 11.6667 10H15.8333C16.7542 10 17.5 10.7458 17.5 11.6667V15.8333C17.5 16.7542 16.7542 17.5 15.8333 17.5Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      clipRule="evenodd"
      d="M8.33333 17.5H4.16667C3.24583 17.5 2.5 16.7542 2.5 15.8333V11.6667C2.5 10.7458 3.24583 10 4.16667 10H8.33333C9.25417 10 10 10.7458 10 11.6667V15.8333C10 16.7542 9.25417 17.5 8.33333 17.5Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      clipRule="evenodd"
      d="M12.0833 10H7.91667C6.99583 10 6.25 9.25417 6.25 8.33333V4.16667C6.25 3.24583 6.99583 2.5 7.91667 2.5H12.0833C13.0042 2.5 13.75 3.24583 13.75 4.16667V8.33333C13.75 9.25417 13.0042 10 12.0833 10Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M5 15H6.66667"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M8.33398 7.50004H10.0007"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M12.5 15H14.1667"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
  </>
);
