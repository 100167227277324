import { AR_CATEGORY_ANOMALY } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseUnattendedObject = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19316)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.79031 16.3799H15.2803C15.5103 16.3399 15.6903 16.1399 15.6903 15.8999C15.6903 15.6299 15.4703 15.4199 15.2103 15.4199H4.72031C4.49031 15.4599 4.31031 15.6599 4.31031 15.8999C4.31031 16.1699 4.53031 16.3799 4.79031 16.3799Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.48031 17.6399C3.25031 17.6799 3.07031 17.8799 3.07031 18.1199C3.07031 18.3899 3.29031 18.5999 3.55031 18.5999H5.85031C6.08031 18.5599 6.26031 18.3599 6.26031 18.1199C6.26031 17.8499 6.04031 17.6399 5.78031 17.6399H3.48031Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2303 17.6399H7.26031C7.03031 17.6799 6.85031 17.8799 6.85031 18.1199C6.85031 18.3899 7.07031 18.5999 7.33031 18.5999H16.3003C16.5303 18.5599 16.7103 18.3599 16.7103 18.1199C16.7103 17.8499 16.4903 17.6399 16.2303 17.6399Z"
          fill={color}
        />
        <path
          d="M7.82828 9.82007C6.94828 9.82007 6.23828 10.5301 6.23828 11.4101V15.2601C6.23828 15.4801 6.41828 15.6601 6.63828 15.6601H13.3783C13.5983 15.6601 13.7783 15.4801 13.7783 15.2601V11.4101C13.7783 10.5301 13.0683 9.82007 12.1883 9.82007H7.82828ZM12.9583 14.8701H7.02828V11.4201C7.02828 10.9801 7.38828 10.6301 7.81828 10.6301H12.1683C12.6083 10.6301 12.9583 10.9901 12.9583 11.4201V14.8701Z"
          fill={getAlertCategoryColor(AR_CATEGORY_ANOMALY)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.53959 2.20991C10.0596 1.97991 10.6896 2.17991 10.9796 2.67991L12.9696 6.16991C13.0696 6.33991 13.1196 6.52991 13.1196 6.72991C13.1196 7.34991 12.6096 7.85991 11.9896 7.85991H8.01959C7.81959 7.85991 7.62959 7.80991 7.45959 7.70991C6.91959 7.39991 6.72959 6.70991 7.03959 6.16991L9.02959 2.67991C9.12959 2.49991 9.27959 2.35991 9.44959 2.25991L9.54959 2.20991H9.53959ZM10.2696 3.08991C10.1796 2.93991 9.98959 2.87991 9.82959 2.96991C9.77959 2.99991 9.73959 3.03991 9.70959 3.08991L7.71959 6.57991C7.62959 6.72991 7.68959 6.92991 7.83959 7.01991C7.88959 7.04991 7.93959 7.05991 7.99959 7.05991H11.9696C12.1496 7.05991 12.2896 6.91991 12.2896 6.73991C12.2896 6.67991 12.2796 6.62991 12.2496 6.57991L10.2596 3.08991H10.2696ZM9.97959 6.03991C10.1796 6.03991 10.3396 6.19991 10.3396 6.38991C10.3396 6.57991 10.1796 6.73991 9.97959 6.73991C9.77959 6.73991 9.61959 6.57991 9.61959 6.38991C9.61959 6.19991 9.77959 6.03991 9.97959 6.03991ZM9.98959 3.67991C10.1896 3.67991 10.3496 3.81991 10.3896 4.00991V5.47991C10.3896 5.69991 10.2096 5.87991 9.98959 5.87991C9.78959 5.87991 9.62959 5.73991 9.58959 5.54991V4.07991C9.58959 3.85991 9.76959 3.67991 9.98959 3.67991Z"
          fill={getAlertCategoryColor(AR_CATEGORY_ANOMALY)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19316">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
