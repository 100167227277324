/**
 * Ported from react-virtualized-auto-sizer
 *
 * https://github.com/bvaughn/react-virtualized-auto-sizer/blob/master/src/index.js
 */

import {
  type CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import type { Dimensions } from "~/types/dimensions";
import type { AutoSizerProps } from "./auto-sizer.types";
import { createDetectElementResize } from "./auto-sizer.utils";

export const AutoSizer = ({
  children,
  disableHeight,
  disableWidth,
  onResize,
}: AutoSizerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLElement>();
  const detectResizeRef =
    useRef<ReturnType<typeof createDetectElementResize>>();

  const [dimensions, setDimensions] = useState<Dimensions>({
    height: 0,
    width: 0,
  });

  const onInternalResize = useCallback(() => {
    if (parentRef.current) {
      // Guard against AutoSizer component being removed from the DOM immediately after being added.
      // This can result in invalid style values which can result in NaN values if we don't handle them.
      // See issue #150 for more context.

      const height = parentRef.current.offsetHeight;
      const width = parentRef.current.offsetWidth;

      const style = window.getComputedStyle(parentRef.current) || {};
      const paddingLeft = parseInt(style.paddingLeft, 10) || 0;
      const paddingRight = parseInt(style.paddingRight, 10) || 0;
      const paddingTop = parseInt(style.paddingTop, 10) || 0;
      const paddingBottom = parseInt(style.paddingBottom, 10) || 0;

      const newHeight = height - paddingTop - paddingBottom;
      const newWidth = width - paddingLeft - paddingRight;

      if (
        (!disableHeight && dimensions.height !== newHeight) ||
        (!disableWidth && dimensions.width !== newWidth)
      ) {
        setDimensions({
          height: height - paddingTop - paddingBottom,
          width: width - paddingLeft - paddingRight,
        });

        onResize?.({ height, width });
      }
    }
  }, [
    dimensions.height,
    dimensions.width,
    disableHeight,
    disableWidth,
    onResize,
  ]);

  useEffect(() => {
    if (ref.current) {
      parentRef.current = ref.current.parentNode as HTMLElement;

      detectResizeRef.current = createDetectElementResize();
      detectResizeRef.current.addResizeListener(
        parentRef.current,
        onInternalResize,
      );

      onInternalResize();
    }
    return () => {
      if (parentRef.current) {
        detectResizeRef.current?.removeResizeListener(
          parentRef.current,
          onInternalResize,
        );
      }
    };
  }, [onInternalResize]);

  const outerStyle: CSSProperties = { overflow: "visible" };
  const childParams: Dimensions = {
    height: 0,
    width: 0,
  };

  // Avoid rendering children before the initial measurements have been collected.
  // At best this would just be wasting cycles.
  let bailoutOnChildren = false;

  if (!disableHeight) {
    if (dimensions.height === 0) {
      bailoutOnChildren = true;
    }
    outerStyle.height = 0;
    childParams.height = dimensions.height;
  }

  if (!disableWidth) {
    if (dimensions.width === 0) {
      bailoutOnChildren = true;
    }
    outerStyle.width = 0;
    childParams.width = dimensions.width;
  }

  return (
    <div ref={ref} style={outerStyle}>
      {!bailoutOnChildren ? children(childParams) : null}
    </div>
  );
};
