import { useEffect, useState } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { Spinner } from "~/components/spinner";
import { getAuth0 } from "~/features/auth/auth.auth0";
import { clearAuthCookie, getAuthCookie } from "~/features/auth/auth.cookie";
import { setAuthStorage } from "~/features/auth/auth.storage";
import { getAuthSearchParams } from "~/features/auth/auth.utils";
import type { CallbackRouteState } from "../callback-route/callback-route.types";
import { isFromCallbackRoute } from "../callback-route/callback-route.utils";
import type { SignUpRouteState } from "../sign-up-route/sign-up-route.types";
import { isFromSignUpRoute } from "../sign-up-route/sign-up-route.utils";

export const GuardRoute = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [context, setContext] = useState<
    CallbackRouteState | SignUpRouteState
  >();

  useEffect(() => {
    const { appName, appUrl, redirect } = getAuthSearchParams(searchParams);

    if (
      isFromCallbackRoute(location.state) ||
      isFromSignUpRoute(location.state)
    ) {
      // Coming from /callback or /signup
      //  pass state down to children via Outlet context
      setContext(location.state);
    } else {
      // Coming from direct URL access
      //  retrieve tenant from cookie
      const tenant = getAuthCookie({ appName, appUrl });

      if (tenant) {
        // We got the tenant
        //  now let's create a storage state to send auth0.authorize
        const id = setAuthStorage({
          appName,
          appUrl,
          internalRedirect: location.pathname,
          redirect,
          tenant,
        });

        // Ask auth0 if user is authenticated
        //  setting prompt="none" will not ask users for login via auth0,
        //  it will redirect back to /callback
        getAuth0(tenant, appName).authorize({
          prompt: "none",
          state: id,
        });
      } else {
        // We failed to retrieve tenant,
        //  clear invalid cookie
        clearAuthCookie({ appName, appUrl });
        setContext({
          appName,
          appUrl,
          fromCallback: false,
          redirect,
        });
      }
    }
  }, [location.pathname, location.state, searchParams]);

  if (!context) {
    return <Spinner color="secondary" cover size="large" />;
  }

  return <Outlet context={context} />;
};
