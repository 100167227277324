import type { IconChildrenProps } from "../icon.types";

export const Marker = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M2.80746 8H0C0 3.58897 3.58874 0 8 0V2.80772C5.13677 2.80772 2.80746 5.13691 2.80746 8Z"
    fill={color}
    fillRule="evenodd"
  />
);
