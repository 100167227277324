import type { IconChildrenProps } from "../../icon.types";

export const ObjectDetection = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.14012 2.52935C7.14012 2.23696 6.90309 1.99994 6.61071 1.99994H4.70483L4.61139 2.0017C3.30944 2.05078 2.26953 3.12124 2.26953 4.43523V6.34112L2.27226 6.39524C2.29938 6.6622 2.52483 6.87053 2.79894 6.87053C3.09133 6.87053 3.32835 6.6335 3.32835 6.34112V4.43523L3.33053 4.35709C3.37101 3.63298 3.97054 3.05876 4.70483 3.05876H6.61071L6.66484 3.05603C6.9318 3.02892 7.14012 2.80346 7.14012 2.52935ZM3.32562 13.9105C3.29851 13.6436 3.07305 13.4352 2.79894 13.4352C2.50656 13.4352 2.26953 13.6723 2.26953 13.9647V15.8705L2.27129 15.964C2.32037 17.2659 3.39083 18.3058 4.70483 18.3058H6.61071L6.66484 18.3031C6.9318 18.276 7.14012 18.0505 7.14012 17.7764C7.14012 17.484 6.90309 17.247 6.61071 17.247H4.70483L4.62669 17.2448C3.90257 17.2044 3.32835 16.6048 3.32835 15.8705V13.9647L3.32562 13.9105ZM18.0463 13.4352C18.3204 13.4352 18.5458 13.6436 18.5729 13.9105L18.5757 13.9646V15.8705C18.5757 17.1845 17.5358 18.255 16.2338 18.3041L16.1404 18.3058H14.2345C13.9421 18.3058 13.7051 18.0688 13.7051 17.7764C13.7051 17.5023 13.9134 17.2768 14.1804 17.2497L14.2345 17.247H16.1404C16.8747 17.247 17.4742 16.6728 17.5147 15.9487L17.5168 15.8705V13.9646C17.5168 13.6723 17.7539 13.4352 18.0463 13.4352ZM18.5739 4.3418C18.5248 3.03984 17.4544 1.99994 16.1404 1.99994H14.2345L14.1804 2.00267C13.9134 2.02978 13.7051 2.25524 13.7051 2.52935C13.7051 2.82174 13.9421 3.05876 14.2345 3.05876H16.1404L16.2185 3.06094C16.9426 3.10141 17.5168 3.70095 17.5168 4.43523V6.34112L17.5196 6.39524C17.5467 6.6622 17.7721 6.87053 18.0463 6.87053C18.3386 6.87053 18.5757 6.6335 18.5757 6.34112V4.43523L18.5739 4.3418Z"
      fill={accentColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1372 4.44098C10.3131 4.35301 10.5202 4.35301 10.6962 4.44098L16.1128 7.14932C16.3246 7.25519 16.4583 7.4716 16.4583 7.70833V13.125C16.4583 13.3617 16.3246 13.5781 16.1128 13.684L10.6962 16.3923C10.5202 16.4803 10.3131 16.4803 10.1372 16.3923L4.72049 13.684C4.50875 13.5781 4.375 13.3617 4.375 13.125V7.70833C4.375 7.4716 4.50875 7.25519 4.72049 7.14932L10.1372 4.44098ZM5.625 8.7196V12.7387L9.79167 14.8221V10.8029L5.625 8.7196ZM11.0417 10.8029V14.8221L15.2083 12.7387V8.7196L11.0417 10.8029ZM14.4358 7.70833L10.4167 9.7179L6.39754 7.70833L10.4167 5.69877L14.4358 7.70833Z"
      fill={color}
    />
  </>
);
