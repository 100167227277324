import type { IconChildrenProps } from "../icon.types";

export const Tracker = ({ color }: IconChildrenProps) => (
  <g id="tracker">
    <g id="Vector">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9561 4.2717C11.9561 3.97207 12.199 3.72917 12.4986 3.72917C13.6616 3.72917 14.6036 4.67123 14.6036 5.8342C14.6036 6.13383 14.3607 6.37674 14.0611 6.37674C13.7615 6.37674 13.5186 6.13383 13.5186 5.8342C13.5186 5.2705 13.0623 4.81424 12.4986 4.81424C12.199 4.81424 11.9561 4.57133 11.9561 4.2717Z"
        fill="#FF3D00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7894 1.66753C12.7894 1.3679 13.0323 1.125 13.3319 1.125C15.4724 1.125 17.2078 2.8604 17.2078 5.00087C17.2078 5.3005 16.9649 5.5434 16.6652 5.5434C16.3656 5.5434 16.1227 5.3005 16.1227 5.00087C16.1227 3.45967 14.8731 2.21007 13.3319 2.21007C13.0323 2.21007 12.7894 1.96717 12.7894 1.66753Z"
        fill="#FF3D00"
      />
    </g>
    <g id="Vector_2">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.06735 15.0018C8.06483 14.6212 8.37404 14.3172 8.74821 14.3172C9.12323 14.3172 9.43074 14.6205 9.43074 14.9989C9.43074 15.3752 9.12534 15.6806 8.74904 15.6806C8.37371 15.6806 8.06892 15.3768 8.06735 15.0018Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.79004 5.83225C2.79004 4.61178 3.77877 3.62305 4.99924 3.62305H9.99924C10.2989 3.62305 10.5418 3.86595 10.5418 4.16558C10.5418 4.46522 10.2989 4.70812 9.99924 4.70812H4.99924C4.37804 4.70812 3.87511 5.21105 3.87511 5.83225V15.8322C3.87511 16.4534 4.37804 16.9564 4.99924 16.9564H12.4992C13.1204 16.9564 13.6234 16.4534 13.6234 15.8322V8.33225C13.6234 8.03261 13.8663 7.78971 14.1659 7.78971C14.4655 7.78971 14.7084 8.03261 14.7084 8.33225V15.8322C14.7084 17.0527 13.7197 18.0414 12.4992 18.0414H4.99924C3.77877 18.0414 2.79004 17.0527 2.79004 15.8322V5.83225Z"
        fill={color}
      />
    </g>
  </g>
);
