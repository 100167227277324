import type { IconChildrenProps } from "../icon.types";

export const Camera = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M15 8.61803V11.382L16.4472 12.1056C16.5003 12.1321 16.5587 12.1459 16.618 12.1459C16.8026 12.1459 16.9566 12.015 16.9922 11.8409L17 11.7639V8.23607C17 8.19654 16.9939 8.15737 16.9819 8.11993L16.9597 8.06525C16.8771 7.90015 16.6911 7.82096 16.5195 7.86694L16.4472 7.89443L15 8.61803ZM14.9617 12.4808C14.7316 13.9091 13.4932 15 12 15H5C3.34315 15 2 13.6569 2 12V8C2 6.34315 3.34315 5 5 5H12C13.4932 5 14.7316 6.09089 14.9617 7.51916L16 7C16.6827 6.65867 17.5128 6.93537 17.8541 7.61803C17.95 7.80993 18 8.02152 18 8.23607V11.7639C18 12.5272 17.3813 13.1459 16.618 13.1459C16.4035 13.1459 16.1919 13.0959 16 13L14.9617 12.4808ZM14 11.382V12L13.9945 12.1493C13.9182 13.1841 13.0544 14 12 14H5L4.85074 13.9945C3.81588 13.9182 3 13.0544 3 12V8L3.00549 7.85074C3.08183 6.81588 3.94564 6 5 6H12L12.1493 6.00549C13.1841 6.08183 14 6.94564 14 8V8.61803V11.382Z"
    fill={color}
    fillRule="evenodd"
  />
);
