import type { IconChildrenProps } from "../icon.types";

export const Webcam = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M7.92383 9.99968C7.92383 8.8487 8.85688 7.91565 10.0079 7.91565C11.1588 7.91565 12.0919 8.8487 12.0919 9.99968C12.0919 11.1507 11.1588 12.0837 10.0079 12.0837C8.85688 12.0837 7.92383 11.1507 7.92383 9.99968ZM10.0079 8.74898C9.31712 8.74898 8.75716 9.30894 8.75716 9.99968C8.75716 10.6904 9.31712 11.2504 10.0079 11.2504C10.6986 11.2504 11.2586 10.6904 11.2586 9.99968C11.2586 9.30894 10.6986 8.74898 10.0079 8.74898Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11.6248 13.7546C11.8532 13.726 12.0614 13.888 12.09 14.1163L12.5068 17.4511C12.5354 17.6794 12.3734 17.8877 12.145 17.9162C11.9167 17.9447 11.7085 17.7828 11.6799 17.5544L11.2631 14.2197C11.2345 13.9914 11.3965 13.7831 11.6248 13.7546Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M6.67491 6.24793C4.60324 6.24793 2.92318 7.92799 2.92318 9.99967C2.92318 12.0713 4.60324 13.7514 6.67491 13.7514H13.3444C15.416 13.7514 17.0961 12.0713 17.0961 9.99967C17.0961 7.92799 15.416 6.24793 13.3444 6.24793H6.67491ZM2.08984 9.99967C2.08984 7.46776 4.143 5.4146 6.67491 5.4146H13.3444C15.8763 5.4146 17.9294 7.46776 17.9294 9.99967C17.9294 12.5316 15.8763 14.5847 13.3444 14.5847H6.67491C4.143 14.5847 2.08984 12.5316 2.08984 9.99967Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.0099 1.24622C10.2401 1.24622 10.4266 1.43276 10.4266 1.66288V3.33024C10.4266 3.56036 10.2401 3.74691 10.0099 3.74691C9.77982 3.74691 9.59327 3.56036 9.59327 3.33024V1.66288C9.59327 1.43276 9.77982 1.24622 10.0099 1.24622Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M13.6388 2.20184C13.8016 2.36452 13.8016 2.62834 13.639 2.7911L12.8053 3.62516C12.6426 3.78792 12.3788 3.78798 12.216 3.6253C12.0533 3.46262 12.0532 3.1988 12.2159 3.03605L13.0496 2.20198C13.2122 2.03922 13.4761 2.03916 13.6388 2.20184Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M6.38025 2.20184C6.543 2.03916 6.80682 2.03922 6.9695 2.20198L7.80318 3.03605C7.96586 3.1988 7.9658 3.46262 7.80304 3.6253C7.64029 3.78798 7.37647 3.78792 7.21379 3.62517L6.38011 2.7911C6.21743 2.62834 6.21749 2.36452 6.38025 2.20184Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.39341 13.7546C8.62175 13.7831 8.78372 13.9914 8.75518 14.2197L8.33834 17.5544C8.3098 17.7828 8.10155 17.9447 7.87321 17.9162C7.64487 17.8877 7.4829 17.6794 7.51144 17.4511L7.92828 14.1163C7.95682 13.888 8.16507 13.726 8.39341 13.7546Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M6.37695 17.503C6.37695 17.2728 6.5635 17.0863 6.79362 17.0863H13.2246C13.4548 17.0863 13.6413 17.2728 13.6413 17.503C13.6413 17.7331 13.4548 17.9196 13.2246 17.9196H6.79362C6.5635 17.9196 6.37695 17.7331 6.37695 17.503Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
