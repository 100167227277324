import type { IconChildrenProps } from "../icon.types";

export const Heart = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M16.4576 3.9789C16.4095 2.79305 15.4336 1.84705 14.2359 1.84705H4.07119L3.97951 1.8489C2.79366 1.89695 1.84766 2.8729 1.84766 4.07058V15.9294L1.84951 16.0211C1.89756 17.2069 2.87351 18.1529 4.07119 18.1529H8.30648L8.36061 18.1502C8.62757 18.1231 8.83589 17.8976 8.83589 17.6235C8.83589 17.3311 8.59887 17.0941 8.30648 17.0941H4.07119L4.0002 17.092C3.38969 17.0553 2.90648 16.5492 2.90648 15.9294V4.07058L2.9086 3.99959C2.94525 3.38908 3.45141 2.90587 4.07119 2.90587H14.2359L14.3069 2.90799C14.9174 2.94464 15.4006 3.4508 15.4006 4.07058V7.45881L15.4033 7.51294C15.4304 7.7799 15.6559 7.98822 15.93 7.98822C16.2224 7.98822 16.4594 7.7512 16.4594 7.45881V4.07058L16.4576 3.9789ZM13.0712 6.61175C13.0712 6.31937 12.8342 6.08234 12.5418 6.08234H5.7653L5.71117 6.08507C5.44422 6.11218 5.23589 6.33764 5.23589 6.61175C5.23589 6.90414 5.47292 7.14116 5.7653 7.14116H12.5418L12.5959 7.13843C12.8629 7.11132 13.0712 6.88586 13.0712 6.61175Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.4638 10.965L10.3663 11.0447C9.32498 11.9411 9.19196 13.4971 10.0522 14.5756C11.1788 15.9883 12.304 17.1463 13.4827 18.0439C13.6721 18.1881 13.9345 18.1882 14.124 18.0441C15.3283 17.1279 16.4747 15.9412 17.6288 14.4835C18.447 13.4499 18.2883 11.9459 17.2845 11.0827L17.2482 11.0514L17.1636 10.9817C16.1638 10.1929 14.7363 10.2658 13.8283 11.1517L13.8038 11.1766L13.8554 11.229C12.9604 10.278 11.4908 10.1661 10.4638 10.965ZM13.0842 11.9545L13.4178 12.3092C13.6267 12.5314 13.9796 12.5316 14.1887 12.3097L14.5225 11.9556C15.0618 11.3826 15.9563 11.337 16.5577 11.8541L16.594 11.8854C17.1706 12.3812 17.2614 13.2417 16.7987 13.8262L16.6041 14.0694C15.6968 15.1912 14.7981 16.1311 13.8785 16.8881L13.8034 16.9484L13.7129 16.875C12.7565 16.0847 11.8219 15.0965 10.88 13.9154L10.8278 13.8462C10.3953 13.238 10.4657 12.4147 10.9941 11.9046L11.0551 11.8487L11.1241 11.7926C11.7102 11.3369 12.5641 11.4019 13.0842 11.9545Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
