import { useCallback, useState } from "react";
import { Input, type InputProps } from "../input";

export const PasswordInput = (props: InputProps) => {
  const [isHidden, setIsHidden] = useState(true);

  const onRightIconClick = useCallback(
    () => setIsHidden((prevIsHidden) => !prevIsHidden),
    [],
  );

  return (
    <Input
      autoComplete="new-password"
      fullWidth
      name="password"
      placeholder="Password"
      {...props}
      autoCapitalize="off"
      autoCorrect="off"
      spellCheck={false}
      type={isHidden ? "password" : "text"}
      rightIcon={isHidden ? "observation-off" : "observation"}
      onRightIconClick={onRightIconClick}
    />
  );
};
