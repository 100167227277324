import type { IconChildrenProps } from "../icon.types";

export const Cloud = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M2.86576 9.18012C2.73832 7.30799 4.08526 5.6581 5.94502 5.40828C6.61773 3.42231 8.62955 2.21556 10.6984 2.55709C12.7672 2.89861 14.2844 4.68795 14.2831 6.78475C15.8594 6.78744 17.1358 8.06603 17.1358 9.64232C17.1358 11.2186 15.8594 12.4972 14.2831 12.4999H6.42776C4.5513 12.5044 2.9932 11.0523 2.86576 9.18012Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11.8125 15.5319C12.0078 15.7271 12.0078 16.0437 11.8125 16.239L10.25 17.8015C10.1563 17.8952 10.0291 17.9479 9.89648 17.9479C9.76388 17.9479 9.6367 17.8952 9.54293 17.8015L8.60543 16.864C8.41017 16.6687 8.41017 16.3521 8.60543 16.1569C8.80069 15.9616 9.11728 15.9616 9.31254 16.1569L9.89648 16.7408L11.1054 15.5319C11.3007 15.3366 11.6173 15.3366 11.8125 15.5319Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.0007 12.0167C10.2768 12.0167 10.5007 12.2405 10.5007 12.5167V14.1667C10.5007 14.4428 10.2768 14.6667 10.0007 14.6667C9.72451 14.6667 9.50065 14.4428 9.50065 14.1667V12.5167C9.50065 12.2405 9.72451 12.0167 10.0007 12.0167Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12.834 16.6668C12.834 16.3907 13.0578 16.1668 13.334 16.1668H16.6673C16.9435 16.1668 17.1673 16.3907 17.1673 16.6668C17.1673 16.943 16.9435 17.1668 16.6673 17.1668H13.334C13.0578 17.1668 12.834 16.943 12.834 16.6668Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M2.83398 16.6668C2.83398 16.3907 3.05784 16.1668 3.33398 16.1668H6.66732C6.94346 16.1668 7.16732 16.3907 7.16732 16.6668C7.16732 16.943 6.94346 17.1668 6.66732 17.1668H3.33398C3.05784 17.1668 2.83398 16.943 2.83398 16.6668Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
