export const colors = {
  "backdrop-00": "rgba(26, 26, 31, 0.24)",
  "backdrop-10": "rgba(189, 189, 189, 0.7)",
  "backdrop-20": "rgba(255, 255, 255, 0.6)",

  "beige-00": "#ded9ca",
  "beige-10": "#f1f0e5",
  "beige-20": "#f5f4ed",
  "beige-30": "#f9f8f4",
  "beige-40": "#fcfcfa",
  "beige-d20": "#46433c",

  "blush-20": "#feb29b",
  "blush-30": "#febba8",
  "blush-40": "#fdc5b3",
  "blush-50": "#fecec0",
  "blush-60": "#fed8cd",
  "blush-70": "#ffe2d9",
  "blush-80": "#ffebe6",
  "blush-90": "#fff5f2",

  "cyan-00": "#00d0df",
  "cyan-10": "#00d8e8",
  "cyan-20": "#00e1f1",
  "cyan-30": "#00eafa",
  "cyan-40": "#32f1ff",
  "cyan-50": "#6ff5ff",
  "cyan-60": "#9af8ff",
  "cyan-70": "#bdfbff",
  "cyan-80": "#dbfdff",
  "cyan-90": "#f6feff",
  "cyan-d30": "#008f99",

  "dark-00": "#000000",
  "dark-10": "#0f0f12",
  "dark-20": "#121216",
  "dark-30": "#2e2e39",
  "dark-40": "#4a4a5b",
  "dark-50": "#67677d",
  "dark-60": "#84849b",
  "dark-70": "#a3a3b4",
  "dark-80": "#c2c2cd",
  "dark-90": "#dfdfe5",

  "darker-10": "#0c0d0e",
  "darker-20": "#1a1a20",
  "darker-30": "#1b1b20",
  "darker-40": "#252528",
  "darker-50": "#2d2d30",
  "darker-60": "#353539",
  "darker-70": "#3c3c40",

  "honey-00": "#e08a00",
  "honey-10": "#ffa100",
  "honey-20": "#fcae2a",
  "honey-30": "#fbba45",
  "honey-40": "#fec361",
  "honey-50": "#fdcd7b",
  "honey-60": "#fdd795",
  "honey-70": "#fee0b0",
  "honey-80": "#feebca",
  "honey-90": "#fff5e5",
  "honey-d10": "#362a1a",
  "honey-d20": "#5a431b",
  "honey-d30": "#815e19",
  "honey-d40": "#a97b10",

  "magenta-00": "#c60bd7",
  "magenta-10": "#e016f3",
  "magenta-20": "#ee79f8",
  "magenta-30": "#f297eb",
  "magenta-40": "#efb4e2",
  "magenta-50": "#eecae0",
  "magenta-60": "#efdbe4",
  "magenta-70": "#f2e9ec",
  "magenta-d30": "#8f089b",

  "midgrey-20": "#99999a",
  "midgrey-30": "#a5a5a7",
  "midgrey-40": "#b2b2b3",
  "midgrey-50": "#bfbfc0",
  "midgrey-60": "#cbcbcd",
  "midgrey-70": "#d8d8d9",
  "midgrey-80": "#e5e5e6",
  "midgrey-90": "#f2f2f2",

  "navy-00": "#006bc0",
  "navy-10": "#127bcf",
  "navy-20": "#1b8cea",
  "navy-30": "#3b9ded",
  "navy-40": "#5cadf0",
  "navy-50": "#7cbef3",
  "navy-60": "#9ecef6",
  "navy-70": "#bedff9",
  "navy-80": "#deeffc",
  "navy-90": "#e8f3fb",
  "navy-d10": "#212831",
  "navy-d20": "#2f3f4e",
  "navy-d30": "#3d576d",
  "navy-d40": "#4c718e",

  "pine-10": "#24958a",
  "pine-20": "#2cbbad",
  "pine-30": "#43d3c4",
  "pine-40": "#68dcd0",
  "pine-50": "#8de4dc",
  "pine-60": "#b5ede8",
  "pine-70": "#d9f6f3",
  "pine-80": "#e2f8f6",
  "pine-90": "#eCf9f7",
  "pine-d10": "#192728",
  "pine-d20": "#1d3e3b",
  "pine-d30": "#1e5550",
  "pine-d40": "#1a6e65",

  "purple-00": "#7248ff",
  "purple-10": "#8662ff",
  "purple-20": "#9879ff",
  "purple-30": "#a88eff",
  "purple-40": "#b7a1ff",
  "purple-50": "#c6b5ff",
  "purple-60": "#d4c7ff",
  "purple-70": "#e2d9ff",
  "purple-d10": "#25163a",
  "purple-d20": "#361663",
  "purple-d30": "#45118e",
  "purple-d40": "#5400bb",

  "red-00": "#bc2c00",
  "red-10": "#e83600",
  "red-20": "#ff3d00",
  "red-30": "#ff5420",
  "red-40": "#ff6d40",
  "red-50": "#ff8560",
  "red-60": "#ff9d80",
  "red-70": "#feb69f",
  "red-80": "#ffcebe",
  "red-90": "#ffe7df",
  "red-d10": "#2a1916",
  "red-d20": "#411f16",
  "red-d30": "#582415",
  "red-d40": "#702913",

  "score-01": "#63ab11",
  "score-02": "#85d345",
  "score-03": "#b9cb18",
  "score-04": "#fff67c",
  "score-05": "#f7d600",
  "score-06": "#fcae2a",
  "score-07": "#f7a501",
  "score-08": "#db9301",
  "score-09": "#feb29b",
  "score-10": "#ff6d40",
  "score-11": "#ff3d00",
  "score-12": "#bc2c00",
  "score-13": "#a22600",
  "score-14": "#8c1600",
  "score-15": "#780700",

  "success-00": "#649e26",
  "success-10": "#74a73c",
  "success-20": "#83b051",
  "success-30": "#92b964",
  "success-40": "#a1c277",
  "success-50": "#afcb8a",
  "success-60": "#bdd49d",
  "success-70": "#ccddb1",
  "success-80": "#dae6c4",
  "success-90": "#E8EFD8",
  "success-d10": "#263218",
  "success-d20": "#2e4318",
  "success-d30": "#375516",

  "tone-20": "#fde5d2",
  "tone-30": "#feeee2",
  "tone-40": "#fef7f0",
  "tone-d20": "#3b1401",

  transparent: "transparent",

  "white-00": "#ffffff",
  "white-60": "#fafafa",

  "yellow-00": "#f7d600",
  "yellow-20": "#fff346",
  "yellow-30": "#fff563",
  "yellow-40": "#fff67c",
  "yellow-50": "#fff893",
  "yellow-60": "#fff9a7",
  "yellow-70": "#fffabb",
  "yellow-80": "#fffccd",
  "yellow-90": "#fffef0",
  "yellow-d30": "#9e8900",
};
