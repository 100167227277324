import { clsx } from "clsx";
import { type ElementType, forwardRef } from "react";
import { useThemeStyle } from "~/features/theme/theme.hooks";
import type { PolymorphicRef, TextComponent, TextProps } from "./text.types";
import css from "./text.module.css";

export const Text: TextComponent = forwardRef(
  <T extends ElementType = "span">(
    {
      align = "left",
      as,
      className,
      classNames,
      color,
      fontSize,
      fontWeight,
      fullWidth,
      transform = "none",
      ...other
    }: TextProps<T>,
    ref: PolymorphicRef<T>,
  ) => {
    const Component: ElementType = as ?? "span";

    const styleWithTheme = useThemeStyle({
      color,
      fontSize,
      fontWeight,
    });

    return (
      <Component
        data-testid="text"
        ref={ref}
        className={clsx(
          css.root,
          className as string,
          classNames?.root,
          css[align],
          css[transform],
          fullWidth && css.fullWidth,
        )}
        {...other}
        style={{ ...other.style, ...styleWithTheme }}
      />
    );
  },
);

// @ts-expect-error This component requires a generic type, therefore it gets annoying to work with fowardRef.
Text.displayName = "Text";
