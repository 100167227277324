import type { IconChildrenProps } from "../../icon.types";

export const HazardsSlipsTripsAndFalls = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M15.6555 4.80751C16.2251 5.37702 16.2251 6.30039 15.6555 6.8699C15.086 7.43941 14.1627 7.43941 13.5932 6.8699C13.0236 6.30038 13.0236 5.37702 13.5932 4.80751C14.1627 4.23799 15.086 4.23799 15.6555 4.80751Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      clipRule="evenodd"
      d="M7.39307 7.4188C7.59306 7.11237 8.00359 7.02608 8.31001 7.22607L11.5138 9.31704L14.6789 8.62848C14.9469 8.57018 15.2231 8.68301 15.3736 8.91227L17.3366 11.9022C17.4075 12.0102 17.4453 12.1367 17.4452 12.2659L17.445 15.2558C17.4449 15.6217 17.1483 15.9183 16.7823 15.9183C16.4164 15.9183 16.1198 15.6216 16.1199 15.2557L16.1201 12.4638L14.8713 10.5617L12.9464 14.0789C12.8773 14.2052 12.7689 14.3055 12.6377 14.3647L8.35736 16.2962L6.98245 17.9255C6.7842 18.1604 6.45039 18.2282 6.17627 18.089L4.21241 17.0924C3.88611 16.9268 3.75584 16.528 3.92143 16.2017C4.08703 15.8754 4.48579 15.7452 4.81208 15.9108L6.3081 16.67L7.44155 15.3268C7.50534 15.2512 7.58524 15.1909 7.67539 15.1502L10.2212 14.0014L7.65087 13.4793L5.9358 15.0035C5.73223 15.1844 5.4387 15.2223 5.19585 15.0991L3.23199 14.1024C2.90569 13.9368 2.77542 13.5381 2.94101 13.2118C3.10661 12.8855 3.50536 12.7552 3.83166 12.9208L5.39298 13.7132L7.01814 12.2689C7.17395 12.1304 7.38585 12.0734 7.59013 12.1149L12.0185 13.0143L13.5412 10.2321L11.5247 10.6708C11.3514 10.7085 11.1702 10.6751 11.0217 10.5782L7.58579 8.33574C7.27937 8.13575 7.19308 7.72522 7.39307 7.4188Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
