import type { IconChildrenProps } from "../icon.types";

export const Message = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M13.9341 3.29999H6.09961C3.61433 3.29999 1.59961 5.31471 1.59961 7.79999V11.3681L1.6048 11.5798C1.70478 13.614 3.23748 15.2714 5.21497 15.5643L5.40485 15.5875L5.47686 16.0908C5.5164 16.3676 5.63254 16.6279 5.81215 16.8422L5.9166 16.9547C6.46132 17.4851 7.33005 17.5272 7.92529 17.0283L9.61785 15.6095L13.9341 15.6103C16.4194 15.6103 18.4341 13.5956 18.4341 11.1103V7.79999C18.4341 5.31471 16.4194 3.29999 13.9341 3.29999ZM6.09961 4.29999H13.9341C15.8671 4.29999 17.4341 5.86699 17.4341 7.79999V11.1103C17.4341 13.0433 15.8671 14.6103 13.9341 14.6103H9.25353L7.28295 16.2619C7.07131 16.4393 6.75595 16.4115 6.57857 16.1998C6.5187 16.1284 6.47999 16.0417 6.46681 15.9494L6.2755 14.6103H5.84185C4.05121 14.6103 2.59961 13.1587 2.59961 11.3681V7.79999C2.59961 5.86699 4.16661 4.29999 6.09961 4.29999Z"
    fill={color}
    fillRule="evenodd"
  />
);
