import { AR_CATEGORY_VEHICLE } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseVehicleRestrictedArea = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19328)">
        <path
          d="M15.1781 9.24009C15.1781 9.24009 15.1781 9.21009 15.1781 9.20009C15.1781 9.09009 15.1681 8.97009 15.1081 8.87009L12.8881 4.90009C12.7781 4.71009 12.5781 4.59009 12.3681 4.59009H9.71813C9.38813 4.59009 9.11812 4.86009 9.11812 5.19009V5.91009H6.84812C6.51812 5.91009 6.24813 6.18009 6.24813 6.51009V8.63009H5.42812C5.09812 8.63009 4.82812 8.90009 4.82812 9.23009V13.4101C4.82812 13.4901 4.84812 13.5601 4.86812 13.6301C4.97812 14.6301 5.81813 15.4101 6.84812 15.4101C7.73812 15.4101 8.48813 14.8201 8.74813 14.0101H11.2481C11.5081 14.8201 12.2481 15.4101 13.1481 15.4101C14.1581 15.4101 14.9781 14.6601 15.1181 13.6901C15.1681 13.6101 15.1981 13.5101 15.1981 13.4101V9.23009L15.1781 9.24009ZM10.3081 5.79009H12.0081L13.5581 8.56009H10.3081V5.79009ZM7.43813 7.11009H8.88813V8.56009H7.43813V7.11009ZM13.9781 9.83009V11.6101C13.7181 11.4901 13.4381 11.4101 13.1281 11.4101C12.2381 11.4101 11.4881 12.0001 11.2281 12.8101H8.72812C8.46812 12.0001 7.72813 11.4101 6.82812 11.4101C6.53813 11.4101 6.25813 11.4801 6.00813 11.5901V9.83009H13.9681H13.9781ZM6.83813 14.2101C6.39813 14.2101 6.03813 13.8501 6.03813 13.4101C6.03813 12.9701 6.39813 12.6101 6.83813 12.6101C7.27813 12.6101 7.63812 12.9701 7.63812 13.4101C7.63812 13.8501 7.27813 14.2101 6.83813 14.2101ZM13.1281 14.2101C12.6881 14.2101 12.3281 13.8501 12.3281 13.4101C12.3281 12.9701 12.6881 12.6101 13.1281 12.6101C13.5681 12.6101 13.9281 12.9701 13.9281 13.4101C13.9281 13.8501 13.5681 14.2101 13.1281 14.2101Z"
          fill={color}
        />
        <path
          d="M16.01 3.99007C12.7 0.680068 7.3 0.680068 3.99 3.99007C2.38 5.60007 1.5 7.73007 1.5 10.0001C1.5 12.2701 2.38 14.4101 3.99 16.0101C5.6 17.6101 7.82 18.5001 10 18.5001C12.18 18.5001 14.35 17.6701 16.01 16.0101C17.67 14.3501 18.5 12.2701 18.5 10.0001C18.5 7.73007 17.62 5.60007 16.01 3.99007ZM4.7 4.70007C6.16 3.24007 8.08 2.51007 10 2.51007C11.76 2.51007 13.51 3.13007 14.91 4.35007L4.34 14.9101C3.15 13.5501 2.5 11.8201 2.5 10.0001C2.5 8.00007 3.28 6.11007 4.7 4.70007ZM15.3 15.3001C12.49 18.1101 7.99 18.2101 5.05 15.6201L15.62 5.05007C16.83 6.42007 17.5 8.16007 17.5 10.0001C17.5 12.0001 16.72 13.8901 15.3 15.3001Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19328">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
