import type { IconChildrenProps } from "../icon.types";

export const Refresh = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M9.29343 4.13838L9.30889 5.02385C9.31467 5.35517 9.05077 5.62845 8.71945 5.63423C8.62526 5.63587 8.532 5.61531 8.44723 5.57422L5.89114 4.335C5.59296 4.19044 5.46843 3.83153 5.61299 3.53335C5.66868 3.41847 5.75958 3.32434 5.87245 3.26467L8.38374 1.937C8.67669 1.78212 9.03973 1.89405 9.19461 2.187C9.23864 2.27028 9.26244 2.36277 9.26408 2.45696L9.27591 3.1348C13.1954 2.75104 16.7833 5.48579 17.4075 9.42676C18.0555 13.5179 15.2642 17.3597 11.1731 18.0077C7.08195 18.6557 3.24014 15.8644 2.59217 11.7733C2.54897 11.5005 2.73505 11.2444 3.00779 11.2012C3.28054 11.158 3.53666 11.3441 3.57985 11.6168C4.14143 15.1625 7.471 17.5816 11.0167 17.02C14.5623 16.4584 16.9814 13.1288 16.4198 9.58319C15.8746 6.14122 12.721 3.76091 9.29343 4.13838Z"
    fill={color}
    fillRule="evenodd"
  />
);
