import type { IconChildrenProps } from "../icon.types";

export const Engineering = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M15.2002 8.07327C13.7589 8.07327 12.59 9.24103 12.5886 10.6824C12.5871 12.0814 13.686 13.2247 15.0685 13.2934L15.204 13.2967C16.6468 13.2938 17.8134 12.1238 17.8119 10.6824C17.8105 9.24103 16.6416 8.07327 15.2002 8.07327ZM15.2002 9.4974C15.8556 9.4974 16.3871 10.0284 16.3878 10.6838C16.3884 11.3094 15.9051 11.8226 15.2912 11.8691L15.1964 11.8725C14.5424 11.8712 14.012 11.3392 14.0127 10.6838C14.0133 10.0284 14.5448 9.4974 15.2002 9.4974ZM16.7707 14.2471L13.6229 14.2471C12.132 14.2514 10.9261 15.4572 10.9219 16.946L10.9219 17.3337C10.9219 17.9892 11.4533 18.5207 12.1088 18.5207H18.2826C18.9382 18.5207 19.4696 17.9892 19.4696 17.3337V16.9481C19.4653 15.4572 18.2595 14.2514 16.7707 14.2471ZM16.7666 15.6712L16.8578 15.6747C17.5207 15.7232 18.0435 16.2753 18.0454 16.9501L18.0454 17.0963H12.3459L12.346 16.9481C12.348 16.2446 12.9194 15.6732 13.6249 15.6712H16.7666Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M15.9091 0C17.3091 0 18.4517 1.10153 18.5178 2.48516L18.5208 2.6117V6.41096C18.5208 6.80422 18.202 7.12302 17.8087 7.12302C17.44 7.12302 17.1368 6.84283 17.1003 6.48377L17.0966 6.41096V2.6117C17.0966 1.98563 16.6122 1.47272 15.9977 1.42738L15.9091 1.42413H2.6117C1.98565 1.42413 1.47272 1.9086 1.42738 2.52307L1.42413 2.6117V14.0094C1.42413 14.6355 1.9086 15.1484 2.52307 15.1937L2.6117 15.197H8.31059C8.70386 15.197 9.02266 15.5158 9.02266 15.9091C9.02266 16.2778 8.74246 16.581 8.3834 16.6175L8.31059 16.6211H2.6117C1.21174 16.6211 0.0690197 15.5196 0.00301176 14.136L0 14.0094V2.6117C0 1.21174 1.10155 0.0690197 2.48516 0.00301176L2.6117 0H15.9091ZM11.1603 4.74896C11.5535 4.74896 11.8723 5.06776 11.8723 5.46103C11.8723 5.82971 11.5922 6.13295 11.2331 6.16941L11.1603 6.17309H4.51163C4.11837 6.17309 3.79957 5.85429 3.79957 5.46103C3.79957 5.09234 4.07976 4.7891 4.43882 4.75264L4.51163 4.74896H11.1603ZM9.0229 9.26023C9.0229 8.86697 8.7041 8.54817 8.31083 8.54817H4.51163L4.43882 8.55185C4.07976 8.58831 3.79957 8.89155 3.79957 9.26023C3.79957 9.65349 4.11837 9.97229 4.51163 9.97229H8.31083L8.38364 9.96862C8.7427 9.93215 9.0229 9.62891 9.0229 9.26023Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
