import type { IconChildrenProps } from "../icon.types";

export const Discard = ({ color }: IconChildrenProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M17.1333 12.3176C18.4133 8.37823 16.2574 4.14708 12.318 2.86709C8.43512 1.60545 4.26877 3.68185 2.92436 7.51401H1.81888C1.72467 7.51401 1.63179 7.53619 1.54775 7.57876C1.25214 7.72851 1.13389 8.08953 1.28363 8.38514L2.56727 10.9192C2.62497 11.0331 2.71749 11.1256 2.83139 11.1833C3.127 11.3331 3.48803 11.2148 3.63777 10.9192L4.92141 8.38514C4.96398 8.3011 4.98616 8.20822 4.98616 8.11401C4.98616 7.78264 4.71753 7.51401 4.38616 7.51401H3.99425C5.27677 4.40594 8.75756 2.76168 12.009 3.81814C15.4232 4.92747 17.2916 8.59447 16.1823 12.0086C15.073 15.4228 11.4059 17.2912 7.9918 16.1819C7.72917 16.0965 7.44709 16.2403 7.36176 16.5029C7.27643 16.7655 7.42015 17.0476 7.68278 17.1329C11.6222 18.4129 15.8533 16.257 17.1333 12.3176Z"
    fill={color}
  />
);
