import type { IconChildrenProps } from "../../icon.types";

export const SolutionsChemicalsAndRelatedProducts = ({
  color,
}: IconChildrenProps) => (
  <>
    <path
      d="M9.67969 12.9364L10.9297 14.1864L9.67969 15.4364"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M7.99805 10.6106L6.74805 11.8606L5.49805 10.6106"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M6.97388 13.9656C7.44805 17.0648 8.62471 19.2631 10.0039 19.2631C11.8022 19.2631 13.2605 15.5323 13.2605 10.9298C13.2605 6.32727 11.8022 2.59644 10.0039 2.59644C8.20555 2.59644 6.74805 6.32727 6.74805 10.9298V11.8606"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M10.9302 14.1864H9.99935C5.39685 14.1864 1.66602 12.728 1.66602 10.9297C1.66602 9.13137 5.39685 7.67303 9.99935 7.67303C14.6018 7.67303 18.3327 9.13137 18.3327 10.9297C18.3327 12.3089 16.1343 13.4855 13.0352 13.9597"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
  </>
);
