import type { IconChildrenProps } from "../icon.types";

export const Info = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44366 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44366 18.25 1.75 14.5563 1.75 10ZM16.75 10C16.75 6.27209 13.7279 3.25 10 3.25C6.27208 3.25 3.25 6.27208 3.25 10C3.25 13.7279 6.27209 16.75 10 16.75C13.7279 16.75 16.75 13.7279 16.75 10ZM10 8.625C10.3797 8.625 10.6935 8.90715 10.7432 9.27323L10.75 9.375V13.75C10.75 14.1642 10.4142 14.5 10 14.5C9.6203 14.5 9.30651 14.2178 9.25685 13.8518L9.25 13.75V9.375C9.25 8.96079 9.58579 8.625 10 8.625ZM9.29688 6.5625C9.29688 6.17417 9.61167 5.85937 10 5.85937C10.3883 5.85937 10.7031 6.17417 10.7031 6.5625C10.7031 6.95083 10.3883 7.26562 10 7.26562C9.61167 7.26562 9.29688 6.95083 9.29688 6.5625Z"
    fill={color}
    fillRule="evenodd"
  />
);
