/* eslint-disable @typescript-eslint/no-explicit-any */
import { get } from "lodash-es";

let metaEnv: Record<string, string | undefined>;

export const getAppEnv = (appName: string, envKey: string) => {
  const webAppsEnv = import.meta.env.VITE_WEB_APPS_ENV;
  if (!metaEnv && webAppsEnv) {
    metaEnv = JSON.parse(webAppsEnv) as Record<string, string | undefined>;
  }
  return get(metaEnv, `${appName}.${envKey}`);
};

export const getAppMode = () => {
  const appUrl = import.meta.env.VITE_APP_URL;

  if (appUrl) {
    return getAppModeFromUrl(appUrl);
  }

  return "production";
};

export const getAppModeFromUrl = (appUrl: string) => {
  if (appUrl.includes("http://local")) {
    return "local";
  }
  if (appUrl.includes("https://stg-") || appUrl.includes("-stg-")) {
    return "staging";
  }
  return "production";
};
