import { AR_CATEGORY_BEHAVIOR } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../icon.types";

export const CategoryBehavior = ({ color }: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();

  return (
    <>
      <path
        clipRule="evenodd"
        d="M14.9233 2H5.07681C3.37751 2 2 3.37751 2 5.07681V13.2821L2.00426 13.4456C2.08921 15.0689 3.43233 16.3589 5.07681 16.3589L8.10284 16.3582L9.56507 17.8198L9.63406 17.8794C9.87488 18.0581 10.2166 18.0382 10.435 17.8198L11.8963 16.3582L14.9233 16.3589C16.6225 16.3589 18 14.9814 18 13.2821V5.07681C18 3.37753 16.6225 2 14.9233 2ZM5.07681 3.2303H14.9233C15.943 3.2303 16.7697 4.05699 16.7697 5.07681V13.2821C16.7697 14.302 15.943 15.1287 14.9233 15.1287H11.6411L11.5443 15.1363C11.417 15.1566 11.2984 15.2165 11.2061 15.3088L9.99996 16.5149L8.79397 15.3088C8.6786 15.1935 8.52214 15.1287 8.35899 15.1287H5.07681C4.05698 15.1287 3.2303 14.302 3.2303 13.2821V5.07681C3.2303 4.05698 4.05698 3.2303 5.07681 3.2303Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.0009 5.46436L10.1384 5.46847C11.3204 5.53951 12.2571 6.52067 12.2571 7.72064C12.2571 8.96669 11.247 9.97684 10.0009 9.97684C8.75487 9.97684 7.74473 8.96669 7.74473 7.72064C7.74473 6.52067 8.68142 5.53951 9.86349 5.46847L10.0009 5.46436ZM9.89604 6.69995C9.37872 6.75249 8.97503 7.1894 8.97503 7.72064C8.97503 8.28722 9.43435 8.74654 10.0009 8.74654C10.5675 8.74654 11.0268 8.28722 11.0268 7.72064C11.0268 7.1894 10.6231 6.75249 10.1058 6.69995L10.0009 6.69465L9.89604 6.69995ZM11.6737 10.7527C12.2655 10.7531 12.8353 10.9772 13.2672 11.3787C13.5246 11.6164 13.7253 11.9088 13.8548 12.2343C13.9803 12.55 13.8262 12.9077 13.5105 13.0332C13.2211 13.1483 12.8965 13.0284 12.7477 12.7644L12.7116 12.6889C12.6501 12.5343 12.5547 12.3954 12.431 12.2812C12.2545 12.1171 12.0303 12.0154 11.7929 11.9896L11.6733 11.983H8.32996C8.04825 11.9832 7.77707 12.0898 7.5705 12.2816C7.47853 12.3669 7.40166 12.4667 7.34276 12.5768L7.28995 12.6902C7.16374 13.0056 6.80572 13.159 6.4903 13.0327C6.17488 12.9065 6.02149 12.5485 6.1477 12.2331C6.2775 11.9087 6.47764 11.6173 6.73352 11.3799C7.12387 11.0174 7.62461 10.7997 8.15269 10.7595L8.32961 10.7527H11.6737Z"
        fill={getAlertCategoryColor(AR_CATEGORY_BEHAVIOR)}
        fillRule="evenodd"
      />
    </>
  );
};
