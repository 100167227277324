/* eslint-disable no-restricted-imports */
import { noop } from "lodash-es";
import StackTrace from "stacktrace-js";
import { getAppMode } from "~/utils/app-env";
import type {
  ErrorReporterOptions,
  ErrorReporterPayload,
  ErrorReporterState,
} from "./error-reporter.types";

const state: ErrorReporterState = {
  apiKey: import.meta.env.VITE_GOOGLE_CLOUD_ERROR_REPORTING_API_KEY,
  baseApiUrl: import.meta.env.VITE_GOOGLE_CLOUD_ERROR_REPORTING_URL,
  projectId: import.meta.env.VITE_GOOGLE_PROJECT_ID,
  serviceName: import.meta.env.VITE_GOOGLE_CLOUD_ERROR_REPORTING_SERVICE_NAME,
};

const logError = (error?: unknown) => {
  if (error) {
    console.error(error);
  }
};

const parseOptionsBody = (payload: ErrorReporterPayload) => {
  try {
    const body = JSON.stringify(payload);
    return body;
  } catch (e) {
    console.error(e);
  }
};

const resolveError = async (error: Error, firstFrameIndex: number) => {
  let result = "";

  try {
    const stack = await StackTrace.fromError(error);
    const lines = [error.toString()];

    for (let i = firstFrameIndex; i < stack.length; i++) {
      lines.push(
        [
          "    at ",
          stack[i].getFunctionName() || "<anonymous>",
          " (",
          stack[i].getFileName(),
          ":",
          stack[i].getLineNumber(),
          ":",
          stack[i].getColumnNumber(),
          ")",
        ].join(""),
      );
    }

    result = lines.join("\n");
  } catch (e) {
    result = [
      "Error extracting stack trace: ",
      e,
      "\n",
      error.toString(),
      "\n",
      "    (",
      "file" in error ? error.file : "",
      ":",
      "line" in error ? error.line : "",
      ":",
      "column" in error ? error.column : "",
      ")",
    ].join("");
  }

  return result;
};

const sendErrorPayload = async (payload: ErrorReporterPayload) => {
  if (state.baseApiUrl && state.projectId && state.apiKey) {
    const url = `${state.baseApiUrl}${state.projectId}/events:report?key=${state.apiKey}`;
    const options: RequestInit = {
      body: parseOptionsBody(payload),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
      method: "POST",
    };

    try {
      const request = new Request(url, options);
      await fetch(request);
    } catch (e) {
      console.error(e);
    }
  }
};

export const identify = (userId?: string) => {
  state.userId = userId;
};

export const report = async (error?: unknown) => {
  if (error instanceof DOMException && error.name === "AbortError") {
    // Do not log abort requests
    return;
  }

  logError(error);

  if (!state.disabled && error) {
    const payload: ErrorReporterPayload = {
      context: {
        httpRequest: {
          url: window.location.href,
          userAgent: window.navigator.userAgent,
        },
        user: state.userId,
      },
      serviceContext: {
        service: state.serviceName,
        version: state.serviceVersion,
      },
    };

    let nextError: unknown = error;

    let firstFrameIndex = 0;
    if (typeof error === "string") {
      try {
        throw new Error(error);
      } catch (e) {
        nextError = e;
      }
      firstFrameIndex = 1;
    }

    if (typeof nextError !== "string") {
      try {
        const message = await resolveError(nextError as Error, firstFrameIndex);
        if (message) {
          await sendErrorPayload({ ...payload, message });
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
};

export const start = ({
  apiKey,
  baseApiUrl,
  projectId,
  serviceName,
  userId,
}: ErrorReporterOptions) => {
  if (
    getAppMode() !== "local" &&
    apiKey &&
    baseApiUrl &&
    projectId &&
    serviceName
  ) {
    state.disabled = import.meta.env.MODE === "development";
    state.apiKey = apiKey;
    state.baseApiUrl = baseApiUrl;
    state.projectId = projectId;
    state.serviceName = serviceName;
    state.userId = userId;
  } else {
    state.disabled = true;
  }

  const oldErrorHandler = window.onerror ?? noop;
  window.onerror = (event, source, lineno, colno, error) => {
    if (error) {
      void report(error);
    }
    oldErrorHandler(event, source, lineno, colno, error);
    return true;
  };

  const oldUnhandledPromiseRejectionHandler =
    window.onunhandledrejection ?? noop;
  window.onunhandledrejection = (event: PromiseRejectionEvent) => {
    if (event) {
      void report(event.reason);
    }
    oldUnhandledPromiseRejectionHandler(event.reason);
    return true;
  };
};

export const stop = () => {
  state.disabled = true;
  state.userId = undefined;
};
