export const abortableFetch = (url: string, options: RequestInit) => {
  const controller = new AbortController();
  const signal = controller.signal;

  const request = new Request(url, { ...options, signal });

  return {
    abort: () => controller.abort(),
    fetch: async () => fetch(request),
  };
};
