import type { IconChildrenProps } from "../../icon.types";

export const SolutionsTextileAndApparel = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M5.41602 8.34515V17.5118C5.41602 17.9718 5.78935 18.3452 6.24935 18.3452H13.7493C14.2093 18.3452 14.5827 17.9718 14.5827 17.5118V8.34515"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M5.41536 11.6786H2.91536C2.45536 11.6786 2.08203 11.3052 2.08203 10.8452V7.02523C2.08203 6.05189 2.64703 5.16689 3.53036 4.75689L6.41703 3.41856C7.0512 3.12439 7.80203 3.25773 8.29703 3.75189L9.40953 4.86439C9.73536 5.19023 10.2629 5.19023 10.5879 4.86439L11.7004 3.75189C12.1945 3.25773 12.9454 3.12439 13.5804 3.41856L16.467 4.75689C17.3504 5.16606 17.9154 6.05106 17.9154 7.02523V10.8452C17.9154 11.3052 17.542 11.6786 17.082 11.6786H14.582"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
  </>
);
