import { createContext } from "react";
import type { UiContextValue } from "./ui.types";

export const initialUiContextValue: UiContextValue = {
  isDesktop: false,
  isMobile: false,
  isTablet: false,
  isTouchDevice: false,
};

export const UiContext = createContext(initialUiContextValue);
