import type { IconChildrenProps } from "../icon.types";

export const PlayVideo = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M9.0013 13.25L12.893 10.3334C13.118 10.1667 13.118 9.83335 12.893 9.66669L9.0013 6.75002C8.87504 6.65533 8.70612 6.6401 8.56496 6.71068C8.4238 6.78126 8.33464 6.92553 8.33464 7.08335V12.9167C8.33464 13.2584 8.7263 13.4584 9.0013 13.25ZM10.0013 1.66669C5.4013 1.66669 1.66797 5.40002 1.66797 10C1.66797 14.6 5.4013 18.3334 10.0013 18.3334C14.6013 18.3334 18.3346 14.6 18.3346 10C18.3346 5.40002 14.6013 1.66669 10.0013 1.66669ZM10.0013 16.6667C6.3263 16.6667 3.33464 13.675 3.33464 10C3.33464 6.32502 6.3263 3.33335 10.0013 3.33335C13.6763 3.33335 16.668 6.32502 16.668 10C16.668 13.675 13.6763 16.6667 10.0013 16.6667Z"
    fillRule="evenodd"
    fill={color}
  />
);
