import type { IconChildrenProps } from "../icon.types";

export const Link = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M15.8446 4.15383C17.289 5.59827 17.289 7.94016 15.8446 9.3846L14.8189 10.4102C14.564 10.6651 14.564 11.0784 14.8189 11.3333C15.0738 11.5882 15.4871 11.5882 15.742 11.3333L16.7677 10.3077C18.7219 8.35344 18.7219 5.18499 16.7677 3.23075C14.8134 1.27652 11.645 1.27652 9.69074 3.23075L8.6651 4.25639C8.4102 4.5113 8.4102 4.92457 8.6651 5.17947C8.92 5.43437 9.33328 5.43437 9.58818 5.17947L10.6138 4.15383C12.0583 2.70939 14.4001 2.70939 15.8446 4.15383Z"
      fill={color}
    />
    <path
      d="M6.71688 13.282C6.46198 13.0271 6.46198 12.6138 6.71688 12.3589L12.3579 6.71792C12.6128 6.46302 13.0261 6.46302 13.281 6.71792C13.5359 6.97282 13.5359 7.3861 13.281 7.641L7.63996 13.282C7.38506 13.5369 6.97178 13.5369 6.71688 13.282Z"
      fill={color}
    />
    <path
      d="M4.15243 10.6154C2.70799 12.0598 2.70799 14.4017 4.15243 15.8461C5.59686 17.2906 7.93876 17.2906 9.3832 15.8461L10.4088 14.8205C10.6637 14.5656 11.077 14.5656 11.3319 14.8205C11.5868 15.0754 11.5868 15.4887 11.3319 15.7436L10.3063 16.7692C8.35203 18.7235 5.18359 18.7235 3.22935 16.7692C1.27511 14.815 1.27511 11.6465 3.22935 9.6923L4.25499 8.66666C4.50989 8.41176 4.92317 8.41176 5.17807 8.66666C5.43297 8.92156 5.43297 9.33484 5.17807 9.58974L4.15243 10.6154Z"
      fill={color}
    />
  </>
);
