import type { IconChildrenProps } from "../../icon.types";

export const CategoryEmergencyAlerts2 = ({
  accentColor,
  color,
}: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M12.8627 3.04129C12.8826 2.80506 12.7315 2.58114 12.4945 2.51724L12.4056 2.50162C12.1694 2.48178 11.9455 2.63285 11.8816 2.86984L10.965 6.26928L10.9494 6.35815C10.9296 6.59438 11.0807 6.8183 11.3176 6.8822C11.5843 6.95408 11.8587 6.79622 11.9306 6.52959L12.8471 3.13016L12.8627 3.04129ZM5.57834 11.8238C5.57834 9.38181 7.55798 7.40216 10 7.40216C12.442 7.40216 14.4217 9.38181 14.4217 11.8238V14.492H15.5376C15.8138 14.492 16.0376 14.7159 16.0376 14.992V17.452H17.5568C17.833 17.452 18.0568 17.6759 18.0568 17.952C18.0568 18.1975 17.88 18.4016 17.6467 18.444L17.5568 18.452H2.4716C2.19546 18.452 1.9716 18.2282 1.9716 17.952C1.9716 17.7066 2.14847 17.5024 2.38172 17.4601L2.4716 17.452H4.04121V14.992C4.04121 14.7159 4.26506 14.492 4.54121 14.492H5.57834V11.8238ZM6.07637 15.492H5.041V17.429H15.037V15.492H13.9236L13.9217 15.492H6.07834L6.07637 15.492ZM13.4217 11.8238C13.4217 9.99708 11.9902 8.50469 10.1877 8.40723L10 8.40216C8.11027 8.40216 6.57834 9.93409 6.57834 11.8238L6.578 14.492H13.421L13.4217 11.8238ZM4.54121 11.1734C4.81735 11.1734 5.04121 11.3972 5.04121 11.6734C5.04121 11.9188 4.86433 12.123 4.63108 12.1653L4.54121 12.1734H1C0.723858 12.1734 0.5 11.9495 0.5 11.6734C0.5 11.4279 0.676875 11.2237 0.910124 11.1814L1 11.1734H4.54121ZM16.8021 5.72526C17.0202 5.5559 17.3343 5.59543 17.5036 5.81354C17.6542 6.00742 17.6397 6.27714 17.4814 6.45363L17.4154 6.51511L14.6249 8.68184C14.4067 8.8512 14.0926 8.81167 13.9233 8.59356C13.7727 8.39968 13.7872 8.12996 13.9455 7.95347L14.0116 7.89199L16.8021 5.72526ZM19.5 11.7125C19.5 11.4364 19.2761 11.2125 19 11.2125H15.4588L15.3689 11.2206C15.1357 11.2629 14.9588 11.4671 14.9588 11.7125C14.9588 11.9887 15.1827 12.2125 15.4588 12.2125H19L19.0899 12.2045C19.3231 12.1621 19.5 11.958 19.5 11.7125Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M7.22021 3.03073C7.09668 2.8284 6.84251 2.73696 6.61354 2.8254C6.35594 2.9249 6.22778 3.21438 6.32727 3.47197L7.59633 6.75757L7.63623 6.83851C7.75976 7.04084 8.01393 7.13228 8.2429 7.04384C8.50049 6.94434 8.62866 6.65486 8.52916 6.39727L7.26011 3.11167L7.22021 3.03073ZM5.70399 8.29642C5.93601 8.44616 6.0027 8.75564 5.85296 8.98766C5.71985 9.19389 5.46054 9.26951 5.24161 9.17859L5.16172 9.13662L2.19182 7.21985C1.9598 7.0701 1.89311 6.76063 2.04285 6.52861C2.17595 6.32237 2.43527 6.24676 2.6542 6.33767L2.73409 6.37964L5.70399 8.29642ZM10.0003 8.79272C10.2764 8.79272 10.5003 9.01658 10.5003 9.29272C10.5003 9.53818 10.3234 9.74233 10.0901 9.78467L9.98174 9.79272C8.95114 9.79272 8.11692 10.6218 8.11692 11.6429C8.11692 11.9191 7.89307 12.1429 7.61692 12.1429C7.34078 12.1429 7.11692 11.9191 7.11692 11.6429C7.11692 10.1244 8.31021 8.8842 9.81348 8.79756L10.0003 8.79272Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
