import type { IconChildrenProps } from "../icon.types";

export const Image = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333ZM7.08333 11.25L9.16667 13.7583L12.0833 10L15.8333 15H4.16667L7.08333 11.25Z"
    fill={color}
    fillRule="evenodd"
  />
);
