import type { IconChildrenProps } from "../icon.types";

export const File = ({ color }: IconChildrenProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M5.5 3C5.22386 3 5 3.22386 5 3.5V16.5C5 16.7761 5.22386 17 5.5 17H14.5C14.7761 17 15 16.7761 15 16.5V7.5H12C11.1716 7.5 10.5 6.82843 10.5 6V3H5.5ZM11.5 3.70711L14.2929 6.5H12C11.7239 6.5 11.5 6.27614 11.5 6V3.70711ZM4 3.5C4 2.67157 4.67157 2 5.5 2H11C11.1326 2 11.2598 2.05268 11.3536 2.14645L15.8536 6.64645C15.9473 6.74021 16 6.86739 16 7V16.5C16 17.3284 15.3284 18 14.5 18H5.5C4.67157 18 4 17.3284 4 16.5V3.5Z"
    fill={color}
  />
);
