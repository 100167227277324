import { AR_CATEGORY_VEHICLE } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseVehicleVehicleInteraction = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_3978_1939)">
        <path
          d="M6.72683 7.82C6.83683 7.93 6.98683 8 7.15683 8H7.17683C7.50683 8 7.76683 7.72 7.75683 7.39C7.78683 7.64 7.95683 7.86 8.21683 7.91C8.36683 7.94 8.50683 7.98 8.64683 8.02C8.70683 8.04 8.76683 8.05 8.82683 8.05C9.07683 8.05 9.31683 7.89 9.39683 7.63C9.49683 7.31 9.31683 6.98 9.00683 6.88C8.82683 6.82 8.65683 6.78 8.46683 6.74C8.14683 6.68 7.82683 6.88 7.75683 7.2C7.74683 7.25 7.75683 7.3 7.75683 7.35V7.2C7.75683 7.06 7.69683 6.95 7.61683 6.85C7.71683 6.76 7.78683 6.64 7.80683 6.5C7.81683 6.4 7.83683 6.3 7.85683 6.2L7.88683 6.06C7.96683 5.74 7.76683 5.41 7.44683 5.34C7.12683 5.26 6.79683 5.46 6.72683 5.78L6.68683 5.96C6.66683 6.08 6.63683 6.21 6.62683 6.33C6.61683 6.44 6.62683 6.54 6.67683 6.63C6.65683 6.63 6.63683 6.63 6.61683 6.63C6.28683 6.66 6.04683 6.95 6.07683 7.28C6.10683 7.61 6.40683 7.86 6.72683 7.82C6.72683 7.82 6.73683 7.82 6.74683 7.82H6.72683Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M7.51683 17.79C7.39683 17.79 7.27683 17.8 7.14683 17.8L7.06683 18.4L7.08683 17.8C6.74683 17.8 6.47683 18.05 6.46683 18.38C6.45683 18.71 6.71683 18.99 7.04683 19H7.16683C7.31683 19 7.46683 19 7.60683 18.98C7.93683 18.96 8.18683 18.67 8.15683 18.34C8.13683 18.01 7.81683 17.76 7.51683 17.79Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M5.32683 17.45C5.18683 17.4 5.04683 17.33 4.91683 17.27C4.62683 17.12 4.25683 17.24 4.10683 17.54C3.95683 17.84 4.07683 18.2 4.37683 18.35C4.53683 18.43 4.70683 18.51 4.88683 18.58C4.95683 18.61 5.03683 18.62 5.10683 18.62C5.34683 18.62 5.56683 18.48 5.66683 18.24C5.78683 17.93 5.63683 17.58 5.32683 17.46V17.45Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M8.13683 9.11C8.00683 8.8 7.65683 8.66 7.34683 8.79C7.04683 8.92 6.89683 9.27 7.02683 9.58C7.09683 9.75 7.17683 9.91 7.25683 10.08C7.36683 10.29 7.57683 10.4 7.78683 10.4C7.87683 10.4 7.97683 10.38 8.06683 10.33C8.35683 10.18 8.47683 9.81 8.31683 9.52C8.24683 9.39 8.18683 9.25 8.12683 9.11H8.13683Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M9.39683 12.28C9.57683 12.28 9.75683 12.2 9.87683 12.04C10.0768 11.77 10.0168 11.4 9.75683 11.2C9.63683 11.11 9.52683 11.02 9.41683 10.92C9.17683 10.7 8.79683 10.72 8.56683 10.96C8.33683 11.2 8.36683 11.58 8.60683 11.81C8.74683 11.94 8.88683 12.05 9.03683 12.16C9.14683 12.24 9.26683 12.28 9.39683 12.28Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M2.49683 10.29C2.68683 10.29 2.87683 10.2 2.99683 10.03C3.07683 9.91 3.16683 9.78 3.26683 9.67C3.47683 9.41 3.43683 9.03 3.17683 8.83C2.91683 8.62 2.54683 8.66 2.33683 8.92C2.22683 9.06 2.11683 9.21 2.00683 9.36C1.81683 9.63 1.89683 10.01 2.16683 10.19C2.26683 10.26 2.38683 10.29 2.49683 10.29Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M4.60683 8.5C4.73683 8.42 4.86683 8.35 5.00683 8.29C5.30683 8.15 5.42683 7.79 5.28683 7.49C5.14683 7.19 4.78683 7.07 4.48683 7.21C4.31683 7.29 4.15683 7.38 3.99683 7.47C3.70683 7.64 3.61683 8.01 3.78683 8.29C3.89683 8.48 4.09683 8.58 4.30683 8.58C4.40683 8.58 4.51683 8.55 4.60683 8.5Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M1.52683 12.6C1.52683 12.6 1.58683 12.6 1.60683 12.6C1.89683 12.6 2.15683 12.38 2.19683 12.08C2.21683 11.93 2.24683 11.79 2.27683 11.64C2.35683 11.32 2.15683 10.99 1.82683 10.92C1.50683 10.85 1.18683 11.04 1.10683 11.37C1.06683 11.55 1.02683 11.73 1.00683 11.91C0.95683 12.24 1.18683 12.54 1.51683 12.59L1.52683 12.6Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M2.38683 14.29C2.34683 14.15 2.30683 14.01 2.27683 13.86C2.20683 13.54 1.88683 13.33 1.56683 13.4C1.24683 13.47 1.03683 13.79 1.10683 14.11C1.14683 14.29 1.19683 14.47 1.24683 14.65C1.32683 14.91 1.56683 15.07 1.81683 15.07C1.87683 15.07 1.93683 15.07 1.99683 15.04C2.31683 14.94 2.48683 14.6 2.38683 14.29Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M3.20683 15.87C3.00683 15.61 2.62683 15.56 2.36683 15.76C2.10683 15.96 2.05683 16.34 2.25683 16.6C2.36683 16.75 2.48683 16.89 2.61683 17.02C2.73683 17.15 2.89683 17.21 3.05683 17.21C3.20683 17.21 3.34683 17.16 3.46683 17.05C3.70683 16.82 3.71683 16.44 3.49683 16.2C3.39683 16.09 3.29683 15.98 3.20683 15.86V15.87Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M11.2668 15.65C11.1768 15.77 11.0868 15.89 10.9968 16.01C10.7868 16.26 10.8168 16.64 11.0768 16.85C11.1868 16.94 11.3268 16.99 11.4568 16.99C11.6268 16.99 11.7968 16.92 11.9168 16.77C12.0368 16.63 12.1468 16.48 12.2468 16.33C12.4368 16.06 12.3668 15.68 12.0968 15.5C11.8268 15.31 11.4468 15.38 11.2668 15.65Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M9.62683 17.15C9.49683 17.22 9.36683 17.29 9.22683 17.35C8.92683 17.49 8.79683 17.84 8.92683 18.14C9.02683 18.36 9.24683 18.49 9.47683 18.49C9.55683 18.49 9.64683 18.47 9.72683 18.44C9.89683 18.36 10.0568 18.28 10.2268 18.19C10.5168 18.02 10.6168 17.66 10.4468 17.37C10.2768 17.08 9.91683 16.98 9.62683 17.15Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M12.7068 10.02C12.5568 9.72 12.1968 9.6 11.8968 9.75C11.5968 9.9 11.4768 10.26 11.6268 10.56C11.6968 10.69 11.7568 10.83 11.8068 10.97C11.8968 11.21 12.1268 11.35 12.3668 11.35C12.4368 11.35 12.5168 11.34 12.5868 11.31C12.8968 11.19 13.0468 10.84 12.9268 10.53C12.8568 10.36 12.7868 10.19 12.6968 10.02H12.7068Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M9.89683 2.98C9.99683 2.98 10.1068 2.95 10.1968 2.9C10.3268 2.82 10.4568 2.75 10.5968 2.69C10.8968 2.55 11.0168 2.19 10.8768 1.89C10.7368 1.59 10.3768 1.47 10.0768 1.61C9.90683 1.69 9.74683 1.78 9.58683 1.87C9.29683 2.04 9.20683 2.41 9.37683 2.69C9.48683 2.88 9.68683 2.98 9.89683 2.98Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M8.09683 4.69C8.28683 4.69 8.47683 4.6 8.59683 4.43C8.67683 4.31 8.76683 4.18 8.86683 4.07C9.07683 3.81 9.03683 3.43 8.77683 3.23C8.51683 3.02 8.14683 3.06 7.93683 3.32C7.82683 3.46 7.71683 3.61 7.60683 3.76C7.41683 4.03 7.49683 4.41 7.76683 4.59C7.86683 4.66 7.98683 4.69 8.09683 4.69Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M17.5568 5.79C17.5968 5.93 17.6368 6.08 17.6668 6.22C17.7268 6.51 17.9768 6.71 18.2568 6.71C18.2968 6.71 18.3368 6.71 18.3768 6.7C18.7068 6.64 18.9168 6.32 18.8468 6C18.8068 5.82 18.7668 5.64 18.7168 5.46C18.6268 5.14 18.2868 4.96 17.9668 5.05C17.6468 5.14 17.4668 5.48 17.5568 5.8V5.79Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M12.2568 2.22C12.2568 2.22 12.2968 2.22 12.3068 2.22C12.4568 2.21 12.6068 2.2 12.7568 2.2H12.9368C13.2568 2.18 13.5468 1.95 13.5568 1.62C13.5668 1.29 13.3068 1.01 12.9768 1H12.7668C12.5768 1 12.3968 1 12.2168 1.02C11.8868 1.05 11.6468 1.34 11.6768 1.67C11.7068 1.98 11.9668 2.22 12.2768 2.22H12.2568Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M18.3768 7.5C18.0568 7.45 17.7468 7.67 17.6868 8C17.6668 8.15 17.6368 8.29 17.5968 8.43C17.5168 8.75 17.7068 9.08 18.0268 9.16C18.0768 9.17 18.1268 9.18 18.1768 9.18C18.4468 9.18 18.6868 9 18.7568 8.73C18.8068 8.55 18.8368 8.37 18.8668 8.19C18.9168 7.86 18.6968 7.56 18.3668 7.5H18.3768Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M13.1168 12.19C13.1168 12.19 13.0368 12.19 13.0068 12.19C12.9268 12.16 12.8468 12.15 12.7568 12.15C12.5268 12.15 12.3268 12.31 12.2268 12.52C12.1668 12.32 11.9968 12.15 11.7768 12.11C11.6268 12.08 11.4868 12.05 11.3468 12C11.0268 11.91 10.6968 12.09 10.5968 12.41C10.5068 12.73 10.6868 13.06 11.0068 13.16C11.1868 13.21 11.3668 13.26 11.5468 13.29C11.5868 13.29 11.6268 13.3 11.6668 13.3C11.8868 13.3 12.0868 13.17 12.1968 12.98C12.2268 13.07 12.2668 13.16 12.3368 13.23C12.2168 13.32 12.1268 13.45 12.1068 13.61C12.0868 13.76 12.0568 13.9 12.0168 14.05C11.9368 14.37 12.1268 14.7 12.4468 14.78C12.4968 14.79 12.5468 14.8 12.5968 14.8C12.8668 14.8 13.1068 14.62 13.1768 14.35C13.2268 14.17 13.2568 13.99 13.2868 13.81C13.3068 13.66 13.2668 13.52 13.1968 13.41C13.5268 13.39 13.7768 13.1 13.7568 12.77C13.7368 12.44 13.4568 12.18 13.1168 12.21V12.19Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M15.0768 2.77C15.1668 2.82 15.2568 2.84 15.3568 2.84C15.5768 2.84 15.7868 2.72 15.8868 2.52C16.0368 2.23 15.9268 1.86 15.6368 1.71C15.4768 1.63 15.3068 1.55 15.1368 1.48C14.8368 1.35 14.4768 1.49 14.3468 1.8C14.2168 2.11 14.3568 2.46 14.6668 2.59C14.8068 2.65 14.9468 2.71 15.0768 2.78V2.77Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M16.8668 10.05C16.7768 10.17 16.6868 10.29 16.5968 10.41C16.3868 10.66 16.4168 11.04 16.6768 11.25C16.7868 11.34 16.9268 11.39 17.0568 11.39C17.2268 11.39 17.3968 11.32 17.5168 11.17C17.6368 11.03 17.7468 10.88 17.8468 10.73C18.0368 10.46 17.9668 10.08 17.6968 9.9C17.4268 9.71 17.0468 9.78 16.8668 10.05Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M11.4068 9.11C11.6368 8.87 11.6168 8.49 11.3768 8.26C11.2368 8.13 11.0968 8.01 10.9568 7.9C10.6968 7.7 10.3168 7.74 10.1168 8.01C9.91683 8.27 9.95683 8.65 10.2268 8.85C10.3468 8.94 10.4568 9.04 10.5668 9.14C10.6868 9.25 10.8268 9.3 10.9768 9.3C11.1368 9.3 11.2968 9.24 11.4168 9.11H11.4068Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M15.2368 11.55C15.1068 11.62 14.9768 11.69 14.8368 11.75C14.5368 11.89 14.4068 12.24 14.5368 12.54C14.6368 12.76 14.8568 12.89 15.0868 12.89C15.1668 12.89 15.2568 12.87 15.3368 12.84C15.5068 12.76 15.6668 12.68 15.8368 12.59C16.1268 12.42 16.2268 12.06 16.0568 11.77C15.8868 11.48 15.5268 11.38 15.2368 11.55Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M16.7568 4.2C16.8768 4.36 17.0568 4.44 17.2368 4.44C17.3668 4.44 17.4868 4.4 17.5968 4.32C17.8568 4.12 17.9168 3.74 17.7168 3.48C17.6068 3.33 17.4868 3.19 17.3668 3.05C17.1468 2.8 16.7668 2.78 16.5168 3.01C16.2668 3.23 16.2568 3.61 16.4768 3.86C16.5768 3.97 16.6668 4.09 16.7568 4.2Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
        <path
          d="M16.2972 6.69996C16.2972 6.63996 16.2772 6.57996 16.2572 6.51996C16.2572 6.50996 16.2572 6.49996 16.2572 6.48996C16.2472 6.45996 16.2572 6.41996 16.2272 6.38996L14.8272 3.88996C14.7172 3.69996 14.5172 3.57996 14.3072 3.57996H12.6372C12.3072 3.57996 12.0372 3.84996 12.0372 4.17996V4.40996H10.8272C10.4972 4.40996 10.2272 4.67996 10.2272 5.00996V6.12996H9.93719C9.65719 6.12996 9.42719 6.32996 9.36719 6.59996C9.88719 6.78996 10.3772 7.02996 10.8272 7.32996H15.1072V7.91996C15.0072 7.89996 14.9072 7.88996 14.7972 7.88996C14.1972 7.88996 13.6772 8.24996 13.4472 8.76996H12.3672C12.6572 9.13996 12.9072 9.53996 13.1072 9.96996H13.4472C13.6772 10.49 14.1972 10.85 14.7972 10.85C15.5272 10.85 16.1372 10.32 16.2472 9.61996C16.2772 9.53996 16.3072 9.45996 16.3072 9.36996V6.71996L16.2972 6.69996ZM11.8972 6.07996H11.4272V5.60996H11.8972V6.07996ZM13.2372 6.07996V4.77996H13.9572L14.6872 6.07996H13.2372ZM14.5172 9.35996C14.5172 9.19996 14.6472 9.07996 14.7972 9.07996C14.9472 9.07996 15.0772 9.20996 15.0772 9.35996C15.0772 9.66996 14.5172 9.66996 14.5172 9.35996Z"
          fill={color}
        />
        <path
          d="M9.50391 12.94V13.5C9.40391 13.48 9.31391 13.47 9.21391 13.47C8.61391 13.47 8.09391 13.83 7.86391 14.35H6.60391C6.37391 13.83 5.85391 13.47 5.25391 13.47C5.14391 13.47 5.04391 13.48 4.94391 13.5V12.91H9.48391C8.06391 12.09 6.96391 10.76 6.46391 9.15997H5.74391C5.52391 9.15997 5.32391 9.27997 5.22391 9.46997L3.82391 11.97C3.82391 11.97 3.80391 12.04 3.79391 12.08C3.79391 12.08 3.79391 12.1 3.79391 12.11C3.77391 12.17 3.75391 12.23 3.75391 12.3V14.95C3.75391 15.04 3.77391 15.12 3.81391 15.2C3.93391 15.9 4.53391 16.43 5.27391 16.43C5.87391 16.43 6.39391 16.07 6.62391 15.55H7.88391C8.11391 16.07 8.63391 16.43 9.23391 16.43C10.0039 16.43 10.6339 15.84 10.7039 15.09C10.7139 15.05 10.7339 15 10.7339 14.95V13.47C10.3139 13.33 9.91391 13.15 9.53391 12.94H9.50391ZM6.08391 10.37H6.80391V11.67H5.36391L6.09391 10.37H6.08391ZM4.96391 14.95C4.96391 14.8 5.09391 14.67 5.24391 14.67C5.39391 14.67 5.52391 14.8 5.52391 14.95C5.52391 15.26 4.96391 15.26 4.96391 14.95ZM9.20391 15.23C9.04391 15.23 8.92391 15.1 8.92391 14.95C8.92391 14.8 9.05391 14.67 9.20391 14.67C9.35391 14.67 9.48391 14.8 9.48391 14.95C9.48391 15.1 9.35391 15.23 9.20391 15.23Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19330">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
