import type { IconChildrenProps } from "../../icon.types";

export const SolutionsWiresAndCables = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M13.3338 14.4427H7.11849C6.65825 14.4427 6.28516 14.0696 6.28516 13.6093V11.526"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      d="M13.691 10.276H7.56836"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <ellipse
      cx="6.32031"
      cy="10.276"
      rx="1.25"
      ry="1.25"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      d="M6.28516 9.02598V6.94265C6.28516 6.48241 6.65825 6.10931 7.11849 6.10931H13.3338"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <rect
      height="15"
      rx="3.53209"
      width="15"
      x="2.5"
      y="2.776"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
  </>
);
