import type { IconChildrenProps } from "../icon.types";

export const Archive = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <rect
      height="15.0062"
      rx="3.53357"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
      width="15.0062"
      x="2.49805"
      y="2.49683"
    />
    <path
      clipRule="evenodd"
      d="M12.4652 10.2199H11.612V7.53665C11.612 7.24149 11.3705 7 11.0753 7H8.92872C8.63357 7 8.39207 7.24149 8.39207 7.53665V10.2199H7.5388C7.06118 10.2199 6.81969 10.7995 7.15778 11.1376L9.621 13.6008C9.83029 13.8101 10.1684 13.8101 10.3777 13.6008L12.8409 11.1376C13.179 10.7995 12.9429 10.2199 12.4652 10.2199Z"
      fillRule="evenodd"
      stroke={accentColor}
    />
  </>
);
