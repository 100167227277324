import { clsx } from "clsx";
import {
  type CSSProperties,
  type RefObject,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
  useLayoutEffect,
  useImperativeHandle,
  type ForwardedRef,
  type ChangeEventHandler,
} from "react";
import {
  type MenuListProps,
  type OptionsOrGroups,
  type PropsValue,
  components,
  type OnChangeValue,
  type Options,
} from "react-select";
import { useBoolean } from "~/hooks/use-boolean";
import { useDimensions } from "~/hooks/use-dimensions";
import { useIntersectionObserver } from "~/hooks/use-intersection-observer";
import { sortCompare } from "~/utils/sort-compare";
// eslint-disable-next-line import/no-deprecated
import { DEPRECATED_BUTTON } from "../button-deprecated";
import { Icon } from "../icon";
import { getScrollParent } from "../scroll-area";
import type {
  SelectBaseGroup,
  SelectBaseOption,
  SelectProps,
  SelectRef,
} from "./select.types";
import css from "./select.module.css";

type UseSelectUtilsProps<
  Option extends SelectBaseOption = SelectBaseOption,
  IsMulti extends boolean = false,
> = Pick<
  SelectProps<Option, IsMulti>,
  | "checkbox"
  | "classNames"
  | "enableClearAll"
  | "icon"
  | "isMulti"
  | "label"
  | "labels"
  | "onChange"
  | "options"
  | "placeholder"
  | "shape"
  | "sortFn"
  | "value"
> & {
  labelNode: HTMLSpanElement | null;
  ref: ForwardedRef<SelectRef>;
  selectRef: RefObject<HTMLDivElement>;
};

const isMultiValues = <Option extends SelectBaseOption = SelectBaseOption>(
  value?: PropsValue<Option>,
): value is Option[] => Boolean(value && Array.isArray(value));

const isOptionsNotSelectBaseGroup = <
  Option extends SelectBaseOption = SelectBaseOption,
>(
  options?: OptionsOrGroups<Option, SelectBaseGroup<Option>>,
): options is Option[] => Boolean(options?.[0] && "value" in options[0]);

export const useSelectUtils = <
  Option extends SelectBaseOption = SelectBaseOption,
  IsMulti extends boolean = false,
>(
  props: UseSelectUtilsProps<Option, IsMulti>,
) => {
  const {
    checkbox,
    classNames,
    enableClearAll,
    icon,
    isMulti,
    label,
    labelNode,
    labels,
    onChange,
    options,
    placeholder,
    ref,
    selectRef,
    shape = "contained",
    sortFn,
    value,
  } = props;

  const [menuListRef, setMenuListRef] = useState<HTMLDivElement | null>(null);
  const [selectPortal, setSelectPortal] = useState<HTMLDivElement>();
  const [isCheckedInternal, setIsCheckedInternal] = useState(() => {
    if (
      checkbox &&
      typeof checkbox === "object" &&
      "defaultValue" in checkbox
    ) {
      return checkbox.defaultValue;
    }

    return false;
  });

  const [isChecked, setIsChecked] = useMemo(() => {
    if (checkbox && typeof checkbox === "object" && "isChecked" in checkbox) {
      return [checkbox.isChecked, checkbox.onCheckboxChange];
    }

    return [isCheckedInternal, setIsCheckedInternal];
  }, [checkbox, isCheckedInternal]);

  const stopEventsOnContainer = Boolean(checkbox && !isChecked);

  const labelDimensions = useDimensions(labelNode);
  const prevAutoOptions = useRef(false);

  const [selectOptions, setSelectOptions] = useState<
    OptionsOrGroups<Option, SelectBaseGroup<Option>> | undefined
  >();

  const {
    value: isMenuOpen,
    setTrue: setIsMenuOpenTrue,
    setFalse: setIsMenuOpenFalse,
  } = useBoolean(false);

  const selectEntry = useIntersectionObserver(selectRef, {
    root: selectRef.current ? getScrollParent(selectRef.current) : undefined,
    threshold: 0.5,
  });

  const rootStyle = useMemo(() => {
    const isRounded = shape === "rounded";
    let nextValuePaddingLeft = isRounded ? 32 : 16;

    if (icon) {
      nextValuePaddingLeft += isRounded ? 40 : 28;
    }
    if (label && labelDimensions?.width) {
      nextValuePaddingLeft +=
        labelDimensions.width + (shape === "rounded" ? 20 : 8);
    }
    if (checkbox) {
      nextValuePaddingLeft += shape === "rounded" ? 40 : 24;
    }

    return {
      "--value-padding-left": `${Math.round(nextValuePaddingLeft)}px`,
    } as CSSProperties;
  }, [checkbox, icon, label, labelDimensions?.width, shape]);

  /**
   * If the menu list is scrollable, then we want options to be autosorted
   */
  const shouldAutoSortOptions = useMemo(() => {
    if (menuListRef?.scrollHeight !== undefined) {
      prevAutoOptions.current =
        menuListRef.scrollHeight > menuListRef.clientHeight;
      return prevAutoOptions.current;
    }
    return prevAutoOptions.current;
  }, [menuListRef?.clientHeight, menuListRef?.scrollHeight]);

  const { applyPlaceholderStyles, computedPlaceholder } = useMemo(() => {
    if (isMulti && isMultiValues(value)) {
      if (value.length === 1) {
        return {
          applyPlaceholderStyles: true,
          computedPlaceholder: value[0].label,
        };
      } else if (value.length > 1) {
        return {
          applyPlaceholderStyles: true,
          computedPlaceholder: `${labels?.selected ?? "Selected"}: ${
            value.length
          }`,
        };
      }
    }

    return {
      applyPlaceholderStyles: false,
      computedPlaceholder: placeholder ?? "Select",
    };
  }, [isMulti, labels?.selected, placeholder, value]);

  const menuListRenderer = useCallback(
    ({ children, ...other }: MenuListProps<Option, IsMulti>) => (
      <components.MenuList {...other} innerRef={setMenuListRef}>
        {children}
      </components.MenuList>
    ),
    [],
  );

  const shouldShowClearAll = useMemo(() => {
    if (isMulti && isMultiValues(value)) {
      return enableClearAll && value.length;
    } else {
      return enableClearAll && value;
    }
  }, [enableClearAll, isMulti, value]);

  const clearAll = useCallback(() => {
    onChange?.(
      (isMulti ? [] : null) as unknown as OnChangeValue<Option, IsMulti>,
      {
        action: "clear",
        removedValues: value as Options<Option>,
      },
    );
  }, [isMulti, onChange, value]);

  const clearBtnRenderer = useMemo(() => {
    if (shouldShowClearAll) {
      return (
        // eslint-disable-next-line deprecation/deprecation, import/no-deprecated
        <DEPRECATED_BUTTON
          classNames={{
            button: css.clearAllBtnInnerBtn,
            root: css.clearAllBtn,
          }}
          onClick={clearAll}
          noStyles
        >
          <Icon
            name="cross"
            size={shape === "rounded" ? "size-10" : "size-6"}
          />
        </DEPRECATED_BUTTON>
      );
    }

    return null;
  }, [clearAll, shape, shouldShowClearAll]);

  const onCheckboxChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setIsChecked(event.target.checked);
    },
    [setIsChecked],
  );

  const sortOptions = useCallback(
    (internalOptions: Option[]) => {
      if (isMulti && isMultiValues(value)) {
        if (shouldAutoSortOptions) {
          const selectedValues = value.map((v) => v.value);

          return [
            ...[...value].sort(sortFn ?? sortCompare("asc", (v) => v.label)),
            ...internalOptions
              .filter((o) => !selectedValues.includes(o.value))
              .sort(sortFn ?? sortCompare("asc", (o) => o.label)),
          ];
        }

        return internalOptions.sort(
          sortFn ?? sortCompare("asc", (o) => o.label),
        );
      } else {
        if (value && shouldAutoSortOptions) {
          const tempValue = Array.isArray(value)
            ? (value[0] as Option | undefined)
            : (value as Option);

          if (tempValue) {
            const nextOptions = internalOptions.filter(
              (o) => o.value !== tempValue.value,
            );

            const sortedOptions = sortFn
              ? [...nextOptions].sort(sortFn)
              : nextOptions;

            return [tempValue, ...sortedOptions];
          }
        }

        return sortFn ? internalOptions.sort(sortFn) : internalOptions;
      }
    },
    [isMulti, shouldAutoSortOptions, sortFn, value],
  );

  useImperativeHandle<SelectRef | null, SelectRef | null>(ref, () =>
    selectRef.current
      ? {
          customFunctions: { clearAll },
          selectRef: selectRef.current,
        }
      : null,
  );

  useLayoutEffect(() => {
    const root = document.createElement("div");
    const container = document.createElement("div");
    root.className = css[shape];
    container.className = clsx(
      css.container,
      css.menuContainer,
      classNames?.container,
    );
    root.appendChild(container);
    document.body.appendChild(root);
    setSelectPortal(container);
    return () => {
      if (root) {
        root.remove();
      }
    };
  }, [classNames, shape]);

  useEffect(() => {
    if (
      isMenuOpen &&
      selectEntry &&
      !selectEntry.isIntersecting &&
      selectEntry.boundingClientRect.top <= (selectEntry.rootBounds?.top ?? 0)
    ) {
      setIsMenuOpenFalse();
    }
  }, [isMenuOpen, selectEntry, setIsMenuOpenFalse]);

  useEffect(() => {
    if (!isMenuOpen) {
      if (isOptionsNotSelectBaseGroup(options)) {
        setSelectOptions(sortOptions(options));
      } else {
        setSelectOptions(options);
      }
    }
  }, [isMenuOpen, options, sortOptions]);

  /**
   * Only fires in cases when shouldAutoSortOptions changes from false to true
   * Useful because we cannot make a decision based on the initial value of shouldAutoSortOptions
   * on the first render since it will always be false.
   *
   * If we shouldAutoSortOption remains false, then we don't need to do anything
   * But if it changes from false to true, then we need to sort the options to bring possible selected options to the top
   */
  useEffect(() => {
    if (shouldAutoSortOptions) {
      if (isOptionsNotSelectBaseGroup(options)) {
        setSelectOptions(sortOptions(options));
      } else {
        setSelectOptions(options);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run when shouldAutoSortOptions changes from false to true
  }, [shouldAutoSortOptions]);

  return {
    applyPlaceholderStyles,
    clearBtnRenderer,
    computedPlaceholder,
    isChecked,
    isMenuOpen,
    menuListRenderer,
    onCheckboxChange,
    rootStyle,
    selectOptions,
    selectPortal,
    setIsMenuOpenFalse,
    setIsMenuOpenTrue,
    shouldShowClearAll,
    stopEventsOnContainer,
  };
};
