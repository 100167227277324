import type { IconChildrenProps } from "../icon.types";

export const It = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M9.40935 4.51481C9.12433 4.2891 8.70909 4.30791 8.44577 4.57123L8.38935 4.63457C8.16365 4.91959 8.18246 5.33483 8.44577 5.59815L9.86894 7.02138L8.44578 8.44553L8.38936 8.50886C8.16365 8.79388 8.18245 9.20912 8.44577 9.47244C8.72934 9.75602 9.1891 9.75603 9.47268 9.47246L11.4099 7.53534L11.4663 7.472C11.692 7.18698 11.6732 6.77174 11.4099 6.50841L9.47269 4.57123L9.40935 4.51481ZM6.56741 4.5713C6.28384 4.28772 5.82408 4.28771 5.5405 4.57128L3.60331 6.50841L3.54689 6.57174C3.32118 6.85676 3.33998 7.27201 3.60331 7.53533L5.54049 9.47251L5.60382 9.52894C5.88885 9.75464 6.30408 9.73583 6.5674 9.47251L6.62383 9.40918C6.84953 9.12416 6.83072 8.70892 6.5674 8.4456L5.14338 7.02199L6.56739 5.59822L6.62382 5.53488C6.84953 5.24986 6.83073 4.83462 6.56741 4.5713Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12.3492 0H2.66332C1.19243 0 0 1.19243 0 2.66332V11.3806C0 12.8515 1.19241 14.0439 2.66332 14.0439H2.90571V16.2235L2.90878 16.3525C2.97609 17.7635 4.14136 18.8867 5.56903 18.8867H16.2235L16.3525 18.8837C17.7635 18.8164 18.8868 17.6511 18.8868 16.2235V7.50616L18.8837 7.37712C18.8164 5.96616 17.6511 4.84283 16.2235 4.84283H15.0126V2.66332C15.0126 1.19243 13.8201 0 12.3492 0ZM13.5603 5.55552C13.5602 5.56 13.5602 5.56448 13.5602 5.56897C13.5602 5.57347 13.5602 5.57795 13.5603 5.58242V11.3806C13.5603 12.0494 13.0181 12.5916 12.3492 12.5916H3.64136C3.6382 12.5916 3.63503 12.5916 3.63185 12.5916C3.62868 12.5916 3.6255 12.5916 3.62234 12.5916H2.66332C1.99448 12.5916 1.45228 12.0494 1.45228 11.3806V2.66332C1.45228 1.9945 1.9945 1.45228 2.66332 1.45228H12.3492C13.0181 1.45228 13.5603 1.9945 13.5603 2.66332V5.55552ZM4.35799 14.0439H12.3492C13.8201 14.0439 15.0126 12.8515 15.0126 11.3806V6.29511H16.2235L16.3139 6.29843C16.9405 6.34467 17.4345 6.86774 17.4345 7.50616V16.2235L17.4312 16.3139C17.385 16.9404 16.8619 17.4345 16.2235 17.4345H5.56903L5.47865 17.4311C4.85199 17.3849 4.35799 16.8619 4.35799 16.2235V14.0439Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
