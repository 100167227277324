import { Flexbox } from "~/components/flexbox";
import { LinkButton } from "~/components/link-button";
import { Text } from "~/components/text";
import { useUi } from "~/features/ui/ui.hooks";
import css from "./invalid-invitation.module.css";

const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL as string;

export const InvalidInvitation = () => {
  const { isMobile } = useUi();

  return (
    <div className={css.root}>
      <div>
        <div>
          <Text
            color="beige-20"
            fontSize={isMobile ? "text-3xl" : "text-4xl"}
            fontWeight="text-semibold"
          >
            This link is no longer active.
          </Text>
        </div>
      </div>
      <div>
        <div className={css.subWrapper}>
          <Text color="beige-20" fontSize={isMobile ? "text-2xl" : "text-3xl"}>
            Please reach out to our customer support team so we can help you
            resolve this issue as soon as possible!
          </Text>
        </div>
      </div>
      <div className={css.buttonWrapper}>
        <LinkButton
          buttonStyleProps={{
            hierarchy: "primary",
            shape: "contained",
            variant: "error",
          }}
          className={css.button}
          fullWidth
          to={`mailto:${supportEmail}`}
        >
          Contact support
        </LinkButton>
      </div>
      <div>
        <Flexbox direction="column">
          <Text
            align="center"
            color="red-20"
            fontSize={isMobile ? "text-2xl" : "text-3xl"}
          >
            <a className={css.link} href="/login">
              Sign in to the dashboard
            </a>
          </Text>
          <Text
            align="center"
            color="beige-20"
            fontSize={isMobile ? "text-2xl" : "text-3xl"}
          >
            if you already have an account.
          </Text>
        </Flexbox>
      </div>
      <div>
        <div>
          <div>
            <Text color="beige-20" fontSize={isMobile ? "text-xl" : "text-2xl"}>
              or send us an email at
            </Text>
          </div>
          <div>
            <Text color="red-20" fontSize={isMobile ? "text-xl" : "text-2xl"}>
              <a className={css.link} href={`mailto:${supportEmail}`}>
                {supportEmail}
              </a>
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
