import type { IconChildrenProps } from "../../icon.types";

export const SolutionsRetail = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M5 17.9526H3.33333C2.8731 17.9526 2.5 17.5795 2.5 17.1193V12.1193C2.5 11.659 2.8731 11.2859 3.33333 11.2859H5C5.46024 11.2859 5.83333 11.659 5.83333 12.1193V17.1193C5.83333 17.5795 5.46024 17.9526 5 17.9526Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      d="M11.2507 3.78595V7.61928"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      d="M16.6673 12.1193V8.81932C16.665 8.15623 16.1271 7.61978 15.464 7.61932H7.03732C6.37422 7.61978 5.83628 8.15623 5.83398 8.81932V13.036"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      d="M5.83398 12.1193V7.09513C5.83416 6.77006 5.89992 6.44836 6.02732 6.1493L6.68648 4.60763C6.89946 4.1091 7.38937 3.78577 7.93148 3.78596H14.569C15.1128 3.78421 15.6049 4.10773 15.819 4.60763L16.4781 6.15096C16.6043 6.45027 16.6687 6.77199 16.6673 7.0968V12.5235"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      d="M10.834 14.6194H12.7782C13.1388 14.6194 13.4897 14.5024 13.7782 14.2861L15.7915 12.7761C16.2941 12.3993 16.9973 12.4494 17.4415 12.8936V12.8936C17.6783 13.1302 17.8114 13.4513 17.8114 13.7861C17.8114 14.1209 17.6783 14.4419 17.4415 14.6786L15.714 16.4061C15.2487 16.8714 14.656 17.1887 14.0107 17.3177L11.569 17.8061C11.0849 17.9028 10.5854 17.8909 10.1065 17.7711L7.89815 17.2194C7.63414 17.1529 7.36291 17.1193 7.09065 17.1194H5.83398"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      d="M10.834 14.6193H12.0715C12.7687 14.6193 13.334 14.054 13.334 13.3568V13.1068C13.334 12.5277 12.9399 12.023 12.3782 11.8826L10.4682 11.4051C10.1578 11.3268 9.83905 11.2868 9.51898 11.2859V11.2859C8.74629 11.2857 7.99086 11.5145 7.34815 11.9434L5.83398 12.9526"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
  </>
);
