import type { IconChildrenProps } from "../icon.types";

export const Audit = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M9.50391 12.6542C9.50391 10.636 11.1399 9 13.1581 9C15.1763 9 16.8123 10.636 16.8123 12.6542C16.8123 13.4851 16.535 14.2511 16.068 14.865L17.4588 16.2558C17.6516 16.4486 17.6516 16.7612 17.4588 16.9539C17.2789 17.1339 16.9947 17.1459 16.8008 16.9899L16.7607 16.9539L15.37 15.5632C14.756 16.0308 13.9895 16.3084 13.1581 16.3084C11.1399 16.3084 9.50391 14.6724 9.50391 12.6542ZM15.825 12.6542C15.825 11.1813 14.631 9.98727 13.1581 9.98727C11.6852 9.98727 10.4912 11.1813 10.4912 12.6542C10.4912 14.1271 11.6852 15.3211 13.1581 15.3211C14.631 15.3211 15.825 14.1271 15.825 12.6542Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      d="M8.10478 2.90889C7.84335 2.90889 7.63141 3.12082 7.63141 3.38226L7.62549 3.58486C7.62549 3.8463 7.83743 4.05824 8.09886 4.05824H11.8858C12.1473 4.05824 12.3592 3.8463 12.3592 3.58486L12.3651 3.38226C12.3651 3.12082 12.1532 2.90889 11.8918 2.90889H11.1628C10.9118 2.90889 10.7083 2.70543 10.7083 2.45444C10.7083 2.20346 10.5049 2 10.2539 2H9.74266C9.49168 2 9.28821 2.20346 9.28821 2.45444C9.28821 2.70543 9.08475 2.90889 8.83377 2.90889H8.10478Z"
      fill={color}
    />
    <path
      d="M6.13768 7.11244C5.77167 7.11244 5.47496 7.40915 5.47496 7.77516C5.47496 8.14117 5.77167 8.43788 6.13768 8.43788H13.0992C13.4652 8.43788 13.7619 8.14117 13.7619 7.77516C13.7619 7.40915 13.4652 7.11244 13.0992 7.11244H6.13768Z"
      fill={color}
    />
    <path
      d="M3.99805 4C3.99805 3.72386 4.2219 3.5 4.49805 3.5H5.99805V5C5.99805 5.27614 6.2219 5.5 6.49805 5.5H13.498C13.7742 5.5 13.998 5.27614 13.998 5V3.5H15.498C15.7742 3.5 15.998 3.72386 15.998 4V8C15.998 8.27614 16.2219 8.5 16.498 8.5C16.7742 8.5 16.998 8.27614 16.998 8V4C16.998 3.17157 16.3265 2.5 15.498 2.5H13.498C13.2219 2.5 12.998 2.72386 12.998 3V4.5H6.99805V3C6.99805 2.72386 6.77419 2.5 6.49805 2.5H4.49805C3.66962 2.5 2.99805 3.17157 2.99805 4V15.5C2.99805 16.8807 4.11733 18 5.49805 18H14.748C15.0242 18 15.248 17.7761 15.248 17.5C15.248 17.2239 15.0242 17 14.748 17H5.49805C4.66962 17 3.99805 16.3284 3.99805 15.5V4Z"
      fill={color}
    />
    <path
      d="M5.47461 10.7751C5.47461 10.4091 5.77132 10.1124 6.13733 10.1124H8.09886C8.46488 10.1124 8.76159 10.4091 8.76159 10.7751C8.76159 11.1412 8.46487 11.4379 8.09886 11.4379H6.13733C5.77132 11.4379 5.47461 11.1412 5.47461 10.7751Z"
      fill={color}
    />
    <path
      d="M6.13733 13.1124C5.77132 13.1124 5.47461 13.4091 5.47461 13.7751C5.47461 14.1412 5.77132 14.4379 6.13733 14.4379H8.09886C8.46487 14.4379 8.76159 14.1412 8.76159 13.7751C8.76159 13.4091 8.46488 13.1124 8.09886 13.1124H6.13733Z"
      fill={color}
    />
  </>
);
