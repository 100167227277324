import type { IconChildrenProps } from "../../icon.types";

export const Light = ({ color }: IconChildrenProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 6.75C8.21984 6.75 6.75 8.22815 6.75 10.0849C6.75 11.2853 7.36681 12.3325 8.28712 12.9196C8.8155 13.2567 9.02117 13.7962 9.12317 14.2193C9.18787 14.4877 9.23185 14.825 9.26816 15.1034C9.28428 15.2271 9.29889 15.3391 9.31313 15.4284C9.37238 15.7998 9.44362 15.9951 9.52588 16.1C9.56603 16.1512 9.65395 16.25 10 16.25C10.3461 16.25 10.434 16.1512 10.4741 16.1C10.5564 15.9951 10.6276 15.7998 10.6869 15.4284C10.7011 15.3391 10.7157 15.2271 10.7318 15.1034C10.7682 14.825 10.8121 14.4877 10.8768 14.2193C10.9788 13.7962 11.1845 13.2567 11.7129 12.9196C12.6332 12.3325 13.25 11.2853 13.25 10.0849C13.25 8.22815 11.7802 6.75 10 6.75ZM5.25 10.0849C5.25 7.42957 7.36188 5.25 10 5.25C12.6381 5.25 14.75 7.42957 14.75 10.0849C14.75 11.8094 13.8617 13.328 12.5196 14.1842C12.4824 14.2079 12.4052 14.2798 12.335 14.5709C12.2875 14.7682 12.2645 14.9506 12.2368 15.1702C12.2187 15.3141 12.1986 15.4741 12.1681 15.6647C12.1043 16.0651 11.9908 16.5966 11.6546 17.0254C11.2763 17.508 10.7121 17.75 10 17.75C9.28794 17.75 8.72367 17.508 8.34538 17.0254C8.00919 16.5966 7.89574 16.0651 7.83186 15.6647C7.80145 15.4741 7.7813 15.3141 7.76316 15.1702C7.7355 14.9506 7.71252 14.7682 7.66495 14.5709C7.59477 14.2798 7.51763 14.2079 7.48041 14.1842C6.13829 13.328 5.25 11.8094 5.25 10.0849Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 9.75C12.1381 9.75 12.25 9.86193 12.25 10C12.25 10.9286 11.8096 11.7448 11.1421 12.2057C11.0285 12.2842 10.8728 12.2557 10.7943 12.142C10.7158 12.0284 10.7444 11.8727 10.858 11.7943C11.3844 11.4308 11.75 10.7716 11.75 10C11.75 9.86193 11.862 9.75 12 9.75Z"
      fill={color}
    />
    <path
      d="M9.6992 2.24448C9.72966 2.1019 9.85563 2 10.0014 2C10.1472 2 10.2732 2.1019 10.3037 2.24448L10.5947 3.60656C10.6754 3.98412 10.3875 4.34 10.0014 4.34C9.61535 4.34 9.32748 3.98412 9.40815 3.60656L9.6992 2.24448Z"
      fill={color}
    />
    <path
      d="M17.7557 9.30466C17.8983 9.33513 18.0002 9.4611 18.0002 9.6069C18.0002 9.75271 17.8983 9.87868 17.7557 9.90914L16.3936 10.2002C16.016 10.2809 15.6602 9.99299 15.6602 9.6069C15.6602 9.22081 16.016 8.93295 16.3936 9.01362L17.7557 9.30466Z"
      fill={color}
    />
    <path
      d="M2.24448 9.90914C2.1019 9.87868 2 9.75271 2 9.6069C2 9.4611 2.1019 9.33513 2.24448 9.30466L3.60656 9.01362C3.98412 8.93295 4.34 9.22081 4.34 9.6069C4.34 9.99299 3.98412 10.2809 3.60656 10.2002L2.24448 9.90914Z"
      fill={color}
    />
    <path
      d="M15.6874 4.28631C15.8103 4.20649 15.9723 4.22283 16.0768 4.32561C16.1808 4.42779 16.2003 4.58821 16.124 4.71237L15.4581 5.79553C15.2563 6.12378 14.8017 6.17923 14.5269 5.9091C14.2535 5.64025 14.2992 5.18734 14.6208 4.97855L15.6874 4.28631Z"
      fill={color}
    />
    <path
      d="M4.5104 4.13947C4.38909 4.05823 4.2274 4.07337 4.12329 4.17572C4.01964 4.27762 4.00138 4.43813 4.07949 4.5607L4.85163 5.77232C5.05981 6.09898 5.51602 6.15088 5.79224 5.87932C6.06747 5.60875 6.02485 5.15369 5.70417 4.93893L4.5104 4.13947Z"
      fill={color}
    />
  </>
);
