import type { IconChildrenProps } from "../icon.types";

export const Search = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M9.25281 3.0704C5.83723 3.0704 3.06836 5.83928 3.06836 9.25485C3.06836 12.6704 5.83723 15.4393 9.25281 15.4393C10.7476 15.4393 12.1185 14.909 13.1876 14.0263L15.917 16.7557L15.9993 16.8244C16.2307 16.9847 16.5506 16.9618 16.7567 16.7557C16.9886 16.5238 16.9886 16.1479 16.7567 15.916L14.027 13.1863C14.9081 12.1176 15.4373 10.748 15.4373 9.25485C15.4373 5.83928 12.6684 3.0704 9.25281 3.0704ZM9.25281 4.2579C12.0125 4.2579 14.2498 6.49511 14.2498 9.25485C14.2498 12.0146 12.0125 14.2518 9.25281 14.2518C6.49307 14.2518 4.25586 12.0146 4.25586 9.25485C4.25586 6.49511 6.49307 4.2579 9.25281 4.2579Z"
    fill={color}
    fillRule="evenodd"
  />
);
