import { clsx } from "clsx";
import { useCallback, useMemo, type MouseEventHandler } from "react";
import type { ThemeSizeName } from "~/features/theme/theme.types";
import { Flexbox } from "../flexbox";
import { Icon, type IconName } from "../icon";
import { PressableWrapper } from "../pressable-wrapper";
import { Spinner } from "../spinner";
import type { ButtonProps } from "./button.types";
import css from "./button.module.css";

export const Button = (props: ButtonProps) => {
  const {
    children,
    className,
    classNames,
    disabled,
    fullWidth,
    hierarchy,
    isAuthButton,
    leftIcon,
    leftIconProps,
    loading = false,
    onPress,
    rightIcon,
    rightIconProps,
    shape,
    style,
    text,
    type = "button",
    variant,
    ...other
  } = props;

  // When we're implementing buttons we might use this
  // but now we're not sure of the usecase so we're commenting out
  // const [isPressed, setIsPressed] = useState<boolean>();

  const buttonShapeVariant = useMemo(() => {
    if (shape && variant) {
      return `${shape}-${variant}`;
    } else if (shape) {
      return shape;
    } else if (variant) {
      return variant;
    } else {
      return undefined;
    }
  }, [shape, variant]);

  let rootSpinnerClassName = "rightSpinner";

  if (!leftIcon && !rightIcon) {
    rootSpinnerClassName = "coverSpinner";
  } else if (leftIcon && !rightIcon) {
    rootSpinnerClassName = "leftSpinner";
  }

  const isNoAccent = useMemo(
    () =>
      hierarchy === "secondary" ||
      !(variant === "contrast" || variant === "inverted"),
    [hierarchy, variant],
  );

  const iconSize: ThemeSizeName = useMemo(() => {
    if (hierarchy === "tertiary" || hierarchy === "text") {
      return "size-8";
    }
    return "size-10";
  }, [hierarchy]);

  // Pressing enter on forms triggers both form submit and button click
  // This is for preventing double requests on auth pages
  const preventDefaultForAuthButton = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >((e) => {
    e.preventDefault();
  }, []);

  // const onPressChange = useCallback((value: boolean) => {
  //   setIsPressed(value);
  // }, []);

  return (
    <PressableWrapper
      isDisabled={disabled || loading}
      fullWidth={fullWidth}
      onPress={onPress}
      // onPressChange={onPressChange}
      {...other}
    >
      <button
        className={clsx(
          css.commonButton,
          className,
          css[hierarchy],
          buttonShapeVariant && css[buttonShapeVariant],
          css[rootSpinnerClassName],
          loading && clsx(css.loading, classNames?.loading),
          fullWidth && css.fullWidth,
          disabled && css.disabled,
        )}
        disabled={disabled || loading}
        style={style}
        type={type}
        onClick={isAuthButton ? preventDefaultForAuthButton : undefined}
      >
        {leftIcon ? (
          <div className={clsx(css.leftIcon, classNames?.leftIcon)}>
            {Array.isArray(leftIcon) && leftIcon?.length > 0 ? (
              <Flexbox direction="row" alignItems="center" gap="4px">
                {leftIcon?.map((name: IconName, index) => (
                  <Icon
                    data-testid="button-left-icon"
                    key={name}
                    inheritColor
                    name={name}
                    noAccent={isNoAccent}
                    size={iconSize}
                    {...(Array.isArray(leftIconProps)
                      ? leftIconProps[index]
                      : leftIconProps)}
                  />
                ))}
              </Flexbox>
            ) : (
              <Icon
                data-testid="button-left-icon"
                inheritColor
                name={leftIcon as IconName}
                noAccent={isNoAccent}
                size={iconSize}
                {...(!Array.isArray(leftIconProps) ? leftIconProps : undefined)}
              />
            )}
          </div>
        ) : null}
        <div className={clsx(css.children, classNames?.children)}>
          {text ?? children}
        </div>
        {rightIcon ? (
          <div className={clsx(css.rightIcon, classNames?.rightIcon)}>
            <Icon
              data-testid="button-right-icon"
              inheritColor
              name={rightIcon}
              noAccent={isNoAccent}
              size={iconSize}
              {...rightIconProps}
            />
          </div>
        ) : null}
        {loading ? (
          <div className={clsx(css.spinner, classNames?.spinner)}>
            <Spinner size={iconSize === "size-8" ? "small" : "medium"} />
          </div>
        ) : null}
      </button>
    </PressableWrapper>
  );
};

Button.displayName = "Button";
