type Order = "asc" | "desc";

export const innerSortCompare = (order: Order, a: unknown, b: unknown) => {
  if (typeof a === "number" && typeof b === "number") {
    return order === "asc" ? a - b : b - a;
  }
  if (typeof a === "string" && typeof b === "string") {
    return order === "asc" ? a.localeCompare(b) : b.localeCompare(a);
  }
  return 0;
};

export const sortCompare =
  <T>(order: Order, selector?: (item: T) => number | string | undefined) =>
  (a: T, b: T) => {
    if (selector) {
      return innerSortCompare(order, selector(a), selector(b));
    }
    return innerSortCompare(order, a, b);
  };
