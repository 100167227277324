import type { IconChildrenProps } from "../icon.types";

export const Tag = ({ color }: IconChildrenProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.977378 13.5918L6.40881 19.0233C7.03506 19.648 7.88353 19.9988 8.7682 19.9988C9.65271 19.9988 10.5011 19.648 11.1274 19.0233L19.0223 11.1303C19.7116 10.442 20.0651 9.48656 19.9901 8.51544L19.6014 3.4701C19.544 2.67462 19.2022 1.92646 18.6383 1.36259C18.0745 0.798567 17.3265 0.456614 16.531 0.399226L11.4856 0.0109958C10.5145 -0.0634258 9.55926 0.289802 8.87029 0.978298L0.977354 8.87123C0.351577 9.49689 0 10.3455 0 11.2303C0 12.1151 0.351553 12.9637 0.977354 13.5894L0.977378 13.5918ZM1.62006 9.5166L9.513 1.62133C9.96759 1.16643 10.5841 0.910773 11.2271 0.910461C11.2893 0.910461 11.3516 0.910461 11.414 0.917766L16.4593 1.306V1.30584C17.0382 1.34724 17.5829 1.59576 17.9935 2.00592C18.4041 2.41605 18.6533 2.9606 18.6951 3.53951L19.0834 8.58484C19.1381 9.29131 18.8811 9.98652 18.3797 10.4875L10.4867 18.3808C10.0311 18.8353 9.41379 19.0905 8.77033 19.0905C8.12686 19.0905 7.50962 18.8353 7.05394 18.3808L1.62017 12.9471C1.16496 12.4918 0.909147 11.8744 0.909147 11.2307C0.909147 10.5869 1.16497 9.96949 1.62017 9.51428L1.62006 9.5166Z"
      fill={color}
    />
    <path
      d="M13.6359 8.18261C14.2388 8.18261 14.8168 7.94322 15.243 7.51693C15.6692 7.09079 15.9087 6.51281 15.9087 5.90998C15.9087 5.30715 15.6692 4.72914 15.243 4.30303C14.8168 3.87677 14.2388 3.63736 13.6359 3.63736C13.0333 3.63736 12.4551 3.87675 12.029 4.30303C11.6027 4.72918 11.3633 5.30715 11.3633 5.90998C11.3641 6.5125 11.6038 7.0902 12.0297 7.51619C12.4559 7.94234 13.0334 8.18202 13.6359 8.18261ZM13.6359 4.54638C13.9977 4.54638 14.3445 4.69004 14.6002 4.94572C14.8559 5.20155 14.9995 5.54839 14.9995 5.90998C14.9995 6.27157 14.8559 6.61843 14.6002 6.87425C14.3445 7.12992 13.9977 7.27359 13.6359 7.27359C13.2743 7.27359 12.9275 7.12992 12.6718 6.87425C12.416 6.61842 12.2723 6.27157 12.2723 5.90998C12.2723 5.54839 12.416 5.20153 12.6718 4.94572C12.9275 4.69004 13.2743 4.54638 13.6359 4.54638Z"
      fill={color}
    />
  </svg>
);
