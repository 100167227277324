import type { IconChildrenProps } from "../icon.types";

export const Kpi = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M16.5689 0.749908H3.43013C1.94993 0.749908 0.75 1.94983 0.75 3.43004V12.7749C0.75 14.2551 1.94993 15.455 3.43013 15.455H6.24252L5.51483 18.3658L5.49772 18.461C5.45919 18.8094 5.68309 19.1413 6.03256 19.2287C6.41379 19.324 6.80011 19.0922 6.89542 18.7109L7.7094 15.455H12.2906L13.1046 18.7109L13.1343 18.803C13.2642 19.1285 13.618 19.316 13.9674 19.2287C14.3487 19.1334 14.5805 18.747 14.4852 18.3658L13.7575 15.455H16.5699C18.0503 15.455 19.25 14.2549 19.25 12.774V3.43004C19.25 1.94963 18.0499 0.749908 16.5689 0.749908ZM12.8621 14.032H16.5699C17.2642 14.032 17.8269 13.4691 17.8269 12.774V3.43004C17.8269 2.73568 17.264 2.17299 16.5689 2.17299H3.43013C2.73587 2.17299 2.17308 2.73578 2.17308 3.43004V12.7749C2.17308 13.4692 2.73587 14.032 3.43013 14.032H7.13791C7.14815 14.0317 7.15842 14.0317 7.16871 14.032H12.8313C12.8416 14.0317 12.8519 14.0317 12.8621 14.032ZM5.73077 7.64709C6.09099 7.64709 6.3887 7.91477 6.43581 8.26207L6.44231 8.35863V10.9486C6.44231 11.3416 6.12374 11.6602 5.73077 11.6602C5.37054 11.6602 5.07284 11.3925 5.02573 11.0452L5.01923 10.9486V8.35863C5.01923 7.96565 5.3378 7.64709 5.73077 7.64709ZM9.28197 5.15977C9.23485 4.81246 8.93715 4.54478 8.57692 4.54478C8.18395 4.54478 7.86538 4.86335 7.86538 5.25632V10.9486L7.87188 11.0452C7.919 11.3925 8.2167 11.6602 8.57692 11.6602C8.96989 11.6602 9.28846 11.3416 9.28846 10.9486V5.25632L9.28197 5.15977ZM11.4231 7.08734C11.7833 7.08734 12.081 7.35503 12.1281 7.70233L12.1346 7.79888V10.9486C12.1346 11.3416 11.816 11.6602 11.4231 11.6602C11.0629 11.6602 10.7651 11.3925 10.718 11.0452L10.7115 10.9486V7.79888C10.7115 7.40591 11.0301 7.08734 11.4231 7.08734ZM14.9743 5.68156C14.9272 5.33426 14.6295 5.06658 14.2692 5.06658C13.8763 5.06658 13.5577 5.38514 13.5577 5.77811V10.9486L13.5642 11.0452C13.6113 11.3925 13.909 11.6602 14.2692 11.6602C14.6622 11.6602 14.9808 11.3416 14.9808 10.9486V5.77811L14.9743 5.68156Z"
    fill={color}
    fillRule="evenodd"
  />
);
