import { clsx } from "clsx";
import { useLocation, matchPath } from "react-router-dom";
import { Logo } from "~/components/logo";
import css from "./header.module.css";

export const Header = () => {
  const location = useLocation();

  const isSignUpPage = matchPath("signup/:id", location.pathname);

  return (
    <header className={clsx(css.header, isSignUpPage && css.signUp)}>
      <Logo className={css.logo} height={46} text variant="light" width={185} />
    </header>
  );
};
