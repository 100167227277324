import type { IconChildrenProps } from "../icon.types";

export const Template2 = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      d="M8.10605 2.90889C7.84462 2.90889 7.63268 3.12082 7.63268 3.38226V3.79883C7.63268 4.06026 7.84462 4.2722 8.10605 4.2722H11.893C12.1545 4.2722 12.3664 4.06026 12.3664 3.79883V3.38226C12.3664 3.12082 12.1545 2.90889 11.893 2.90889H11.164C10.9131 2.90889 10.7096 2.70543 10.7096 2.45444C10.7096 2.20346 10.5061 2 10.2552 2H9.74393C9.49294 2 9.28948 2.20346 9.28948 2.45444C9.28948 2.70543 9.08602 2.90889 8.83504 2.90889H8.10605Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8989 13.2663C10.5329 13.2663 10.2362 13.563 10.2362 13.929V15.4438C10.2362 15.8098 10.5329 16.1065 10.8989 16.1065H13.8605C14.2265 16.1065 14.5232 15.8098 14.5232 15.4438V13.929C14.5232 13.563 14.2265 13.2663 13.8605 13.2663H10.8989ZM11.183 15.1598V14.213H13.5765V15.1598H11.183Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2362 7.77516C10.2362 7.40915 10.5329 7.11244 10.8989 7.11244H13.8605C14.2265 7.11244 14.5232 7.40915 14.5232 7.77516V11.1302C14.5232 11.4962 14.2265 11.7929 13.8605 11.7929H10.8989C10.5329 11.7929 10.2362 11.4962 10.2362 11.1302V7.77516ZM11.183 10.8462H13.5765V8.05919H11.183V10.8462Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.1386 11.3728C5.77259 11.3728 5.47588 11.6695 5.47588 12.0355V15.4438C5.47588 15.8098 5.77259 16.1065 6.1386 16.1065H8.10013C8.46614 16.1065 8.76285 15.8098 8.76285 15.4438V12.0355C8.76285 11.6695 8.46614 11.3728 8.10013 11.3728H6.1386ZM6.42262 15.1598V12.3195H7.81611V15.1598H6.42262Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.3457 4.27221C3.3457 3.4879 3.98151 2.85209 4.76582 2.85209H6.68594C6.94737 2.85209 7.15931 3.06403 7.15931 3.32546V4.74558H12.8398V3.32546C12.8398 3.06403 13.0517 2.85209 13.3131 2.85209H15.2333C16.0176 2.85209 16.6534 3.4879 16.6534 4.27221V15.6331C16.6534 16.9403 15.5937 18 14.2865 18H5.71256C3.90538 18 3.3457 16.9403 3.3457 15.6331V4.27221ZM4.76582 3.79884C4.50438 3.79884 4.29245 4.01077 4.29245 4.27221V15.6331C4.29245 16.4174 4.92826 17.0533 5.71256 17.0533H14.2865C15.0708 17.0533 15.7066 16.4174 15.7066 15.6331V4.27221C15.7066 4.01077 15.4947 3.79884 15.2333 3.79884H13.7865V5.21895C13.7865 5.48039 13.5746 5.69233 13.3131 5.69233H6.68594C6.4245 5.69233 6.21256 5.48039 6.21256 5.21895V3.79884H4.76582Z"
      fill="#121216"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.13928 7.11243C5.77327 7.11243 5.47656 7.40914 5.47656 7.77515V9.23668C5.47656 9.60269 5.77327 9.8994 6.13928 9.8994H8.10082C8.46683 9.8994 8.76354 9.60269 8.76354 9.23668V7.77515C8.76354 7.40914 8.46683 7.11243 8.10082 7.11243H6.13928ZM6.42331 8.95266V8.05917H7.8168V8.95266H6.42331Z"
      fill={accentColor}
    />
  </>
);
