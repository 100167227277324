import { AR_CATEGORY_ANOMALY } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseCleanVehicleRoads = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19312)">
        <path
          d="M16.5297 15.4199H12.3697C12.6797 15.1199 12.8897 14.7199 12.9497 14.2699C12.9997 14.1899 13.0297 14.0899 13.0297 13.9899V9.80991C13.0297 9.80991 13.0297 9.77991 13.0297 9.76991C13.0297 9.65991 13.0197 9.53991 12.9597 9.43991L10.7397 5.46991C10.6297 5.27991 10.4297 5.15991 10.2197 5.15991H7.56969C7.23969 5.15991 6.96969 5.42991 6.96969 5.75991V6.47991H4.69969C4.36969 6.47991 4.09969 6.74991 4.09969 7.07991V9.19991H3.27969C2.94969 9.19991 2.67969 9.46991 2.67969 9.79991V13.9799C2.67969 14.0599 2.69969 14.1299 2.71969 14.1999C2.82969 15.1999 3.66969 15.9799 4.69969 15.9799C5.58969 15.9799 6.33969 15.3899 6.59969 14.5799H9.09969C9.19969 14.8999 9.36969 15.1799 9.59969 15.4099H9.04969C8.81969 15.4499 8.63969 15.6499 8.63969 15.8899C8.63969 16.1599 8.85969 16.3699 9.11969 16.3699H16.6097C16.8397 16.3299 17.0197 16.1299 17.0197 15.8899C17.0197 15.6199 16.7997 15.4099 16.5397 15.4099L16.5297 15.4199ZM10.1797 13.9899C10.1797 13.5499 10.5397 13.1899 10.9797 13.1899C11.4197 13.1899 11.7797 13.5499 11.7797 13.9899C11.7797 14.4299 11.4197 14.7899 10.9797 14.7899C10.5397 14.7899 10.1797 14.4299 10.1797 13.9899ZM9.83969 6.36991L11.3897 9.13991H8.13969V6.36991H9.83969ZM5.27969 7.68991H6.72969V9.13991H5.27969V7.68991ZM11.8197 10.4099V12.1799C11.5597 12.0599 11.2797 11.9799 10.9697 11.9799C10.0797 11.9799 9.32969 12.5699 9.06969 13.3799H6.56969C6.30969 12.5699 5.56969 11.9799 4.66969 11.9799C4.37969 11.9799 4.09969 12.0499 3.84969 12.1599V10.3999H11.8097L11.8197 10.4099ZM4.67969 14.7799C4.23969 14.7799 3.87969 14.4199 3.87969 13.9799C3.87969 13.5399 4.23969 13.1799 4.67969 13.1799C5.11969 13.1799 5.47969 13.5399 5.47969 13.9799C5.47969 14.4199 5.11969 14.7799 4.67969 14.7799Z"
          fill={color}
        />
        <path
          d="M3.47969 17.6399C3.24969 17.6799 3.06969 17.8799 3.06969 18.1199C3.06969 18.3899 3.28969 18.5999 3.54969 18.5999H5.84969C6.07969 18.5599 6.25969 18.3599 6.25969 18.1199C6.25969 17.8499 6.03969 17.6399 5.77969 17.6399H3.47969Z"
          fill={color}
        />
        <path
          d="M16.2297 17.6399H7.25969C7.02969 17.6799 6.84969 17.8799 6.84969 18.1199C6.84969 18.3899 7.06969 18.5999 7.32969 18.5999H16.2997C16.5297 18.5599 16.7097 18.3599 16.7097 18.1199C16.7097 17.8499 16.4897 17.6399 16.2297 17.6399Z"
          fill={color}
        />
        <path
          d="M14.2594 11.76C13.7094 11.76 13.2694 12.2 13.2694 12.75V15.27C13.2694 15.49 13.4494 15.67 13.6694 15.67H16.3694C16.5894 15.67 16.7694 15.49 16.7694 15.27V12.75C16.7694 12.2 16.3194 11.76 15.7794 11.76H14.2594ZM15.9594 14.87H14.0594V12.75C14.0594 12.64 14.1494 12.56 14.2494 12.56H15.7594C15.8694 12.56 15.9494 12.65 15.9494 12.75V14.87H15.9594Z"
          fill={getAlertCategoryColor(AR_CATEGORY_ANOMALY)}
        />
        <path
          d="M15.0194 3.84999C14.8294 3.84999 14.6694 4.00999 14.6694 4.19999V5.48999C14.7094 5.64999 14.8494 5.77999 15.0194 5.77999C15.2094 5.77999 15.3694 5.61999 15.3694 5.42999V4.13999C15.3294 3.97999 15.1894 3.84999 15.0194 3.84999Z"
          fill={getAlertCategoryColor(AR_CATEGORY_ANOMALY)}
        />
        <path
          d="M17.5994 6.01999L15.8694 2.97999C15.6194 2.53999 15.0794 2.36999 14.6194 2.56999L14.5294 2.61999C14.3794 2.70999 14.2494 2.82999 14.1594 2.98999L12.4294 6.02999C12.1594 6.49999 12.3294 7.09999 12.7994 7.36999C12.9494 7.44999 13.1194 7.49999 13.2894 7.49999H16.7494C17.2894 7.49999 17.7294 7.05999 17.7294 6.51999C17.7294 6.34999 17.6894 6.17999 17.5994 6.02999V6.01999ZM16.7494 6.77999H13.2894C13.2894 6.77999 13.1894 6.76999 13.1494 6.73999C13.0194 6.65999 12.9694 6.48999 13.0494 6.35999L14.7794 3.31999C14.7794 3.31999 14.8394 3.23999 14.8794 3.21999C15.0094 3.13999 15.1794 3.18999 15.2594 3.31999L16.9894 6.35999C16.9894 6.35999 17.0294 6.44999 17.0294 6.49999C17.0294 6.64999 16.9094 6.77999 16.7494 6.77999Z"
          fill={getAlertCategoryColor(AR_CATEGORY_ANOMALY)}
        />
        <path
          d="M15.0094 6.51999C15.1806 6.51999 15.3194 6.3812 15.3194 6.20999C15.3194 6.03878 15.1806 5.89999 15.0094 5.89999C14.8382 5.89999 14.6994 6.03878 14.6994 6.20999C14.6994 6.3812 14.8382 6.51999 15.0094 6.51999Z"
          fill={getAlertCategoryColor(AR_CATEGORY_ANOMALY)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19312">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
