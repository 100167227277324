import { endpoints } from "~/backend/endpoints";
import { AuthTenant } from "~/features/auth/auth.types";
import { report } from "~/features/error-reporter";
import { getHttp, HttpRequestError } from "~/utils/http";
import type {
  CheckRecaptchaReturnType,
  GetUserInfoReturnType,
  UserInfo,
} from "./login-form.types";

const recaptchaKey = import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY;
const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL as string;

export const emailPasswordConnection = "Username-Password-Authentication";

export const checkRecaptcha = async (): Promise<CheckRecaptchaReturnType> =>
  new Promise((resolve) => {
    const grecaptcha = window.grecaptcha;

    if (grecaptcha && recaptchaKey) {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(recaptchaKey, { action: "submit" })
          .then((token?: string) => {
            if (token) {
              resolve([undefined, token]);
            } else {
              resolve(["Something went wrong."]);
            }
          })
          .catch(() => {
            resolve(["Something went wrong."]);
          });
      });
    }
  });

export const getUserInfo = async (
  email: string,
  token: string,
): Promise<GetUserInfoReturnType> => {
  try {
    const response = await getHttp().post<{
      data: {
        connection: string;
        region: "EU" | "US";
      };
    }>(endpoints.session.users, {
      email,
      token,
    });

    const data: UserInfo = {
      connection: response.data.data.connection,
      tenant:
        response.data.data.region === "EU" ? AuthTenant.eu : AuthTenant.us,
    };

    return [undefined, data];
  } catch (e) {
    void report(e);
    if (e instanceof HttpRequestError) {
      try {
        switch (e.data?.errorCode) {
          case "SIGNUP_NOT_COMPLETED":
            return [
              `If you have received an invitation email, please use the link in the email. Otherwise, please contact our customer support team via ${supportEmail}.`,
            ];
          default:
            return [
              `The email address you entered is not registered. Please contact our customer support team via ${supportEmail}.`,
            ];
        }
      } catch {
        // Couldn't parse response, returns error below
      }
    }

    return ["Something went wrong."];
  }
};
