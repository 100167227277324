import { AR_CATEGORY_ERGONOMICS } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../icon.types";
import { Ergonomics } from "../ergonomics";

export const CategoryErgonomics = (props: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();

  return (
    <Ergonomics
      {...props}
      accentColor={getAlertCategoryColor(AR_CATEGORY_ERGONOMICS)}
    />
  );
};
