import type { IconChildrenProps } from "../icon.types";

export const CheckboxIndeterminate = ({
  color,
  oppositeColor,
}: IconChildrenProps) => (
  <>
    <rect
      height="16"
      rx="3"
      stroke={color}
      strokeLinejoin="round"
      width="16"
      x="2"
      y="2"
    />
    <rect fill={color} height="16" rx="3" width="16" x="2" y="2" />
    <rect
      fill={oppositeColor}
      height="1.2"
      rx="0.6"
      width="10.4"
      x="4.80078"
      y="9.39999"
    />
  </>
);
