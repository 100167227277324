import type { IconChildrenProps } from "../icon.types";

export const Dislike = ({ color }: IconChildrenProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3 3.99991C3 3.44762 3.44772 2.99991 4 2.99991H5C5.55228 2.99991 6 3.44762 6 3.99991V9.99991C6 10.5522 5.55228 10.9999 5 10.9999H4C3.44772 10.9999 3 10.5522 3 9.99991V3.99991ZM4 1.99991C2.89543 1.99991 2 2.89534 2 3.99991V9.99991C2 11.1045 2.89543 11.9999 4 11.9999H5C6.10457 11.9999 7 11.1045 7 9.99991V3.99991C7 2.89534 6.10457 1.99991 5 1.99991H4ZM9.01953 3.99991C9.01953 3.44605 9.46567 2.99991 10.0195 2.99991H14.5195C15.9034 2.99991 17.0195 4.11605 17.0195 5.49991V9.99991C17.0195 10.5538 16.5734 10.9999 16.0195 10.9999H12.6695C12.4908 10.9999 12.3256 11.0953 12.2364 11.2502C12.1471 11.405 12.1473 11.5957 12.2368 11.7504L13.885 14.5973L13.8858 14.5987C14.1595 15.0809 13.9995 15.6853 13.5198 15.9667C13.0384 16.2393 12.4356 16.08 12.1539 15.6022L9.20891 10.2912C9.08511 10.0579 9.01953 9.80935 9.01953 9.55991V3.99991ZM10.0195 1.99991C8.91339 1.99991 8.01953 2.89377 8.01953 3.99991V9.55991C8.01953 9.98926 8.13325 10.3995 8.3283 10.7652L11.2822 16.0924L11.2876 16.1018C11.8466 17.06 13.0602 17.3793 14.017 16.8344L14.0215 16.8318C14.9796 16.2729 15.2989 15.0593 14.754 14.1025L13.5368 11.9999H16.0195C17.1257 11.9999 18.0195 11.1061 18.0195 9.99991V5.49991C18.0195 3.56377 16.4557 1.99991 14.5195 1.99991H10.0195Z"
    fill={color}
  />
);
