import type { IconChildrenProps } from "../icon.types";

export const EHSReport = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      d="M5.83948 11.3291C5.83948 11.0423 6.0665 10.8098 6.34653 10.8098H12.8395C13.1195 10.8098 13.3465 11.0423 13.3465 11.3291C13.3465 11.6159 13.1195 11.8484 12.8395 11.8484H6.34653C6.0665 11.8484 5.83948 11.6159 5.83948 11.3291Z"
      fill={color}
    />
    <path
      d="M6.34653 13.719C6.0665 13.719 5.83948 13.9515 5.83948 14.2383C5.83948 14.5251 6.0665 14.7576 6.34653 14.7576H9.59301C9.87304 14.7576 10.1001 14.5251 10.1001 14.2383C10.1001 13.9515 9.87304 13.719 9.59301 13.719H6.34653Z"
      fill={color}
    />
    <path
      clipRule="evenodd"
      d="M3 4.68166C3 3.47676 3.95376 2.5 5.13029 2.5H14.8697C16.0462 2.5 17 3.47676 17 4.68166V16.7345C17 17.7095 16.2282 18.5 15.276 18.5H5.13029C3.95376 18.5 3 17.5232 3 16.3183V4.68166ZM5.13029 3.53856C4.51383 3.53856 4.0141 4.05034 4.0141 4.68166V16.3183C4.0141 16.9497 4.51383 17.4614 5.13029 17.4614H15.276C15.6681 17.4614 15.9859 17.136 15.9859 16.7345V4.68166C15.9859 4.05034 15.4862 3.53856 14.8697 3.53856H5.13029Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      d="M12.0292 4.58026C11.7491 4.58026 11.5221 4.81275 11.5221 5.09954C11.5221 5.38633 11.7491 5.61882 12.0292 5.61882H12.677L9.94992 7.73086L8.48349 6.17242C8.29777 5.97505 7.99423 5.9595 7.7902 6.13691L5.61639 8.02708C5.40291 8.2127 5.37679 8.54039 5.55804 8.75901C5.73928 8.97763 6.05926 9.00439 6.27274 8.81877L8.08363 7.24417L9.53184 8.78325C9.70995 8.97253 9.99807 8.99566 10.2028 8.8371L13.5503 6.24456V7.17665C13.5503 7.46344 13.7773 7.69593 14.0574 7.69593C14.3374 7.69593 14.5644 7.46344 14.5644 7.17665V5.09954C14.5644 4.81275 14.3374 4.58026 14.0574 4.58026H12.0292Z"
      fill={accentColor}
    />
  </>
);
