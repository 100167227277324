import { AR_CATEGORY_AREA_CONTROLS } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../icon.types";

export const CategoryAreaControls = ({ color }: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();

  return (
    <>
      <path
        clipRule="evenodd"
        d="M9.58981 11.026C11.5156 11.026 13.0768 12.5871 13.0768 14.513C13.0768 16.4388 11.5156 18 9.58981 18C7.66398 18 6.10279 16.4388 6.10279 14.513C6.10279 12.5871 7.66398 11.026 9.58981 11.026ZM9.58981 12.2562C8.34345 12.2562 7.33308 13.2666 7.33308 14.513C7.33308 15.7593 8.34345 16.7697 9.58981 16.7697C10.8362 16.7697 11.8465 15.7593 11.8465 14.513C11.8465 13.2666 10.8362 12.2562 9.58981 12.2562ZM16.1525 13.4876C16.4251 13.4872 16.6866 13.5953 16.879 13.7879C17.0441 13.953 17.1471 14.1686 17.1729 14.3985L17.1791 14.5094C17.1787 15.0448 16.7751 15.4809 16.2583 15.5331L16.1535 15.5384C15.5875 15.5382 15.1287 15.0794 15.1285 14.5134C15.1283 13.9474 15.5867 13.4882 16.1525 13.4876ZM12.05 2C12.3223 1.99959 12.5836 2.10768 12.7767 2.30057C12.9416 2.46569 13.0446 2.68126 13.0704 2.91101L13.0765 3.02426C13.076 3.55737 12.6725 3.99332 12.1558 4.04557L12.051 4.05082C11.485 4.05061 11.0262 3.59181 11.026 3.0258C11.0258 2.45984 11.4841 2.00082 12.05 2ZM3.43535 11.4362C3.70761 11.436 3.96885 11.544 4.16138 11.7365C4.32653 11.9017 4.42952 12.1173 4.45531 12.3472L4.46113 12.4581C4.46113 12.9935 4.05752 13.4296 3.54052 13.4818L3.43569 13.4871C2.86981 13.4867 2.4111 13.028 2.41089 12.4621C2.41069 11.8961 2.86899 11.437 3.43535 11.4362ZM5.89729 2.00003C3.74488 2.00003 2 3.74491 2 5.89732C2 8.04974 3.74488 9.79462 5.89729 9.79462C8.04971 9.79462 9.79459 8.04974 9.79459 5.89732C9.79459 3.74491 8.04971 2.00003 5.89729 2.00003ZM5.89729 3.23032C7.37024 3.23032 8.5643 4.42438 8.5643 5.89732C8.5643 7.37027 7.37024 8.56432 5.89729 8.56432C4.42435 8.56432 3.23029 7.37027 3.23029 5.89732C3.23029 4.42438 4.42435 3.23032 5.89729 3.23032Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M17.9992 8.35897C17.9992 6.65972 16.6217 5.28221 14.9225 5.28221C13.2232 5.28221 11.8457 6.65972 11.8457 8.35897C11.8457 10.0582 13.2232 11.4357 14.9225 11.4357C16.6217 11.4357 17.9992 10.0582 17.9992 8.35897ZM13.076 8.35897C13.076 7.33919 13.9027 6.5125 14.9225 6.5125C15.9422 6.5125 16.7689 7.33919 16.7689 8.35897C16.7689 9.37874 15.9422 10.2054 14.9225 10.2054C13.9027 10.2054 13.076 9.37874 13.076 8.35897Z"
        fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        fillRule="evenodd"
      />
    </>
  );
};
