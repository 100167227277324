import type { IconChildrenProps } from "../icon.types";

export const Equal = ({ color }: IconChildrenProps) => (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 8.44444C6 8.19898 6.19898 8 6.44444 8H13.5556C13.801 8 14 8.19898 14 8.44444C14 8.6899 13.801 8.88889 13.5556 8.88889H6.44444C6.19898 8.88889 6 8.6899 6 8.44444Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11.3333C6 11.0879 6.19898 10.8889 6.44444 10.8889H13.5556C13.801 10.8889 14 11.0879 14 11.3333C14 11.5788 13.801 11.7778 13.5556 11.7778H6.44444C6.19898 11.7778 6 11.5788 6 11.3333Z"
      fill={color}
    />
  </g>
);
