import type { IconChildrenProps } from "../icon.types";

export const ObservationRatio = ({ color }: IconChildrenProps) => (
  <svg fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7692 2.25H5.23077C3.58442 2.25 2.25 3.58442 2.25 5.23077V14.7692C2.25 16.4156 3.58442 17.75 5.23077 17.75H14.7692C16.4156 17.75 17.75 16.4156 17.75 14.7692V5.23077C17.75 3.58442 16.4156 2.25 14.7692 2.25ZM5.23077 3.44231H14.7692C15.7571 3.44231 16.5577 4.24291 16.5577 5.23077V14.7692C16.5577 15.7571 15.7571 16.5577 14.7692 16.5577H5.23077C4.24291 16.5577 3.44231 15.7571 3.44231 14.7692V5.23077C3.44231 4.24291 4.24291 3.44231 5.23077 3.44231Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.83705 7.53966L6.04748 5.3882C6.46458 4.64474 7.5343 4.64474 7.95233 5.38791L9.16292 7.53966C9.57282 8.26821 9.04642 9.1687 8.2104 9.1687H5.78958C4.95351 9.1687 4.42723 8.26861 4.83705 7.53966ZM7.29952 6.28288C7.27979 6.13738 7.15507 6.02524 7.00416 6.02524C6.83954 6.02524 6.70608 6.1587 6.70608 6.32333V7.05264L6.7088 7.09309C6.72854 7.23859 6.85326 7.35073 7.00416 7.35073C7.16879 7.35073 7.30224 7.21727 7.30224 7.05264V6.32333L7.29952 6.28288ZM7.00332 7.55898L7.04538 7.56159C7.21063 7.58227 7.33757 7.72326 7.33757 7.89323C7.33757 8.0768 7.18917 8.22748 7.00372 8.22748C6.81922 8.22748 6.66947 8.07773 6.66953 7.89919C6.66607 7.72604 6.79487 7.58266 6.96105 7.56163L7.00332 7.55898ZM8.64767 7.834L7.43705 5.6822C7.24699 5.34431 6.76113 5.34431 6.57155 5.68222L5.36099 7.83392C5.17461 8.16544 5.4138 8.57454 5.7939 8.57454H8.21472C8.59471 8.57454 8.834 8.16519 8.64767 7.834Z"
      fill="#FF3D00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2596 12.7426C10.8789 11.5962 11.9623 10.7588 13.0002 10.7588C14.0381 10.7588 15.1214 11.596 15.7408 12.742C15.8279 12.9028 15.8279 13.0978 15.7409 13.2577C15.1216 14.4041 14.0382 15.2415 13.0002 15.2415C11.9624 15.2415 10.879 14.4042 10.2596 13.2583C10.1726 13.0974 10.1726 12.9024 10.2596 12.7426ZM15.2752 12.9938C14.7395 12.0028 13.8149 11.2882 13.0002 11.2882C12.1856 11.2882 11.2609 12.0029 10.725 12.9949C10.7234 12.9979 10.7234 13.0028 10.7253 13.0064C11.261 13.9974 12.1856 14.712 13.0002 14.712C13.8149 14.712 14.7395 13.9973 15.2755 13.0053C15.2771 13.0024 15.2771 12.9974 15.2752 12.9938Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7114 12.2889C13.3186 11.896 12.6817 11.896 12.2888 12.2889C11.896 12.6817 11.896 13.3186 12.2888 13.7114C12.6817 14.1042 13.3186 14.1042 13.7114 13.7114C14.1042 13.3186 14.1042 12.6817 13.7114 12.2889ZM12.6632 12.6632C12.8404 12.486 13.1225 12.4776 13.3097 12.6379L13.337 12.6632L13.3623 12.6905C13.5227 12.8778 13.5142 13.1598 13.337 13.3371C13.151 13.5231 12.8493 13.5231 12.6632 13.3371C12.4771 13.151 12.4771 12.8493 12.6632 12.6632Z"
      fill={color}
    />
    <path d="M5 15.5L15 5" stroke="#121216" strokeLinecap="round" />
  </svg>
);
