import type { WebAuth } from "auth0-js";
import qs from "query-string";
import { defaultAppName, defaultAppUrl } from "./auth.constants";
import { clearAuthCookie, setAuthCookie } from "./auth.cookie";
import {
  AuthSearchParam,
  type RedirectToAppOptions,
  type RedirectToAuthOptions,
} from "./auth.types";

export const getCurrentRedirectPath = () =>
  window.location.pathname + window.location.search;

export const getAuthSearchParams = (searchParams: URLSearchParams) => {
  const parsed = qs.parse(searchParams.toString());

  const appNameParam = parsed[AuthSearchParam.appName];
  const appUrlParam = parsed[AuthSearchParam.appUrl];
  const redirectParam = parsed[AuthSearchParam.redirect];

  const appName =
    typeof appNameParam === "string"
      ? appNameParam || defaultAppName
      : defaultAppName;

  const appUrl =
    typeof appUrlParam === "string"
      ? appUrlParam || defaultAppUrl
      : defaultAppUrl;

  const redirect =
    typeof redirectParam === "string" ? redirectParam : undefined;

  return { appName, appUrl, redirect };
};

export const logoutAndRedirect = (
  auth0Client: WebAuth,
  { noRedirect } = { noRedirect: false },
) => {
  const appName = import.meta.env.VITE_APP_NAME ?? "";
  const appUrl = import.meta.env.VITE_APP_URL ?? "";
  const authUrl = import.meta.env.VITE_AUTH_URL ?? "";

  const query = qs.stringify({
    [AuthSearchParam.appName]: appName,
    [AuthSearchParam.appUrl]: appUrl,
    [AuthSearchParam.redirect]: noRedirect
      ? undefined
      : getCurrentRedirectPath(),
  });

  clearAuthCookie({ appName, appUrl });

  auth0Client.logout({ returnTo: `${authUrl}?${query}` });
};

export const redirectToApp = ({
  appName,
  appUrl,
  redirect = "/",
  tenant,
}: RedirectToAppOptions) => {
  setAuthCookie({ appName, appUrl }, tenant);
  window.location.replace(`${appUrl}${redirect}`);
};

export const redirectToAuth = ({
  redirect = "/",
}: RedirectToAuthOptions = {}) => {
  const appName = import.meta.env.VITE_APP_NAME ?? "";
  const appUrl = import.meta.env.VITE_APP_URL ?? "";
  const authUrl = import.meta.env.VITE_AUTH_URL ?? "";

  const query = qs.stringify({
    [AuthSearchParam.appName]: appName,
    [AuthSearchParam.appUrl]: appUrl,
    [AuthSearchParam.redirect]: redirect,
  });

  if (authUrl) {
    clearAuthCookie({ appName, appUrl });
    window.location.replace(`${authUrl}/?${query}`);
  }
};
