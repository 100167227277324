import type { IconChildrenProps } from "../icon.types";

export const ArrowCross = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M7.26978 13.6727L12.5354 8.40709V13.008C12.5354 13.3757 12.8371 13.6774 13.2048 13.6774C13.5725 13.6774 13.8694 13.3804 13.8694 13.0127V6.7996C13.8696 6.62326 13.7997 6.45409 13.675 6.3294C13.5503 6.20471 13.3811 6.13473 13.2048 6.13492L6.99165 6.12549C6.75418 6.12549 6.53475 6.25217 6.41602 6.45783C6.29729 6.66348 6.29729 6.91685 6.41602 7.12251C6.53475 7.32816 6.75418 7.45485 6.99165 7.45485L11.5926 7.46428L6.32697 12.7299C6.0677 12.9891 6.0677 13.4134 6.32697 13.6727C6.58624 13.9319 7.01051 13.9319 7.26978 13.6727Z"
    fill={color}
    fillRule="evenodd"
  />
);
