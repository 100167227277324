import type { IconChildrenProps } from "../icon.types";

export const Coffee = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M13.0779 1.84705C14.0378 1.84705 14.8853 2.46225 15.1866 3.36551L15.2158 3.45997L15.4813 4.38822H15.6464C16.2389 4.38822 16.7655 4.7674 16.9525 5.32976L17.2344 6.17643C17.5319 7.06754 16.8682 7.98822 15.9284 7.98822H15.9051L14.937 16.1897C14.8085 17.2772 13.909 18.1045 12.8243 18.1509L12.7282 18.1529H7.2697C6.17472 18.1529 5.24834 17.3568 5.07501 16.285L5.06169 16.1897L4.0928 7.98822H4.07129C3.1315 7.98822 2.46777 7.06754 2.76514 6.17682L3.0475 5.32887C3.23575 4.76687 3.76128 4.38822 4.35336 4.38822H4.51815L4.78352 3.46033C5.0468 2.53724 5.87103 1.89127 6.82264 1.84923L6.92152 1.84705H13.0779ZM4.90568 5.44705C4.91367 5.44723 4.92165 5.44723 4.9296 5.44705H15.0699C15.0778 5.44723 15.0858 5.44723 15.0938 5.44705H15.6464C15.7832 5.44705 15.9048 5.53459 15.9478 5.66407L16.23 6.51135C16.2986 6.71695 16.1455 6.9294 15.9284 6.9294H15.4412C15.4363 6.92933 15.4314 6.92933 15.4265 6.9294H4.57139C4.56648 6.92933 4.56156 6.92933 4.55663 6.9294H4.07129C3.85424 6.9294 3.70108 6.71695 3.7696 6.51173L4.0518 5.66429C4.09525 5.53455 4.2167 5.44705 4.35336 5.44705H4.90568ZM5.15899 7.98822L6.1132 16.0655C6.17964 16.6278 6.63872 17.057 7.19642 17.0918L7.2697 17.0941H12.7282C13.2941 17.0941 13.7746 16.688 13.8746 16.1381L13.8855 16.0656L14.8389 7.98822H5.15899ZM14.38 4.38822H5.61942L5.80163 3.75111L5.82537 3.67724C5.99022 3.21705 6.4274 2.90587 6.92152 2.90587H13.0779L13.1555 2.90843C13.643 2.94074 14.0622 3.27567 14.1977 3.75075L14.38 4.38822Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.42665 10.9689C9.29499 10.1006 10.7028 10.1006 11.5712 10.9689C12.4395 11.8373 12.4395 13.2451 11.5712 14.1134C10.7028 14.9818 9.29499 14.9818 8.42665 14.1134C7.5583 13.2451 7.5583 11.8373 8.42665 10.9689ZM10.7642 11.6631C10.3067 11.2636 9.61124 11.2817 9.17535 11.7176C8.7205 12.1725 8.7205 12.9099 9.17535 13.3647C9.6302 13.8196 10.3676 13.8196 10.8225 13.3647C11.2584 12.9288 11.2765 12.2334 10.877 11.7759L10.8225 11.7176L10.7642 11.6631Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
