import type { UseResizeObserverCallback } from "./use-resize-observer.types";

let resizeObserver: ReturnType<typeof createResizeObserver>;

const createResizeObserver = () => {
  let ticking = false;
  let allEntries: ResizeObserverEntry[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callbacks: Map<any, Array<UseResizeObserverCallback>> = new Map();

  const observer = new ResizeObserver(
    (entries: ResizeObserverEntry[], obs: ResizeObserver) => {
      allEntries = allEntries.concat(entries);
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const triggered = new Set<Element>();
          for (const entry of allEntries) {
            if (triggered.has(entry.target)) continue;
            triggered.add(entry.target);
            const cbs = callbacks.get(entry.target);
            cbs?.forEach((cb) => cb(entry, obs));
          }
          allEntries = [];
          ticking = false;
        });
      }
      ticking = true;
    },
  );

  return {
    observer,
    subscribe(target: HTMLElement, callback: UseResizeObserverCallback) {
      observer.observe(target);
      const cbs = callbacks.get(target) ?? [];
      cbs.push(callback);
      callbacks.set(target, cbs);
    },
    unsubscribe(target: HTMLElement, callback: UseResizeObserverCallback) {
      const cbs = callbacks.get(target) ?? [];
      if (cbs.length === 1) {
        observer.unobserve(target);
        callbacks.delete(target);
        return;
      }
      const cbIndex = cbs.indexOf(callback);
      if (cbIndex !== -1) cbs.splice(cbIndex, 1);
      callbacks.set(target, cbs);
    },
  };
};

export const getResizeObserver = () =>
  !resizeObserver ? (resizeObserver = createResizeObserver()) : resizeObserver;
