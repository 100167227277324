import type { IconChildrenProps } from "../icon.types";

export const Privacy = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M7.09565 3.43093C8.70848 1.91297 11.165 1.88433 12.8097 3.34501L12.9037 3.43093L12.9152 3.4421C14.5339 5.06079 14.5633 7.62645 12.9926 9.19805L12.9037 9.28432C11.2262 10.8631 8.60855 10.8932 7.07297 9.26164C5.49418 7.58418 5.46409 4.96652 7.09565 3.43093ZM12.0927 4.12445C10.8565 3.03913 9.02938 3.06498 7.82133 4.20197C6.63068 5.32258 6.65307 7.27059 7.84401 8.53596C8.96462 9.72662 10.9126 9.70422 12.178 8.51329C13.3707 7.39073 13.3972 5.5081 12.2468 4.27393L12.1745 4.19847L12.0927 4.12445ZM9.7663 12.5412C9.7663 12.2488 9.52927 12.0117 9.23689 12.0117H7.03454L6.91444 12.0133C4.56067 12.0763 2.69336 13.9831 2.69336 16.3529C2.69336 16.6453 2.93039 16.8823 3.22277 16.8823C3.51516 16.8823 3.75218 16.6453 3.75218 16.3529C3.75218 14.5277 5.20927 13.0706 7.03454 13.0706H9.23689L9.29102 13.0678C9.55798 13.0407 9.7663 12.8153 9.7663 12.5412Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M16.4566 13.303C16.4098 12.3701 15.6003 11.5882 14.6588 11.5882C13.6887 11.5882 12.8588 12.4182 12.8588 13.3882V13.8839C12.3458 14.0778 12.0117 14.5572 12.0117 15.167V16.3529C12.0117 17.1535 12.5876 17.7294 13.3882 17.7294H15.9294C16.73 17.7294 17.3058 17.1535 17.3058 16.3529V15.167C17.3058 14.5572 16.9718 14.0778 16.4588 13.8839V13.3882L16.4566 13.303ZM15.4 13.7905V13.3882C15.4 13.0029 15.044 12.647 14.6588 12.647C14.2928 12.647 13.9533 12.9682 13.9202 13.3307L13.9176 13.3882V13.7905H15.4ZM13.3882 14.8494C13.1723 14.8494 13.0705 14.9512 13.0705 15.167V16.3529C13.0705 16.5688 13.1723 16.6705 13.3882 16.6705H15.9294C16.1452 16.6705 16.247 16.5688 16.247 16.3529V15.167C16.247 14.9512 16.1452 14.8494 15.9294 14.8494H13.3882Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
