import { AR_CATEGORY_ERGONOMICS } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseRula = ({ color }: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <path
        d="M11.4925 8.2C11.6725 8.2 11.8125 8.35 11.8125 8.52V11.64C11.8125 11.82 11.6625 11.96 11.4925 11.96H7.8325C7.6525 11.96 7.5125 11.81 7.5125 11.64V8.52C7.5125 8.34 7.6625 8.2 7.8325 8.2H11.4925ZM11.4925 7H7.8325C6.9925 7 6.3125 7.68 6.3125 8.52V11.64C6.3125 12.48 6.9925 13.16 7.8325 13.16H11.4925C12.3325 13.16 13.0125 12.48 13.0125 11.64V8.52C13.0125 7.68 12.3325 7 11.4925 7Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.30128 10.8599V10.4199C6.21128 10.4399 6.11128 10.4499 6.03128 10.4599C5.87128 10.4799 5.75128 10.4799 5.66128 10.4599C5.72128 10.3199 5.85128 10.1299 6.06128 9.88991C6.13128 9.80991 6.21128 9.72991 6.29128 9.64991V9.62991H6.31128C6.49128 9.44991 6.69128 9.24991 6.93128 9.04991C6.98128 9.24991 7.14128 9.40991 7.35128 9.46991C7.66128 9.54991 7.98128 9.35991 8.06128 9.03991L8.37128 7.78991C8.41128 7.62991 8.38128 7.45991 8.29128 7.32991C8.20128 7.18991 8.06128 7.09991 7.90128 7.06991L6.76128 6.87991C6.44128 6.82991 6.14128 7.03991 6.09128 7.35991C6.05128 7.61991 6.18128 7.85991 6.40128 7.97991C5.91128 8.37991 5.50128 8.76991 5.18128 9.12991C4.92128 9.42991 4.70128 9.73991 4.57128 10.0299C4.45128 10.3099 4.36128 10.7199 4.57128 11.0899C4.75128 11.3899 5.04128 11.5299 5.30128 11.5899C5.56128 11.6499 5.85128 11.6499 6.14128 11.6199C6.24128 11.6099 6.17128 11.6599 6.28128 11.6399V10.8599H6.30128Z"
        fill={getAlertCategoryColor(AR_CATEGORY_ERGONOMICS)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4229 10.0299C15.2929 9.72991 15.0729 9.41991 14.8129 9.12991C14.4929 8.76991 14.0829 8.37991 13.5929 7.97991C13.8129 7.86991 13.9429 7.61991 13.9029 7.35991C13.8529 7.03991 13.5529 6.82991 13.2329 6.87991L12.0929 7.06991C11.9329 7.09991 11.7929 7.18991 11.7029 7.32991C11.6129 7.46991 11.5829 7.62991 11.6229 7.78991L11.9329 9.04991C12.0129 9.35991 12.3229 9.54991 12.6429 9.47991C12.8529 9.42991 13.0129 9.25991 13.0629 9.05991C13.4129 9.35991 13.7129 9.64991 13.9329 9.89991C14.1429 10.1399 14.2729 10.3399 14.3329 10.4699C14.2229 10.4799 14.0629 10.4699 13.8329 10.4399C13.5929 10.4099 13.3129 10.3399 13.0129 10.2499V10.8599C13.0129 11.0599 13.0129 11.2799 13.0129 11.4599C13.2829 11.5299 13.4329 11.5599 13.6629 11.5999C13.9929 11.6499 14.3229 11.6599 14.6029 11.6099C14.8829 11.5599 15.2229 11.4199 15.4129 11.0899C15.6329 10.7199 15.5429 10.3099 15.4129 10.0299H15.4229Z"
        fill={getAlertCategoryColor(AR_CATEGORY_ERGONOMICS)}
      />
      <path
        d="M10.5892 14.73V16.28C10.5892 16.5 10.4092 16.67 10.1992 16.67H9.03922C8.81922 16.67 8.64922 16.49 8.64922 16.28V14.73H10.5892ZM11.0792 13.53H8.15922C7.76922 13.53 7.44922 13.85 7.44922 14.24V16.28C7.44922 17.16 8.15922 17.87 9.03922 17.87H10.1992C11.0792 17.87 11.7892 17.16 11.7892 16.28V14.24C11.7892 13.85 11.4692 13.53 11.0792 13.53Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66031 3.17C9.01031 3.17 8.49031 3.69 8.49031 4.34C8.49031 4.99 9.01031 5.51 9.66031 5.51C10.3103 5.51 10.8303 4.99 10.8303 4.34C10.8303 3.69 10.3103 3.17 9.66031 3.17ZM7.32031 4.34C7.32031 3.05 8.37031 2 9.66031 2C10.9503 2 12.0003 3.05 12.0003 4.34C12.0003 5.63 10.9503 6.68 9.66031 6.68C8.37031 6.68 7.32031 5.63 7.32031 4.34Z"
        fill={color}
      />
    </>
  );
};
