import type { IconChildrenProps } from "../icon.types";

export const Score = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M14.7873 5.57661C14.9423 5.68296 15.0024 5.8829 14.9316 6.05706L11.5 14.5C11.5 15.3284 10.8284 16 10 16C9.17157 16 8.5 15.3284 8.5 14.5C8.5 14.2158 8.57904 13.9501 8.71632 13.7236L8.71318 13.7231L14.2312 5.68015C14.3562 5.49799 14.6052 5.45163 14.7873 5.57661ZM15.8192 6.80426C17.4694 8.35475 18.5 10.557 18.5 13C18.5 13.2761 18.2761 13.5 18 13.5C17.7239 13.5 17.5 13.2761 17.5 13C17.5 10.932 16.663 9.05945 15.3093 7.70269L15.8192 6.80426ZM10 4.5C11.1703 4.5 12.2854 4.73653 13.3001 5.16438L12.8013 6.04066C11.9358 5.69194 10.9903 5.5 10 5.5C5.85786 5.5 2.5 8.85786 2.5 13C2.5 13.2761 2.27614 13.5 2 13.5C1.72386 13.5 1.5 13.2761 1.5 13C1.5 8.30558 5.30558 4.5 10 4.5Z"
    fill={color}
    fillRule="evenodd"
  />
);
