import { useCallback, useEffect, useState } from "react";
import type { InvitationData } from "./sign-up-route.types";
import { getInvitationData } from "./sign-up-route.utils";

export const useInvitation = (invitationId?: string) => {
  const [invitationData, setInvitationData] = useState<InvitationData>();
  const [invitationDataError, setInvitationDataError] = useState<boolean>();
  const [invitationDataLoading, setInvitationDataLoading] = useState<boolean>();

  const fetchInvitationData = useCallback(async (id: string) => {
    setInvitationDataLoading(true);
    const [error, response] = await getInvitationData(id);
    if (error) {
      setInvitationDataError(true);
    } else if (response) {
      setInvitationData(response);
    } else {
      setInvitationData(undefined);
      setInvitationDataError(undefined);
    }
    setInvitationDataLoading(false);
  }, []);

  useEffect(() => {
    if (invitationId) {
      void fetchInvitationData(invitationId);
    } else {
      setInvitationDataError(true);
      setInvitationDataLoading(false);
    }
  }, [fetchInvitationData, invitationId]);

  return {
    invitationData,
    invitationDataError,
    invitationDataLoading,
  } as const;
};
