import { AR_CATEGORY_PPE } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../icon.types";

export const CategoryPpe = ({ color }: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();

  return (
    <>
      <path
        clipRule="evenodd"
        d="M11.6276 3.28186C14.5987 3.28186 17.0192 5.63916 17.1209 8.5855L17.1242 8.77847V12.8502C17.1242 13.1874 16.8508 13.4607 16.5137 13.4607C16.2046 13.4607 15.9492 13.2311 15.9087 12.9331L15.9032 12.8502V8.77847C15.9032 6.47615 14.0834 4.59887 11.8038 4.50644L11.6276 4.50288H11.4093C11.0722 4.50288 10.7988 4.22954 10.7988 3.89237C10.7988 3.58329 11.0285 3.32786 11.3265 3.28743L11.4093 3.28186H11.6276Z"
        fill={getAlertCategoryColor(AR_CATEGORY_PPE)}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.58985 3.28186C8.92702 3.28186 9.20035 3.55519 9.20035 3.89237C9.20035 4.20145 8.97068 4.45688 8.67269 4.4973L8.58985 4.50288H8.37161C6.06929 4.50288 4.19201 6.3226 4.09958 8.60223L4.09602 8.77847V12.8502C4.09602 13.1874 3.82268 13.4607 3.48551 13.4607C3.17643 13.4607 2.921 13.2311 2.88057 12.9331L2.875 12.8502V8.77847C2.875 5.80736 5.2323 3.38684 8.17864 3.28518L8.37161 3.28186H8.58985Z"
        fill={getAlertCategoryColor(AR_CATEGORY_PPE)}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16.9214 12.2396H3.07746C2.1312 12.2396 1.34321 12.9599 1.25377 13.896L1.24609 14.0427C1.24541 15.0835 2.06565 15.9037 3.07746 15.9037H3.64751C4.41262 15.9041 5.16333 16.1117 5.81976 16.5041L6.00876 16.6237C6.89224 17.2129 7.94503 17.5319 9.02192 17.5324H10.9766C11.9629 17.532 12.9299 17.2645 13.7755 16.7591L13.99 16.6237C14.6983 16.1517 15.5156 15.9041 16.3516 15.9037H16.9214C17.8676 15.9037 18.6556 15.1835 18.7451 14.2474L18.7528 14.1007C18.7534 13.0599 17.9332 12.2396 16.9214 12.2396ZM3.07746 13.4606H16.9214C17.2588 13.4606 17.5324 13.7342 17.5324 14.0717L17.5275 14.1604C17.4999 14.4423 17.2368 14.6827 16.9214 14.6827H16.3514C15.357 14.6831 14.3836 14.955 13.5349 15.4667L13.1362 15.7189C12.4925 16.1034 11.7418 16.3111 10.9764 16.3114H9.02218C8.26242 16.3111 7.51788 16.1063 6.86612 15.7207L6.45906 15.4639C5.60069 14.9506 4.63369 14.6831 3.64777 14.6827H3.07746C2.74 14.6827 2.46642 14.4091 2.46642 14.0717L2.47133 13.9831C2.49897 13.7011 2.76202 13.4606 3.07746 13.4606Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9.99868 2.46753C11.1896 2.46753 12.1634 3.39715 12.2339 4.57031L12.2379 4.70671V6.33547C12.2379 6.6729 12.5115 6.94646 12.8489 6.94646C13.1583 6.94646 13.4139 6.71659 13.4544 6.41837L13.4599 6.33547V4.54391C13.4599 4.20673 13.7333 3.9334 14.0704 3.9334C14.3795 3.9334 14.635 4.16308 14.6754 4.46107L14.681 4.54391V6.33547C14.681 7.34723 13.8607 8.16748 12.8489 8.16748C11.8793 8.16748 11.0856 7.41416 11.0212 6.46089L11.0169 6.33547V4.70671C11.0169 4.1444 10.5611 3.68855 9.99868 3.68855C9.47152 3.68855 9.03791 4.0892 8.98577 4.60261L8.98051 4.70671V6.33547C8.98051 7.34721 8.16025 8.16748 7.1485 8.16748C6.17885 8.16748 5.3851 7.41416 5.32063 6.46089L5.31641 6.33547V4.54391C5.31641 4.20673 5.58974 3.9334 5.92691 3.9334C6.23599 3.9334 6.49142 4.16308 6.53185 4.46107L6.53742 4.54391V6.33547C6.53742 6.67287 6.81103 6.94646 7.1485 6.94646C7.45778 6.94646 7.71345 6.71656 7.75392 6.41836L7.7595 6.33547V4.70671C7.7595 3.47005 8.76202 2.46753 9.99868 2.46753Z"
        fill={getAlertCategoryColor(AR_CATEGORY_PPE)}
        fillRule="evenodd"
      />
    </>
  );
};
