import { AR_CATEGORY_AREA_CONTROLS } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseProhibitedObject = ({ color }: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.74013 4.44416C9.90372 4.36236 10.0963 4.36236 10.2599 4.44416L15.296 6.96221C15.4928 7.06064 15.6172 7.26185 15.6172 7.48195V12.518C15.6172 12.7381 15.4928 12.9394 15.296 13.0378L10.2599 15.5558C10.0963 15.6376 9.90372 15.6376 9.74013 15.5558L4.70403 13.0378C4.50717 12.9394 4.38281 12.7381 4.38281 12.518V7.48195C4.38281 7.26185 4.50717 7.06064 4.70403 6.96221L9.74013 4.44416ZM5.54499 8.42217V12.1589L9.41891 14.0959V10.3591L5.54499 8.42217ZM10.5811 10.3591V14.0959L14.455 12.1589V8.42217L10.5811 10.3591ZM13.7367 7.48195L10 9.35032L6.26325 7.48195L10 5.61358L13.7367 7.48195Z"
        fill={color}
      />
      <path
        d="M16.01 3.99C14.4 2.38 12.27 1.5 10 1.5C7.73 1.5 5.6 2.38 3.99 3.99C2.38 5.6 1.5 7.73 1.5 10C1.5 12.27 2.38 14.4 3.99 16.01C5.6 17.62 7.82 18.5 10 18.5C12.18 18.5 14.35 17.67 16.01 16.01C17.67 14.35 18.5 12.27 18.5 10C18.5 7.73 17.62 5.6 16.01 3.99V3.99ZM4.7 4.7C6.12 3.28 8 2.5 10 2.5C11.83 2.5 13.55 3.15 14.91 4.34L4.34 14.91C3.15 13.55 2.5 11.82 2.5 10C2.5 8 3.28 6.11 4.7 4.7V4.7ZM15.3 15.3C12.49 18.11 7.99 18.21 5.05 15.62L15.62 5.05C16.83 6.42 17.5 8.16 17.5 10C17.5 12 16.72 13.89 15.3 15.3Z"
        fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
      />
    </>
  );
};
