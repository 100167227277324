import type { IconChildrenProps } from "../../icon.types";

export const ErgonomicsTrunkSideBend = ({
  accentColor,
  color,
}: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M9.70807 12.3999C9.70807 12.0547 9.9879 11.7749 10.3331 11.7749H13.8331C14.1783 11.7749 14.4581 12.0547 14.4581 12.3999C14.4581 12.7451 14.1783 13.0249 13.8331 13.0249H10.3331C9.9879 13.0249 9.70807 12.7451 9.70807 12.3999Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.35335 7.29864C9.77728 6.94343 10.3037 6.88524 10.7155 6.92109C11.1732 6.96092 11.4571 6.97957 12.0817 6.97957C12.4268 6.97957 12.7067 7.25939 12.7067 7.60457C12.7067 7.94975 12.4268 8.22957 12.0817 8.22957C11.4157 8.22957 11.0952 8.20886 10.6071 8.16638C10.3729 8.14599 10.2293 8.19545 10.1562 8.25676C9.73182 8.61231 9.50268 9.14385 9.28347 9.84594C9.2495 9.95474 9.21536 10.07 9.17992 10.1897C9.00678 10.7743 8.80264 11.4636 8.43611 12.0133C8.24464 12.3006 7.8566 12.3782 7.56939 12.1867C7.28219 11.9952 7.20458 11.6072 7.39605 11.32C7.64792 10.9422 7.78693 10.4787 7.95982 9.90223C8.00076 9.76574 8.04359 9.62292 8.09028 9.4734C8.31485 8.75414 8.62852 7.90598 9.35335 7.29864Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.8113 7.29864C14.3874 6.94343 13.861 6.88524 13.4491 6.92109C12.9915 6.96092 12.7075 6.97957 12.083 6.97957C11.7378 6.97957 11.458 7.25939 11.458 7.60457C11.458 7.94975 11.7378 8.22957 12.083 8.22957C12.749 8.22957 13.0695 8.20886 13.5575 8.16638C13.7918 8.14599 13.9354 8.19545 14.0085 8.25676C14.4329 8.61231 14.662 9.14385 14.8812 9.84594C14.9152 9.95474 14.9493 10.07 14.9848 10.1897C15.1579 10.7743 15.362 11.4636 15.7286 12.0133C15.92 12.3006 16.3081 12.3782 16.5953 12.1867C16.8825 11.9952 16.9601 11.6072 16.7686 11.32C16.5168 10.9422 16.3778 10.4787 16.2049 9.90223C16.1639 9.76574 16.1211 9.62292 16.0744 9.4734C15.8498 8.75414 15.5362 7.90598 14.8113 7.29864Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.3302 6.92748C10.6754 6.92748 10.9552 7.2073 10.9552 7.55248V7.56213C10.9552 9.27413 10.9552 11.1822 10.8544 12.9218C10.7544 14.6464 10.5524 16.2829 10.1147 17.4132C9.99008 17.7351 9.62809 17.895 9.3062 17.7703C8.98431 17.6457 8.82442 17.2837 8.94906 16.9618C9.30971 16.0304 9.50687 14.5677 9.60648 12.8494C9.70503 11.1493 9.70522 9.27468 9.70522 7.55248C9.70522 7.2073 9.98505 6.92748 10.3302 6.92748Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M13.8353 6.92748C14.1805 6.92748 14.4603 7.2073 14.4603 7.55248C14.4603 12.8701 14.4619 13.9255 15.2399 17.0358C15.3237 17.3707 15.1201 17.71 14.7853 17.7938C14.4504 17.8776 14.111 17.674 14.0273 17.3391C13.2103 14.0728 13.2103 12.8797 13.2103 7.58466V7.55248C13.2103 7.2073 13.4901 6.92748 13.8353 6.92748Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12.0827 3.40833C11.6225 3.40833 11.2494 3.78142 11.2494 4.24166C11.2494 4.7019 11.6225 5.07499 12.0827 5.07499C12.543 5.07499 12.9161 4.7019 12.9161 4.24166C12.9161 3.78142 12.543 3.40833 12.0827 3.40833ZM9.99942 4.24166C9.99942 3.09107 10.9322 2.15833 12.0827 2.15833C13.2333 2.15833 14.1661 3.09107 14.1661 4.24166C14.1661 5.39225 13.2333 6.32499 12.0827 6.32499C10.9322 6.32499 9.99942 5.39225 9.99942 4.24166Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M2.89144 8.69436C3.10262 8.9673 3.49505 9.01742 3.76806 8.80632L5.23832 7.66947C5.51138 7.45833 5.56158 7.0658 5.35044 6.79273C5.13929 6.51966 4.74676 6.46947 4.47369 6.68061L3.49772 7.43527L2.74239 6.45906C2.53116 6.18606 2.13861 6.13599 1.86561 6.34722C1.59261 6.55845 1.54253 6.951 1.75377 7.224L2.89144 8.69436Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M3.64271 8.75379C3.9864 8.72177 4.23906 8.4172 4.20704 8.07351C4.16334 7.6044 4.16479 7.13002 4.22596 6.6517L4.22639 6.64839C4.45039 4.89681 5.36236 3.40327 6.65681 2.40169C6.9298 2.19045 6.97987 1.7979 6.76864 1.52491C6.55741 1.25191 6.16486 1.20184 5.89186 1.41307C4.34627 2.60898 3.25424 4.39607 2.98648 6.48983L2.98606 6.49313C2.91192 7.07286 2.91124 7.63996 2.96243 8.18945C2.99445 8.53314 3.29902 8.7858 3.64271 8.75379Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
