import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseWorkerDown = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  return (
    <>
      <g clipPath="url(#clip0_1030_19324)">
        <path
          d="M4.77187 6.71004C4.77187 6.71004 4.66188 7.26004 4.44187 7.48004C4.22187 7.70004 3.67188 7.81004 3.67188 7.81004C3.67188 7.81004 4.22187 7.92004 4.44187 8.14004C4.66188 8.36004 4.77187 8.91004 4.77187 8.91004C4.77187 8.91004 4.88187 8.36004 5.10187 8.14004C5.32187 7.92004 5.87187 7.81004 5.87187 7.81004C5.87187 7.81004 5.32187 7.70004 5.10187 7.48004C4.88187 7.26004 4.77187 6.71004 4.77187 6.71004Z"
          fill="#FF3D00"
        />
        <path
          d="M7.91187 5.12004C7.45187 4.66004 7.23187 3.54004 7.23187 3.54004C7.23187 3.54004 7.01188 4.66004 6.55188 5.12004C6.09188 5.58004 4.97187 5.80004 4.97187 5.80004C4.97187 5.80004 6.09188 6.02004 6.55188 6.48004C7.01188 6.94004 7.23187 8.06004 7.23187 8.06004C7.23187 8.06004 7.45187 6.94004 7.91187 6.48004C8.37187 6.02004 9.49187 5.80004 9.49187 5.80004C9.49187 5.80004 8.37187 5.58004 7.91187 5.12004Z"
          fill="#FF3D00"
        />
        <path
          d="M4.60913 9.21001C3.64913 9.23001 2.86913 10.02 2.88913 10.99C2.90913 11.95 3.68913 12.71 4.63913 12.71C4.63913 12.71 4.65913 12.71 4.66913 12.71C5.62913 12.7 6.40913 11.9 6.38913 10.93C6.36913 9.97001 5.53913 9.21001 4.60913 9.21001ZM4.64913 11.66C4.23913 11.67 3.93913 11.36 3.93913 10.97C3.93913 10.58 4.23913 10.26 4.62913 10.26H4.63913C5.01913 10.26 5.32913 10.57 5.33913 10.95C5.33913 11.34 5.03913 11.66 4.64913 11.66Z"
          fill={color}
        />
        <path
          d="M6.34913 12.01C6.32913 12.03 6.31913 12.06 6.30913 12.09C6.30913 12.09 6.30913 12.09 6.30913 12.1C6.28913 12.16 6.26913 12.22 6.26913 12.28C6.26913 12.31 6.26913 12.35 6.26913 12.38C6.26913 12.38 6.26913 12.41 6.26913 12.42L6.88913 15.17C6.90913 15.27 6.95913 15.36 7.03913 15.43L8.73913 17.08C8.83913 17.18 8.96913 17.23 9.10913 17.23C9.24913 17.23 9.37913 17.18 9.48913 17.07C9.68913 16.86 9.68913 16.53 9.47913 16.33L7.88913 14.79L7.49913 13.03L10.5491 13.87C10.5491 13.87 10.6191 13.89 10.6491 13.89C10.6791 13.89 10.6991 13.89 10.7291 13.89C10.7791 13.89 10.8391 13.87 10.8891 13.86C10.8891 13.86 10.8891 13.86 10.8991 13.86L14.3491 12.49L16.0391 12.61C16.2791 12.61 16.5091 12.47 16.5791 12.24L17.0991 10.57C17.1891 10.29 17.0291 10 16.7491 9.91001C16.4691 9.82001 16.1791 9.98001 16.0891 10.26L15.6991 11.53L14.3091 11.43C14.2291 11.43 14.1491 11.43 14.0791 11.47L12.0191 12.29L13.1391 10.54L14.9491 10.4C15.1691 10.38 15.3491 10.24 15.4091 10.03L15.9291 8.36001C16.0191 8.08001 15.8591 7.79001 15.5791 7.70001C15.2991 7.62001 15.0091 7.77001 14.9191 8.05001L14.5091 9.38001L12.7891 9.51001C12.6191 9.52001 12.4791 9.61001 12.3891 9.75001L10.4591 12.77L8.03913 12.1L9.39913 11.19C9.51913 11.11 9.59913 10.99 9.62913 10.85L10.2491 7.66001C10.2991 7.38001 10.1191 7.10001 9.82913 7.04001C9.53913 6.99001 9.26913 7.17001 9.20913 7.46001L8.62913 10.44L6.49913 11.85C6.49913 11.85 6.44913 11.89 6.41913 11.91C6.38913 11.94 6.35913 11.97 6.33913 12.01H6.34913Z"
          fill={color}
        />
        <path
          d="M1.99047 10.34C2.26047 10.34 2.48047 10.12 2.49047 9.84998C2.49047 9.59998 2.51047 9.34998 2.54047 9.09998C2.57047 8.82998 2.38047 8.57998 2.10047 8.53998C1.82047 8.50998 1.58047 8.69998 1.54047 8.97998C1.51047 9.25998 1.49047 9.53998 1.48047 9.82998C1.48047 10.11 1.69047 10.33 1.97047 10.34H1.99047Z"
          fill={color}
        />
        <path
          d="M3.37046 6.02003C3.53046 6.02003 3.69046 5.94003 3.78046 5.80003C3.92046 5.59003 4.07046 5.39003 4.23046 5.20003C4.41046 4.99003 4.38046 4.67003 4.17046 4.50003C3.96046 4.32003 3.64046 4.35003 3.47046 4.56003C3.29046 4.78003 3.12046 5.00003 2.96046 5.24003C2.81046 5.47003 2.86046 5.78003 3.09046 5.93003C3.18046 5.99003 3.27046 6.02003 3.37046 6.02003Z"
          fill={color}
        />
        <path
          d="M9.08892 2.5501C9.08892 2.5501 9.12892 2.5501 9.14892 2.5501C9.39892 2.5201 9.64892 2.5101 9.89892 2.5001C10.1789 2.5001 10.3989 2.2701 10.3889 1.9901C10.3889 1.7101 10.1489 1.4701 9.87892 1.5001C9.58892 1.5001 9.30892 1.5201 9.02892 1.5501C8.75892 1.5801 8.55892 1.8301 8.58892 2.1001C8.61892 2.3501 8.83892 2.5401 9.08892 2.5401V2.5501Z"
          fill={color}
        />
        <path
          d="M4.91184 4.31994C5.02184 4.31994 5.13184 4.27994 5.23184 4.20994C5.42184 4.04994 5.62184 3.89994 5.83184 3.75994C6.06184 3.60994 6.12184 3.29994 5.97184 3.06994C5.82184 2.83994 5.50184 2.77994 5.28184 2.92994C5.04184 3.08994 4.82184 3.25994 4.60184 3.43994C4.39184 3.61994 4.36184 3.92994 4.53184 4.13994C4.63184 4.25994 4.77184 4.31994 4.92184 4.31994H4.91184Z"
          fill={color}
        />
        <path
          d="M6.86813 3.13005C6.93813 3.13005 6.99813 3.12005 7.06813 3.09005C7.29813 2.99005 7.52813 2.91005 7.76813 2.83005C8.02813 2.75005 8.17813 2.47005 8.09813 2.20005C8.01813 1.93005 7.73813 1.79005 7.46813 1.87005C7.19813 1.95005 6.92813 2.05005 6.66813 2.16005C6.41813 2.27005 6.29813 2.56005 6.40813 2.82005C6.48813 3.01005 6.67813 3.12005 6.86813 3.12005V3.13005Z"
          fill={color}
        />
        <path
          d="M2.21958 8.06005C2.26958 8.08005 2.31958 8.08005 2.36958 8.08005C2.57958 8.08005 2.77958 7.94005 2.84958 7.73005C2.92958 7.49005 3.00958 7.26005 3.10958 7.03005C3.21958 6.78005 3.10958 6.48005 2.84958 6.37005C2.59958 6.26005 2.29958 6.37005 2.18958 6.63005C2.07958 6.89005 1.97958 7.15005 1.88958 7.43005C1.80958 7.69005 1.94958 7.97005 2.21958 8.06005Z"
          fill={color}
        />
        <path
          d="M16.4493 6.17006C16.5793 6.38006 16.6893 6.61006 16.7993 6.83006C16.8793 7.01006 17.0693 7.12006 17.2493 7.12006C17.3193 7.12006 17.3893 7.10006 17.4593 7.07006C17.7093 6.95006 17.8193 6.66006 17.6993 6.41006C17.5793 6.15006 17.4493 5.90006 17.2993 5.66006C17.1593 5.42006 16.8493 5.34006 16.6093 5.49006C16.3693 5.63006 16.2893 5.94006 16.4393 6.18006L16.4493 6.17006Z"
          fill={color}
        />
        <path
          d="M11.2913 2.61006C11.5413 2.65006 11.7813 2.71006 12.0213 2.78006C12.0613 2.79006 12.1113 2.80006 12.1513 2.80006C12.3713 2.80006 12.5713 2.66006 12.6313 2.43006C12.7013 2.16006 12.5513 1.89006 12.2813 1.81006C12.0113 1.73006 11.7313 1.67006 11.4513 1.62006C11.1813 1.57006 10.9213 1.76006 10.8713 2.03006C10.8213 2.30006 11.0113 2.56006 11.2813 2.61006H11.2913Z"
          fill={color}
        />
        <path
          d="M17.2686 8.15989C17.3286 8.39989 17.3786 8.64989 17.4186 8.89989C17.4586 9.14989 17.6686 9.3299 17.9086 9.3299C17.9286 9.3299 17.9586 9.3299 17.9786 9.3299C18.2486 9.2899 18.4386 9.0399 18.3986 8.7599C18.3586 8.4799 18.2986 8.19989 18.2286 7.92989C18.1586 7.65989 17.8886 7.4999 17.6186 7.5699C17.3486 7.6399 17.1886 7.9099 17.2586 8.1799L17.2686 8.15989Z"
          fill={color}
        />
        <path
          d="M13.3314 3.27997C13.5514 3.38997 13.7714 3.50997 13.9814 3.64997C14.0614 3.69997 14.1514 3.72997 14.2514 3.72997C14.4214 3.72997 14.5814 3.64997 14.6714 3.49997C14.8214 3.26997 14.7514 2.95997 14.5114 2.80997C14.2714 2.65997 14.0214 2.51997 13.7714 2.38997C13.5214 2.26997 13.2214 2.36997 13.1014 2.61997C12.9814 2.86997 13.0814 3.16997 13.3314 3.28997V3.27997Z"
          fill={color}
        />
        <path
          d="M15.619 5.0399C15.719 5.1499 15.859 5.2099 15.999 5.2099C16.119 5.2099 16.239 5.1699 16.329 5.0899C16.539 4.9099 16.559 4.5899 16.369 4.3799C16.179 4.1699 15.979 3.9599 15.779 3.7699C15.579 3.5799 15.259 3.5899 15.069 3.7999C14.879 3.9999 14.889 4.3199 15.099 4.5099C15.279 4.6799 15.459 4.8599 15.619 5.0499V5.0399Z"
          fill={color}
        />
        <path
          d="M16.9892 13.9301C16.7592 13.7801 16.4492 13.8501 16.2992 14.0801C16.1592 14.2901 16.0192 14.4901 15.8592 14.6901C15.6892 14.9101 15.7192 15.2201 15.9392 15.3901C16.0292 15.4601 16.1392 15.5001 16.2492 15.5001C16.3992 15.5001 16.5392 15.4401 16.6392 15.3101C16.8192 15.0901 16.9792 14.8601 17.1392 14.6201C17.2892 14.3901 17.2192 14.0801 16.9892 13.9301Z"
          fill={color}
        />
        <path
          d="M14.8813 15.7001C14.6913 15.8601 14.4913 16.0201 14.2913 16.1601C14.0613 16.3201 14.0113 16.6301 14.1713 16.8601C14.2713 17.0001 14.4213 17.0701 14.5813 17.0701C14.6813 17.0701 14.7813 17.0401 14.8713 16.9801C15.1013 16.8201 15.3313 16.6401 15.5413 16.4601C15.7513 16.2801 15.7713 15.9601 15.5913 15.7501C15.4113 15.5401 15.1013 15.5201 14.8813 15.7001Z"
          fill={color}
        />
        <path
          d="M13.0698 16.8501C12.8398 16.9501 12.6098 17.0401 12.3698 17.1201C12.1098 17.2101 11.9698 17.4901 12.0498 17.7501C12.1198 17.9601 12.3198 18.0901 12.5198 18.0901C12.5698 18.0901 12.6298 18.0901 12.6798 18.0601C12.9498 17.9701 13.2098 17.8701 13.4698 17.7501C13.7198 17.6401 13.8298 17.3401 13.7198 17.0901C13.6098 16.8401 13.3098 16.7201 13.0598 16.8401L13.0698 16.8501Z"
          fill={color}
        />
        <path
          d="M10.9999 17.4301C10.7499 17.4601 10.5099 17.4801 10.2499 17.4901C9.96987 17.4901 9.75987 17.7301 9.76987 18.0101C9.76987 18.2801 9.99987 18.4901 10.2699 18.4901C10.2699 18.4901 10.2799 18.4901 10.2899 18.4901C10.5799 18.4901 10.8599 18.4601 11.1399 18.4201C11.4099 18.3801 11.6099 18.1301 11.5699 17.8601C11.5299 17.5901 11.2799 17.4001 11.0099 17.4301H10.9999Z"
          fill={color}
        />
        <path
          d="M17.8193 11.79C17.5493 11.71 17.2793 11.86 17.1993 12.13C17.1293 12.37 17.0493 12.61 16.9493 12.84C16.8493 13.1 16.9693 13.39 17.2193 13.49C17.2793 13.52 17.3493 13.53 17.4093 13.53C17.6093 13.53 17.7893 13.41 17.8693 13.22C17.9793 12.96 18.0693 12.69 18.1493 12.42C18.2293 12.15 18.0793 11.88 17.8093 11.8L17.8193 11.79Z"
          fill={color}
        />
        <path
          d="M18 9.5C17.72 9.5 17.5 9.72 17.5 10C17.5 10.25 17.49 10.5 17.46 10.75C17.43 11.02 17.63 11.27 17.91 11.3C17.93 11.3 17.94 11.3 17.96 11.3C18.21 11.3 18.43 11.11 18.46 10.85C18.49 10.57 18.5 10.29 18.5 10C18.5 9.71 18.28 9.5 18 9.5Z"
          fill={color}
        />
        <path
          d="M8.84862 17.4101C8.59862 17.3701 8.35862 17.3201 8.11862 17.2601C7.84862 17.1901 7.57862 17.3501 7.50862 17.6201C7.43862 17.8901 7.59862 18.1601 7.86862 18.2301C8.13862 18.3001 8.41862 18.3601 8.69862 18.4001C8.72862 18.4001 8.74862 18.4001 8.77862 18.4001C9.01862 18.4001 9.23862 18.2201 9.26862 17.9801C9.30862 17.7101 9.11862 17.4501 8.84862 17.4101Z"
          fill={color}
        />
        <path
          d="M4.46951 15.0701C4.27951 14.8701 3.96951 14.8501 3.75951 15.0401C3.55951 15.2301 3.53951 15.5401 3.72951 15.7501C3.91951 15.9601 4.11951 16.1601 4.32951 16.3501C4.42951 16.4401 4.53951 16.4801 4.65951 16.4801C4.79951 16.4801 4.92951 16.4201 5.02951 16.3101C5.20951 16.1001 5.19951 15.7901 4.98951 15.6001C4.79951 15.4301 4.61951 15.2601 4.45951 15.0701H4.46951Z"
          fill={color}
        />
        <path
          d="M3.61921 13.9499C3.48921 13.7399 3.36921 13.5199 3.25921 13.2899C3.13921 13.0399 2.83921 12.9399 2.58921 13.0599C2.33921 13.1799 2.23921 13.4799 2.35921 13.7299C2.47921 13.9799 2.61921 14.2299 2.76921 14.4699C2.85921 14.6199 3.02921 14.7099 3.19921 14.7099C3.28921 14.7099 3.37921 14.6899 3.45921 14.6299C3.68921 14.4799 3.76921 14.1799 3.61921 13.9399V13.9499Z"
          fill={color}
        />
        <path
          d="M6.80046 16.78C6.57046 16.67 6.35046 16.55 6.14046 16.43C5.90046 16.29 5.60046 16.36 5.45046 16.6C5.31046 16.84 5.38046 17.14 5.62046 17.29C5.86046 17.44 6.11046 17.57 6.37046 17.69C6.44046 17.72 6.51046 17.74 6.58046 17.74C6.77046 17.74 6.95046 17.63 7.03046 17.45C7.15046 17.2 7.04046 16.9 6.79046 16.78H6.80046Z"
          fill={color}
        />
        <path
          d="M2.77128 11.98C2.70128 11.74 2.65128 11.5 2.61128 11.25C2.56128 10.98 2.31128 10.79 2.03128 10.84C1.76128 10.89 1.57128 11.14 1.62128 11.42C1.67128 11.7 1.73128 11.98 1.80128 12.25C1.86128 12.47 2.06128 12.62 2.28128 12.62C2.32128 12.62 2.37128 12.62 2.41128 12.6C2.68128 12.53 2.83128 12.25 2.76128 11.99L2.77128 11.98Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19324">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
