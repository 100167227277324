import type { IconChildrenProps } from "../icon.types";

export const Workspace = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M4.65426 3.64645C4.74803 3.55268 4.8752 3.5 5.00781 3.5H7.50781C7.78395 3.5 8.00781 3.72386 8.00781 4V5.5H8.50781V4C8.50781 3.72386 8.73167 3.5 9.00781 3.5H11.0078C11.284 3.5 11.5078 3.72386 11.5078 4V5.5H12.0078V4C12.0078 3.72386 12.2317 3.5 12.5078 3.5H15.0078C15.1404 3.5 15.2676 3.55268 15.3614 3.64645C15.4551 3.74021 15.5078 3.86739 15.5078 4V15.5C15.5078 16.0523 15.0601 16.5 14.5078 16.5H12.2578C11.7055 16.5 11.2578 16.0523 11.2578 15.5V12.5H8.75781V15.5C8.75781 16.0523 8.3101 16.5 7.75781 16.5H5.50781C4.95553 16.5 4.50781 16.0523 4.50781 15.5V4C4.50781 3.86739 4.56049 3.74021 4.65426 3.64645ZM5.50781 4.5V15.5H7.75781V12.5C7.75781 11.9477 8.20553 11.5 8.75781 11.5H11.2578C11.8101 11.5 12.2578 11.9477 12.2578 12.5V15.5H14.5078V4.5H13.0078V6C13.0078 6.27614 12.784 6.5 12.5078 6.5H11.0078C10.7317 6.5 10.5078 6.27614 10.5078 6V4.5H9.50781V6C9.50781 6.27614 9.28396 6.5 9.00781 6.5H7.50781C7.23167 6.5 7.00781 6.27614 7.00781 6V4.5H5.50781Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M2.00781 16C2.00781 15.7239 2.23167 15.5 2.50781 15.5H17.5078C17.784 15.5 18.0078 15.7239 18.0078 16C18.0078 16.2761 17.784 16.5 17.5078 16.5H2.50781C2.23167 16.5 2.00781 16.2761 2.00781 16Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
