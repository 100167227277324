import type { IconChildrenProps } from "../../icon.types";

export const HazardsMachineInteraction = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M10.3896 3.44839C11.526 4.58481 11.526 6.42732 10.3896 7.56375C9.25313 8.70017 7.41062 8.70017 6.27419 7.56375C5.13777 6.42732 5.13777 4.58481 6.27419 3.44839C7.41062 2.31196 9.25313 2.31196 10.3896 3.44839"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M11.9685 11.5927C10.886 11.1602 9.61602 10.9219 8.33268 10.9219C4.95935 10.9219 1.66602 12.561 1.66602 15.0819V15.9152C1.66602 16.3752 2.03935 16.7485 2.49935 16.7485H10.3435"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M14.1667 19.2628C11.8658 19.2628 10 17.3978 10 15.0961C10 12.8428 11.9167 10.9269 14.17 10.9294C16.47 10.9311 18.3333 12.7961 18.3333 15.0961C18.3333 17.3969 16.4683 19.2628 14.1667 19.2628"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M14.1647 13.4294V13.9877"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M14.1647 16.2044V16.7627"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M12.7238 14.2627L13.2072 14.5377"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M15.1323 15.6544L15.6073 15.9294"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M12.7238 15.9294L13.2072 15.6544"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M15.1323 14.5377L15.6073 14.2627"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M14.9505 14.31C15.3846 14.7441 15.3846 15.448 14.9505 15.8821C14.5163 16.3162 13.8125 16.3162 13.3783 15.8821C12.9442 15.448 12.9442 14.7441 13.3783 14.31C13.8125 13.8758 14.5163 13.8758 14.9505 14.31"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
  </>
);
