import { v4 as uuid } from "uuid";
import { type AuthStorage, AuthTenant } from "./auth.types";

const authStorageKeyPrefix = "auth-state-";

const getAuthStorageKey = (id: string) => `${authStorageKeyPrefix}${id}`;

export const clearAuthStorage = () =>
  Object.keys(window.localStorage)
    .filter((i) => i.startsWith(authStorageKeyPrefix))
    .forEach((i) => window.localStorage.removeItem(i));

export const getAuthStorage = (id: string) => {
  const storageId = getAuthStorageKey(id);
  const data = window.localStorage.getItem(storageId);
  window.localStorage.removeItem(storageId);
  if (data) {
    const result = JSON.parse(data) as Record<string, unknown>;
    if (
      result &&
      "tenant" in result &&
      (result as AuthStorage).tenant &&
      (Object.values(AuthTenant) as string[]).includes(
        (result as AuthStorage).tenant,
      )
    ) {
      return result as AuthStorage;
    }
  }
};

export const setAuthStorage = (data: AuthStorage) => {
  const id = uuid();
  window.localStorage.setItem(getAuthStorageKey(id), JSON.stringify(data));
  return id;
};
