import type { IconChildrenProps } from "../icon.types";

export const DragAndDrop = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M7.49219 7C8.32061 7 8.99219 6.32843 8.99219 5.5C8.99219 4.67157 8.32061 4 7.49219 4C6.66376 4 5.99219 4.67157 5.99219 5.5C5.99219 6.32843 6.66376 7 7.49219 7ZM12.4922 7C13.3206 7 13.9922 6.32843 13.9922 5.5C13.9922 4.67157 13.3206 4 12.4922 4C11.6638 4 10.9922 4.67157 10.9922 5.5C10.9922 6.32843 11.6638 7 12.4922 7ZM13.9922 10.5C13.9922 11.3284 13.3206 12 12.4922 12C11.6638 12 10.9922 11.3284 10.9922 10.5C10.9922 9.67157 11.6638 9 12.4922 9C13.3206 9 13.9922 9.67157 13.9922 10.5ZM12.4922 17C13.3206 17 13.9922 16.3284 13.9922 15.5C13.9922 14.6716 13.3206 14 12.4922 14C11.6638 14 10.9922 14.6716 10.9922 15.5C10.9922 16.3284 11.6638 17 12.4922 17ZM8.99219 10.5C8.99219 11.3284 8.32061 12 7.49219 12C6.66376 12 5.99219 11.3284 5.99219 10.5C5.99219 9.67157 6.66376 9 7.49219 9C8.32061 9 8.99219 9.67157 8.99219 10.5ZM7.49219 17C8.32061 17 8.99219 16.3284 8.99219 15.5C8.99219 14.6716 8.32061 14 7.49219 14C6.66376 14 5.99219 14.6716 5.99219 15.5C5.99219 16.3284 6.66376 17 7.49219 17Z"
    fill={color}
    fillRule="evenodd"
  />
);
