import type { IconChildrenProps } from "../icon.types";

export const CaretUp = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M6.76688 12.2584L10.0002 9.02502L13.2335 12.2584C13.4434 12.4682 13.7494 12.5502 14.0361 12.4734C14.3228 12.3966 14.5468 12.1726 14.6236 11.8859C14.7004 11.5992 14.6184 11.2932 14.4085 11.0834L10.5835 7.25835C10.4278 7.10233 10.2165 7.01465 9.99605 7.01465C9.77562 7.01465 9.56425 7.10233 9.40855 7.25835L5.58355 11.0834C5.42752 11.2391 5.33984 11.4504 5.33984 11.6709C5.33984 11.8913 5.42752 12.1026 5.58355 12.2584C5.90855 12.575 6.44188 12.5834 6.76688 12.2584Z"
    fill={color}
    fillRule="evenodd"
  />
);
