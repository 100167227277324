import type { IconChildrenProps } from "../icon.types";

export const BusinessChart = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M18.9977 4.57042C18.9473 3.32603 17.9231 2.33331 16.6663 2.33331H4.22199L4.12578 2.33526C2.88139 2.38568 1.88867 3.40982 1.88867 4.66663V7.33327L1.89154 7.39007C1.91999 7.67021 2.15658 7.88882 2.44422 7.88882C2.75105 7.88882 2.99977 7.64009 2.99977 7.33327V4.66663L3.002 4.59213C3.04046 3.95148 3.57161 3.44441 4.22199 3.44441H16.6663L16.7408 3.44664C17.3815 3.4851 17.8885 4.01625 17.8885 4.66663V15.3332L17.8863 15.4077C17.8479 16.0484 17.3167 16.5554 16.6663 16.5554H11.333L11.2762 16.5583C10.9961 16.5867 10.7775 16.8233 10.7775 17.111C10.7775 17.4178 11.0262 17.6665 11.333 17.6665H16.6663L16.7625 17.6646C18.0069 17.6142 18.9996 16.59 18.9996 15.3332V4.66663L18.9977 4.57042ZM10.108 7.27647C10.0795 6.99633 9.84292 6.77772 9.55528 6.77772C9.24845 6.77772 8.99972 7.02645 8.99972 7.33327V12.6666L9.00259 12.7234C9.03104 13.0035 9.26763 13.2221 9.55528 13.2221C9.8621 13.2221 10.1108 12.9734 10.1108 12.6666V7.33327L10.108 7.27647ZM12.2219 9.15992C12.5096 9.15992 12.7462 9.37853 12.7746 9.65867L12.7775 9.71547V12.6666C12.7775 12.9734 12.5287 13.2221 12.2219 13.2221C11.9343 13.2221 11.6977 13.0035 11.6692 12.7234L11.6664 12.6666V9.71547C11.6664 9.40865 11.9151 9.15992 12.2219 9.15992ZM15.4412 7.76536C15.4128 7.48522 15.1762 7.26661 14.8886 7.26661C14.5817 7.26661 14.333 7.51533 14.333 7.82216V12.6666L14.3359 12.7234C14.3643 13.0035 14.6009 13.2221 14.8886 13.2221C15.1954 13.2221 15.4441 12.9734 15.4441 12.6666V7.82216L15.4412 7.76536Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M3.17374 10.0631C3.97374 9.26308 5.25504 9.23884 6.08337 9.99061L6.16633 10.0701L6.23203 10.1394C6.95919 10.9417 6.95919 12.1689 6.23203 12.9723L6.15939 13.0487C5.33537 13.8727 3.99896 13.8727 3.17374 13.0487C2.34972 12.2247 2.34972 10.8883 3.17374 10.0631ZM5.37343 10.8485C4.9833 10.4578 4.3504 10.4578 3.95969 10.8485C3.56921 11.2395 3.56921 11.8729 3.95912 12.2628C4.35017 12.6533 4.98352 12.6533 5.37343 12.2633C5.74532 11.8909 5.76303 11.2987 5.42681 10.9061L5.37343 10.8485ZM7.41992 15.3973C6.9387 14.951 6.30527 14.703 5.64811 14.703H3.68546L3.56261 14.7059C2.95009 14.7348 2.36585 14.979 1.91355 15.3974C1.62882 15.6606 1.40621 15.9849 1.26266 16.3455L1.0396 16.9054L1.02124 16.9592C0.943986 17.23 1.08286 17.5206 1.35008 17.6271C1.63511 17.7406 1.95824 17.6016 2.0718 17.3166L2.29491 16.7566L2.33293 16.6693C2.41441 16.4974 2.52816 16.3424 2.66796 16.2131C2.94532 15.9565 3.30851 15.8141 3.68546 15.8141H5.64811L5.74208 15.817C6.0853 15.8386 6.41183 15.9778 6.665 16.2125C6.82871 16.3639 6.95635 16.5498 7.03861 16.7565L7.26177 17.3166L7.28546 17.3683C7.41557 17.618 7.71627 17.7335 7.98349 17.6271C8.26852 17.5135 8.40753 17.1904 8.29397 16.9054L8.07086 16.3454L8.02012 16.2266C7.87712 15.9136 7.6731 15.6314 7.41992 15.3973Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
