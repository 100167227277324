import type { IconChildrenProps } from "../icon.types";

export const CheckboxSelected = ({
  color,
  oppositeColor,
}: IconChildrenProps) => (
  <>
    <rect fill={color} height="16" rx="3" width="16" x="2" y="2" />
    <path
      d="M13.3047 7.28248L14.6978 8.71752L8.85061 14.3937L5.30469 10.9515L6.69776 9.51642L8.85023 11.606L13.3047 7.28248Z"
      fill={oppositeColor}
    />
  </>
);
