import type { IconChildrenProps } from "../../icon.types";

export const SolutionsAutomativeParts = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M11.1875 1.85791C12.9133 1.85791 14.3125 3.25708 14.3125 4.98291"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M3.33268 11.0328L1.66602 11.0245"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M11.666 11.0328L13.3327 11.0245"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M11.6654 11.0303L10.6795 12.4537C10.2129 13.1278 9.44453 13.5303 8.62453 13.5303H6.37286C5.55286 13.5303 4.78453 13.1278 4.31786 12.4537L3.33203 11.0303"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M17.4987 1.85791V3.52458"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M17.4987 6.85785V8.52452"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M17.4987 11.8578V13.5245"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M17.4987 16.8579V18.5246"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      clipRule="evenodd"
      d="M9.16536 18.5245H5.83203C4.4512 18.5245 3.33203 17.4054 3.33203 16.0245V6.02452C3.33203 5.10368 4.07786 4.35785 4.9987 4.35785H9.9987C10.9195 4.35785 11.6654 5.10368 11.6654 6.02452V16.0245C11.6654 17.4054 10.5462 18.5245 9.16536 18.5245Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M11.6654 7.6912H3.33203"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
  </>
);
