import { Link } from "react-router-dom";
import { AuthButton } from "~/components/auth-button";
import { Input } from "~/components/input";
import { Text } from "~/components/text";
import { useUi } from "~/features/ui/ui.hooks";
import { FormCard } from "../form-card/form-card";
import { useChangePasswordForm } from "./change-password-form.hooks";
import css from "./change-password-form.module.css";

export const ChangePasswordForm = () => {
  const {
    email,
    emailError,
    error,
    loading,
    onEmailChange,
    onPressSubmit,
    onSubmit,
    success,
  } = useChangePasswordForm();

  const { isMobile } = useUi();

  return (
    <FormCard title="Change Password">
      <form onSubmit={onSubmit}>
        <Input
          autoCapitalize="off"
          autoComplete="email"
          autoCorrect="off"
          className={css.input}
          data-testid="change-password-input-email"
          disabled={loading}
          error={emailError}
          fullWidth
          name="email"
          onChange={onEmailChange}
          placeholder="Email"
          shape="rounded"
          spellCheck={false}
          type="email"
          value={email}
        />
        {error ? (
          <div className={css.error}>
            <Text
              align="center"
              color="red-20"
              fontWeight="text-semibold"
              fontSize={isMobile ? "text-md" : "text-lg"}
              fullWidth
            >
              Invalid email. Please check your information and try again.
            </Text>
          </div>
        ) : null}
        {success ? (
          <div className={css.success}>
            <Text
              align="center"
              color="success-20"
              fontWeight="text-semibold"
              fontSize={isMobile ? "text-md" : "text-lg"}
              fullWidth
            >
              {`We've just sent you an email to reset your password.`}
            </Text>
          </div>
        ) : null}
        <AuthButton
          className={css.button}
          data-testid="change-password-button-submit"
          disabled={!email || emailError || loading}
          fullWidth
          loading={loading}
          onPress={onPressSubmit}
        >
          Change password
        </AuthButton>
      </form>
      <div className={css.link}>
        <Link data-testid="change-password-link-login" to="/login">
          <Text
            align="center"
            fontSize={isMobile ? "text-sm" : "text-md"}
            fullWidth
          >
            Go back to login
          </Text>
        </Link>
      </div>
    </FormCard>
  );
};
