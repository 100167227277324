import type { IconChildrenProps } from "../icon.types";

export const CaretLeft = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M12.2574 13.2333L9.02404 9.99997L12.2574 6.76664C12.4673 6.55674 12.5492 6.25082 12.4724 5.9641C12.3956 5.67738 12.1716 5.45342 11.8849 5.3766C11.5982 5.29977 11.2923 5.38174 11.0824 5.59164L7.25738 9.41664C7.10135 9.57234 7.01367 9.78371 7.01367 10.0041C7.01367 10.2246 7.10135 10.4359 7.25738 10.5916L11.0824 14.4166C11.4074 14.7416 11.9324 14.7416 12.2574 14.4166C12.574 14.0916 12.5824 13.5583 12.2574 13.2333Z"
    fill={color}
    fillRule="evenodd"
  />
);
