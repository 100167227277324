import type { IconChildrenProps } from "../icon.types";

export const CheckCircle = ({
  confirmColor,
  oppositeColor,
}: IconChildrenProps) => (
  <>
    <rect x="2" y="2" width="16" height="16" rx="8" fill={confirmColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3713 7.03864C13.6644 6.76162 14.1228 6.76162 14.416 7.03864C14.7335 7.33872 14.7335 7.84405 14.416 8.14413L9.38761 12.896C9.00216 13.2602 8.39939 13.2602 8.01394 12.896L5.58582 10.6014C5.26827 10.3013 5.26827 9.79596 5.58582 9.49588C5.87896 9.21886 6.33738 9.21886 6.63052 9.49588L8.70078 11.4523L13.3713 7.03864Z"
      fill={oppositeColor}
    />
  </>
);
