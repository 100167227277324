// import type { ThemeColorName, ThemePaletteVariableName } from "../theme.types";

import {
  buttonColors,
  buttonSecondaryColors,
  buttonTextColors,
} from "./button-colors.light";

/* eslint-disable sort-keys */
export const themeVariantLight = {
  palette: {
    ...buttonColors,
    ...buttonSecondaryColors,
    ...buttonTextColors,

    // "component-part?-depth?-state?-property(bg-fg-border)"

    "card-bg": "white-00",
    "card-collapsed-bg": "white-00",
    "card-collapsed-border": "beige-20",
    "card-highlight-bg": "dark-20",
    "card-hover-bg": "beige-40",
    "card-selected-bg": "dark-00",

    "container-level1-bg": "beige-40",
    "container-level2-bg": "beige-20",
    "container-level3-bg": "beige-40",
    "container-level4-bg": "beige-30",
    "container-level4-border": "beige-20",

    "drawer-bg": "white-00",
    "drawer-card-bg": "beige-30",

    "foreground-highlight": "red-20",
    "foreground-primary": "dark-20",
    "foreground-secondary": "dark-50",
    "foreground-tertiary": "dark-70",
    "foreground-contrast": "beige-20",
    "foreground-disabled": "dark-90",

    "input-idle-bg": "white-00",
    "input-idle-border": "dark-90",
    "input-hover-bg": "tone-40",
    "input-selected-border": "blush-20",
    "input-disabled-bg": "beige-40",
    "input-disabled-border": "beige-00",
    "input-idle-icon": "dark-60",
    "input-disabled-icon": "dark-90",

    "chip-primary-level1-bg": "beige-20",
    "chip-primary-level2-bg": "beige-30",
    "chip-primary-level3-bg": "beige-20",
    "chip-primary-level4-bg": "white-00",
    "chip-contrast-bg": "dark-20",
    "chip-contrast-fg": "beige-20",
    "chip-contrast-icon": "beige-20",
    "chip-lightBlue-bg": "navy-80",
    "chip-information-bg": "navy-20",
    "chip-purple-bg": "purple-70",
    "chip-success-bg": "success-40",
    "chip-complete-bg": "success-80",
    "chip-honey-bg": "honey-80",
    "chip-gray-bg": "midgrey-80",
    "chip-error-bg": "red-40",
    "chip-fatalError-bg": "red-20",
    "chip-icon": "dark-20",
    "chip-fg": "dark-20",

    "main-bg": "midgrey-90",
    "main-header-bg": "white-00",

    "modal-container-bg": "midgrey-90",
    "modal-container-body-bg": "white-00",
    "modal-header-bg": "white-00",

    "sidebar-bg": "dark-20",
    "sidebar-button-bg": "darker-30",
    "sidebar-button-border": "darker-70",
    "sidebar-icon": "red-20",

    "table-header-bg": "white-00",
    "table-header-border": "beige-20",
    "table-header-level-2-bg": "beige-30",
    "table-header-level-2-border": "beige-10",
    "table-line-bg": "white-00",
    "table-line-border": "beige-20",
    "table-line-active-bg": "navy-90",
    "table-line-active-border": "beige-20",
    "table-line-hover-bg": "tone-40",
    "table-line-hover-border": "beige-20",
  },
};
