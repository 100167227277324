import type { IconChildrenProps } from "../icon.types";

export const Check = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M16.714 6.74362C17.1045 6.35312 17.1045 5.71992 16.714 5.32942C16.3235 4.93892 15.6903 4.93892 15.2998 5.32942L7.72682 12.869L4.91215 9.99999C4.52165 9.60949 3.88845 9.60949 3.49795 9.99999C3.10745 10.3905 3.10745 11.0237 3.49795 11.4142L6.31252 14.2833L7.44398 15.4147C7.60019 15.5709 7.85345 15.5709 8.00965 15.4147L9.14102 14.2833L16.714 6.74362Z"
    fill={color}
    fillRule="evenodd"
  />
);
