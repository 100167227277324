import { formatVar, px } from "~/utils/css";
import { initialThemeContextValue } from "./theme.context";
import type {
  ThemeBorderRadiusName,
  ThemeColorName,
  ThemeFontSizeName,
  ThemeFontWeightName,
  ThemePaletteVariableName,
  ThemeShadowName,
  ThemeSizeName,
  ThemeSpacingName,
} from "./theme.types";

export const getThemeStyle = () => {
  const {
    borderRadius,
    colors,
    fontSizes,
    fontWeights,
    shadows,
    sizes,
    spacings,
  } = initialThemeContextValue;

  const cssVars: string[] = [];

  // Add theme colors CSS variables
  for (const key in colors) {
    cssVars.push(formatVar(key, colors[key as ThemeColorName]));
  }

  // Add theme fontSizes CSS variables
  for (const key in fontSizes) {
    cssVars.push(formatVar(key, px(fontSizes[key as ThemeFontSizeName])));
  }

  // Add theme colors CSS variables
  for (const key in fontWeights) {
    cssVars.push(formatVar(key, fontWeights[key as ThemeFontWeightName]));
  }

  // Add theme shadows CSS variables
  for (const key in shadows) {
    cssVars.push(formatVar(key, shadows[key as ThemeShadowName]));
  }

  // Add theme sizes CSS variables
  for (const key in sizes) {
    cssVars.push(formatVar(key, px(sizes[key as ThemeSizeName])));
  }

  // Add theme spacing CSS variables
  for (const key in spacings) {
    cssVars.push(
      formatVar(
        key,
        px(sizes[spacings[key as ThemeSpacingName] as ThemeSizeName]),
      ),
    );
  }

  // Add theme radius CSS variables
  for (const key in borderRadius) {
    cssVars.push(
      formatVar(
        key,
        px(sizes[borderRadius[key as ThemeBorderRadiusName] as ThemeSizeName]),
      ),
    );
  }

  return `
    :root {
      ${cssVars.join("")}
    }
  `;
};

export const getThemePalette = () => {
  const { colors, palette } = initialThemeContextValue;

  const cssVars: string[] = [];

  // Add theme palette CSS variables
  for (const key in palette) {
    cssVars.push(
      formatVar(
        key,
        colors[palette[key as ThemePaletteVariableName] as ThemeColorName],
      ),
    );
  }

  return `
    :root {
      ${cssVars.join("")}
    }
  `;
};
