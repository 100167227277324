import type { IconChildrenProps } from "../icon.types";

export const BookmarkList = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M6.75138 1.02032H15.9221C16.9729 1.02032 17.8327 1.88008 17.8327 2.93089V14.3943C17.8327 15.4451 16.9729 16.3049 15.9221 16.3049H6.75138C5.70057 16.3049 4.84081 15.4451 4.84081 14.3943V2.93089C4.84081 1.88008 5.70057 1.02032 6.75138 1.02032ZM13.5339 9.14024L15.9221 10.5732V2.93089H11.1457V10.5732L13.5339 9.14024ZM2.54813 3.69512C2.75917 3.69512 2.93024 3.8662 2.93024 4.07723V17.0691C2.93024 17.7022 3.44348 18.2154 4.07658 18.2154H17.0685C17.2795 18.2154 17.4506 18.3865 17.4506 18.5976C17.4506 18.8086 17.2795 18.9797 17.0685 18.9797H4.07658C3.02577 18.9797 2.16602 18.1199 2.16602 17.0691V4.07724C2.16602 3.8662 2.33709 3.69512 2.54813 3.69512Z"
    fill={color}
    fillRule="evenodd"
  />
);
