import type { IconChildrenProps } from "~/components/icon/icon.types";
import { AR_CATEGORY_AREA_CONTROLS } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";

export const UseCasePersonMandatoryArea = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();

  return (
    <>
      <g clipPath="url(#clip0_5697_6569)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.007 9.98005C10.0054 9.98006 10.0037 9.98006 10.002 9.98006H10.012C10.0104 9.98006 10.0087 9.98006 10.007 9.98005ZM11.942 9.18006C11.4033 9.7088 10.7054 9.97877 10.007 9.98005C9.30865 9.97877 8.60077 9.7088 8.07203 9.18006C7.00203 8.11006 7.00203 6.37006 8.07203 5.30006C9.13203 4.23006 10.872 4.23006 11.942 5.30006C13.012 6.37006 13.012 8.11006 11.942 9.18006ZM10.002 5.70006C9.60203 5.70006 9.21203 5.85006 8.91203 6.15006C8.62203 6.44006 8.46203 6.83006 8.46203 7.24006C8.46203 7.65006 8.62203 8.04006 8.91203 8.33006C9.51203 8.93006 10.492 8.93006 11.092 8.33006C11.692 7.73006 11.692 6.75006 11.092 6.15006C10.792 5.85006 10.402 5.70006 10.002 5.70006ZM8.73047 10.7901H11.2605C12.9105 10.7901 14.2605 12.1301 14.2605 13.7901V14.8901C14.2605 15.2201 13.9905 15.4901 13.6605 15.4901C13.3305 15.4901 13.0605 15.2201 13.0605 14.8901V13.7901C13.0605 12.8001 12.2605 11.9901 11.2605 11.9901H8.73047C7.74047 11.9901 6.93047 12.7901 6.93047 13.7901V14.8901C6.93047 15.2201 6.66047 15.4901 6.33047 15.4901C6.00047 15.4901 5.73047 15.2201 5.73047 14.8901V13.7901C5.73047 12.1401 7.07047 10.7901 8.73047 10.7901Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 2.5C3.17157 2.5 2.5 3.17157 2.5 4V6.4C2.5 6.67614 2.27614 6.9 2 6.9C1.72386 6.9 1.5 6.67614 1.5 6.4V4C1.5 2.61929 2.61929 1.5 4 1.5H6.4C6.67614 1.5 6.9 1.72386 6.9 2C6.9 2.27614 6.67614 2.5 6.4 2.5H4ZM13.1 2C13.1 1.72386 13.3239 1.5 13.6 1.5H16C17.3807 1.5 18.5 2.61929 18.5 4V6.4C18.5 6.67614 18.2761 6.9 18 6.9C17.7239 6.9 17.5 6.67614 17.5 6.4V4C17.5 3.17157 16.8284 2.5 16 2.5H13.6C13.3239 2.5 13.1 2.27614 13.1 2ZM2 13.1C2.27614 13.1 2.5 13.3239 2.5 13.6V16C2.5 16.8284 3.17157 17.5 4 17.5H6.4C6.67614 17.5 6.9 17.7239 6.9 18C6.9 18.2761 6.67614 18.5 6.4 18.5H4C2.61929 18.5 1.5 17.3807 1.5 16V13.6C1.5 13.3239 1.72386 13.1 2 13.1ZM18 13.1C18.2761 13.1 18.5 13.3239 18.5 13.6V16C18.5 17.3807 17.3807 18.5 16 18.5H13.6C13.3239 18.5 13.1 18.2761 13.1 18C13.1 17.7239 13.3239 17.5 13.6 17.5H16C16.8284 17.5 17.5 16.8284 17.5 16V13.6C17.5 13.3239 17.7239 13.1 18 13.1Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5697_6569">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
