import type { IconChildrenProps } from "../icon.types";

export const Detection = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0062 2.40002C8.69794 2.40002 7.63742 3.46055 7.63742 4.76876C7.63742 6.07698 8.69794 7.1375 10.0062 7.1375C11.3144 7.1375 12.3749 6.07698 12.3749 4.76876C12.3749 3.46055 11.3144 2.40002 10.0062 2.40002ZM10.0062 3.58402C10.6605 3.58402 11.1909 4.11445 11.1909 4.76876C11.1909 5.42308 10.6605 5.9535 10.0062 5.9535C9.35184 5.9535 8.82142 5.42308 8.82142 4.76876C8.82142 4.11445 9.35184 3.58402 10.0062 3.58402ZM11.9804 7.92759H8.03204C7.2692 7.92759 6.65039 8.54644 6.65039 9.30932V12.0455L6.65606 12.1706C6.69366 12.5844 6.91633 12.9627 7.26579 13.1953L7.8347 13.5736L7.83486 16.4162C7.83486 17.1791 8.45369 17.7979 9.21651 17.7979H10.7959L10.9092 17.7933C11.619 17.7356 12.1775 17.1409 12.1775 16.4162L12.1768 13.5744L12.7468 13.1952C13.131 12.9395 13.362 12.5073 13.362 12.0455V9.30932C13.362 8.54644 12.7432 7.92759 11.9804 7.92759ZM8.03204 9.11159H11.9804C12.0893 9.11159 12.178 9.20033 12.178 9.30932V12.0455C12.178 12.1115 12.1448 12.1736 12.0906 12.2097L11.2573 12.7649C11.0925 12.8747 10.9935 13.0596 10.9935 13.2576V16.4162C10.9935 16.5251 10.9048 16.6139 10.7959 16.6139H9.21651C9.10759 16.6139 9.01886 16.5251 9.01886 16.4162V13.2576C9.01886 13.0596 8.91988 12.8747 8.75512 12.7649L7.92205 12.2098C7.86756 12.1736 7.83439 12.1115 7.83439 12.0455V9.30932C7.83439 9.20033 7.92312 9.11159 8.03204 9.11159Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.7202 2.52941C6.7202 2.23703 6.48317 2 6.19079 2H4.2849L4.19147 2.00176C2.88951 2.05084 1.84961 3.1213 1.84961 4.43529V6.34118L1.85234 6.39531C1.87945 6.66226 2.10491 6.87059 2.37902 6.87059C2.67141 6.87059 2.90843 6.63356 2.90843 6.34118V4.43529L2.91061 4.35716C2.95108 3.63304 3.55062 3.05882 4.2849 3.05882H6.19079L6.24492 3.05609C6.51187 3.02898 6.7202 2.80352 6.7202 2.52941ZM2.9057 13.9106C2.87859 13.6436 2.65313 13.4353 2.37902 13.4353C2.08664 13.4353 1.84961 13.6723 1.84961 13.9647V15.8706L1.85137 15.964C1.90045 17.266 2.97091 18.3059 4.2849 18.3059H6.19079L6.24492 18.3032C6.51187 18.276 6.7202 18.0506 6.7202 17.7765C6.7202 17.4841 6.48317 17.2471 6.19079 17.2471H4.2849L4.20677 17.2449C3.48265 17.2044 2.90843 16.6049 2.90843 15.8706V13.9647L2.9057 13.9106ZM17.6263 13.4353C17.9004 13.4353 18.1259 13.6436 18.153 13.9106L18.1557 13.9647V15.8706C18.1557 17.1846 17.1158 18.2551 15.8139 18.3041L15.7205 18.3059H13.8146C13.5222 18.3059 13.2852 18.0689 13.2852 17.7765C13.2852 17.5024 13.4935 17.2769 13.7604 17.2498L13.8146 17.2471H15.7205C16.4547 17.2471 17.0543 16.6729 17.0947 15.9487L17.0969 15.8706V13.9647C17.0969 13.6723 17.3339 13.4353 17.6263 13.4353ZM18.154 4.34186C18.1049 3.03991 17.0344 2 15.7205 2H13.8146L13.7604 2.00273C13.4935 2.02984 13.2852 2.2553 13.2852 2.52941C13.2852 2.8218 13.5222 3.05882 13.8146 3.05882H15.7205L15.7986 3.061C16.5227 3.10148 17.0969 3.70101 17.0969 4.43529V6.34118L17.0997 6.39531C17.1268 6.66226 17.3522 6.87059 17.6263 6.87059C17.9187 6.87059 18.1557 6.63356 18.1557 6.34118V4.43529L18.154 4.34186Z"
      fill={accentColor}
    />
  </>
);
