import { AR_CATEGORY_VEHICLE } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../icon.types";

export const CategoryVehicleControl2 = ({ color }: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();

  return (
    <>
      <path
        clipRule="evenodd"
        d="M12.9927 1.41415C12.9522 1.18056 12.7495 1.00203 12.5041 1.00003C12.2279 0.997786 12.0023 1.21981 12 1.49595L11.9094 12.6317L10.376 6.31494L10.3264 6.13231C9.89145 4.68949 8.55937 3.68995 7.03763 3.68995H4.53107L4.42385 3.69613C3.96663 3.74924 3.61173 4.13782 3.61173 4.60929L3.611 9.94602L3.05587 9.94638L2.91425 9.95274C2.12134 10.0243 1.5 10.6907 1.5 11.5022V15.398L1.50583 15.5422C1.56905 16.321 2.13996 16.9564 2.88718 17.1168C2.95471 18.4424 4.03127 19.5 5.3547 19.5C6.72187 19.5 7.82559 18.3713 7.82559 16.9845C7.82559 15.5977 6.72187 14.469 5.3547 14.469C4.28608 14.469 3.37841 15.1586 3.03296 16.122C2.72421 16.0262 2.5 15.7383 2.5 15.398V11.5022L2.50728 11.4121C2.55036 11.148 2.77957 10.9464 3.05587 10.9464H3.944L4.04267 10.9391C4.36466 10.8915 4.61173 10.6139 4.61173 10.2786L4.611 4.68902L7.03763 4.68995L7.21377 4.69627C8.26231 4.77178 9.15314 5.51629 9.40427 6.55083L11.1024 13.5463L11.1314 13.6318C11.2276 13.8484 11.4677 13.9722 11.7062 13.9143C11.7806 13.8962 11.847 13.8624 11.9028 13.8172L11.9073 13.8735C11.9478 14.1071 12.1505 14.2856 12.3959 14.2876C12.6721 14.2898 12.8977 14.0678 12.9 13.7917L13 1.50409L12.9927 1.41415ZM6.82559 16.9845C6.82559 16.1451 6.16452 15.469 5.3547 15.469C4.54489 15.469 3.88382 16.1451 3.88382 16.9845C3.88382 17.824 4.54489 18.5 5.3547 18.5C6.16452 18.5 6.82559 17.824 6.82559 16.9845ZM6.8409 5.92297H6.09444C5.5867 5.92297 5.1751 6.33457 5.1751 6.84231V9.96438C5.1751 10.3305 5.46996 10.6284 5.83609 10.6321L8.54434 10.6595C8.85894 10.6626 9.09826 10.3779 9.04106 10.0686L8.53527 7.3328C8.38423 6.51583 7.67172 5.92297 6.8409 5.92297ZM6.175 9.63502V6.92202L6.8409 6.92297L6.94382 6.93028C7.24699 6.97367 7.49489 7.20605 7.55194 7.5146L7.947 9.65302L6.175 9.63502ZM16.0305 16.156C16.3066 16.156 16.5305 16.3799 16.5305 16.656C16.5305 16.9015 16.3536 17.1056 16.1204 17.1479L16.0305 17.156H13.5103C13.2342 17.156 13.0103 16.9321 13.0103 16.656C13.0103 16.4105 13.1872 16.2064 13.4204 16.1641L13.5103 16.156H16.0305Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.6153 12.8395C8.80809 12.8395 7.34766 14.333 7.34766 16.1697C7.34766 18.0065 8.80809 19.5 10.6153 19.5C12.4225 19.5 13.8829 18.0065 13.8829 16.1697C13.8829 14.333 12.4225 12.8395 10.6153 12.8395ZM10.6153 13.8395C11.8651 13.8395 12.8829 14.8803 12.8829 16.1697C12.8829 17.4592 11.8651 18.5 10.6153 18.5C9.36544 18.5 8.34766 17.4592 8.34766 16.1697C8.34766 14.8803 9.36544 13.8395 10.6153 13.8395Z"
        fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        fillRule="evenodd"
      />
    </>
  );
};
