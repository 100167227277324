import { clsx } from "clsx";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useFocus } from "~/hooks/use-focus";
import { Icon, type IconProps } from "../icon";
import { Spinner } from "../spinner";
import { Text } from "../text";
import type { CheckboxProps } from "./checkbox.types";
import css from "./checkbox.module.css";

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checked = false,
      checkboxIconVariant,
      children,
      className,
      classNames,
      disabled = false,
      error = false,
      fullWidth = false,
      indeterminate = false,
      onBlur,
      onFocus,
      loading,
      shape = "text",
      ...other
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLInputElement>(null);

    const { focus, onInternalFocus, onInternalBlur } =
      useFocus<HTMLInputElement>(false, onFocus, onBlur, disabled);

    useEffect(() => {
      if (internalRef.current) {
        internalRef.current.checked = checked;
        internalRef.current.indeterminate = indeterminate;
      }
    }, [checked, indeterminate]);

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      ref,
      () => internalRef.current,
    );

    const checkboxIconProps = useMemo(() => {
      const iconProps: Partial<IconProps> = {};
      if (!checkboxIconVariant) {
        iconProps.inheritColor = true;
      } else {
        iconProps.variant = checkboxIconVariant;
      }
      return iconProps;
    }, [checkboxIconVariant]);

    return (
      <div
        className={clsx(
          css.root,
          className,
          classNames?.root,
          css[shape],
          checked && css.checked,
          indeterminate && css.indeterminate,
          disabled && clsx(css.disabled, classNames?.disabled),
          error && css.error,
          fullWidth && css.fullWidth,
          !disabled && focus ? css.focused : undefined,
          loading && css.loading,
        )}
      >
        <label className={clsx(css.label, classNames?.label)}>
          <input
            ref={ref}
            checked={checked}
            className={css.input}
            type="checkbox"
            {...other}
            disabled={disabled || loading}
            onFocus={onInternalFocus}
            onBlur={onInternalBlur}
          />
          <div className={clsx(css.icons, classNames?.icon)}>
            <span className={css.checkedIcon}>
              <Icon
                {...checkboxIconProps}
                name="checkbox-selected"
                size="size-8"
              />
            </span>
            <span className={css.notCheckedIcon}>
              <Icon
                {...checkboxIconProps}
                name="checkbox-nonselected"
                size="size-8"
              />
            </span>
            <span className={css.indeterminateIcon}>
              <Icon
                {...checkboxIconProps}
                name="checkbox-indeterminate"
                size="size-8"
              />
            </span>
          </div>
          {loading ? (
            <div className={clsx(css.spinner, classNames?.spinner)}>
              <Spinner />
            </div>
          ) : null}
          <div className={clsx(css.children, classNames?.children)}>
            <Text>{children}</Text>
          </div>
        </label>
      </div>
    );
  },
);

Checkbox.displayName = "Checkbox";
