import { useCallback, useMemo } from "react";
import { BaseSelect, type SelectChangeHandler } from "~/components/select";
import { USER_JOB_TITLE } from "./user-job-title-select.constants";
import type { UserJobTitleSelectProps } from "./user-job-title-select.types";

export const UserJobTitleSelect = (props: UserJobTitleSelectProps) => {
  const { onChange, value, ...selectProps } = props;

  const selectOptions = USER_JOB_TITLE.map((title) => ({
    label: title,
    value: title,
  }));

  const selectValue = useMemo(
    () => selectOptions.find((i) => i.value === value),
    [selectOptions, value],
  );

  const onSelectChange = useCallback<SelectChangeHandler>(
    (selectedOption) => {
      if (onChange && selectedOption) {
        onChange(selectedOption.value);
      }
    },
    [onChange],
  );

  return (
    <BaseSelect
      hideSelectedOptions={false}
      isSearchable={false}
      onChange={onSelectChange}
      options={selectOptions}
      placeholder=""
      value={selectValue}
      {...selectProps}
    />
  );
};
