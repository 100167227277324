import { clsx } from "clsx";
import { Icon } from "~/components/icon";
import { Button } from "../button";
import type { SubmitButtonProps } from "./submit-button.types";
import css from "./submit-button.module.css";

export const SubmitButton = (props: SubmitButtonProps) => {
  const {
    className,
    shape = "contained",
    submitted,
    variant = "error",
    ...other
  } = props;

  return (
    <Button
      hierarchy="primary"
      shape={shape}
      type="submit"
      variant={variant}
      {...other}
      className={clsx(css.root, className, submitted && css.submitted)}
    >
      <span className={css.children}>{props.children}</span>
      {submitted ? (
        <div className={css.successIcon}>
          <Icon inheritColor name="check" />
        </div>
      ) : null}
    </Button>
  );
};
