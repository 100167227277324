import type { IconChildrenProps } from "../../icon.types";

export const CategoryCustom = ({ accentColor, color }: IconChildrenProps) => {
  return (
    <>
      <g clipPath="url(#clip0_3490_34177)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99961 3.40315C9.66824 3.40315 9.39961 3.67178 9.39961 4.00315C9.39961 4.33452 9.66824 4.60315 9.99961 4.60315C10.331 4.60315 10.5996 4.33452 10.5996 4.00315C10.5996 3.67178 10.331 3.40315 9.99961 3.40315ZM8.59961 4.00315C8.59961 3.22995 9.22641 2.60315 9.99961 2.60315C10.7728 2.60315 11.3996 3.22995 11.3996 4.00315C11.3996 4.77635 10.7728 5.40315 9.99961 5.40315C9.22641 5.40315 8.59961 4.77635 8.59961 4.00315Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99961 15.7827C9.66824 15.7827 9.39961 16.0513 9.39961 16.3827C9.39961 16.714 9.66824 16.9827 9.99961 16.9827C10.331 16.9827 10.5996 16.714 10.5996 16.3827C10.5996 16.0513 10.331 15.7827 9.99961 15.7827ZM8.59961 16.3827C8.59961 15.6095 9.22641 14.9827 9.99961 14.9827C10.7728 14.9827 11.3996 15.6095 11.3996 16.3827C11.3996 17.1559 10.7728 17.7827 9.99961 17.7827C9.22641 17.7827 8.59961 17.1559 8.59961 16.3827Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.61875 9.40004C3.28738 9.40004 3.01875 9.66867 3.01875 10C3.01875 10.3314 3.28738 10.6 3.61875 10.6C3.95012 10.6 4.21875 10.3314 4.21875 10C4.21875 9.66867 3.95012 9.40004 3.61875 9.40004ZM2.21875 10C2.21875 9.22684 2.84555 8.60004 3.61875 8.60004C4.39195 8.60004 5.01875 9.22684 5.01875 10C5.01875 10.7732 4.39195 11.4 3.61875 11.4C2.84555 11.4 2.21875 10.7732 2.21875 10Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 7.60001C6 7.3791 6.17909 7.20001 6.4 7.20001H13.6C13.8209 7.20001 14 7.3791 14 7.60001C14 7.82093 13.8209 8.00001 13.6 8.00001H6.4C6.17909 8.00001 6 7.82093 6 7.60001Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.61719 10.0415C4.61719 9.82063 4.79627 9.64154 5.01719 9.64154L14.9828 9.64154C15.2037 9.64154 15.3828 9.82063 15.3828 10.0415C15.3828 10.2625 15.2037 10.4415 14.9828 10.4415L5.01719 10.4415C4.79627 10.4415 4.61719 10.2625 4.61719 10.0415Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 12.4831C6 12.2622 6.17909 12.0831 6.4 12.0831H13.6C13.8209 12.0831 14 12.2622 14 12.4831C14 12.704 13.8209 12.8831 13.6 12.8831H6.4C6.17909 12.8831 6 12.704 6 12.4831Z"
          fill={color}
        />
        <path
          d="M13 7.59955C13 8.15183 12.5523 8.59955 12 8.59955C11.4477 8.59955 11 8.15183 11 7.59955C11 7.04726 11.4477 6.59955 12 6.59955C12.5523 6.59955 13 7.04726 13 7.59955Z"
          fill={accentColor}
        />
        <path
          d="M8.36133 10C8.36133 10.5523 7.91361 11 7.36133 11C6.80904 11 6.36133 10.5523 6.36133 10C6.36133 9.44772 6.80904 9 7.36133 9C7.91361 9 8.36133 9.44772 8.36133 10Z"
          fill={color}
        />
        <path
          d="M14 12.4468C14 12.9991 13.5523 13.4468 13 13.4468C12.4477 13.4468 12 12.9991 12 12.4468C12 11.8945 12.4477 11.4468 13 11.4468C13.5523 11.4468 14 11.8945 14 12.4468Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.83286 4.96683C7.83286 4.7738 7.67637 4.61731 7.48333 4.61731H6.22502L6.16334 4.61847C5.30376 4.65087 4.61719 5.35761 4.61719 6.22512V7.48341L4.61899 7.51915C4.63689 7.6954 4.78574 7.83294 4.96672 7.83294C5.15976 7.83294 5.31625 7.67645 5.31625 7.48341V6.22512L5.31768 6.17354C5.34441 5.69546 5.74023 5.31636 6.22502 5.31636H7.48333L7.51906 5.31455C7.69532 5.29666 7.83286 5.14781 7.83286 4.96683ZM5.31444 12.4808C5.29654 12.3046 5.14769 12.1671 4.96672 12.1671C4.77368 12.1671 4.61719 12.3235 4.61719 12.5166V13.7749L4.61835 13.8366C4.65075 14.6961 5.35749 15.3827 6.22502 15.3827H7.48333L7.51906 15.3809C7.69532 15.363 7.83286 15.2141 7.83286 15.0332C7.83286 14.8401 7.67637 14.6836 7.48333 14.6836H6.22502L6.17343 14.6822C5.69535 14.6555 5.31625 14.2597 5.31625 13.7749V12.5166L5.31444 12.4808ZM15.0333 12.1671C15.2143 12.1671 15.3631 12.3046 15.381 12.4808L15.3828 12.5166V13.7749C15.3828 14.6424 14.6962 15.3491 13.8367 15.3815L13.775 15.3827H12.5167C12.3236 15.3827 12.1671 15.2262 12.1671 15.0332C12.1671 14.8522 12.3047 14.7033 12.4809 14.6854L12.5167 14.6836H13.775C14.2598 14.6836 14.6556 14.3045 14.6823 13.8265L14.6838 13.7749V12.5166C14.6838 12.3235 14.8402 12.1671 15.0333 12.1671ZM15.3817 6.16344C15.3492 5.30387 14.6425 4.61731 13.775 4.61731H12.5167L12.4809 4.61911C12.3047 4.63701 12.1671 4.78586 12.1671 4.96683C12.1671 5.15987 12.3236 5.31636 12.5167 5.31636H13.775L13.8266 5.3178C14.3046 5.34452 14.6838 5.74034 14.6838 6.22512V7.48341L14.6856 7.51915C14.7035 7.6954 14.8523 7.83294 15.0333 7.83294C15.2263 7.83294 15.3828 7.67645 15.3828 7.48341V6.22512L15.3817 6.16344Z"
          fill="#1A1A20"
        />
        <path
          d="M17.3828 10C17.3828 10.5523 16.9351 11 16.3828 11C15.8305 11 15.3828 10.5523 15.3828 10C15.3828 9.44772 15.8305 9 16.3828 9C16.9351 9 17.3828 9.44772 17.3828 10Z"
          fill={accentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3824 9.39998C16.0511 9.39998 15.7824 9.6686 15.7824 9.99998C15.7824 10.3313 16.0511 10.6 16.3824 10.6C16.7138 10.6 16.9824 10.3313 16.9824 9.99998C16.9824 9.6686 16.7138 9.39998 16.3824 9.39998ZM14.9824 9.99998C14.9824 9.22678 15.6092 8.59998 16.3824 8.59998C17.1556 8.59998 17.7824 9.22678 17.7824 9.99998C17.7824 10.7732 17.1556 11.4 16.3824 11.4C15.6092 11.4 14.9824 10.7732 14.9824 9.99998Z"
          fill={accentColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_3490_34177">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </>
  );
};
