import type { Auth0Error } from "auth0-js";
import {
  type ChangeEventHandler,
  type FormEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { getAuth0 } from "~/features/auth/auth.auth0";
import { defaultAppName } from "~/features/auth/auth.constants";
import type { AuthTenant } from "~/features/auth/auth.types";
import { validateEmail } from "~/utils/form-validator";
import {
  checkRecaptcha,
  emailPasswordConnection,
  getUserInfo,
} from "../login-form/login-form.utils";

export const useChangePasswordForm = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const doChangePassword = useCallback(
    async (
      tenant: AuthTenant,
      email: string,
    ): Promise<[error: Auth0Error | undefined, result: unknown]> =>
      new Promise((resolve) => {
        getAuth0(tenant, defaultAppName).changePassword(
          {
            connection: emailPasswordConnection,
            email,
          },
          (changePasswordError, changePasswordResponse) =>
            resolve([changePasswordError || undefined, changePasswordResponse]),
        );
      }),
    [],
  );

  const fetchUserInfo = useCallback(
    async (email: string) => {
      // Reset loading and error states
      setLoading(true);
      setError(false);
      setSuccess(false);

      // Fetch recaptcha token
      const [recaptchaError, recaptchaToken] = await checkRecaptcha();
      if (recaptchaError) {
        // Error fetching recaptcha token
        setError(true);
      } else if (recaptchaToken) {
        // Got recaptcha token
        //   fetch user info
        const [userInfoError, userInfoResponse] = await getUserInfo(
          email,
          recaptchaToken,
        );

        if (userInfoError) {
          // Error fetching user info
          setError(true);
        } else if (
          userInfoResponse &&
          userInfoResponse.connection === emailPasswordConnection
        ) {
          // Got user info for email & password user
          const [changePasswordError, changePasswordResponse] =
            await doChangePassword(userInfoResponse.tenant, email);

          if (changePasswordError) {
            // Error requesting auth0 change password
            setError(true);
          } else if (changePasswordResponse) {
            // Successful auth0 change password request
            setSuccess(true);
          }
        } else {
          setError(true);
        }
      }

      // Stop loading
      setLoading(false);
    },
    [doChangePassword],
  );

  const onEmailChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => setEmail(event.target.value),
    [],
  );

  const onPressSubmit = useCallback(() => {
    if (email && !emailError) {
      void fetchUserInfo(email);
    }
  }, [email, emailError, fetchUserInfo]);

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();
      onPressSubmit();
    },
    [onPressSubmit],
  );

  useEffect(() => {
    setEmailError(Boolean(email && !validateEmail(email)));
  }, [email]);

  return {
    email,
    emailError,
    error,
    loading,
    onEmailChange,
    onPressSubmit,
    onSubmit,
    success,
  } as const;
};
