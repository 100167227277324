export const USER_JOB_TITLE_EHS_DIRECTOR = "EHS Director";
export const USER_JOB_TITLE_EHS_MANAGER = "EHS Manager";
export const USER_JOB_TITLE_EHS_SUPERVISOR = "EHS Supervisor";
export const USER_JOB_TITLE_IT = "IT";
export const USER_JOB_TITLE_GLOBAL_OWNER = "Global Owner";
export const USER_JOB_TITLE_SITE_MANAGEMENT = "Site Management";

export const USER_JOB_TITLE = [
  USER_JOB_TITLE_EHS_DIRECTOR,
  USER_JOB_TITLE_EHS_MANAGER,
  USER_JOB_TITLE_EHS_SUPERVISOR,
  USER_JOB_TITLE_IT,
  USER_JOB_TITLE_GLOBAL_OWNER,
  USER_JOB_TITLE_SITE_MANAGEMENT,
];
