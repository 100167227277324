import type { IconChildrenProps } from "../icon.types";

export const Fact = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      d="M3.65547 3.02085C3.30562 3.02085 3.02214 3.30434 3.02214 3.65419V10.5125C3.02214 10.8624 3.30562 11.1459 3.65547 11.1459H6.66797C6.95562 11.1459 7.1888 11.379 7.1888 11.6667C7.1888 11.9543 6.95562 12.1875 6.66797 12.1875H3.65547C2.73032 12.1875 1.98047 11.4377 1.98047 10.5125V3.65419C1.98047 2.72904 2.73032 1.97919 3.65547 1.97919H15.5146C16.4392 1.97919 17.1888 2.72928 17.1888 3.65419V7.50002C17.1888 7.78767 16.9556 8.02085 16.668 8.02085C16.3803 8.02085 16.1471 7.78767 16.1471 7.50002V3.65419C16.1471 3.30409 15.8634 3.02085 15.5146 3.02085H3.65547Z"
      fill={color}
    />
    <path
      d="M5.467 5.46511C5.6704 5.26171 6.00017 5.26171 6.20357 5.46511L7.81445 7.07599V5.83339C7.81445 5.54575 8.04764 5.31256 8.33529 5.31256C8.62293 5.31256 8.85612 5.54575 8.85612 5.83339V8.33339C8.85612 8.40401 8.84206 8.47135 8.8166 8.53276C8.79118 8.59419 8.75351 8.65174 8.70357 8.70168C8.60187 8.80338 8.46858 8.85423 8.33529 8.85423H5.83529C5.54764 8.85423 5.31445 8.62104 5.31445 8.33339C5.31445 8.04575 5.54764 7.81256 5.83529 7.81256H7.07788L5.467 6.20168C5.2636 5.99828 5.2636 5.66851 5.467 5.46511Z"
      fill={color}
    />
    <path
      clipRule="evenodd"
      d="M11.668 11.3542C11.0348 11.3542 10.5221 11.8668 10.5221 12.5V15.8333C10.5221 16.4665 11.0348 16.9792 11.668 16.9792H15.8346C16.4678 16.9792 16.9805 16.4665 16.9805 15.8333V12.5C16.9805 11.8668 16.4678 11.3542 15.8346 11.3542H11.668ZM9.48047 12.5C9.48047 11.2915 10.4595 10.3125 11.668 10.3125H15.8346C17.0431 10.3125 18.0221 11.2915 18.0221 12.5V15.8333C18.0221 17.0418 17.0431 18.0208 15.8346 18.0208H11.668C10.4595 18.0208 9.48047 17.0418 9.48047 15.8333V12.5Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
