import type { IconChildrenProps } from "../icon.types";

export const UseCase2 = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M6.4784 2.5H10.0078V3.38235H6.4784C4.77282 3.38235 3.39017 4.765 3.39017 6.47059V13.5294C3.39017 15.235 4.77282 16.6176 6.4784 16.6176H13.5372C15.2428 16.6176 16.6255 15.235 16.6255 13.5294V10H17.5078V13.5294C17.5078 15.7223 15.7301 17.5 13.5372 17.5H6.4784C4.28551 17.5 2.50781 15.7223 2.50781 13.5294V6.47059C2.50781 4.27769 4.28551 2.5 6.4784 2.5ZM16.6255 10V6.47059C16.6255 4.765 15.2428 3.38235 13.5372 3.38235H10.0078V8C10.0078 9.10457 10.9032 10 12.0078 10H16.6255ZM7.00781 8.5C7.83624 8.5 8.50781 7.82843 8.50781 7C8.50781 6.17157 7.83624 5.5 7.00781 5.5C6.17939 5.5 5.50781 6.17157 5.50781 7C5.50781 7.82843 6.17939 8.5 7.00781 8.5ZM14.5078 13C14.5078 13.8284 13.8362 14.5 13.0078 14.5C12.1794 14.5 11.5078 13.8284 11.5078 13C11.5078 12.1716 12.1794 11.5 13.0078 11.5C13.8362 11.5 14.5078 12.1716 14.5078 13ZM7.00781 14.5C7.83624 14.5 8.50781 13.8284 8.50781 13C8.50781 12.1716 7.83624 11.5 7.00781 11.5C6.17939 11.5 5.50781 12.1716 5.50781 13C5.50781 13.8284 6.17939 14.5 7.00781 14.5Z"
      fill={color}
      fillRule="evenodd"
    />
    <circle cx="13.0078" cy="7" fill={accentColor} r="1.5" />
  </>
);
