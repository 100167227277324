import type { IconChildrenProps } from "../icon.types";

export const PieChart = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M9.44004 7.961e-07C11.0926 0.000755855 12.716 0.435395 14.1478 1.26051C15.5702 2.08024 16.754 3.25673 17.5824 4.67359C17.5922 4.68809 17.6016 4.70304 17.6105 4.71845C17.6194 4.73391 17.6277 4.74957 17.6354 4.76538C18.4417 6.18071 18.8682 7.78104 18.8728 9.4107C18.8773 11.0641 18.4474 12.6896 17.6262 14.1246C16.8049 15.5595 15.621 16.7535 14.1931 17.587C12.7653 18.4204 11.1435 18.8641 9.48998 18.8738C7.83653 18.8831 6.20971 18.458 4.77254 17.641C3.33513 16.824 2.13761 15.6437 1.29989 14.2183C1.29032 14.202 1.28146 14.1855 1.2733 14.1688L1.26534 14.1553C1.07825 13.8313 1.16941 13.423 1.46468 13.207L1.53105 13.1637L8.71192 9.01784V0.749213C8.71168 0.741429 8.71156 0.733614 8.71156 0.72577C8.71164 0.324858 9.03671 -8.02767e-05 9.43762 0C9.43843 1.61746e-07 9.43923 4.27115e-07 9.44004 7.961e-07ZM10.1638 1.48508V8.17963V1.48508ZM9.79117 10.0712C9.79727 10.0678 9.80331 10.0643 9.8093 10.0607L16.6859 6.09049C17.1658 7.13023 17.4177 8.26384 17.4209 9.41471C17.4248 10.8137 17.061 12.1892 16.3661 13.4034C15.6712 14.6176 14.6694 15.6279 13.4612 16.3331C12.2531 17.0383 10.8807 17.4138 9.48163 17.422C8.08265 17.4299 6.70608 17.0702 5.49 16.3789C4.46729 15.7976 3.58811 14.9989 2.91277 14.0424L9.79117 10.0712Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M6.29032 0.537522C4.17146 1.28941 2.39001 2.77205 1.26594 4.71917C0.141861 6.66632 -0.251195 8.95044 0.157356 11.1614C0.248454 11.6544 0.799983 11.9088 1.23415 11.6581L6.89605 8.38916C7.12064 8.25949 7.259 8.01985 7.259 7.7605V1.22164C7.259 0.720271 6.76282 0.369852 6.29032 0.537522ZM5.80704 2.32387V7.34079L1.46256 9.84894L1.4567 9.72309C1.4047 8.23314 1.77011 6.74972 2.5233 5.44504C3.28441 4.12664 4.40162 3.05983 5.73626 2.36001L5.80704 2.32387Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
