import { Navigate, useLocation } from "react-router-dom";

export const NotFoundRoute = () => {
  const location = useLocation();

  return (
    <Navigate
      replace
      to={{
        pathname: "/login",
        search: location.search,
      }}
    />
  );
};
