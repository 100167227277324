import type { IconChildrenProps } from "../icon.types";

export const Calendar = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M14.1447 3.41012C14.1024 3.17688 13.8983 3 13.6528 3C13.3767 3 13.1528 3.22386 13.1528 3.5V3.77989H6.85V3.5L6.84194 3.41012C6.79961 3.17688 6.59546 3 6.35 3C6.07386 3 5.85 3.22386 5.85 3.5V3.77989H5.71429C3.93908 3.77989 2.5 5.27228 2.5 7.11323V13.6688C2.5 15.5097 3.93908 17.0021 5.71429 17.0021H14.2857C16.0609 17.0021 17.5 15.5097 17.5 13.6688V7.11323C17.5 5.27228 16.0609 3.77989 14.2857 3.77989H14.1528V3.5L14.1447 3.41012ZM5.85 5.00212V4.891H5.71429C4.58461 4.891 3.65911 5.79754 3.57731 6.94738L3.57537 7.00212H16.4259C16.3727 5.88155 15.5192 4.97923 14.4456 4.8971L14.2857 4.891H14.1528V5.00212C14.1528 5.27826 13.9289 5.50212 13.6528 5.50212C13.4073 5.50212 13.2032 5.32524 13.1609 5.09199L13.1528 5.00212V4.891H6.85V5.00212C6.85 5.27826 6.62614 5.50212 6.35 5.50212C6.10454 5.50212 5.90039 5.32524 5.85806 5.09199L5.85 5.00212ZM16.4286 8.00212H3.57143V13.6688C3.57143 14.8403 4.44558 15.8001 5.55436 15.8849L5.71429 15.891H14.2857C15.4154 15.891 16.3409 14.9845 16.4227 13.8346L16.4286 13.6688V8.00212Z"
    fill={color}
    fillRule="evenodd"
  />
);
