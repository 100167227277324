import { AR_CATEGORY_AREA_CONTROLS } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseLineOfFire = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19300)">
        <path
          d="M17.5805 1.59009H2.33047C2.00047 1.59009 1.73047 1.86009 1.73047 2.19009V5.16009C1.73047 5.49009 2.00047 5.76009 2.33047 5.76009C2.66047 5.76009 2.93047 5.49009 2.93047 5.16009V4.56009H7.36047V6.36009C7.36047 6.69009 7.63047 6.96009 7.96047 6.96009H9.46047V8.77009H9.29047C9.01047 8.77009 8.79047 8.99009 8.79047 9.27009C8.79047 9.55009 9.01047 9.77009 9.29047 9.77009H9.46047V10.0301C8.97047 10.2301 8.62047 10.7101 8.62047 11.2801C8.62047 12.0201 9.22047 12.6201 9.96047 12.6201C10.3305 12.6201 10.6705 12.4801 10.9205 12.2101C11.1705 11.9601 11.3005 11.6301 11.3005 11.2701C11.3005 10.9901 11.0805 10.7701 10.8005 10.7701C10.5205 10.7701 10.3005 10.9901 10.3005 11.2701C10.3005 11.3601 10.2705 11.4401 10.2005 11.5101C10.0005 11.7201 9.61047 11.5501 9.61047 11.2701C9.61047 11.0801 9.76047 10.9301 9.95047 10.9301C10.2305 10.9301 10.4505 10.7101 10.4505 10.4301V9.77009H10.6405C10.9205 9.77009 11.1405 9.55009 11.1405 9.27009C11.1405 8.99009 10.9205 8.77009 10.6405 8.77009H10.4505V6.96009H12.0105C12.3405 6.96009 12.6105 6.69009 12.6105 6.36009V4.56009H16.9805V5.16009C16.9805 5.49009 17.2505 5.76009 17.5805 5.76009C17.9105 5.76009 18.1805 5.49009 18.1805 5.16009V2.19009C18.1805 1.86009 17.9105 1.59009 17.5805 1.59009ZM11.4105 5.76009H8.56047V4.56009H11.4105V5.76009ZM2.93047 3.36009V2.79009H16.9805V3.36009H2.93047Z"
          fill={color}
        />
        <path
          d="M17.1305 11.58H16.6305C16.4105 11.58 16.2305 11.4 16.2305 11.18C16.2305 10.96 16.4105 10.78 16.6305 10.78H17.1305C17.3505 10.78 17.5305 10.96 17.5305 11.18C17.5305 11.4 17.3505 11.58 17.1305 11.58ZM15.6305 11.58H15.1305C14.9105 11.58 14.7305 11.4 14.7305 11.18C14.7305 10.96 14.9105 10.78 15.1305 10.78H15.6305C15.8505 10.78 16.0305 10.96 16.0305 11.18C16.0305 11.4 15.8505 11.58 15.6305 11.58ZM14.1305 11.58H13.6305C13.4105 11.58 13.2305 11.4 13.2305 11.18C13.2305 10.96 13.4105 10.78 13.6305 10.78H14.1305C14.3505 10.78 14.5305 10.96 14.5305 11.18C14.5305 11.4 14.3505 11.58 14.1305 11.58ZM12.6305 11.58H12.1305C11.9105 11.58 11.7305 11.4 11.7305 11.18C11.7305 10.96 11.9105 10.78 12.1305 10.78H12.6305C12.8505 10.78 13.0305 10.96 13.0305 11.18C13.0305 11.4 12.8505 11.58 12.6305 11.58Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M7.66953 11.78H7.16953C6.94953 11.78 6.76953 11.6 6.76953 11.38C6.76953 11.16 6.94953 10.98 7.16953 10.98H7.66953C7.88953 10.98 8.06953 11.16 8.06953 11.38C8.06953 11.6 7.88953 11.78 7.66953 11.78ZM6.16953 11.78H5.66953C5.44953 11.78 5.26953 11.6 5.26953 11.38C5.26953 11.16 5.44953 10.98 5.66953 10.98H6.16953C6.38953 10.98 6.56953 11.16 6.56953 11.38C6.56953 11.6 6.38953 11.78 6.16953 11.78ZM4.66953 11.78H4.16953C3.94953 11.78 3.76953 11.6 3.76953 11.38C3.76953 11.16 3.94953 10.98 4.16953 10.98H4.66953C4.88953 10.98 5.06953 11.16 5.06953 11.38C5.06953 11.6 4.88953 11.78 4.66953 11.78ZM3.16953 11.78H2.66953C2.44953 11.78 2.26953 11.6 2.26953 11.38C2.26953 11.16 2.44953 10.98 2.66953 10.98H3.16953C3.38953 10.98 3.56953 11.16 3.56953 11.38C3.56953 11.6 3.38953 11.78 3.16953 11.78Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M9.99976 14.6099C9.77976 14.6099 9.59976 14.7899 9.59976 15.0099V15.5099C9.59976 15.7299 9.77976 15.9099 9.99976 15.9099C10.2198 15.9099 10.3998 15.7299 10.3998 15.5099V15.0099C10.3998 14.7899 10.2198 14.6099 9.99976 14.6099Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M9.99976 16.1099C9.77976 16.1099 9.59976 16.2899 9.59976 16.5099V17.0099C9.59976 17.2299 9.77976 17.4099 9.99976 17.4099C10.2198 17.4099 10.3998 17.2299 10.3998 17.0099V16.5099C10.3998 16.2899 10.2198 16.1099 9.99976 16.1099Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M9.99976 13.1099C9.77976 13.1099 9.59976 13.2899 9.59976 13.5099V14.0099C9.59976 14.2299 9.77976 14.4099 9.99976 14.4099C10.2198 14.4099 10.3998 14.2299 10.3998 14.0099V13.5099C10.3998 13.2899 10.2198 13.1099 9.99976 13.1099Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M9.99976 17.6099C9.77976 17.6099 9.59976 17.7899 9.59976 18.0099V18.5099C9.59976 18.7299 9.77976 18.9099 9.99976 18.9099C10.2198 18.9099 10.3998 18.7299 10.3998 18.5099V18.0099C10.3998 17.7899 10.2198 17.6099 9.99976 17.6099Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M2.19976 13.2999C2.03976 13.3399 1.94976 13.5099 1.98976 13.6699C2.02976 13.8399 2.08976 13.9999 2.13976 14.1699C2.17976 14.2899 2.29976 14.3699 2.41976 14.3699C2.44976 14.3699 2.47976 14.3699 2.51976 14.3499C2.67976 14.2999 2.75976 14.1299 2.70976 13.9699C2.65976 13.8199 2.60976 13.6699 2.56976 13.5099C2.52976 13.3499 2.35976 13.2499 2.19976 13.2999Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M2.00976 11.9099C2.17976 11.9099 2.30976 11.7699 2.29976 11.5999V11.4599C2.29976 11.2999 2.18976 11.1999 1.98976 11.1999C1.81976 11.1999 1.68976 11.3699 1.69976 11.5399V11.6099C1.69976 11.7699 1.83976 11.8999 1.99976 11.8999L2.00976 11.9099Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M3.55976 15.6899C3.46976 15.5499 3.27976 15.5099 3.13976 15.5999C2.99976 15.6899 2.95976 15.8799 3.04976 16.0199C3.14976 16.1699 3.23976 16.3099 3.34976 16.4399C3.40976 16.5199 3.49976 16.5599 3.58976 16.5599C3.64976 16.5599 3.71976 16.5399 3.76976 16.4999C3.89976 16.3999 3.92976 16.2099 3.82976 16.0799C3.72976 15.9499 3.63976 15.8199 3.54976 15.6899H3.55976Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M5.16976 17.4699C5.03976 17.3699 4.84976 17.3899 4.74976 17.5199C4.64976 17.6499 4.66976 17.8399 4.78976 17.9399C4.91976 18.0499 5.05976 18.1499 5.19976 18.2499C5.24976 18.2899 5.30976 18.3099 5.36976 18.3099C5.45976 18.3099 5.55976 18.2699 5.60976 18.1799C5.70976 18.0499 5.66976 17.8599 5.53976 17.7599C5.40976 17.6699 5.27976 17.5699 5.15976 17.4699H5.16976Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M7.69976 18.8199C7.54976 18.7699 7.39976 18.7199 7.24976 18.6599C7.08976 18.5999 6.91976 18.6799 6.85976 18.8299C6.79976 18.9799 6.87976 19.1599 7.02976 19.2199C7.18976 19.2799 7.35976 19.3399 7.51976 19.3899C7.54976 19.3899 7.57976 19.3999 7.60976 19.3999C7.73976 19.3999 7.85976 19.3199 7.89976 19.1899C7.94976 19.0299 7.85976 18.8599 7.69976 18.8099V18.8199Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M9.99976 19.1699C9.85976 19.1699 9.71976 19.1699 9.58976 19.1599C9.38976 19.1599 9.27976 19.2799 9.26976 19.4399C9.26976 19.6099 9.38976 19.7499 9.54976 19.7599C9.69976 19.7599 9.83976 19.7699 9.99976 19.7699H10.0698C10.2398 19.7699 10.3298 19.6399 10.3298 19.4699C10.3298 19.2999 10.1598 19.1699 9.99976 19.1699Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M17.9998 11.1699C17.8298 11.1699 17.6998 11.2999 17.6998 11.4699C17.6998 11.6399 17.6998 11.7899 17.6798 11.9499C17.6698 12.1199 17.7998 12.2599 17.9598 12.2699C17.9598 12.2699 17.9698 12.2699 17.9798 12.2699C18.1398 12.2699 18.2698 12.1499 18.2798 11.9899C18.2898 11.8199 18.2998 11.6399 18.2998 11.4699C18.2998 11.2999 18.1698 11.1699 17.9998 11.1699Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M17.7098 13.6399C17.5498 13.5899 17.3798 13.6799 17.3298 13.8299C17.2798 13.9799 17.2298 14.1399 17.1698 14.2899C17.1098 14.4399 17.1898 14.6199 17.3398 14.6799C17.3798 14.6899 17.4098 14.6999 17.4498 14.6999C17.5698 14.6999 17.6798 14.6299 17.7298 14.5099C17.7898 14.3499 17.8498 14.1899 17.8998 14.0199C17.9498 13.8599 17.8598 13.6899 17.7098 13.6399Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M14.5598 17.6799C14.4298 17.7699 14.2998 17.8699 14.1598 17.9499C14.0198 18.0399 13.9798 18.2199 14.0698 18.3599C14.1298 18.4499 14.2198 18.4999 14.3198 18.4999C14.3798 18.4999 14.4298 18.4799 14.4798 18.4499C14.6298 18.3599 14.7698 18.2599 14.9098 18.1599C15.0398 18.0599 15.0698 17.8699 14.9698 17.7399C14.8698 17.6099 14.6798 17.5799 14.5498 17.6799H14.5598Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M12.4298 18.7799C12.2798 18.8299 12.1198 18.8799 11.9698 18.9199C11.8098 18.9599 11.7198 19.1299 11.7598 19.2899C11.7998 19.4199 11.9198 19.5099 12.0498 19.5099C12.0798 19.5099 12.0998 19.5099 12.1298 19.4999C12.2998 19.4599 12.4598 19.4099 12.6298 19.3499C12.7898 19.2999 12.8698 19.1299 12.8198 18.9699C12.7698 18.8099 12.5998 18.7299 12.4398 18.7799H12.4298Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M16.2498 15.9799C16.1598 16.1099 16.0598 16.2399 15.9598 16.3599C15.8498 16.4899 15.8698 16.6799 15.9998 16.7799C16.0598 16.8299 16.1198 16.8499 16.1898 16.8499C16.2798 16.8499 16.3598 16.8099 16.4198 16.7399C16.5298 16.6099 16.6398 16.4699 16.7398 16.3299C16.8398 16.1999 16.8098 16.0099 16.6698 15.9099C16.5298 15.8099 16.3498 15.8399 16.2498 15.9799Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19300">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
