import type { IconChildrenProps } from "../icon.types";

export const Duplicate = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M16.8242 4.61255C18.5601 4.61255 19.9986 5.97095 19.9986 7.68436V16.2038C19.9986 17.9172 18.5601 19.2756 16.8242 19.2756H6.98496C5.24904 19.2756 3.81055 17.9172 3.81055 16.2038H4.74842C4.74842 17.3654 5.7325 18.3377 6.98496 18.3377H16.8242C18.0767 18.3377 19.0607 17.3654 19.0607 16.2038V7.68436C19.0607 6.52276 18.0766 5.55042 16.8242 5.55042V4.61255Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M0 3.76677C0 2.12828 1.3283 0.799988 2.96678 0.799988H12.0509C13.6894 0.799988 15.0177 2.12828 15.0177 3.76677V11.9424C15.0177 13.581 13.6894 14.9092 12.0509 14.9092H2.96678C1.32828 14.9092 0 13.581 0 11.9424V3.76677ZM2.96678 1.73786C1.84627 1.73786 0.93787 2.64626 0.93787 3.76677V11.9424C0.93787 13.063 1.84625 13.9713 2.96678 13.9713H12.0509C13.1714 13.9713 14.0798 13.063 14.0798 11.9424V3.76677C14.0798 2.64626 13.1714 1.73786 12.0509 1.73786H2.96678Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
