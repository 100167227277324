import { endpoints } from "~/backend/endpoints";
import { report } from "~/features/error-reporter";
import { getHttp } from "~/utils/http";
import type {
  GetInvitationDataReturnType,
  InvitationData,
  SignUpRouteState,
} from "./sign-up-route.types";

export const getInvitationData = async (
  id: string,
): Promise<GetInvitationDataReturnType> => {
  try {
    const response = await getHttp().get<{ data: InvitationData }>(
      endpoints.session.invitation(id),
    );
    return [undefined, response.data.data];
  } catch (e) {
    void report(e);
    return ["error"];
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFromSignUpRoute = (state: any): state is SignUpRouteState =>
  (state &&
    "fromSignUp" in state &&
    (state as SignUpRouteState).fromSignUp) as boolean;
