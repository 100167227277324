import type { IconChildrenProps } from "../icon.types";

export const RadioNonselected = ({ color }: IconChildrenProps) => (
  <rect
    height="14"
    rx="7"
    stroke={color}
    strokeWidth="2"
    width="14"
    x="3"
    y="3"
  />
);
