import { AR_CATEGORY_ANOMALY } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseLeakageAndSpill = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19314)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.8506 1C14.9106 1 14.9706 1.01 15.0206 1.03L15.0606 1.05H15.0706C15.5306 1.19 15.8906 1.55 16.0206 2.01L16.0506 2.14L18.7906 17.35C18.8006 17.43 18.8106 17.51 18.8106 17.6C18.8106 18.33 18.2606 18.92 17.5506 18.99H13.2906C12.6906 18.99 12.1606 18.61 11.9706 18.05L11.9306 17.92L11.4106 15.72C11.3706 15.57 11.2506 15.45 11.1006 15.42H6.44063C6.28063 15.42 6.14062 15.51 6.08063 15.65L6.05063 15.72L5.52063 17.93C5.38063 18.51 4.89063 18.94 4.30063 19H2.54062C1.77062 19 1.14062 18.38 1.14062 17.6V17.47L1.16062 17.35L3.84062 2.88C4.03062 1.85 4.89062 1.08 5.93062 1.01H14.8506V1ZM14.6906 2H14.6106C14.4806 2 14.3806 2.1 14.3806 2.23V2.26L16.3306 17.43C16.3506 17.61 16.3406 17.79 16.3006 17.95L16.2806 18H17.4306C17.6206 18 17.7806 17.86 17.8206 17.68V17.56L15.0806 2.33C15.0506 2.17 14.9306 2.04 14.7706 2.01H14.6906V2ZM13.4006 2H6.09063C5.51063 2 5.01063 2.38 4.85063 2.93L4.82063 3.06L2.18063 17.53C2.18063 17.53 2.18063 17.58 2.18063 17.6C2.18063 17.79 2.32063 17.95 2.50063 17.99H4.20062C4.36063 17.99 4.50063 17.9 4.56063 17.76L4.59063 17.69L5.12063 15.48C5.26062 14.9 5.75063 14.47 6.34063 14.41H11.0606C11.6606 14.41 12.1906 14.79 12.3806 15.35L12.4206 15.48L12.9406 17.68C12.9806 17.83 13.1006 17.95 13.2506 17.98H14.9806C15.0006 17.98 15.0106 17.98 15.0306 17.98C15.2206 17.96 15.3606 17.8 15.3706 17.62V17.54L13.4206 2.37C13.4206 2.32 13.4206 2.27 13.4206 2.21C13.4206 2.17 13.4206 2.13 13.4206 2.08L13.4406 1.98L13.4006 2ZM11.7406 2.96C12.0206 2.96 12.2406 3.18 12.2406 3.46C12.2406 3.71 12.0606 3.91 11.8306 3.95H6.60063C6.32062 3.95 6.10063 3.73 6.10063 3.45C6.10063 3.2 6.28063 3 6.51063 2.96H11.7406Z"
          fill={color}
        />
        <path
          d="M9.08125 10.9601C9.74125 10.9601 10.3713 10.6701 10.8113 10.1801C11.5713 9.32009 11.5713 8.02009 10.8113 7.16009L9.90125 6.13009C9.48125 5.66009 8.67125 5.66009 8.25125 6.13009L7.35125 7.16009C6.59125 8.02009 6.59125 9.32009 7.35125 10.1801C7.79125 10.6801 8.42125 10.9601 9.08125 10.9601ZM8.10125 7.82009L9.00125 6.79009H9.15125L10.0613 7.82009C10.4913 8.30009 10.4913 9.03009 10.0613 9.52009C9.56125 10.0801 8.59125 10.0801 8.10125 9.52009C7.67125 9.04009 7.67125 8.31009 8.10125 7.82009Z"
          fill={getAlertCategoryColor(AR_CATEGORY_ANOMALY)}
        />
        <path
          d="M10.5813 11.9201H7.58125C7.30125 11.9201 7.08125 12.1401 7.08125 12.4201C7.08125 12.7001 7.30125 12.9201 7.58125 12.9201H10.5813C10.8612 12.9201 11.0813 12.7001 11.0813 12.4201C11.0813 12.1401 10.8612 11.9201 10.5813 11.9201Z"
          fill={getAlertCategoryColor(AR_CATEGORY_ANOMALY)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19314">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
