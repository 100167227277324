import type { IconChildrenProps } from "../../icon.types";

export const ErgonomicsArmActivity = ({
  accentColor,
  color,
}: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M9.99935 1.875C8.84876 1.875 7.91602 2.80774 7.91602 3.95833C7.91602 5.10893 8.84876 6.04167 9.99935 6.04167C11.1499 6.04167 12.0827 5.10893 12.0827 3.95833C12.0827 2.80774 11.1499 1.875 9.99935 1.875ZM9.16602 3.95833C9.16602 3.4981 9.53911 3.125 9.99935 3.125C10.4596 3.125 10.8327 3.4981 10.8327 3.95833C10.8327 4.41857 10.4596 4.79167 9.99935 4.79167C9.53911 4.79167 9.16602 4.41857 9.16602 3.95833Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M5.22867 3.72472C5.47275 3.48065 5.86848 3.48065 6.11256 3.72472C6.35663 3.9688 6.35663 4.36453 6.11256 4.60861C6.08282 4.63834 6.02278 4.73075 6.04578 4.96771C6.06839 5.20072 6.16849 5.49698 6.34837 5.8003C6.72479 6.43504 7.30195 6.87197 7.79863 6.90757C7.92301 6.91648 8.04793 6.92457 8.17329 6.93183C8.19759 6.92897 8.22231 6.92749 8.24737 6.92749C8.29585 6.92749 8.34304 6.93301 8.38835 6.94346C8.86785 6.96753 9.35299 6.97958 9.83836 6.97958C9.86552 6.97958 9.89227 6.98131 9.91852 6.98467C9.94476 6.98131 9.97152 6.97958 9.99868 6.97958C10.5679 6.97958 11.1368 6.963 11.6967 6.92991C11.7083 6.92889 11.7199 6.92819 11.7317 6.92781C11.8343 6.92162 11.9365 6.91487 12.0384 6.90757C12.5351 6.87197 13.1122 6.43504 13.4887 5.8003C13.6686 5.49698 13.7686 5.20072 13.7913 4.96771C13.8143 4.73075 13.7542 4.63834 13.7245 4.60861C13.4804 4.36453 13.4804 3.9688 13.7245 3.72472C13.9686 3.48065 14.3643 3.48065 14.6084 3.72472C14.9953 4.11166 15.0795 4.63405 15.0354 5.08844C14.9909 5.54676 14.8126 6.01839 14.5638 6.43791C14.1207 7.18517 13.3376 7.9474 12.3771 8.12271C12.3782 12.9505 12.4073 14.04 13.1567 17.0358C13.2404 17.3707 13.0369 17.7101 12.702 17.7938C12.3671 17.8776 12.0278 17.674 11.944 17.3391C11.516 15.628 11.3122 14.4859 11.2152 13.0249H8.76542C8.6634 14.7115 8.46082 16.3054 8.03187 17.4132C7.90722 17.7351 7.54524 17.895 7.22335 17.7703C6.90146 17.6457 6.74156 17.2837 6.86621 16.9618C7.22686 16.0304 7.42402 14.5677 7.52363 12.8494C7.61095 11.343 7.62105 9.69949 7.62222 8.14645C6.58774 8.03241 5.74106 7.22682 5.27321 6.43791C5.02442 6.01839 4.8461 5.54676 4.80162 5.08844C4.75753 4.63405 4.84174 4.11166 5.22867 3.72472ZM11.1582 11.7749C11.1308 10.8172 11.1275 9.68199 11.1271 8.20867C10.7523 8.22261 10.3754 8.22958 9.99868 8.22958C9.97152 8.22958 9.94476 8.22784 9.91852 8.22448C9.89227 8.22784 9.86552 8.22958 9.83836 8.22958C9.51592 8.22958 9.19339 8.22448 8.87217 8.21426C8.87113 9.37062 8.8646 10.5922 8.82377 11.7749H11.1582Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M18.3826 6.03419C18.1715 5.76125 17.779 5.71113 17.506 5.92222L16.0358 7.05907C15.7627 7.27021 15.7125 7.66275 15.9236 7.93581C16.1348 8.20888 16.5273 8.25908 16.8004 8.04794L17.7764 7.29328L18.5317 8.26948C18.7429 8.54248 19.1355 8.59256 19.4085 8.38133C19.6815 8.1701 19.7315 7.77755 19.5203 7.50455L18.3826 6.03419Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M17.6314 5.97476C17.2877 6.00678 17.035 6.31135 17.067 6.65504C17.1107 7.12415 17.1093 7.59852 17.0481 8.07685L17.0477 8.08015C16.8237 9.83174 15.9117 11.3253 14.6173 12.3269C14.3443 12.5381 14.2942 12.9306 14.5054 13.2036C14.7167 13.4766 15.1092 13.5267 15.3822 13.3155C16.9278 12.1196 18.0198 10.3325 18.2876 8.23872L18.288 8.23541C18.3622 7.65568 18.3628 7.08858 18.3116 6.53909C18.2796 6.1954 17.9751 5.94274 17.6314 5.97476Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M1.64339 6.03419C1.85458 5.76125 2.24701 5.71113 2.52001 5.92222L3.99027 7.05907C4.26334 7.27022 4.31353 7.66275 4.10239 7.93582C3.89125 8.20888 3.49871 8.25908 3.22565 8.04794L2.24967 7.29328L1.49434 8.26948C1.28311 8.54248 0.890563 8.59256 0.617562 8.38133C0.344562 8.1701 0.294488 7.77755 0.505719 7.50455L1.64339 6.03419Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M2.39466 5.97476C2.73835 6.00678 2.99101 6.31135 2.959 6.65504C2.9153 7.12415 2.91675 7.59853 2.97792 8.07685L2.97834 8.08015C3.20234 9.83174 4.11431 11.3253 5.40876 12.3269C5.68176 12.5381 5.73183 12.9306 5.52059 13.2036C5.30936 13.4766 4.91681 13.5267 4.64381 13.3155C3.09823 12.1196 2.0062 10.3325 1.73844 8.23872L1.73801 8.23541C1.66388 7.65568 1.6632 7.08858 1.71439 6.53909C1.7464 6.1954 2.05097 5.94274 2.39466 5.97476Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
