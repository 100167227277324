import { getBaseUrl } from "~/backend/api/backend-api.base-url";
import { report } from "~/features/error-reporter/error-reporter";
import { getStorageLocale } from "~/features/intl/intl.utils";
import { abortableFetch } from "./abortable-fetch";

type HttpClient = ReturnType<typeof createHttpClient>;

type HttpClientOptions = {
  baseUrl: string;
  token?: string;
};

type RequestOptions = {
  cache?: RequestCache;
  credentials?: RequestCredentials;
  headers?: Record<string, string | undefined>;
  mode?: RequestMode;
  redirect?: RequestRedirect;
  referrer?: string;
  referrerPolicy?: ReferrerPolicy;
};

export type RequestResponse<T> = {
  data: T;
  headers: Headers;
  ok: boolean;
  response: Response;
  status: number;
  statusText: string;
};

export class HttpRequestError extends Error {
  constructor(response: Response, data?: Record<string, unknown>) {
    super(`${response.status} (${response.statusText}) at ${response.url}`);
    this.name = "HttpRequestError";
    this.response = response;
    this.data = data;
  }

  data?: {
    errorCode?: string;
    message?: string;
  };
  response: Response;
}

const defaultOptions: RequestOptions = {
  credentials: "include",
  headers: {
    Accept: "application/json, text/plain, */*",
  },
  mode: "cors",
  redirect: "follow",
};

const requestQueue: Record<string, (() => void) | undefined> = {};

const getRequestQueueKey = (
  method: "DELETE" | "GET" | "PATCH" | "POST" | "PUT",
  url: string,
  token?: string,
  data?: unknown,
  options?: RequestOptions,
) => {
  const dataStr = JSON.stringify(data);
  const optionsStr = JSON.stringify(options);
  const tokenStr = token ?? "";
  return `${method}${url}${tokenStr}${dataStr}${optionsStr}`;
};

const withAuth = (token?: string) => {
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
};

const withContentType = (data: unknown) => {
  if (data && !(data instanceof FormData)) {
    return { "Content-Type": "application/json" };
  }
};

const withLanguage = () => {
  const locale = getStorageLocale();
  if (locale) {
    return { "Accept-Language": locale };
  }
};

const makeRequest = async <T = undefined>(
  method: "DELETE" | "GET" | "PATCH" | "POST" | "PUT",
  url: string,
  token?: string,
  data?: unknown,
  options?: RequestOptions,
): Promise<RequestResponse<T>> => {
  let requestBody;

  if (data) {
    if (data instanceof FormData) {
      requestBody = data;
    } else if (data !== undefined && data !== null) {
      requestBody = JSON.stringify(data);
    }
  }

  const requestOptions: RequestInit = {
    ...defaultOptions,
    ...options,
    body: requestBody,
    headers: {
      ...withContentType(data),
      ...defaultOptions.headers,
      ...options?.headers,
      ...withAuth(token),
      ...withLanguage(),
    },
    method,
  };

  const request = abortableFetch(url, requestOptions);

  const requestQueueKey = getRequestQueueKey(method, url, token, data, options);
  const previousRequestAbort = requestQueue[requestQueueKey];

  if (previousRequestAbort) {
    previousRequestAbort();
    requestQueue[requestQueueKey] = undefined;
  } else {
    requestQueue[requestQueueKey] = request.abort;
  }

  const response = await request.fetch();
  requestQueue[requestQueueKey] = undefined;

  const contentType = response.headers.get("Content-Type");

  let responseData;

  if (contentType?.startsWith("application/json")) {
    responseData = (await response.json()) as Record<string, unknown>;
  } else if (contentType?.startsWith("text/plain")) {
    responseData = await response.text();
  }

  if (!response.ok) {
    throw new HttpRequestError(
      response,
      typeof responseData === "string" ? {} : responseData,
    );
  }

  return {
    data: responseData as T,
    headers: response.headers,
    ok: response.ok,
    response,
    status: response.status,
    statusText: response.statusText,
  };
};

export const createHttpClient = ({ baseUrl, token }: HttpClientOptions) => ({
  delete: async <T>(url: string, data?: unknown, options?: RequestOptions) =>
    makeRequest<T>("DELETE", `${baseUrl}${url}`, token, data, options),

  get: async <T>(url: string, data?: unknown, options?: RequestOptions) =>
    makeRequest<T>("GET", `${baseUrl}${url}`, token, data, options),

  patch: async <T>(url: string, data?: unknown, options?: RequestOptions) =>
    makeRequest<T>("PATCH", `${baseUrl}${url}`, token, data, options),

  post: async <T>(url: string, data?: unknown, options?: RequestOptions) =>
    makeRequest<T>("POST", `${baseUrl}${url}`, token, data, options),

  put: async <T>(url: string, data?: unknown, options?: RequestOptions) =>
    makeRequest<T>("PUT", `${baseUrl}${url}`, token, data, options),
});

const http: HttpClient = createHttpClient({ baseUrl: getBaseUrl() });
let authHttp: HttpClient = http;
let authToken: string;

export const setAuthHttpToken = (token: string) => {
  if (authToken !== token) {
    authToken = token;
    authHttp = createHttpClient({ baseUrl: getBaseUrl(), token });
  }
};

// Use this http client for authenticated requests
export const getAuthHttp = () => {
  if (!authToken) {
    void report(new Error("Authentication is not initialized."));
  }

  return authHttp;
};

// Use this http client for NON-authenticated requests
export const getHttp = () => http;
