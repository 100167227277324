import type { IconChildrenProps } from "../icon.types";

export const Share = ({ color }: IconChildrenProps) => (
  <>
    <g clipPath="url(#clip0_978_18513)">
      <g clipPath="url(#clip1_978_18513)">
        <path
          d="M14.6667 4C14.1009 4 13.5583 4.22476 13.1582 4.62484C12.7581 5.02492 12.5333 5.56754 12.5333 6.13333C12.5343 6.28329 12.551 6.43273 12.5833 6.57917L7.73958 9.00104C7.53981 8.77104 7.29309 8.5865 7.01602 8.45982C6.73896 8.33315 6.43798 8.26729 6.13333 8.26667C5.56754 8.26667 5.02492 8.49143 4.62484 8.89151C4.22476 9.29158 4 9.83421 4 10.4C4 10.9658 4.22476 11.5084 4.62484 11.9085C5.02492 12.3086 5.56754 12.5333 6.13333 12.5333C6.43808 12.533 6.73921 12.4674 7.01646 12.3409C7.29371 12.2144 7.54064 12.0299 7.74063 11.8L12.5813 14.2208C12.5497 14.3673 12.5336 14.5168 12.5333 14.6667C12.5333 15.2325 12.7581 15.7751 13.1582 16.1752C13.5583 16.5752 14.1009 16.8 14.6667 16.8C15.2325 16.8 15.7751 16.5752 16.1752 16.1752C16.5752 15.7751 16.8 15.2325 16.8 14.6667C16.8 14.1009 16.5752 13.5583 16.1752 13.1582C15.7751 12.7581 15.2325 12.5333 14.6667 12.5333C14.3619 12.5337 14.0608 12.5993 13.7835 12.7258C13.5063 12.8523 13.2594 13.0367 13.0594 13.2667L8.21875 10.8458C8.25034 10.6993 8.2664 10.5499 8.26667 10.4C8.26564 10.2504 8.24888 10.1013 8.21667 9.95521L13.0604 7.53333C13.2603 7.76314 13.5071 7.94749 13.7841 8.07398C14.0612 8.20047 14.3621 8.26618 14.6667 8.26667C15.2325 8.26667 15.7751 8.04191 16.1752 7.64183C16.5752 7.24175 16.8 6.69913 16.8 6.13333C16.8 5.56754 16.5752 5.02492 16.1752 4.62484C15.7751 4.22476 15.2325 4 14.6667 4Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_978_18513">
        <rect width="20" height="20" fill={color} />
      </clipPath>
      <clipPath id="clip1_978_18513">
        <rect width="20" height="20" fill={color} />
      </clipPath>
    </defs>
  </>
);
