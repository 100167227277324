import type { IconChildrenProps } from "../icon.types";

export const World = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M14.1782 10.1059C11.9709 10.1059 10.7846 7.53586 12.1744 5.85836L12.2374 5.78494L13.5982 4.25334C12.4802 3.40754 11.0873 2.90588 9.57707 2.90588C8.65186 2.90588 7.77069 3.09416 6.96979 3.43451C7.06089 3.59845 7.25967 3.75179 7.62581 3.94703L7.88315 4.07799L8.2021 4.2297C9.55159 4.86462 10.1062 5.34318 10.1062 6.37967C10.1062 7.09497 9.81076 7.5584 9.16547 8.11444L8.99872 8.25407L8.71589 8.48641C8.02089 9.06562 7.73194 9.50766 7.70813 10.2731L7.70651 10.3795C7.70651 11.6547 7.23709 12.4835 6.29555 12.91C5.89399 13.0919 5.45701 13.1961 4.85686 13.252L4.62391 13.2707L4.37417 13.2853L4.04003 13.298C5.23796 15.077 7.27066 16.2471 9.57707 16.2471C9.86945 16.2471 10.1065 16.4841 10.1065 16.7765C10.1065 17.0689 9.86945 17.3059 9.57707 17.3059C5.30774 17.3059 1.84766 13.8458 1.84766 9.57647C1.84766 6.55914 3.57593 3.94602 6.09671 2.67289C6.12509 2.6547 6.15562 2.63944 6.18764 2.62721C7.21105 2.12767 8.36125 1.84706 9.57707 1.84706C13.8464 1.84706 17.3065 5.30715 17.3065 9.57647C17.3065 9.86886 17.0695 10.1059 16.7771 10.1059H14.1782ZM6.01032 3.93831L5.92555 3.9929C4.10729 5.18428 2.90648 7.23993 2.90648 9.57647C2.90648 10.5268 3.10513 11.4307 3.46319 12.2489L3.73173 12.2449L4.11035 12.2342L4.34482 12.2231L4.56537 12.2086L4.77237 12.1906C6.17483 12.0508 6.60857 11.6265 6.64511 10.5393L6.64768 10.3795C6.64768 9.22281 7.06094 8.51417 7.93511 7.76025L8.14653 7.58363L8.39303 7.38116C8.88528 6.97092 9.0474 6.73448 9.0474 6.37967C9.0474 5.98594 8.82565 5.73627 8.14769 5.38288L7.96032 5.28817L7.63119 5.13133C7.54852 5.09246 7.46909 5.05436 7.39283 5.01684L7.17346 4.90583C6.5753 4.59358 6.20302 4.31012 6.01032 3.93831ZM14.3897 4.95703L13.029 6.48813C12.1654 7.46053 12.825 8.98583 14.101 9.04527L14.1782 9.04706L16.227 9.04716C16.1027 7.46413 15.4261 6.03654 14.3897 4.95703Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12.787 12.7871C13.8207 11.7533 15.4968 11.7533 16.5305 12.787C17.4362 13.6927 17.5484 15.0912 16.8673 16.119L17.9974 17.2492C18.2042 17.4559 18.2042 17.7911 17.9974 17.9979C17.8045 18.1909 17.4996 18.2037 17.2917 18.0365L17.2487 17.9979L16.1185 16.8676C15.0907 17.5484 13.6925 17.436 12.787 16.5305C11.7533 15.4968 11.7533 13.8208 12.787 12.7871ZM15.7163 13.4738C15.0927 12.9162 14.1346 12.9369 13.5357 13.5358C12.9155 14.156 12.9155 15.1616 13.5357 15.7818C14.156 16.4021 15.1616 16.4021 15.7818 15.7818C16.3807 15.183 16.4013 14.2249 15.8438 13.6013L15.7818 13.5358L15.7163 13.4738Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
