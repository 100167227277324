import { AR_CATEGORY_AREA_CONTROLS } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseSafeLifting = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19326)">
        <path
          d="M13.6606 12.0299C13.9706 12.0299 14.2806 11.9099 14.5106 11.6799C14.9806 11.2099 14.9806 10.4499 14.5106 9.97994C14.0406 9.50994 13.2806 9.50994 12.8106 9.97994C12.3406 10.4499 12.3406 11.2099 12.8106 11.6799C13.0406 11.9099 13.3506 12.0299 13.6606 12.0299ZM13.3806 10.5399C13.4606 10.4599 13.5606 10.4199 13.6606 10.4199C13.7606 10.4199 13.8706 10.4599 13.9406 10.5399C14.1006 10.6999 14.1006 10.9499 13.9406 11.1099C13.7806 11.2699 13.5306 11.2699 13.3706 11.1099C13.2106 10.9499 13.2106 10.6999 13.3706 10.5399H13.3806Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M14.1406 12.5099H13.1906C12.4706 12.5099 11.8906 13.0899 11.8906 13.8099V14.2199C11.8906 14.4399 12.0706 14.6199 12.2906 14.6199C12.5106 14.6199 12.6906 14.4399 12.6906 14.2199V13.8099C12.6906 13.5299 12.9106 13.3099 13.1906 13.3099H14.1406C14.4206 13.3099 14.6406 13.5399 14.6406 13.8099V14.2199C14.6406 14.4399 14.8206 14.6199 15.0406 14.6199C15.2606 14.6199 15.4406 14.4399 15.4406 14.2199V13.8099C15.4406 13.0899 14.8606 12.5099 14.1406 12.5099Z"
          fill={getAlertCategoryColor(AR_CATEGORY_AREA_CONTROLS)}
        />
        <path
          d="M12.6105 6.37V4.57H15.1605C15.3205 4.72 15.4805 4.88 15.6205 5.04C15.7205 5.15 15.8605 5.21 16.0005 5.21C16.1205 5.21 16.2405 5.17 16.3305 5.09C16.5405 4.91 16.5605 4.59 16.3705 4.38C16.1805 4.17 15.9905 3.96 15.7805 3.77C15.5805 3.58 15.2605 3.59 15.0705 3.8C14.9405 3.94 14.9105 4.14 14.9705 4.31C14.7205 4.09 14.4605 3.89 14.1905 3.71C14.2105 3.71 14.2305 3.73 14.2505 3.73C14.4205 3.73 14.5805 3.65 14.6705 3.5C14.8205 3.27 14.7505 2.96 14.5105 2.81C14.2705 2.66 14.0205 2.52 13.7705 2.39C13.5305 2.27 13.2205 2.37 13.1005 2.62C12.9805 2.87 13.0805 3.17 13.3205 3.29C13.3705 3.32 13.4205 3.35 13.4705 3.37H6.34047C6.22047 3.44 6.11047 3.52 5.99047 3.6C6.08047 3.44 6.08047 3.24 5.97047 3.08C5.82047 2.85 5.51047 2.79 5.28047 2.94C5.04047 3.1 4.82047 3.27 4.60047 3.45C4.39047 3.63 4.36047 3.94 4.53047 4.15C4.63047 4.27 4.77047 4.33 4.92047 4.33C4.96047 4.33 4.99047 4.32 5.03047 4.31C4.93047 4.4 4.82047 4.48 4.73047 4.57H7.37047V6.37C7.37047 6.7 7.64047 6.97 7.97047 6.97H9.47047V8.78H9.30047C9.02047 8.78 8.80047 9 8.80047 9.28C8.80047 9.56 9.02047 9.78 9.30047 9.78H9.47047V10.04C8.98047 10.24 8.63047 10.72 8.63047 11.29C8.63047 12.03 9.23047 12.63 9.97047 12.63C10.3405 12.63 10.6805 12.49 10.9305 12.22C11.1805 11.97 11.3105 11.64 11.3105 11.28C11.3105 11 11.0905 10.78 10.8105 10.78C10.5305 10.78 10.3105 11 10.3105 11.28C10.3105 11.37 10.2805 11.45 10.2105 11.52C10.0105 11.73 9.62047 11.56 9.62047 11.28C9.62047 11.09 9.77047 10.94 9.96047 10.94C10.2405 10.94 10.4605 10.72 10.4605 10.44V9.78H10.6505C10.9305 9.78 11.1505 9.56 11.1505 9.28C11.1505 9 10.9305 8.78 10.6505 8.78H10.4605V6.97H12.0205C12.3505 6.97 12.6205 6.7 12.6205 6.37H12.6105ZM11.4105 5.77H8.56047V4.57H11.4105V5.77Z"
          fill={color}
        />
        <path
          d="M2.77047 11.98C2.70047 11.74 2.65047 11.5 2.61047 11.25C2.57047 10.98 2.31047 10.79 2.03047 10.84C1.76047 10.88 1.57047 11.14 1.62047 11.41C1.67047 11.69 1.73047 11.97 1.80047 12.24C1.86047 12.46 2.06047 12.61 2.28047 12.61C2.32047 12.61 2.37047 12.61 2.41047 12.59C2.68047 12.52 2.83047 12.24 2.76047 11.98H2.77047Z"
          fill={color}
        />
        <path
          d="M3.37047 6.02C3.53047 6.02 3.69047 5.94 3.78047 5.8C3.92047 5.59 4.07047 5.39 4.23047 5.2C4.41047 4.99 4.38047 4.67 4.17047 4.5C3.96047 4.33 3.64047 4.35 3.46047 4.56C3.28047 4.78 3.11047 5 2.95047 5.24C2.79047 5.47 2.86047 5.78 3.08047 5.93C3.17047 5.99 3.26047 6.02 3.36047 6.02H3.37047Z"
          fill={color}
        />
        <path
          d="M3.62047 13.95C3.49047 13.74 3.37047 13.52 3.26047 13.3C3.14047 13.05 2.84047 12.95 2.59047 13.07C2.34047 13.19 2.24047 13.49 2.36047 13.74C2.48047 13.99 2.62047 14.24 2.77047 14.48C2.86047 14.63 3.03047 14.72 3.19047 14.72C3.28047 14.72 3.37047 14.7 3.45047 14.64C3.68047 14.49 3.76047 14.19 3.61047 13.95H3.62047Z"
          fill={color}
        />
        <path
          d="M4.47047 15.07C4.28047 14.87 3.97047 14.85 3.76047 15.04C3.56047 15.23 3.54047 15.54 3.73047 15.75C3.92047 15.96 4.12047 16.16 4.33047 16.35C4.43047 16.43 4.54047 16.48 4.66047 16.48C4.80047 16.48 4.93047 16.42 5.03047 16.31C5.21047 16.1 5.20047 15.79 4.99047 15.6C4.80047 15.43 4.62047 15.26 4.46047 15.07H4.47047Z"
          fill={color}
        />
        <path
          d="M2.22047 8.06C2.27047 8.08 2.32047 8.08 2.37047 8.08C2.58047 8.08 2.78047 7.94 2.85047 7.73C2.93047 7.49 3.01047 7.26 3.11047 7.03C3.22047 6.78 3.11047 6.48 2.85047 6.37C2.60047 6.26 2.30047 6.37 2.19047 6.63C2.08047 6.89 1.98047 7.15 1.89047 7.42C1.81047 7.68 1.95047 7.96 2.22047 8.05V8.06Z"
          fill={color}
        />
        <path
          d="M6.87047 3.13C6.94047 3.13 7.00047 3.12 7.07047 3.09C7.30047 2.99 7.53047 2.91 7.77047 2.83C8.03047 2.75 8.18047 2.47 8.10047 2.2C8.02047 1.94 7.74047 1.79 7.47047 1.87C7.20047 1.95 6.93047 2.05 6.67047 2.16C6.42047 2.27 6.30047 2.56 6.41047 2.82C6.49047 3.01 6.68047 3.12 6.87047 3.12V3.13Z"
          fill={color}
        />
        <path
          d="M17.2705 8.16C17.3305 8.4 17.3805 8.65 17.4205 8.9C17.4605 9.15 17.6705 9.33 17.9105 9.33C17.9305 9.33 17.9605 9.33 17.9805 9.33C18.2505 9.29 18.4405 9.04 18.4005 8.76C18.3605 8.48 18.3005 8.2 18.2305 7.93C18.1605 7.66 17.8905 7.5 17.6205 7.57C17.3505 7.64 17.1905 7.91 17.2605 8.18L17.2705 8.16Z"
          fill={color}
        />
        <path
          d="M16.4505 6.17C16.5805 6.38 16.6905 6.6 16.8005 6.83C16.8805 7.01 17.0605 7.12 17.2505 7.12C17.3205 7.12 17.3905 7.11 17.4605 7.07C17.7105 6.95 17.8205 6.66 17.7005 6.41C17.5805 6.15 17.4505 5.9 17.3005 5.66C17.1605 5.42 16.8505 5.34 16.6205 5.49C16.3805 5.63 16.3005 5.94 16.4505 6.18V6.17Z"
          fill={color}
        />
        <path
          d="M11.2905 2.61C11.5405 2.65 11.7805 2.71 12.0205 2.78C12.0605 2.79 12.1105 2.8 12.1505 2.8C12.3705 2.8 12.5705 2.66 12.6305 2.43C12.7005 2.16 12.5505 1.89 12.2805 1.81C12.0105 1.73 11.7305 1.67 11.4505 1.62C11.1805 1.57 10.9205 1.76 10.8705 2.03C10.8205 2.3 11.0005 2.56 11.2805 2.61H11.2905Z"
          fill={color}
        />
        <path
          d="M9.09047 2.55C9.09047 2.55 9.13047 2.55 9.15047 2.55C9.40047 2.52 9.65047 2.51 9.90047 2.5C10.1805 2.5 10.4005 2.27 10.3905 1.99C10.3905 1.72 10.1605 1.5 9.89047 1.5C9.60047 1.5 9.32047 1.52 9.04047 1.55C8.77047 1.58 8.57047 1.83 8.60047 2.1C8.63047 2.36 8.85047 2.54 9.10047 2.54L9.09047 2.55Z"
          fill={color}
        />
        <path
          d="M1.99047 10.34C2.26047 10.34 2.49047 10.12 2.49047 9.85C2.49047 9.6 2.51047 9.35 2.54047 9.1C2.57047 8.83 2.38047 8.58 2.10047 8.54C1.84047 8.51 1.58047 8.7 1.54047 8.98C1.51047 9.26 1.49047 9.54 1.48047 9.83C1.48047 10.11 1.69047 10.33 1.97047 10.34H1.99047Z"
          fill={color}
        />
        <path
          d="M14.8805 15.7C14.6905 15.86 14.4905 16.02 14.2905 16.16C14.0605 16.32 14.0105 16.63 14.1705 16.86C14.2705 17 14.4205 17.07 14.5805 17.07C14.6805 17.07 14.7805 17.04 14.8705 16.98C15.1005 16.82 15.3305 16.64 15.5405 16.46C15.7505 16.28 15.7705 15.96 15.5905 15.75C15.4105 15.54 15.0905 15.52 14.8805 15.7Z"
          fill={color}
        />
        <path
          d="M16.9905 13.93C16.7605 13.78 16.4505 13.85 16.3005 14.08C16.1605 14.29 16.0205 14.49 15.8605 14.69C15.6905 14.9 15.7205 15.22 15.9405 15.39C16.0305 15.46 16.1405 15.5 16.2505 15.5C16.4005 15.5 16.5405 15.44 16.6405 15.31C16.8205 15.09 16.9805 14.86 17.1405 14.62C17.2905 14.39 17.2205 14.08 16.9905 13.93Z"
          fill={color}
        />
        <path
          d="M17.8205 11.79C17.5605 11.71 17.2805 11.86 17.2005 12.13C17.1305 12.37 17.0405 12.61 16.9505 12.84C16.8505 13.1 16.9705 13.39 17.2305 13.49C17.2905 13.51 17.3505 13.53 17.4205 13.53C17.6205 13.53 17.8005 13.41 17.8805 13.22C17.9905 12.96 18.0805 12.69 18.1605 12.42C18.2405 12.15 18.0905 11.88 17.8205 11.8V11.79Z"
          fill={color}
        />
        <path
          d="M13.0705 16.85C12.8405 16.95 12.6105 17.04 12.3705 17.12C12.1105 17.21 11.9705 17.49 12.0505 17.75C12.1205 17.96 12.3105 18.09 12.5205 18.09C12.5705 18.09 12.6305 18.09 12.6805 18.06C12.9505 17.97 13.2105 17.87 13.4705 17.75C13.7205 17.64 13.8305 17.34 13.7205 17.09C13.6105 16.84 13.3105 16.73 13.0605 16.84L13.0705 16.85Z"
          fill={color}
        />
        <path
          d="M18.0005 9.5C17.7205 9.5 17.5005 9.72 17.5005 10C17.5005 10.25 17.4905 10.5 17.4605 10.75C17.4305 11.02 17.6305 11.27 17.9105 11.3C17.9305 11.3 17.9405 11.3 17.9605 11.3C18.2105 11.3 18.4305 11.11 18.4605 10.85C18.4905 10.57 18.5005 10.29 18.5005 10C18.5005 9.71 18.2805 9.5 18.0005 9.5Z"
          fill={color}
        />
        <path
          d="M11.0005 17.43C10.7505 17.46 10.5105 17.48 10.2505 17.49C9.97047 17.49 9.76047 17.73 9.77047 18.01C9.77047 18.28 10.0005 18.49 10.2705 18.49C10.2705 18.49 10.2805 18.49 10.2905 18.49C10.5805 18.49 10.8605 18.46 11.1405 18.42C11.4105 18.38 11.6105 18.13 11.5705 17.86C11.5305 17.59 11.2705 17.4 11.0105 17.43H11.0005Z"
          fill={color}
        />
        <path
          d="M6.80047 16.78C6.57047 16.67 6.35047 16.55 6.14047 16.43C5.90047 16.29 5.60047 16.36 5.45047 16.6C5.31047 16.84 5.38047 17.14 5.62047 17.29C5.86047 17.44 6.11047 17.57 6.37047 17.69C6.44047 17.72 6.51047 17.74 6.58047 17.74C6.77047 17.74 6.95047 17.63 7.03047 17.45C7.15047 17.2 7.04047 16.9 6.79047 16.78H6.80047Z"
          fill={color}
        />
        <path
          d="M8.85047 17.41C8.60047 17.37 8.36047 17.32 8.12047 17.26C7.84047 17.19 7.58047 17.35 7.51047 17.62C7.44047 17.89 7.60047 18.16 7.87047 18.23C8.14047 18.3 8.42047 18.36 8.70047 18.4C8.73047 18.4 8.75047 18.4 8.78047 18.4C9.02047 18.4 9.24047 18.22 9.27047 17.98C9.31047 17.71 9.12047 17.45 8.85047 17.41Z"
          fill={color}
        />
        <path
          d="M7.26047 11.75C7.77047 11.24 7.77047 10.42 7.26047 9.91C6.75047 9.4 5.93047 9.4 5.42047 9.91C4.91047 10.42 4.91047 11.24 5.42047 11.75C5.67047 12 6.01047 12.13 6.34047 12.13C6.67047 12.13 7.01047 12 7.26047 11.75ZM6.13047 10.61C6.19047 10.55 6.27047 10.52 6.34047 10.52C6.41047 10.52 6.50047 10.55 6.55047 10.61C6.67047 10.73 6.67047 10.92 6.55047 11.04C6.43047 11.16 6.24047 11.16 6.12047 11.04C6.00047 10.92 6.00047 10.73 6.12047 10.61H6.13047Z"
          fill={color}
        />
        <path
          d="M8.21047 14.22V13.81C8.21047 13.04 7.58047 12.41 6.81047 12.41H5.86047C5.09047 12.41 4.46047 13.04 4.46047 13.81V14.22C4.46047 14.5 4.68047 14.72 4.96047 14.72C5.24047 14.72 5.46047 14.5 5.46047 14.22V13.81C5.46047 13.59 5.64047 13.41 5.86047 13.41H6.81047C7.03047 13.41 7.21047 13.59 7.21047 13.81V14.22C7.21047 14.5 7.43047 14.72 7.71047 14.72C7.99047 14.72 8.21047 14.5 8.21047 14.22Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19326">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
