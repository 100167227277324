import type { IconChildrenProps } from "../icon.types";

export const Notification = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M10.0006 3.0243C7.52551 3.0243 5.51991 5.02842 5.51991 7.50003V10.8334C5.51991 11.8139 5.07534 12.7418 4.30964 13.3564L4.05214 13.5631C3.6749 13.8659 3.88817 14.4758 4.37398 14.4758H15.6273C16.1131 14.4758 16.3264 13.8658 15.9492 13.5631L15.6917 13.3564C14.9273 12.7421 14.4814 11.8142 14.4814 10.8334V7.50003C14.4814 5.02842 12.4758 3.0243 10.0006 3.0243ZM4.47138 7.50003C4.47138 4.44831 6.94745 1.97577 10.0006 1.97577C13.0538 1.97577 15.5299 4.44831 15.5299 7.50003V10.8334C15.5299 11.4958 15.8309 12.1231 16.3481 12.5388L16.6055 12.7453C17.7565 13.6692 17.1015 15.5243 15.6273 15.5243H4.37398C2.89979 15.5243 2.24474 13.6692 3.39582 12.7453L3.65333 12.5387C4.17096 12.1232 4.47138 11.4961 4.47138 10.8334V7.50003Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.22656 17.5002C8.22656 17.2107 8.46128 16.976 8.75083 16.976H11.2508C11.5404 16.976 11.7751 17.2107 11.7751 17.5002C11.7751 17.7898 11.5404 18.0245 11.2508 18.0245H8.75083C8.46128 18.0245 8.22656 17.7898 8.22656 17.5002Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
