import type { IconChildrenProps } from "../icon.types";

export const Users = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M2.19314 7.52791C3.06909 6.65199 4.4893 6.65199 5.36522 7.52792C6.24119 8.40388 6.24119 9.8241 5.36523 10.7C4.4893 11.576 3.06908 11.576 2.19314 10.7C1.31718 9.82409 1.31718 8.40389 2.19314 7.52791ZM4.55726 8.22122C4.0921 7.81499 3.38501 7.83345 2.94184 8.27661C2.47938 8.73908 2.47938 9.48887 2.94184 9.95131C3.40429 10.4138 4.15408 10.4138 4.61653 9.9513C5.05972 9.50814 5.07818 8.80107 4.67192 8.33588L4.61652 8.27662L4.55726 8.22122ZM14.6657 7.5279C15.5417 6.65199 16.9619 6.65199 17.8378 7.52792C18.7138 8.40388 18.7138 9.8241 17.8378 10.7C16.9619 11.576 15.5417 11.576 14.6657 10.7C13.7898 9.82409 13.7898 8.40389 14.6657 7.5279ZM17.0299 8.22122C16.5647 7.81499 15.8576 7.83345 15.4144 8.2766C14.952 8.73908 14.952 9.48888 15.4144 9.95131C15.8769 10.4138 16.6267 10.4138 17.0891 9.9513C17.5323 9.50814 17.5508 8.80107 17.1445 8.33588L17.0891 8.27662L17.0299 8.22122ZM17.2152 12.354C18.6449 12.354 19.8095 13.4868 19.8605 14.904L19.8623 15.001V15.9294C19.8623 16.2218 19.6252 16.4588 19.3328 16.4588C19.0587 16.4588 18.8333 16.2505 18.8062 15.9835L18.8034 15.9294V15.001C18.8034 14.1519 18.1377 13.4588 17.2996 13.415L17.2152 13.4128H16.5367C16.2443 13.4128 16.0073 13.1758 16.0073 12.8834C16.0073 12.6093 16.2156 12.3838 16.4826 12.3567L16.5367 12.354H17.2152ZM4.02293 12.8834C4.02293 12.591 3.78591 12.354 3.49352 12.354H2.81503L2.71797 12.3557C1.30078 12.4068 0.167969 13.5714 0.167969 15.001V15.9294L0.170702 15.9835C0.197813 16.2505 0.423269 16.4588 0.697381 16.4588C0.989767 16.4588 1.22679 16.2218 1.22679 15.9294V15.001L1.22899 14.9167C1.27283 14.0785 1.96593 13.4128 2.81503 13.4128H3.49352L3.54765 13.4101C3.81461 13.383 4.02293 13.1575 4.02293 12.8834Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M7.77517 4.46908C9.01232 3.23191 11.0182 3.23191 12.2554 4.46907C13.4926 5.70627 13.4926 7.71213 12.2554 8.94933C11.0182 10.1865 9.01233 10.1865 7.77518 8.94933C6.53798 7.71213 6.53798 5.70627 7.77517 4.46908ZM11.4304 5.14517C10.6025 4.39486 9.32258 4.41906 8.52388 5.21778C7.70018 6.04148 7.70018 7.37693 8.52388 8.20062C9.34753 9.02428 10.683 9.02428 11.5067 8.20062C12.3054 7.40189 12.3296 6.12194 11.5793 5.29404L11.5067 5.21778L11.4304 5.14517ZM15.0659 14.4665C15.0094 12.5861 13.4674 11.0792 11.5733 11.0792H8.45701L8.35018 11.0808C6.46985 11.1372 4.96289 12.6793 4.96289 14.5733V15.9294L4.96562 15.9836C4.99274 16.2505 5.21819 16.4588 5.4923 16.4588C5.78469 16.4588 6.02171 16.2218 6.02171 15.9294V14.5733L6.02347 14.4799C6.07256 13.1782 7.14331 12.138 8.45701 12.138H11.5733L11.6667 12.1398C12.9684 12.1889 14.0086 13.2596 14.0086 14.5733V15.9294L14.0114 15.9836C14.0385 16.2505 14.2639 16.4588 14.538 16.4588C14.8304 16.4588 15.0675 16.2218 15.0675 15.9294V14.5733L15.0659 14.4665Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
