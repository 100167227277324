export const getBaseUrl = () => {
  const appName = import.meta.env.VITE_APP_NAME;

  if (appName === "client") {
    const HOST = window.APP_HOST;
    const PORT = window.APP_PORT;
    const SCHEME = window.APP_SCHEME;

    if (!(HOST && PORT && SCHEME)) {
      throw new Error("Client: Missing backend host information");
    }

    return `${SCHEME}://${HOST}:${PORT}/api`;
  } else {
    const baseUrl = import.meta.env.VITE_INTENSEYE_API_URL;

    if (!baseUrl || typeof baseUrl !== "string") {
      throw new Error("Missing VITE_INTENSEYE_API_URL in .env");
    }

    return baseUrl;
  }
};
