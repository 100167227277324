import type { IconChildrenProps } from "../icon.types";

export const Computer = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M16.7769 2.2706C17.5106 2.2706 18.1107 2.84532 18.1512 3.56898L18.1534 3.64707V16.353C18.1534 17.0867 17.5786 17.6867 16.855 17.7272L16.7769 17.7294H14.0239C13.7316 17.7294 13.4945 17.4924 13.4945 17.2C13.4945 16.9259 13.7029 16.7004 13.9698 16.6733L14.0239 16.6706H16.7769C16.9375 16.6706 17.0706 16.5509 17.0916 16.396L17.0945 16.353V3.64707C17.0945 3.48648 16.9748 3.35339 16.8199 3.33233L16.7769 3.32942H11.271C11.1104 3.32942 10.9773 3.44913 10.9563 3.60402L10.9534 3.64707V5.49959C10.9534 5.79197 10.7163 6.029 10.4239 6.029C10.1498 6.029 9.92438 5.82068 9.89726 5.55372L9.89453 5.49959V3.64707C9.89453 2.91331 10.4693 2.31329 11.1929 2.27278L11.271 2.2706H16.7769Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M5.34177 17.7294C5.04939 17.7294 4.81236 17.4924 4.81236 17.2C4.81236 16.9259 5.02069 16.7005 5.28764 16.6733L5.34177 16.6706H7.77707V15.1883H3.22413C2.49037 15.1883 1.89034 14.6135 1.84984 13.8899L1.84766 13.8118V7.03531C1.84766 6.30156 2.42238 5.70153 3.14604 5.66102L3.22413 5.65884H13.3888C14.1226 5.65884 14.7226 6.23357 14.7631 6.95723L14.7653 7.03531V13.8118C14.7653 14.5455 14.1906 15.1456 13.4669 15.1861L13.3888 15.1883H8.83589V16.6706H11.2712C11.5636 16.6706 11.8006 16.9076 11.8006 17.2C11.8006 17.4741 11.5923 17.6996 11.3253 17.7267L11.2712 17.7294H5.34177ZM13.3888 6.71767H3.22413C3.06354 6.71767 2.93045 6.83737 2.90938 6.99226L2.90648 7.03531V13.8118C2.90648 13.9724 3.02618 14.1055 3.18107 14.1265L3.22413 14.1294H13.3888C13.5494 14.1294 13.6825 14.0097 13.7036 13.8548L13.7065 13.8118V7.03531C13.7065 6.86012 13.564 6.71767 13.3888 6.71767Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
