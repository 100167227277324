import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseExclusionZone = ({ oppositeColor }: IconChildrenProps) => {
  return (
    <>
      <g clipPath="url(#clip0_1030_19336)">
        <path
          d="M16.01 3.98991C12.7 0.679914 7.3 0.669914 3.99 3.98991C2.38 5.59991 1.5 7.72991 1.5 9.99991C1.5 12.2699 2.38 14.4099 3.99 16.0099C5.6 17.6099 7.82 18.4999 10 18.4999C12.18 18.4999 14.35 17.6699 16.01 16.0099C17.62 14.3999 18.5 12.2699 18.5 9.99991C18.5 7.72991 17.62 5.59991 16.01 3.98991ZM4.7 4.69991C6.16 3.23991 8.08 2.50991 10 2.50991C11.76 2.50991 13.51 3.12991 14.91 4.34991L4.34 14.9099C3.15 13.5499 2.5 11.8199 2.5 9.99991C2.5 7.99991 3.28 6.10991 4.7 4.69991ZM15.3 15.2999C12.49 18.1099 7.99 18.2099 5.05 15.6199L15.62 5.04991C16.83 6.41991 17.5 8.15991 17.5 9.99991C17.5 11.9999 16.72 13.8899 15.3 15.2999Z"
          fill="#FF3D00"
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19336">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
