import type { IconChildrenProps } from "../icon.types";

export const Renew = ({ color }: IconChildrenProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10.3333 5.86197V7.0553C10.3333 7.3553 10.6933 7.50197 10.9 7.28863L12.76 5.42863C12.8933 5.2953 12.8933 5.08863 12.76 4.9553L10.9 3.0953C10.8039 3.0011 10.6606 2.97392 10.5367 3.0264C10.4127 3.07889 10.3325 3.20073 10.3333 3.3353V4.52863C7.38667 4.52863 5 6.9153 5 9.86197C5 10.5553 5.13333 11.222 5.38 11.8286C5.56 12.2753 6.13333 12.3953 6.47333 12.0553C6.65333 11.8753 6.72667 11.602 6.62667 11.362C6.43333 10.902 6.33333 10.3886 6.33333 9.86197C6.33333 7.6553 8.12667 5.86197 10.3333 5.86197ZM14.1933 7.66863C14.0133 7.84863 13.94 8.12863 14.04 8.36197C14.2267 8.82863 14.3333 9.3353 14.3333 9.86197C14.3333 12.0686 12.54 13.862 10.3333 13.862V12.6686C10.3333 12.3686 9.97333 12.222 9.76667 12.4353L7.90667 14.2953C7.77333 14.4286 7.77333 14.6353 7.90667 14.7686L9.76667 16.6286C9.8619 16.722 10.0036 16.7496 10.1269 16.6988C10.2503 16.6481 10.3315 16.5287 10.3333 16.3953V15.1953C13.28 15.1953 15.6667 12.8086 15.6667 9.86197C15.6667 9.16863 15.5333 8.50197 15.2867 7.8953C15.1067 7.44863 14.5333 7.32863 14.1933 7.66863Z"
    fill={color}
  />
);
