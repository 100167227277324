import type { IconChildrenProps } from "~/components/icon";

export const Submit = ({
  infoBackgroundColor,
  infoColor,
}: IconChildrenProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill={infoBackgroundColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5556 13.8888V7.68323L13.2667 10.3943C13.4834 10.611 13.8389 10.611 14.0556 10.3943C14.2723 10.1777 14.2723 9.82767 14.0556 9.611L10.3945 5.94989C10.2907 5.84588 10.1498 5.78742 10.0028 5.78742C9.85586 5.78742 9.71494 5.84588 9.61114 5.94989L5.94447 9.60545C5.80454 9.74538 5.7499 9.94933 5.80111 10.1405C5.85233 10.3316 6.00163 10.4809 6.19278 10.5321C6.38393 10.5834 6.58788 10.5287 6.72781 10.3888L9.44447 7.68323V13.8888C9.44447 14.1943 9.69447 14.4443 10 14.4443C10.3056 14.4443 10.5556 14.1943 10.5556 13.8888Z"
      fill={infoColor}
    />
  </svg>
);
