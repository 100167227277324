import { type CountryCode, parsePhoneNumber } from "libphonenumber-js";
import type { ComponentPropsWithoutRef } from "react";
import yup from "./yup";

export enum PasswordErrors {
  LetterAndDigit = "password-letter-and-digit",
  SpecialCharacter = "password-special-character",
  Length = "password-length",
  Whitespace = "password-whitespace",
  PersonalInformation = "password-personal-information",
}

const passwordRegex = {
  // At least one uppercase, one lowercase letter and one digit
  [PasswordErrors.LetterAndDigit]: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).+$/,
  // At least one special character
  [PasswordErrors.SpecialCharacter]:
    /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^\\_`{}|~]).+$/,
  // No whitespace
  [PasswordErrors.Whitespace]: /^\S+$/,
};

const emailSchema = yup.string().trim().email().required();
const nameSchema = yup.string().trim().required();
const passwordSchema = yup.string().trim().required();
const passwordSchemasByRule = (
  firstName?: string,
  lastName?: string,
  userName?: string,
) => ({
  [PasswordErrors.Length]: yup.string().min(12, PasswordErrors.Length),
  [PasswordErrors.LetterAndDigit]: yup
    .string()
    .matches(
      passwordRegex[PasswordErrors.LetterAndDigit],
      PasswordErrors.LetterAndDigit,
    ),
  [PasswordErrors.SpecialCharacter]: yup
    .string()
    .matches(
      passwordRegex[PasswordErrors.SpecialCharacter],
      PasswordErrors.SpecialCharacter,
    ),
  [PasswordErrors.Whitespace]: yup
    .string()
    .matches(
      passwordRegex[PasswordErrors.Whitespace],
      PasswordErrors.Whitespace,
    ),
  [PasswordErrors.PersonalInformation]: yup
    .string()
    .matches(
      new RegExp(
        `(${firstName ?? ""}|${lastName ?? ""}|${userName ?? ""})`,
        "gi",
      ),
      PasswordErrors.PersonalInformation,
    ),
});

const positiveNumberSchema = yup.number().positive().required();

export const validateEmail = (email?: string) => emailSchema.isValidSync(email);

export const validateFile = (file?: File, accept?: string[]) => {
  if (file && accept) {
    const fileName = file.name ?? "";
    const mimeType = file.type ?? "";
    const baseMimeType = mimeType.replace(/\/.*$/, "");

    return accept.some((type) => {
      const validType = type.trim();
      if (validType.startsWith(".")) {
        return fileName.toLowerCase().endsWith(validType.toLowerCase());
      } else if (validType.endsWith("/*")) {
        return baseMimeType === validType.replace(/\/.*$/, "");
      }
      return mimeType === validType;
    });
  }

  return false;
};

export const validateFileSize = (file?: File, limitInBytes?: number) => {
  if (file && limitInBytes) {
    return file.size < limitInBytes;
  }
  return false;
};

export const validateImage = async (
  src?: string,
  crossOrigin?: ComponentPropsWithoutRef<"img">["crossOrigin"],
): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (src) {
      const img = new Image();
      const hasDecode = Boolean(img.decode);
      const onError = () => {
        reject(new Error("Unable to validate image"));
      };
      const onLoad = () => {
        resolve();
      };
      img.crossOrigin = crossOrigin ?? null;
      img.src = src;
      if (hasDecode) {
        img.decode().then(onLoad).catch(onError);
      } else {
        img.addEventListener("load", onLoad);
        img.addEventListener("error", onError);
      }
    } else {
      reject(new Error("Unable to validate image"));
    }
  });
};

export const validateIpSpeakerVolume = (n?: number) =>
  positiveNumberSchema.min(1).max(100).isValidSync(n);

export const validateName = (name?: string) => nameSchema.isValidSync(name);

export const validatePassword = (password?: string) =>
  passwordSchema.isValidSync(password);

export const validatePasswordByRule = (
  rule: PasswordErrors,
  password?: string,
  userName?: string,
  firstName?: string,
  lastName?: string,
) =>
  passwordSchemasByRule(firstName, lastName, userName)[rule].isValidSync(
    password,
  );

export const validatePhoneNumber = (number: string, cc: CountryCode) => {
  try {
    const parsed = parsePhoneNumber(number, cc);
    return parsed.isValid();
  } catch (e) {
    return false;
  }
};

export const validatePositiveNumber = (n?: number) =>
  positiveNumberSchema.isValidSync(n);
