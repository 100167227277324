import { AR_CATEGORY_BEHAVIOR } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseContactWithElectricity = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19320)">
        <path
          d="M14.93 14.41C13.86 14.41 13 15.28 13 16.34C13 16.98 13.52 17.5 14.16 17.5H17.22C17.86 17.5 18.38 16.98 18.38 16.34C18.38 15.27 17.51 14.41 16.45 14.41H14.93ZM17.22 16.5H14.16C14.07 16.5 14 16.43 14 16.34C14 15.83 14.42 15.41 14.93 15.41H16.44C16.95 15.41 17.37 15.83 17.37 16.34C17.37 16.43 17.3 16.5 17.21 16.5H17.22Z"
          fill={getAlertCategoryColor(AR_CATEGORY_BEHAVIOR)}
        />
        <path
          d="M15.69 13.91C16.16 13.91 16.61 13.73 16.94 13.39C17.27 13.05 17.46 12.61 17.46 12.14C17.46 11.67 17.28 11.22 16.94 10.89C16.27 10.22 15.1 10.22 14.43 10.89C14.1 11.22 13.91 11.67 13.91 12.14C13.91 12.61 14.09 13.06 14.43 13.39C14.76 13.72 15.21 13.91 15.68 13.91H15.69ZM15.14 11.59C15.29 11.44 15.48 11.36 15.69 11.36C15.9 11.36 16.09 11.44 16.24 11.59C16.39 11.74 16.47 11.93 16.47 12.14C16.47 12.35 16.39 12.54 16.24 12.69C15.95 12.98 15.44 12.98 15.14 12.69C14.99 12.54 14.91 12.35 14.91 12.14C14.91 11.93 14.99 11.74 15.14 11.59Z"
          fill={getAlertCategoryColor(AR_CATEGORY_BEHAVIOR)}
        />
        <path
          d="M2.23 2.23003C1.76 2.70003 1.5 3.33003 1.5 4.00003C1.5 4.67003 1.76 5.30003 2.23 5.77003C2.72 6.26003 3.36 6.50003 4 6.50003C4.64 6.50003 5.28 6.26003 5.77 5.77003C6.24 5.30003 6.5 4.67003 6.5 4.00003C6.5 3.33003 6.24 2.70003 5.77 2.23003C4.8 1.26003 3.21 1.25003 2.23 2.23003ZM2.94 2.94003C3.23 2.65003 3.62 2.50003 4 2.50003C4.22 2.50003 4.43 2.56003 4.63 2.65003L2.65 4.63003C2.56 4.43003 2.5 4.22003 2.5 3.99003C2.5 3.59003 2.66 3.21003 2.94 2.93003V2.94003ZM5.06 5.06003C4.6 5.52003 3.92 5.60003 3.36 5.34003L5.35 3.35003C5.45 3.55003 5.5 3.77003 5.5 4.00003C5.5 4.40003 5.34 4.78003 5.06 5.06003Z"
          fill={getAlertCategoryColor(AR_CATEGORY_BEHAVIOR)}
        />
        <path
          d="M14.0317 6.06H12.8317L13.3117 5.11C13.4317 4.86 13.3317 4.56 13.0917 4.44C12.8517 4.32 12.5417 4.41 12.4217 4.66L11.5817 6.33C11.5017 6.49 11.5117 6.67 11.6017 6.82C11.6917 6.97 11.8517 7.06 12.0317 7.06H13.2317L12.7517 8.01C12.6317 8.26 12.7317 8.56 12.9717 8.68C13.0417 8.72 13.1217 8.73 13.1917 8.73C13.3717 8.73 13.5517 8.63 13.6417 8.45L14.4817 6.78C14.5617 6.63 14.5517 6.44 14.4617 6.29C14.3717 6.14 14.2117 6.05 14.0317 6.05V6.06Z"
          fill={color}
        />
        <path
          d="M12.1317 2.81C12.3717 2.88 12.6117 2.96 12.8417 3.06C12.9017 3.09 12.9717 3.1 13.0317 3.1C13.2317 3.1 13.4117 2.98 13.4917 2.79C13.5917 2.53 13.4717 2.24 13.2217 2.14C12.9617 2.03 12.6917 1.94 12.4217 1.86C12.1517 1.78 11.8817 1.93 11.8017 2.2C11.7217 2.46 11.8717 2.74 12.1417 2.82L12.1317 2.81Z"
          fill={color}
        />
        <path
          d="M10.0017 2.5C10.2517 2.5 10.5017 2.51 10.7517 2.54C10.7717 2.54 10.7817 2.54 10.8017 2.54C11.0517 2.54 11.2717 2.35 11.3017 2.09C11.3317 1.82 11.1317 1.57 10.8517 1.54C10.5717 1.51 10.2917 1.5 10.0017 1.5C9.71172 1.5 9.50172 1.72 9.50172 2C9.50172 2.28 9.72172 2.5 10.0017 2.5Z"
          fill={color}
        />
        <path
          d="M17.7517 7.95C18.0117 7.86 18.1517 7.58 18.0717 7.32C17.9817 7.05 17.8817 6.79 17.7617 6.53C17.6517 6.28 17.3517 6.17 17.1017 6.28C16.8517 6.39 16.7317 6.69 16.8517 6.94C16.9517 7.17 17.0417 7.4 17.1217 7.64C17.1917 7.85 17.3917 7.98 17.5917 7.98C17.6417 7.98 17.7017 7.98 17.7517 7.95Z"
          fill={color}
        />
        <path
          d="M16.9717 5.14C16.8117 4.91 16.6317 4.68 16.4517 4.47C16.2717 4.26 15.9617 4.24 15.7417 4.42C15.5317 4.6 15.5117 4.92 15.6917 5.13C15.8517 5.32 16.0117 5.52 16.1517 5.72C16.2517 5.86 16.4017 5.93 16.5617 5.93C16.6617 5.93 16.7617 5.9 16.8517 5.84C17.0817 5.68 17.1317 5.37 16.9717 5.14Z"
          fill={color}
        />
        <path
          d="M18.4217 8.87C18.3817 8.6 18.1217 8.41 17.8617 8.44C17.5917 8.48 17.3917 8.73 17.4317 9C17.4617 9.24 17.4817 9.49 17.4917 9.75C17.4917 10.02 17.7217 10.24 17.9917 10.24H18.0017C18.2817 10.24 18.4917 10 18.4917 9.73C18.4917 9.44 18.4617 9.16 18.4217 8.88V8.87Z"
          fill={color}
        />
        <path
          d="M14.6217 2.87C14.3917 2.72 14.0817 2.78 13.9317 3.02C13.7817 3.25 13.8517 3.56 14.0817 3.71C14.2917 3.85 14.4917 3.99 14.6917 4.15C14.7817 4.22 14.8917 4.26 15.0017 4.26C15.1517 4.26 15.2917 4.2 15.3917 4.07C15.5617 3.85 15.5317 3.54 15.3117 3.37C15.0917 3.19 14.8617 3.03 14.6217 2.87Z"
          fill={color}
        />
        <path
          d="M2.14172 11.56C2.41172 11.52 2.61172 11.27 2.57172 11C2.54172 10.76 2.52172 10.51 2.51172 10.25C2.51172 9.97 2.26172 9.77 2.00172 9.76C1.72172 9.76 1.51172 10 1.51172 10.27C1.51172 10.56 1.54172 10.84 1.58172 11.12C1.61172 11.37 1.83172 11.55 2.07172 11.55C2.09172 11.55 2.11172 11.55 2.14172 11.55V11.56Z"
          fill={color}
        />
        <path
          d="M3.15172 13.07C3.05172 12.84 2.96172 12.61 2.88172 12.37C2.79172 12.11 2.51172 11.97 2.25172 12.05C1.99172 12.14 1.85172 12.42 1.93172 12.68C2.02172 12.95 2.12172 13.21 2.24172 13.47C2.32172 13.66 2.51172 13.77 2.70172 13.77C2.77172 13.77 2.84172 13.76 2.90172 13.73C3.15172 13.62 3.27172 13.32 3.15172 13.07Z"
          fill={color}
        />
        <path
          d="M3.84172 14.29C3.68172 14.06 3.37172 14.01 3.14172 14.17C2.91172 14.33 2.86172 14.64 3.02172 14.87C3.18172 15.1 3.36172 15.33 3.54172 15.54C3.64172 15.65 3.78172 15.71 3.92172 15.71C4.04172 15.71 4.15172 15.67 4.25172 15.59C4.46172 15.41 4.48172 15.09 4.30172 14.88C4.14172 14.69 3.98172 14.49 3.84172 14.29Z"
          fill={color}
        />
        <path
          d="M10.0017 17.5C9.75172 17.5 9.50172 17.49 9.25172 17.46C8.99172 17.44 8.73172 17.63 8.70172 17.91C8.67172 18.18 8.87172 18.43 9.15172 18.46C9.43172 18.49 9.71172 18.5 10.0017 18.5C10.2917 18.5 10.5017 18.28 10.5017 18C10.5017 17.72 10.2817 17.5 10.0017 17.5Z"
          fill={color}
        />
        <path
          d="M7.87172 17.19C7.63172 17.12 7.39172 17.04 7.16172 16.94C6.91172 16.83 6.61172 16.96 6.51172 17.21C6.41172 17.47 6.53172 17.76 6.78172 17.86C7.04172 17.97 7.31172 18.06 7.58172 18.14C7.63172 18.15 7.67172 18.16 7.72172 18.16C7.94172 18.16 8.14172 18.02 8.20172 17.8C8.28172 17.54 8.13172 17.26 7.86172 17.18L7.87172 17.19Z"
          fill={color}
        />
        <path
          d="M12.0517 10.88L11.8917 10.75L12.3617 10.16C12.5717 9.9 12.5317 9.52 12.2717 9.32C12.0117 9.11 11.6317 9.15 11.4317 9.41L10.9617 10L9.79172 9.06L10.2617 8.48C10.4717 8.22 10.4317 7.84 10.1717 7.64C9.91172 7.43 9.53172 7.47 9.33172 7.73L8.86172 8.32L8.70172 8.19C8.05172 7.67 7.11172 7.78 6.59172 8.42L6.25172 8.85C5.64172 9.61 5.36172 10.57 5.47172 11.54C5.55172 12.31 5.88172 13.01 6.39172 13.57L4.58172 15.82C4.37172 16.08 4.41172 16.46 4.67172 16.66C4.73172 16.71 4.79172 16.74 4.86172 16.76C5.03172 16.89 5.20172 17.02 5.38172 17.14C5.46172 17.2 5.56172 17.22 5.65172 17.22C5.81172 17.22 5.97172 17.14 6.07172 16.99C6.22172 16.76 6.15172 16.45 5.92172 16.3C5.88172 16.27 5.84172 16.24 5.80172 16.21L7.31172 14.32C7.87172 14.63 8.48172 14.79 9.09172 14.79C10.1617 14.79 11.2217 14.32 11.9517 13.42L12.2917 12.99C12.8117 12.34 12.7017 11.4 12.0617 10.88H12.0517ZM11.3517 12.24L11.0117 12.67C10.1717 13.73 8.62172 13.9 7.56172 13.05C7.05172 12.64 6.73172 12.06 6.66172 11.4C6.59172 10.75 6.77172 10.11 7.18172 9.6L7.52172 9.17C7.59172 9.09 7.68172 9.06 7.72172 9.06C7.72172 9.06 7.74172 9.06 7.75172 9.06C7.80172 9.06 7.87172 9.07 7.94172 9.13L8.56172 9.63L10.6617 11.32L11.2917 11.82C11.4217 11.92 11.4417 12.11 11.3417 12.24H11.3517Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19320">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
