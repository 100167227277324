import type { IconChildrenProps } from "~/components/icon";

export const Update = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <rect
      x="2.49805"
      y="2.4968"
      width="15.0062"
      height="15.0062"
      rx="3.53357"
      stroke={color}
      strokeWidth="1.32509"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1086 12.8333C12.1086 13.4046 11.6433 13.87 11.072 13.87L8.92539 13.87C8.3541 13.87 7.88875 13.4046 7.88875 12.8333L7.88875 10.6501L7.53547 10.6501C6.60405 10.6501 6.15911 9.52603 6.80627 8.87888L9.26949 6.41566C9.67404 6.0111 10.3287 6.0111 10.7333 6.41566L13.1965 8.87888C13.8512 9.53358 13.3827 10.6501 12.4619 10.6501L12.1086 10.6501L12.1086 12.8333ZM11.0974 12.8588C11.1056 12.8506 11.1086 12.8419 11.1086 12.8333L11.1086 9.6501L12.4619 9.6501C12.4706 9.6501 12.4752 9.64886 12.4771 9.64827C12.4791 9.64763 12.4807 9.64682 12.4823 9.64567C12.486 9.64304 12.4922 9.63679 12.4966 9.62609C12.5011 9.6154 12.5011 9.60688 12.5004 9.60282C12.5001 9.60106 12.4996 9.59955 12.4987 9.59782C12.4979 9.59622 12.4955 9.59212 12.4894 9.58598L10.0262 7.12276C10.0121 7.10873 9.99062 7.10873 9.97659 7.12276L7.51337 9.58598C7.50699 9.59237 7.50435 9.59683 7.50328 9.59889C7.50215 9.60108 7.50152 9.60304 7.50114 9.60518C7.50029 9.61001 7.50043 9.61869 7.50461 9.62893C7.50879 9.63916 7.51438 9.64457 7.51709 9.64649C7.51823 9.6473 7.51934 9.64788 7.52095 9.64839C7.52241 9.64886 7.52682 9.6501 7.53547 9.6501L8.88875 9.6501L8.88875 12.8333C8.88875 12.8419 8.89178 12.8506 8.89994 12.8588C8.9081 12.867 8.91687 12.87 8.92539 12.87L11.072 12.87C11.0805 12.87 11.0893 12.867 11.0974 12.8588Z"
      stroke={accentColor}
    />
  </>
);
