import { clsx } from "clsx";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import SimpleBar from "simplebar-react";
import type { Dimensions } from "~/types/dimensions";
import { AutoSizer } from "../auto-sizer";
import type { ScrollAreaProps } from "./scroll-area.types";
import css from "./scroll-area.module.css";

export const ScrollArea = forwardRef<HTMLDivElement, ScrollAreaProps>(
  (
    { children, classNames, disableScroll, horizontal, scrollTo, ...other },
    ref,
  ) => {
    const [scrollParentRef, setScrollParentRef] =
      useState<HTMLDivElement | null>(null);

    useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
      ref,
      () => scrollParentRef,
    );

    const child = useMemo(
      () => (
        <div className={clsx(css.children, classNames?.children)}>
          {children}
        </div>
      ),
      [children, classNames?.children],
    );

    const autoSizerChildrenRenderer = useCallback(
      ({ height }: Dimensions) => (
        <SimpleBar
          scrollableNodeProps={{
            className: classNames?.scrollNode,
            ref: setScrollParentRef,
          }}
          style={{ height }}
        >
          {child}
        </SimpleBar>
      ),
      [child, classNames?.scrollNode],
    );

    useEffect(() => {
      if (scrollTo && scrollParentRef) {
        scrollParentRef.scrollTo(scrollTo);
      }
    }, [scrollParentRef, scrollTo]);

    return (
      <div
        id="scroll-area"
        className={clsx(
          css.root,
          classNames?.root,
          horizontal && css.horizontal,
          disableScroll && css.simplebarDisableScroll,
        )}
        {...other}
      >
        <AutoSizer disableWidth>{autoSizerChildrenRenderer}</AutoSizer>
      </div>
    );
  },
);

ScrollArea.displayName = "ScrollArea";
