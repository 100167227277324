import { clsx } from "clsx";
import { type CSSProperties, useMemo, forwardRef } from "react";
import type { FlexboxProps } from "./flexbox.types";
import css from "./flexbox.module.css";

export const Flexbox = forwardRef<HTMLDivElement, FlexboxProps>(
  (
    {
      alignItems,
      basis,
      direction,
      gap,
      grow,
      justifyContent,
      fullWidth,
      width,
      wrap,
      inlineFlex,
      className,
      style,
      shrink,
      ...divProps
    },
    ref,
  ) => {
    const flexProperties = useMemo(
      () =>
        ({
          "--align-items": alignItems ?? "normal",
          "--display": inlineFlex ? "inline-flex" : "flex",
          "--flex-basis": basis ?? "auto",
          "--flex-direction": direction ?? "row",
          "--flex-grow": grow ?? 0,
          "--flex-shrink": shrink ?? 1,
          "--flex-wrap": wrap ?? "nowrap",
          "--gap": gap ?? "normal",
          "--justify-content": justifyContent ?? "flex-start",
          "--width": fullWidth ? "100%" : width ?? "auto",
        }) as CSSProperties,
      [
        alignItems,
        basis,
        direction,
        fullWidth,
        gap,
        grow,
        inlineFlex,
        justifyContent,
        shrink,
        width,
        wrap,
      ],
    );

    return (
      <div
        {...divProps}
        className={clsx(css.root, className)}
        ref={ref}
        style={{ ...style, ...flexProperties }}
      />
    );
  },
);

Flexbox.displayName = "Flexbox";
