import { LoginForm } from "../../components/login-form/login-form";
import { useGuardContext } from "../guard-route/guard-route.hooks";

export const LoginRoute = () => {
  const {
    appName,
    appUrl,
    emailPasswordLoginError,
    fromSignUp,
    redirect,
    ssoLoginError,
  } = useGuardContext();

  return (
    <LoginForm
      appName={appName}
      appUrl={appUrl}
      emailPasswordLoginError={emailPasswordLoginError}
      fromSignUp={fromSignUp}
      redirect={redirect}
      ssoLoginError={ssoLoginError}
    />
  );
};
