import type { IconChildrenProps } from "../icon.types";

export const Connectivity = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M16.9967 0.5C15.5553 0.5 14.3868 1.66845 14.3868 3.10982C14.3868 4.55118 15.5553 5.71963 16.9967 5.71963C18.438 5.71963 19.6065 4.55118 19.6065 3.10982C19.6065 1.66845 18.438 0.5 16.9967 0.5ZM16.9967 1.92311C17.6521 1.92311 18.1834 2.45441 18.1834 3.10982C18.1834 3.76522 17.6521 4.29653 16.9967 4.29653C16.3413 4.29653 15.81 3.76522 15.81 3.10982C15.81 2.45441 16.3413 1.92311 16.9967 1.92311ZM6.42633 6.31219H10.6182C11.6319 6.31219 12.4847 7.00086 12.7343 7.93586L13.6831 7.35106C14.0547 7.12177 14.5212 7.1117 14.9025 7.32447C15.2838 7.53737 15.52 7.93975 15.52 8.37626V12.0804C15.52 12.517 15.2838 12.9194 14.9025 13.1323C14.5213 13.345 14.0549 13.3349 13.6834 13.1059L12.7343 12.5209C12.4847 13.4559 11.6319 14.1446 10.6182 14.1446H6.42633C5.21682 14.1446 4.23633 13.1641 4.23633 11.9546V8.50219C4.23633 7.29268 5.21682 6.31219 6.42633 6.31219ZM12.8082 9.56107V10.8944L14.0964 11.6893V8.76714L12.8082 9.56107ZM10.6182 7.73529H6.42633C6.00278 7.73529 5.65943 8.07864 5.65943 8.50219V11.9546C5.65943 12.3781 6.00278 12.7215 6.42633 12.7215H10.6182C11.0418 12.7215 11.3851 12.3781 11.3851 11.9546V8.50219C11.3851 8.07864 11.0418 7.73529 10.6182 7.73529Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12.2516 1.44916C12.6446 1.44916 12.9632 1.76773 12.9632 2.16071C12.9632 2.52094 12.6955 2.81865 12.3482 2.86577L12.2516 2.87226H6.29319C4.27392 2.87226 2.62539 4.45961 2.52733 6.45455L2.52272 6.64274V13.814C2.52272 15.8332 4.11006 17.4818 6.10501 17.5798L6.29319 17.5844H13.4644C15.4837 17.5844 17.1322 15.9971 17.2303 14.0021L17.2349 13.814V7.85551C17.2349 7.46253 17.5535 7.14396 17.9464 7.14396C18.3067 7.14396 18.6044 7.41165 18.6515 7.75896L18.658 7.85551V13.814C18.658 16.6123 16.4448 18.8938 13.6733 19.0034L13.4644 19.0075H6.29319C3.49482 19.0075 1.21336 16.7943 1.10373 14.0228L1.09961 13.814V6.64274C1.09961 3.84437 3.31281 1.56291 6.08431 1.45328L6.29319 1.44916H12.2516Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
