import { AR_CATEGORY_ANOMALY } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseOpenClosedDoor = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19315)">
        <path
          d="M12.6684 7.0399C12.9984 7.0399 13.2684 6.7699 13.2684 6.4399V3.7799C13.2684 2.4699 12.1984 1.3999 10.8884 1.3999H3.77844C2.46844 1.3999 1.39844 2.4699 1.39844 3.7799V16.2199C1.39844 17.5299 2.46844 18.5999 3.77844 18.5999H10.8884C12.1984 18.5999 13.2684 17.5299 13.2684 16.2199V9.9999C13.2684 9.6699 12.9984 9.3999 12.6684 9.3999C12.3384 9.3999 12.0684 9.6699 12.0684 9.9999V16.2199C12.0684 16.8699 11.5384 17.3999 10.8884 17.3999H3.77844C3.12844 17.3999 2.59844 16.8699 2.59844 16.2199V3.7799C2.59844 3.1299 3.12844 2.5999 3.77844 2.5999H10.8884C11.5384 2.5999 12.0684 3.1299 12.0684 3.7799V6.4499C12.0684 6.7799 12.3384 7.0499 12.6684 7.0499V7.0399Z"
          fill={color}
        />
        <path
          d="M4.05844 14.4399C4.05844 14.7699 4.32844 15.0399 4.65844 15.0399C4.98844 15.0399 5.25844 14.7699 5.25844 14.4399C5.25844 14.1099 4.98844 13.8399 4.65844 13.8399C4.32844 13.8399 4.05844 14.1099 4.05844 14.4399Z"
          fill={color}
        />
        <path
          d="M6.72844 14.4399C6.72844 14.7699 6.99844 15.0399 7.32844 15.0399C7.65844 15.0399 7.92844 14.7699 7.92844 14.4399C7.92844 14.1099 7.65844 13.8399 7.32844 13.8399C6.99844 13.8399 6.72844 14.1099 6.72844 14.4399Z"
          fill={color}
        />
        <path
          d="M9.99844 15.0399C10.3298 15.0399 10.5984 14.7713 10.5984 14.4399C10.5984 14.1085 10.3298 13.8399 9.99844 13.8399C9.66707 13.8399 9.39844 14.1085 9.39844 14.4399C9.39844 14.7713 9.66707 15.0399 9.99844 15.0399Z"
          fill={color}
        />
        <path
          d="M7.32864 11.4899C6.35864 11.4899 5.44864 11.0699 4.82864 10.3299C4.20864 9.58989 3.94864 8.60989 4.10864 7.64989C4.33864 6.32989 5.42864 5.24989 6.74864 5.01989C7.77864 4.83989 8.79864 5.14989 9.54864 5.85989H17.0986C17.9186 5.85989 18.5886 6.52989 18.5886 7.34989V9.12989C18.5886 9.94989 17.9186 10.6199 17.0986 10.6199H9.54864C8.94864 11.1899 8.14864 11.5099 7.31864 11.5099L7.32864 11.4899ZM7.32864 6.15989C7.20864 6.15989 7.07864 6.16989 6.95864 6.18989C6.12864 6.33989 5.43864 7.01989 5.29864 7.84989C5.18864 8.46989 5.34864 9.07989 5.74864 9.55989C6.51864 10.4799 8.03864 10.5199 8.85864 9.60989C8.96864 9.47989 9.13864 9.40989 9.30864 9.40989H17.1086C17.2686 9.40989 17.3986 9.27989 17.3986 9.11989V7.33989C17.3986 7.17989 17.2686 7.04989 17.1086 7.04989H9.30864C9.13864 7.04989 8.97864 6.97989 8.85864 6.84989C8.45864 6.39989 7.90864 6.15989 7.32864 6.15989Z"
          fill={getAlertCategoryColor(AR_CATEGORY_ANOMALY)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19315">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
