import type { IconChildrenProps } from "../icon.types";

export const InfoCircle = ({ infoColor }: IconChildrenProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58173 18 10 18C14.4182 18 18 14.4182 18 10C18 5.58173 14.4183 2 10 2ZM9.99956 8.49242C10.3677 8.49242 10.672 8.76602 10.7202 9.12101L10.7268 9.21969V13.4621C10.7268 13.8638 10.4012 14.1894 9.99956 14.1894C9.63137 14.1894 9.32708 13.9158 9.27892 13.5608L9.27228 13.4621V9.21969C9.27228 8.81803 9.59789 8.49242 9.99956 8.49242ZM9.31774 6.49243C9.31774 6.11586 9.62299 5.8106 9.99955 5.8106C10.3761 5.8106 10.6813 6.11586 10.6813 6.49243C10.6813 6.86899 10.3761 7.17424 9.99955 7.17424C9.62299 7.17424 9.31774 6.86899 9.31774 6.49243Z"
    fill={infoColor}
  />
);
