import type { IconChildrenProps } from "../icon.types";

export const RadioSelected = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z"
    fill={color}
    fillRule="evenodd"
  />
);
