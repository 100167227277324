import type { IconChildrenProps } from "../icon.types";

export const CheckboxNonselected = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M15 2C16.6569 2 18 3.34315 18 5V15C18 16.6569 16.6569 18 15 18H5C3.34315 18 2 16.6569 2 15V5C2 3.34315 3.34315 2 5 2H15ZM15 4H5C4.48716 4 4.06449 4.38604 4.00673 4.88338L4 5V15C4 15.5128 4.38604 15.9355 4.88338 15.9933L5 16H15C15.5128 16 15.9355 15.614 15.9933 15.1166L16 15V5C16 4.48716 15.614 4.06449 15.1166 4.00673L15 4Z"
    fill={color}
    fillRule="evenodd"
  />
);
