import { AR_CATEGORY_PANDEMIC } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseFaceMask = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19296)">
        <path
          d="M17.5413 15.71C17.4713 14.4 16.5213 13.29 15.2213 13.03L13.0312 12.59L12.9113 12.57C12.8212 12.57 12.7312 12.57 12.6512 12.57L12.2213 10.62C13.1213 9.94998 13.7013 8.88998 13.7013 7.67998V4.46998C13.6313 3.35998 12.7113 2.47998 11.5813 2.47998H8.37125C7.26125 2.54998 6.38125 3.46998 6.38125 4.59998V7.87998C6.45125 8.99998 7.01125 9.98998 7.86125 10.62L7.43125 12.57C7.30125 12.56 7.17125 12.57 7.04125 12.59L4.85125 13.03L4.70125 13.06C3.43125 13.38 2.53125 14.53 2.53125 15.85V16.99C2.57125 17.27 2.81125 17.49 3.11125 17.49C3.43125 17.49 3.69125 17.23 3.69125 16.91V15.72C3.75125 14.95 4.32125 14.31 5.08125 14.15L7.27125 13.71H7.30125C7.30125 13.71 7.34125 13.71 7.36125 13.73C8.98125 14.81 11.0913 14.81 12.7113 13.73C12.7413 13.71 12.7713 13.71 12.8013 13.71L14.9913 14.15L15.1213 14.18C15.8612 14.39 16.3912 15.07 16.3912 15.85V16.99C16.4312 17.27 16.6712 17.49 16.9712 17.49C17.2912 17.49 17.5512 17.23 17.5512 16.91V15.69L17.5413 15.71ZM12.4713 8.25998C12.2113 9.36998 11.2313 10.2 10.0413 10.2C8.66125 10.2 7.54125 9.07998 7.54125 7.69998V7.56998C7.56125 7.48998 7.60125 7.41998 7.61125 7.40998L9.78125 6.90998C9.85125 6.87998 9.92125 6.86998 9.99125 6.86998C10.0613 6.86998 10.1312 6.87998 10.2013 6.90998C10.2213 6.90998 10.2413 6.91998 10.2513 6.92998L12.3212 7.39998C12.3912 7.42998 12.4713 7.53998 12.4713 7.68998V8.25998ZM7.54125 4.51998C7.59125 4.03998 8.00125 3.65998 8.50125 3.65998H11.5813C12.1112 3.65998 12.5413 4.08998 12.5413 4.61998V6.62998L10.4613 6.14998C10.1513 6.03998 9.81125 6.03998 9.55125 6.14998L7.54125 6.61998V4.52998V4.51998ZM8.51125 13.07L8.93125 11.18C9.28125 11.29 9.66125 11.35 10.0413 11.35C10.4213 11.35 10.8012 11.29 11.1512 11.18L11.5712 13.07C10.6012 13.52 9.48125 13.52 8.51125 13.07Z"
          fill={color}
        />
        <path
          d="M10.8091 8.7601H9.18906C8.96906 8.7601 8.78906 8.9401 8.78906 9.1601C8.78906 9.3801 8.96906 9.5601 9.18906 9.5601H10.8091C11.0291 9.5601 11.2091 9.3801 11.2091 9.1601C11.2091 8.9401 11.0291 8.7601 10.8091 8.7601Z"
          fill={getAlertCategoryColor(AR_CATEGORY_PANDEMIC)}
        />
        <path
          d="M9.18906 8.4001H10.8091C11.0291 8.4001 11.2091 8.2201 11.2091 8.0001C11.2091 7.7801 11.0291 7.6001 10.8091 7.6001H9.18906C8.96906 7.6001 8.78906 7.7801 8.78906 8.0001C8.78906 8.2201 8.96906 8.4001 9.18906 8.4001Z"
          fill={getAlertCategoryColor(AR_CATEGORY_PANDEMIC)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19296">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
