import type { IconChildrenProps } from "../icon.types";

export const Action = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M16.8287 5.8476L11.9539 10.2211L10.2908 8.69379C9.89281 8.33502 9.25114 8.33502 8.8554 8.69172C8.45966 9.04842 8.45966 9.62678 8.8554 9.98348L10.7546 11.7286L11.5213 12.4196C11.7588 12.6336 12.1437 12.6336 12.3812 12.4196L13.1456 11.7306L18.2613 7.13983C18.6576 6.78266 18.6576 6.2043 18.2619 5.8476C17.8661 5.4909 17.2244 5.4909 16.8287 5.8476Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M13.6313 3.6527C13.9 3.80782 13.9921 4.15137 13.837 4.42004C13.6818 4.68872 13.3383 4.78077 13.0696 4.62565C10.1129 2.91857 6.33203 3.93162 4.62494 6.88839C2.91788 9.84511 3.93098 13.6259 6.88772 15.333C9.84445 17.0401 13.6252 16.027 15.3323 13.0703C15.4874 12.8016 15.831 12.7096 16.0996 12.8647C16.3683 13.0198 16.4604 13.3633 16.3052 13.632C14.2879 17.1261 9.82006 18.3233 6.32599 16.306C2.8319 14.2886 1.63469 9.82073 3.65199 6.32666C5.66932 2.83254 10.1372 1.63538 13.6313 3.6527Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
