import type { IconChildrenProps } from "../icon.types";

export const With = ({ color }: IconChildrenProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 3.5C6.29822 3.5 3.5 6.29822 3.5 9.75C3.5 13.2018 6.29822 16 9.75 16C13.2018 16 16 13.2018 16 9.75C16 6.29822 13.2018 3.5 9.75 3.5ZM2 9.75C2 5.46979 5.46979 2 9.75 2C14.0302 2 17.5 5.46979 17.5 9.75C17.5 14.0302 14.0302 17.5 9.75 17.5C5.46979 17.5 2 14.0302 2 9.75Z"
      fill={color}
    />
    <circle cx="9.75" cy="9.75" r="4" fill={color} />
  </>
);
