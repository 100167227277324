import type { IconChildrenProps } from "../icon.types";

export const CaretDown = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M6.76688 7.74166L10.0002 10.975L13.2335 7.74166C13.4434 7.53177 13.7494 7.44979 14.0361 7.52662C14.3228 7.60345 14.5468 7.8274 14.6236 8.11412C14.7004 8.40084 14.6184 8.70677 14.4085 8.91666L10.5835 12.7417C10.4278 12.8977 10.2165 12.9854 9.99605 12.9854C9.77562 12.9854 9.56425 12.8977 9.40855 12.7417L5.58355 8.91666C5.42752 8.76096 5.33984 8.54958 5.33984 8.32916C5.33984 8.10874 5.42752 7.89736 5.58355 7.74166C5.90855 7.42499 6.44188 7.41666 6.76688 7.74166Z"
    fill={color}
    fillRule="evenodd"
  />
);
