import type { IconChildrenProps } from "../icon.types";

export const Unsafe = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M5.21724 4.06981L9.8497 2.52566C9.95233 2.49145 10.0633 2.49145 10.1659 2.52566L14.7984 4.06981C15.8192 4.4101 16.5078 5.36544 16.5078 6.44152V11.44C16.5078 15.0298 13.5977 17.94 9.99861 17.9399L9.76446 17.9355C6.27792 17.807 3.5078 14.9391 3.50781 11.44V6.44152C3.50781 5.36544 4.19639 4.4101 5.21724 4.06981ZM10.0078 3.527L5.53347 5.01849C4.92096 5.22266 4.50781 5.79587 4.50781 6.44152V11.44C4.50781 14.4007 6.85186 16.8275 9.79207 16.9359L10.0078 16.94C13.0454 16.94 15.5078 14.4776 15.5078 11.44V6.44152C15.5078 5.79587 15.0947 5.22266 14.4822 5.01849L10.0078 3.527ZM7.1127 8.31479C6.86668 8.06877 6.84208 7.68515 7.03889 7.41161L7.1127 7.32485C7.38606 7.05149 7.82929 7.05149 8.10264 7.32484L10.0077 9.22983L11.913 7.32485C12.159 7.07882 12.5426 7.05422 12.8162 7.25104L12.9029 7.32485C13.1763 7.5982 13.1763 8.04143 12.9029 8.31479L10.9967 10.2198L12.9029 12.1251C13.1489 12.3711 13.1735 12.7548 12.9767 13.0283L12.9029 13.1151C12.6296 13.3884 12.1863 13.3884 11.913 13.1151L10.0077 11.2088L8.10264 13.1151C7.85662 13.3611 7.473 13.3857 7.19946 13.1889L7.1127 13.1151C6.83934 12.8417 6.83934 12.3985 7.1127 12.1251L9.01768 10.2198L7.1127 8.31479Z"
    fill={color}
    fillRule="evenodd"
  />
);
