import type { IconChildrenProps } from "../../icon.types";

export const HazardsSpillAndLeakage = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M8.11782 11.3836V11.3836C7.29406 10.4481 7.29406 9.04611 8.11782 8.11061L9.37459 6.68336C9.53279 6.5037 9.76062 6.40076 10 6.40076C10.2394 6.40076 10.4672 6.5037 10.6254 6.68336L11.8822 8.11059C12.706 9.04609 12.706 10.4481 11.8822 11.3836V11.3836C11.4061 11.9243 10.7204 12.2341 10 12.2341C9.27958 12.2341 8.59394 11.9243 8.11782 11.3836V11.3836Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32453"
    />
    <path
      d="M12.0827 14.9582H7.91602"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32453"
    />
    <rect
      x="2.5"
      y="3.08325"
      width="15"
      height="15"
      rx="3.53207"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32453"
    />
  </>
);
