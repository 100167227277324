import type { IconChildrenProps } from "../icon.types";

export const AlertCircle = ({ color }: IconChildrenProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 2C5.584 2 2 5.584 2 10C2 14.416 5.584 18 10 18C14.416 18 18 14.416 18 10C18 5.584 14.416 2 10 2ZM10.8327 13.5471C10.8327 14.0073 10.4596 14.3804 9.99935 14.3804C9.53911 14.3804 9.16602 14.0073 9.16602 13.5471V13.1748C9.16602 12.7146 9.53911 12.3415 9.99935 12.3415C10.4596 12.3415 10.8327 12.7146 10.8327 13.1748V13.5471ZM10.8327 9.78623C10.8327 10.2465 10.4596 10.6196 9.99935 10.6196C9.53911 10.6196 9.16602 10.2465 9.16602 9.78623V6.4529C9.16602 5.99266 9.53911 5.61957 9.99935 5.61957C10.4596 5.61957 10.8327 5.99266 10.8327 6.4529V9.78623Z"
    fill={color}
  />
);
