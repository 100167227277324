import type { IconChildrenProps } from "../../icon.types";

export const VehicleRoad = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M17.62 14.312H12.628C13 13.952 13.252 13.472 13.324 12.932C13.384 12.836 13.42 12.716 13.42 12.596V7.58C13.42 7.58 13.42 7.544 13.42 7.532C13.42 7.4 13.408 7.256 13.336 7.136L10.672 2.372C10.54 2.144 10.3 2 10.048 2H6.868C6.472 2 6.148 2.324 6.148 2.72V3.584H3.424C3.028 3.584 2.704 3.908 2.704 4.304V6.848H1.72C1.324 6.848 1 7.172 1 7.568V12.584C1 12.68 1.024 12.764 1.048 12.848C1.18 14.048 2.188 14.984 3.424 14.984C4.492 14.984 5.392 14.276 5.704 13.304H8.704C8.824 13.688 9.028 14.024 9.304 14.3H8.644C8.368 14.348 8.152 14.588 8.152 14.876C8.152 15.2 8.416 15.452 8.728 15.452H17.716C17.992 15.404 18.208 15.164 18.208 14.876C18.208 14.552 17.944 14.3 17.632 14.3L17.62 14.312ZM10 12.596C10 12.068 10.432 11.636 10.96 11.636C11.488 11.636 11.92 12.068 11.92 12.596C11.92 13.124 11.488 13.556 10.96 13.556C10.432 13.556 10 13.124 10 12.596ZM9.592 3.452L11.452 6.776H7.552V3.452H9.592ZM4.12 5.036H5.86V6.776H4.12V5.036ZM11.968 8.3V10.424C11.656 10.28 11.32 10.184 10.948 10.184C9.88 10.184 8.98 10.892 8.668 11.864H5.668C5.356 10.892 4.468 10.184 3.388 10.184C3.04 10.184 2.704 10.268 2.404 10.4V8.288H11.956L11.968 8.3ZM3.4 13.544C2.872 13.544 2.44 13.112 2.44 12.584C2.44 12.056 2.872 11.624 3.4 11.624C3.928 11.624 4.36 12.056 4.36 12.584C4.36 13.112 3.928 13.544 3.4 13.544Z"
      fill={color}
    />
    <path
      d="M1.96 16.976C1.684 17.024 1.468 17.264 1.468 17.552C1.468 17.876 1.732 18.128 2.044 18.128H4.804C5.08 18.08 5.296 17.84 5.296 17.552C5.296 17.228 5.032 16.976 4.72 16.976H1.96Z"
      fill={color}
    />
    <path
      d="M17.26 16.976H6.496C6.22 17.024 6.004 17.264 6.004 17.552C6.004 17.876 6.268 18.128 6.58 18.128H17.344C17.62 18.08 17.836 17.84 17.836 17.552C17.836 17.228 17.572 16.976 17.26 16.976Z"
      fill={color}
    />
  </>
);
