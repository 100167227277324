import type { IconChildrenProps } from "../icon.types";

export const Ergonomics = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M10.0552 0.5C8.77386 0.5 7.73242 1.52739 7.73242 2.79817C7.73242 4.06894 8.77386 5.09633 10.0552 5.09633C11.3365 5.09633 12.3779 4.06894 12.3779 2.79817C12.3779 1.52739 11.3365 0.5 10.0552 0.5ZM10.0552 1.5C10.7872 1.5 11.3779 2.08274 11.3779 2.79817C11.3779 3.51359 10.7872 4.09633 10.0552 4.09633C9.32313 4.09633 8.73242 3.51359 8.73242 2.79817C8.73242 2.08274 9.32313 1.5 10.0552 1.5Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M17.7844 5.56421H2.2156C1.54424 5.56421 1 6.10845 1 6.77981L1.00628 6.90409C1.06853 7.51706 1.5862 7.9954 2.2156 7.9954L7.249 7.99463L7.24945 17.8807C7.24945 18.7253 7.89611 19.4189 8.72133 19.4934L8.89933 19.5L9.04672 19.4934C9.43506 19.4583 9.78386 19.2862 10.0443 19.0257L10.093 18.9726L10.1417 19.0257C10.4347 19.3187 10.8395 19.5 11.2867 19.5L11.4647 19.4934C12.2899 19.4189 12.9365 18.7253 12.9365 17.8807L12.936 7.99463L17.7844 7.9954C18.4558 7.9954 19 7.45116 19 6.77981C19 6.10845 18.4558 5.56421 17.7844 5.56421ZM2.2156 6.56421H17.7844C17.9035 6.56421 18 6.66073 18 6.77981C18 6.89888 17.9035 6.9954 17.7844 6.9954H12.4365C12.1604 6.9954 11.9365 7.21926 11.9365 7.4954V17.8807C11.9365 18.2227 11.6593 18.5 11.3173 18.5H11.2867C10.9447 18.5 10.6674 18.2227 10.6674 17.8807L10.667 11.5132C10.667 11.237 10.4431 11.0132 10.167 11.0132H10.018C9.74184 11.0132 9.51797 11.2371 9.518 11.5133L9.5186 17.8808C9.5186 18.2227 9.24134 18.5 8.89933 18.5H8.86872C8.52671 18.5 8.24945 18.2227 8.24945 17.8807V7.4954C8.24945 7.21926 8.0256 6.9954 7.74945 6.9954H2.2156C2.09653 6.9954 2 6.89888 2 6.77981C2 6.66073 2.09653 6.56421 2.2156 6.56421Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
