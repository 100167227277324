import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDebounce } from "~/hooks/use-debounce";
import { useMediaQuery } from "~/hooks/use-media-query";
import { UiContext } from "./ui.context";
import type { UiContextValue, UiProviderProps } from "./ui.types";
import { isTouchDevice as isTouchDeviceFn } from "./ui.utils";

export const UiProvider = ({ children }: UiProviderProps) => {
  const [_mediaQuery, setMediaQuery] = useState({
    isDesktop: false,
    isMobile: false,
    isTablet: false,
  });
  const [mediaQuery] = useDebounce(_mediaQuery, 250);

  const isDesktop = useMediaQuery({ minWidth: 1025 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ maxWidth: 1024, minWidth: 768 });

  const isTouchDevice = useMemo(() => isTouchDeviceFn(), []);

  const value = useMemo<UiContextValue>(
    () => ({
      isDesktop,
      isMobile,
      isTablet,
      isTouchDevice,
    }),
    [isDesktop, isMobile, isTablet, isTouchDevice],
  );

  useEffect(() => {
    setMediaQuery({ isDesktop, isMobile, isTablet });
  }, [isDesktop, isMobile, isTablet]);

  useLayoutEffect(() => {
    if (Object.values(mediaQuery).some((i) => i)) {
      document.body.classList.toggle("desktop", mediaQuery.isDesktop);
      document.body.classList.toggle("mobile", mediaQuery.isMobile);
      document.body.classList.toggle("tablet", mediaQuery.isTablet);
    }
  }, [mediaQuery]);

  return <UiContext.Provider value={value}>{children}</UiContext.Provider>;
};
