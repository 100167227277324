import {
  type FocusEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

export const useFocus = <T extends HTMLElement>(
  initialFocus = false,
  onFocus?: FocusEventHandler<T>,
  onBlur?: FocusEventHandler<T>,
  disabled?: boolean,
  dynamicFocus?: boolean,
) => {
  const [focus, setFocus] = useState(initialFocus);
  const onInternalFocus = useCallback<FocusEventHandler<T>>(
    (event) => {
      setFocus(true);
      if (onFocus && typeof onFocus === "function") {
        onFocus(event);
      }
    },
    [onFocus],
  );
  const onInternalBlur = useCallback<FocusEventHandler<T>>(
    (event) => {
      setFocus(false);
      if (onBlur && typeof onBlur === "function") {
        onBlur(event);
      }
    },
    [onBlur],
  );

  useEffect(() => {
    if (disabled) {
      setFocus(false);
    }
  }, [disabled]);

  useEffect(() => {
    setFocus(Boolean(dynamicFocus));
  }, [dynamicFocus]);

  return { focus, onInternalBlur, onInternalFocus } as const;
};
