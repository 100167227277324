import { memoize } from "lodash-es";

export const getBrowserLocales = memoize(() => {
  // Get all possible locales from browser and add "en" as fallback
  // filter to remove falsy values
  // filter to remove duplicates
  // map to normalize locale to "en" string format
  return [...navigator.languages, navigator.language, "en"]
    .filter((i) => i)
    .filter((i, index, self) => self.indexOf(i) === index)
    .map((i) => i.toLowerCase().split("-")[0]);
});

export const getStorageLocale = () => {
  const locale = window.localStorage.getItem("locale_current");
  return typeof locale === "string" ? locale : "";
};

export const setStorageLocale = (locale: string) => {
  window.localStorage.setItem("locale_current", locale);
};
