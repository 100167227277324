import type { IconChildrenProps } from "../../icon.types";

export const CategoryEmergencyAlerts = ({
  accentColor,
  color,
}: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM10 3.11628C13.8018 3.11628 16.8837 6.19823 16.8837 10C16.8837 13.8018 13.8018 16.8837 10 16.8837C6.19823 16.8837 3.11628 13.8018 3.11628 10C3.11628 6.19823 6.19823 3.11628 10 3.11628Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11.8213 6.34509C12.2166 6.21345 12.651 6.27883 12.9901 6.521C13.2952 6.73897 13.49 7.07646 13.5285 7.44908L13.5355 7.57447V12.0163C13.5277 12.7301 12.9467 13.3046 12.2349 13.3046C12.1283 13.305 12.0222 13.2921 11.9213 13.2669L11.8223 13.2377L11.0754 12.9906C10.8424 13.4804 10.4084 13.8607 9.86694 14.0175C9.09918 14.2397 8.27382 13.9666 7.79016 13.3302C7.44939 12.8818 7.32782 12.3182 7.4326 11.7864L6.61292 11.5148C6.11605 11.3526 5.76877 10.9097 5.72372 10.3951L5.71875 10.2838V9.29758C5.71985 8.77492 6.03402 8.30796 6.50713 8.10715L6.61088 8.06826L11.8213 6.34509ZM8.67892 12.6548C8.88331 12.9237 9.2321 13.0392 9.5565 12.9453C9.73783 12.8928 9.89065 12.7809 9.99526 12.6337L8.51259 12.1435C8.50886 12.3239 8.5648 12.5046 8.67892 12.6548ZM12.2167 7.39546L12.1728 7.40455L6.95947 9.12872C6.90022 9.14808 6.85623 9.19616 6.84092 9.25392L6.83503 9.29875V10.2826C6.83516 10.3449 6.8673 10.4016 6.91907 10.4344L6.96139 10.4543L12.1781 12.1797C12.1952 12.1855 12.213 12.1884 12.2329 12.1884C12.318 12.1884 12.3899 12.1313 12.4122 12.0567L12.4193 12.0102V7.57936C12.4188 7.51975 12.3898 7.46401 12.3412 7.42936C12.3049 7.4034 12.2608 7.39162 12.2167 7.39546Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
