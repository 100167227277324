import type { IconChildrenProps } from "../../icon.types";

export const HazardsWorkerMachineInteraction = ({
  color,
}: IconChildrenProps) => (
  <>
    <path
      d="M11.9705 10.8543C10.888 10.4218 9.61797 10.1835 8.33464 10.1835C4.9613 10.1835 1.66797 11.8226 1.66797 14.3435V15.1768C1.66797 15.6368 2.0413 16.0101 2.5013 16.0101H10.3455"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32507"
    />
    <path
      d="M14.1667 18.5245C11.8658 18.5245 10 16.6595 10 14.3578C10 12.1045 11.9167 10.1887 14.17 10.1912C16.47 10.1928 18.3333 12.0578 18.3333 14.3578C18.3333 16.6587 16.4683 18.5245 14.1667 18.5245"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32507"
    />
    <path
      d="M14.1686 12.691V13.2493"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32507"
    />
    <path
      d="M14.1686 15.4659V16.0242"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32507"
    />
    <path
      d="M12.7277 13.5244L13.2111 13.7994"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32507"
    />
    <path
      d="M15.1342 14.9161L15.6092 15.1911"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32507"
    />
    <path
      d="M12.7277 15.1911L13.2111 14.9161"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32507"
    />
    <path
      d="M15.1342 13.7994L15.6092 13.5244"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32507"
    />
    <path
      d="M14.9544 13.5716C15.3885 14.0057 15.3885 14.7096 14.9544 15.1437C14.5202 15.5778 13.8164 15.5778 13.3822 15.1437C12.9481 14.7096 12.9481 14.0057 13.3822 13.5716C13.8164 13.1374 14.5202 13.1374 14.9544 13.5716"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32507"
    />
    <path
      d="M11.7579 5.73547C12.0791 5.73547 12.3395 5.99589 12.3395 6.31714C12.3395 6.63839 12.0791 6.89881 11.7579 6.89881H11.4864C11.031 6.89902 10.5857 7.03393 10.2068 7.28658C9.82779 7.53924 9.38256 7.67415 8.9271 7.67436H7.99643C7.54096 7.67415 7.09573 7.53924 6.71676 7.28658C6.33779 7.03393 5.89256 6.89902 5.4371 6.89881H5.16565C4.84441 6.89881 4.58398 6.63839 4.58398 6.31714C4.58398 5.99589 4.84441 5.73547 5.16565 5.73547"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32507"
    />
    <path
      d="M11.37 5.73557V3.97294C11.37 2.80477 10.2733 1.85779 8.9206 1.85779H8.8112H7.49219"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32507"
    />
    <path
      d="M7.49358 1.85779H7.41068C6.38564 1.85779 5.55469 2.80477 5.55469 3.97294V5.73557"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32507"
    />
  </>
);
