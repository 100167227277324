import { Navigate, useParams } from "react-router-dom";
import { Spinner } from "~/components/spinner";
import { InvalidInvitation } from "../../components/invalid-invitation/invalid-invitation";
import { SignUpForm } from "../../components/sign-up-form/sign-up-form";
import { useGuardContext } from "../guard-route/guard-route.hooks";
import { useInvitation } from "./sign-up-route.hooks";
import type { SignUpRouteParams } from "./sign-up-route.types";

export const SignUpRoute = () => {
  const { id } = useParams<SignUpRouteParams>();
  const { appName, appUrl, redirect } = useGuardContext();

  const { invitationData, invitationDataError, invitationDataLoading } =
    useInvitation(id);

  if (invitationDataLoading === undefined || invitationDataLoading) {
    return <Spinner color="secondary" cover size="large" />;
  }

  if (invitationDataError) {
    return <InvalidInvitation />;
  }

  if (id && invitationData) {
    return (
      <SignUpForm
        appName={appName}
        appUrl={appUrl}
        email={invitationData.email}
        invitationId={id}
        redirect={redirect}
        sso={invitationData.sso}
      />
    );
  }

  return <Navigate replace to="/login" />;
};
