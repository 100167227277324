/* eslint-disable sort-keys */

export type ThemeButtonColors = typeof buttonColors;
export type ThemeButtonColor = keyof ThemeButtonColors;
export type ThemeButtonShape = ExtractButtonShape<ThemeButtonColor>;
export type ThemeButtonVariant = ExtractButtonVariant<ThemeButtonColor>;
export type ThemeButtonState = ExtractButtonState<ThemeButtonColor>;
export type ThemeButtonProperty = ExtractButtonProperty<ThemeButtonColor>;

type ExtractButtonShape<T extends `button-${string}-${string}`> =
  T extends `button-${infer Prefix}-${string}` ? Prefix : never;

type ExtractButtonVariant<
  T extends `button-${ThemeButtonShape}-${string}-${string}`,
> = T extends `button-${ThemeButtonShape}-${infer Prefix}-${string}`
  ? Prefix
  : never;

type ExtractButtonState<
  T extends
    `button-${ThemeButtonShape}-${ThemeButtonVariant}-${string}-${string}`,
> =
  T extends `button-${ThemeButtonShape}-${ThemeButtonVariant}-${infer Prefix}-${string}`
    ? Prefix
    : never;

type ExtractButtonProperty<
  T extends
    `button-${ThemeButtonShape}-${ThemeButtonVariant}-${ThemeButtonState}-${string}`,
> =
  T extends `button-${ThemeButtonShape}-${ThemeButtonVariant}-${ThemeButtonState}-${infer Prefix}`
    ? Prefix
    : never;

export const buttonColors = {
  "button-contained-contrast-idle-bg": "dark-20",
  "button-contained-contrast-idle-fg": "beige-20",
  "button-contained-contrast-idle-border": "transparent",
  "button-contained-contrast-hover-bg": "darker-50",
  "button-contained-contrast-active-bg": "darker-70",
  "button-contained-contrast-disabled-bg": "midgrey-50",
  "button-contained-contrast-disabled-fg": "dark-90",
  "button-contained-contrast-disabled-border": "transparent",

  "button-ghost-contrast-idle-bg": "transparent",
  "button-ghost-contrast-idle-fg": "dark-50",
  "button-ghost-contrast-idle-border": "transparent",
  "button-ghost-contrast-hover-fg": "dark-20",
  "button-ghost-contrast-hover-border": "dark-90",
  "button-ghost-contrast-active-fg": "dark-20",
  "button-ghost-contrast-active-border": "dark-20",
  "button-ghost-contrast-disabled-fg": "dark-70",
  "button-ghost-contrast-disabled-border": "transparent",

  "button-outlined-contrast-idle-bg": "transparent",
  "button-outlined-contrast-idle-fg": "dark-20",
  "button-outlined-contrast-idle-border": "dark-20",
  "button-outlined-contrast-hover-bg": "beige-20",
  "button-outlined-contrast-active-bg": "dark-70",
  "button-outlined-contrast-disabled-fg": "dark-80",
  "button-outlined-contrast-disabled-border": "dark-80",

  "button-contained-error-idle-bg": "red-20",
  "button-contained-error-idle-fg": "beige-20",
  "button-contained-error-idle-border": "transparent",
  "button-contained-error-hover-bg": "red-40",
  "button-contained-error-active-bg": "red-10",
  "button-contained-error-disabled-bg": "red-80",
  "button-contained-error-disabled-fg": "beige-20",
  "button-contained-error-disabled-border": "transparent",

  "button-ghost-error-idle-bg": "transparent",
  "button-ghost-error-idle-fg": "dark-50",
  "button-ghost-error-idle-border": "transparent",
  "button-ghost-error-hover-fg": "red-20",
  "button-ghost-error-active-fg": "red-20",
  "button-ghost-error-active-border": "red-20",
  "button-ghost-error-disabled-fg": "dark-70",
  "button-ghost-error-disabled-border": "transparent",

  "button-outlined-error-idle-bg": "transparent",
  "button-outlined-error-idle-fg": "red-20",
  "button-outlined-error-idle-border": "red-20",
  "button-outlined-error-hover-bg": "red-90",
  "button-outlined-error-active-bg": "red-80",
  "button-outlined-error-disabled-fg": "red-70",
  "button-outlined-error-disabled-border": "red-70",

  "button-contained-success-idle-bg": "success-20",
  "button-contained-success-idle-fg": "beige-20",
  "button-contained-success-idle-border": "transparent",
  "button-contained-success-hover-bg": "success-40",
  "button-contained-success-active-bg": "success-10",
  "button-contained-success-disabled-bg": "success-80",
  "button-contained-success-disabled-fg": "beige-20",
  "button-contained-success-disabled-border": "transparent",

  "button-ghost-success-idle-bg": "transparent",
  "button-ghost-success-idle-fg": "dark-50",
  "button-ghost-success-idle-border": "transparent",
  "button-ghost-success-hover-fg": "success-20",
  "button-ghost-success-active-fg": "success-20",
  "button-ghost-success-active-border": "success-20",
  "button-ghost-success-disabled-fg": "dark-70",
  "button-ghost-success-disabled-border": "transparent",

  "button-outlined-success-idle-bg": "transparent",
  "button-outlined-success-idle-fg": "success-20",
  "button-outlined-success-idle-border": "success-20",
  "button-outlined-success-hover-bg": "success-90",
  "button-outlined-success-active-bg": "success-80",
  "button-outlined-success-disabled-fg": "success-70",
  "button-outlined-success-disabled-border": "success-70",

  "button-contained-warning-idle-bg": "honey-20",
  "button-contained-warning-idle-fg": "beige-20",
  "button-contained-warning-idle-border": "transparent",
  "button-contained-warning-hover-bg": "honey-40",
  "button-contained-warning-active-bg": "honey-10",
  "button-contained-warning-disabled-bg": "honey-80",
  "button-contained-warning-disabled-fg": "beige-20",
  "button-contained-warning-disabled-border": "transparent",

  "button-ghost-warning-idle-bg": "transparent",
  "button-ghost-warning-idle-fg": "dark-50",
  "button-ghost-warning-idle-border": "transparent",
  "button-ghost-warning-hover-fg": "honey-20",
  "button-ghost-warning-active-fg": "honey-20",
  "button-ghost-warning-active-border": "honey-20",
  "button-ghost-warning-disabled-fg": "dark-70",
  "button-ghost-warning-disabled-border": "transparent",

  "button-outlined-warning-idle-bg": "transparent",
  "button-outlined-warning-idle-fg": "honey-20",
  "button-outlined-warning-idle-border": "honey-20",
  "button-outlined-warning-hover-bg": "honey-90",
  "button-outlined-warning-active-bg": "honey-80",
  "button-outlined-warning-disabled-fg": "honey-70",
  "button-outlined-warning-disabled-border": "honey-70",

  "button-contained-info-idle-bg": "navy-20",
  "button-contained-info-idle-fg": "beige-20",
  "button-contained-info-idle-border": "transparent",
  "button-contained-info-hover-bg": "navy-40",
  "button-contained-info-active-bg": "navy-10",
  "button-contained-info-disabled-bg": "navy-80",
  "button-contained-info-disabled-fg": "beige-20",
  "button-contained-info-disabled-border": "transparent",

  "button-ghost-info-idle-bg": "transparent",
  "button-ghost-info-idle-fg": "dark-50",
  "button-ghost-info-idle-border": "transparent",
  "button-ghost-info-hover-fg": "navy-20",
  "button-ghost-info-active-fg": "navy-20",
  "button-ghost-info-active-border": "navy-20",
  "button-ghost-info-disabled-fg": "dark-70",
  "button-ghost-info-disabled-border": "transparent",

  "button-outlined-info-idle-bg": "transparent",
  "button-outlined-info-idle-fg": "navy-20",
  "button-outlined-info-idle-border": "navy-20",
  "button-outlined-info-hover-bg": "navy-90",
  "button-outlined-info-active-bg": "navy-80",
  "button-outlined-info-disabled-fg": "navy-70",
  "button-outlined-info-disabled-border": "navy-70",

  "button-contained-inverted-idle-bg": "beige-20",
  "button-contained-inverted-idle-fg": "dark-20",
  "button-contained-inverted-idle-border": "transparent",
  "button-contained-inverted-hover-bg": "beige-30",
  "button-contained-inverted-active-bg": "beige-10",
  "button-contained-inverted-disabled-bg": "beige-40",
  "button-contained-inverted-disabled-fg": "beige-00",
  "button-contained-inverted-disabled-border": "transparent",

  "button-ghost-inverted-idle-bg": "transparent",
  "button-ghost-inverted-idle-fg": "dark-50",
  "button-ghost-inverted-idle-border": "transparent",
  "button-ghost-inverted-hover-fg": "beige-20",
  "button-ghost-inverted-active-fg": "beige-20",
  "button-ghost-inverted-active-border": "beige-20",
  "button-ghost-inverted-disabled-fg": "dark-70",
  "button-ghost-inverted-disabled-border": "transparent",

  "button-outlined-inverted-idle-bg": "transparent",
  "button-outlined-inverted-idle-fg": "beige-20",
  "button-outlined-inverted-idle-border": "beige-20",
  "button-outlined-inverted-hover-bg": "dark-20",
  "button-outlined-inverted-active-bg": "dark-40",
  "button-outlined-inverted-disabled-border": "beige-00",
  "button-outlined-inverted-disabled-fg": "beige-00",
};

export const buttonSecondaryColors = {
  "button-secondary-idle-bg": "white-00",
  "button-secondary-idle-fg": "dark-50",
  "button-secondary-idle-icon": "dark-50",
  "button-secondary-idle-border": "dark-90",
  "button-secondary-hover-bg": "beige-40",
  "button-secondary-hover-icon": "dark-20",
  "button-secondary-hover-border": "dark-20",
  "button-secondary-active-bg": "beige-20",
  "button-secondary-active-icon": "dark-20",
  "button-secondary-active-border": "dark-20",
  "button-secondary-disabled-bg": "white-00",
  "button-secondary-disabled-fg": "dark-90",
  "button-secondary-disabled-icon": "dark-90",
  "button-secondary-disabled-border": "midgrey-90",
};

export const buttonTextColors = {
  "button-text-contrast-idle-fg": "dark-50",
  "button-text-contrast-hover-fg": "darker-50",
  "button-text-contrast-active-fg": "darker-70",
  "button-text-contrast-disabled-fg": "midgrey-50",

  "button-text-error-idle-fg": "dark-50",
  "button-text-error-hover-fg": "red-20",
  "button-text-error-active-fg": "darker-70",
  "button-text-error-disabled-fg": "midgrey-50",

  "button-text-info-idle-fg": "navy-20",
  "button-text-info-hover-fg": "navy-40",
  "button-text-info-active-fg": "navy-10",
  "button-text-info-disabled-fg": "navy-80",
};
