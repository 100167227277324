import type { IconChildrenProps } from "../../icon.types";

export const ErgonomicsShoulder = ({
  accentColor,
  color,
}: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M8.33175 2.70956C7.75607 2.70956 7.28939 3.17625 7.28939 3.75193V7.08665C7.28939 8.58319 8.50258 9.79637 9.99911 9.79637C11.4957 9.79637 12.7088 8.58319 12.7088 7.08665V3.75193C12.7088 3.17625 12.2422 2.70956 11.6665 2.70956H8.33175ZM6.03939 3.75193C6.03939 2.48589 7.06572 1.45956 8.33175 1.45956H11.6665C12.9325 1.45956 13.9588 2.48589 13.9588 3.75193V7.08665C13.9588 9.27354 12.186 11.0464 9.99911 11.0464C7.81222 11.0464 6.03939 9.27354 6.03939 7.08665V3.75193Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11.616 9.81096C11.9532 9.73678 12.2866 9.94993 12.3608 10.287L12.9802 13.102C13.0544 13.4392 12.8412 13.7726 12.5041 13.8468C12.167 13.9209 11.8336 13.7078 11.7594 13.3707L11.14 10.5557C11.0658 10.2186 11.2789 9.88514 11.616 9.81096Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.38301 9.81096C8.72012 9.88514 8.93327 10.2186 8.85909 10.5557L8.23966 13.3707C8.16548 13.7078 7.83207 13.9209 7.49495 13.8468C7.15784 13.7726 6.94469 13.4392 7.01887 13.102L7.63829 10.287C7.71247 9.94993 8.04589 9.73678 8.38301 9.81096Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12.9588 13.5964C12.94 13.6125 12.9202 13.6275 12.8996 13.6412C11.1544 14.8053 8.88362 14.8126 7.13185 13.6631C7.11962 13.657 7.10353 13.6491 7.08385 13.6396C7.03079 13.614 6.95181 13.5768 6.85191 13.5324C6.65151 13.4433 6.37009 13.3267 6.0471 13.2167C5.3766 12.9884 4.62301 12.8228 4.04762 12.9065C3.82135 12.9394 3.67772 13.027 3.57072 13.1415C3.4541 13.2663 3.35501 13.4523 3.28036 13.7101C3.12635 14.2421 3.1211 14.9046 3.1211 15.5198V16.6738C3.1211 17.019 2.84128 17.2988 2.4961 17.2988C2.15092 17.2988 1.8711 17.019 1.8711 16.6738V15.5198C1.8711 15.5103 1.8711 15.5008 1.8711 15.4912C1.87104 14.915 1.87095 14.0834 2.07967 13.3625C2.18813 12.9879 2.3633 12.6028 2.65743 12.288C2.96121 11.9629 3.36485 11.7426 3.86775 11.6695C4.75069 11.5411 5.74109 11.7921 6.45 12.0335C6.81671 12.1583 7.13384 12.2898 7.35957 12.3901C7.47274 12.4404 7.56371 12.4832 7.62735 12.514C7.65918 12.5293 7.68423 12.5417 7.70185 12.5505L7.72264 12.561L7.72872 12.5641L7.73162 12.5655C7.73162 12.5655 7.73184 12.5657 7.44566 13.1213L7.73162 12.5655C7.75248 12.5763 7.77294 12.5883 7.79247 12.6013C9.12019 13.4869 10.8477 13.4925 12.1805 12.6182C12.1937 12.6075 12.2104 12.5942 12.2306 12.5785C12.2791 12.5409 12.3477 12.4897 12.4341 12.4302C12.6064 12.3118 12.8533 12.1581 13.1569 12.0135C13.7566 11.728 14.6261 11.4555 15.5947 11.6175C16.6492 11.7938 17.2951 12.4974 17.6581 13.2613C18.0142 14.0104 18.1273 14.8649 18.1273 15.5198V16.6738C18.1273 17.019 17.8475 17.2988 17.5023 17.2988C17.1572 17.2988 16.8773 17.019 16.8773 16.6738V15.5198C16.8773 14.9824 16.7807 14.3271 16.5292 13.7978C16.2846 13.2833 15.9251 12.9401 15.3886 12.8504C14.7661 12.7463 14.1662 12.9175 13.6942 13.1422C13.462 13.2527 13.2725 13.3707 13.1424 13.4602C13.0776 13.5048 13.0285 13.5416 12.997 13.566C12.9814 13.5781 12.9702 13.5872 12.9638 13.5924L12.9581 13.5971L12.9588 13.5964Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M16.666 11.0417C16.3208 11.0417 16.041 10.7619 16.041 10.4167V6.66669C16.041 6.32151 16.3208 6.04169 16.666 6.04169C17.0112 6.04169 17.291 6.32151 17.291 6.66669V10.4167C17.291 10.7619 17.0112 11.0417 16.666 11.0417Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M18.7746 8.7753C18.5305 9.01937 18.1348 9.01937 17.8907 8.7753L16.666 7.55057L15.4413 8.7753C15.1972 9.01937 14.8015 9.01937 14.5574 8.7753C14.3133 8.53122 14.3133 8.13549 14.5574 7.89141L16.2241 6.22475C16.4682 5.98067 16.8639 5.98067 17.108 6.22475L18.7746 7.89141C19.0187 8.13549 19.0187 8.53122 18.7746 8.7753Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M3.33268 11.0417C2.9875 11.0417 2.70768 10.7619 2.70768 10.4167V6.66669C2.70768 6.32151 2.9875 6.04169 3.33268 6.04169C3.67786 6.04169 3.95768 6.32151 3.95768 6.66669V10.4167C3.95768 10.7619 3.67786 11.0417 3.33268 11.0417Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M5.44129 8.7753C5.19721 9.01937 4.80149 9.01937 4.55741 8.7753L3.33268 7.55057L2.10796 8.7753C1.86388 9.01937 1.46815 9.01937 1.22407 8.7753C0.979996 8.53122 0.979996 8.13549 1.22407 7.89141L2.89074 6.22475C3.13482 5.98067 3.53055 5.98067 3.77462 6.22475L5.44129 7.89141C5.68537 8.13549 5.68537 8.53122 5.44129 8.7753Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
