import type { IconChildrenProps } from "../icon.types";

export const Mail = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M18 6.7C18 5.4851 16.9765 4.5 15.7143 4.5H4.28571C3.02348 4.5 2 5.4851 2 6.7V13.3C2 14.5149 3.02348 15.5 4.28571 15.5H15.7143C16.9765 15.5 18 14.5149 18 13.3V6.7ZM16.8571 6.7V13.3C16.8571 13.9077 16.3457 14.4 15.7143 14.4H4.28571C3.65433 14.4 3.14286 13.9077 3.14286 13.3V6.7C3.14286 6.0923 3.65433 5.6 4.28571 5.6H15.7143C16.3457 5.6 16.8571 6.0923 16.8571 6.7ZM4.03037 7.1917L9.74465 9.9417C9.90524 10.0193 10.0949 10.0193 10.2555 9.9417L15.9698 7.1917C16.2521 7.05641 16.3664 6.7253 16.2253 6.4541C16.0847 6.18242 15.7407 6.0724 15.4589 6.2082L10 8.83494L4.54107 6.2082C4.25931 6.0723 3.9153 6.1823 3.77473 6.4541C3.63353 6.7253 3.74782 7.0564 4.03022 7.1917H4.03037Z"
    fill={color}
    fillRule="evenodd"
  />
);
