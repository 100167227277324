import {
  type ChangeEventHandler,
  type FormEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  PasswordErrors,
  validateName,
  validatePassword,
  validatePasswordByRule,
} from "~/utils/form-validator";
import type { SignUpRouteState } from "../../routes/sign-up-route/sign-up-route.types";
import type { SignUpFormProps, SignUpRequest } from "./sign-up-form.types";
import { signUp } from "./sign-up-form.utils";

export const useSignUpForm = ({
  appName,
  appUrl,
  email,
  invitationId,
  redirect,
  sso,
}: SignUpFormProps) => {
  const navigate = useNavigate();

  const [consent, setConsent] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [signUpError, setSignUpError] = useState<string>();

  const userName = useMemo(() => email.slice(0, email.indexOf("@")), [email]);

  const errors = useMemo(
    () => ({
      consent: !consent,
      firstName: !sso && !validateName(firstName),
      jobTitle: !jobTitle,
      lastName: !sso && !validateName(lastName),
      password: !sso && !validatePassword(password),
      [PasswordErrors.Length]:
        !sso && !validatePasswordByRule(PasswordErrors.Length, password),
      [PasswordErrors.LetterAndDigit]:
        !sso &&
        !validatePasswordByRule(PasswordErrors.LetterAndDigit, password),
      [PasswordErrors.SpecialCharacter]:
        !sso &&
        !validatePasswordByRule(PasswordErrors.SpecialCharacter, password),
      [PasswordErrors.Whitespace]:
        !sso && !validatePasswordByRule(PasswordErrors.Whitespace, password),
      [PasswordErrors.PersonalInformation]:
        !sso &&
        validatePasswordByRule(
          PasswordErrors.PersonalInformation,
          password,
          userName,
          firstName.trim(),
          lastName.trim(),
        ),
    }),
    [consent, firstName, jobTitle, lastName, password, sso, userName],
  );

  const disabled = useMemo(
    () => Object.values(errors).some((i) => i),
    [errors],
  );

  const doSignUp = useCallback(
    async (req: SignUpRequest) => {
      setLoading(true);
      setSignUpError(undefined);
      const [error, response] = await signUp(req);
      if (error) {
        setSignUpError(error);
      } else if (response) {
        const state: SignUpRouteState = {
          appName,
          appUrl,
          fromSignUp: true,
          redirect,
        };
        navigate("/login", {
          replace: true,
          state,
        });
      } else {
        setSignUpError(undefined);
      }
      setLoading(false);
    },
    [appName, appUrl, navigate, redirect],
  );

  const onConsentChange = useCallback(
    () => setConsent((prevConsent) => !prevConsent),
    [],
  );

  const onFirstNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => setFirstName(event.target.value),
    [],
  );

  const onJobTitleChange = useCallback(
    (value: string) => setJobTitle(value),
    [],
  );

  const onLastNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => setLastName(event.target.value),
    [],
  );

  const onPasswordChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => setPassword(event.target.value),
    [],
  );

  const onPressSubmit = useCallback(() => {
    if (!disabled) {
      let req: SignUpRequest = {
        consent,
        invitationId,
        jobTitle,
      };

      if (!sso) {
        req = { ...req, firstName, lastName, password };
      }

      void doSignUp(req);
    }
  }, [
    consent,
    disabled,
    doSignUp,
    firstName,
    invitationId,
    jobTitle,
    lastName,
    password,
    sso,
  ]);

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();

      onPressSubmit();
    },
    [onPressSubmit],
  );

  return {
    consent,
    disabled,
    email,
    errors,
    firstName,
    jobTitle,
    lastName,
    loading,
    onConsentChange,
    onFirstNameChange,
    onJobTitleChange,
    onLastNameChange,
    onPasswordChange,
    onPressSubmit,
    onSubmit,
    password,
    signUpError,
    sso,
  } as const;
};
