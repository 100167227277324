import { memo } from "react";
import { Helmet } from "react-helmet-async";
import { getThemePalette, getThemeStyle } from "./theme.utils";

const themeStyle = getThemeStyle();
const themePalette = getThemePalette();

const ThemeStyleComponent = () => (
  <Helmet>
    <style>{themeStyle}</style>
    <style>{themePalette}</style>
  </Helmet>
);

export const ThemeStyle = memo(ThemeStyleComponent);
