import { type AuthOptions, WebAuth } from "auth0-js";
import qs from "query-string";
import { getAppEnv } from "~/utils/app-env";
import { AuthTenant } from "./auth.types";

type Auth0TenantOptions = "clientID" | "domain";

const getCommonOptions = (
  app: string,
): Omit<AuthOptions, Auth0TenantOptions> => {
  const appUrl = getAppEnv(
    import.meta.env.VITE_APP_NAME === "auth" ? "auth" : app,
    "VITE_APP_URL",
  );

  if (!appUrl) {
    throw new Error("auth.auth0: Missing VITE_APP_URL");
  }

  return {
    audience: getAppEnv(app, "VITE_AUTH0_AUDIENCE"),
    redirectUri: `${appUrl}/callback`,
    responseType: getAppEnv(app, "VITE_AUTH0_RESPONSE_TYPE"),
    scope: getAppEnv(app, "VITE_AUTH0_SCOPE"),
  };
};

const getTenantOptions = (
  app: string,
  tenant?: AuthTenant,
): Pick<AuthOptions, Auth0TenantOptions> => {
  switch (tenant) {
    // getAppEnv calls below might return undefined, that's why we're adding `?? ""` at the end.
    //   In case it falls through to an empty string, new WebAuth() call below will throw an error.
    case AuthTenant.eu:
      return {
        clientID: getAppEnv(app, "VITE_AUTH0_CLIENT_ID_EU") ?? "",
        domain: getAppEnv(app, "VITE_AUTH0_DOMAIN_EU") ?? "",
      };
    case AuthTenant.us:
      return {
        clientID: getAppEnv(app, "VITE_AUTH0_CLIENT_ID_US") ?? "",
        domain: getAppEnv(app, "VITE_AUTH0_DOMAIN_US") ?? "",
      };
    default:
      return {
        clientID: getAppEnv(app, "VITE_AUTH0_CLIENT_ID") ?? "",
        domain: getAppEnv(app, "VITE_AUTH0_DOMAIN") ?? "",
      };
  }
};

export const getAuth0 = (tenant?: AuthTenant, app?: string) => {
  const appName = app ?? import.meta.env.VITE_APP_NAME;

  if (!appName) {
    throw new Error("auth.auth0: Missing appName");
  }

  return new WebAuth({
    ...getCommonOptions(appName),
    ...getTenantOptions(appName, tenant),
  });
};

export const getAuth0CallbackState = () => {
  const parsedHash = qs.parse(window.location.hash);

  if (
    "state" in parsedHash &&
    typeof parsedHash.state === "string" &&
    parsedHash.state
  ) {
    return parsedHash.state;
  }
};
