import type { IconChildrenProps } from "../icon.types";

export const SolveHow = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M9.50586 5.65421C9.50586 3.63604 11.1419 2 13.1601 2C15.1782 2 16.8143 3.63604 16.8143 5.65421C16.8143 6.48505 16.537 7.25113 16.0699 7.86496L17.4608 9.25584C17.6536 9.44862 17.6536 9.76117 17.4608 9.95395C17.2809 10.1339 16.9966 10.1459 16.8028 9.98993L16.7627 9.95395L15.3719 8.5632C14.7579 9.03079 13.9914 9.30841 13.1601 9.30841C11.1419 9.30841 9.50586 7.67237 9.50586 5.65421ZM15.827 5.65421C15.827 4.1813 14.633 2.98727 13.1601 2.98727C11.6872 2.98727 10.4931 4.1813 10.4931 5.65421C10.4931 7.12711 11.6872 8.32114 13.1601 8.32114C14.633 8.32114 15.827 7.12711 15.827 5.65421Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M6.8389 3.58029C7.11153 3.58029 7.33253 3.8013 7.33253 4.07393C7.33253 4.32951 7.13829 4.53973 6.88937 4.56501L6.8389 4.56756H5.2586C4.25087 4.56756 3.42863 5.3618 3.38373 6.35843L3.3818 6.44434V9.60494C3.3818 9.87756 3.16079 10.0986 2.88817 10.0986C2.63258 10.0986 2.42236 9.90432 2.39708 9.65541L2.39453 9.60494V6.44434C2.39453 4.89422 3.62605 3.6317 5.16406 3.58182L5.2586 3.58029H6.8389ZM17.1126 12.2719C17.3682 12.2719 17.5784 12.4661 17.6037 12.715L17.6063 12.7655V15.1359C17.6063 16.6861 16.3748 17.9486 14.8368 17.9985L14.7422 18H13.1619C12.8893 18 12.6683 17.779 12.6683 17.5064C12.6683 17.2508 12.8625 17.0405 13.1114 17.0153L13.1619 17.0127H14.7422C15.75 17.0127 16.5722 16.2185 16.6171 15.2218L16.619 15.1359V12.7655C16.619 12.4929 16.84 12.2719 17.1126 12.2719ZM5.26069 18L5.25541 18H2.88817C2.61554 18 2.39453 17.779 2.39453 17.5064V14.5433C2.39453 14.2707 2.61554 14.0497 2.88817 14.0497H4.76706V11.1852C4.76706 10.9126 4.98807 10.6916 5.26069 10.6916H7.63112C7.90375 10.6916 8.12476 10.9126 8.12476 11.1852V12.4694H10.0006C10.2732 12.4694 10.4942 12.6904 10.4942 12.963V17.5064C10.4942 17.779 10.2732 18 10.0006 18H7.6374C7.63531 18 7.63322 18 7.63112 18H5.26069ZM7.13737 12.9336C7.13679 12.9433 7.1365 12.9531 7.1365 12.963V17.0124H5.75401V11.6788H7.13737V12.9336ZM8.12476 17.0124H9.50681V13.4566H8.12476V17.0124ZM4.76476 15.0367V17.0124H3.3818V15.0367H4.76476Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
