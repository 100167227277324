import type { IconChildrenProps } from "../icon.types";

export const Facilities = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M10.6941 7.24602L16.6941 2.60453C17.0227 2.35032 17.5 2.58454 17.5 3.00001V18C17.5 18.2761 17.2761 18.5 17 18.5H11C10.7239 18.5 10.5 18.2761 10.5 18V7.6415C10.5 7.48674 10.5717 7.34071 10.6941 7.24602ZM16.5 17.5V4.01801L11.5 7.88501V17.5H16.5ZM3 11.5H9C9.27614 11.5 9.5 11.7239 9.5 12V18C9.5 18.2761 9.27614 18.5 9 18.5H3C2.72386 18.5 2.5 18.2761 2.5 18V12C2.5 11.7239 2.72386 11.5 3 11.5ZM8.5 17.5V12.5H3.5V17.5H8.5Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9 7.5H3C2.72386 7.5 2.5 7.72386 2.5 8V10C2.5 10.2761 2.72386 10.5 3 10.5H9C9.27614 10.5 9.5 10.2761 9.5 10V8C9.5 7.72386 9.27614 7.5 9 7.5ZM8.5 8.5V9.5H3.5V8.5H8.5Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
