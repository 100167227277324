import { AR_CATEGORY_PPE } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseSleeve = ({ color, oppositeColor }: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19305)">
        <path
          d="M17.5409 13.4451C17.5361 13.4363 17.5311 13.4275 17.526 13.4188L17.5209 13.41H17.5409L13.8609 7.18001C13.4509 6.77001 12.9009 6.54001 12.3209 6.54001H10.8009C10.8009 6.54001 10.4609 6.74001 9.97091 6.74001C9.48091 6.74001 9.14091 6.54001 9.14091 6.54001H7.62091C7.04091 6.54001 6.49091 6.77001 6.08091 7.18001L2.47091 13.46C2.08091 14.14 2.31091 15.02 2.99091 15.41C3.67091 15.8 4.55091 15.57 4.94091 14.89L7.67091 9.43001L7.12091 13.56L7.05091 14.05L6.60507 16.5594C6.56426 16.7892 6.74094 17 6.97429 17C7.1561 17 7.31171 16.8696 7.34351 16.6906L7.78934 14.1812C7.79083 14.1728 7.79217 14.1645 7.79337 14.1561L7.8634 13.6661L8.41434 9.52901C8.46272 9.16573 8.24147 8.82068 7.89113 8.71307C7.54079 8.60545 7.16399 8.7668 7.00009 9.0946L4.28149 14.5318C4.09695 14.8344 3.68638 14.9443 3.36404 14.7594C3.05029 14.5795 2.93381 14.1607 3.1215 13.8331L6.67637 7.64904C6.93834 7.41715 7.27138 7.29001 7.62091 7.29001H8.97242C9.01822 7.30924 9.07079 7.3296 9.12953 7.34968C9.33437 7.41971 9.625 7.49001 9.97091 7.49001C10.3168 7.49001 10.6074 7.41971 10.8123 7.34968C10.871 7.3296 10.9236 7.30924 10.9694 7.29001H12.3209C12.6718 7.29001 13.0061 7.41814 13.2685 7.65174L16.8087 13.645C16.8252 13.6951 16.8471 13.7438 16.8745 13.7903C17.0605 14.1066 16.9496 14.5248 16.6278 14.7094L16.6206 14.7136C16.3081 14.8974 15.8961 14.7913 15.7082 14.4784L12.9672 9.17561C12.7992 8.85076 12.4215 8.69437 12.0731 8.80544C11.7247 8.91652 11.5072 9.26268 11.5583 9.62478L12.2383 14.4448C12.2394 14.4531 12.2407 14.4613 12.2422 14.4695L12.6315 16.6898C12.6629 16.8691 12.8187 17 13.0008 17C13.2339 17 13.4104 16.7897 13.3702 16.5602L12.9809 14.34L12.3009 9.52001L15.0509 14.84C15.4409 15.52 16.3209 15.76 17.0009 15.36C17.4516 15.1015 17.7091 14.6277 17.7149 14.1396C17.7177 13.9039 17.6619 13.6649 17.5409 13.4451Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6428 5.51849C10.9394 5.41962 11.14 5.14416 11.14 4.83V3.18C11.14 2.94421 10.9458 2.75 10.71 2.75H9.18C8.94421 2.75 8.75 2.94421 8.75 3.18V4.83C8.75 5.14416 8.95056 5.41962 9.24717 5.51849L9.25779 5.52203L9.45197 5.59008C9.77499 5.69664 10.115 5.69664 10.438 5.59008L10.6428 5.51849ZM10.68 6.3C10.2 6.46 9.69 6.46 9.21 6.3L9.01 6.23C8.41 6.03 8 5.47 8 4.83V3.18C8 2.53 8.53 2 9.18 2H10.71C11.36 2 11.89 2.53 11.89 3.18V4.83C11.89 5.47 11.48 6.03 10.88 6.23L10.68 6.3Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.59878 9.27001C4.59878 9.27001 4.02878 10.03 3.57878 10.47C3.12878 10.91 2.57878 11.61 2.37878 12.15C2.15878 12.74 2.49878 13.11 2.49878 13.11L4.94878 14.45C4.94878 14.45 5.18878 14.09 5.49878 13.6C5.98878 12.81 6.55878 12.6 6.74878 12.24C6.99878 11.76 6.90878 10.7 6.90878 10.7L4.60878 9.26001L4.59878 9.27001Z"
          fill={getAlertCategoryColor(AR_CATEGORY_PPE)}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.8805 9.24001C14.8805 9.24001 15.4505 10 15.9005 10.44C16.3505 10.88 16.9005 11.58 17.1005 12.12C17.3205 12.71 16.9805 13.08 16.9805 13.08L14.5305 14.42C14.5305 14.42 14.2905 14.06 13.9805 13.57C13.4905 12.78 12.9205 12.57 12.7305 12.21C12.4805 11.73 12.5705 10.67 12.5705 10.67L14.8705 9.23001L14.8805 9.24001Z"
          fill={getAlertCategoryColor(AR_CATEGORY_PPE)}
        />
        <path
          d="M13.1887 17.2C12.9557 17.2 12.7791 17.4103 12.8193 17.6398L13.0315 18.8498C13.0629 19.0291 13.2187 19.16 13.4008 19.16C13.6338 19.16 13.8104 18.9497 13.7702 18.7202L13.558 17.5102C13.5266 17.3308 13.3708 17.2 13.1887 17.2Z"
          fill={color}
        />
        <path
          d="M6.7893 17.2C6.60749 17.2 6.45188 17.3304 6.42008 17.5094L6.2051 18.7194C6.16429 18.9491 6.34097 19.16 6.57432 19.16C6.75612 19.16 6.91174 19.0296 6.94354 18.8506L7.15851 17.6406C7.19933 17.4108 7.02265 17.2 6.7893 17.2Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19305">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
