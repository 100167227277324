import type { IconChildrenProps } from "../icon.types";

export const CaretRightDouble = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M9.99753 6.17916C9.99753 5.95874 10.0852 5.74737 10.2412 5.59166C10.5662 5.26666 11.0996 5.26666 11.4162 5.58333L15.2412 9.40833C15.3973 9.56404 15.4849 9.77541 15.4849 9.99583C15.4849 10.2163 15.3973 10.4276 15.2412 10.5833L11.4162 14.4083C11.0918 14.7328 10.5657 14.7328 10.2412 14.4083C9.91676 14.0839 9.91676 13.5578 10.2412 13.2333L13.4746 10L10.2412 6.76666C10.0852 6.61096 9.99753 6.39959 9.99753 6.17916ZM4.50586 6.17916C4.50586 5.95874 4.59354 5.74737 4.74956 5.59166C5.07456 5.26666 5.6079 5.26666 5.9329 5.59166L9.74956 9.40833C9.90559 9.56404 9.99327 9.77541 9.99327 9.99583C9.99327 10.2163 9.90559 10.4276 9.74956 10.5833L5.92456 14.4083C5.71467 14.6182 5.40874 14.7002 5.12202 14.6234C4.8353 14.5465 4.61135 14.3226 4.53452 14.0359C4.4577 13.7492 4.53967 13.4432 4.74956 13.2333L7.9829 10L4.74956 6.76666C4.59354 6.61096 4.50586 6.39959 4.50586 6.17916Z"
    fill={color}
    fillRule="evenodd"
  />
);
