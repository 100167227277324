import type { IconChildrenProps } from "../icon.types";

export const History = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M17.1333 12.3176C18.4133 8.37822 16.2574 4.14706 12.318 2.86707C8.43512 1.60544 4.26877 3.68184 2.92436 7.514H1.81888C1.72467 7.514 1.63179 7.53618 1.54775 7.57875C1.25214 7.72849 1.13389 8.08952 1.28363 8.38513L2.56727 10.9192C2.62497 11.0331 2.71749 11.1256 2.83139 11.1833C3.127 11.3331 3.48803 11.2148 3.63777 10.9192L4.92141 8.38513C4.96398 8.30109 4.98616 8.2082 4.98616 8.114C4.98616 7.78262 4.71753 7.514 4.38616 7.514H3.99425C5.27677 4.40593 8.75756 2.76166 12.009 3.81813C15.4232 4.92745 17.2916 8.59446 16.1823 12.0086C15.073 15.4228 11.4059 17.2912 7.9918 16.1819C7.72917 16.0965 7.44709 16.2403 7.36176 16.5029C7.27643 16.7655 7.42015 17.0476 7.68278 17.1329C11.6222 18.4129 15.8533 16.257 17.1333 12.3176ZM10.4924 5.41224C10.45 5.17899 10.2459 5.00211 10.0004 5.00211C9.72427 5.00211 9.50041 5.22597 9.50041 5.50211V9.13378C9.20151 9.30668 9.00041 9.62985 9.00041 10C9.00041 10.5523 9.44812 11 10.0004 11C10.0897 11 10.1762 10.9883 10.2586 10.9663L12.446 13.1537L12.5152 13.2116C12.7101 13.3466 12.9795 13.3273 13.1531 13.1537L13.2109 13.0845C13.3459 12.8896 13.3267 12.6202 13.1531 12.4466L10.9663 10.2599C10.9885 10.177 11.0004 10.0899 11.0004 10C11.0004 9.62985 10.7993 9.30668 10.5004 9.13378V5.50211L10.4924 5.41224Z"
    fill={color}
    fillRule="evenodd"
  />
);
