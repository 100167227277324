import { clsx } from "clsx";
import {
  type CSSProperties,
  forwardRef,
  useMemo,
  useState,
  useId,
} from "react";
import { useDimensions } from "~/hooks/use-dimensions";
import { useFocus } from "~/hooks/use-focus";
import { Icon } from "../icon";
import { Spinner } from "../spinner";
import { Text } from "../text";
import type { InputProps } from "./input.types";
import css from "./input.module.css";

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      align = "left",
      className,
      classNames,
      disabled = false,
      error = false,
      fullWidth = false,
      label,
      labelPlacement = "inline",
      leftIcon,
      leftIconProps,
      loading,
      onBlur,
      onFocus,
      onRightIconClick,
      rightIcon,
      rightIconProps,
      shape = "contained",
      style,
      ...other
    },
    ref,
  ) => {
    const [labelNode, setLabelNode] = useState<HTMLSpanElement | null>(null);
    const labelDimensions = useDimensions(labelNode);
    const inputId = useId();

    const rootStyle = useMemo(() => {
      const isRounded = shape === "rounded";
      let nextValuePaddingLeft = isRounded ? 32 : 16;

      if (leftIcon) {
        nextValuePaddingLeft += isRounded ? 40 : 28;
      }
      if (label && labelPlacement === "inline" && labelDimensions?.width) {
        nextValuePaddingLeft += labelDimensions.width + (isRounded ? 20 : 8);
      }

      return {
        ...style,
        "--value-padding-left": `${Math.round(nextValuePaddingLeft)}px`,
      } as CSSProperties;
    }, [label, labelDimensions?.width, labelPlacement, leftIcon, shape, style]);

    const { focus, onInternalFocus, onInternalBlur } =
      useFocus<HTMLInputElement>(false, onFocus, onBlur, disabled);

    return (
      <div
        className={clsx(
          css.root,
          className,
          classNames?.root,
          fullWidth && css.fullWidth,
          css[shape],
          disabled && css.disabled,
          error && css.error,
          leftIcon && css.hasLeftIcon,
          loading && css.loading,
          rightIcon && css.hasRightIcon,
          align === "left" && css.alignLeft,
          align === "right" && css.alignRight,
          !disabled && focus ? css.focused : undefined,
        )}
        style={rootStyle}
      >
        {label && labelPlacement === "top" ? (
          <Text
            as="label"
            htmlFor={other.id ?? inputId}
            className={clsx(css.topLabel, classNames?.label)}
          >
            {label}
          </Text>
        ) : null}
        <div
          className={clsx(
            css.inputContainer,
            classNames?.inputContainer,
            error && classNames?.error,
          )}
        >
          {leftIcon ? (
            <div className={clsx(css.leftIcon, classNames?.leftIcon)}>
              <Icon inheritColor name={leftIcon} {...leftIconProps} />
            </div>
          ) : null}
          {label && labelPlacement === "inline" ? (
            <Text
              ref={setLabelNode}
              as="label"
              htmlFor={other.id ?? inputId}
              className={clsx(css.label, classNames?.label)}
            >
              {label}
            </Text>
          ) : null}
          <input
            id={inputId}
            ref={ref}
            className={clsx(css.input, classNames?.input)}
            {...other}
            disabled={disabled}
            onFocus={onInternalFocus}
            onBlur={onInternalBlur}
          />
          {rightIcon ? (
            onRightIconClick ? (
              <button
                type="button"
                onClick={onRightIconClick}
                className={clsx(
                  css.rightIcon,
                  css.iconButton,
                  classNames?.rightIcon,
                )}
              >
                <Icon inheritColor name={rightIcon} />
              </button>
            ) : (
              <div className={clsx(css.rightIcon, classNames?.rightIcon)}>
                <Icon inheritColor name={rightIcon} {...rightIconProps} />
              </div>
            )
          ) : null}
          {loading ? (
            <div className={clsx(css.spinner, classNames?.spinner)}>
              <Spinner />
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);

Input.displayName = "Input";
