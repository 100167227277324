import type { IconChildrenProps } from "../icon.types";

export const Period = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M4.5 9.99997C4.5 9.07528 4.7282 8.20389 5.13135 7.43903C5.2913 7.13564 5.47829 6.84854 5.68998 6.582C6.01142 6.1772 6.38911 5.81914 6.81057 5.51864C7.09087 5.3188 7.39069 5.14457 7.70665 4.99942C8.40472 4.67875 9.18149 4.49997 10 4.49997C10.2761 4.49997 10.5 4.72383 10.5 4.99997V9.79397L13.8762 13.1977C14.049 13.3719 14.0672 13.6414 13.9314 13.8358L13.8733 13.9048C13.0689 14.7027 12.0441 15.232 10.9281 15.4218C10.6246 15.4734 10.3144 15.5 10 15.5C9.85758 15.5 9.71642 15.4946 9.57672 15.4839C6.87665 15.2784 4.72155 13.1233 4.51605 10.4233C4.50541 10.2836 4.5 10.1424 4.5 9.99997ZM5.50001 9.99247C5.50105 9.35043 5.63656 8.73993 5.87986 8.18761L11.8122 14.1204C11.2523 14.3668 10.64 14.4991 10.0065 14.5L5.50001 9.99247ZM12.794 13.526L12.7029 13.5961L6.40317 7.29532C6.71482 6.88153 7.09621 6.52324 7.52988 6.23792L9.5 8.20793V9.99997L9.50616 10.0783C9.52248 10.1812 9.5707 10.2772 9.64502 10.3521L12.794 13.526ZM9.5 6.79284L8.47291 5.76571C8.76024 5.66206 9.06116 5.58683 9.37227 5.54341L9.5 5.52797V6.79284ZM8.23225 14.1395L5.8605 11.7677C6.3154 12.8316 7.16841 13.6846 8.23225 14.1395Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5ZM10 3.5C6.41015 3.5 3.5 6.41015 3.5 10C3.5 13.5899 6.41015 16.5 10 16.5C13.5899 16.5 16.5 13.5899 16.5 10C16.5 6.41015 13.5899 3.5 10 3.5Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
