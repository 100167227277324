// I'm hardcoding this right now, but if we ever want to make it dynamic,
//   we can move the `base` variable and `s` function to ThemeContext,
//   so we can recalculate everything on the fly.
const base = 4;
const scale = 0.5;

const s = (n: number) => base * scale * n;

// When base is 4, value is twice as much as its key number
export const sizes = {
  "size-0": 0, // 0
  "size-1": s(1), // 2
  "size-2": s(2), // 4
  "size-3": s(3), // 6
  "size-4": s(4), // 8
  "size-5": s(5), // 10
  "size-6": s(6), // 12
  "size-7": s(7), // 14
  "size-8": s(8), // 16
  "size-9": s(9), // 18
  "size-10": s(10), // 20
  "size-11": s(11), // 22
  "size-12": s(12), // 24
  "size-13": s(13), // 26
  "size-15": s(15), // 30
  "size-16": s(16), // 32
  "size-17": s(17), // 34
  "size-18": s(18), // 36
  "size-19": s(19), // 38
  "size-20": s(20), // 40
  "size-23": s(23), // 46
  "size-24": s(24), // 48
  "size-25": s(25), // 50
  "size-27": s(27), // 54
  "size-30": s(30), // 60
  "size-32": s(32), // 64
  "size-37": s(37), // 74
  "size-40": s(40), // 80
  "size-50": s(50), // 100
  "size-60": s(60), // 120
  "size-80": s(80), // 160
  "size-85": s(85), // 170
  "size-87": s(87), // 174
  "size-95": s(95), // 190
  "size-100": s(100), // 200
  "size-110": s(110), // 220
  "size-120": s(120), // 240
  "size-160": s(160), // 320
  "size-180": s(180), // 320
  "size-192": s(192), // 384
  "size-210": s(210), // 420
  "size-230": s(230), // 460
};

/* eslint-disable sort-keys */
export const spacings = {
  "spacing-xxs": "size-2",
  "spacing-xs": "size-4",
  "spacing-s": "size-8",
  "spacing-m": "size-12",
  "spacing-l": "size-16",
  "spacing-xl": "size-20",
};
/* eslint-enable sort-keys */

/* eslint-disable sort-keys */
export const radius = {
  "radius-xxs": "size-2",
  "radius-xs": "size-4",
  "radius-s": "size-8",
  "radius-m": "size-12",
  "radius-l": "size-16",
  "radius-xl": "size-20",
};
/* eslint-enable sort-keys */
