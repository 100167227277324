import type { IconChildrenProps } from "../../icon.types";

export const SolutionsElectricityOilAndGas = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M4.16736 2.82202C3.2465 2.82202 2.5 3.56852 2.5 4.48938"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      d="M2.50082 7.26379V8.93116"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      d="M6.94336 2.82224H8.61072"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      d="M15.834 17.8228C16.7548 17.8228 17.5013 17.0763 17.5013 16.1555"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      d="M17.5008 13.381V11.7137"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      d="M13.0599 17.8222H11.3926"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      d="M14.3383 7.88954L15.2083 6.15537H13.125L13.995 4.4212"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <circle
      cx="14.1667"
      cy="6.15538"
      r="4.16667"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
    <path
      clipRule="evenodd"
      d="M5.70047 11.4229L7.29797 13.5604C7.90077 14.368 7.99675 15.4465 7.54604 16.3479C7.09533 17.2493 6.17495 17.8197 5.16714 17.822V17.822C4.15918 17.82 3.2385 17.2498 2.78759 16.3483C2.33667 15.4468 2.43258 14.3681 3.03547 13.5604L4.6338 11.4229C4.75971 11.255 4.9573 11.1562 5.16714 11.1562C5.37698 11.1562 5.57457 11.255 5.70047 11.4229V11.4229Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32454"
    />
  </>
);
