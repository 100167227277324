import { useCallback } from "react";
import { useTheme } from "~/features/theme/theme.hooks";
import type { ThemeColorName } from "~/features/theme/theme.types";
import {
  type AR_CATEGORY,
  AR_CATEGORY_ANOMALY,
  AR_CATEGORY_AREA_CONTROLS,
  AR_CATEGORY_BEHAVIOR,
  AR_CATEGORY_CUSTOM,
  AR_CATEGORY_ERGONOMICS,
  AR_CATEGORY_PANDEMIC,
  AR_CATEGORY_PPE,
  AR_CATEGORY_PUBLIC_SAFETY,
  AR_CATEGORY_TEST_RULE,
  AR_CATEGORY_VEHICLE,
} from "../alert-rule.constants";

const alertCategoryColorMap: Record<AR_CATEGORY, ThemeColorName> = {
  [AR_CATEGORY_ANOMALY]: "success-00",
  [AR_CATEGORY_AREA_CONTROLS]: "pine-20",
  [AR_CATEGORY_BEHAVIOR]: "honey-20",
  [AR_CATEGORY_CUSTOM]: "midgrey-70",
  [AR_CATEGORY_ERGONOMICS]: "magenta-20",
  [AR_CATEGORY_PANDEMIC]: "blush-20",
  [AR_CATEGORY_PPE]: "navy-20",
  [AR_CATEGORY_PUBLIC_SAFETY]: "yellow-20",
  [AR_CATEGORY_TEST_RULE]: "midgrey-70",
  [AR_CATEGORY_VEHICLE]: "purple-00",
};

export const useAlertCategoryColor = () => {
  const { colors } = useTheme();

  return useCallback(
    (alertCategoryId: string) =>
      colors[
        alertCategoryColorMap[alertCategoryId as AR_CATEGORY] ?? "midgrey-70"
      ],
    [colors],
  );
};
