import {
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

type Args = IntersectionObserverInit & {
  freezeOnceVisible?: boolean;
};

export const useIntersectionObserver = <T extends HTMLElement>(
  target: RefObject<T> | T,
  {
    threshold = 0,
    root = undefined,
    rootMargin = "0%",
    freezeOnceVisible = false,
  }: Args,
): IntersectionObserverEntry | undefined => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = useCallback(
    ([updatedEntry]: IntersectionObserverEntry[]): void => {
      setEntry(updatedEntry);
    },
    [],
  );

  // This is needed to trick the dependency array.
  // We can do without this when threshold is a number.
  // However, it can also be an array of numbers.
  // Not doing this triggers an infinite loop when an array
  // of numbers is provided!
  const stringifiedThreshold = useMemo(
    () => JSON.stringify(threshold),
    [threshold],
  );

  useEffect(() => {
    const node = "current" in target ? target.current : target;

    if (frozen || !node) return;

    const internalThreshold = JSON.parse(stringifiedThreshold) as
      | number[]
      | number;

    const observerParams = { internalThreshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [target, stringifiedThreshold, root, rootMargin, frozen, updateEntry]);

  return entry;
};
