import { type MouseEventHandler, useCallback, useState } from "react";

export const useMouseEvents = <T extends HTMLElement>(
  initialActive = false,
  initialHover = false,
  onMouseDown?: MouseEventHandler<T>,
  onMouseEnter?: MouseEventHandler<T>,
  onMouseLeave?: MouseEventHandler<T>,
  onMouseUp?: MouseEventHandler<T>,
) => {
  const [active, setActive] = useState(initialActive);
  const [hover, setHover] = useState(initialHover);

  const onInternalMouseDown = useCallback<MouseEventHandler<T>>(
    (event) => {
      setActive(true);
      if (onMouseDown && typeof onMouseDown === "function") {
        onMouseDown(event);
      }
    },
    [onMouseDown],
  );

  const onInternalMouseEnter = useCallback<MouseEventHandler<T>>(
    (event) => {
      setHover(true);
      if (onMouseEnter && typeof onMouseEnter === "function") {
        onMouseEnter(event);
      }
    },
    [onMouseEnter],
  );

  const onInternalMouseLeave = useCallback<MouseEventHandler<T>>(
    (event) => {
      setActive(false);
      setHover(false);
      if (onMouseLeave && typeof onMouseLeave === "function") {
        onMouseLeave(event);
      }
      if (onMouseUp && typeof onMouseUp === "function") {
        onMouseUp(event);
      }
    },
    [onMouseLeave, onMouseUp],
  );

  const onInternalMouseUp = useCallback<MouseEventHandler<T>>(
    (event) => {
      setActive(false);
      if (onMouseUp && typeof onMouseUp === "function") {
        onMouseUp(event);
      }
    },
    [onMouseUp],
  );

  return {
    active,
    hover,
    onInternalMouseDown,
    onInternalMouseEnter,
    onInternalMouseLeave,
    onInternalMouseUp,
  } as const;
};
