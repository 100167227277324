import type { IconChildrenProps } from "../icon.types";

export const Attach = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M11.3551 2.17629C11.5892 2.4123 11.5881 2.79199 11.3533 3.02582L4.38217 9.95552C3.61967 10.7134 3.20042 11.7195 3.20042 12.7908C3.20042 13.862 3.61973 14.8681 4.38216 15.626C5.95705 17.1918 8.52106 17.1916 10.0965 15.6259L16.0424 9.71508C16.531 9.22948 16.7995 8.58531 16.7995 7.89901C16.7995 7.21291 16.531 6.56881 16.0424 6.08308C15.0327 5.079 13.3881 5.0789 12.3782 6.08305L7.25257 11.1783C7.0378 11.3918 6.92015 11.674 6.92015 11.9752C6.92015 12.2766 7.03781 12.5589 7.25263 12.7726C7.69727 13.2145 8.42224 13.2145 8.86658 12.7726L12.557 9.10364C12.7921 8.87012 13.1714 8.87132 13.4055 9.1057C13.6395 9.34139 13.6384 9.72128 13.4036 9.95513L9.71305 13.6239C8.80101 14.5308 7.31828 14.5308 6.40622 13.6239C5.96389 13.1839 5.71974 12.5974 5.71974 11.9749C5.71974 11.3528 5.964 10.7666 6.40618 10.3268L11.5318 5.23122C13.0095 3.7629 15.4115 3.7629 16.8886 5.23121C17.6049 5.94329 18 6.89155 18 7.89885C18 8.90631 17.6048 9.8547 16.8886 10.5666L10.9431 16.4772C9.94903 17.4654 8.62025 18 7.23931 18C5.85845 18 4.52967 17.4653 3.53582 16.4772C2.54591 15.4932 2 14.183 2 12.7905C2 11.3981 2.54588 10.0879 3.53595 9.10355L10.5067 2.17432C10.7418 1.94096 11.121 1.94217 11.3551 2.17629Z"
    fill={color}
    fillRule="evenodd"
  />
);
