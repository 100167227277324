import type { IconChildrenProps } from "../icon.types";

export const LeadingIndicators = ({
  accentColor,
  color,
}: IconChildrenProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1818 10C19.1818 4.89135 15.0404 0.749969 9.9318 0.749969C9.53898 0.749969 9.22053 1.06841 9.22053 1.46124C9.22053 1.85406 9.53898 2.1725 9.9318 2.1725C14.2548 2.1725 17.7593 5.67699 17.7593 10C17.7593 14.323 14.2548 17.8275 9.9318 17.8275C9.53898 17.8275 9.22053 18.1459 9.22053 18.5388C9.22053 18.9316 9.53898 19.25 9.9318 19.25C15.0404 19.25 19.1818 15.1087 19.1818 10ZM6.77275 1.3151C7.14221 1.18165 7.5499 1.37298 7.68334 1.74244C7.81679 2.1119 7.62546 2.51959 7.256 2.65303C6.39797 2.96294 5.6011 3.4211 4.90177 4.00659C4.60058 4.25876 4.15199 4.21902 3.89982 3.91782C3.64765 3.61662 3.68739 3.16803 3.98859 2.91586C4.81565 2.22342 5.75805 1.68159 6.77275 1.3151ZM3.89948 16.0827C4.15142 15.7813 4.59998 15.7412 4.90137 15.9932C5.60134 16.5783 6.39842 17.0366 7.2565 17.3472C7.62586 17.481 7.81688 17.8888 7.68316 18.2582C7.54943 18.6275 7.1416 18.8185 6.77224 18.6848C5.75822 18.3177 4.81624 17.7761 3.98899 17.0846C3.68761 16.8326 3.64753 16.3841 3.89948 16.0827ZM2.89778 5.11474C2.55762 4.91826 2.12259 5.03473 1.92612 5.37489C1.38614 6.30972 1.0148 7.33224 0.829081 8.39569C0.761502 8.78265 1.02042 9.15113 1.40738 9.21871C1.79435 9.28629 2.16283 9.02738 2.23041 8.64041C2.38743 7.7413 2.70138 6.87678 3.15792 6.0864C3.3544 5.74624 3.23793 5.31122 2.89778 5.11474ZM1.40749 10.7803C1.79447 10.7128 2.16291 10.9718 2.23043 11.3587C2.38737 12.2582 2.70133 13.1231 3.15795 13.9137C3.3544 14.2539 3.23791 14.6889 2.89774 14.8854C2.55757 15.0818 2.12255 14.9653 1.92609 14.6252C1.38602 13.69 1.01468 12.6671 0.829062 11.6033C0.761544 11.2163 1.02052 10.8478 1.40749 10.7803Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3722 5.63328C11.0937 5.42669 10.6986 5.44965 10.4461 5.70217L10.3772 5.78195C10.1706 6.0604 10.1936 6.45555 10.4461 6.70806L11.1298 7.39112L7.1541 7.39131L7.05758 7.3978C6.71041 7.4449 6.44283 7.74249 6.44283 8.10257C6.44283 8.4954 6.76128 8.81384 7.1541 8.81384H12.8466L12.944 8.80763C13.5116 8.73407 13.774 8.02411 13.3495 7.59962L11.452 5.70215L11.3722 5.63328ZM13.5579 11.8976C13.5579 11.5048 13.2394 11.1863 12.8466 11.1863H7.1541L7.05674 11.1926C6.48914 11.2661 6.22667 11.9761 6.65116 12.4006L8.54863 14.298L8.6284 14.3669C8.90685 14.5735 9.302 14.5505 9.55451 14.298L9.62338 14.2182C9.82998 13.9398 9.80703 13.5447 9.55451 13.2921L8.86987 12.608L12.8466 12.6089L12.9431 12.6024C13.2903 12.5553 13.5579 12.2577 13.5579 11.8976Z"
      fill={accentColor}
    />
  </>
);
