import type { IconChildrenProps } from "../icon.types";

export const Speaker = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M12.5008 7.80911C12.7904 7.80911 13.0251 8.04383 13.0251 8.33338V11.6667C13.0251 11.9563 12.7904 12.191 12.5008 12.191C12.2113 12.191 11.9766 11.9563 11.9766 11.6667V8.33338C11.9766 8.04383 12.2113 7.80911 12.5008 7.80911Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M15.0009 6.14246C15.2905 6.14246 15.5252 6.37718 15.5252 6.66672V13.3334C15.5252 13.6229 15.2905 13.8577 15.0009 13.8577C14.7114 13.8577 14.4767 13.6229 14.4767 13.3334V6.66672C14.4767 6.37718 14.7114 6.14246 15.0009 6.14246Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M7.87217 3.97844C8.7543 3.22989 10.1071 3.8585 10.1071 5.01417V14.9858C10.1071 16.1418 8.75504 16.7698 7.8723 16.0217L4.70314 13.3368H2.91619C2.16665 13.3368 1.55859 12.7287 1.55859 11.9792V8.02083C1.55859 7.27129 2.16665 6.66324 2.91619 6.66324H4.70314L7.87217 3.97844ZM9.05859 5.01417C9.05859 4.74996 8.75005 4.60858 8.5505 4.77799L5.23425 7.58751C5.13955 7.66774 5.01947 7.71177 4.89536 7.71177H2.91619C2.74574 7.71177 2.60713 7.85038 2.60713 8.02083V11.9792C2.60713 12.1496 2.74574 12.2882 2.91619 12.2882H4.89536C5.01947 12.2882 5.13955 12.3323 5.23425 12.4125L8.55008 15.2217C8.75068 15.3917 9.05859 15.2498 9.05859 14.9858V5.01417Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
