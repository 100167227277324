import type { IconChildrenProps } from "../icon.types";

export const Target = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M9.21803 8.55726C9.18624 8.24426 8.9219 8 8.60051 8C8.25769 8 7.97978 8.27791 7.97978 8.62073V14.5797L7.98298 14.6432C8.01477 14.9562 8.27911 15.2005 8.60051 15.2005C8.94332 15.2005 9.22123 14.9225 9.22123 14.5797V8.62073L9.21803 8.55726ZM6.23825 11.8045C6.20647 11.4915 5.94212 11.2472 5.62073 11.2472C5.27791 11.2472 5 11.5251 5 11.8679V14.5793L5.0032 14.6427C5.03499 14.9557 5.29934 15.2 5.62073 15.2C5.96355 15.2 6.24146 14.9221 6.24146 14.5793V11.8679L6.23825 11.8045ZM11.5769 10.6614C11.8983 10.6614 12.1627 10.9057 12.1945 11.2187L12.1977 11.2821V14.5794C12.1977 14.9223 11.9198 15.2002 11.5769 15.2002C11.2555 15.2002 10.9912 14.9559 10.9594 14.6429L10.9562 14.5794V11.2821C10.9562 10.9393 11.2341 10.6614 11.5769 10.6614ZM15.1776 9.10331C15.1458 8.7903 14.8815 8.54605 14.5601 8.54605C14.2172 8.54605 13.9393 8.82396 13.9393 9.16678V14.5795L13.9425 14.643C13.9743 14.956 14.2387 15.2003 14.5601 15.2003C14.9029 15.2003 15.1808 14.9223 15.1808 14.5795V9.16678L15.1776 9.10331Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.7692 2.25H5.23077C3.58442 2.25 2.25 3.58442 2.25 5.23077V14.7692C2.25 16.4156 3.58442 17.75 5.23077 17.75H14.7692C16.4156 17.75 17.75 16.4156 17.75 14.7692V5.23077C17.75 3.58442 16.4156 2.25 14.7692 2.25ZM5.23077 3.44231H14.7692C15.7571 3.44231 16.5577 4.24291 16.5577 5.23077V14.7692C16.5577 15.7571 15.7571 16.5577 14.7692 16.5577H5.23077C4.24291 16.5577 3.44231 15.7571 3.44231 14.7692V5.23077C3.44231 4.24291 4.24291 3.44231 5.23077 3.44231Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
