import { AR_CATEGORY_VEHICLE } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseVehiclePpeCompliance = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19331)">
        <path
          d="M14.8282 13.48C14.8682 13.4 14.8982 13.31 14.8982 13.21V9.28999C14.8982 9.28999 14.8982 9.26999 14.8982 9.24999C14.8982 9.13999 14.8882 9.01999 14.8282 8.91999L12.7382 5.18999C12.6282 4.99999 12.4282 4.87999 12.2182 4.87999H9.72816C9.39816 4.87999 9.12816 5.14999 9.12816 5.47999V6.11999H7.03816C6.70816 6.11999 6.43816 6.38999 6.43816 6.71999V8.67999H5.70816C5.37816 8.67999 5.10816 8.94999 5.10816 9.27999V13.2C5.10816 13.28 5.12816 13.35 5.14816 13.41C5.25816 14.36 6.05816 15.11 7.03816 15.11C7.87815 15.11 8.58816 14.56 8.84816 13.8H11.1382C11.3882 14.56 12.0982 15.11 12.9482 15.11C13.9082 15.11 14.6982 14.4 14.8382 13.47L14.8282 13.48ZM10.3282 6.07999H11.8682L13.2882 8.60999H10.3382V6.07999H10.3282ZM7.63815 7.31999H8.92816V8.60999H7.63815V7.31999ZM13.7082 9.87999V11.45C13.4782 11.35 13.2182 11.29 12.9482 11.29C12.1082 11.29 11.3982 11.84 11.1382 12.6H8.84816C8.59816 11.84 7.88815 11.29 7.03816 11.29C6.77816 11.29 6.52816 11.34 6.30816 11.44V9.87999H13.7082ZM7.03816 13.92C6.64816 13.92 6.32816 13.6 6.32816 13.21C6.32816 12.82 6.64816 12.5 7.03816 12.5C7.42815 12.5 7.74816 12.82 7.74816 13.21C7.74816 13.6 7.42815 13.92 7.03816 13.92ZM12.9482 13.92C12.5582 13.92 12.2382 13.6 12.2382 13.21C12.2382 12.82 12.5582 12.5 12.9482 12.5C13.3382 12.5 13.6582 12.82 13.6582 13.21C13.6582 13.6 13.3382 13.92 12.9482 13.92Z"
          fill={color}
        />
        <path
          d="M11.8782 2.73999C12.1182 2.79999 12.3582 2.87999 12.5882 2.95999C12.6482 2.97999 12.7082 2.98999 12.7582 2.98999C12.9582 2.98999 13.1482 2.86999 13.2282 2.65999C13.3282 2.39999 13.1882 2.10999 12.9382 2.01999C12.6782 1.91999 12.3982 1.83999 12.1282 1.76999C11.8582 1.69999 11.5882 1.85999 11.5182 2.12999C11.4482 2.39999 11.6082 2.66999 11.8782 2.73999Z"
          fill={color}
        />
        <path
          d="M9.72816 2.49999C9.72816 2.49999 9.73816 2.49999 9.74816 2.49999H10.0082C10.1782 2.49999 10.3382 2.49999 10.4982 2.50999C10.5082 2.50999 10.5182 2.50999 10.5282 2.50999C10.7882 2.50999 11.0082 2.30999 11.0282 2.03999C11.0482 1.75999 10.8382 1.52999 10.5582 1.50999C10.3782 1.49999 10.1882 1.48999 9.99816 1.48999H9.70816C9.42816 1.49999 9.21815 1.72999 9.22816 2.00999C9.22816 2.27999 9.45816 2.48999 9.72816 2.48999V2.49999Z"
          fill={color}
        />
        <path
          d="M2.15816 11.68C2.42816 11.64 2.61816 11.38 2.57816 11.11C2.53816 10.87 2.51816 10.62 2.50816 10.37C2.49816 10.1 2.25816 9.85999 1.98816 9.88999C1.70816 9.89999 1.49816 10.14 1.50816 10.41C1.51816 10.7 1.54816 10.98 1.58816 11.26C1.62816 11.51 1.83816 11.69 2.07816 11.69C2.10816 11.69 2.12816 11.69 2.15816 11.69V11.68Z"
          fill={color}
        />
        <path
          d="M2.00816 9.38999C2.00816 9.38999 2.05816 9.38999 2.07816 9.38999C2.32816 9.38999 2.53816 9.20999 2.56816 8.95999C2.60816 8.70999 2.64816 8.45999 2.70816 8.21999C2.76816 7.94999 2.60816 7.67999 2.33816 7.61999C2.06816 7.54999 1.79816 7.71999 1.73816 7.98999C1.66816 8.25999 1.61816 8.53999 1.57816 8.81999C1.53816 9.08999 1.72816 9.34999 2.00816 9.37999V9.38999Z"
          fill={color}
        />
        <path
          d="M7.46815 2.90999C7.51816 2.90999 7.57816 2.90999 7.62816 2.87999C7.86816 2.79999 8.10816 2.72999 8.34816 2.67999C8.61816 2.61999 8.78815 2.34999 8.72815 2.07999C8.66815 1.80999 8.39816 1.63999 8.12816 1.69999C7.84816 1.75999 7.57816 1.83999 7.30816 1.92999C7.04815 2.01999 6.90815 2.29999 6.98815 2.55999C7.05816 2.76999 7.24816 2.89999 7.45816 2.89999L7.46815 2.90999Z"
          fill={color}
        />
        <path
          d="M17.3982 8.74999C17.4382 8.98999 17.4682 9.23999 17.4882 9.48999C17.5082 9.74999 17.7282 9.95999 17.9882 9.95999C17.9982 9.95999 18.0082 9.95999 18.0182 9.95999C18.2982 9.93999 18.4982 9.69999 18.4882 9.42999C18.4682 9.13999 18.4382 8.85999 18.3882 8.58999C18.3382 8.31999 18.0782 8.13999 17.8082 8.17999C17.5382 8.22999 17.3482 8.47999 17.3982 8.75999V8.74999Z"
          fill={color}
        />
        <path
          d="M13.8582 3.56999C14.0682 3.69999 14.2782 3.83999 14.4782 3.98999C14.5682 4.05999 14.6682 4.08999 14.7782 4.08999C14.9282 4.08999 15.0782 4.01999 15.1782 3.88999C15.3382 3.66999 15.2982 3.35999 15.0782 3.18999C14.8482 3.01999 14.6182 2.85999 14.3782 2.71999C14.1482 2.57999 13.8382 2.64999 13.6882 2.88999C13.5482 3.12999 13.6182 3.42999 13.8582 3.57999V3.56999Z"
          fill={color}
        />
        <path
          d="M16.7382 6.69999C16.8482 6.91999 16.9482 7.14999 17.0282 7.38999C17.0982 7.58999 17.2982 7.71999 17.4982 7.71999C17.5582 7.71999 17.6182 7.71999 17.6682 7.68999C17.9282 7.58999 18.0582 7.30999 17.9682 7.04999C17.8682 6.77999 17.7582 6.51999 17.6282 6.26999C17.5082 6.01999 17.2082 5.91999 16.9582 6.03999C16.7082 6.15999 16.6082 6.45999 16.7282 6.70999L16.7382 6.69999Z"
          fill={color}
        />
        <path
          d="M15.9982 5.49999C16.0982 5.62999 16.2482 5.69999 16.3982 5.69999C16.4982 5.69999 16.6082 5.66999 16.6982 5.59999C16.9182 5.42999 16.9682 5.11999 16.7982 4.89999C16.6282 4.66999 16.4482 4.45999 16.2582 4.24999C16.0682 4.04999 15.7582 4.02999 15.5482 4.21999C15.3482 4.40999 15.3282 4.71999 15.5182 4.92999C15.6882 5.10999 15.8482 5.30999 15.9982 5.50999V5.49999Z"
          fill={color}
        />
        <path
          d="M3.90816 14.38C3.74816 14.15 3.42816 14.1 3.20816 14.26C2.98816 14.42 2.92816 14.73 3.08816 14.96C3.25816 15.19 3.42816 15.41 3.61816 15.63C3.71816 15.74 3.85816 15.8 3.98816 15.8C4.10816 15.8 4.22816 15.76 4.31816 15.67C4.52816 15.49 4.54816 15.17 4.35816 14.96C4.18816 14.77 4.03816 14.58 3.88816 14.38H3.90816Z"
          fill={color}
        />
        <path
          d="M17.5482 12.71C17.2882 12.6 16.9982 12.71 16.8882 12.97C16.7882 13.2 16.6782 13.43 16.5582 13.64C16.4282 13.88 16.5082 14.19 16.7582 14.32C16.8382 14.36 16.9182 14.38 16.9982 14.38C17.1782 14.38 17.3482 14.29 17.4382 14.12C17.5782 13.87 17.6982 13.62 17.8182 13.36C17.9282 13.11 17.8182 12.81 17.5582 12.7L17.5482 12.71Z"
          fill={color}
        />
        <path
          d="M15.7682 14.8C15.6082 14.99 15.4382 15.18 15.2582 15.35C15.0582 15.54 15.0582 15.86 15.2582 16.06C15.3582 16.16 15.4882 16.21 15.6182 16.21C15.7482 16.21 15.8682 16.16 15.9682 16.07C16.1682 15.87 16.3582 15.66 16.5482 15.45C16.7282 15.24 16.6982 14.92 16.4882 14.75C16.2782 14.58 15.9582 14.6 15.7882 14.81L15.7682 14.8Z"
          fill={color}
        />
        <path
          d="M17.9982 10.46C17.7282 10.43 17.4782 10.62 17.4482 10.9C17.4182 11.15 17.3782 11.4 17.3282 11.64C17.2682 11.91 17.4382 12.18 17.7082 12.24C17.7482 12.24 17.7782 12.25 17.8182 12.25C18.0482 12.25 18.2582 12.09 18.3082 11.86C18.3682 11.59 18.4182 11.31 18.4482 11.02C18.4782 10.75 18.2782 10.5 18.0082 10.47L17.9982 10.46Z"
          fill={color}
        />
        <path
          d="M14.1682 16.24C13.9582 16.38 13.7482 16.51 13.5282 16.62C13.2882 16.75 13.1882 17.05 13.3182 17.3C13.4082 17.47 13.5782 17.57 13.7582 17.57C13.8382 17.57 13.9182 17.55 13.9882 17.51C14.2382 17.38 14.4882 17.23 14.7182 17.07C14.9482 16.92 15.0082 16.61 14.8582 16.38C14.7082 16.15 14.3882 16.09 14.1682 16.24Z"
          fill={color}
        />
        <path
          d="M6.00816 16.35C5.79816 16.22 5.58816 16.07 5.39816 15.92C5.17816 15.75 4.86816 15.79 4.69815 16.01C4.52815 16.23 4.56816 16.54 4.78816 16.71C5.00816 16.88 5.24815 17.05 5.48815 17.2C5.56815 17.25 5.65816 17.28 5.75816 17.28C5.92816 17.28 6.08815 17.2 6.17815 17.05C6.32816 16.82 6.25815 16.51 6.01816 16.36L6.00816 16.35Z"
          fill={color}
        />
        <path
          d="M12.2282 17.16C11.9882 17.23 11.7482 17.3 11.4982 17.35C11.2282 17.41 11.0582 17.67 11.1082 17.94C11.1582 18.18 11.3682 18.34 11.5982 18.34C11.6282 18.34 11.6682 18.34 11.6982 18.33C11.9782 18.27 12.2482 18.2 12.5182 18.12C12.7782 18.04 12.9282 17.76 12.8482 17.49C12.7682 17.23 12.4882 17.08 12.2182 17.16H12.2282Z"
          fill={color}
        />
        <path
          d="M3.19816 13.16C3.08816 12.93 2.99816 12.7 2.91816 12.47C2.82816 12.21 2.53816 12.07 2.27816 12.16C2.01816 12.25 1.87816 12.54 1.96816 12.8C2.05816 13.07 2.16816 13.33 2.28816 13.59C2.36816 13.77 2.55816 13.88 2.73816 13.88C2.80816 13.88 2.87816 13.86 2.94816 13.83C3.19816 13.71 3.30816 13.42 3.18816 13.17L3.19816 13.16Z"
          fill={color}
        />
        <path
          d="M7.97816 17.22C7.73816 17.15 7.49816 17.07 7.26816 16.98C7.00815 16.88 6.71815 17.01 6.61816 17.26C6.51816 17.52 6.64816 17.81 6.89816 17.91C7.15815 18.01 7.42815 18.1 7.70816 18.18C7.74816 18.19 7.79816 18.2 7.83816 18.2C8.05816 18.2 8.25815 18.06 8.31815 17.83C8.38815 17.56 8.23815 17.29 7.96815 17.21L7.97816 17.22Z"
          fill={color}
        />
        <path
          d="M10.1182 17.5H9.99816C9.77816 17.5 9.56815 17.5 9.35815 17.47C9.09816 17.45 8.83815 17.65 8.81815 17.93C8.79815 18.2 8.99815 18.45 9.27815 18.47C9.51816 18.49 9.75816 18.5 10.0082 18.5H10.1282C10.4082 18.5 10.6282 18.27 10.6182 17.99C10.6182 17.72 10.3882 17.5 10.1182 17.5Z"
          fill={color}
        />
        <path
          d="M7.35156 5.40011V4.37011C7.35156 3.42011 6.59156 2.65011 5.65156 2.62011C5.51156 2.47011 5.32156 2.36011 5.10156 2.36011C4.88156 2.36011 4.68156 2.46011 4.55156 2.62011C3.61156 2.65011 2.85156 3.42011 2.85156 4.37011V5.40011C2.57156 5.46011 2.35156 5.70011 2.35156 6.00011C2.35156 6.34011 2.63156 6.63011 2.98156 6.63011H3.15156C3.40156 6.63011 3.63156 6.70011 3.84156 6.84011C4.13156 7.03011 4.46156 7.13011 4.80156 7.13011H5.40156C5.75156 7.13011 6.08156 7.03011 6.36156 6.84011C6.56156 6.70011 6.80156 6.63011 7.05156 6.63011H7.23156C7.57156 6.63011 7.85156 6.35011 7.85156 6.00011C7.85156 5.70011 7.63156 5.46011 7.35156 5.40011ZM5.85156 3.17011C5.94156 3.19011 6.02156 3.19011 6.10156 3.23011V3.62011C6.10156 3.76011 5.85156 3.76011 5.85156 3.62011V3.17011ZM4.10156 3.23011C4.18156 3.20011 4.27156 3.19011 4.35156 3.17011V3.62011C4.35156 3.76011 4.10156 3.76011 4.10156 3.62011V3.23011ZM3.61156 3.65011C3.63156 3.98011 3.89156 4.25011 4.23156 4.25011C4.57156 4.25011 4.86156 3.97011 4.86156 3.62011V3.12011C4.86156 2.98011 4.97156 2.87011 5.11156 2.87011C5.25156 2.87011 5.36156 2.98011 5.36156 3.12011V3.62011C5.36156 3.97011 5.64156 4.25011 5.99156 4.25011C6.34156 4.25011 6.59156 3.98011 6.61156 3.65011C6.76156 3.86011 6.87156 4.10011 6.87156 4.37011V5.37011H3.35156V4.37011C3.35156 4.10011 3.46156 3.85011 3.61156 3.65011ZM7.24156 6.13011H7.06156C6.71156 6.13011 6.38156 6.23011 6.09156 6.42011C5.89156 6.56011 5.65156 6.63011 5.40156 6.63011H4.80156C4.55156 6.63011 4.32156 6.56011 4.11156 6.42011C3.82156 6.23011 3.49156 6.13011 3.14156 6.13011H2.97156C2.90156 6.13011 2.84156 6.07011 2.84156 6.00011C2.84156 5.93011 2.90156 5.87011 2.97156 5.87011H7.23156C7.30156 5.87011 7.35156 5.93011 7.35156 6.00011C7.35156 6.07011 7.29156 6.13011 7.23156 6.13011H7.24156Z"
          fill={getAlertCategoryColor(AR_CATEGORY_VEHICLE)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19331">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
