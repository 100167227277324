import type { IconChildrenProps } from "../icon.types";

export const FilterAdd = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M17.5489 3.06754C17.5682 3.2153 17.5543 3.36552 17.5082 3.50722L16.5558 6.43048C16.3586 7.03568 15.656 8.08126 15.656 8.08126L14.9825 7.31739C14.9825 7.31739 15.3897 6.69162 15.5264 6.34068L15.605 6.12071L16.5574 3.19744L4.44363 3.20523L5.3935 6.12071C5.65948 6.93709 6.17227 7.64854 6.85827 8.15853L7.0496 8.29255L8.09997 8.98494C8.61458 9.32417 8.94136 9.87962 8.99225 10.4877L8.99923 10.6548V16H11.9992V17H8.99923C8.44694 17 7.99923 16.5523 7.99923 16V10.6548C7.99923 10.3186 7.83029 10.0049 7.5496 9.81986L6.49923 9.12747C5.52808 8.4873 4.80301 7.53643 4.44269 6.43048L3.49282 3.515C3.32174 2.98989 3.60874 2.4255 4.13385 2.25442C4.27704 2.20777 4.42891 2.19406 4.57814 2.21431L16.4275 2.20591C16.9751 2.13417 17.4771 2.51993 17.5489 3.06754Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.0007 8.03064C13.5423 8.03064 13.1673 8.40564 13.1673 8.86397V11.364H10.6673C10.209 11.364 9.83398 11.739 9.83398 12.1973C9.83398 12.6556 10.209 13.0306 10.6673 13.0306H13.1673V15.5306C13.1673 15.989 13.5423 16.364 14.0007 16.364C14.459 16.364 14.834 15.989 14.834 15.5306V13.0306H17.334C17.7923 13.0306 18.1673 12.6556 18.1673 12.1973C18.1673 11.739 17.7923 11.364 17.334 11.364H14.834V8.86397C14.834 8.40564 14.459 8.03064 14.0007 8.03064Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
