import { sizes } from "./theme.sizes";

/* eslint-disable sort-keys */
export const fontSizes = {
  "text-xs": sizes["size-5"], // 10
  "text-sm": sizes["size-6"], // 12
  "text-md": sizes["size-7"], // 14
  "text-lg": sizes["size-8"], // 16
  "text-xl": sizes["size-10"], // 20
  "text-2xl": sizes["size-12"], // 24
  "text-3xl": sizes["size-16"], // 32
  "text-4xl": sizes["size-23"], // 46
};
/* eslint-enable sort-keys */

/* eslint-disable sort-keys */
export const fontWeights = {
  "text-light": "300",
  "text-regular": "400",
  "text-medium": "500",
  "text-semibold": "600",
  "text-bold": "700",
  "text-heavy": "800",
};
/* eslint-enable sort-keys */
