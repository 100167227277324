import type { IconChildrenProps } from "../icon.types";

export const Sample = ({ color }: IconChildrenProps) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M8.8 4.59167C9.925 4.61667 10.8333 5.53333 10.8333 6.66667C10.8333 7.29167 10.5583 7.84167 10.125 8.225L10.6167 9.575L11.4 9.29167L11.6833 10.075L13.25 9.50833L12.9667 8.725L13.75 8.44167L11.4667 2.16667L10.6833 2.45L10.4 1.66667L8.83333 2.23333L9.11667 3.01667L8.33333 3.30833L8.8 4.59167ZM5.83333 15.8333C4.91667 15.8333 4.16667 16.5833 4.16667 17.5H15.8333C15.8333 16.5833 15.0833 15.8333 14.1667 15.8333H10.8333V14.1667H13.3333C14.25 14.1667 15 13.4167 15 12.5H8.33333C6.95 12.5 5.83333 11.3833 5.83333 10C5.83333 9.09167 6.325 8.3 7.05 7.86667C6.80833 7.525 6.66667 7.11667 6.66667 6.66667C6.66667 6.49167 6.7 6.31667 6.74167 6.15C5.18489 6.79592 4.16919 8.31455 4.16667 10C4.16667 12.3 6.03333 14.1667 8.33333 14.1667V15.8333H5.83333ZM8.75 7.91667C9.44036 7.91667 10 7.35702 10 6.66667C10 5.97631 9.44036 5.41667 8.75 5.41667C8.05964 5.41667 7.5 5.97631 7.5 6.66667C7.5 7.35702 8.05964 7.91667 8.75 7.91667Z"
    fill={color}
  />
);
