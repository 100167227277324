import type { IconChildrenProps } from "../icon.types";

export const AddCircle = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M10 0.25C15.3848 0.25 19.75 4.61521 19.75 10C19.75 15.3848 15.3848 19.75 10 19.75C4.61521 19.75 0.25 15.3848 0.25 10C0.25 4.61523 4.61523 0.25 10 0.25ZM10 1.75C5.44365 1.75 1.75 5.44365 1.75 10C1.75 14.5564 5.44364 18.25 10 18.25C14.5564 18.25 18.25 14.5564 18.25 10C18.25 5.44364 14.5564 1.75 10 1.75ZM10 5.5C10.3797 5.5 10.6935 5.78215 10.7432 6.14823L10.75 6.25V9.25H13.75C14.1642 9.25 14.5 9.58579 14.5 10C14.5 10.3797 14.2178 10.6935 13.8518 10.7432L13.75 10.75H10.75V13.75C10.75 14.1642 10.4142 14.5 10 14.5C9.6203 14.5 9.30651 14.2178 9.25685 13.8518L9.25 13.75V10.75H6.25C5.83579 10.75 5.5 10.4142 5.5 10C5.5 9.6203 5.78215 9.30651 6.14823 9.25685L6.25 9.25H9.25V6.25C9.25 5.83579 9.58579 5.5 10 5.5Z"
    fill={color}
    fillRule="evenodd"
  />
);
