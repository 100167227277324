import { AuthButton } from "~/components/auth-button";
import { Checkbox } from "~/components/checkbox";
import { Input } from "~/components/input";
import { PasswordInput } from "~/components/password-input/password-input";
import { Text } from "~/components/text";
import { UserJobTitleSelect } from "~/components/user-job-title-select";
import { useUi } from "~/features/ui/ui.hooks";
import { PasswordErrors } from "~/utils/form-validator";
import { FormCard } from "../form-card/form-card";
import { useSignUpForm } from "./sign-up-form.hooks";
import type { SignUpFormProps } from "./sign-up-form.types";
import css from "./sign-up-form.module.css";

export const SignUpForm = (props: SignUpFormProps) => {
  const {
    consent,
    disabled,
    email,
    errors,
    firstName,
    jobTitle,
    lastName,
    loading,
    onConsentChange,
    onFirstNameChange,
    onJobTitleChange,
    onLastNameChange,
    onPasswordChange,
    onPressSubmit,
    onSubmit,
    password,
    signUpError,
    sso,
  } = useSignUpForm(props);
  const { isMobile } = useUi();

  return (
    <FormCard title="Sign Up">
      <form onSubmit={onSubmit}>
        <Input defaultValue={email} disabled fullWidth shape="rounded" />
        {!sso ? (
          <>
            <Input
              autoCapitalize="on"
              autoComplete="given-name"
              autoCorrect="off"
              className={css.input}
              data-testid="sign-up-input-first-name"
              disabled={loading}
              error={errors.firstName}
              fullWidth
              name="first-name"
              onChange={onFirstNameChange}
              placeholder="Name"
              shape="rounded"
              spellCheck={false}
              type="text"
              value={firstName}
            />
            <Input
              autoCapitalize="on"
              autoComplete="family-name"
              autoCorrect="off"
              className={css.input}
              data-testid="sign-up-input-last-name"
              disabled={loading}
              error={errors.lastName}
              fullWidth
              name="last-name"
              onChange={onLastNameChange}
              placeholder="Surname"
              shape="rounded"
              spellCheck={false}
              type="text"
              value={lastName}
            />
          </>
        ) : null}
        <UserJobTitleSelect
          className={css.input}
          data-testid="sign-up-select-job-title"
          disabled={loading}
          error={errors.jobTitle}
          fullWidth
          label="Job Title"
          onChange={onJobTitleChange}
          shape="rounded"
          value={jobTitle}
        />
        {!sso ? (
          <>
            <PasswordInput
              className={css.input}
              data-testid="sign-up-input-password"
              disabled={loading}
              error={
                errors[PasswordErrors.Length] ||
                errors[PasswordErrors.LetterAndDigit] ||
                errors[PasswordErrors.SpecialCharacter] ||
                errors[PasswordErrors.Whitespace] ||
                errors[PasswordErrors.PersonalInformation]
              }
              fullWidth
              onChange={onPasswordChange}
              shape="rounded"
              spellCheck={false}
              value={password}
            />
            <div className={css.passwordErrors}>
              {/* messages.auth.signup.password-error.length */}
              {errors[PasswordErrors.Length] ? (
                <Text color="red-20">• At least 12 characters in length</Text>
              ) : (
                <Text color="navy-10">✓ At least 12 characters in length</Text>
              )}
              {/* messages.auth.signup.password-error.letter-and-digit */}
              {errors[PasswordErrors.LetterAndDigit] ? (
                <Text color="red-20">
                  • At least 1 lower case letter, 1 upper case letter and a
                  number
                </Text>
              ) : (
                <Text color="navy-10">
                  ✓ At least 1 lower case letter, 1 upper case letter and a
                  number
                </Text>
              )}
              {/* messages.auth.signup.password-error.special-character */}
              {errors[PasswordErrors.SpecialCharacter] ? (
                <Text color="red-20">
                  • At least 1 special character (e.g. !@#$%^&*)
                </Text>
              ) : (
                <Text color="navy-10">
                  ✓ At least 1 special character (e.g. !@#$%^&*)
                </Text>
              )}
              {/* messages.auth.signup.password-error.whitespace */}
              {errors[PasswordErrors.Whitespace] ? (
                <Text color="red-20">• No whitespace</Text>
              ) : (
                <Text color="navy-10">✓ No whitespace</Text>
              )}
              {errors[PasswordErrors.PersonalInformation] ? (
                <Text color="red-20">
                  • No personal data like name, surname and email
                </Text>
              ) : (
                <Text color="navy-10">
                  ✓ No personal data like name, surname and email
                </Text>
              )}
            </div>
          </>
        ) : null}
        {signUpError ? (
          <div className={css.error}>
            <Text
              align="center"
              color="red-20"
              fontWeight="text-semibold"
              fontSize={isMobile ? "text-md" : "text-lg"}
              fullWidth
            >
              {signUpError}
            </Text>
          </div>
        ) : null}
        <div className={css.consent}>
          <Checkbox
            checked={consent}
            data-testid="sign-up-checkbox-consent"
            disabled={loading}
            onChange={onConsentChange}
          >
            <div>
              <Text fontSize={isMobile ? "text-md" : "text-lg"}>
                {"I agree to Intenseye's "}
              </Text>
              <Text color="red-20" fontSize={isMobile ? "text-md" : "text-lg"}>
                <a
                  className={css.link}
                  data-testid="sign-up-link-terms-of-service"
                  href="https://www.intenseye.com/terms-of-service"
                  rel="noreferrer"
                  target="_blank"
                >
                  Terms of Service
                </a>
              </Text>
              <Text fontSize={isMobile ? "text-md" : "text-lg"}> & </Text>
              <Text color="red-20" fontSize={isMobile ? "text-md" : "text-lg"}>
                <a
                  className={css.link}
                  data-testid="sign-up-link-privacy-policy"
                  href="https://www.intenseye.com/privacy-policy"
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </Text>
            </div>
          </Checkbox>
        </div>
        <AuthButton
          className={css.button}
          data-testid="sign-up-button-submit"
          disabled={disabled || loading}
          fullWidth
          onPress={onPressSubmit}
        >
          Sign up
        </AuthButton>
      </form>
    </FormCard>
  );
};
