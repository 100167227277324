import type { IconChildrenProps } from "../../icon.types";

export const ErgonomicsNeckTwist = ({
  accentColor,
  color,
}: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M13.6963 4.48639C13.6297 3.37818 12.7099 2.5 11.5849 2.5H8.50782L8.37897 2.50386C7.27076 2.57045 6.39258 3.4903 6.39258 4.61524V7.69237L6.39792 7.89161C6.45818 9.01298 7.02408 10 7.8717 10.6288L7.44342 12.5751C7.43054 12.5737 7.41763 12.5724 7.4047 12.5714L7.43934 12.6C7.25776 13.1447 7.27498 13.5048 7.37308 13.7426C8.99272 14.8214 11.103 14.8209 12.7226 13.7405C12.7484 13.7234 12.7807 13.717 12.8117 13.7232L15.0013 14.1609L15.1272 14.191C15.8703 14.3978 16.3931 15.0767 16.3931 15.8585V16.9233L16.3983 17.0015C16.4365 17.283 16.6778 17.5 16.9698 17.5C17.2883 17.5 17.5465 17.2418 17.5465 16.9233V15.8585L17.5425 15.7065C17.4741 14.3969 16.5259 13.2895 15.2274 13.0299L13.038 12.5922L12.9136 12.5736C12.8255 12.5649 12.7371 12.5654 12.6499 12.575L12.2211 10.6288C13.1185 9.96302 13.7002 8.89561 13.7002 7.69237V4.61524L13.6963 4.48639ZM10.0465 11.3461C10.4349 11.3461 10.8092 11.2855 11.1604 11.1732L11.5765 13.0636C10.606 13.509 9.48693 13.5091 8.51677 13.0639L8.93262 11.1732C9.2838 11.2855 9.65808 11.3461 10.0465 11.3461ZM8.50792 3.65343H11.585C12.1162 3.65343 12.5469 4.08406 12.5469 4.61524V7.69237L12.5429 7.83425C12.4693 9.14912 11.3798 10.1927 10.0465 10.1927C8.66558 10.1927 7.54611 9.07326 7.54611 7.69237V4.61524L7.55108 4.5169C7.60033 4.03191 8.00994 3.65343 8.50792 3.65343Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M3.78259 14.2821C4.17907 14.1622 4.59767 14.3864 4.71756 14.7829C5.12794 16.1401 5.94771 16.8581 6.82202 17.1935C7.72773 17.5409 8.7272 17.4891 9.44828 17.2209C9.8365 17.0764 10.2683 17.2741 10.4127 17.6623C10.5571 18.0506 10.3594 18.4823 9.97121 18.6267C8.93226 19.0132 7.55499 19.0812 6.2848 18.594C4.98322 18.0947 3.82796 17.0235 3.28176 15.217C3.16187 14.8206 3.3861 14.402 3.78259 14.2821Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M7.49876 18.5C7.70148 18.2387 7.65403 17.8626 7.39276 17.6599C6.18092 16.7196 4.99262 16.9734 4.73678 15.441L4.70109 15.1976L4.67719 14.9645L4.93786 15.1653C5.17543 15.3496 5.50787 15.327 5.7186 15.125L5.77795 15.0591C5.96217 14.8215 5.9396 14.4891 5.73764 14.2783L5.67169 14.219L4.26467 13.1279C4.02716 12.9438 3.69481 12.9663 3.48407 13.1681L3.42471 13.234L2.33277 14.6412C2.13005 14.9025 2.17749 15.2786 2.43875 15.4813C2.67625 15.6656 3.0087 15.6432 3.2195 15.4413L3.27887 15.3754L3.48745 15.1066L3.51221 15.3413C3.75967 17.2983 5.13473 17.4235 6.65864 18.606C6.9199 18.8087 7.29604 18.7612 7.49876 18.5Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
