import { AR_CATEGORY_ANOMALY } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../icon.types";

export const CategoryAnomaly = ({ color }: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();

  return (
    <>
      <path
        clipRule="evenodd"
        d="M16.5242 15.416C16.7904 15.416 17.0061 15.6317 17.0061 15.8979C17.0061 16.1399 16.8278 16.3402 16.5954 16.3746L16.5242 16.3799H9.10943C8.84325 16.3799 8.62747 16.1641 8.62747 15.8979C8.62747 15.6559 8.8058 15.4556 9.03821 15.4212L9.10943 15.416H16.5242ZM5.77279 15.416C6.03897 15.416 6.25475 15.6317 6.25475 15.8979C6.25475 16.1399 6.07642 16.3402 5.84401 16.3746L5.77279 16.3799H3.54836C3.28219 16.3799 3.06641 16.1641 3.06641 15.8979C3.06641 15.6559 3.24474 15.4556 3.47714 15.4212L3.54836 15.416H5.77279ZM14.2997 17.6404C14.5659 17.6404 14.7817 17.8562 14.7817 18.1223C14.7817 18.3643 14.6034 18.5646 14.371 18.5991L14.2997 18.6043H5.40205C5.13587 18.6043 4.92009 18.3885 4.92009 18.1223C4.92009 17.8804 5.09842 17.68 5.33083 17.6456L5.40205 17.6404H14.2997Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9.25769 3.3104L5.15808 6.18139L9.34444 12.1599L13.4441 9.28891L9.25769 3.3104ZM10.1682 2.67254L14.3553 8.65105L14.735 8.38572L14.8057 8.34354C15.0482 8.22231 15.3494 8.29359 15.5095 8.5223C15.6696 8.75102 15.6337 9.05841 15.4368 9.24477L15.3729 9.29685L8.6912 13.9746L8.62043 14.0168C8.37799 14.1381 8.07689 14.0668 7.91673 13.8381C7.75658 13.6094 7.79257 13.302 7.98946 13.1156L8.05331 13.0635L8.43239 12.7974L4.24676 6.81887L3.86732 7.08518L3.79654 7.12738C3.55409 7.24867 3.25293 7.17738 3.09277 6.94867C2.93268 6.71989 2.96862 6.41256 3.1655 6.2262L3.22935 6.17413L9.9096 1.49634L9.98037 1.45414C10.2228 1.33287 10.524 1.4042 10.6841 1.63291C10.8442 1.86162 10.8083 2.16899 10.6114 2.35536L10.5476 2.40744L10.1682 2.67254Z"
        fill={getAlertCategoryColor(AR_CATEGORY_ANOMALY)}
        fillRule="evenodd"
      />
    </>
  );
};
