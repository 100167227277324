import { useFocusRing } from "@react-aria/focus";
import { usePress, useLongPress } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";
import { clsx } from "clsx";
import { forwardRef } from "react";
import { type PressableWrapperProps } from "~/components/pressable-wrapper/pressable-wrapper.types";
import css from "./pressable-wrapper.module.css";

export const PressableWrapper = forwardRef<
  HTMLDivElement,
  PressableWrapperProps
>((props: PressableWrapperProps, ref) => {
  const {
    className,
    children,
    forcePropagation,
    fullWidth,
    onPress,
    onPressChange,
    onPressEnd,
    onPressStart,
    onPressUp,
    isDisabled,
    isLongPressDisabled,
    onLongPressStart,
    onLongPressEnd,
    onLongPress,
    threshold,
    accessibilityDescription,
    ...extraProps
  } = props;

  const { isFocusVisible, focusProps } = useFocusRing();

  /**
  - Handles mouse and touch events
  - Handles 'Enter' or 'Space' key presses
  - Handles screen reader virtual clicks
  - Uses pointer events where available, with fallbacks to mouse and touch events
  - Normalizes focus behavior on mouse and touch interactions across browsers
  - Handles disabling text selection on mobile while the press interaction is active
  - Handles canceling press interactions on scroll
  - Normalizes many cross browser inconsistencies
  */
  const { pressProps } = usePress({
    onPress,
    onPressChange,
    onPressEnd,
    onPressStart(e) {
      if (forcePropagation) {
        e.continuePropagation();
      }
    },
    onPressUp,
  });

  const { longPressProps } = useLongPress({
    accessibilityDescription,
    isDisabled: isLongPressDisabled,
    onLongPress,
    onLongPressEnd,
    onLongPressStart,
    threshold,
  });

  return (
    <div
      {...extraProps}
      {...(!isDisabled
        ? mergeProps(pressProps, longPressProps, focusProps)
        : null)}
      role={"button"}
      tabIndex={0}
      className={clsx(
        className,
        isDisabled && css.disabled,
        fullWidth && css.fullWidth,
        css.root,
      )}
      style={{
        outline: isFocusVisible ? "2px solid dodgerblue" : "none",
      }}
      ref={ref}
    >
      {children}
    </div>
  );
});

PressableWrapper.displayName = "PressableWrapper";
