import { useTheme } from "~/features/theme/theme.hooks";
import type { LogoOptionProps } from "./logo.types";

export const LogoIcon = ({ variant, ...svgProps }: LogoOptionProps) => {
  const { colors } = useTheme();

  const backgroundColor =
    variant === "dark" ? colors["dark-20"] : colors["beige-20"];

  const textColor = variant === "dark" ? colors["white-00"] : colors["dark-20"];

  return (
    <svg
      data-testid="logo-icon"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <title>intenseye</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M27.249 1.445H12.896c-6.273 0-11.358 5.085-11.358 11.358v14.352c0 6.273 5.085 11.358 11.358 11.358H27.25c6.273 0 11.358-5.085 11.358-11.358V12.803c0-6.273-5.085-11.358-11.358-11.358"
          data-testid="logo-icon-background"
          fill={backgroundColor}
        />
        <path
          fill={textColor}
          data-testid="logo-icon-foreground"
          d="M19.223 28.487h2.823V17.958h-2.823z"
        />
        <path
          d="M17.105 17.958h-2.672c0-4.197 3.415-7.613 7.613-7.613v2.672a4.947 4.947 0 0 0-4.941 4.941"
          fill={colors["red-20"]}
        />
      </g>
    </svg>
  );
};
