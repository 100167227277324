import { clsx } from "clsx";
import { forwardRef, useRef, useState, type ForwardedRef } from "react";
import { useIntl } from "react-intl";
import ReactSelect from "react-select";
import { Checkbox } from "../checkbox";
import { Icon } from "../icon";
import { Spinner } from "../spinner";
import { Text } from "../text";
import { useSelectUtils } from "./select.hooks";
import type { SelectProps, SelectBaseOption, SelectRef } from "./select.types";
import css from "./select.module.css";

const InnerSelect = <
  Option extends SelectBaseOption = SelectBaseOption,
  IsMulti extends boolean = false,
>(
  props: SelectProps<Option, IsMulti>,
  ref: ForwardedRef<SelectRef>,
) => {
  const { formatMessage: f } = useIntl();

  return (
    <BaseSelect
      ref={ref}
      labels={{
        selected: f({ id: "common.selected" }),
      }}
      placeholder={f({ id: "common.select" })}
      {...props}
    />
  );
};

export const Select = forwardRef(InnerSelect) as <
  Option extends SelectBaseOption = SelectBaseOption,
  IsMulti extends boolean = false,
>(
  props: SelectProps<Option, IsMulti> & { ref?: ForwardedRef<SelectRef> },
) => ReturnType<typeof InnerSelect>;

const InnerBaseSelect = <
  Option extends SelectBaseOption = SelectBaseOption,
  IsMulti extends boolean = false,
>(
  props: SelectProps<Option, IsMulti>,
  ref: ForwardedRef<SelectRef>,
) => {
  const {
    checkbox = false,
    className,
    classNames,
    closeMenuOnSelect,
    components,
    "data-testid": dataTestId,
    disabled = false,
    enableClearAll = false,
    error = false,
    fullWidth = false,
    hideDropdownIcon = false,
    hideSelectedOptions,
    icon,
    iconProps,
    isMulti,
    label,
    labels,
    loading,
    onChange,
    options,
    placeholder,
    shape = "contained",
    sortFn,
    value,
    ...other
  } = props;

  const [labelNode, setLabelNode] = useState<HTMLSpanElement | null>(null);
  const selectRef = useRef<HTMLDivElement>(null);

  const {
    applyPlaceholderStyles,
    clearBtnRenderer,
    computedPlaceholder,
    isChecked,
    isMenuOpen,
    menuListRenderer,
    onCheckboxChange,
    rootStyle,
    selectOptions,
    selectPortal,
    setIsMenuOpenFalse,
    setIsMenuOpenTrue,
    shouldShowClearAll,
    stopEventsOnContainer,
  } = useSelectUtils<Option, IsMulti>({
    checkbox,
    classNames,
    enableClearAll,
    icon,
    isMulti,
    label,
    labelNode,
    labels,
    onChange,
    options,
    placeholder,
    ref,
    selectRef,
    shape,
    sortFn,
    value,
  });

  if (selectPortal) {
    return (
      <div
        ref={selectRef}
        className={clsx(
          css.root,
          className,
          classNames?.root,
          css[shape],
          checkbox && css.hasCheckbox,
          disabled && css.disabled,
          error && css.error,
          fullWidth && css.fullWidth,
          hideDropdownIcon && css.hideDropdownIcon,
          shouldShowClearAll && css.shouldShowClearAll,
          icon && css.hasIcon,
          applyPlaceholderStyles && css.applyPlaceholderStyles,
          label && css.hasLabel,
          loading && css.loading,
          stopEventsOnContainer && css.stopEventsOnContainer,
        )}
        data-testid={dataTestId}
        style={rootStyle}
      >
        {icon ? (
          <div className={clsx(css.icon, classNames?.icon)}>
            <Icon inheritColor name={icon} {...iconProps} />
          </div>
        ) : null}
        {label ? (
          <Text
            ref={setLabelNode}
            className={clsx(css.label, classNames?.label)}
          >
            {label}
          </Text>
        ) : null}
        {checkbox ? (
          <Checkbox
            className={clsx(css.checkbox, classNames?.checkbox)}
            checked={isChecked}
            onChange={onCheckboxChange}
          />
        ) : null}
        <ReactSelect<Option, IsMulti>
          menuPlacement="auto"
          menuPortalTarget={selectPortal}
          {...other}
          hideSelectedOptions={isMulti ? false : hideSelectedOptions}
          placeholder={computedPlaceholder}
          className={clsx(css.container, classNames?.container)}
          classNamePrefix="select"
          closeMenuOnSelect={closeMenuOnSelect ?? !isMulti}
          isDisabled={disabled || loading}
          isMulti={isMulti}
          isOptionDisabled={(option) => Boolean(option.disabled)}
          options={selectOptions}
          onChange={onChange}
          onMenuOpen={setIsMenuOpenTrue}
          onMenuClose={setIsMenuOpenFalse}
          menuIsOpen={isMenuOpen}
          menuShouldScrollIntoView={false}
          value={value}
          controlShouldRenderValue={!isMulti}
          components={{
            ...components,
            MenuList: menuListRenderer,
          }}
        />
        {!hideDropdownIcon && !shouldShowClearAll ? (
          <div className={css.dropdownIcon}>
            <Icon
              inheritColor
              name="caret-down"
              size={shape === "rounded" ? "size-10" : "size-7"}
            />
          </div>
        ) : null}
        {clearBtnRenderer}
        {loading ? (
          <div className={clsx(css.spinner, classNames?.spinner)}>
            <Spinner />
          </div>
        ) : null}
      </div>
    );
  }

  return null;
};

export const BaseSelect = forwardRef(InnerBaseSelect) as <
  Option extends SelectBaseOption = SelectBaseOption,
  IsMulti extends boolean = false,
>(
  props: SelectProps<Option, IsMulti> & { ref?: ForwardedRef<SelectRef> },
) => ReturnType<typeof InnerBaseSelect>;
