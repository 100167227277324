import { AR_CATEGORY_PPE } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../../icon.types";

export const UseCaseReflectiveVest = ({
  color,
  oppositeColor,
}: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();
  return (
    <>
      <g clipPath="url(#clip0_1030_19304)">
        <path
          d="M11.0697 2.29989L10.4197 5.97989C10.4197 5.97989 10.4197 6.04989 10.4197 6.07989V11.1899C10.4197 11.5199 10.6897 11.7899 11.0197 11.7899C11.3497 11.7899 11.6197 11.5199 11.6197 11.1899V6.12989L12.3297 2.05989C12.5597 1.97989 12.7297 1.75989 12.7297 1.48989C12.7297 1.15989 12.4597 0.889893 12.1297 0.889893H11.8197C11.7497 0.889893 11.6797 0.899893 11.6097 0.929893C11.5897 0.929893 10.8797 1.18989 9.99974 1.18989C8.98974 1.18989 8.45974 0.959893 8.43974 0.949893H8.41974C8.37974 0.929893 8.32974 0.919893 8.27974 0.919893C8.24974 0.919893 8.22974 0.909893 8.19974 0.909893C8.19974 0.909893 8.19974 0.909893 8.18974 0.909893H7.87974C7.54974 0.909893 7.27974 1.17989 7.27974 1.50989C7.27974 1.76989 7.44974 1.99989 7.67974 2.07989L8.38974 6.14989V11.1999C8.38974 11.5299 8.65974 11.7999 8.98974 11.7999C9.31974 11.7999 9.58974 11.5299 9.58974 11.1999V6.07989C9.58974 6.07989 9.58974 6.00989 9.58974 5.97989L8.94974 2.30989C9.24974 2.35989 9.59974 2.38989 10.0197 2.38989C10.4197 2.38989 10.7797 2.34989 11.0897 2.28989L11.0697 2.29989Z"
          fill={color}
        />
        <path
          d="M8.97974 12.3999C8.64974 12.3999 8.37974 12.6699 8.37974 12.9999V17.9099H6.28974C5.07974 17.9099 4.02974 17.0099 3.84974 15.8199C3.79974 15.4899 3.48974 15.2699 3.16974 15.3199C2.83974 15.3699 2.61974 15.6699 2.66974 15.9999C2.93974 17.7799 4.48974 19.1199 6.28974 19.1199H8.97974C9.30974 19.1199 9.57974 18.8499 9.57974 18.5199V13.0099C9.57974 12.6799 9.30974 12.4099 8.97974 12.4099V12.3999Z"
          fill={color}
        />
        <path
          d="M3.24974 14.5199C3.57974 14.5199 3.84974 14.2499 3.84974 13.9199V13.0499C3.84974 12.7199 3.57974 12.4499 3.24974 12.4499C2.91974 12.4499 2.64974 12.7199 2.64974 13.0499V13.9199C2.64974 14.2499 2.91974 14.5199 3.24974 14.5199Z"
          fill={color}
        />
        <path
          d="M3.23974 11.7099C3.56974 11.7099 3.83974 11.4399 3.83974 11.1099V10.4099C3.83974 10.1299 3.88974 9.85989 3.97974 9.59989L5.38974 5.87989C5.38974 5.87989 5.41974 5.78989 5.41974 5.74989L5.92974 2.09989H6.02974C6.35974 2.09989 6.62974 1.82989 6.62974 1.49989C6.62974 1.16989 6.35974 0.899893 6.02974 0.899893H5.40974C5.10974 0.899893 4.85974 1.11989 4.81974 1.41989L4.23974 5.51989L2.84974 9.17989C2.69974 9.57989 2.61974 9.99989 2.62974 10.4199V11.1199C2.62974 11.4499 2.89974 11.7199 3.22974 11.7199L3.23974 11.7099Z"
          fill={color}
        />
        <path
          d="M16.8197 15.3099C16.4997 15.2599 16.1897 15.4899 16.1397 15.8099C15.9597 16.9999 14.9097 17.8999 13.6997 17.8999H11.6097V12.9899C11.6097 12.6599 11.3397 12.3899 11.0097 12.3899C10.6797 12.3899 10.4097 12.6599 10.4097 12.9899V18.4999C10.4097 18.8299 10.6797 19.0999 11.0097 19.0999H13.6997C15.4997 19.0999 17.0597 17.7599 17.3197 15.9799C17.3697 15.6499 17.1397 15.3499 16.8197 15.2999V15.3099Z"
          fill={color}
        />
        <path
          d="M16.7597 12.4499C16.4297 12.4499 16.1597 12.7199 16.1597 13.0499V13.9199C16.1597 14.2499 16.4197 14.5199 16.7497 14.5199C17.0797 14.5199 17.3497 14.2499 17.3497 13.9199V13.0499C17.3497 12.7199 17.0897 12.4499 16.7597 12.4499Z"
          fill={color}
        />
        <path
          d="M17.1397 9.16989L15.7497 5.50989L15.1697 1.40989C15.1297 1.10989 14.8797 0.889893 14.5797 0.889893H13.9597C13.6297 0.889893 13.3597 1.15989 13.3597 1.48989C13.3597 1.81989 13.6297 2.08989 13.9597 2.08989H14.0597L14.5697 5.73989C14.5697 5.73989 14.5897 5.82989 14.5997 5.86989L16.0097 9.58989C16.1097 9.84989 16.1597 10.1299 16.1597 10.3999V11.0999C16.1597 11.4299 16.4197 11.6999 16.7497 11.6999C17.0797 11.6999 17.3497 11.4299 17.3497 11.0999V10.3999C17.3497 9.96989 17.2797 9.55989 17.1297 9.15989L17.1397 9.16989Z"
          fill={color}
        />
        <path
          d="M9.12062 10.91H8.17062V1.28003C8.17062 1.00003 7.95062 0.780029 7.67062 0.780029H6.24062C5.96062 0.780029 5.74062 1.00003 5.74062 1.28003V10.91H2.89062C2.61062 10.91 2.39062 11.13 2.39062 11.41V12.79C2.39062 13.07 2.61062 13.29 2.89062 13.29H9.12062C9.40062 13.29 9.62062 13.07 9.62062 12.79V11.41C9.62062 11.13 9.40062 10.91 9.12062 10.91ZM6.74062 1.78003H7.17062V10.91H6.74062V1.78003ZM8.62062 12.3H3.39062V11.92H8.62062V12.3Z"
          fill={getAlertCategoryColor(AR_CATEGORY_PPE)}
        />
        <path
          d="M17.1106 10.91H14.2606V1.28003C14.2606 1.00003 14.0406 0.780029 13.7606 0.780029H12.3306C12.0506 0.780029 11.8306 1.00003 11.8306 1.28003V10.91H10.8806C10.6006 10.91 10.3806 11.13 10.3806 11.41V12.79C10.3806 13.07 10.6006 13.29 10.8806 13.29H17.1106C17.3906 13.29 17.6106 13.07 17.6106 12.79V11.41C17.6106 11.13 17.3906 10.91 17.1106 10.91ZM12.8306 1.78003H13.2606V10.91H12.8306V1.78003ZM16.6106 12.3H11.3806V11.92H16.6106V12.3Z"
          fill={getAlertCategoryColor(AR_CATEGORY_PPE)}
        />
      </g>
      <defs>
        <clipPath id="clip0_1030_19304">
          <rect width="20" height="20" fill={oppositeColor} />
        </clipPath>
      </defs>
    </>
  );
};
