import type { IconChildrenProps } from "../icon.types";

export const Camera3d = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M14.2728 8.80164V11.1984L15.5277 11.8258C15.5737 11.8488 15.6244 11.8608 15.6758 11.8608C15.8359 11.8608 15.9694 11.7473 16.0003 11.5963L16.0071 11.5296V8.47042C16.0071 8.43614 16.0017 8.40218 15.9914 8.36972L15.9721 8.3223C15.9005 8.17914 15.7392 8.11047 15.5904 8.15034L15.5277 8.17417L14.2728 8.80164ZM14.2395 12.1512C14.04 13.3897 12.9662 14.3357 11.6714 14.3357H5.60141C4.16469 14.3357 3 13.171 3 11.7343V8.26572C3 6.829 4.16469 5.66431 5.60141 5.66431H11.6714C12.9662 5.66431 14.04 6.61026 14.2395 7.84877L15.1399 7.39858C15.7319 7.1026 16.4517 7.34254 16.7477 7.9345C16.8309 8.1009 16.8742 8.28439 16.8742 8.47042V11.5296C16.8742 12.1914 16.3377 12.7279 15.6758 12.7279C15.4898 12.7279 15.3063 12.6846 15.1399 12.6014L14.2395 12.1512ZM13.4056 11.1984V11.7343L13.4009 11.8637C13.3347 12.7611 12.5856 13.4685 11.6714 13.4685H5.60141L5.47198 13.4638C4.57462 13.3976 3.86714 12.6486 3.86714 11.7343V8.26572L3.87189 8.13629C3.9381 7.23892 4.68714 6.53144 5.60141 6.53144H11.6714L11.8008 6.5362C12.6982 6.60241 13.4056 7.35144 13.4056 8.26572V8.80164V11.1984Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      d="M12.4736 3.21864C10.3293 2.41667 7.91595 3.04129 6.12964 4.68359C6.0507 4.75616 5.95049 4.8022 5.84366 4.8114C5.42973 4.84704 5.19171 4.37762 5.49512 4.0938C7.49417 2.22386 10.248 1.46046 12.7774 2.40644C13.9078 2.82924 14.8481 3.53912 15.5674 4.4381L16.4584 3.78084C16.6753 3.62088 16.9838 3.75849 17.0096 4.02672L17.2201 6.21233C17.2697 6.72732 16.7585 7.11342 16.2767 6.92481L14.1962 6.11027C13.942 6.01075 13.8971 5.67019 14.1168 5.50815L14.8656 4.95575C14.2376 4.18109 13.4283 3.57568 12.4736 3.21864Z"
      fill={color}
    />
    <path
      d="M6.29127 16.2149C6.63454 16.4426 7.00534 16.6333 7.40112 16.7814C10.1308 17.8023 13.2964 16.5114 15.0496 13.7522C15.1341 13.6192 15.2793 13.5353 15.4369 13.5352C15.7722 13.535 15.9855 13.8897 15.8069 14.1734C13.8747 17.2433 10.3054 18.7934 7.09736 17.5936C6.5686 17.3958 6.08145 17.1352 5.63906 16.8219L4.86734 17.5401C4.67009 17.7237 4.34797 17.6221 4.2918 17.3585L3.8341 15.211C3.72625 14.705 4.19028 14.2633 4.69037 14.3959L6.84999 14.9685C7.11384 15.0385 7.19722 15.3717 6.99739 15.5577L6.29127 16.2149Z"
      fill={color}
    />
  </>
);
