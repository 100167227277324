import type { IconChildrenProps } from "../icon.types";

export const Code = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M4.2356 4.23503C7.4195 1.05109 12.5817 1.05109 15.7656 4.23503C18.9495 7.41893 18.9495 12.5811 15.7656 15.765C12.5817 18.9489 7.4195 18.9489 4.2356 15.765C1.05168 12.5811 1.05168 7.41893 4.2356 4.23503ZM14.9052 4.87438C12.127 2.21378 7.71777 2.25023 4.9843 4.98373C2.21387 7.75414 2.21387 12.2459 4.9843 15.0163C7.75471 17.7867 12.2465 17.7867 15.0169 15.0163C17.7503 12.2828 17.7868 7.87361 15.1262 5.09545L15.0169 4.98373L14.9052 4.87438ZM11.0844 7.83238C10.8228 7.70162 10.5048 7.80762 10.3741 8.06914L8.67997 11.4574L8.65821 11.507C8.56307 11.7579 8.67156 12.0451 8.91673 12.1677C9.17825 12.2984 9.49625 12.1924 9.62701 11.9309L11.3211 8.54266L11.3429 8.49302C11.438 8.24212 11.3295 7.95496 11.0844 7.83238ZM13.7201 7.89295C13.5123 7.72572 13.2074 7.73858 13.0144 7.93155L12.9758 7.97453C12.8086 8.18242 12.8215 8.48728 13.0144 8.68025L14.3342 10L13.0144 11.3198L12.9758 11.3628C12.8086 11.5707 12.8215 11.8755 13.0144 12.0685C13.2212 12.2752 13.5564 12.2752 13.7631 12.0685L15.4573 10.3744L15.4958 10.3314C15.6631 10.1235 15.6502 9.81863 15.4573 9.62566L13.7631 7.93155L13.7201 7.89295ZM6.23796 7.93155C6.44471 7.7248 6.77991 7.7248 6.98666 7.93155C7.17963 8.12451 7.19249 8.42938 7.02526 8.63726L6.98666 8.68025L5.66662 10L6.98666 11.3198C7.17963 11.5127 7.19249 11.8176 7.02526 12.0255L6.98666 12.0685C6.7937 12.2614 6.48883 12.2743 6.28095 12.1071L6.23796 12.0685L4.54384 10.3744C4.35088 10.1814 4.33801 9.87654 4.50525 9.66865L4.54384 9.62566L6.23796 7.93155Z"
    fill={color}
    fillRule="evenodd"
  />
);
