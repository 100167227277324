import type { IconChildrenProps } from "../../icon.types";

export const ErgonomicsNeckPosition = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M11.5857 2.5C12.7106 2.5 13.6305 3.37818 13.6971 4.48639L13.7009 4.61524V7.69237C13.7009 8.89561 13.1193 9.96302 12.2219 10.6288L12.6507 12.575C12.7379 12.5654 12.8262 12.5649 12.9143 12.5736L13.0387 12.5922L15.2282 13.0299C16.5267 13.2895 17.4748 14.3969 17.5433 15.7065L17.5473 15.8585V16.9233C17.5473 17.2418 17.2891 17.5 16.9705 17.5C16.6786 17.5 16.4373 17.283 16.3991 17.0015L16.3938 16.9233V15.8585C16.3938 15.0767 15.871 14.3978 15.1279 14.191L15.0021 14.1609L12.8124 13.7232C12.7814 13.717 12.7492 13.7234 12.7234 13.7405C11.1029 14.8214 8.99127 14.8214 7.37128 13.7409C7.35374 13.7292 7.33355 13.7225 7.31279 13.7211L7.2815 13.7232L5.09207 14.1609C4.3254 14.3142 3.76225 14.9599 3.7051 15.7292L3.70031 15.8585V16.9233C3.70031 17.2418 3.4421 17.5 3.12359 17.5C2.83162 17.5 2.59033 17.283 2.55214 17.0015L2.54688 16.9233V15.8585C2.54688 14.5343 3.44693 13.3874 4.71771 13.0636L4.86595 13.0299L7.0552 12.5922C7.1844 12.5664 7.31555 12.5609 7.44418 12.5751L7.87246 10.6288C7.02485 10 6.45894 9.01298 6.39868 7.89161L6.39334 7.69237V4.61524C6.39334 3.4903 7.27152 2.57045 8.37973 2.50386L8.50858 2.5H11.5857ZM11.1611 11.1732C10.8099 11.2855 10.4356 11.3461 10.0472 11.3461C9.65874 11.3461 9.28446 11.2855 8.93328 11.1732L8.51743 13.0639C9.48759 13.5091 10.6066 13.509 11.5771 13.0636L11.1611 11.1732ZM11.5857 3.65343H8.50858C8.0106 3.65343 7.60099 4.03191 7.55174 4.5169L7.54677 4.61524V7.69237C7.54677 9.07325 8.66624 10.1927 10.0472 10.1927C11.3804 10.1927 12.47 9.14912 12.5436 7.83425L12.5475 7.69237V4.61524C12.5475 4.08406 12.1169 3.65343 11.5857 3.65343Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M1.71774 7.84885C1.92249 7.6441 2.24289 7.62549 2.46866 7.79301L2.53334 7.84885L4.07125 9.38676C4.276 9.5915 4.29461 9.9119 4.12709 10.1377L4.07125 10.2024L2.53334 11.7403C2.30812 11.9655 1.94297 11.9655 1.71774 11.7403C1.513 11.5355 1.49438 11.2151 1.6619 10.9894L1.71774 10.9247L2.84759 9.79417L1.71774 8.66445C1.513 8.4597 1.49438 8.13931 1.6619 7.91353L1.71774 7.84885ZM17.4679 7.84885C17.6931 7.62363 18.0582 7.62363 18.2835 7.84885C18.4882 8.05359 18.5068 8.37399 18.3393 8.59976L18.2835 8.66445L17.1525 9.79417L18.2835 10.9247C18.4882 11.1294 18.5068 11.4498 18.3393 11.6756L18.2835 11.7403C18.0787 11.945 17.7583 11.9636 17.5325 11.7961L17.4679 11.7403L15.93 10.2024C15.7252 9.99761 15.7066 9.67721 15.8741 9.45144L15.93 9.38676L17.4679 7.84885Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
