import type { IconChildrenProps } from "../icon.types";

export const Drawing = ({ color }: IconChildrenProps) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 1C1.22386 1 1 1.22386 1 1.5V4.5C1 4.77614 1.22386 5 1.5 5H2.25V15H1.5C1.22386 15 1 15.2239 1 15.5V18.5C1 18.7761 1.22386 19 1.5 19H4.5C4.77614 19 5 18.7761 5 18.5V17.75H10.5V18.25C10.5 18.6642 10.8358 19 11.25 19C11.6642 19 12 18.6642 12 18.25V1.75C12 1.33579 11.6642 1 11.25 1C10.8358 1 10.5 1.33579 10.5 1.75V2.25H5V1.5C5 1.22386 4.77614 1 4.5 1H1.5ZM10.5 3.75H5V4.5C5 4.77614 4.77614 5 4.5 5H3.75V15H4.5C4.77614 15 5 15.2239 5 15.5V16.25H10.5V3.75Z"
      fill={color}
    />
    <path
      d="M12.625 3C12.625 2.79289 12.7929 2.625 13 2.625H14C14.2071 2.625 14.375 2.79289 14.375 3C14.375 3.20711 14.2071 3.375 14 3.375H13C12.7929 3.375 12.625 3.20711 12.625 3Z"
      fill={color}
    />
    <path
      d="M15.625 3C15.625 2.79289 15.7929 2.625 16 2.625H17C17.2071 2.625 17.375 2.79289 17.375 3V3.875C17.375 4.08211 17.2071 4.25 17 4.25C16.7929 4.25 16.625 4.08211 16.625 3.875V3.375H16C15.7929 3.375 15.625 3.20711 15.625 3Z"
      fill={color}
    />
    <path
      d="M17 5.25C17.2071 5.25 17.375 5.41789 17.375 5.625V7.375C17.375 7.58211 17.2071 7.75 17 7.75C16.7929 7.75 16.625 7.58211 16.625 7.375V5.625C16.625 5.41789 16.7929 5.25 17 5.25Z"
      fill={color}
    />
    <path
      d="M17 8.75C17.2071 8.75 17.375 8.91789 17.375 9.125V10.875C17.375 11.0821 17.2071 11.25 17 11.25C16.7929 11.25 16.625 11.0821 16.625 10.875V9.125C16.625 8.91789 16.7929 8.75 17 8.75Z"
      fill={color}
    />
    <path
      d="M17 12.25C17.2071 12.25 17.375 12.4179 17.375 12.625V14.375C17.375 14.5821 17.2071 14.75 17 14.75C16.7929 14.75 16.625 14.5821 16.625 14.375V12.625C16.625 12.4179 16.7929 12.25 17 12.25Z"
      fill={color}
    />
    <path
      d="M17 15.75C17.2071 15.75 17.375 15.9179 17.375 16.125V17C17.375 17.2071 17.2071 17.375 17 17.375H16C15.7929 17.375 15.625 17.2071 15.625 17C15.625 16.7929 15.7929 16.625 16 16.625H16.625V16.125C16.625 15.9179 16.7929 15.75 17 15.75Z"
      fill={color}
    />
    <path
      d="M12.625 17C12.625 16.7929 12.7929 16.625 13 16.625H14C14.2071 16.625 14.375 16.7929 14.375 17C14.375 17.2071 14.2071 17.375 14 17.375H13C12.7929 17.375 12.625 17.2071 12.625 17Z"
      fill={color}
    />
    <rect x="3" y="3" width="8" height="14" fill={color} fillOpacity="0.3" />
  </>
);
