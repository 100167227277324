import type { IconChildrenProps } from "../icon.types";

export const Alerts = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M5.67459 11.0796L8.09541 6.77679C8.9296 5.28991 11.069 5.28991 11.9051 6.77621L14.3262 11.0796C15.146 12.5366 14.0932 14.3376 12.4212 14.3376H7.57962C5.9075 14.3376 4.85495 12.5375 5.67459 11.0796ZM10.5907 8.56223C10.5512 8.27125 10.3018 8.04697 10 8.04697C9.67075 8.04697 9.40385 8.31388 9.40385 8.64312V10.1017L9.40929 10.1826C9.44876 10.4736 9.69819 10.6979 10 10.6979C10.3292 10.6979 10.5962 10.431 10.5962 10.1017V8.64312L10.5907 8.56223ZM9.99921 11.1144L10.0833 11.1196C10.4138 11.161 10.6677 11.4429 10.6677 11.7829C10.6677 12.15 10.3709 12.4514 10 12.4514C9.63101 12.4514 9.33151 12.1519 9.33163 11.7948C9.32471 11.4485 9.58231 11.1618 9.91467 11.1197L9.99921 11.1144ZM13.2871 11.6642L10.8659 7.36075C10.4858 6.685 9.51404 6.685 9.1349 7.3608L6.71381 11.6641C6.34104 12.3271 6.81943 13.1453 7.57962 13.1453H12.4212C13.1812 13.1453 13.6597 12.3266 13.2871 11.6642Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.7692 2.25H5.23077C3.58442 2.25 2.25 3.58442 2.25 5.23077V14.7692C2.25 16.4156 3.58442 17.75 5.23077 17.75H14.7692C16.4156 17.75 17.75 16.4156 17.75 14.7692V5.23077C17.75 3.58442 16.4156 2.25 14.7692 2.25ZM5.23077 3.44231H14.7692C15.7571 3.44231 16.5577 4.24291 16.5577 5.23077V14.7692C16.5577 15.7571 15.7571 16.5577 14.7692 16.5577H5.23077C4.24291 16.5577 3.44231 15.7571 3.44231 14.7692V5.23077C3.44231 4.24291 4.24291 3.44231 5.23077 3.44231Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
