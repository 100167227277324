import type { IconChildrenProps } from "../icon.types";

export const Eye = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M6.71824 2.37646C6.71824 2.08407 6.48122 1.84705 6.18883 1.84705H4.28295L4.18952 1.8488C2.88756 1.89788 1.84766 2.96835 1.84766 4.28234V6.18822L1.85039 6.24235C1.8775 6.50931 2.10296 6.71763 2.37707 6.71763C2.66945 6.71763 2.90648 6.48061 2.90648 6.18822V4.28234L2.90866 4.2042C2.94913 3.48008 3.54867 2.90587 4.28295 2.90587H6.18883L6.24296 2.90314C6.50992 2.87602 6.71824 2.65057 6.71824 2.37646ZM2.90375 13.7576C2.87664 13.4907 2.65118 13.2823 2.37707 13.2823C2.08468 13.2823 1.84766 13.5194 1.84766 13.8118V15.7176L1.84941 15.8111C1.89849 17.113 2.96896 18.1529 4.28295 18.1529H6.18883L6.24296 18.1502C6.50992 18.1231 6.71824 17.8976 6.71824 17.6235C6.71824 17.3311 6.48122 17.0941 6.18883 17.0941H4.28295L4.20481 17.0919C3.48069 17.0515 2.90648 16.4519 2.90648 15.7176V13.8118L2.90375 13.7576ZM17.6241 13.2823C17.8982 13.2823 18.1237 13.4907 18.1508 13.7576L18.1535 13.8118V15.7176C18.1535 17.0316 17.1136 18.1021 15.8117 18.1512L15.7182 18.1529H13.8124C13.52 18.1529 13.283 17.9159 13.283 17.6235C13.283 17.3494 13.4913 17.1239 13.7582 17.0968L13.8124 17.0941H15.7182C16.4525 17.0941 17.0521 16.5199 17.0925 15.7958L17.0947 15.7176V13.8118C17.0947 13.5194 17.3317 13.2823 17.6241 13.2823ZM18.1518 4.18891C18.1027 2.88695 17.0322 1.84705 15.7182 1.84705H13.8124L13.7582 1.84978C13.4913 1.87689 13.283 2.10235 13.283 2.37646C13.283 2.66884 13.52 2.90587 13.8124 2.90587H15.7182L15.7964 2.90805C16.5205 2.94852 17.0947 3.54806 17.0947 4.28234V6.18822L17.0974 6.24235C17.1246 6.50931 17.35 6.71763 17.6241 6.71763C17.9165 6.71763 18.1535 6.48061 18.1535 6.18822V4.28234L18.1518 4.18891Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M4.5191 9.48489C5.75783 7.19212 7.92444 5.51733 10.0003 5.51733C12.076 5.51733 14.2426 7.19184 15.4813 9.48369C15.6555 9.80541 15.6555 10.1953 15.4815 10.5151C14.2428 12.8078 12.0762 14.4826 10.0003 14.4826C7.92464 14.4826 5.75805 12.8081 4.51929 10.5162C4.34516 10.1945 4.34516 9.8046 4.5191 9.48489ZM14.55 9.98742C13.4787 8.00536 11.6295 6.57616 10.0003 6.57616C8.37099 6.57616 6.52182 8.00556 5.44993 9.98954C5.44672 9.99544 5.44672 10.0053 5.45062 10.0125C6.52193 11.9946 8.37113 13.4238 10.0003 13.4238C11.6296 13.4238 13.4788 11.9944 14.5507 10.0104C14.5539 10.0045 14.5539 9.99462 14.55 9.98742Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11.4226 8.57744C10.637 7.79181 9.36316 7.79181 8.57753 8.57744C7.79186 9.36311 7.79186 10.6369 8.57753 11.4225C9.36316 12.2082 10.637 12.2082 11.4226 11.4225C12.2082 10.6369 12.2082 9.36312 11.4226 8.57744ZM9.32624 9.32614C9.68065 8.97173 10.2448 8.95485 10.6192 9.27551L10.6739 9.32613L10.7245 9.38082C11.0452 9.75525 11.0283 10.3194 10.6739 10.6738C10.3017 11.046 9.69837 11.046 9.32624 10.6738C8.95406 10.3017 8.95406 9.69832 9.32624 9.32614Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
