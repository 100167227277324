import type { IconChildrenProps } from "../icon.types";

export const Accuracy1 = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M12.8755 5.01736C13.4896 5.01736 13.9948 5.48414 14.0555 6.08221L14.0616 6.20348L14.0616 6.79243C14.0633 7.14874 13.9049 7.48429 13.6364 7.70825L13.5307 7.78682L12.2783 8.61963V9.5418L13.5318 10.3765C13.8255 10.5727 14.0151 10.8876 14.0541 11.2351L14.0616 11.3668V11.959C14.0616 12.5731 13.5949 13.0783 12.9968 13.1391L12.8755 13.1452H10.7121V15.2203C10.7121 15.6133 10.3935 15.9318 10.0005 15.9318C9.64032 15.9318 9.34261 15.6641 9.29549 15.3168L9.289 15.2203V13.1452H7.12559C6.51147 13.1452 6.00634 12.6784 5.9456 12.0803L5.93947 11.959L5.93948 11.3701C5.93781 11.0138 6.09626 10.678 6.36467 10.4542L6.47038 10.3758L7.72149 9.5418V8.62342L6.46931 7.79084C6.17559 7.5946 5.98597 7.27974 5.94695 6.9322L5.93947 6.80052V6.20348C5.93947 5.58939 6.40626 5.08423 7.00432 5.02349L7.12559 5.01736H12.8755ZM12.6378 6.44038H7.36192V6.67566L8.8277 7.65078C8.99763 7.76385 9.10942 7.94336 9.1379 8.1423L9.14507 8.24317V9.92405C9.14507 10.1281 9.05756 10.3207 8.90773 10.4546L8.82773 10.5164L7.36192 11.4915V11.722H12.6378V11.4905L11.1734 10.5164C11.0035 10.4033 10.8917 10.2238 10.8632 10.0249L10.856 9.92405V8.23842C10.856 8.03431 10.9435 7.8418 11.0934 7.70789L11.1734 7.64603L12.6378 6.67092V6.44038Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M13.7984 0.746246H6.20134C3.18849 0.746246 0.746094 3.18864 0.746094 6.20149V13.7985C0.746094 16.8114 3.18849 19.2537 6.20134 19.2537H13.7984C16.8112 19.2537 19.2536 16.8114 19.2536 13.7985V6.20149C19.2536 3.18864 16.8112 0.746246 13.7984 0.746246ZM6.20134 2.16935H13.7984C16.0252 2.16935 17.8305 3.9746 17.8305 6.20149V13.7985C17.8305 16.0254 16.0252 17.8306 13.7984 17.8306H6.20134C3.97445 17.8306 2.1692 16.0254 2.1692 13.7985V6.20149C2.1692 3.9746 3.97445 2.16935 6.20134 2.16935Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
