import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { initialThemeContextValue, ThemeContext } from "./theme.context";
import { ThemeStyle } from "./theme.style";
import {
  type ThemeContextValue,
  ThemeMode,
  type ThemeProviderProps,
} from "./theme.types";

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [mode, setMode] = useState<ThemeMode>(initialThemeContextValue.mode);

  const onModeChange = useCallback((mode: ThemeMode) => setMode(mode), []);

  const value = useMemo<ThemeContextValue>(
    () => ({
      ...initialThemeContextValue,
      mode,
      setMode: onModeChange,
    }),
    [mode, onModeChange],
  );

  useLayoutEffect(() => {
    for (const value in ThemeMode) {
      document.body.classList.toggle(value, value === (mode as string));
    }
  }, [mode]);

  return (
    <ThemeContext.Provider value={value}>
      <ThemeStyle />
      {children}
    </ThemeContext.Provider>
  );
};
