import type { IconChildrenProps } from "../icon.types";

export const All = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M6.5 5C6.5 5.82843 5.82843 6.5 5 6.5C4.17157 6.5 3.5 5.82843 3.5 5C3.5 4.17157 4.17157 3.5 5 3.5C5.82843 3.5 6.5 4.17157 6.5 5ZM11.5 5C11.5 5.82843 10.8284 6.5 10 6.5C9.17157 6.5 8.5 5.82843 8.5 5C8.5 4.17157 9.17157 3.5 10 3.5C10.8284 3.5 11.5 4.17157 11.5 5ZM15 6.5C15.8284 6.5 16.5 5.82843 16.5 5C16.5 4.17157 15.8284 3.5 15 3.5C14.1716 3.5 13.5 4.17157 13.5 5C13.5 5.82843 14.1716 6.5 15 6.5ZM11.5 10C11.5 10.8284 10.8284 11.5 10 11.5C9.17157 11.5 8.5 10.8284 8.5 10C8.5 9.17157 9.17157 8.5 10 8.5C10.8284 8.5 11.5 9.17157 11.5 10ZM10 16.5C10.8284 16.5 11.5 15.8284 11.5 15C11.5 14.1716 10.8284 13.5 10 13.5C9.17157 13.5 8.5 14.1716 8.5 15C8.5 15.8284 9.17157 16.5 10 16.5ZM16.5 10C16.5 10.8284 15.8284 11.5 15 11.5C14.1716 11.5 13.5 10.8284 13.5 10C13.5 9.17157 14.1716 8.5 15 8.5C15.8284 8.5 16.5 9.17157 16.5 10ZM15 16.5C15.8284 16.5 16.5 15.8284 16.5 15C16.5 14.1716 15.8284 13.5 15 13.5C14.1716 13.5 13.5 14.1716 13.5 15C13.5 15.8284 14.1716 16.5 15 16.5ZM6.5 10C6.5 10.8284 5.82843 11.5 5 11.5C4.17157 11.5 3.5 10.8284 3.5 10C3.5 9.17157 4.17157 8.5 5 8.5C5.82843 8.5 6.5 9.17157 6.5 10ZM5 16.5C5.82843 16.5 6.5 15.8284 6.5 15C6.5 14.1716 5.82843 13.5 5 13.5C4.17157 13.5 3.5 14.1716 3.5 15C3.5 15.8284 4.17157 16.5 5 16.5Z"
    fill={color}
    fillRule="evenodd"
  />
);
