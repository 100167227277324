import type { IconChildrenProps } from "../icon.types";

export const Goal = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M15.4679 1.42898C15.3931 1.27921 15.2508 1.17456 15.0855 1.14773C14.9202 1.1209 14.7521 1.17515 14.6338 1.29355L11.299 4.62827C11.2014 4.72594 11.1465 4.85842 11.1465 4.99655V8.11931L9.24239 10.0234C9.03899 10.2268 9.03899 10.5566 9.24239 10.76C9.44579 10.9634 9.77556 10.9634 9.97896 10.76L11.8868 8.85211H15.002C15.1402 8.85211 15.2726 8.79724 15.3703 8.69956L18.705 5.36484C18.8234 5.24645 18.8777 5.07835 18.8509 4.91309C18.824 4.74782 18.7194 4.60553 18.5696 4.53067L16.5016 3.49703L15.4679 1.42898ZM12.1882 7.81044V5.21229L14.8593 2.54116L15.6475 4.11811C15.6979 4.21896 15.7796 4.30073 15.8805 4.35114L17.4574 5.13932L14.7863 7.81044H12.1882Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.20106 3.82879C9.48817 3.81117 9.70663 3.56414 9.68901 3.27703C9.67139 2.98992 9.42436 2.77146 9.13725 2.78908C5.05548 3.03961 1.89839 6.46601 1.9821 10.5546C2.0658 14.6432 5.36048 17.9375 9.44909 18.0208C13.5377 18.104 16.9637 14.9465 17.2138 10.8648C17.2314 10.5776 17.0129 10.3306 16.7258 10.313C16.4387 10.2955 16.1917 10.5139 16.1741 10.8011C15.9582 14.3251 13.0003 17.0512 9.47029 16.9793C5.94033 16.9074 3.09581 14.0632 3.02354 10.5333C2.95128 7.00333 5.677 4.04509 9.20106 3.82879ZM9.22371 7.00702C9.50966 6.97579 9.71615 6.71867 9.68493 6.43272C9.6537 6.14677 9.39658 5.94028 9.11063 5.97151C6.78664 6.22529 5.053 8.23052 5.13774 10.5668C5.22249 12.9031 7.09685 14.7774 9.43312 14.8622C11.7694 14.9469 13.7746 13.2133 14.0284 10.8893C14.0596 10.6033 13.8531 10.3462 13.5672 10.315C13.2812 10.2838 13.0241 10.4902 12.9929 10.7762C12.7984 12.5574 11.2615 13.8861 9.47088 13.8212C7.68026 13.7562 6.24368 12.3196 6.17873 10.529C6.11378 8.73842 7.44251 7.20153 9.22371 7.00702Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
