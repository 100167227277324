import { useOutletContext } from "react-router-dom";
import { defaultAppName, defaultAppUrl } from "~/features/auth/auth.constants";
import type {
  CallbackOrSignupOutletContext,
  UseGuardContextReturnType,
} from "./guard-route.types";

export const useGuardContext = (): UseGuardContextReturnType => {
  const context = useOutletContext<CallbackOrSignupOutletContext>();
  return {
    ...context,
    appName: context.appName || defaultAppName,
    appUrl: context.appUrl || defaultAppUrl,
  };
};
