import type { IconChildrenProps } from "../icon.types";

export const Cross = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M15.749 5.46314C16.0837 5.12844 16.0837 4.58572 15.749 4.25102C15.4143 3.91633 14.8716 3.91633 14.5369 4.25102L10 8.78784L5.46314 4.25102C5.12844 3.91633 4.58572 3.91633 4.25102 4.25102C3.91633 4.58572 3.91633 5.12844 4.25102 5.46314L8.78784 10L4.25102 14.5369C3.91633 14.8716 3.91633 15.4143 4.25102 15.749C4.58572 16.0837 5.12844 16.0837 5.46314 15.749L10 11.2122L14.5369 15.749C14.8716 16.0837 15.4143 16.0837 15.749 15.749C16.0837 15.4143 16.0837 14.8716 15.749 14.5369L11.2122 10L15.749 5.46314Z"
    fill={color}
    fillRule="evenodd"
  />
);
