export const UseCaseInactive = () => {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform="translate(0, 5)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.5C0 3.63401 3.13401 0.5 7 0.5H16C19.866 0.5 23 3.63401 23 7.5C23 11.366 19.866 14.5 16 14.5H7C3.13401 14.5 0 11.366 0 7.5Z"
        fill="#DFDFE5"
      />
      <ellipse
        cx="6.9698"
        cy="7.4999"
        rx="5.57576"
        ry="5.6"
        fill="#F5F4ED"
        transform="translate(0, 5)"
      />
    </svg>
  );
};
