import type { IconChildrenProps } from "../icon.types";

export const TaskParent = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M16 4C17.1046 4 18 4.89543 18 6C18 7.10457 17.1046 8 16 8H4.47854C4.47854 9.40726 5.57548 10.5583 6.96095 10.6448L7.12854 10.65H8.04105C8.19871 9.99039 8.79209 9.5 9.5 9.5H16.5C17.3284 9.5 18 10.1716 18 11C18 11.8284 17.3284 12.5 16.5 12.5H9.5C8.79209 12.5 8.19871 12.0096 8.04105 11.35H7.12854C6.05045 11.35 5.09131 10.8407 4.47854 10.0497V12C4.47854 13.4073 5.57548 14.5583 6.96095 14.6448L7.12854 14.65H8.04105C8.19871 13.9904 8.79209 13.5 9.5 13.5H16.5C17.3284 13.5 18 14.1716 18 15C18 15.8284 17.3284 16.5 16.5 16.5H9.5C8.79209 16.5 8.19871 16.0096 8.04105 15.35H7.12854C5.34006 15.35 3.87892 13.9485 3.7835 12.1838L3.77854 12V8V7.98788C2.77819 7.87768 2 7.02972 2 6C2 4.89543 2.89543 4 4 4H16ZM9.5 10.2H16.5L16.6004 10.2062C16.9948 10.2556 17.3 10.5922 17.3 11C17.3 11.4418 16.9418 11.8 16.5 11.8H9.5L9.39965 11.7938C9.00519 11.7444 8.7 11.4078 8.7 11C8.7 10.5582 9.05817 10.2 9.5 10.2ZM16.5 14.2H9.5C9.05817 14.2 8.7 14.5582 8.7 15C8.7 15.4078 9.00519 15.7444 9.39965 15.7938L9.5 15.8H16.5C16.9418 15.8 17.3 15.4418 17.3 15C17.3 14.5922 16.9948 14.2556 16.6004 14.2062L16.5 14.2Z"
    fill={color}
    fillRule="evenodd"
  />
);
