import type { ReactNode } from "react";
import type { colors } from "./theme.colors";
import type { shadows } from "./theme.shadows";
import type { radius, sizes, spacings } from "./theme.sizes";
import type { fontSizes, fontWeights } from "./theme.typography";
import type { themeVariantLight } from "./variant/light/theme-variant.light";

export type ThemeColorName = keyof typeof colors;
export type ThemeFontSizeName = keyof typeof fontSizes;
export type ThemeFontWeightName = keyof typeof fontWeights;
export type ThemeShadowName = keyof typeof shadows;
export type ThemeSizeName = keyof typeof sizes;
export type ThemePaletteVariableName =
  keyof (typeof themeVariantLight)["palette"];
export type ThemeSpacingName = keyof typeof spacings;
export type ThemeBorderRadiusName = keyof typeof radius;

export enum ThemeMode {
  dark = "dark",
  light = "light",
}

export type ThemeColors = typeof colors;
export type ThemeFontSizes = typeof fontSizes;
export type ThemeFontWeights = typeof fontWeights;
export type ThemeShadows = typeof shadows;
export type ThemeSizes = typeof sizes;
export type ThemePaletteVariables = typeof themeVariantLight.palette;
export type ThemeSpacings = typeof spacings;
export type ThemeBorderRadius = typeof radius;

export type ThemeContextValue = {
  colors: ThemeColors;
  fontSizes: ThemeFontSizes;
  fontWeights: ThemeFontWeights;
  mode: ThemeMode;
  setMode: (mode: ThemeMode) => void;
  shadows: ThemeShadows;
  sizes: ThemeSizes;
  palette: ThemePaletteVariables;
  spacings: ThemeSpacings;
  borderRadius: ThemeBorderRadius;
};

export type ThemeProviderProps = {
  children?: ReactNode;
};

export type ThemeTypographyStyleProps = {
  backgroundColor?: ThemeColorName;
  color?: ThemeColorName;
  fontSize?: ThemeFontSizeName;
  fontWeight?: ThemeFontWeightName;
};
