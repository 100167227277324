import type { IconChildrenProps } from "../../icon.types";

export const SolutionsMotorVehicles = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M0.832682 7.56036V14.227"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M9.9987 2.56036V5.8937"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M7.5 2.56034H12.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      clipRule="evenodd"
      d="M16.6673 7.56047V9.22713H15.0007L13.334 5.8938H6.66732L5.00065 7.56047H3.33398V14.2271H5.83398L7.27065 17.0996C7.41148 17.3821 7.69982 17.5605 8.01565 17.5605H13.334C13.794 17.5605 14.1673 17.1871 14.1673 16.7271V14.2271H16.6673V15.8938H18.334C18.794 15.8938 19.1673 15.5205 19.1673 15.0605V8.3938C19.1673 7.9338 18.794 7.56047 18.334 7.56047H16.6673Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M3.33398 10.8937H0.833984"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
  </>
);
