import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "~/features/theme/theme.provider";
import { UiProvider } from "~/features/ui/ui.provider";
import type { AppProviderProps } from "./app.types";

export const AppProvider = ({ children }: AppProviderProps) => (
  <HelmetProvider>
    <UiProvider>
      <ThemeProvider>
        <BrowserRouter>{children}</BrowserRouter>
      </ThemeProvider>
    </UiProvider>
  </HelmetProvider>
);
