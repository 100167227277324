import type { IconChildrenProps } from "../icon.types";

export const Task = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M18 6C18 4.89543 17.1046 4 16 4H4C2.89543 4 2 4.89543 2 6C2 7.02972 2.77819 7.87768 3.77854 7.98788V8V12L3.7835 12.1838C3.87892 13.9485 5.34006 15.35 7.12854 15.35H8.04105C8.19871 16.0096 8.79209 16.5 9.5 16.5H16.5C17.3284 16.5 18 15.8284 18 15C18 14.1716 17.3284 13.5 16.5 13.5H9.5C8.79209 13.5 8.19871 13.9904 8.04105 14.65H7.12854L6.96095 14.6448C5.57548 14.5583 4.47854 13.4073 4.47854 12V10.0497C5.09131 10.8407 6.05045 11.35 7.12854 11.35H8.04105C8.19871 12.0096 8.79209 12.5 9.5 12.5H16.5C17.3284 12.5 18 11.8284 18 11C18 10.1716 17.3284 9.5 16.5 9.5H9.5C8.79209 9.5 8.19871 9.99039 8.04105 10.65H7.12854L6.96095 10.6448C5.57548 10.5583 4.47854 9.40726 4.47854 8H16C17.1046 8 18 7.10457 18 6ZM4 4.7H16L16.1329 4.70671C16.7885 4.77328 17.3 5.3269 17.3 6C17.3 6.71797 16.718 7.3 16 7.3H4L3.86708 7.29329C3.21155 7.22672 2.7 6.6731 2.7 6C2.7 5.28203 3.28203 4.7 4 4.7ZM9.5 10.2H16.5L16.6004 10.2062C16.9948 10.2556 17.3 10.5922 17.3 11C17.3 11.4418 16.9418 11.8 16.5 11.8H9.5L9.39965 11.7938C9.00519 11.7444 8.7 11.4078 8.7 11C8.7 10.5582 9.05817 10.2 9.5 10.2ZM16.5 14.2H9.5C9.05817 14.2 8.7 14.5582 8.7 15C8.7 15.4078 9.00519 15.7444 9.39965 15.7938L9.5 15.8H16.5C16.9418 15.8 17.3 15.4418 17.3 15C17.3 14.5922 16.9948 14.2556 16.6004 14.2062L16.5 14.2Z"
    fill={color}
    fillRule="evenodd"
  />
);
