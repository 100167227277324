import type { IconChildrenProps } from "../../icon.types";

export const ErgonomicsBodyActivity = ({
  accentColor,
  color,
}: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M9.96378 1.46288C9.15535 1.46288 8.49999 2.11824 8.49999 2.92667C8.49999 3.7351 9.15535 4.39046 9.96378 4.39046C10.7722 4.39046 11.4276 3.7351 11.4276 2.92667C11.4276 2.11824 10.7722 1.46288 9.96378 1.46288ZM7.03711 2.92667C7.03711 1.31032 8.34742 0 9.96378 0C11.5801 0 12.8905 1.31032 12.8905 2.92667C12.8905 4.54303 11.5801 5.85334 9.96378 5.85334C8.34742 5.85334 7.03711 4.54303 7.03711 2.92667Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      d="M6.49902 10.9753C6.09505 10.9753 5.76758 11.3028 5.76758 11.7067V12.8926C5.76758 13.4621 6.0523 13.9966 6.52796 14.3133L7.23107 14.7818V18.2929C7.23107 19.2354 7.99564 20 8.93816 20H10.8895C11.832 20 12.5966 19.2354 12.5966 18.2929V14.7818L13.3001 14.3131C13.7757 13.9964 14.0601 13.4621 14.0601 12.8926V11.7067C14.0601 11.3028 13.7326 10.9753 13.3286 10.9753V10.9753C12.9247 10.9753 12.5972 11.3028 12.5972 11.7067V12.8926C12.5972 12.9748 12.5554 13.0514 12.4889 13.0957L11.4595 13.7816C11.256 13.9172 11.1337 14.1457 11.1337 14.3903V18.2929C11.1337 18.4275 11.0241 18.5371 10.8895 18.5371H8.93816C8.80356 18.5371 8.69394 18.4275 8.69394 18.2929V14.3903C8.69394 14.1457 8.57166 13.9172 8.3681 13.7816L7.33841 13.0955C7.27183 13.0511 7.23046 12.9748 7.23046 12.8926V11.7067C7.23046 11.3028 6.90298 10.9753 6.49902 10.9753V10.9753Z"
      fill={color}
    />
    <path
      clipRule="evenodd"
      d="M5.10461 11.0411C5.17064 11.4397 5.54725 11.7092 5.94578 11.6432L7.37334 11.4066C7.57446 11.3733 7.75254 11.2576 7.86466 11.0873C7.97678 10.9171 8.01275 10.7077 7.96391 10.5098L7.57693 8.94154C7.48016 8.54934 7.08376 8.30985 6.69157 8.40663C6.42432 8.47258 6.22798 8.67763 6.16062 8.92569C5.71769 8.54836 5.35086 8.19282 5.07201 7.87358C4.80688 7.57007 4.64778 7.32917 4.56933 7.1579C4.67583 7.1476 4.8263 7.1476 5.02735 7.16936C5.58637 7.22987 6.36973 7.43898 7.31066 7.80026C7.68778 7.94506 8.11088 7.75673 8.25568 7.37961C8.40048 7.00249 8.21215 6.57939 7.83503 6.43459C6.83777 6.05168 5.92146 5.79472 5.18477 5.71498C4.81897 5.67539 4.4546 5.67443 4.13061 5.74969C3.80676 5.82491 3.43632 5.9995 3.21747 6.37907C2.94847 6.84562 3.05984 7.3488 3.212 7.70533C3.37138 8.07876 3.64369 8.4621 3.97027 8.83597C4.36453 9.28733 4.88123 9.77356 5.49039 10.2716C5.21601 10.4132 5.05141 10.7201 5.10461 11.0411ZM13.419 13.9447C13.307 14.3328 13.5309 14.7382 13.9191 14.8502C14.6073 15.0487 15.2475 15.1687 15.7783 15.1659C16.2494 15.1635 16.9251 15.0598 17.2615 14.4763C17.5643 13.9512 17.3894 13.3818 17.1977 12.9989C16.9896 12.5832 16.6461 12.1463 16.2302 11.7126C15.9506 11.421 15.4876 11.4113 15.196 11.6908C14.9045 11.9704 14.8947 12.4334 15.1743 12.725C15.544 13.1106 15.7753 13.4254 15.8896 13.6538C15.8973 13.6692 15.9042 13.6836 15.9104 13.697C15.8725 13.7004 15.8264 13.7028 15.7706 13.703C15.44 13.7048 14.9525 13.6257 14.3245 13.4446C13.9363 13.3326 13.5309 13.5565 13.419 13.9447Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.8698 11.0411C14.8038 11.4396 14.4272 11.7092 14.0286 11.6431L12.6011 11.4066C12.4 11.3733 12.2219 11.2575 12.1098 11.0873C11.9976 10.917 11.9617 10.7077 12.0105 10.5098L12.3975 8.94149C12.4943 8.5493 12.8907 8.30981 13.2829 8.40659C13.5501 8.47253 13.7464 8.67759 13.8138 8.92564C14.2567 8.54831 14.6236 8.19278 14.9024 7.87354C15.1675 7.57002 15.3266 7.32912 15.4051 7.15786C15.2662 7.14437 15.0616 7.14931 14.7829 7.19043C14.1158 7.28886 13.1941 7.56802 12.1134 8.02155C11.7409 8.17787 11.3122 8.00263 11.1559 7.63014C10.9996 7.25765 11.1748 6.82896 11.5473 6.67264C12.6848 6.19528 13.7329 5.86664 14.5694 5.74322C14.9841 5.68203 15.3906 5.66439 15.747 5.72959C16.0971 5.79362 16.5169 5.9627 16.757 6.37903C17.026 6.84558 16.9146 7.34876 16.7624 7.70529C16.603 8.07871 16.3307 8.46206 16.0042 8.83592C15.6099 9.28728 15.0932 9.77351 14.484 10.2715C14.7584 10.4132 14.923 10.72 14.8698 11.0411ZM6.55542 13.9446C6.66737 14.3328 6.44347 14.7382 6.05533 14.8501C5.36713 15.0486 4.72688 15.1686 4.19616 15.1659C3.72506 15.1634 3.04936 15.0598 2.71293 14.4763C2.37453 13.8894 2.6273 13.2482 2.86405 12.8366C3.13119 12.3721 3.56139 11.8735 4.08533 11.3724C4.37725 11.0932 4.84026 11.1035 5.11948 11.3954C5.3987 11.6873 5.3884 12.1503 5.09647 12.4295C4.61792 12.8873 4.2997 13.2746 4.13216 13.5659C4.10258 13.6174 4.08037 13.6608 4.06369 13.6969C4.10163 13.7003 4.14787 13.7027 4.20377 13.703C4.53439 13.7047 5.02191 13.6257 5.64993 13.4445C6.03807 13.3326 6.44348 13.5565 6.55542 13.9446Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
