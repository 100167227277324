import type { IconChildrenProps } from "../icon.types";

export const CaretLeftDouble = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M15.4864 13.8208C15.4864 14.0412 15.3987 14.2526 15.2427 14.4083C14.9177 14.7333 14.3927 14.7333 14.076 14.4166L10.251 10.5916C10.095 10.4359 10.0073 10.2246 10.0073 10.0041C10.0073 9.78371 10.095 9.57234 10.251 9.41664L14.076 5.59164C14.2859 5.38174 14.5918 5.29977 14.8785 5.3766C15.1653 5.45342 15.3892 5.67738 15.466 5.9641C15.5429 6.25082 15.4609 6.55674 15.251 6.76664L12.0177 9.99997L15.2427 13.2333C15.3987 13.389 15.4864 13.6004 15.4864 13.8208ZM9.9947 13.8208C9.9947 14.0412 9.90702 14.2526 9.751 14.4083C9.426 14.7333 8.90099 14.7333 8.58433 14.4166L4.75933 10.5916C4.6033 10.4359 4.51562 10.2246 4.51562 10.0041C4.51562 9.78371 4.6033 9.57234 4.75933 9.41664L8.58433 5.59164C8.9088 5.26717 9.43486 5.26717 9.75933 5.59164C10.0838 5.9161 10.0838 6.44217 9.75933 6.76664L6.526 9.99997L9.751 13.2333C9.90702 13.389 9.9947 13.6004 9.9947 13.8208Z"
    fill={color}
    fillRule="evenodd"
  />
);
