import type { IconChildrenProps } from "../icon.types";

export const ZoomOut = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M3.06836 9.25482C3.06836 5.83925 5.83723 3.07037 9.25281 3.07037C12.6684 3.07037 15.4373 5.83925 15.4373 9.25482C15.4373 10.748 14.9081 12.1176 14.027 13.1863L16.7567 15.916C16.9886 16.1479 16.9886 16.5238 16.7567 16.7557C16.5506 16.9618 16.2307 16.9847 15.9993 16.8244L15.917 16.7557L13.1876 14.0263C12.1185 14.909 10.7476 15.4393 9.25281 15.4393C5.83723 15.4393 3.06836 12.6704 3.06836 9.25482ZM14.2498 9.25482C14.2498 6.49508 12.0125 4.25787 9.25281 4.25787C6.49307 4.25787 4.25586 6.49508 4.25586 9.25482C4.25586 12.0146 6.49307 14.2518 9.25281 14.2518C12.0125 14.2518 14.2498 12.0146 14.2498 9.25482ZM5.94922 9.2C5.94922 8.99289 6.11711 8.825 6.32422 8.825H12.0742C12.2813 8.825 12.4492 8.99289 12.4492 9.2C12.4492 9.40711 12.2813 9.575 12.0742 9.575H6.32422C6.11711 9.575 5.94922 9.40711 5.94922 9.2Z"
    fill={color}
    fillRule="evenodd"
  />
);
