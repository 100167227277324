import type { IconChildrenProps } from "../../icon.types";

export const SolutionsWarehouses = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M5.83398 13.0837H14.1673"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M5.83398 15.5836H14.1673"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M5.83398 18.0837H14.1673"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M14.1673 18.0836V10.5836H5.83398V18.0836"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      d="M8.33398 8.08366H11.6673"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
    <path
      clipRule="evenodd"
      d="M17.5 7.25028L10 3.08362L2.5 7.25028V18.0836H17.5V7.25028Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.32509"
    />
  </>
);
