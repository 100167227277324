import type { IconChildrenProps } from "../icon.types";

export const ArrowUpward = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="M10.8339 15.8333V6.52496L14.9005 10.5916C15.2255 10.9166 15.7589 10.9166 16.0839 10.5916C16.4089 10.2666 16.4089 9.74163 16.0839 9.41663L10.5922 3.92496C10.4365 3.76894 10.2251 3.68126 10.0047 3.68126C9.78427 3.68126 9.5729 3.76894 9.4172 3.92496L3.9172 9.40829C3.7073 9.61819 3.62533 9.92411 3.70216 10.2108C3.77898 10.4976 4.00294 10.7215 4.28966 10.7983C4.57638 10.8752 4.8823 10.7932 5.0922 10.5833L9.1672 6.52496V15.8333C9.1672 16.2916 9.5422 16.6666 10.0005 16.6666C10.4589 16.6666 10.8339 16.2916 10.8339 15.8333Z"
    fill={color}
    fillRule="evenodd"
  />
);
