import type { IconChildrenProps } from "../icon.types";

export const ChartAlertTask = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M6.44437 2C6.71699 2 6.938 2.22101 6.938 2.49363C6.938 2.74922 6.74376 2.95944 6.49484 2.98472L6.44437 2.98727H4.86407C3.85634 2.98727 3.0341 3.78151 2.9892 4.77814L2.98727 4.86404V8.02464C2.98727 8.29727 2.76626 8.51828 2.49363 8.51828C2.23805 8.51828 2.02783 8.32403 2.00255 8.07511L2 8.02464V4.86404C2 3.31393 3.23152 2.05141 4.76953 2.00153L4.86407 2H6.44437ZM17.4444 12.2719C17.7 12.2719 17.9102 12.4662 17.9354 12.7151L17.938 12.7655V15.136C17.938 16.6861 16.7065 17.9486 15.1685 17.9985L15.0739 18H13.4936C13.221 18 13 17.779 13 17.5064C13 17.2508 13.1942 17.0406 13.4432 17.0153L13.4936 17.0128H15.0739C16.0817 17.0128 16.9039 16.2185 16.9488 15.2219L16.9507 15.136V12.7655C16.9507 12.4929 17.1717 12.2719 17.4444 12.2719ZM4.86668 18.0001L4.85989 18H2.49363C2.22101 18 2 17.779 2 17.5064V14.5434C2 14.2707 2.22101 14.0497 2.49363 14.0497H4.37305V11.1853C4.37305 10.9127 4.59405 10.6917 4.86668 10.6917H7.23711C7.50974 10.6917 7.73074 10.9127 7.73074 11.1853V12.4694H9.60603C9.87866 12.4694 10.0997 12.6904 10.0997 12.9631V17.5064C10.0997 17.779 9.87866 18 9.60603 18H7.2447C7.24218 18.0001 7.23965 18.0001 7.23711 18.0001H4.86668ZM6.74273 12.9353C6.74223 12.9445 6.74197 12.9538 6.74197 12.9631V17.0124H5.35938V11.6788H6.74273V12.9353ZM7.73074 17.0124H9.11166V13.4567H7.73074V17.0124ZM4.36929 15.0367V17.0124H2.98633V15.0367H4.36929Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M13.9968 4.26605C14.2397 4.26605 14.4403 4.44651 14.4721 4.68064L14.4765 4.74573V6.72397C14.4765 6.98889 14.2617 7.20365 13.9968 7.20365C13.754 7.20365 13.5533 7.02319 13.5215 6.78906L13.5171 6.72397V4.74573C13.5171 4.48081 13.7319 4.26605 13.9968 4.26605Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M13.9962 7.53876L14.0639 7.54296C14.3298 7.57625 14.5341 7.80312 14.5341 8.07664C14.5341 8.37203 14.2953 8.61452 13.9969 8.61452C13.7 8.61452 13.459 8.37354 13.4591 8.08623C13.4535 7.8076 13.6608 7.57688 13.9282 7.54303L13.9962 7.53876Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.2107 3.08892C14.0544 2.80477 13.6434 2.81282 13.4983 3.10289L10.7432 8.61316C10.6094 8.88066 10.804 9.19539 11.103 9.19539H16.8888C17.1948 9.19539 17.3888 8.86732 17.2413 8.5992L14.2107 3.08892ZM12.7786 2.74305C13.2137 1.87286 14.4468 1.84868 14.9157 2.70116L17.9463 8.21144C18.3888 9.0158 17.8068 10 16.8888 10H11.103C10.2058 10 9.62226 9.05582 10.0235 8.25333L12.7786 2.74305Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
