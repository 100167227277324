import type { IconChildrenProps } from "../icon.types";

export const RemoveCircle = ({ color }: IconChildrenProps) => (
  <path
    clipRule="evenodd"
    d="m10,0.25c5.3848,0 9.75,4.36521 9.75,9.75c0,5.3848 -4.3652,9.75 -9.75,9.75c-5.38479,0 -9.75,-4.3652 -9.75,-9.75c0,-5.38477 4.36523,-9.75 9.75,-9.75zm0,1.5c-4.55635,0 -8.25,3.69365 -8.25,8.25c0,4.5564 3.69364,8.25 8.25,8.25c4.5564,0 8.25,-3.6936 8.25,-8.25c0,-4.55636 -3.6936,-8.25 -8.25,-8.25zm0.75,7.5l3,0c0.4142,0 0.75,0.33579 0.75,0.75c0,0.3797 -0.2822,0.6935 -0.6482,0.7432l-0.1018,0.0068l-3,0l-1.5,0l-3,0c-0.41421,0 -0.75,-0.3358 -0.75,-0.75c0,-0.3797 0.28215,-0.69349 0.64823,-0.74315l0.10177,-0.00685l3,0l1.5,0z"
    fill={color}
    fillRule="evenodd"
  />
);
