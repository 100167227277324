import type { IconChildrenProps } from "../icon.types";

export const ControlRoom = ({ accentColor, color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M16.7601 9.62951C16.7601 5.69136 13.5682 2.49945 9.63006 2.49945C5.69189 2.49945 2.5 5.69134 2.5 9.62951C2.5 13.549 5.66176 16.7293 9.57418 16.7594C9.59286 16.7599 9.61157 16.7601 9.63032 16.7601C9.88592 16.7601 10.0931 16.5529 10.0931 16.2973C10.0931 16.2274 10.0776 16.1611 10.0499 16.1016C9.97624 15.9435 9.81595 15.8339 9.63006 15.8339C9.62415 15.8339 9.61824 15.8339 9.61233 15.8339L9.55819 15.8322C9.1995 15.8089 8.87212 15.6125 8.66943 15.2742C8.11463 14.3479 7.70974 13.3482 7.45477 12.3148H9.62965L9.67697 12.3124C9.91035 12.2887 10.0925 12.0916 10.0925 11.852C10.0925 11.5964 9.88526 11.3892 9.62965 11.3892H7.26775C7.08345 10.225 7.08348 9.03381 7.26784 7.86965H11.9918C12.0835 8.44887 12.13 9.03747 12.13 9.62933C12.13 9.88493 12.3372 10.0921 12.5928 10.0921C12.8484 10.0921 13.0556 9.88493 13.0556 9.62933C13.0556 9.0382 13.0128 8.44991 12.9281 7.86965H15.5814C15.7461 8.42755 15.8345 9.0182 15.8345 9.62951C15.8345 9.88512 16.0417 10.0923 16.2973 10.0923C16.5529 10.0923 16.7601 9.88512 16.7601 9.62951ZM12.7559 6.94403H15.2249C14.489 5.41353 13.1437 4.23162 11.5034 3.71284C12.0788 4.7203 12.4989 5.81088 12.7559 6.94403ZM7.75638 3.71293C6.11625 4.23175 4.77102 5.41362 4.0352 6.94403H6.50373C6.75868 5.81952 7.17623 4.72832 7.75638 3.71293ZM7.45491 6.94403H11.8047C11.5476 5.90249 11.1403 4.90363 10.5906 3.98529C10.1428 3.23837 9.11658 3.23837 8.66947 3.98514C8.11475 4.91128 7.7099 5.91079 7.45491 6.94403ZM6.33156 7.86965H3.67869C3.514 8.42755 3.42562 9.0182 3.42562 9.62951C3.42562 10.2408 3.51398 10.8313 3.67864 11.3892H6.33149C6.16138 10.2236 6.16141 9.03524 6.33156 7.86965ZM6.50361 12.3148H4.03512C4.77088 13.8452 6.11605 15.0271 7.75613 15.546C7.17602 14.5306 6.75851 13.4393 6.50361 12.3148Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12.8932 11.2262L12.3752 11.2943L12.3054 11.3056C11.7754 11.4065 11.3657 11.8351 11.2938 12.3743L11.2241 12.8934L10.9055 13.3109L10.8609 13.3736C10.5633 13.8195 10.5785 14.4074 10.9057 14.8374L11.2249 15.2556L11.2938 15.7738L11.3051 15.8435C11.4059 16.3729 11.8345 16.7826 12.3736 16.8546L12.8936 16.9239L13.3103 17.2422L13.3728 17.287C13.817 17.5861 14.4071 17.5711 14.8371 17.2418L15.2539 16.9232L15.7723 16.8546L15.8421 16.8433C16.3715 16.7424 16.7813 16.3139 16.8532 15.7747L16.9226 15.2552L17.2415 14.838L17.2864 14.7756C17.5854 14.3314 17.5705 13.7413 17.2412 13.3112L16.9226 12.8945L16.854 12.3759L16.8427 12.3062C16.7418 11.7768 16.3132 11.367 15.774 11.2952L15.2528 11.2262L14.8367 10.9083C14.3866 10.5637 13.7604 10.5637 13.3099 10.9086L12.8932 11.2262ZM14.2744 11.6436L14.789 12.0365C14.8532 12.0856 14.929 12.1169 15.0091 12.1275L15.6521 12.2127C15.7999 12.2324 15.9166 12.3493 15.9364 12.4981L16.0216 13.1387C16.0323 13.2188 16.0636 13.2946 16.1126 13.3587L16.506 13.8736C16.597 13.9924 16.597 14.1573 16.5063 14.2758L16.1932 14.6847L16.1804 14.6966C16.1047 14.7681 16.0403 14.8703 16.0217 15.0099L15.9357 15.6521C15.9159 15.8005 15.7991 15.9172 15.6502 15.9371L15.009 16.0222L14.9571 16.0316C14.8738 16.0507 14.8057 16.0874 14.7525 16.1355L14.7267 16.1612L14.2748 16.5066C14.156 16.5976 13.991 16.5976 13.8726 16.5069L13.358 16.1139C13.294 16.065 13.2184 16.0337 13.1385 16.023L12.4962 15.9371C12.3478 15.9173 12.2312 15.8005 12.2113 15.6516L12.1262 15.011C12.1155 14.931 12.0841 14.8551 12.0351 14.791L11.6417 14.2761C11.5512 14.1572 11.5511 13.9913 11.642 13.8715L11.9528 13.4647L11.9666 13.4524C12.0423 13.3809 12.1067 13.2787 12.1254 13.1391L12.2113 12.4968C12.2311 12.3484 12.3479 12.2318 12.4967 12.2119L13.1375 12.1276L13.1894 12.1183C13.2728 12.0992 13.341 12.0626 13.3943 12.0145L13.4193 11.9889L13.8719 11.6441C13.991 11.5529 14.156 11.5529 14.2744 11.6436Z"
      fill={accentColor}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.6689 13.2869C14.8496 13.1061 15.1427 13.1061 15.3234 13.2869C15.4921 13.4555 15.5033 13.7221 15.3571 13.9038L15.3234 13.9414L14.1707 15.0941C14.0019 15.2628 13.7353 15.274 13.5536 15.1277L13.516 15.0939L12.8248 14.402C12.6442 14.2211 12.6443 13.9281 12.8252 13.7475C12.994 13.5789 13.2605 13.5678 13.4421 13.7141L13.4797 13.7478L13.8433 14.1119L14.6689 13.2869Z"
      fill={accentColor}
      fillRule="evenodd"
    />
  </>
);
