import type { IconChildrenProps } from "../../icon.types";

export const Machinery = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M3.73792 16.5636H10.1709C10.6156 16.5636 10.9713 16.9194 10.9713 17.3641V18.2238H2.9375V17.3641C2.9375 16.9194 3.29324 16.5636 3.73792 16.5636Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.74117 17.0005C3.53775 17.0005 3.3776 17.1607 3.3776 17.3641V17.7869H10.5377V17.3641C10.5377 17.1607 10.3776 17.0005 10.1741 17.0005H3.74117ZM2.50391 17.3641C2.50391 16.6781 3.05523 16.1268 3.74117 16.1268H10.1741C10.8601 16.1268 11.4114 16.6781 11.4114 17.3641V18.2238C11.4114 18.4651 11.2158 18.6607 10.9746 18.6607H2.94075C2.69949 18.6607 2.50391 18.4651 2.50391 18.2238V17.3641Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.40392 11.4272C9.64047 11.3798 9.87069 11.5331 9.91813 11.7697L10.8622 16.4777C10.8879 16.6061 10.8548 16.7392 10.7718 16.8405C10.6888 16.9418 10.5648 17.0005 10.4339 17.0005H3.45247C3.3239 17.0005 3.20185 16.9438 3.11884 16.8456C3.03584 16.7474 3.00032 16.6176 3.02173 16.4908L3.81692 11.7828C3.85711 11.5449 4.08253 11.3846 4.32042 11.4248C4.55832 11.465 4.71859 11.6904 4.67841 11.9283L3.96929 16.1267H9.90074L9.06149 11.9414C9.01406 11.7049 9.16737 11.4747 9.40392 11.4272Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2071 4.15601C12.3579 3.96761 12.6328 3.93706 12.8212 4.08778L15.0054 5.83522C15.1938 5.98594 15.2243 6.26085 15.0736 6.44925C14.9229 6.63766 14.648 6.6682 14.4596 6.51748L12.2754 4.77004C12.087 4.61932 12.0564 4.34441 12.2071 4.15601ZM10.847 5.54412C10.9549 5.32832 11.2173 5.24085 11.4331 5.34875L14.0542 6.65933C14.2699 6.76723 14.3574 7.02964 14.2495 7.24544C14.1416 7.46124 13.8792 7.54871 13.6634 7.44081L11.0424 6.13023C10.8266 6.02233 10.7391 5.75992 10.847 5.54412Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.95453 8.60672C5.82653 8.60672 4.9121 9.52118 4.9121 10.6492C4.9121 11.7773 5.82653 12.6917 6.95453 12.6917C8.08253 12.6917 8.99696 11.7773 8.99696 10.6492C8.99696 9.52118 8.08253 8.60672 6.95453 8.60672ZM3.60156 10.6492C3.60156 8.79737 5.10274 7.29614 6.95453 7.29614C8.80632 7.29614 10.3075 8.79737 10.3075 10.6492C10.3075 12.5011 8.80632 14.0023 6.95453 14.0023C5.10274 14.0023 3.60156 12.5011 3.60156 10.6492Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.90081 4.97096C9.08399 5.12798 9.10521 5.40377 8.9482 5.58696L6.32712 8.64497C6.17011 8.82816 5.89433 8.84938 5.71115 8.69236C5.52797 8.53534 5.50676 8.25955 5.66377 8.07637L8.28484 5.01835C8.44185 4.83516 8.71763 4.81395 8.90081 4.97096ZM10.0893 6.20762C10.3133 6.29722 10.4223 6.55146 10.3327 6.77548L9.45896 8.95978C9.36936 9.18379 9.11513 9.29275 8.89112 9.20314C8.66712 9.11354 8.55816 8.8593 8.64776 8.63528L9.52145 6.45099C9.61106 6.22697 9.86529 6.11801 10.0893 6.20762Z"
      fill={color}
    />
    <path
      d="M10.3639 6.61323C11.5702 6.61323 12.5481 5.63528 12.5481 4.42893C12.5481 3.22257 11.5702 2.24463 10.3639 2.24463C9.1576 2.24463 8.17969 3.22257 8.17969 4.42893C8.17969 5.63528 9.1576 6.61323 10.3639 6.61323Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3625 2.48928C9.29125 2.48928 8.42286 3.3577 8.42286 4.42894C8.42286 5.50018 9.29125 6.3686 10.3625 6.3686C11.4337 6.3686 12.302 5.50018 12.302 4.42894C12.302 3.3577 11.4337 2.48928 10.3625 2.48928ZM7.93359 4.42894C7.93359 3.08747 9.02103 2 10.3625 2C11.7039 2 12.7913 3.08747 12.7913 4.42894C12.7913 5.77041 11.7039 6.85788 10.3625 6.85788C9.02103 6.85788 7.93359 5.77041 7.93359 4.42894Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2095 5.69833L15.7256 6.17636L15.7155 6.18341C15.4754 5.9112 15.1239 5.7395 14.7324 5.7395C14.0086 5.7395 13.4219 6.32627 13.4219 7.05008C13.4219 7.60342 13.7648 8.07666 14.2497 8.26889L14.2956 8.36066L14.3651 8.30849C14.3685 8.30949 14.3719 8.31046 14.3753 8.31143L14.7049 9.98221L16.706 10.6344L17.1655 10.0415L15.4312 9.03354V8.15898C15.7988 7.92684 16.0429 7.51697 16.0429 7.05008L16.75 6.79524L18.4842 7.78838L18.7807 7.0917L17.2095 5.69833Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1422 5.49044C17.2157 5.46676 17.2963 5.48367 17.3541 5.53493L18.9253 6.92829C18.9955 6.9906 19.0181 7.09084 18.9813 7.17724L18.6849 7.87393C18.6604 7.93134 18.6126 7.97557 18.5535 7.99545C18.4943 8.01533 18.4295 8.00896 18.3753 7.97795L16.7287 7.03498L16.2531 7.2064C16.2089 7.64245 15.9813 8.0242 15.6493 8.27349V8.90787L17.2749 9.85267C17.3294 9.88432 17.3675 9.93792 17.3795 9.99973C17.3916 10.0615 17.3764 10.1255 17.3378 10.1753L16.8783 10.7682C16.8218 10.8411 16.7256 10.8707 16.638 10.8421L14.6369 10.1899C14.5617 10.1654 14.5056 10.1021 14.4903 10.0245L14.201 8.55772C14.1576 8.53695 14.1218 8.50219 14.0999 8.45837L14.0897 8.43794C13.5665 8.19536 13.2031 7.66541 13.2031 7.0501C13.2031 6.20565 13.8877 5.52109 14.7321 5.52109C15.1347 5.52109 15.5012 5.67696 15.7741 5.93116L17.1422 5.49044ZM14.3751 8.09033C14.3922 8.09113 14.4092 8.09394 14.4259 8.09881L14.4344 8.10125C14.5135 8.1236 14.5734 8.1885 14.5893 8.26917L14.8941 9.81425L16.6272 10.3791L16.8396 10.105L15.3212 9.22241C15.2539 9.18331 15.2125 9.11137 15.2125 9.03356V8.159C15.2125 8.08405 15.2509 8.01434 15.3143 7.97432C15.6212 7.78051 15.8242 7.43894 15.8242 7.0501C15.8242 6.95802 15.8819 6.87583 15.9686 6.84461L16.6756 6.58977C16.7358 6.56805 16.8026 6.57387 16.8582 6.60571L18.3791 7.4767L18.5178 7.15084L17.1575 5.94448L15.8189 6.3757C15.7305 6.42344 15.6193 6.4049 15.5514 6.32794C15.3509 6.10073 15.0583 5.95795 14.7321 5.95795C14.1289 5.95795 13.64 6.44692 13.64 7.0501C13.64 7.51087 13.9254 7.90551 14.3298 8.06585C14.346 8.07224 14.3612 8.08048 14.3751 8.09033Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0584 17.8044C17.2943 17.8044 17.4778 17.9967 17.4778 18.2238C17.4778 18.4335 17.3205 18.6083 17.1196 18.6432H17.0584H1.41937C1.18348 18.6432 1 18.451 1 18.2238C1 18.0141 1.15726 17.8394 1.35821 17.8044H1.41937H17.0584Z"
      fill={color}
    />
  </>
);
