import type { IconChildrenProps } from "../icon.types";

export const Logic = ({ color }: IconChildrenProps) => (
  <>
    <line
      x1="10.2423"
      y1="6.92083"
      x2="4.67443"
      y2="13.5563"
      stroke={color}
      strokeWidth="0.5"
    />
    <line
      x1="10.2423"
      y1="7.16865"
      x2="14.855"
      y2="12.6658"
      stroke={color}
      strokeWidth="0.5"
    />
    <rect x="8.55078" y="4.76562" width="3" height="3" rx="0.5" fill={color} />
    <rect x="13.0566" y="12.2343" width="3" height="3" rx="0.5" fill={color} />
    <rect x="3.94141" y="12.2343" width="3" height="3" rx="0.5" fill={color} />
  </>
);
