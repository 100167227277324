import { AR_CATEGORY_PUBLIC_SAFETY } from "~/features/alert-rule/alert-rule.constants";
import { useAlertCategoryColor } from "~/features/alert-rule/hooks/use-alert-category-color";
import type { IconChildrenProps } from "../../icon.types";

export const CategoryPublicSafety = ({ color }: IconChildrenProps) => {
  const getAlertCategoryColor = useAlertCategoryColor();

  return (
    <>
      <path
        clipRule="evenodd"
        d="M12.6809 12.38C12.4599 12.38 12.2809 12.5591 12.2809 12.78C12.2809 13.0009 12.4599 13.18 12.6809 13.18C12.9018 13.18 13.0809 13.0009 13.0809 12.78C13.0809 12.5591 12.9018 12.38 12.6809 12.38ZM11.2809 12.78C11.2809 12.0068 11.9077 11.38 12.6809 11.38C13.4541 11.38 14.0809 12.0068 14.0809 12.78C14.0809 13.5532 13.4541 14.18 12.6809 14.18C11.9077 14.18 11.2809 13.5532 11.2809 12.78Z"
        fill={getAlertCategoryColor(AR_CATEGORY_PUBLIC_SAFETY)}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.28086 12.38C7.05995 12.38 6.88086 12.5591 6.88086 12.78C6.88086 13.0009 7.05995 13.18 7.28086 13.18C7.50177 13.18 7.68086 13.0009 7.68086 12.78C7.68086 12.5591 7.50177 12.38 7.28086 12.38ZM5.88086 12.78C5.88086 12.0068 6.50766 11.38 7.28086 11.38C8.05406 11.38 8.68086 12.0068 8.68086 12.78C8.68086 13.5532 8.05406 14.18 7.28086 14.18C6.50766 14.18 5.88086 13.5532 5.88086 12.78Z"
        fill={getAlertCategoryColor(AR_CATEGORY_PUBLIC_SAFETY)}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9.7981 0.928287C9.91649 0.890604 10.0437 0.890604 10.1621 0.928287L14.3721 2.26829C15.8701 2.74767 16.8801 4.12864 16.8801 5.70002V11C16.8801 14.0914 14.3714 16.6 11.2801 16.6H8.68008C5.58871 16.6 3.08008 14.0914 3.08008 11V5.70002C3.08008 4.1264 4.10113 2.74795 5.58588 2.269L5.5881 2.26828L9.7981 0.928287ZM5.95428 3.41105C5.95394 3.41116 5.95359 3.41127 5.95325 3.41138C4.95856 3.73274 4.28008 4.65401 4.28008 5.70002V11C4.28008 13.4287 6.25145 15.4 8.68008 15.4H11.2801C13.7087 15.4 15.6801 13.4287 15.6801 11V5.70002C15.6801 4.65157 15.0094 3.73238 14.0077 3.41162C14.0075 3.41158 14.0078 3.41167 14.0077 3.41162L9.98008 2.12968L5.95428 3.41105Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.68008 5.86002C7.45916 5.86002 7.28008 6.03911 7.28008 6.26003V8.56002C7.28008 8.78094 7.45916 8.96002 7.68008 8.96002H12.2801C12.501 8.96002 12.6801 8.78094 12.6801 8.56002V6.26003C12.6801 6.03911 12.501 5.86002 12.2801 5.86002H7.68008ZM6.08008 6.26003C6.08008 5.37637 6.79642 4.66002 7.68008 4.66002H12.2801C13.1637 4.66002 13.8801 5.37637 13.8801 6.26003V8.56002C13.8801 9.44368 13.1637 10.16 12.2801 10.16H7.68008C6.79642 10.16 6.08008 9.44368 6.08008 8.56002V6.26003Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M13.116 15.5391C13.3705 15.327 13.7489 15.3613 13.961 15.6159L16.461 18.6159C16.6732 18.8705 16.6388 19.2488 16.3842 19.461C16.1296 19.6731 15.7513 19.6387 15.5391 19.3841L13.0391 16.3841C12.827 16.1296 12.8614 15.7512 13.116 15.5391Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.88419 15.5391C7.13875 15.7512 7.17315 16.1296 6.96101 16.3841L4.46101 19.3841C4.24887 19.6387 3.87053 19.6731 3.61597 19.461C3.3614 19.2488 3.32701 18.8705 3.53915 18.6159L6.03915 15.6159C6.25128 15.3613 6.62962 15.327 6.88419 15.5391Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M4.21008 18.02C4.21008 17.6887 4.47871 17.42 4.81008 17.42H15.1901C15.5214 17.42 15.7901 17.6887 15.7901 18.02C15.7901 18.3514 15.5214 18.62 15.1901 18.62H4.81008C4.47871 18.62 4.21008 18.3514 4.21008 18.02Z"
        fill={color}
        fillRule="evenodd"
      />
    </>
  );
};
