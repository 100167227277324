import type { IconChildrenProps } from "../../icon.types";

export const SolutionsMining = ({ color }: IconChildrenProps) => (
  <>
    <path
      d="M15.5986 4.40205C18.6902 7.4937 18.6902 12.5063 15.5986 15.5979C12.5069 18.6895 7.49435 18.6895 4.40272 15.5979C1.31107 12.5062 1.31107 7.49368 4.40272 4.40205C7.49437 1.3104 12.5069 1.3104 15.5986 4.40205"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.26108"
    />
    <path
      d="M15.5986 4.40205C18.6902 7.4937 18.6902 12.5063 15.5986 15.5979C12.5069 18.6895 7.49435 18.6895 4.40272 15.5979C1.31107 12.5062 1.31107 7.49368 4.40272 4.40205C7.49437 1.3104 12.5069 1.3104 15.5986 4.40205"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.26108"
    />
    <path
      d="M13.5355 6.46444C15.4882 8.41706 15.4882 11.5829 13.5355 13.5355C11.5829 15.4881 8.41709 15.4881 6.46447 13.5355C4.51184 11.5829 4.51184 8.41705 6.46447 6.46444C8.41709 4.51181 11.5829 4.51181 13.5355 6.46444"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.26108"
    />
    <path
      d="M13.5355 6.46444C15.4882 8.41706 15.4882 11.5829 13.5355 13.5355C11.5829 15.4881 8.41709 15.4881 6.46447 13.5355C4.51184 11.5829 4.51184 8.41705 6.46447 6.46444C8.41709 4.51181 11.5829 4.51181 13.5355 6.46444"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.26108"
    />
    <path
      d="M6.45898 2.92496L7.76732 5.53329"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.26108"
    />
    <path
      d="M12.2324 14.4667L13.5408 17.075"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.26108"
    />
    <path
      d="M12.2324 5.53329L13.5408 2.92496"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.26108"
    />
    <path
      d="M7.76537 14.4667L6.45703 17.075"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.26108"
    />
    <path
      d="M17.0732 6.45828L14.4648 7.76662"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.26108"
    />
    <path
      d="M5.53216 12.2333L2.92383 13.5416"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.26108"
    />
    <path
      d="M17.0732 13.5416L14.4648 12.2333"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.26108"
    />
    <path
      d="M5.53216 7.76662L2.92383 6.45828"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.26108"
    />
    <path
      clipRule="evenodd"
      d="M9.9987 12.4999L12.2904 9.99994L9.9987 7.49994L7.70703 9.99994L9.9987 12.4999Z"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.26108"
    />
  </>
);
