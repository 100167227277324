import type { IconChildrenProps } from "../../icon.types";

export const ErgonomicsNeckBend = ({ color }: IconChildrenProps) => (
  <>
    <path
      clipRule="evenodd"
      d="M13.2973 3.63897L13.3373 3.77179L14.1629 6.85273C14.5472 8.28702 14.0545 9.74967 13.0135 10.6713L13.5067 12.7501C13.601 12.7389 13.6966 12.7379 13.792 12.7473L13.921 12.7666L16.1905 13.2203C17.5365 13.4894 18.5193 14.6373 18.5903 15.9948L18.5944 16.1523V17.2561C18.5944 17.5862 18.3267 17.8539 17.9966 17.8539C17.6939 17.8539 17.4438 17.629 17.4042 17.3372L17.3988 17.2561V16.1523C17.3988 15.3419 16.8568 14.6383 16.0866 14.4238L15.9561 14.3927L13.6865 13.939C13.6542 13.9325 13.6208 13.9391 13.5941 13.9569C11.8773 15.1021 9.87411 15.177 8.22589 14.0776C8.12091 14.0076 8.04523 13.9101 8.00201 13.8011C7.96527 13.7148 7.94888 13.6193 7.95689 13.5216L7.96899 13.4412L8.49618 11.0441C7.76424 10.5866 7.18614 9.87934 6.90235 9.00347L6.8461 8.81323L6.02057 5.73229C5.71921 4.60757 6.34938 3.45275 7.43816 3.08696L7.57098 3.04691L10.6519 2.22137C11.7317 1.93207 12.8391 2.50126 13.2496 3.51055L13.2973 3.63897ZM11.9415 11.3377C11.7946 11.3977 11.6422 11.4492 11.4848 11.4913C10.8513 11.6611 10.2122 11.6597 9.61665 11.5156L9.22817 13.2837C10.2193 13.8003 11.3401 13.7879 12.4008 13.268L11.9415 11.3377ZM11.0612 3.35484L10.9614 3.37625L7.88043 4.20178C7.38179 4.33539 7.07322 4.82421 7.15403 5.32305L7.17544 5.42285L8.00097 8.50379C8.37145 9.88645 9.79265 10.707 11.1754 10.3365C12.5103 9.97877 13.3213 8.64159 13.0422 7.30531L13.0081 7.16218L12.1824 4.08123C12.0577 3.61584 11.6236 3.31602 11.1607 3.34381L11.0612 3.35484Z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M5.75157 11.3408C5.92509 11.5857 5.88826 11.9164 5.67773 12.1179L5.6094 12.1742L3.52727 13.6488L4.32796 13.786C4.62629 13.8369 4.83503 14.1006 4.82499 14.3949L4.81673 14.4758C4.76584 14.7742 4.50208 14.9829 4.20779 14.9729L4.12691 14.9646L1.90363 14.5853C1.60529 14.5345 1.39655 14.2707 1.4066 13.9764L1.41486 13.8955L1.79411 11.6722C1.845 11.3739 2.10875 11.1652 2.40304 11.1752L2.48392 11.1835C2.78226 11.2344 2.991 11.4981 2.98096 11.7924L2.97269 11.8733L2.83541 12.674L4.91818 11.1987C5.18757 11.0078 5.56069 11.0714 5.75157 11.3408Z"
      fill={color}
      fillRule="evenodd"
    />
  </>
);
